import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {FORM_ANALYSIS_FIELDS_GET, FORM_FILTERS_GET, FORM_FILTERS_CLEAR} from 'appRedux/actions/forms';
import {PanelFormTypes, PanelHasStatusItemTypes, PanelHasFieldItemTypes} from 'appRedux/actions/analytics/types';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import {
    validationPanelSchemaPanelCreate,
    validationPanelSchemaPanelForFormCreate,
} from 'components/Forms/Dashboard/validation';
import {
    panelTypesArray,
    panelDatefilterTypeArray,
    panelDisplayTypeArray,
    PanelOptionType,
    DISPLAY_TYPE_BAR_CHART,
    TYPE_CASE_CHECKBOX_ANALYSIS,
    TYPE_CASE_STATUS_TIME_ANALYSIS,
    TYPE_CASE_STATUS_DISTRIBUTION,
    TYPE_TEXT_FIELD_ANALYSIS,
    getAvailableFormVersion,
} from 'components/Forms/Dashboard/helper';
import WorkflowStatusesSelectors from 'components/Forms/Dashboard/partials/WorkflowStatusesSelectors';
import FormFieldFilterSelectors from 'components/Forms/Dashboard/partials/FormFieldFilterSelectors';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import FormAnalysisFieldsSelectors from 'components/Forms/Dashboard/partials/FormAnalysisFieldsSelectors';

interface WorkflowFormType {
    initialValues: PanelFormTypes;
    statuses: PanelHasStatusItemTypes[];
    setStatuses: (values: PanelHasStatusItemTypes[]) => void;
    panelFields: PanelHasFieldItemTypes[];
    setPanelFields: (values: PanelHasFieldItemTypes[]) => void;
    onSubmitClicked: (values: PanelFormTypes) => void;
}

const PanelForm: FC<WorkflowFormType> = ({
    initialValues,
    onSubmitClicked,
    statuses,
    setStatuses,
    panelFields,
    setPanelFields,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {filtersAnalyticCreateAllowed, filtersAnalyticEditAllowed} = useContext(PermissionContext);

    const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
        initialValues.workflow !== 0 ? initialValues.workflow : null,
    );

    const getFormAnalysisFields = useCallback(
        data => dispatch({type: FORM_ANALYSIS_FIELDS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);

    const {
        admin: {formsList, formAnalysisFields, formFieldFilters},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedWorkflow = organizationWorkflows.find(item => item.id === selectedWorkflowId);
    const selectedWorkflowStatuses = selectedWorkflow ? selectedWorkflow.workflowStatuses : [];
    const fieldFilters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);

    useEffect(() => {
        if (initialValues.type === TYPE_CASE_CHECKBOX_ANALYSIS && initialValues.version) {
            getFormFieldFilters({
                id: initialValues.version,
                showAlert,
            });
        }
        if (initialValues.type === TYPE_TEXT_FIELD_ANALYSIS && initialValues.version) {
            getFormAnalysisFields({
                id: initialValues.version,
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        return () => {
            clearFormFieldFilters();
        };
    }, []);

    const filteredFormVersions: FilterOptionType[] = formsList
        ? getAvailableFormVersion(
              formsList,
              selectedWorkflowId,
              filtersAnalyticCreateAllowed,
              filtersAnalyticEditAllowed,
          )
        : [];

    const isPossibleForAllForms =
        filtersAnalyticCreateAllowed.length === formsList.length &&
        filtersAnalyticEditAllowed.length === formsList.length;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() =>
                isPossibleForAllForms ? validationPanelSchemaPanelCreate(t) : validationPanelSchemaPanelForFormCreate(t)
            }
            onSubmit={onSubmitClicked}
            enableReinitialize
        >
            {(formik: FormikProps<PanelFormTypes>) => {
                const isFormRequired =
                    [TYPE_TEXT_FIELD_ANALYSIS, TYPE_CASE_CHECKBOX_ANALYSIS].includes(formik.values.type) ||
                    !isPossibleForAllForms;
                const formLabel = isFormRequired
                    ? t('orguser.analytics.panels.formTitle')
                    : `${t('orguser.analytics.panels.formTitle')} (${t('common.optional')})`;
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.analytics.panels.title')}
                            component={FormikTextInput}
                        />

                        <Field
                            as="select"
                            required
                            name={'type'}
                            label={t('orguser.analytics.panels.type')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                            onChange={e => {
                                formik.handleChange(e);
                                const value = e.target.value;
                                if (Number(value) === TYPE_CASE_STATUS_TIME_ANALYSIS) {
                                    setStatuses([]);
                                    formik.setFieldValue('displayType', DISPLAY_TYPE_BAR_CHART);
                                }
                                if (Number(value) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                    clearFormFieldFilters();
                                    setPanelFields([]);
                                    const versionId = Number(formik.values.version);
                                    if (versionId !== 0) {
                                        getFormFieldFilters({
                                            id: versionId,
                                            showAlert,
                                        });
                                    }
                                }
                                if (Number(value) === TYPE_TEXT_FIELD_ANALYSIS) {
                                    const versionId = Number(formik.values.version);
                                    if (versionId !== 0) {
                                        getFormAnalysisFields({
                                            id: versionId,
                                            showAlert,
                                        });
                                    }
                                }
                            }}
                        >
                            {panelTypesArray.map((item: PanelOptionType, index: number) => {
                                return (
                                    <MenuItem key={`panel-type-${index}`} value={item.name}>
                                        <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <Field
                            as="select"
                            required
                            name={'workflow'}
                            label={t('orguser.analytics.panels.workflow')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                            onChange={e => {
                                formik.handleChange(e);
                                const value = e.target.value;
                                setSelectedWorkflowId(Number(value));
                                formik.setFieldValue('version', null);
                                if (Number(formik.values.type) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                    clearFormFieldFilters();
                                    setPanelFields([]);
                                }
                            }}
                        >
                            {organizationWorkflows &&
                                organizationWorkflows.map((workflowItem: WorkflowItemTypes) => {
                                    const {uuid} = workflowItem;
                                    return (
                                        <MenuItem key={`panel-workflow-${uuid}`} value={workflowItem.id}>
                                            <Typography sx={{fontWeight: 600}}>{workflowItem.title}</Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                        {formik.values.workflow !== 0 && (
                            <Field
                                as="select"
                                required={isFormRequired}
                                name={'version'}
                                label={formLabel}
                                displayEmpty={isPossibleForAllForms}
                                options
                                component={FormikSelectInput}
                                fullwidth
                                onChange={e => {
                                    formik.handleChange(e);
                                    if (Number(formik.values.type) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                        clearFormFieldFilters();
                                        setPanelFields([]);
                                        const versionId = Number(e.target.value);
                                        getFormFieldFilters({
                                            id: versionId,
                                        });
                                    }
                                    if (Number(formik.values.type) === TYPE_TEXT_FIELD_ANALYSIS) {
                                        const versionId = Number(e.target.value);
                                        if (versionId !== 0) {
                                            getFormAnalysisFields({
                                                id: versionId,
                                                showAlert,
                                            });
                                        }
                                    }
                                }}
                            >
                                {![TYPE_CASE_CHECKBOX_ANALYSIS, TYPE_TEXT_FIELD_ANALYSIS].includes(
                                    formik.values.type,
                                ) &&
                                    isPossibleForAllForms && (
                                        <MenuItem key={`panel-form-all`} value={0}>
                                            <Typography sx={{fontWeight: 600}}>{t('common.all')}</Typography>
                                        </MenuItem>
                                    )}
                                {filteredFormVersions &&
                                    filteredFormVersions.map((versionItem: FilterOptionType, index: number) => {
                                        const {name, label} = versionItem;
                                        return (
                                            <MenuItem key={`panel-form-${index}`} value={name}>
                                                <Typography sx={{fontWeight: 600}}>{label}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                        )}
                        {formik.values.type === TYPE_CASE_STATUS_TIME_ANALYSIS && (
                            <>
                                <Box sx={{mb: 3, mt: 3}}>
                                    <Field
                                        type="checkbox"
                                        name="isIncludeCurrentStatus"
                                        label={t('orguser.analytics.panels.isIncludeCurrentStatus')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <WorkflowStatusesSelectors
                                        statuses={statuses}
                                        setStatuses={setStatuses}
                                        workflowStatuses={selectedWorkflowStatuses}
                                    />
                                </Box>
                            </>
                        )}
                        {formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS && fieldFilters.length > 0 && (
                            <Box sx={{mb: 2, mt: 1}}>
                                <FormFieldFilterSelectors
                                    panelFields={panelFields}
                                    setPanelFields={setPanelFields}
                                    fieldFilters={fieldFilters}
                                />
                            </Box>
                        )}
                        {formik.values.type === TYPE_TEXT_FIELD_ANALYSIS && (
                            <Box sx={{mb: 2, mt: 1}}>
                                <FormAnalysisFieldsSelectors
                                    panelFields={panelFields}
                                    setPanelFields={setPanelFields}
                                    analysisFields={formAnalysisFields}
                                />
                            </Box>
                        )}
                        <MdxEditorSmallField
                            name="description"
                            value={formik.values.description ? String(formik.values.description) : ''}
                            label={t('orguser.analytics.panels.description')}
                        />
                        <Field
                            as="select"
                            required
                            name={'datefilterType'}
                            label={t('orguser.analytics.panels.datefilterType')}
                            options
                            onChange={formik.handleChange}
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {panelDatefilterTypeArray.map((item: PanelOptionType, index: number) => {
                                return (
                                    <MenuItem key={`panel-datefilterType-${index}`} value={item.name}>
                                        <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        {formik.values.type === TYPE_CASE_STATUS_DISTRIBUTION && (
                            <Field
                                as="select"
                                required
                                name={'displayType'}
                                label={t('orguser.analytics.panels.displayType')}
                                options
                                onChange={formik.handleChange}
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {panelDisplayTypeArray.map((item: PanelOptionType, index: number) => {
                                    return (
                                        <MenuItem key={`panel-displayType-${index}`} value={item.name}>
                                            <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Field>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isDisabled={
                                    (formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS &&
                                        (!formik.values.version || panelFields.length === 0)) ||
                                    (formik.values.type === TYPE_CASE_STATUS_TIME_ANALYSIS && statuses.length === 0)
                                }
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PanelForm;
