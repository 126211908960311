import React, {FC, useContext, useState} from 'react';

import Box from '@mui/material/Box';
import {Badge, IconButton, Menu} from '@mui/material';

import {MediaContext} from 'contexts/media/context';

import {AgentMenuItemTypes} from 'components/AgentScreenComponents/helper';

import {MAIN_CLIENT_COLOR, theme} from 'config/theme';

interface MenuBlockItemWrapperTypes {
    menuItem: AgentMenuItemTypes;
    isActive?: boolean;
    enablePopup?: boolean;
    children?: React.ReactNode;
}

const MenuBlockItemWrapper: FC<MenuBlockItemWrapperTypes> = ({menuItem, isActive, enablePopup, children}) => {
    const {isMobile} = useContext(MediaContext);

    const [showMenuPopup, setShowMenuPopup] = useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const ItemIcon = menuItem.icon;

    if (!enablePopup || !ItemIcon) {
        return <Box sx={{mb: isMobile ? 0 : 0.25}}>{children}</Box>;
    }

    return (
        <>
            <IconButton
                sx={{
                    mx: isMobile ? '0' : 'auto',
                    width: 40,
                    height: 40,
                    backgroundColor: isActive ? theme.palette.info.main : 'initial',
                    '&:hover': {
                        backgroundColor: isActive ? theme.palette.info.main : 'initial',
                    },
                }}
                onClick={() => setShowMenuPopup(true)}
                ref={anchorRef}
            >
                <Badge
                    sx={{
                        '& .MuiBadge-badge': {
                            top: 2,
                            right: 2,
                            backgroundColor: MAIN_CLIENT_COLOR,
                        },
                    }}
                    variant="dot"
                    invisible={!menuItem.badge}
                >
                    <ItemIcon isActive={isActive} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorRef.current}
                open={showMenuPopup}
                onClose={() => setShowMenuPopup(false)}
                onClick={() => setShowMenuPopup(false)}
                PaperProps={{
                    elevation: 5,
                    sx: {
                        mt: 1.5,
                        minWidth: 240,
                        borderRadius: 4,
                        overflow: 'visible',
                        overflowY: 'auto',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'bottom'}}
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            >
                <Box sx={{p: 1}}>{children}</Box>
            </Menu>
        </>
    );
};

export default MenuBlockItemWrapper;
