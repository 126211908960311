import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';
import {GET_REDIRECT_URL} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';
import CaseKeyContextWrapper from 'contexts/caseKey';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import PagesList from 'pages/client/requests/partials/PagesList';
import FilesList from 'pages/client/requests/partials/FilesList';
import RequesterCaseTitle from 'pages/client/requests/partials/RequesterCaseTitle';

import {theme} from 'config/theme';

interface RequesterCaseItemType {
    requesterCase: RequesterAllCaseItemTypes;
}

const RequesterCaseItem: FC<RequesterCaseItemType> = ({requesterCase}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {organizationDomain, organization, organizationId, requestCase} = requesterCase;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const getCorrectOrganizationRedirect = useCallback(
        data => dispatch({type: GET_REDIRECT_URL.REQUEST, payload: data}),
        [dispatch],
    );

    const isCurrentOrganizationCase = window.location.href.includes(organizationDomain);

    const onRedirectClicked = () => {
        getCorrectOrganizationRedirect({
            id: organizationId,
            showAlert,
            callback: (redirectTo: string) => {
                window.location.href = redirectTo;
            },
        });
    };

    const isEncryptInfo = requestCase.isEncryptInfo;

    const [isPagesOpened, setIsPagesOpened] = useState<boolean>(false);
    const [isFilesOpened, setIsFilesOpened] = useState<boolean>(false);

    const togglePagesModal = () => {
        setIsPagesOpened(previous => !previous);
        setIsFilesOpened(false);
    };

    const toggleFilesModal = () => {
        setIsPagesOpened(false);
        setIsFilesOpened(previous => !previous);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.default,
                    p: 2,
                    borderRadius: 2,
                    mb: 2,
                    cursor: isCurrentOrganizationCase ? 'initial' : 'pointer',
                }}
                onClick={() => {
                    if (!isCurrentOrganizationCase) {
                        toggleModal();
                    }
                }}
            >
                <RequesterCaseTitle
                    item={requesterCase}
                    isCurrentOrganizationCase={isCurrentOrganizationCase}
                    togglePagesModal={togglePagesModal}
                    toggleFilesModal={toggleFilesModal}
                    isPagesOpened={isPagesOpened}
                    isFilesOpened={isFilesOpened}
                />
                <Divider sx={{mt: 1.5, mb: 2}} />
                {isEncryptInfo && isCurrentOrganizationCase ? (
                    <CaseKeyContextWrapper caseId={requestCase.id} formPrefix={requestCase.formPrefix}>
                        <FilesList
                            isCurrentOrganizationCase={isCurrentOrganizationCase}
                            currentCase={requesterCase}
                            isOpened={isFilesOpened}
                        />
                    </CaseKeyContextWrapper>
                ) : (
                    <FilesList
                        isCurrentOrganizationCase={isCurrentOrganizationCase}
                        currentCase={requesterCase}
                        isOpened={isFilesOpened}
                    />
                )}
                <PagesList item={requesterCase} isOpened={isPagesOpened} />
            </Box>
            <ModalWrapper title={t('messages.error.attention')} toggleModal={toggleModal} isShowModal={isOpened}>
                <Typography>
                    {t('messages.error.wrongSubdomainAttention').replace('%ORGANIZATION_NAME%', organization)}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentInverseButton
                        title={t('common.buttons.cancel')}
                        onClick={() => {
                            setIsOpened(false);
                        }}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.redirect')}
                        onClick={onRedirectClicked}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default RequesterCaseItem;
