import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {FormPopupTypes, FormFieldTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {FORM_FIELD_SIGNATURE, isSelectionFields} from 'pages/admin/updateForm/partials/FormStructure/helper';
import PopupInformationItemRow from 'pages/agent/requesterPage/sections/PopupInformationItemRow';
import PopupInformationSelectableItemRow from 'pages/agent/requesterPage/sections/PopupInformationSelectableItemRow';
import {FORM_FIELD_UPLOADER} from 'pages/admin/updateForm/partials/FormStructure/helper';
import UploadedFilesContent from 'pages/agent/requesterPage/sections/UploadedFilesContent';
import SignaturesContent from 'pages/agent/requesterPage/sections/SignaturesContent';

import {getFieldKeyword} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface PopupInformationItemType {
    formId: number;
    sectionId: number;
    popup: FormPopupTypes;
    item: RequesterCasePopupType;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    popupItem: number;
}

const PopupInformationItem: FC<PopupInformationItemType> = ({
    formId,
    popup,
    item,
    sectionId,
    clientTranslations,
    setClientTranslations,
    showTranslation,
    editTranslation,
    popupItem,
}) => {
    const {fields, title} = popup;

    const {
        requestCase: {
            currentCase: {files, signatures},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const content = item.result && JSON.parse(item.result);
    console.log('SignaturesContent', signatures, popup);

    return (
        <Box sx={{width: '100%', p: 1}}>
            {fields &&
                fields.map((field: FormFieldTypes, index: number) => {
                    const keywordTitle = getFieldKeyword(formId, field.id, 'label');
                    const keywordDescription = getFieldKeyword(formId, field.id, 'description');
                    const value = get(content, `field-${field.id}`, EMPTY_DATA);

                    if (field.type === FORM_FIELD_UPLOADER) {
                        const popupFiles = files.filter(file => file.requesterCaseHasPopupId === item.id);
                        return (
                            <UploadedFilesContent
                                key={`popup-${title}-field-${index}`}
                                formId={formId}
                                field={field}
                                keywordTitle={keywordTitle}
                                keywordDescription={keywordDescription}
                                files={popupFiles}
                                sectionId={sectionId}
                            />
                        );
                    }

                    if (field.type === FORM_FIELD_SIGNATURE) {
                        const popupSignatures = signatures.filter(s => s.requesterCaseHasPopup === item.id);
                        return (
                            <SignaturesContent
                                key={`popup-${title}-field-${index}`}
                                field={field}
                                keywordTitle={keywordTitle}
                                keywordDescription={keywordDescription}
                                sectionId={sectionId}
                                signatures={popupSignatures}
                            />
                        );
                    }

                    if (isSelectionFields(field.type)) {
                        return (
                            <PopupInformationSelectableItemRow
                                key={`popup-${title}-field-${index}`}
                                value={value}
                                formId={formId}
                                field={field}
                                keyword={keywordTitle}
                                popupItemId={item.id}
                            />
                        );
                    }

                    return (
                        <PopupInformationItemRow
                            key={`popup-${title}-field-${index}`}
                            keyword={keywordTitle}
                            value={value}
                            field={field}
                            sectionId={sectionId}
                            clientTranslations={clientTranslations}
                            setClientTranslations={setClientTranslations}
                            showTranslation={showTranslation}
                            editTranslation={editTranslation}
                            popupItem={popupItem}
                        />
                    );
                })}
            <Divider sx={{mt: 1, mb: 1}} />
        </Box>
    );
};

export default PopupInformationItem;
