import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
    GET_REQUESTER_CASE,
    REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE,
    REQUESTER_CASE_CALENDARS_LIST,
    REQUESTER_CASE_BUDGETS_LIST,
    REQUESTER_CASE_SLOTS_LIST,
    REFRESH_CASE_INFORMATION,
    REQUESTER_CASE_TIME_BUCKETS_LIST,
} from 'appRedux/actions/requestCase';
import {GET_REQUESTER_CASE_COMMENTS} from 'appRedux/actions/comments';
import {GET_AGENTS_WITH_CASE_ACCESS} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import CaseKeyContextWrapper from 'contexts/caseKey';
import {AlertContext} from 'contexts/alert/context';

import RequesterPageContent from 'pages/agent/requesterPage/RequesterPageContent';

import {DEFAULT_PAGE} from 'config/index';
import {FORM_TIME_BUCKETS_LIST} from 'appRedux/actions/forms';

const RequesterPage: FC = () => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {
        backTab,
        setBackLink,
        setPageTitle,
        setTopBarTitle,
        setBackLinkLabel,
        setFormPage,
        setFormSection,
        setCustomBoardTitle,
        setTopBarSubtitle,
    } = useContext(RouteContext);

    const [isChatOpened, setIsChatOpened] = useState<boolean>(false);

    const toggleIsChatOpened = () => {
        setIsChatOpened(previous => !previous);
    };

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseComments = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_COMMENTS.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseCalendars = useCallback(
        data => dispatch({type: REQUESTER_CASE_CALENDARS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseBudgets = useCallback(
        data => dispatch({type: REQUESTER_CASE_BUDGETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseSlots = useCallback(
        data => dispatch({type: REQUESTER_CASE_SLOTS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseAttachments = useCallback(
        data => dispatch({type: REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST, payload: data}),
        [dispatch],
    );

    const getAgentsWithCaseAccessList = useCallback(
        data => dispatch({type: GET_AGENTS_WITH_CASE_ACCESS.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseTimeBucketsList = useCallback(
        data => dispatch({type: REQUESTER_CASE_TIME_BUCKETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormTimeBucketsList = useCallback(
        data => dispatch({type: FORM_TIME_BUCKETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const refreshCaseInformation = useCallback(() => dispatch({type: REFRESH_CASE_INFORMATION.REQUEST}), [dispatch]);

    const {isEncryptInfo} = currentCase;

    useEffect(() => {
        getRequesterCaseInfo({id: caseId});
        getRequesterCaseComments({id: caseId});
        getRequesterCaseAttachments({
            id: caseId,
            showAlert,
        });
        getAgentsWithCaseAccessList({
            caseId,
            showAlert,
        });
        getRequesterCaseCalendars({
            id: caseId,
            showAlert,
        });
        getRequesterCaseSlots({
            id: caseId,
            showAlert,
        });
        getRequesterCaseTimeBucketsList({
            id: caseId,
            showAlert,
        });
    }, [caseId]);

    useEffect(() => {
        if (currentCase) {
            getRequesterCaseBudgets({
                id: caseId,
                showAlert,
            });
        }
    }, [currentCase.statusUuid]);

    useEffect(() => {
        return () => {
            setFormPage(DEFAULT_PAGE);
            setFormSection(null);
            setCustomBoardTitle(null);
            refreshCaseInformation();
        };
    }, []);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setTopBarSubtitle('');
        setBackLinkLabel('');
    }, [currentCase, backTab]);

    useEffect(() => {
        if (currentCase?.formId) {
            getFormTimeBucketsList({
                id: currentCase.formId,
                showAlert,
            });
        }
    }, [currentCase.formId]);

    if (isEncryptInfo) {
        return (
            <CaseKeyContextWrapper caseId={Number(caseId)}>
                <RequesterPageContent
                    isChatOpened={isChatOpened}
                    setIsChatOpened={setIsChatOpened}
                    toggleIsChatOpened={toggleIsChatOpened}
                />
            </CaseKeyContextWrapper>
        );
    }
    return (
        <RequesterPageContent
            isChatOpened={isChatOpened}
            setIsChatOpened={setIsChatOpened}
            toggleIsChatOpened={toggleIsChatOpened}
        />
    );
};

export default RequesterPage;
