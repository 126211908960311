import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import {Autocomplete, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import {RequesterCaseTimeBucketFormTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderTimeInput from 'components/AgentScreenComponents/_form/FormBuilderTimeInput';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormBuilderDatePicker from 'components/AgentScreenComponents/_form/FormBuilderDatePicker';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {BucketOptionTypes} from 'pages/agent/requesterPage/timeTracking/EditTimeRecordForm';

interface TimeRecordFormType {
    initialValues: RequesterCaseTimeBucketFormTypes;
    onSubmitAction: (values: RequesterCaseTimeBucketFormTypes, {setErrors}) => void;
    closeModal: () => void;
}

const TimeRecordForm: FC<TimeRecordFormType> = ({initialValues, onSubmitAction, closeModal}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {timeBuckets},
        },
        requestCase: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formBucketsSelection: BucketOptionTypes[] = timeBuckets
        .map(item => ({
            label: item.bucketTitle,
            id: item.bucketId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmitAction}>
            {(formik: FormikProps<RequesterCaseTimeBucketFormTypes>) => {
                return (
                    <Form>
                        {/* <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={formBucketsSelection}
                                value={formBucketsSelection.find(item => item.id === formik.values.bucket)}
                                onChange={(event, value) => {
                                    if (value) {
                                        formik.setFieldValue('bucket', value.id);
                                    }
                                }}
                                autoHighlight
                                renderInput={params => (
                                    <AutoDirectionTextField
                                        name="bucket"
                                        variant="standard"
                                        placeholder={t('orguser.forms.timeTracking.selectTimeBucket')}
                                        required
                                        {...params}
                                    />
                                )}
                            />
                        </FormControl> */}
                        <FormControl fullWidth>
                            <RadioGroup
                                value={formik.values.bucket}
                                onChange={event => {
                                    formik.setFieldValue('bucket', Number(event.target.value));
                                }}
                            >
                                {formBucketsSelection.map(option => (
                                    <FormControlLabel
                                        key={option.id}
                                        value={option.id}
                                        control={<Radio />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth>
                            <Field
                                required
                                name="bucketDate"
                                placeholder={t('orguser.requesterCase.timeTracking.date')}
                                component={FormBuilderDatePicker}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <Field
                                name="duration"
                                required
                                placeholder={t('orguser.requesterCase.timeTracking.workedTime')}
                                component={FormBuilderTimeInput}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Field
                                name="text"
                                placeholder={t('orguser.requesterCase.timeTracking.description')}
                                multiline
                                rows={4}
                                component={FormikTextInput}
                            />
                        </FormControl>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                            <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default TimeRecordForm;
