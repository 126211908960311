import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {FORM_FIELD_POPUP_ADD} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';

import {UPDATE_FIELD_POPUP_KEYWORD} from 'pages/admin/updateForm/partials/constants';
import {getPageByPopupId} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface CreateFormPopupFieldType {
    popupId: number;
    fieldType?: number | null;
    newItemPriority?: number;
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
}

const CreateFormPopupField: FC<CreateFormPopupFieldType> = ({
    popupId,
    fieldType,
    newItemPriority,
    setModalTitle,
    setEntityId,
}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(
        data => dispatch({type: FORM_FIELD_POPUP_ADD.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {pages},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const initialValues = fieldType ? {...formFieldInitialValues, type: fieldType} : formFieldInitialValues;

    const continueEditing = (fieldId: number) => {
        setModalTitle(UPDATE_FIELD_POPUP_KEYWORD);
        setEntityId(fieldId);
    };

    const pageId = getPageByPopupId(pages, popupId);

    if (!pageId) return null;

    return (
        <Box>
            <FieldForm
                pageId={pageId}
                entityId={popupId}
                newItemPriority={newItemPriority}
                initialValues={initialValues}
                onSubmitAction={submitCreateForm}
                continueEditing={continueEditing}
                isPopup
                isCreate
            />
        </Box>
    );
};

export default CreateFormPopupField;
