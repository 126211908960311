import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {RequesterCaseAllItemsListType} from 'appRedux/actions/requestCase/types';
import {REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';

interface DraftAutoRemoveAlertType {
    requestCase: RequesterCaseAllItemsListType;
}

const DraftAutoRemoveAlert: FC<DraftAutoRemoveAlertType> = ({requestCase}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isMobile} = useContext(MediaContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {id, removedAt} = requestCase;

    const toggleRemovingPossibility = useCallback(
        data => dispatch({type: REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.REQUEST, payload: data}),
        [dispatch],
    );

    const onDenyClick = () => {
        toggleRemovingPossibility({
            id,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    if (!removedAt) return null;

    const alertText = t('requester.casesList.draftRemovingAlert').replace(
        '%DATE%',
        convertDateToCurrentLocale(removedAt),
    );

    const explanationText = t('requester.casesList.draftRemovingExplanation').replace(
        '%DATE%',
        convertDateToCurrentLocale(removedAt),
    );

    return (
        <>
            <Button
                sx={{
                    cursor: 'initial',
                    minWidth: 120,
                    minHeight: 40,
                    mr: isMobile ? 0 : 1,
                    p: isMobile ? 1 : 0,
                    pl: isMobile ? 1 : 2,
                    pr: isMobile ? 0.5 : 1,
                    borderRadius: 20,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#A9BBC4FF',
                    backgroundColor: '#E4F6FF',
                    '&:hover': {
                        backgroundColor: '#E4F6FF',
                    },
                }}
            >
                <Typography
                    sx={{
                        textTransform: 'none',
                        color: ERROR_TEXT_COLOR,
                        ml: 1,
                        mr: 1,
                    }}
                >
                    {alertText}
                </Typography>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    onClick={() => setIsOpened(true)}
                >
                    <CloseSvgIcon isActive />
                </IconButton>
            </Button>
            <ModalWrapper
                title={t('requester.casesList.draftRemoving')}
                isShowModal={isOpened}
                toggleModal={toggleModal}
            >
                <Typography>{explanationText}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={() => setIsOpened(false)} />
                    <AgentSaveButton
                        title={t('requester.casesList.denyRemoving')}
                        isSubmit={false}
                        onClick={onDenyClick}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default DraftAutoRemoveAlert;
