import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_BOARD, GET_MENU_BOARDS_LIST} from 'appRedux/actions/workflow';
import {BoardFormItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import BoardGeneralForm from 'components/Forms/BoardForm/BoardGeneralForm';
import {initialValues} from 'components/Forms/BoardForm/validation';

interface CreateBoardFormType {
    setShowUpdateColumnModal: (value: boolean) => void;
    setCreatedBoardId: (value: number) => void;
}

const CreateBoardGeneralForm: FC<CreateBoardFormType> = ({setShowUpdateColumnModal, setCreatedBoardId}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const createBoard = useCallback(data => dispatch({type: CREATE_BOARD.REQUEST, payload: data}), [dispatch]);

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitAction = (values: BoardFormItemTypes, {setErrors}) => {
        createBoard({
            ...values,
            workflow: values.workflow ? values.workflow : null,
            showAlert,
            setErrors,
            callback: (boardId: number) => {
                setShowUpdateColumnModal(true);
                setCreatedBoardId(boardId);
                if (isCaseViewEnabled) {
                    getMenuBoardsList({showAlert});
                }
            },
        });
    };

    return <BoardGeneralForm initialValues={initialValues} onSubmitAction={onSubmitAction} isCreate />;
};

export default CreateBoardGeneralForm;
