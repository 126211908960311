import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import FilterItemWithSubItems from 'components/AgentScreenComponents/_topBar/FilterItemWithSubItems';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

interface FilterMultiplePropsTypes {
    selectedIds: string[];
    optionList: FilterOptionType[];
    handleFilterChange: any;
    filterName?: string;
    filterLabel: string;
    isFormField?: boolean;
}

const FilterMultiple: FC<FilterMultiplePropsTypes> = ({selectedIds, optionList, handleFilterChange, filterLabel}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFilterChange(event);
    };

    const open = Boolean(anchorEl);

    if (optionList.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={filterLabel} onClick={handleClick} open={open} />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClose={handleClose}>
                <FormGroup onChange={handleChange}>
                    {optionList.map((option: FilterOptionType, index: number) => {
                        const subItems = get(option, 'subItems', []);
                        if (subItems.length > 0) {
                            return (
                                <FilterItemWithSubItems
                                    key={`option-${index}`}
                                    option={option}
                                    selectedIds={selectedIds}
                                />
                            );
                        }
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.name}
                                control={<Checkbox checked={selectedIds.includes(option.name)} />}
                                label={t(option.label)}
                            />
                        );
                    })}
                </FormGroup>
            </MenuAnchorWrapper>
        </>
    );
};

export default FilterMultiple;
