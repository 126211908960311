import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AttachAgentToBoardForm from 'components/Forms/BoardForm/AttachAgentToBoardForm';

import {theme} from 'config/theme';

interface AttachAgentToBoardButtonType {
    item: BoardItemTypes;
}

const AttachAgentToBoardButton: FC<AttachAgentToBoardButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showForm, setShowForm] = useState<boolean>(false);

    const toggleShowForm = () => {
        setShowForm(previous => !previous);
    };

    if (showForm) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{width: '90%'}}>
                    <AttachAgentToBoardForm item={item} closeForm={() => setShowForm(false)} />
                </Box>
                <IconButton
                    onClick={toggleShowForm}
                    title={t('orguser.boards.addAgent')}
                    sx={{
                        ml: 2,
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                >
                    <CloseSvgIcon isActive />
                </IconButton>
            </Box>
        );
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.boards.addAgent')}
                icon={<PlusSvgIcon isActive />}
                onClick={toggleShowForm}
            />
        </Box>
    );
};

export default AttachAgentToBoardButton;
