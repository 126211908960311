import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {BoardStatusTypes} from 'appRedux/actions/workflow/types';

import {BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';

interface StatusesListType {
    type: number;
    statuses: BoardStatusTypes[];
}

const StatusesList: FC<StatusesListType> = ({statuses, type}) => {
    const [t] = useTranslation();

    const activeStatuses = statuses ? statuses.filter(item => item.isActive) : [];

    if (activeStatuses.length === 0) {
        return <Typography>{t('common.none')}</Typography>;
    }

    return (
        <>
            {activeStatuses &&
                activeStatuses.map((status: BoardStatusTypes) => {
                    return (
                        <Typography key={`board-${status.uuid}-status-${status.statusType}`} sx={{mb: 0.5}}>
                            {type === BOARD_TYPE_OVERVIEW && (
                                <span style={{fontWeight: 600}}>{`${status.workflowTitle} - `}</span>
                            )}
                            <span style={{fontWeight: 600, marginRight: 5}}>{status.title}</span>
                            {!status.isInitial &&
                                `(${t(
                                    `orguser.workflows.workflowStatusParameters.statusTypes.${String(
                                        status.statusType,
                                    )}`,
                                )})`}
                        </Typography>
                    );
                })}
        </>
    );
};

export default StatusesList;
