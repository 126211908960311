import React, {FC, useContext} from 'react';

import Box from '@mui/material/Box';

import ChatFilesContextWrapper from 'contexts/chatFiles';
import {MediaContext} from 'contexts/media/context';

import ChatMessagesList from 'components/RequesterChat/ChatMessagesList';

const AgentChatPage: FC = () => {
    const {isMobile} = useContext(MediaContext);

    return (
        <Box sx={{px: isMobile ? 0 : 2}}>
            <ChatFilesContextWrapper>
                <ChatMessagesList />
            </ChatFilesContextWrapper>
        </Box>
    );
};

export default AgentChatPage;
