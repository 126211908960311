import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {GET_CUSTOM_ATTRIBUTES_LIST} from 'appRedux/actions/customAttributes';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import ProfileSectionWrapper from 'components/AgentScreenComponents/_wrappers/ProfileSectionWrapper';
import UserInformationForm from 'components/Forms/SettingsForms/UserInformationForm/UserInformationForm';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import DeleteAccountForm from 'components/Forms/SettingsForms/DeleteAccountForm/DeleteAccountForm';

import ExportAccountInformation from 'pages/common/account/export/ExportAccountInformation';
import UpdateEmailPanel from 'pages/common/account/UpdateEmailPanel';
import {getCustomAttributesFilteredList} from 'pages/common/account/helper';
import AdditionalUserInformation from 'pages/common/account/additionalInformation/AdditionalUserInformation';

import {userRoles} from 'config/index';

const AccountSettingsPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode, setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} =
        useContext(RouteContext);
    const {isUserEditEnabled} = useContext(PermissionContext);

    const {
        instance: {customAttributes},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getCustomAttributesList = useCallback(
        data => dispatch({type: GET_CUSTOM_ATTRIBUTES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const isSuperAdmin = profile && profile.roles.includes(userRoles.ROLE_SUPER_ADMIN);
    const isSsoLogin = profile && profile.isSsoLogin;
    const isAnonymous = profile && profile.isAnonymousAccount;
    const isVerified = profile && profile.isVerify;

    useEffect(() => {
        setPageTitle(t('common.menu.account'));
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    useEffect(() => {
        getCustomAttributesList({
            showAlert,
        });
    }, []);

    const customAttributesFiltered = customAttributes
        ? getCustomAttributesFilteredList(customAttributes, isClientMode, isUserEditEnabled)
        : [];

    return (
        <>
            <ProfileSectionWrapper
                identifier="editYourProfileInformation"
                title={t('common.account.editYourProfileInformation')}
                isEditProfile
            >
                <UserInformationForm />
            </ProfileSectionWrapper>
            {customAttributesFiltered.length > 0 && (
                <ClientSectionWrapper
                    toggleIdentifier="editAdditionalInformation"
                    title={t('common.account.additionalInformation')}
                >
                    <AdditionalUserInformation attributes={customAttributesFiltered} />
                </ClientSectionWrapper>
            )}
            {!isSuperAdmin && !isSsoLogin && !isAnonymous && (
                <ProfileSectionWrapper identifier="updateEmail" title={t('common.account.updateEmail')}>
                    <UpdateEmailPanel />
                </ProfileSectionWrapper>
            )}
            {isClientMode && isVerified && (
                <ClientSectionWrapper
                    toggleIdentifier="exportAccountInformation"
                    title={t('common.account.exportAccountInformation.exportAccountInformation')}
                >
                    <ExportAccountInformation />
                </ClientSectionWrapper>
            )}
            {!isSuperAdmin && (
                <ProfileSectionWrapper identifier="deleteAccount" title={t('common.account.deleteAccount')}>
                    <DeleteAccountForm />
                </ProfileSectionWrapper>
            )}
        </>
    );
};

export default AccountSettingsPage;
