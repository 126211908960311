import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseMultipleChangeStatusType} from 'appRedux/actions/admin/types';
import {REQUEST_CASE_CHANGE_STATUS_MULTIPLE} from 'appRedux/actions/admin';
import {FORM_VERSION_STATUSES} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {getCasesStatus} from 'pages/agent/tableView/helper';

import {VIEW_MODE_BOARD} from 'helpers/filter';

import {
    PARAMETER_TAG,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_STATUS,
    PARAMETER_TAB,
    PARAMETER_VIEW_MODE,
    PARAMETER_FIRST_LETTER,
    ITEMS_PER_PAGE_DEFAULT,
} from 'config/index';

interface ChangeStatusMultipleFormType {
    permittedUuids: string[];
    formVersionId: number;
    closeModal: () => void;
    pinnedCasesList?: boolean;
    isTableView?: boolean;
}

const ChangeStatusMultipleForm: FC<ChangeStatusMultipleFormType> = ({
    formVersionId,
    permittedUuids,
    closeModal,
    pinnedCasesList,
    isTableView,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isBoardListCasesPage, isBoardOverviewCasesPage, isSwimlaneCasesPage} = useContext(RouteContext);
    const {selectedIds, setSelectedIds, selectAllFiltered, setIsSelectClicked, setSelectAllFiltered} =
        useContext(AdminContext);

    const {
        admin: {isLoading, requesterCasesNumber, formVersionStatuses},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitMultipleClicked = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormVersionStatuses = useCallback(
        data => dispatch({type: FORM_VERSION_STATUSES.REQUEST, payload: data}),
        [dispatch],
    );

    const pageFromUrl = searchParams.get(PARAMETER_PAGE) ?? DEFAULT_PAGE;
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE) ?? ITEMS_PER_PAGE_DEFAULT;
    const currentTab = searchParams.get(PARAMETER_TAB);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const modeFromUrl = searchParams.get(PARAMETER_VIEW_MODE) ?? VIEW_MODE_BOARD;
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER) ?? '';

    useEffect(() => {
        if (formVersionId) {
            getFormVersionStatuses({
                id: formVersionId,
            });
        }
    }, [formVersionId]);

    if (isLoading) return null;
    return (
        <Box sx={{pr: 2}}>
            <Typography sx={{mb: 2}}>
                {t('orguser.requesterCase.changeMultipleCases').replace(
                    '%NUMBER%',
                    String(selectAllFiltered ? requesterCasesNumber : selectedIds.length),
                )}
            </Typography>
            <Formik
                initialValues={{
                    ids: selectedIds,
                    uuid: '',
                    board: uuid,
                    isAlphabetList: Number(isBoardListCasesPage),
                    isSwimlaneBoard: Number(isSwimlaneCasesPage),
                    isOverviewBoard: Number(isBoardOverviewCasesPage),
                    isPinnedList: Number(pinnedCasesList !== undefined),
                    isTableView: Number(isTableView !== undefined),
                    viewMode: modeFromUrl,
                    firstLetter: firstLetterFromUrl,
                    page: pageFromUrl,
                    per_page: perPageFromUrl,
                    agent: getParameterFromUrl(agentFromUrl),
                    form: getParameterFromUrl(formFromUrl),
                    tags: getParameterFromUrl(tagsFromUrl),
                    search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
                    currentPageStatus: getCasesStatus(statusFromUrl, currentTab),
                }}
                onSubmit={values => {
                    onSubmitMultipleClicked({
                        ...values,
                        callback: () => {
                            closeModal();
                            setSelectAllFiltered(false);
                            setSelectedIds([]);
                            setIsSelectClicked(false);
                        },
                        showAlert,
                    });
                }}
            >
                {(formik: FormikProps<RequesterCaseMultipleChangeStatusType>) => {
                    return (
                        <Form>
                            <FormControl fullWidth>
                                <Select
                                    required
                                    name="uuid"
                                    fullWidth
                                    value={formik.values.status}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    {formVersionStatuses &&
                                        formVersionStatuses.map(item => {
                                            const {uuid, statusType, title, isInitial} = item;
                                            if (permittedUuids.includes(uuid)) {
                                                return (
                                                    <MenuItem key={`status-${uuid}`} value={uuid}>
                                                        {isInitial
                                                            ? t(`orguser.requesterCase.statusTypes.${statusType}`)
                                                            : `${title} (${t(
                                                                  `orguser.requesterCase.statusTypes.${statusType}`,
                                                              )})`}
                                                    </MenuItem>
                                                );
                                            }
                                            return null;
                                        })}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                                <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ChangeStatusMultipleForm;
