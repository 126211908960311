import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form, Formik} from 'formik';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {FormTagType} from 'appRedux/actions/forms/types';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {REQUEST_CASE_CHANGE_TAG_MULTIPLE} from 'appRedux/actions/admin';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import TagChip from 'components/TagChip/TagChip';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {getCasesStatus} from 'pages/agent/tableView/helper';

import {VIEW_MODE_BOARD} from 'helpers/filter';

import {
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_TAB,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_STATUS,
    PARAMETER_SEARCH,
    PARAMETER_VIEW_MODE,
    PARAMETER_FIRST_LETTER,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_AGENT,
    ITEMS_PER_PAGE_DEFAULT,
} from 'config/index';

interface RequesterTagFormType {
    closeModal: () => void;
    pinnedCasesList?: boolean;
    isTableView?: boolean;
}

const CaseTagForm: FC<RequesterTagFormType> = ({closeModal, pinnedCasesList, isTableView}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isBoardListCasesPage, isBoardOverviewCasesPage, isSwimlaneCasesPage} = useContext(RouteContext);

    const {
        requestCase: {isLoading, currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formTags: FormTagType[] = get(currentCase, 'formTags', []);
    const requesterTags: RequesterCaseTagTypes[] = get(currentCase, 'tags', []);

    const pageFromUrl = searchParams.get(PARAMETER_PAGE) ?? DEFAULT_PAGE;
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE) ?? ITEMS_PER_PAGE_DEFAULT;
    const currentTab = searchParams.get(PARAMETER_TAB);
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const optionsFromUrl = searchParams.get(PARAMETER_OPTION);
    const modeFromUrl = searchParams.get(PARAMETER_VIEW_MODE) ?? VIEW_MODE_BOARD;
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER) ?? '';
    const agentFromUrl = searchParams.get(PARAMETER_AGENT) ?? '';

    const commonParameters = {
        ids: [currentCase.id],
        showAlert,
        board: uuid,
        isAlphabetList: Number(isBoardListCasesPage),
        isPinnedList: Number(pinnedCasesList !== undefined),
        isTableView: Number(isTableView !== undefined),
        isSwimlaneBoard: Number(isSwimlaneCasesPage),
        isOverviewBoard: Number(isBoardOverviewCasesPage),
        viewMode: modeFromUrl,
        firstLetter: firstLetterFromUrl,
        page: pageFromUrl,
        per_page: perPageFromUrl,
        agent: getParameterFromUrl(agentFromUrl),
        status: getCasesStatus(statusFromUrl, currentTab),
        form: getParameterFromUrl(formFromUrl),
        tags: getParameterFromUrl(tagsFromUrl),
        options: getParameterFromUrl(optionsFromUrl),
        search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
    };

    const updateTagsToRequesterCases = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const [addTags, setAddTags] = useState<number[]>([]);
    const [deleteTags, setDeleteTags] = useState<number[]>([]);

    const handleTagSelect = (tag?: FormTagType) => {
        setAddTags(prev => {
            if (!tag?.id) return prev;
            return [...prev, tag.id];
        });
        setDeleteTags(prev => {
            return prev.filter(id => id !== tag?.id);
        });
    };

    const handleTagDelete = (tag?: FormTagType) => {
        setDeleteTags(prev => {
            if (!tag?.id) return prev;
            return [...prev, tag.id];
        });
        setAddTags(prev => {
            return prev.filter(id => id !== tag?.id);
        });
    };

    const onSubmitClicked = () => {
        updateTagsToRequesterCases({
            ...commonParameters,
            cases: [currentCase.id],
            addTags,
            deleteTags,
            callback: onClose,
            showAlert,
        });
    };

    const onClose = () => {
        closeModal();
        setAddTags([]);
        setDeleteTags([]);
    };

    if (!isLoading && formTags.length === 0) {
        return <Typography sx={{mt: 1, mb: 1}}>{t('orguser.requesterCase.tags.formWithoutTags')}</Typography>;
    }

    return (
        <Formik initialValues={{}} onSubmit={onSubmitClicked}>
            <Form>
                <FormControl fullWidth>
                    <RadioGroup
                        row
                        sx={{
                            p: 1,
                        }}
                    >
                        {formTags &&
                            formTags.map((item: FormTagType, index: number) => {
                                if (!item.id) return null;

                                const isDeleted = deleteTags.some(id => id === item.id);
                                const isAdded = addTags.some(id => id === item.id);

                                const someSelected = !!requesterTags.find(
                                    requesterItem => requesterItem.tag === item.title,
                                );
                                const allSelected = !!requesterTags.find(
                                    requesterItem => requesterItem.tag === item.title,
                                );

                                const hasValue = (someSelected && !isDeleted) || isAdded;
                                const disabled = (allSelected || isAdded) && !isDeleted;

                                return (
                                    <FormControlLabel
                                        key={`color-${index}`}
                                        value={hasValue}
                                        disabled={disabled}
                                        control={<Radio sx={{mb: 1.5, display: 'none'}} />}
                                        label={
                                            <TagChip
                                                id={String(index)}
                                                title={item.title}
                                                color={item.color}
                                                hasOpacity={disabled}
                                                disabled={disabled}
                                                onClickAction={() => handleTagSelect(item)}
                                                onDelete={hasValue ? () => handleTagDelete(item) : undefined}
                                            />
                                        }
                                    />
                                );
                            })}
                    </RadioGroup>
                </FormControl>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={onClose} />
                    <AgentSaveButton
                        isDisabled={isLoading}
                        title={t('common.buttons.confirm')}
                        onClick={onSubmitClicked}
                    />
                </Box>
            </Form>
        </Formik>
    );
};

export default CaseTagForm;
