import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import CaseKeyContextWrapper from 'contexts/caseKey';
import ClientFormContextWrapper from 'contexts/clientForm';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import ClientForm from 'pages/client/form/ClientForm';

const RequesterCasePage: FC = () => {
    const {requestCase} = useParams();

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <ClientFormContextWrapper>
            <ClientScreenWrapper>
                {isEncryptInfo ? (
                    <CaseKeyContextWrapper caseId={Number(requestCase)}>
                        <ClientForm />
                    </CaseKeyContextWrapper>
                ) : (
                    <ClientForm />
                )}
            </ClientScreenWrapper>
        </ClientFormContextWrapper>
    );
};

export default RequesterCasePage;
