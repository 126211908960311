import {ElementType} from 'react';
import get from 'lodash/get';

import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';

import {userRoles} from 'config/index';

export const getAvatarTitle = (firstName: string | null, lastName: string | null, email: string | null): string => {
    if (firstName && lastName) {
        return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }
    if (email) {
        return `${email[0]}${email[1]}`.toUpperCase();
    }
    return '?';
};

export const getUserName = (profile?: MainProfileResponseTypes): string => {
    const firstName = get(profile, 'firstName', null);
    const lastName = get(profile, 'lastName', null);
    const email = get(profile, 'email', null);
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    if (email) {
        return `${email}`;
    }
    return '?';
};

export const getCurrentRoleLabel = (roles: string[]) => {
    if (roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
        return 'roleSuperAdmin';
    } else if (roles.includes(userRoles.ROLE_AGENT) || roles.includes(userRoles.ROLE_ADMIN)) {
        return 'roleOrgUser';
    }
    return 'roleRequester';
};

export interface MenuContentItemType {
    icon: ElementType;
    label: string;
    path?: string;
    isHidden?: boolean;
    onClickAction: () => void;
}

export const showLinkToChat = (
    isUserVerified: boolean,
    isChatActivatedByAgent: boolean,
    isAgentAssigned: boolean,
    isRequesterFormAgentPage: boolean,
): boolean => {
    return isUserVerified && isAgentAssigned && isChatActivatedByAgent && !isRequesterFormAgentPage;
};
