import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {
    MDXEditor,
    toolbarPlugin,
    imagePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    linkDialogPlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
    BoldItalicUnderlineToggles,
    UndoRedo,
    Separator,
    CreateLink,
    ListsToggle,
    BlockTypeSelect,
    InsertThematicBreak,
    InsertImage,
    MDXEditorMethods,
} from '@mdxeditor/editor';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CodeIcon from '@mui/icons-material/Code';
import {Button, IconButton} from '@mui/material';
import EmptyArea from 'components/EmptyArea';

import CustomMdxEditorButton from 'components/MdxEditorField/CustomMdxEditorButton';
import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';
import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {theme, ERROR_TEXT_COLOR, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface MdxEditorCommonTypes {
    mdxEditorClassName: any;
    key?: string;
    label?: string;
    description?: string;
    notInPopup?: boolean;
    name: string;
    value?: string;
    customButtons?: MdxEditorCustomButtonType[];
    customOnBlur?: () => void;
}

const MdxEditorCommon: FC<MdxEditorCommonTypes> = ({
    mdxEditorClassName,
    key,
    label,
    description,
    name,
    value = '',
    notInPopup,
    customButtons,
    customOnBlur,
}) => {
    const [t] = useTranslation();
    const [field, meta] = useField(name);
    const type = 'text';

    const mdxRef = useRef<MDXEditorMethods>(null);

    const [isSourceMode, setIsSourceMode] = useState(false);

    const handleEditorChange = (value: string) => {
        field.onChange({target: {type, name, value}});
    };

    const handleBlur = () => {
        field.onBlur({target: {name}});
        customOnBlur && customOnBlur();
    };

    useEffect(() => {
        mdxRef.current && mdxRef.current.setMarkdown(value);
    }, [value]);

    const handleSourceModeToggle = () => {
        setIsSourceMode(!isSourceMode);
        if (mdxRef.current) {
            mdxRef.current.setMarkdown(value);
        }
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleEditorChange(e.target?.value || '');
    };

    return (
        <Box sx={{mt: 2, mb: 2}}>
            {label && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 600, mr: 1}}>{`${label}:`}</Typography>
                    {description && <DescriptionPopover text={description} />}
                </Box>
            )}
            <Box
                sx={{
                    borderColor: CLIENT_BACKGROUND_COLOR,
                    backgroundColor: theme.palette.background.default,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 4,
                    p: 1,
                    pt: 2,
                }}
            >
                {isSourceMode ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pb: 1,
                                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                            }}
                        >
                            <IconButton
                                onClick={handleSourceModeToggle}
                                sx={{
                                    backgroundColor: theme.palette.info.main,
                                    border: `1px solid ${theme.palette.info.main}`,
                                    borderRadius: 16,
                                    height: 32,
                                    minWidth: 60,
                                    mr: 0.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.info.main,
                                    },
                                }}
                            >
                                <CodeIcon sx={{color: theme.palette.background.default}} />
                            </IconButton>
                        </Box>
                        <AutoDirectionTextField
                            fullWidth
                            multiline
                            minRows={5}
                            value={value}
                            onChange={handleTextChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            InputProps={{
                                sx: {
                                    fontFamily: 'monospace',
                                    '& fieldset': {border: 'none'},
                                },
                            }}
                        />
                    </>
                ) : (
                    <MDXEditor
                        ref={mdxRef}
                        markdown={value}
                        contentEditableClassName={mdxEditorClassName}
                        plugins={[
                            toolbarPlugin({
                                toolbarContents: () => (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                mb: 1,
                                                '& div': {
                                                    display: 'flex',
                                                },
                                                '& div[role="separator"]': {
                                                    width: 3,
                                                },
                                                '& button': {
                                                    backgroundColor: theme.palette.info.main,
                                                    border: `1px solid ${theme.palette.info.main}`,
                                                    borderRadius: 16,
                                                    height: 32,
                                                    minWidth: 60,
                                                    marginRight: 0.5,
                                                },
                                                '& button[disabled]': {
                                                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                                                    border: `1px solid ${CLIENT_BACKGROUND_COLOR}`,
                                                },
                                                '& button[title="Underline"]': {
                                                    display: 'none',
                                                },
                                                '& g': {
                                                    color: theme.palette.background.default,
                                                },
                                                '& button[disabled] g': {
                                                    color: theme.palette.info.light,
                                                },
                                            }}
                                        >
                                            <UndoRedo />
                                            <Separator />
                                            <BoldItalicUnderlineToggles />
                                            <Separator />
                                            <ListsToggle options={['number', 'bullet']} />
                                            <Separator />
                                            {notInPopup && <BlockTypeSelect />}
                                            <Separator />
                                            {notInPopup && (
                                                <>
                                                    <CreateLink />
                                                    <InsertImage />
                                                    <Separator />
                                                </>
                                            )}
                                            <InsertThematicBreak />
                                            <Separator />
                                            <EmptyArea />
                                            <Button
                                                onClick={handleSourceModeToggle}
                                                sx={{
                                                    backgroundColor: theme.palette.info.main,
                                                    cursor: 'default',
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.info.main,
                                                    },
                                                }}
                                            >
                                                <CodeIcon sx={{color: theme.palette.background.default}} />
                                            </Button>
                                        </Box>
                                        {customButtons && customButtons.length > 0 && (
                                            <Box sx={{display: 'flex', mb: 1}}>
                                                {customButtons.map(
                                                    (customButton: MdxEditorCustomButtonType, index: number) => {
                                                        return (
                                                            <CustomMdxEditorButton
                                                                key={`custom-button-${index}`}
                                                                title={t(customButton.title)}
                                                                markdown={customButton.markdown}
                                                                items={customButton.items ?? []}
                                                                editorRef={mdxRef}
                                                            />
                                                        );
                                                    },
                                                )}
                                            </Box>
                                        )}
                                        <Divider sx={{mt: 1}} />
                                    </Box>
                                ),
                            }),
                            headingsPlugin(),
                            imagePlugin(),
                            listsPlugin(),
                            quotePlugin(),
                            thematicBreakPlugin(),
                            linkPlugin(),
                            linkDialogPlugin(),
                            frontmatterPlugin(),
                            markdownShortcutPlugin(),
                        ]}
                        onChange={handleEditorChange}
                        onBlur={handleBlur}
                    />
                )}
            </Box>
            {meta.touched && meta.error && (
                <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR, mt: 1}}>{t(meta.error)}</Typography>
            )}
        </Box>
    );
};

export default MdxEditorCommon;
