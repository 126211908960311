import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {FORM_CREATE} from 'appRedux/actions/forms';
import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';

import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import ConfigurationForm from 'components/Forms/FormBuilder/ConfigurationForm/Form';
import ConfigurationSubForm from 'components/Forms/FormBuilder/ConfigurationForm/FormSub';
import ConfigurationTicketForm from 'components/Forms/FormBuilder/ConfigurationForm/FormTicket';
import {formInitialValues} from 'components/Forms/FormBuilder/ConfigurationForm/validation';

import {routes} from 'config/index';

interface CreateConfigurationFormType {
    isSubForm: boolean;
    isTicketForm: boolean;
}

const CreateConfigurationForm: FC<CreateConfigurationFormType> = ({isSubForm, isTicketForm}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {toggleIsTokenUpdated} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading, organizationList},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const submitCreateForm = useCallback(data => dispatch({type: FORM_CREATE.REQUEST, payload: data}), [dispatch]);

    const redirectToUpdate = (id: number) => {
        navigate(`${routes.FORM}/${id}/edit`);
    };

    const onSubmitClicked = (values: FormConfigurationRequestTypes) => {
        submitCreateForm({
            ...values,
            callback: (id: number) => {
                redirectToUpdate(id);
                toggleIsTokenUpdated();
            },
        });
    };

    useEffect(() => {
        if (myOrganization) {
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    const currentWorkflow =
        organizationWorkflows && organizationWorkflows.length > 0 ? organizationWorkflows[0].id : null;

    if (!myOrganization || !currentWorkflow) return null;

    return (
        <Grid container>
            <Grid item sm={8}>
                {isSubForm ? (
                    <ConfigurationSubForm
                        initialValues={{
                            ...formInitialValues,
                            isSubForm: true,
                            workflow: currentWorkflow,
                            url: `subForm-${moment().unix()}`,
                            handlePrefix: `subForm-${moment().unix()}`,
                        }}
                        onSubmitClicked={onSubmitClicked}
                        isLoading={isLoading}
                        isCreate
                    />
                ) : isTicketForm ? (
                    <ConfigurationTicketForm
                        initialValues={{
                            ...formInitialValues,
                            isTicket: true,
                            workflow: currentWorkflow,
                            url: `ticket-${moment().unix()}`,
                            handlePrefix: `ticket-${moment().unix()}`,
                        }}
                        onSubmitClicked={onSubmitClicked}
                        isLoading={isLoading}
                        isCreate
                    />
                ) : (
                    <ConfigurationForm
                        initialValues={formInitialValues}
                        onSubmitClicked={onSubmitClicked}
                        isLoading={isLoading}
                        isCreate
                    />
                )}
            </Grid>
            <Grid item sm={4}></Grid>
        </Grid>
    );
};

export default CreateConfigurationForm;
