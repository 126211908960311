import React, {FC, MutableRefObject, useEffect} from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {AgentsWithCaseAccessTypes} from 'appRedux/actions/crypto/types';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

import {theme} from 'config/theme';

interface MentionsListType {
    selectAgent: (value: number) => void;
    commentFieldInput: MutableRefObject<HTMLInputElement | null>;
    currentMenuItem: number;
    topPosition: number | null;
    leftPosition: number | null;
    closeMentions: () => void;
    options: AgentsWithCaseAccessTypes[];
}

const MentionsList: FC<MentionsListType> = ({
    selectAgent,
    currentMenuItem,
    topPosition,
    leftPosition,
    closeMentions,
    options,
}) => {
    useEffect(() => {
        return () => {
            closeMentions();
        };
    }, []);

    return (
        <ClickAwayListener onClickAway={closeMentions}>
            <Box
                sx={{
                    position: 'absolute',
                    top: topPosition,
                    left: leftPosition,
                    boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                    backgroundColor: theme.palette.background.default,
                    mt: 5,
                    ml: 3,
                    p: 0,
                    borderRadius: 2,
                    zIndex: 9999999,
                }}
            >
                {options &&
                    options.map((agent: AgentsWithCaseAccessTypes, key: number) => {
                        const {id, avatarType, avatarLink, firstName, lastName, email} = agent;
                        const avatarTitle = getAvatarTitle(firstName, lastName, email);
                        if (!firstName && !lastName) return null;
                        return (
                            <MenuItem
                                key={email}
                                value={id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    mb: 1,
                                    backgroundColor:
                                        currentMenuItem === key
                                            ? theme.palette.info.main
                                            : theme.palette.background.default,
                                    '& p': {
                                        color:
                                            currentMenuItem === key
                                                ? theme.palette.background.default
                                                : theme.palette.info.main,
                                    },
                                    '&:hover p': {
                                        color: theme.palette.info.main,
                                    },
                                    '& div p': {
                                        color: theme.palette.background.default,
                                    },
                                    '&:hover div p': {
                                        color: theme.palette.background.default,
                                    },
                                }}
                                onClick={() => selectAgent(id)}
                            >
                                <AvatarImage
                                    size={25}
                                    avatarType={avatarType}
                                    avatarLink={avatarLink}
                                    avatarTitle={avatarTitle}
                                />
                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                </Typography>
                            </MenuItem>
                        );
                    })}
            </Box>
        </ClickAwayListener>
    );
};

export default MentionsList;
