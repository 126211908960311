import React, {FC, useCallback, useContext} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CREATE_SUBFORM_REQUEST} from 'appRedux/actions/requestCase';
import {CreateSubFormRequestFormTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {validationSchema} from 'components/Forms/RequesterCase/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';

interface CreateSubFormRequestFormType {
    closeModal: () => void;
}

const CreateSubFormRequestForm: FC<CreateSubFormRequestFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const createSubFormRequest = useCallback(
        data => dispatch({type: CREATE_SUBFORM_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {additionalForms},
        },
        requestCase: {
            isLoading,
            currentCase: {subFormRequests},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSendClicked = (values: CreateSubFormRequestFormTypes) => {
        createSubFormRequest({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                caseId: Number(caseId),
                form: 0,
                text: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSendClicked}
        >
            {(formik: FormikProps<CreateSubFormRequestFormTypes>) => {
                return (
                    <Form>
                        <Field
                            as="select"
                            required
                            name={'form'}
                            label={t('orguser.requesterCase.additionalForms.selectForm')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {additionalForms &&
                                additionalForms.map(item => {
                                    const {id, title, isSubForm} = item;
                                    if (
                                        isSubForm &&
                                        !subFormRequests.some(subFormRequest => subFormRequest.formId === id)
                                    ) {
                                        return (
                                            <MenuItem key={`subForm-form-${id}`} value={id}>
                                                <Typography>{title}</Typography>
                                            </MenuItem>
                                        );
                                    }
                                    return null;
                                })}
                        </Field>
                        <MdxEditorField
                            name={'text'}
                            label={t('orguser.requesterCase.additionalForms.text')}
                            value={''}
                            customButtons={[]}
                        />
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                            <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.send')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateSubFormRequestForm;
