import React, {FC, ChangeEvent, useRef, useEffect, useContext, useState, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import Markdown from 'react-markdown';
import {useParams} from 'react-router-dom';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_OPTION, UPDATE_REQUESTER_CASE_POPUP_OPTION} from 'appRedux/actions/requestCase';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';
import {FieldOptionType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {isRequesterCaseOptionPresented} from 'pages/client/form/fieldTypes/helper';
import CustomOptionTextField from 'pages/client/form/fieldTypes/CustomOptionTextField';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {ignoreHtmlInString, getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

const FormRadio: FC<FormFieldPropsType> = props => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {
        formId,
        isPopup,
        pageId,
        sectionId,
        field,
        clientInformation,
        agentInformation,
        handleChangeInformation,
        onPopupFieldChange,
        popupContent,
        disabled,
        isAgentPage,
        previewMode,
        popupItemId,
        onSaveClicked,
    } = props;

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [previewModeSelectedOption, setPreviewModeSelectedOption] = useState<number | null>(null);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [customSelectedOptionId, setCustomSelectedOptionId] = useState<number | null>(null);

    const updateRequesterCaseOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const updateRequesterCasePopupOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const options = field.fieldOptions || [];
    const keywordsOptions = options.map(opt => getOptionKeyword(formId, opt.id, opt.text ? 'text' : 'title'));
    const translatedOptions = keywordsOptions.map((keyword, i) =>
        getFormTranslatedLabel(translations, keyword, options[i].text || options[i].title),
    );
    const initialValue = getInitialFieldValue(
        pageId,
        sectionId,
        field.id,
        clientInformation,
        agentInformation,
        isAgentPage,
        isPopup,
        popupContent,
    );

    const requesterCaseOptions: RequesterCaseOptionTypes[] = useMemo(() => {
        if (isPopup && popupItemId) {
            const currentItem = currentCase.popupItems.find(item => item.id === popupItemId);
            return currentItem?.options || [];
        } else {
            return get(currentCase, 'options', []);
        }
    }, [currentCase, isPopup, popupItemId]);

    const updateOption = (optionId: number, customAnswer: string | null, customCallback?: () => void) => {
        updateRequesterCaseOption({
            id: Number(requestCase),
            optionId,
            customAnswer,
            showAlert,
            callback: () => {
                setIsInitialRender(false);
                setIsUpdated(previous => !previous);
                customCallback && customCallback();
            },
        });
    };

    const updatePopupOption = (optionId: number, customAnswer: string | null, customCallback?: () => void) => {
        updateRequesterCasePopupOption({
            id: Number(requestCase),
            optionId,
            customAnswer,
            requesterCaseHasPopupId: popupItemId,
            showAlert,
            callback: () => {
                setIsInitialRender(false);
                setIsUpdated(previous => !previous);
                customCallback && customCallback();
            },
        });
    };

    const onClickInPreviewMode = (optionId: number, isCustom: boolean) => {
        setPreviewModeSelectedOption(optionId);
        setIsUpdated(previous => !previous);
        if (isCustom) {
            setCustomSelectedOptionId(optionId);
        } else {
            setCustomSelectedOptionId(null);
        }
    };

    const onRadioSelect = (e: ChangeEvent<{value: string}>, isCustom: boolean) => {
        setErrorField && setErrorField(null);
        const optionId = Number(e.target.value);
        if (previewMode) {
            onClickInPreviewMode(optionId, isCustom);
        } else if (handleChangeInformation) {
            if (isCustom) {
                setIsInitialRender(false);
                setCustomSelectedOptionId(optionId);
            } else {
                updateOption(optionId, null, () => {
                    handleChangeInformation && handleChangeInformation(pageId, sectionId, field.id, e.target.value);
                    setCustomSelectedOptionId(null);
                });
            }
        }
    };

    const handlePopupFieldChange = (e: ChangeEvent<{value: string}>, isCustom: boolean) => {
        setErrorField && setErrorField(null);
        const optionId = Number(e.target.value);
        if (previewMode) {
            onClickInPreviewMode(optionId, isCustom);
        } else if (onPopupFieldChange) {
            if (isCustom) {
                setIsInitialRender(false);
                setCustomSelectedOptionId(optionId);
            } else {
                updatePopupOption(optionId, null, () => {
                    onPopupFieldChange(field.id, e.target.value);
                    setCustomSelectedOptionId(null);
                });
            }
        }
    };

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    const selectedOption = options.find(item => isRequesterCaseOptionPresented(item.id, requesterCaseOptions));
    const showCustomTextArea = (selectedOption && selectedOption.isCustom) || customSelectedOptionId;

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
            hasDivider={!customSelectedOptionId}
        >
            <RadioGroup value={initialValue}>
                {options.map((option: FieldOptionType, index: number) => {
                    const {id, isCustom, text, title} = option;
                    const translatedValue = get(translatedOptions, index, null);
                    const value = !previewMode && translatedValue ? ignoreHtmlInString(translatedValue) : text || title;
                    const isChecked = previewMode
                        ? previewModeSelectedOption === id
                        : isRequesterCaseOptionPresented(id, requesterCaseOptions);
                    return (
                        <>
                            <FormControlLabel
                                key={`radio-${index}`}
                                value={id}
                                disabled={disabled}
                                control={
                                    <Radio
                                        data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-${index}`}
                                        checked={customSelectedOptionId ? customSelectedOptionId === id : isChecked}
                                        onChange={e =>
                                            isPopup ? handlePopupFieldChange(e, isCustom) : onRadioSelect(e, isCustom)
                                        }
                                    />
                                }
                                label={
                                    text ? (
                                        <Markdown>{value}</Markdown>
                                    ) : (
                                        <Typography variant="body1">{value}</Typography>
                                    )
                                }
                            />
                        </>
                    );
                })}
            </RadioGroup>
            {showCustomTextArea && (
                <CustomOptionTextField
                    customSelectedOptionId={customSelectedOptionId || Number(selectedOption && selectedOption.id)}
                    updateOption={updateOption}
                    updatePopupOption={updatePopupOption}
                    {...props}
                />
            )}
        </FormSelectorWrapper>
    );
};

export default FormRadio;
