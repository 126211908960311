import React, {FC, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormLanguageTypes} from 'appRedux/actions/forms/types';

import FormLogo from 'components/StartScreenComponents/FormLogo';
import EmptyArea from 'components/EmptyArea';
import StartScreenWrapper from 'components/StartScreenComponents/StartScreenWrapper';
import FormInformation from 'components/RegistrationForm/FormInformation';
import PrivacyPolice from 'components/RegistrationForm/PrivacyPolice';
import ContentSwitcher from 'components/RegistrationForm/ContentSwitcher';

import NotFound from 'pages/start/404/NotFound';
import LanguageDropdownSwitcher from 'pages/start/login/partials/LanguageDropdownSwitcher';

import {userRoles} from 'config/index';

interface RegistrationFormType {
    isRegistrationByInvite?: boolean;
}

const RegistrationForm: FC<RegistrationFormType> = ({isRegistrationByInvite}) => {
    const [alreadyLogin, setAlreadyLogin] = useState<boolean>(false);

    const {
        auth,
        profile: {profile, isLoading: isLoadingProfile},
        errors: {generalError},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);
        const isTwoFactorCompleted = get(profile, 'isTwoFactorCompleted', false);
        if (isTwoFactorActivated && !isTwoFactorCompleted) {
            return;
        }
        const roles = get(profile, 'roles', []);
        if (profile && roles.length > 0) {
            setAlreadyLogin(true);
        }
    }, [profile]);

    const formBaseInformation = get(auth, 'form', null);

    const availableFormLanguages: FormLanguageTypes[] = get(auth, ['form', 'availableLanguagesList'], []);

    if (
        !formBaseInformation ||
        generalError ||
        (profile?.roles.length && !profile?.roles.includes(userRoles.ROLE_REQUEST))
    ) {
        return auth.isLoading ? null : <NotFound />;
    }

    return (
        <StartScreenWrapper isAuthenticated={alreadyLogin}>
            <FormLogo />
            {availableFormLanguages && availableFormLanguages.length > 1 && (
                <LanguageDropdownSwitcher isFormRegistrationPage isFormInviteRegistration={isRegistrationByInvite} />
            )}
            <EmptyArea />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                }}
            >
                <FormInformation form={formBaseInformation} />
                <ContentSwitcher
                    alreadyLogin={alreadyLogin}
                    isLoading={auth.isLoading || auth.isLoadingKeys || isLoadingProfile}
                    formInformation={formBaseInformation}
                    userId={get(profile, 'id', null)}
                    isRegistrationByInvite={isRegistrationByInvite}
                />
                {formBaseInformation.privacyPolice && <PrivacyPolice form={formBaseInformation} />}
            </Box>
        </StartScreenWrapper>
    );
};

export default RegistrationForm;
