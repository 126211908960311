import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import ReassignImpersonatedCaseForm from 'components/Forms/ImpersonalCaseForm/ReassignImpersonatedCaseForm';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

const HandoverCasePage: FC = () => {
    const [t] = useTranslation();
    const {requestCase} = useParams();

    return (
        <ClientScreenWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    mb: 1,
                }}
            >
                <Typography align="center" sx={{fontSize: 24, fontWeight: 700, mb: 2}}>
                    {t('requester.handover.pageTitle')}
                </Typography>
                <Typography align="center" sx={{mb: 2}}>
                    {t('requester.handover.subtitle')}
                </Typography>
            </Box>
            <ClientSectionWrapper>
                <ReassignImpersonatedCaseForm caseId={Number(requestCase)} />
            </ClientSectionWrapper>
        </ClientScreenWrapper>
    );
};

export default HandoverCasePage;
