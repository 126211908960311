import React, {FC, useState} from 'react';

import Box from '@mui/material/Box';

import {AgentMenuItemTypes} from 'components/AgentScreenComponents/helper';
import MenuBlockItem from 'components/AgentScreenComponents/MenuBlockItem';
import DraggableMenuItem from 'components/AgentScreenComponents/DraggableMenuItem';

import useDeepCompareEffect from 'hooks/useDeepCompareEffect';

interface MenuItemsBlockTypes {
    isMenuCollapsed: boolean;
    items: AgentMenuItemTypes[];
    toggleAdminMenu: (value: string) => void;
    onDragEnd?: (dragItem: string, dropTo: number, clearAll: () => void) => void;
}

const MenuItemsBlock: FC<MenuItemsBlockTypes> = ({items, isMenuCollapsed, toggleAdminMenu, onDragEnd}) => {
    const [dragItem, setDragItem] = useState<string | undefined>();
    const [dropTo, setDropTo] = useState<number | undefined>();

    const [menuItems, setMenuItems] = useState<AgentMenuItemTypes[]>(items);

    useDeepCompareEffect(() => {
        setMenuItems(items);
    }, [items]);

    const handleDragEnd = () => {
        console.log('dragItem', dragItem, dropTo);

        if (typeof dragItem === 'string' && typeof dropTo === 'number') {
            setMenuItems(prev => {
                const currentIndex = prev.findIndex(cf => cf.uuid === dragItem);
                const newArr = [...prev];
                const [movedItem] = newArr.splice(currentIndex, 1);
                newArr.splice(dropTo, 0, movedItem);
                return newArr;
            });

            onDragEnd &&
                onDragEnd(dragItem, dropTo, () => {
                    setDragItem(undefined);
                    setDropTo(undefined);
                });
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                '@media (min-width: 600px)': {
                    mt: 3,
                    flexDirection: 'column',
                },
            }}
        >
            {menuItems.map((menuItem: AgentMenuItemTypes, index: number) => {
                if (menuItem.isHidden) {
                    return null;
                }

                if (onDragEnd && !isMenuCollapsed) {
                    return (
                        <DraggableMenuItem
                            item={menuItem}
                            dragItem={dragItem}
                            setDragItem={setDragItem}
                            dropTo={dropTo}
                            setDropTo={setDropTo}
                            onDragEnd={handleDragEnd}
                            key={`menuItem-${index}`}
                        >
                            <MenuBlockItem
                                isMenuCollapsed={isMenuCollapsed}
                                menuItem={menuItem}
                                toggleAdminMenu={toggleAdminMenu}
                            />
                        </DraggableMenuItem>
                    );
                }

                return (
                    <MenuBlockItem
                        isMenuCollapsed={isMenuCollapsed}
                        menuItem={menuItem}
                        toggleAdminMenu={toggleAdminMenu}
                        key={`menuItem-${index}`}
                    />
                );
            })}
        </Box>
    );
};

export default MenuItemsBlock;
