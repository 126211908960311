import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/system/Box';

import {RootReducer} from 'appRedux/reducers';

import FormLabelFilterItem from 'pages/admin/formsList/FormLabelFilterItem';

const FormLabelFilters: FC = () => {
    const {
        admin: {formLabelsForOrganization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!formLabelsForOrganization || formLabelsForOrganization.length === 0) return null;

    return (
        <Box sx={{mb: 2}}>
            {formLabelsForOrganization.map(item => {
                const {uuid} = item;
                return <FormLabelFilterItem key={`form-filter-${uuid}`} item={item} />;
            })}
        </Box>
    );
};

export default FormLabelFilters;
