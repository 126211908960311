import {requestTypeCreator} from 'helpers/index';

import {
    FormsListRequestType,
    FormsListByOrganizationRequestType,
    FormItemListResponseType,
    FormsListByBoardRequestType,
    FormItemBasic,
    FormParameterTypes,
    FormByCaseRequestType,
    FormChangeStatusType,
    FormTotalInformationTypes,
    FormPageRequestType,
    FormFieldRequestType,
    FormFieldGdprSettingsUpdateRequestType,
    FormFieldMetadataUpdateRequestType,
    FormSectionRequestType,
    FormVersionStatusesRequestType,
    FormVersionStructureRequestType,
    FormVersionStructureCreateType,
    FormVersionStructureUpdateType,
    FormVersionStructureDeactivateType,
    FormVersionStructureRemoveType,
    FormSectionMoveRequestType,
    FormPopupRequestType,
    CommonFormBuilderRequest,
    FormMultipleChangeStatusType,
    FormTranslationRequest,
    FormAddLanguageRequest,
    FormMultipleActionType,
    FormUpdateTranslationRequestType,
    FormMainTranslationsUpdateRequestTypes,
    FormTranslationImportType,
    FormTranslationExportType,
    CommonUploadLogoTypes,
    CommonGetLogoUrlTypes,
    CommonDeleteLogoTypes,
    CommonLogoResponseType,
    FormFieldFilterResponseType,
    FormPauseRequestType,
    FormTagCreateRequestType,
    FormTagType,
    FormSnippetType,
    FormSnippetUpdateRequestType,
    FormSnippetDeleteLogoRequestType,
    FormSnippetUpdateWithLogoRequestType,
    FormPageTypes,
    FormVersionTypes,
    OptionFileCreateResponseType,
    OptionFileUpdateResponseType,
    OptionFileRequestType,
    OptionFileResponseType,
    FieldOptionChangePriorityRequestTypes,
    OptionRelateSectionRequestType,
    OptionRelateSubFormRequestType,
    OptionRelateRequestType,
    FormWorkflowStatusTypes,
    FormTranslationItemTypes,
    FormMainTranslationsType,
    FormAllowListCreateTypes,
    FormAllowListDeleteTypes,
    FormAllowListTypes,
    FormHasAdditionalCreateTypes,
    FormHasAdditionalDeleteTypes,
    FormHasAdditionalAppendTypes,
    FormHasAdditionalTypes,
    FormWorkflowStatusesRequest,
    CurrentFormWorkflowStatusesTypes,
    UpdateFormWorkflowRequestTypes,
    FormFieldMoveRequestType,
    FormPopupMoveRequestType,
    FormsListForRequesterListRequestTypes,
    FormForRequesterItemBasic,
    FormHasLanguageTypes,
    FormFaqListRequestTypes,
    FormFaqItemTypes,
    FormFaqCreateRequestTypes,
    FormFaqUpdateRequestTypes,
    FormFaqDeleteRequestTypes,
    FormFaqChangePriorityRequestTypes,
    ImportOptionsResponseType,
    FormVersionCardCreateType,
    FormVersionCardRequestType,
    FormVersionCardType,
    FormVersionCardFieldCreateType,
    FormGdprFieldsType,
    FormTimeBucketAddRequestTypes,
    FormTimeBucketTypes,
    FormTimeBucketDeleteRequestTypes,
    FormInformationByVersionRequestType,
    FormAnalysisFieldsRequestTypes,
    FormAnalysisFieldTypes,
    FormLabelOrganizationListRequestTypes,
    FormLabelListRequestTypes,
    FormLabelCreateRequestTypes,
    FormLabelUpdateRequestTypes,
    FormLabelAttachRequestTypes,
    FormLabelDetachRequestTypes,
    FormLabelTypes,
} from 'appRedux/actions/forms/types';
import {CommonRequestType} from 'appRedux/actions/profile/types';

export const FORMS_LIST = requestTypeCreator('FORMS_LIST');
export const FORMS_LIST_BY_ORGANIZATION = requestTypeCreator('FORMS_LIST_BY_ORGANIZATION');
export const FORMS_LIST_BY_BOARD = requestTypeCreator('FORMS_LIST_BY_BOARD');

export const FORM_CHANGE_STATUS = requestTypeCreator('FORM_CHANGE_STATUS');
export const FORM_DELETE = requestTypeCreator('FORM_DELETE');
export const FORMS_COPY = requestTypeCreator('FORM_COPY');
export const FORM_CREATE = requestTypeCreator('FORM_CREATE');
export const FORM_UPDATE = requestTypeCreator('FORM_UPDATE');
export const FORM_PAUSE_ON = requestTypeCreator('FORM_PAUSE_ON');
export const FORM_PAUSE_OFF = requestTypeCreator('FORM_PAUSE_OFF');

export const FORM_UPLOAD_LOGO = requestTypeCreator('FORM_UPLOAD_LOGO');
export const FORM_LOGO_GET = requestTypeCreator('FORM_LOGO_GET');
export const FORM_LOGO_DELETE = requestTypeCreator('FORM_LOGO_DELETE');

export const FORM_GDPR_FIELDS_GET = requestTypeCreator('FORM_GDPR_FIELDS_GET');

export const FORM_ANALYSIS_FIELDS_GET = requestTypeCreator('FORM_ANALYSIS_FIELDS_GET');
export const FORM_FILTERS_GET = requestTypeCreator('FORM_FILTERS_GET');
export const FORM_FILTERS_CLEAR = requestTypeCreator('FORM_FILTERS_CLEAR');

export const FORM_MULTIPLE_STATUS_CHANGING = requestTypeCreator('FORM_MULTIPLE_STATUS_CHANGING');

export const FORM_INFORMATION = requestTypeCreator('FORM_INFORMATION');
export const FORM_INFORMATION_BY_VERSION = requestTypeCreator('FORM_INFORMATION_BY_VERSION');
export const FORM_BY_CASE_INFORMATION = requestTypeCreator('FORM_BY_CASE_INFORMATION');

export const FORM_VERSION_STATUSES = requestTypeCreator('FORM_VERSION_STATUSES');
export const FORM_VERSION_STRUCTURE = requestTypeCreator('FORM_VERSION_STRUCTURE');
export const CREATE_FORM_VERSION = requestTypeCreator('CREATE_FORM_VERSION');
export const UPDATE_FORM_VERSION = requestTypeCreator('UPDATE_FORM_VERSION');
export const DEACTIVATE_FORM_VERSION = requestTypeCreator('DEACTIVATE_FORM_VERSION');
export const DELETE_FORM_VERSION = requestTypeCreator('DELETE_FORM_VERSION');

export const FORM_VERSION_CARD_CREATE = requestTypeCreator('FORM_VERSION_CARD_CREATE');
export const FORM_VERSION_CARD_UPDATE = requestTypeCreator('FORM_VERSION_CARD_UPDATE');
export const FORM_VERSION_CARD_DELETE = requestTypeCreator('FORM_VERSION_CARD_DELETE');
export const FORM_VERSION_CARD_GET = requestTypeCreator('FORM_VERSION_CARD_GET');
export const FORM_VERSION_CARDS_GET_ALL = requestTypeCreator('FORM_VERSION_CARDS_GET_ALL');
export const FORM_VERSION_CARD_FIELD_CREATE = requestTypeCreator('FORM_VERSION_CARD_FIELD_CREATE');
export const FORM_VERSION_CARD_FIELD_UPDATE = requestTypeCreator('FORM_VERSION_CARD_FIELD_UPDATE');
export const FORM_VERSION_CARD_FIELD_DELETE = requestTypeCreator('FORM_VERSION_CARD_FIELD_DELETE');

export const FORM_LANGUAGE_ADD = requestTypeCreator('FORM_LANGUAGE_ADD');
export const FORM_LANGUAGE_STATUS = requestTypeCreator('FORM_LANGUAGE_STATUS');
export const FORM_LANGUAGE_DELETE = requestTypeCreator('FORM_LANGUAGE_DELETE');

export const FORM_TRANSLATIONS = requestTypeCreator('FORM_TRANSLATIONS');
export const FORM_MAIN_TRANSLATION_UPDATE = requestTypeCreator('FORM_MAIN_TRANSLATION_UPDATE');
export const FORM_TRANSLATION_UPDATE = requestTypeCreator('FORM_TRANSLATION_UPDATE');
export const FORM_TRANSLATION_EXPORT = requestTypeCreator('FORM_TRANSLATION_EXPORT');
export const FORM_TRANSLATION_IMPORT = requestTypeCreator('FORM_TRANSLATION_IMPORT');
export const FORM_TRANSLATION_AUTO = requestTypeCreator('FORM_TRANSLATION_AUTO');

export const FORM_TAG_LIST = requestTypeCreator('FORM_TAG_LIST');
export const FORM_TAG_CREATE = requestTypeCreator('FORM_TAG_CREATE');
export const FORM_TAG_UPDATE = requestTypeCreator('FORM_TAG_UPDATE');
export const FORM_TAG_DELETE = requestTypeCreator('FORM_TAG_DELETE');

export const FORM_PAGE_ADD = requestTypeCreator('FORM_PAGE_ADD');
export const FORM_PAGE_UPDATE = requestTypeCreator('FORM_PAGE_UPDATE');
export const FORM_PAGE_DELETE = requestTypeCreator('FORM_PAGE_DELETE');
export const FORM_PAGE_COPY = requestTypeCreator('FORM_PAGE_COPY');
export const FORM_PAGE_UP = requestTypeCreator('FORM_PAGE_UP');
export const FORM_PAGE_DOWN = requestTypeCreator('FORM_PAGE_DOWN');

export const FORM_SECTION_ADD = requestTypeCreator('FORM_SECTION_ADD');
export const FORM_SECTION_UPDATE = requestTypeCreator('FORM_SECTION_UPDATE');
export const FORM_SECTION_DELETE = requestTypeCreator('FORM_SECTION_DELETE');
export const FORM_SECTION_COPY = requestTypeCreator('FORM_SECTION_COPY');
export const FORM_SECTION_MOVE = requestTypeCreator('FORM_SECTION_MOVE');
export const FORM_SECTION_UP = requestTypeCreator('FORM_SECTION_UP');
export const FORM_SECTION_DOWN = requestTypeCreator('FORM_SECTION_DOWN');
export const FORM_SECTION_RECALCULATE_PRIORITIES = requestTypeCreator('FORM_SECTION_RECALCULATE_PRIORITIES');

export const FORM_FIELD_ADD = requestTypeCreator('FORM_FIELD_ADD');
export const FORM_FIELD_UPDATE = requestTypeCreator('FORM_FIELD_UPDATE');
export const FORM_FIELD_GDPR_SETTINGS_UPDATE = requestTypeCreator('FORM_FIELD_GDPR_SETTINGS_UPDATE');
export const FORM_FIELD_METADATA_UPDATE = requestTypeCreator('FORM_FIELD_METADATA_UPDATE');
export const FORM_FIELD_DELETE = requestTypeCreator('FORM_FIELD_DELETE');
export const FORM_FIELD_COPY = requestTypeCreator('FORM_FIELD_COPY');
export const FORM_FIELD_MOVE = requestTypeCreator('FORM_FIELD_MOVE');
export const FORM_FIELD_UP = requestTypeCreator('FORM_FIELD_UP');
export const FORM_FIELD_DOWN = requestTypeCreator('FORM_FIELD_DOWN');

export const FORM_FIELD_ADD_RELATED_SECTION = requestTypeCreator('FORM_FIELD_ADD_RELATED_SECTION');
export const FORM_FIELD_REMOVE_RELATED_SECTION = requestTypeCreator('FORM_FIELD_REMOVE_RELATED_SECTION');
export const FORM_FIELD_ADD_RELATED_SUBFORM = requestTypeCreator('FORM_FIELD_ADD_RELATED_SUBFORM');
export const FORM_FIELD_REMOVE_RELATED_SUBFORM = requestTypeCreator('FORM_FIELD_REMOVE_RELATED_SUBFORM');

export const FORM_FIELD_CREATE_OPTION = requestTypeCreator('FORM_FIELD_CREATE_OPTION');
export const FORM_FIELD_UPDATE_OPTION = requestTypeCreator('FORM_FIELD_UPDATE_OPTION');
export const FORM_FIELD_DELETE_OPTION = requestTypeCreator('FORM_FIELD_DELETE_OPTION');
export const FORM_FIELD_CHANGE_OPTION_PRIORITY = requestTypeCreator('FORM_FIELD_CHANGE_OPTION_PRIORITY');
export const FORM_FIELD_CUSTOM_OPTION = requestTypeCreator('FORM_FIELD_CUSTOM_OPTION');
export const FORM_FIELD_IMPORT_OPTIONS = requestTypeCreator('FORM_FIELD_IMPORT_OPTIONS');

export const FORM_POPUP_ADD = requestTypeCreator('FORM_POPUP_ADD');
export const FORM_POPUP_UPDATE = requestTypeCreator('FORM_POPUP_UPDATE');
export const FORM_POPUP_DELETE = requestTypeCreator('FORM_POPUP_DELETE');
export const FORM_POPUP_COPY = requestTypeCreator('FORM_POPUP_COPY');
export const FORM_POPUP_MOVE = requestTypeCreator('FORM_POPUP_MOVE');
export const FORM_POPUP_UP = requestTypeCreator('FORM_POPUP_UP');
export const FORM_POPUP_DOWN = requestTypeCreator('FORM_POPUP_DOWN');

export const FORM_FIELD_POPUP_ADD = requestTypeCreator('FORM_FIELD_POPUP_ADD');

export const GET_FORM_SNIPPET = requestTypeCreator('GET_FORM_SNIPPET');
export const GET_FORM_SNIPPET_CODE = requestTypeCreator('GET_FORM_SNIPPET_CODE');
export const GET_FORM_SNIPPET_LOGO = requestTypeCreator('GET_FORM_SNIPPET_LOGO');
export const UPDATE_FORM_SNIPPET = requestTypeCreator('UPDATE_FORM_SNIPPET');
export const UPDATE_FORM_WITH_LOGO_SNIPPET = requestTypeCreator('UPDATE_FORM_WITH_LOGO_SNIPPET');
export const DELETE_FORM_SNIPPET_LOGO = requestTypeCreator('DELETE_FORM_SNIPPET_LOGO');

export const FORM_ALLOW_LIST_CREATE = requestTypeCreator('FORM_ALLOW_LIST_CREATE');
export const FORM_ALLOW_LIST_DELETE = requestTypeCreator('FORM_ALLOW_LIST_DELETE');

export const FORM_HAS_ADDITIONAL_CREATE = requestTypeCreator('FORM_HAS_ADDITIONAL_CREATE');
export const FORM_HAS_ADDITIONAL_DELETE = requestTypeCreator('FORM_HAS_ADDITIONAL_DELETE');
export const FORM_HAS_ADDITIONAL_APPEND = requestTypeCreator('FORM_HAS_ADDITIONAL_APPEND');

export const FORM_WORKFLOW_GET_STATUSES = requestTypeCreator('FORM_WORKFLOW_GET_STATUSES');
export const FORM_WORKFLOW_UPDATE = requestTypeCreator('FORM_WORKFLOW_UPDATE');

export const FORMS_LIST_FOR_REQUESTER = requestTypeCreator('FORMS_LIST_FOR_REQUESTER');
export const ORGS_LIST_FOR_REQUESTER = requestTypeCreator('ORGS_LIST_FOR_REQUESTER');

export const FORM_FAQ_LIST = requestTypeCreator('FORM_FAQ_LIST');
export const FORM_FAQ_CREATE = requestTypeCreator('FORM_FAQ_CREATE');
export const FORM_FAQ_UPDATE = requestTypeCreator('FORM_FAQ_UPDATE');
export const FORM_FAQ_DELETE = requestTypeCreator('FORM_FAQ_DELETE');
export const FORM_FAQ_PRIORITY = requestTypeCreator('FORM_FAQ_PRIORITY');

export const FORM_TIME_BUCKETS_LIST = requestTypeCreator('FORM_TIME_BUCKETS_LIST');
export const FORM_TIME_BUCKET_ADD = requestTypeCreator('FORM_TIME_BUCKET_ADD');
export const FORM_TIME_BUCKET_DELETE = requestTypeCreator('FORM_TIME_BUCKET_DELETE');

export const FORM_LABEL_ORGANIZATION_LIST = requestTypeCreator('FORM_LABEL_ORGANIZATION_LIST');
export const FORM_LABEL_LIST = requestTypeCreator('FORM_LABEL_LIST');
export const FORM_LABEL_CREATE = requestTypeCreator('FORM_LABEL_CREATE');
export const FORM_LABEL_UPDATE = requestTypeCreator('FORM_LABEL_UPDATE');
export const FORM_LABEL_ATTACH = requestTypeCreator('FORM_LABEL_ATTACH');
export const FORM_LABEL_DETACH = requestTypeCreator('FORM_LABEL_DETACH');

export const formsListInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORMS_LIST.ERROR,
    }),
    request: (payload: FormsListRequestType): IActionType<FormsListRequestType> => ({
        payload,
        type: FORMS_LIST.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORMS_LIST.SUCCESS,
    }),
};

export const formsListByOrganizationInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORMS_LIST_BY_ORGANIZATION.ERROR,
    }),
    request: (payload: FormsListByOrganizationRequestType): IActionType<FormsListByOrganizationRequestType> => ({
        payload,
        type: FORMS_LIST_BY_ORGANIZATION.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORMS_LIST_BY_ORGANIZATION.SUCCESS,
    }),
};

export const formsListByBoardInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORMS_LIST_BY_BOARD.ERROR,
    }),
    request: (payload: FormsListByBoardRequestType): IActionType<FormsListByBoardRequestType> => ({
        payload,
        type: FORMS_LIST_BY_BOARD.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORMS_LIST_BY_BOARD.SUCCESS,
    }),
};

export const formChangeStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_CHANGE_STATUS.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_CHANGE_STATUS.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_CHANGE_STATUS.SUCCESS,
    }),
};

export const formPageUpPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_UP.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_PAGE_UP.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_PAGE_UP.SUCCESS,
    }),
};

export const formPageDownPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_DOWN.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_PAGE_DOWN.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_PAGE_DOWN.SUCCESS,
    }),
};

export const deleteForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_DELETE.ERROR,
    }),
    request: (payload: FormChangeStatusType): IActionType<FormChangeStatusType> => ({
        payload,
        type: FORM_DELETE.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_DELETE.SUCCESS,
    }),
};

export const formPauseOn = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAUSE_ON.ERROR,
    }),
    request: (payload: FormPauseRequestType): IActionType<FormPauseRequestType> => ({
        payload,
        type: FORM_PAUSE_ON.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_PAUSE_ON.SUCCESS,
    }),
};

export const formPauseOff = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAUSE_OFF.ERROR,
    }),
    request: (payload: FormChangeStatusType): IActionType<FormChangeStatusType> => ({
        payload,
        type: FORM_PAUSE_OFF.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_PAUSE_OFF.SUCCESS,
    }),
};

export const getFormGdprFields = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_GDPR_FIELDS_GET.ERROR,
    }),
    request: (payload: FormChangeStatusType): IActionType<FormChangeStatusType> => ({
        payload,
        type: FORM_GDPR_FIELDS_GET.REQUEST,
    }),
    success: (payload: FormGdprFieldsType | null): IActionType<FormGdprFieldsType | null> => ({
        payload,
        type: FORM_GDPR_FIELDS_GET.SUCCESS,
    }),
};

export const getFormAnalysisFields = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_ANALYSIS_FIELDS_GET.ERROR,
    }),
    request: (payload: FormAnalysisFieldsRequestTypes): IActionType<FormAnalysisFieldsRequestTypes> => ({
        payload,
        type: FORM_ANALYSIS_FIELDS_GET.REQUEST,
    }),
    success: (payload: FormAnalysisFieldTypes[]): IActionType<FormAnalysisFieldTypes[]> => ({
        payload,
        type: FORM_ANALYSIS_FIELDS_GET.SUCCESS,
    }),
};

export const getFormFilters = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FILTERS_GET.ERROR,
    }),
    request: (payload: FormChangeStatusType): IActionType<FormChangeStatusType> => ({
        payload,
        type: FORM_FILTERS_GET.REQUEST,
    }),
    success: (payload: FormFieldFilterResponseType): IActionType<FormFieldFilterResponseType> => ({
        payload,
        type: FORM_FILTERS_GET.SUCCESS,
    }),
};

export const clearFormFilters = {
    request: () => ({
        type: FORM_FILTERS_CLEAR.REQUEST,
    }),
};

export const copyForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORMS_COPY.ERROR,
    }),
    request: (payload: FormMultipleActionType): IActionType<FormMultipleActionType> => ({
        payload,
        type: FORMS_COPY.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORMS_COPY.SUCCESS,
    }),
};

export const createForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_CREATE.ERROR,
    }),
    request: (payload: FormParameterTypes): IActionType<FormParameterTypes> => ({
        payload,
        type: FORM_CREATE.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_CREATE.SUCCESS,
    }),
};

export const updateForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_UPDATE.ERROR,
    }),
    request: (payload: FormParameterTypes): IActionType<FormParameterTypes> => ({
        payload,
        type: FORM_UPDATE.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_UPDATE.SUCCESS,
    }),
};

export const multipleFormStatusChanging = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_MULTIPLE_STATUS_CHANGING.ERROR,
    }),
    request: (payload: FormMultipleChangeStatusType): IActionType<FormMultipleChangeStatusType> => ({
        payload,
        type: FORM_MULTIPLE_STATUS_CHANGING.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_MULTIPLE_STATUS_CHANGING.SUCCESS,
    }),
};

export const uploadFormLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_UPLOAD_LOGO.ERROR,
    }),
    request: (payload: CommonUploadLogoTypes): IActionType<CommonUploadLogoTypes> => ({
        payload,
        type: FORM_UPLOAD_LOGO.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_UPLOAD_LOGO.SUCCESS,
    }),
};

export const getFormLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LOGO_GET.ERROR,
    }),
    request: (payload: CommonGetLogoUrlTypes): IActionType<CommonGetLogoUrlTypes> => ({
        payload,
        type: FORM_LOGO_GET.REQUEST,
    }),
    success: (payload: CommonLogoResponseType): IActionType<CommonLogoResponseType> => ({
        payload,
        type: FORM_LOGO_GET.SUCCESS,
    }),
};

export const formLogoDelete = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LOGO_DELETE.ERROR,
    }),
    request: (payload: CommonDeleteLogoTypes): IActionType<CommonDeleteLogoTypes> => ({
        payload,
        type: FORM_LOGO_DELETE.REQUEST,
    }),
    success: (payload: FormItemListResponseType): IActionType<FormItemListResponseType> => ({
        payload,
        type: FORM_LOGO_DELETE.SUCCESS,
    }),
};

export const getFormInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_INFORMATION.ERROR,
    }),
    request: (payload: FormChangeStatusType): IActionType<FormChangeStatusType> => ({
        payload,
        type: FORM_INFORMATION.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_INFORMATION.SUCCESS,
    }),
};

export const getFormInformationByVersion = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_INFORMATION_BY_VERSION.ERROR,
    }),
    request: (payload: FormInformationByVersionRequestType): IActionType<FormInformationByVersionRequestType> => ({
        payload,
        type: FORM_INFORMATION_BY_VERSION.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_INFORMATION_BY_VERSION.SUCCESS,
    }),
};

export const getFormByCaseInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_BY_CASE_INFORMATION.ERROR,
    }),
    request: (payload: FormByCaseRequestType): IActionType<FormByCaseRequestType> => ({
        payload,
        type: FORM_BY_CASE_INFORMATION.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_BY_CASE_INFORMATION.SUCCESS,
    }),
};

export const getFormVersionStatuses = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_STATUSES.ERROR,
    }),
    request: (payload: FormVersionStatusesRequestType): IActionType<FormVersionStatusesRequestType> => ({
        payload,
        type: FORM_VERSION_STATUSES.REQUEST,
    }),
    success: (payload: FormWorkflowStatusTypes[]): IActionType<FormWorkflowStatusTypes[]> => ({
        payload,
        type: FORM_VERSION_STATUSES.SUCCESS,
    }),
};

export const getFormStructure = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_STRUCTURE.ERROR,
    }),
    request: (payload: FormVersionStructureRequestType): IActionType<FormVersionStructureRequestType> => ({
        payload,
        type: FORM_VERSION_STRUCTURE.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_VERSION_STRUCTURE.SUCCESS,
    }),
};

export const createFormStructure = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_FORM_VERSION.ERROR,
    }),
    request: (payload: FormVersionStructureCreateType): IActionType<FormVersionStructureCreateType> => ({
        payload,
        type: CREATE_FORM_VERSION.REQUEST,
    }),
    success: (payload: FormVersionTypes[]): IActionType<FormVersionTypes[]> => ({
        payload,
        type: CREATE_FORM_VERSION.SUCCESS,
    }),
};

export const updateFormStructure = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_FORM_VERSION.ERROR,
    }),
    request: (payload: FormVersionStructureUpdateType): IActionType<FormVersionStructureUpdateType> => ({
        payload,
        type: UPDATE_FORM_VERSION.REQUEST,
    }),
    success: (payload: FormVersionTypes[]): IActionType<FormVersionTypes[]> => ({
        payload,
        type: UPDATE_FORM_VERSION.SUCCESS,
    }),
};

export const deactivateFormStructure = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DEACTIVATE_FORM_VERSION.ERROR,
    }),
    request: (payload: FormVersionStructureDeactivateType): IActionType<FormVersionStructureDeactivateType> => ({
        payload,
        type: DEACTIVATE_FORM_VERSION.REQUEST,
    }),
    success: (payload: FormVersionTypes[]): IActionType<FormVersionTypes[]> => ({
        payload,
        type: DEACTIVATE_FORM_VERSION.SUCCESS,
    }),
};

export const deleteFormStructure = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_FORM_VERSION.ERROR,
    }),
    request: (payload: FormVersionStructureRemoveType): IActionType<FormVersionStructureRemoveType> => ({
        payload,
        type: DELETE_FORM_VERSION.REQUEST,
    }),
    success: (payload: FormVersionTypes[]): IActionType<FormVersionTypes[]> => ({
        payload,
        type: DELETE_FORM_VERSION.SUCCESS,
    }),
};

export const createFormVersionCard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_CREATE.ERROR,
    }),
    request: (payload: FormVersionCardCreateType): IActionType<FormVersionCardCreateType> => ({
        payload,
        type: FORM_VERSION_CARD_CREATE.REQUEST,
    }),
    success: (payload: FormVersionCardType): IActionType<FormVersionCardType> => ({
        payload,
        type: FORM_VERSION_CARD_CREATE.SUCCESS,
    }),
};

export const updateFormVersionCard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_UPDATE.ERROR,
    }),
    request: (payload: FormVersionCardCreateType): IActionType<FormVersionCardCreateType> => ({
        payload,
        type: FORM_VERSION_CARD_UPDATE.REQUEST,
    }),
    success: (payload: FormVersionCardType): IActionType<FormVersionCardType> => ({
        payload,
        type: FORM_VERSION_CARD_UPDATE.SUCCESS,
    }),
};

export const deleteFormVersionCard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_DELETE.ERROR,
    }),
    request: (payload: FormVersionCardRequestType): IActionType<FormVersionCardRequestType> => ({
        payload,
        type: FORM_VERSION_CARD_DELETE.REQUEST,
    }),
    success: () => ({
        type: FORM_VERSION_CARD_DELETE.SUCCESS,
    }),
};

export const getFormVersionCard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_GET.ERROR,
    }),
    request: (payload: FormVersionCardRequestType): IActionType<FormVersionCardRequestType> => ({
        payload,
        type: FORM_VERSION_CARD_GET.REQUEST,
    }),
    success: (payload: FormVersionCardType | null): IActionType<FormVersionCardType | null> => ({
        payload,
        type: FORM_VERSION_CARD_GET.SUCCESS,
    }),
};

export const getAllFormVersionCards = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARDS_GET_ALL.ERROR,
    }),
    request: (payload: CommonRequestType): IActionType<CommonRequestType> => ({
        payload,
        type: FORM_VERSION_CARDS_GET_ALL.REQUEST,
    }),
    success: (payload: FormVersionCardType[]): IActionType<FormVersionCardType[]> => ({
        payload,
        type: FORM_VERSION_CARDS_GET_ALL.SUCCESS,
    }),
};

export const createFormVersionCardField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_CREATE.ERROR,
    }),
    request: (payload: FormVersionCardFieldCreateType): IActionType<FormVersionCardFieldCreateType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_CREATE.REQUEST,
    }),
    success: (payload: FormVersionCardType): IActionType<FormVersionCardType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_CREATE.SUCCESS,
    }),
};

export const updateFormVersionCardField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_UPDATE.ERROR,
    }),
    request: (payload: FormVersionCardFieldCreateType): IActionType<FormVersionCardFieldCreateType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_UPDATE.REQUEST,
    }),
    success: (payload: FormVersionCardType): IActionType<FormVersionCardType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_UPDATE.SUCCESS,
    }),
};

export const deleteFormVersionCardField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_DELETE.ERROR,
    }),
    request: (payload: FormVersionCardRequestType): IActionType<FormVersionCardRequestType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_DELETE.REQUEST,
    }),
    success: (payload: FormVersionCardType): IActionType<FormVersionCardType> => ({
        payload,
        type: FORM_VERSION_CARD_FIELD_DELETE.SUCCESS,
    }),
};

export const addFormLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LANGUAGE_ADD.ERROR,
    }),
    request: (payload: FormAddLanguageRequest): IActionType<FormAddLanguageRequest> => ({
        payload,
        type: FORM_LANGUAGE_ADD.REQUEST,
    }),
    success: (payload: FormHasLanguageTypes[]): IActionType<FormHasLanguageTypes[]> => ({
        payload,
        type: FORM_LANGUAGE_ADD.SUCCESS,
    }),
};

export const changeFormLanguageStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LANGUAGE_STATUS.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_LANGUAGE_STATUS.REQUEST,
    }),
    success: (payload: FormHasLanguageTypes[]): IActionType<FormHasLanguageTypes[]> => ({
        payload,
        type: FORM_LANGUAGE_STATUS.SUCCESS,
    }),
};

export const deleteFormLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LANGUAGE_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_LANGUAGE_DELETE.REQUEST,
    }),
    success: (payload: FormHasLanguageTypes[]): IActionType<FormHasLanguageTypes[]> => ({
        payload,
        type: FORM_LANGUAGE_DELETE.SUCCESS,
    }),
};

export const getFormTranslations = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TRANSLATIONS.ERROR,
    }),
    request: (payload: FormTranslationRequest): IActionType<FormTranslationRequest> => ({
        payload,
        type: FORM_TRANSLATIONS.REQUEST,
    }),
    success: (payload: FormTranslationItemTypes[]): IActionType<FormTranslationItemTypes[]> => ({
        payload,
        type: FORM_TRANSLATIONS.SUCCESS,
    }),
};

export const updateFormTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TRANSLATION_UPDATE.ERROR,
    }),
    request: (payload: FormUpdateTranslationRequestType): IActionType<FormUpdateTranslationRequestType> => ({
        payload,
        type: FORM_TRANSLATION_UPDATE.REQUEST,
    }),
    success: (payload: FormTranslationItemTypes[]): IActionType<FormTranslationItemTypes[]> => ({
        payload,
        type: FORM_TRANSLATION_UPDATE.SUCCESS,
    }),
};

export const updateFormMainTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_MAIN_TRANSLATION_UPDATE.ERROR,
    }),
    request: (
        payload: FormMainTranslationsUpdateRequestTypes,
    ): IActionType<FormMainTranslationsUpdateRequestTypes> => ({
        payload,
        type: FORM_MAIN_TRANSLATION_UPDATE.REQUEST,
    }),
    success: (payload: FormMainTranslationsType): IActionType<FormMainTranslationsType> => ({
        payload,
        type: FORM_MAIN_TRANSLATION_UPDATE.SUCCESS,
    }),
};

export const exportFormTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TRANSLATION_EXPORT.ERROR,
    }),
    request: (payload: FormTranslationExportType): IActionType<FormTranslationExportType> => ({
        payload,
        type: FORM_TRANSLATION_EXPORT.REQUEST,
    }),
    success: () => ({
        type: FORM_TRANSLATION_EXPORT.SUCCESS,
    }),
};

export const importFormTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TRANSLATION_IMPORT.ERROR,
    }),
    request: (payload: FormTranslationImportType): IActionType<FormTranslationExportType> => ({
        payload,
        type: FORM_TRANSLATION_IMPORT.REQUEST,
    }),
    success: () => ({
        type: FORM_TRANSLATION_IMPORT.SUCCESS,
    }),
};

export const autoFormTranslation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TRANSLATION_AUTO.ERROR,
    }),
    request: (payload: FormTranslationRequest): IActionType<FormTranslationRequest> => ({
        payload,
        type: FORM_TRANSLATION_AUTO.REQUEST,
    }),
    success: (payload: FormTranslationItemTypes[]): IActionType<FormTranslationItemTypes[]> => ({
        payload,
        type: FORM_TRANSLATION_AUTO.SUCCESS,
    }),
};

export const addFormPage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_ADD.ERROR,
    }),
    request: (payload: FormPageRequestType): IActionType<FormPageRequestType> => ({
        payload,
        type: FORM_PAGE_ADD.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_PAGE_ADD.SUCCESS,
    }),
};

export const updateFormPage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_UPDATE.ERROR,
    }),
    request: (payload: FormPageRequestType): IActionType<FormPageRequestType> => ({
        payload,
        type: FORM_PAGE_UPDATE.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_PAGE_UPDATE.SUCCESS,
    }),
};

export const deleteFormPage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_PAGE_DELETE.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_PAGE_DELETE.SUCCESS,
    }),
};

export const copyFormPage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_PAGE_COPY.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_PAGE_COPY.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_PAGE_COPY.SUCCESS,
    }),
};

export const addFormSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_ADD.ERROR,
    }),
    request: (payload: FormSectionRequestType): IActionType<FormSectionRequestType> => ({
        payload,
        type: FORM_SECTION_ADD.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_SECTION_ADD.SUCCESS,
    }),
};

export const updateFormSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_UPDATE.ERROR,
    }),
    request: (payload: FormSectionRequestType): IActionType<FormSectionRequestType> => ({
        payload,
        type: FORM_SECTION_UPDATE.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_SECTION_UPDATE.SUCCESS,
    }),
};

export const deleteFormSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_SECTION_DELETE.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_SECTION_DELETE.SUCCESS,
    }),
};

export const copyFormSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_COPY.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_SECTION_COPY.REQUEST,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_SECTION_COPY.SUCCESS,
    }),
};

export const formSectionMoving = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_MOVE.ERROR,
    }),
    request: (payload: FormSectionMoveRequestType) => ({
        type: FORM_SECTION_MOVE.REQUEST,
        payload,
    }),
    success: (payload: FormTotalInformationTypes[]): IActionType<FormTotalInformationTypes[]> => ({
        payload,
        type: FORM_SECTION_MOVE.SUCCESS,
    }),
};

export const formSectionUpPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_UP.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_SECTION_UP.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_SECTION_UP.SUCCESS,
    }),
};

export const formSectionDownPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_DOWN.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_SECTION_DOWN.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_SECTION_DOWN.SUCCESS,
    }),
};

export const formSectionPrioritiesRecalculate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_SECTION_RECALCULATE_PRIORITIES.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_SECTION_RECALCULATE_PRIORITIES.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_SECTION_RECALCULATE_PRIORITIES.SUCCESS,
    }),
};

export const addFormField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_ADD.ERROR,
    }),
    request: (payload: FormFieldRequestType): IActionType<FormFieldRequestType> => ({
        payload,
        type: FORM_FIELD_ADD.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_ADD.SUCCESS,
    }),
};

export const createFormFieldOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_CREATE_OPTION.ERROR,
    }),
    request: (payload: OptionFileCreateResponseType): IActionType<OptionFileCreateResponseType> => ({
        payload,
        type: FORM_FIELD_CREATE_OPTION.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_CREATE_OPTION.SUCCESS,
    }),
};

export const updateFormFieldOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_UPDATE_OPTION.ERROR,
    }),
    request: (payload: OptionFileUpdateResponseType): IActionType<OptionFileUpdateResponseType> => ({
        payload,
        type: FORM_FIELD_UPDATE_OPTION.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_UPDATE_OPTION.SUCCESS,
    }),
};

export const deleteFormFieldOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_DELETE_OPTION.ERROR,
    }),
    request: (payload: OptionFileRequestType): IActionType<OptionFileRequestType> => ({
        payload,
        type: FORM_FIELD_DELETE_OPTION.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_DELETE_OPTION.SUCCESS,
    }),
};

export const changeFormFieldOptionPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_CHANGE_OPTION_PRIORITY.ERROR,
    }),
    request: (payload: FieldOptionChangePriorityRequestTypes): IActionType<FieldOptionChangePriorityRequestTypes> => ({
        payload,
        type: FORM_FIELD_CHANGE_OPTION_PRIORITY.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_CHANGE_OPTION_PRIORITY.SUCCESS,
    }),
};

export const setCustomFormFieldOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_CUSTOM_OPTION.ERROR,
    }),
    request: (payload: OptionFileRequestType): IActionType<OptionFileRequestType> => ({
        payload,
        type: FORM_FIELD_CUSTOM_OPTION.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_CUSTOM_OPTION.SUCCESS,
    }),
};

export const importFormFieldOptions = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_IMPORT_OPTIONS.ERROR,
    }),
    request: (payload: ImportOptionsResponseType): IActionType<ImportOptionsResponseType> => ({
        payload,
        type: FORM_FIELD_IMPORT_OPTIONS.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_IMPORT_OPTIONS.SUCCESS,
    }),
};

export const updateFormField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_UPDATE.ERROR,
    }),
    request: (payload: FormFieldRequestType): IActionType<FormFieldRequestType> => ({
        payload,
        type: FORM_FIELD_UPDATE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_UPDATE.SUCCESS,
    }),
};

export const updateFormFieldGdprSettings = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_GDPR_SETTINGS_UPDATE.ERROR,
    }),
    request: (
        payload: FormFieldGdprSettingsUpdateRequestType,
    ): IActionType<FormFieldGdprSettingsUpdateRequestType> => ({
        payload,
        type: FORM_FIELD_GDPR_SETTINGS_UPDATE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_GDPR_SETTINGS_UPDATE.SUCCESS,
    }),
};

export const updateFormFieldMetadata = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_METADATA_UPDATE.ERROR,
    }),
    request: (payload: FormFieldMetadataUpdateRequestType): IActionType<FormFieldMetadataUpdateRequestType> => ({
        payload,
        type: FORM_FIELD_METADATA_UPDATE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_METADATA_UPDATE.SUCCESS,
    }),
};

export const deleteFormField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_FIELD_DELETE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_DELETE.SUCCESS,
    }),
};

export const copyFormField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_COPY.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_FIELD_COPY.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_COPY.SUCCESS,
    }),
};

export const formFieldMoving = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_MOVE.ERROR,
    }),
    request: (payload: FormFieldMoveRequestType) => ({
        type: FORM_FIELD_MOVE.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_MOVE.SUCCESS,
    }),
};

export const formFieldUpPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_UP.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_FIELD_UP.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_UP.SUCCESS,
    }),
};

export const formFieldDownPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_DOWN.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_FIELD_DOWN.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_DOWN.SUCCESS,
    }),
};

export const formFieldAddRelatedSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_ADD_RELATED_SECTION.ERROR,
    }),
    request: (payload: OptionRelateSectionRequestType) => ({
        type: FORM_FIELD_ADD_RELATED_SECTION.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_ADD_RELATED_SECTION.SUCCESS,
    }),
};

export const formFieldRemoveRelatedSection = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_REMOVE_RELATED_SECTION.ERROR,
    }),
    request: (payload: OptionRelateRequestType) => ({
        type: FORM_FIELD_REMOVE_RELATED_SECTION.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_REMOVE_RELATED_SECTION.SUCCESS,
    }),
};

export const formFieldAddRelatedSubForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_ADD_RELATED_SUBFORM.ERROR,
    }),
    request: (payload: OptionRelateSubFormRequestType) => ({
        type: FORM_FIELD_ADD_RELATED_SUBFORM.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_ADD_RELATED_SUBFORM.SUCCESS,
    }),
};

export const formFieldRemoveRelatedSubForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_REMOVE_RELATED_SUBFORM.ERROR,
    }),
    request: (payload: OptionRelateRequestType) => ({
        type: FORM_FIELD_REMOVE_RELATED_SUBFORM.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_FIELD_REMOVE_RELATED_SUBFORM.SUCCESS,
    }),
};

export const addFormPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_ADD.ERROR,
    }),
    request: (payload: FormPopupRequestType): IActionType<FormPopupRequestType> => ({
        payload,
        type: FORM_POPUP_ADD.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_ADD.SUCCESS,
    }),
};

export const updateFormPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_UPDATE.ERROR,
    }),
    request: (payload: FormPopupRequestType): IActionType<FormPopupRequestType> => ({
        payload,
        type: FORM_POPUP_UPDATE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_UPDATE.SUCCESS,
    }),
};

export const deleteFormPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_POPUP_DELETE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_DELETE.SUCCESS,
    }),
};

export const copyFormPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_COPY.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_POPUP_COPY.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_COPY.SUCCESS,
    }),
};

export const formPopupMoving = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_MOVE.ERROR,
    }),
    request: (payload: FormPopupMoveRequestType) => ({
        type: FORM_POPUP_MOVE.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_MOVE.SUCCESS,
    }),
};

export const formPopupUpPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_UP.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_POPUP_UP.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_UP.SUCCESS,
    }),
};

export const formPopupDownPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_POPUP_DOWN.ERROR,
    }),
    request: (payload: FormChangeStatusType) => ({
        type: FORM_POPUP_DOWN.REQUEST,
        payload,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_POPUP_DOWN.SUCCESS,
    }),
};

export const addFormPopupField = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FIELD_POPUP_ADD.ERROR,
    }),
    request: (payload: FormFieldRequestType): IActionType<FormFieldRequestType> => ({
        payload,
        type: FORM_FIELD_POPUP_ADD.REQUEST,
    }),
    success: (payload: FormPageTypes[]): IActionType<FormPageTypes[]> => ({
        payload,
        type: FORM_FIELD_POPUP_ADD.SUCCESS,
    }),
};

export const getFormTagsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TAG_LIST.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_TAG_LIST.REQUEST,
    }),
    success: (payload: FormTagType[]): IActionType<FormTagType[]> => ({
        payload,
        type: FORM_TAG_LIST.SUCCESS,
    }),
};

export const createFormTag = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TAG_CREATE.ERROR,
    }),
    request: (payload: FormTagCreateRequestType): IActionType<FormTagCreateRequestType> => ({
        payload,
        type: FORM_TAG_CREATE.REQUEST,
    }),
    success: (payload: FormTagType[]): IActionType<FormTagType[]> => ({
        payload,
        type: FORM_TAG_CREATE.SUCCESS,
    }),
};

export const updateFormTag = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TAG_UPDATE.ERROR,
    }),
    request: (payload: FormTagCreateRequestType): IActionType<FormTagCreateRequestType> => ({
        payload,
        type: FORM_TAG_UPDATE.REQUEST,
    }),
    success: (payload: FormTagType[]): IActionType<FormTagType[]> => ({
        payload,
        type: FORM_TAG_UPDATE.SUCCESS,
    }),
};

export const deleteFormTag = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TAG_DELETE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: FORM_TAG_DELETE.REQUEST,
    }),
    success: (payload: FormTagType[]): IActionType<FormTagType[]> => ({
        payload,
        type: FORM_TAG_DELETE.SUCCESS,
    }),
};

export const getFormSnippetLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_FORM_SNIPPET_LOGO.ERROR,
    }),
    request: (payload: CommonGetLogoUrlTypes): IActionType<CommonGetLogoUrlTypes> => ({
        payload,
        type: GET_FORM_SNIPPET_LOGO.REQUEST,
    }),
    success: (payload: CommonLogoResponseType): IActionType<CommonLogoResponseType> => ({
        payload,
        type: GET_FORM_SNIPPET_LOGO.SUCCESS,
    }),
};

export const getFormSnippet = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_FORM_SNIPPET.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: GET_FORM_SNIPPET.REQUEST,
    }),
    success: (payload: FormSnippetType | null): IActionType<FormSnippetType | null> => ({
        payload,
        type: GET_FORM_SNIPPET.SUCCESS,
    }),
};

export const getFormSnippetCode = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_FORM_SNIPPET_CODE.ERROR,
    }),
    request: (payload: CommonFormBuilderRequest): IActionType<CommonFormBuilderRequest> => ({
        payload,
        type: GET_FORM_SNIPPET_CODE.REQUEST,
    }),
    success: () => ({
        type: GET_FORM_SNIPPET_CODE.SUCCESS,
    }),
};

export const updateFormSnippet = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_FORM_SNIPPET.ERROR,
    }),
    request: (payload: FormSnippetUpdateRequestType): IActionType<FormSnippetUpdateRequestType> => ({
        payload,
        type: UPDATE_FORM_SNIPPET.REQUEST,
    }),
    success: (payload: FormSnippetType | null): IActionType<FormSnippetType | null> => ({
        payload,
        type: UPDATE_FORM_SNIPPET.SUCCESS,
    }),
};

export const updateFormWithLogoSnippet = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_FORM_WITH_LOGO_SNIPPET.ERROR,
    }),
    request: (payload: FormSnippetUpdateWithLogoRequestType): IActionType<FormSnippetUpdateWithLogoRequestType> => ({
        payload,
        type: UPDATE_FORM_WITH_LOGO_SNIPPET.REQUEST,
    }),
    success: (payload: FormSnippetType | null): IActionType<FormSnippetType | null> => ({
        payload,
        type: UPDATE_FORM_WITH_LOGO_SNIPPET.SUCCESS,
    }),
};

export const deleteFormSnippetLogo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_FORM_SNIPPET_LOGO.ERROR,
    }),
    request: (payload: FormSnippetDeleteLogoRequestType): IActionType<FormSnippetDeleteLogoRequestType> => ({
        payload,
        type: DELETE_FORM_SNIPPET_LOGO.REQUEST,
    }),
    success: (payload: FormSnippetType | null): IActionType<FormSnippetType | null> => ({
        payload,
        type: DELETE_FORM_SNIPPET_LOGO.SUCCESS,
    }),
};

export const createFormAllowList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_ALLOW_LIST_CREATE.ERROR,
    }),
    request: (payload: FormAllowListCreateTypes): IActionType<FormAllowListCreateTypes> => ({
        payload,
        type: FORM_ALLOW_LIST_CREATE.REQUEST,
    }),
    success: (payload: FormAllowListTypes[]): IActionType<FormAllowListTypes[]> => ({
        payload,
        type: FORM_ALLOW_LIST_CREATE.SUCCESS,
    }),
};

export const deleteFormAllowList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_ALLOW_LIST_DELETE.ERROR,
    }),
    request: (payload: FormAllowListDeleteTypes): IActionType<FormAllowListDeleteTypes> => ({
        payload,
        type: FORM_ALLOW_LIST_DELETE.REQUEST,
    }),
    success: (payload: FormAllowListTypes[]): IActionType<FormAllowListTypes[]> => ({
        payload,
        type: FORM_ALLOW_LIST_DELETE.SUCCESS,
    }),
};

export const createFormHasAdditional = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_CREATE.ERROR,
    }),
    request: (payload: FormHasAdditionalCreateTypes): IActionType<FormHasAdditionalCreateTypes> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_CREATE.REQUEST,
    }),
    success: (payload: FormHasAdditionalTypes[]): IActionType<FormHasAdditionalTypes[]> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_CREATE.SUCCESS,
    }),
};

export const deleteFormHasAdditional = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_DELETE.ERROR,
    }),
    request: (payload: FormHasAdditionalDeleteTypes): IActionType<FormHasAdditionalDeleteTypes> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_DELETE.REQUEST,
    }),
    success: (payload: FormHasAdditionalTypes[]): IActionType<FormHasAdditionalTypes[]> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_DELETE.SUCCESS,
    }),
};

export const appendFormHasAdditional = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_APPEND.ERROR,
    }),
    request: (payload: FormHasAdditionalAppendTypes): IActionType<FormHasAdditionalAppendTypes> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_APPEND.REQUEST,
    }),
    success: (payload: FormHasAdditionalTypes[]): IActionType<FormHasAdditionalTypes[]> => ({
        payload,
        type: FORM_HAS_ADDITIONAL_APPEND.SUCCESS,
    }),
};

export const getFormWorkflowStatuses = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_WORKFLOW_GET_STATUSES.ERROR,
    }),
    request: (payload: FormWorkflowStatusesRequest): IActionType<FormWorkflowStatusesRequest> => ({
        payload,
        type: FORM_WORKFLOW_GET_STATUSES.REQUEST,
    }),
    success: (payload: CurrentFormWorkflowStatusesTypes[]): IActionType<CurrentFormWorkflowStatusesTypes[]> => ({
        payload,
        type: FORM_WORKFLOW_GET_STATUSES.SUCCESS,
    }),
};

export const updateFormWorkflow = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_WORKFLOW_UPDATE.ERROR,
    }),
    request: (payload: UpdateFormWorkflowRequestTypes): IActionType<UpdateFormWorkflowRequestTypes> => ({
        payload,
        type: FORM_WORKFLOW_UPDATE.REQUEST,
    }),
    success: (payload: FormItemBasic[]): IActionType<FormItemBasic[]> => ({
        payload,
        type: FORM_WORKFLOW_UPDATE.SUCCESS,
    }),
};

export const getFormsListForRequester = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORMS_LIST_FOR_REQUESTER.ERROR,
    }),
    request: (payload: FormsListForRequesterListRequestTypes): IActionType<FormsListForRequesterListRequestTypes> => ({
        payload,
        type: FORMS_LIST_FOR_REQUESTER.REQUEST,
    }),
    success: (payload: FormForRequesterItemBasic[]): IActionType<FormForRequesterItemBasic[]> => ({
        payload,
        type: FORMS_LIST_FOR_REQUESTER.SUCCESS,
    }),
};

export const getFormFaqList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FAQ_LIST.ERROR,
    }),
    request: (payload: FormFaqListRequestTypes): IActionType<FormFaqListRequestTypes> => ({
        payload,
        type: FORM_FAQ_LIST.REQUEST,
    }),
    success: (payload: FormFaqItemTypes[]): IActionType<FormFaqItemTypes[]> => ({
        payload,
        type: FORM_FAQ_LIST.SUCCESS,
    }),
};

export const createFormFaq = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FAQ_CREATE.ERROR,
    }),
    request: (payload: FormFaqCreateRequestTypes): IActionType<FormFaqCreateRequestTypes> => ({
        payload,
        type: FORM_FAQ_CREATE.REQUEST,
    }),
    success: (payload: FormFaqItemTypes[]): IActionType<FormFaqItemTypes[]> => ({
        payload,
        type: FORM_FAQ_CREATE.SUCCESS,
    }),
};

export const updateFormFaq = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FAQ_UPDATE.ERROR,
    }),
    request: (payload: FormFaqUpdateRequestTypes): IActionType<FormFaqUpdateRequestTypes> => ({
        payload,
        type: FORM_FAQ_UPDATE.REQUEST,
    }),
    success: (payload: FormFaqItemTypes[]): IActionType<FormFaqItemTypes[]> => ({
        payload,
        type: FORM_FAQ_UPDATE.SUCCESS,
    }),
};

export const deleteFormFaq = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FAQ_DELETE.ERROR,
    }),
    request: (payload: FormFaqDeleteRequestTypes): IActionType<FormFaqDeleteRequestTypes> => ({
        payload,
        type: FORM_FAQ_DELETE.REQUEST,
    }),
    success: (payload: FormFaqItemTypes[]): IActionType<FormFaqItemTypes[]> => ({
        payload,
        type: FORM_FAQ_DELETE.SUCCESS,
    }),
};

export const changeFormFaqPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_FAQ_PRIORITY.ERROR,
    }),
    request: (payload: FormFaqChangePriorityRequestTypes): IActionType<FormFaqChangePriorityRequestTypes> => ({
        payload,
        type: FORM_FAQ_PRIORITY.REQUEST,
    }),
    success: (payload: FormFaqItemTypes[]): IActionType<FormFaqItemTypes[]> => ({
        payload,
        type: FORM_FAQ_PRIORITY.SUCCESS,
    }),
};

export const getFormTimeBucketList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TIME_BUCKETS_LIST.ERROR,
    }),
    request: (payload: FormTimeBucketAddRequestTypes): IActionType<FormTimeBucketAddRequestTypes> => ({
        payload,
        type: FORM_TIME_BUCKETS_LIST.REQUEST,
    }),
    success: (payload: FormTimeBucketTypes[]): IActionType<FormTimeBucketTypes[]> => ({
        payload,
        type: FORM_TIME_BUCKETS_LIST.SUCCESS,
    }),
};

export const addFormTimeBucket = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TIME_BUCKET_ADD.ERROR,
    }),
    request: (payload: FormTimeBucketAddRequestTypes): IActionType<FormTimeBucketAddRequestTypes> => ({
        payload,
        type: FORM_TIME_BUCKET_ADD.REQUEST,
    }),
    success: (payload: FormTimeBucketTypes[]): IActionType<FormTimeBucketTypes[]> => ({
        payload,
        type: FORM_TIME_BUCKET_ADD.SUCCESS,
    }),
};

export const deleteFormTimeBucket = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_TIME_BUCKET_DELETE.ERROR,
    }),
    request: (payload: FormTimeBucketDeleteRequestTypes): IActionType<FormTimeBucketDeleteRequestTypes> => ({
        payload,
        type: FORM_TIME_BUCKET_DELETE.REQUEST,
    }),
    success: (payload: FormTimeBucketTypes[]): IActionType<FormTimeBucketTypes[]> => ({
        payload,
        type: FORM_TIME_BUCKET_DELETE.SUCCESS,
    }),
};

export const getFormLabelsForOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_ORGANIZATION_LIST.ERROR,
    }),
    request: (payload: FormLabelOrganizationListRequestTypes): IActionType<FormLabelOrganizationListRequestTypes> => ({
        payload,
        type: FORM_LABEL_ORGANIZATION_LIST.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_ORGANIZATION_LIST.SUCCESS,
    }),
};

export const getFormLabelsForForm = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_LIST.ERROR,
    }),
    request: (payload: FormLabelListRequestTypes): IActionType<FormLabelListRequestTypes> => ({
        payload,
        type: FORM_LABEL_LIST.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_LIST.SUCCESS,
    }),
};

export const createFormLabel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_CREATE.ERROR,
    }),
    request: (payload: FormLabelCreateRequestTypes): IActionType<FormLabelCreateRequestTypes> => ({
        payload,
        type: FORM_LABEL_CREATE.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_CREATE.SUCCESS,
    }),
};

export const updateFormLabel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_UPDATE.ERROR,
    }),
    request: (payload: FormLabelUpdateRequestTypes): IActionType<FormLabelUpdateRequestTypes> => ({
        payload,
        type: FORM_LABEL_UPDATE.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_UPDATE.SUCCESS,
    }),
};

export const attachFormLabel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_ATTACH.ERROR,
    }),
    request: (payload: FormLabelAttachRequestTypes): IActionType<FormLabelAttachRequestTypes> => ({
        payload,
        type: FORM_LABEL_ATTACH.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_ATTACH.SUCCESS,
    }),
};

export const detachFormLabel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_LABEL_DETACH.ERROR,
    }),
    request: (payload: FormLabelDetachRequestTypes): IActionType<FormLabelDetachRequestTypes> => ({
        payload,
        type: FORM_LABEL_DETACH.REQUEST,
    }),
    success: (payload: FormLabelTypes[]): IActionType<FormLabelTypes[]> => ({
        payload,
        type: FORM_LABEL_DETACH.SUCCESS,
    }),
};
