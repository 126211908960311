import React, {FC, useCallback, useContext} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';
import {AttachGroupToBoardFormTypes} from 'appRedux/actions/workflow/types';
import {GroupItemTypes} from 'appRedux/actions/groups/types';
import {ATTACH_GROUP_TO_BOARD} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';

interface ComponentType {
    item: BoardItemTypes;
    closeForm: () => void;
}

const AttachGroupToBoardForm: FC<ComponentType> = ({item, closeForm}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const {
        groups: {groups},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_GROUP_TO_BOARD.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = (values: AttachGroupToBoardFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const groupsNotAttached = groups
        ? groups.filter(group => !item.groups.some(attachedGroup => Number(attachedGroup.id) === Number(group.id)))
        : [];

    return (
        <Formik
            initialValues={{
                group: 0,
            }}
            enableReinitialize
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<AttachGroupToBoardFormTypes>) => {
                return (
                    <Form>
                        <Field
                            as="select"
                            required
                            name={'group'}
                            options
                            component={FormikSelectInput}
                            fullwidth
                            onChange={e => {
                                formik.handleChange(e);
                                formik.submitForm();
                            }}
                        >
                            {groupsNotAttached &&
                                groupsNotAttached.map((group: GroupItemTypes) => {
                                    const {title, id, uuid} = group;
                                    return (
                                        <MenuItem
                                            key={`group-${uuid}`}
                                            value={id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mb: 1,
                                            }}
                                        >
                                            <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AttachGroupToBoardForm;
