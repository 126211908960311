import React, {FC, Ref} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {WorkflowStatusEventFormTypes, WorkflowTemplateTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {getValidationSchemaType} from 'components/Forms/Workflow/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {
    getCustomButtonsForTemplate,
    getCustomButtonsForWebhook,
} from 'components/Forms/Workflow/workflowStatusEvent/helper';
import TranslationSelector from 'components/Forms/Workflow/workflowStatusEvent/TranslationSelector';
import CodeMirrorField from 'components/CodeMirrorField/CodeMirrorField';

interface WorkflowStatusEventFormType {
    formRef: Ref<any> | null;
    initialValues: WorkflowStatusEventFormTypes;
    onSubmitClicked: (values: WorkflowStatusEventFormTypes) => void;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
    translations: WorkflowTemplateTypes[];
    codeMirrorText: string;
    workflowStatusUuid: string;
    language: number;
    setLanguage: (value: number) => void;
    setCodeMirrorText: (value: string) => void;
}

const WorkflowStatusEventForm: FC<WorkflowStatusEventFormType> = ({
    formRef,
    initialValues,
    onSubmitClicked,
    forEmailTemplate,
    forWebhookTemplate,
    translations,
    codeMirrorText,
    workflowStatusUuid,
    language,
    setLanguage,
    setCodeMirrorText,
}) => {
    const [t] = useTranslation();

    const {
        workflow: {workflowStatusesList, currentWorkflowVariables},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => getValidationSchemaType(t, initialValues.type, forEmailTemplate)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<WorkflowStatusEventFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mt: 2,
                                flexWrap: 'wrap-reverse',
                            }}
                        >
                            {forWebhookTemplate && (
                                <Box sx={{m: 0, minWidth: '300px', flexGrow: 1}}>
                                    <Field
                                        required
                                        name="url"
                                        placeholder={t('orguser.workflows.workflowStatusEventParameters.url')}
                                        component={FormikTextInput}
                                    />
                                </Box>
                            )}
                            {forEmailTemplate && (
                                <Box sx={{m: 0, minWidth: '300px', flexGrow: 1}}>
                                    <Field
                                        required
                                        name="subject"
                                        placeholder={t('orguser.workflows.workflowStatusEventParameters.subject')}
                                        component={FormikTextInput}
                                    />
                                </Box>
                            )}
                            {!forWebhookTemplate && (
                                <Box
                                    sx={{
                                        ml: 'auto',
                                        pl: 3,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <TranslationSelector
                                        translations={translations}
                                        language={initialValues.language}
                                        setLanguage={setLanguage}
                                        forEmailTemplate={forEmailTemplate}
                                    />
                                </Box>
                            )}
                        </Box>
                        {forWebhookTemplate ? (
                            <CodeMirrorField
                                name="text"
                                label={t('orguser.workflows.workflowStatusEventParameters.text')}
                                key={`template-${initialValues.language}`}
                                value={initialValues.text ? initialValues.text : ''}
                                customButtons={getCustomButtonsForWebhook(
                                    workflowStatusesList,
                                    workflowStatusUuid,
                                    currentWorkflowVariables,
                                )}
                                codeMirrorText={codeMirrorText}
                                setCodeMirrorText={setCodeMirrorText}
                            />
                        ) : (
                            <MdxEditorField
                                name="text"
                                key={`template-language-${language}`}
                                label={t('orguser.workflows.workflowStatusEventParameters.text')}
                                value={initialValues.text ? initialValues.text : ''}
                                customButtons={getCustomButtonsForTemplate(currentWorkflowVariables)}
                            />
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusEventForm;
