import React, {FC, useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {RootReducer} from 'appRedux/reducers';

import {BreadcrumbsType, RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import RulesList from 'components/Rules/RulesList';
import {
    ADMIN_MY_ORGANIZATION,
    SUPER_ADMIN_ORGANIZATIONS,
    SUPER_ADMIN_INSTANCE,
    SUPER_ADMIN_FORMS,
    SUPER_ADMIN_GENERAL_TEMPLATES,
    SUPER_ADMIN_LOGIN_ATTEMPTS,
    SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS,
    SUPER_ADMIN_DRAFT_REMOVES,
    SUPER_ADMIN_IMPRINT,
    SUPER_ADMIN_PRIVACY_POLICY,
    CUSTOM_ATTRIBUTES_LIST,
    ROLES_LIST,
    ORG_USERS_LIST,
    GROUPS_LIST,
    WORKFLOWS_LIST,
    INVITES_LIST,
    BOARDS_LIST,
    FORMS_LIST,
    PUBLIC_KEYS_LIST,
    ACCESS_REQUESTS_LIST,
    TEMPLATES_LIST,
    PANELS_LIST,
    DASHBOARDS_LIST,
    ORGANIZATION_POLICY,
    getBreadcrumbs,
    UPDATE_WORKFLOW,
    UPDATE_WORKFLOW_STATUS_EVENTS,
    UPDATE_FORM,
    FORM_VERSION,
    SUPER_ADMIN_ORGANIZATION_DETAILS,
    REQUESTER_CASE_FORM,
    REQUESTER_CASE_ACTIVITY,
    RESOURCE_FIELDS,
    RESOURCE_FIELD_PAGE,
    ORGANIZATION_TOKENS,
    RESOURCE_FIELD_SLOT_CASES_PAGE,
    RESOURCE_FIELD_BUDGET_CASES_PAGE,
    UPDATE_PDF_TEMPLATE,
    PDF_TEMPLATES,
    TIME_TRACKING,
} from 'components/AgentScreenComponents/helper';
import Invites from 'components/Invites/Invites';

import SuperAdminDashboard from 'pages/admin/dashboard/SuperAdminDashboard';
import RequesterPage from 'pages/agent/requesterPage/RequesterPage';
import SecurityPage from 'pages/common/security/SecurityPage';
import AccountSettingsPage from 'pages/common/account/AccountSettingsPage';
import NotFound from 'pages/start/404/NotFound';
import MyOrganization from 'pages/admin/myOrganization/MyOrganization';
import AgentDashboard from 'pages/agent/dashboard/AgentDashboard';
import AgentChatPage from 'pages/agent/chat/AgentChatPage';
import SavedFiltersPage from 'pages/agent/savedFilters/SavedFiltersPage';
import FormsList from 'pages/admin/formsList/FormsList';
import BoardsList from 'pages/admin/boardsList/BoardsList';
import LoginAttempts from 'pages/admin/loginAttempts/LoginAttempts';
import PasswordResetAttempts from 'pages/admin/passwordResetAttempts/PasswordResetAttempts';
import CreateFormComponent from 'pages/admin/createForm/CreateFormComponent';
import UpdatePdfTemplateComponent from 'pages/admin/updatePdfTemplate/UpdatePdfTemplateComponent';
import UpdateFormComponent from 'pages/admin/updateForm/UpdateFormComponent';
import UpdateWorkflowComponent from 'pages/admin/updateWorkflow/UpdateWorkflow';
import UpdateWorkflowStatusEventsComponent from 'pages/admin/updateWorkflowEvents/UpdateWorkflowStatusEventsComponent';
import CreateOrganizationComponent from 'pages/admin/createOrganization/CreateOrganizationComponent';
import UpdateOrganizationComponent from 'pages/admin/updateOrganization/UpdateOrganizationComponent';
import UpdateCaseAccessRequest from 'pages/admin/updateAccessRequest/UpdateAccessRequestComponent';
import InstanceSettings from 'pages/admin/instanceSettings/InstanceSettings';
import ActivityPage from 'pages/agent/activityPage/ActivityPage';
import GeneralTemplates from 'pages/admin/generalTemplate/GeneralTemplates';
import OrgUsers from 'pages/admin/orgUsers/OrgUsers';
import GroupsList from 'pages/admin/groups/GroupsList';
import Roles from 'pages/admin/roles/Roles';
import CreateRole from 'pages/admin/createRole/CreateRole';
import UpdateRole from 'pages/admin/updateRole/UpdateRole';
import OrganizationPublicKeys from 'pages/admin/publicKeys/OrganizationPublicKeys';
import CaseAccessRequestsList from 'pages/admin/accessRequests/CaseAccessRequestsList';
import OrganizationTemplatesList from 'pages/admin/organizationTemplates/OrganizationTemplatesList';
import OrganizationPrivacyPolicy from 'pages/admin/organizationPrivacyPolicy/OrganizationPrivacyPolicy';
import ViewFormComponent from 'pages/admin/viewForm/ViewFormComponent';
import Workflows from 'pages/admin/workflows/Workflows';
import OrganizationsTiles from 'pages/admin/organizations/OrganizationList/OrganizationsTiles';
import BoardCasesComponent from 'pages/agent/blockView/BoardCasesComponent';
import BoardOverviewCasesComponent from 'pages/agent/tableView/RequestersList';
import FormVersionComponent from 'pages/admin/formVersion/FormVersion';
import CustomBoardCasesList from 'pages/agent/listView/CustomBoardCasesList';
import PanelsList from 'pages/admin/analytics/panels/PanelsList';
import DashboardsList from 'pages/admin/analytics/dashboards/DashboardsList';
import ResourceFieldsList from 'pages/admin/resourceFields/ResourceFieldsList';
import UpdateResourceFieldPage from 'pages/admin/updateResourceField/UpdateResourceFieldPage';
import UpdateResourceFieldCalendarPage from 'pages/admin/updateResourceField/calendar/UpdateResourceFieldCalendarPage';
import DashboardWithPanelsPage from 'pages/admin/analytics/dashboardPage/DashboardWithPanelsPage';
import DraftRemoves from 'pages/admin/draftRemoves/DraftRemoves';
import SwimlaneBoardComponent from 'pages/agent/swimlaneView/SwimlaneBoardComponent';
import SlotCasesList from 'pages/admin/updateResourceField/slotCases/SlotCasesList';
import BudgetCasesList from 'pages/admin/updateResourceField/budgetCases/BudgetCasesList';
import OrganizationIntegrationTokens from 'pages/admin/organizationIntegrationTokens/OrganizationIntegrationTokens';
import PdfTemplates from 'pages/admin/organizationPdfTemplates/PdfTemplates';
import CustomAttributesList from 'pages/admin/customAttributes/CustomAttributesList';
import WorkflowFieldVariablesComponent from 'pages/admin/workflowFieldVariables/WorkflowFieldVariablesComponent';
import TimeTracking from 'pages/admin/organizationTimeTracking/TimeTracking';
import TicketCreationPage from 'pages/admin/ticketCreation/TicketCreationPage';
import InteractiveRequestPage from 'pages/agent/interactiveRequest/InteractiveRequestPage';

import {userRoles, RULE_TYPE_IMPRINT, RULE_TYPE_PRIVACY_POLICY, routes, PARAMETER_CURRENT_BOARD} from 'config/index';

const AgentContent: FC = () => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();
    const routerParams = useParams();
    const location = useLocation();

    const {
        currentTab,
        isSuperAdminPage,
        isSuperAdminDashboard,
        isOrganizationCreatePage,
        isOrganizationUpdatePage,
        isRolesListPage,
        isCreateRolePage,
        isUpdateRolePage,
        isAdminDashboard,
        isAgentDashboard,
        isAccountSettingsPage,
        isOrgUserChatPage,
        isAgentSavedFiltersPage,
        isSecurityPage,
        isTicketCreationPage,
        isRequesterCaseInfoPage,
        isRequesterCaseActivityPage,
        isRequesterFormAgentPage,
        isCaseAccessRequestUpdatePage,
        setTopBarTitle,
        setBackLinkLabel,
        setBackTab,
        setBackLink,
        isFormCreatePage,
        isFormUpdatePage,
        isFormVersionPage,
        isFormViewPage,
        isWorkflowEditPage,
        isWorkflowStatusEventsPage,
        isWorkflowVariablesPage,
        isPdfTemplateEditPage,
        isBoardCasesPage,
        isSwimlaneCasesPage,
        isBoardListCasesPage,
        isBoardOverviewCasesPage,
        isDashboardWithPanelsPage,
        setPageTitle,
        setAddLink,
        setPageBreadcrumbs,
        isAgentPage,
        isUpdateResourceFieldPage,
        isUpdateResourceFieldCalendarPage,
        isResourceFieldSlotCasesPage,
        isResourceFieldBudgetCasesPage,
    } = useContext(RouteContext);

    const {isFormEditEnabled} = useContext(PermissionContext);

    const {
        admin: {formsList, formInfo, savedFilters, organizationPdfTemplates},
        profile: {profile},
        requestCase: {currentCase},
        resourceFields: {currentSlotItem, currentBudgetItem},
        workflow: {currentWorkflowStatus, organizationWorkflows, boardsMenu, currentBoard},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        let breadcrumbs: BreadcrumbsType[];
        const currentSearchParams = location.search;

        if (isWorkflowEditPage || isWorkflowVariablesPage) {
            breadcrumbs = getBreadcrumbs(UPDATE_WORKFLOW, {
                isAgentPage,
                isSuperAdminPage,
                workflowName: organizationWorkflows.find(item => item.uuid === routerParams.uuid)?.title,
            });
        } else if (isWorkflowStatusEventsPage) {
            breadcrumbs = getBreadcrumbs(UPDATE_WORKFLOW_STATUS_EVENTS, {
                isSuperAdminPage,
                isAgentPage,
                workflowId: currentWorkflowStatus?.workflowUuid,
                workflowName: organizationWorkflows.find(item => item.uuid === currentWorkflowStatus?.workflowUuid)
                    ?.title,
            });
        } else if (isPdfTemplateEditPage) {
            const currentTemplate = organizationPdfTemplates
                ? organizationPdfTemplates.find(item => item.uuid === routerParams.uuid)
                : null;
            breadcrumbs = getBreadcrumbs(UPDATE_PDF_TEMPLATE, {
                isAgentPage,
                isSuperAdminPage,
                templateTitle: currentTemplate?.title,
            });
        } else if (isFormUpdatePage || isFormViewPage) {
            const currentForm = formsList ? formsList.find(item => item.id === Number(routerParams.id)) : null;
            breadcrumbs = getBreadcrumbs(UPDATE_FORM, {isAgentPage, isSuperAdminPage, formName: currentForm?.title});
        } else if (isFormVersionPage) {
            const currentForm = formsList ? formsList.find(item => item.id === formInfo.id) : null;
            const currentVersion = formInfo?.versions.find(item => item.uuid === routerParams.versionUuid);
            breadcrumbs = getBreadcrumbs(FORM_VERSION, {
                isAgentPage,
                isSuperAdminPage,
                formId: formInfo?.id,
                isFormEditEnabled,
                formName: currentForm?.title,
                versionName: currentVersion?.title,
            });
        } else if (isDashboardWithPanelsPage) {
            breadcrumbs = getBreadcrumbs(PANELS_LIST, {isAgentPage, isSuperAdminPage});
        } else if (isOrganizationCreatePage || isOrganizationUpdatePage) {
            breadcrumbs = getBreadcrumbs(SUPER_ADMIN_ORGANIZATION_DETAILS, {isAgentPage, isSuperAdminPage});
        } else if (isRequesterFormAgentPage) {
            breadcrumbs = getBreadcrumbs(routes.INTERACTIVE_REQUEST, {
                currentCase: currentCase,
                caseSearchParams: currentSearchParams,
            });
        } else if (
            (isRequesterCaseInfoPage || isTicketCreationPage) &&
            searchParams.get(PARAMETER_CURRENT_BOARD) === currentBoard?.id
        ) {
            const board = boardsMenu.find(item => item.uuid === currentBoard?.id);
            const boardParams = `?${currentBoard?.params?.map(([key, val]) => `${key}=${val}`).join('&')}`;
            const boardFilter = savedFilters.find(filter => filter.text === boardParams);
            breadcrumbs = getBreadcrumbs(REQUESTER_CASE_FORM, {
                isAgentPage,
                isSuperAdminPage,
                board,
                boardParams,
                boardFilter,
            });
        } else if (isRequesterCaseActivityPage) {
            breadcrumbs = getBreadcrumbs(REQUESTER_CASE_ACTIVITY, {
                isAgentPage,
                isSuperAdminPage,
                currentCase: currentCase,
                caseSearchParams: currentSearchParams,
            });
        } else if (isUpdateResourceFieldCalendarPage || isUpdateResourceFieldPage) {
            breadcrumbs = getBreadcrumbs(RESOURCE_FIELD_PAGE, {
                isAgentPage,
                isSuperAdminPage,
            });
        } else if (isResourceFieldSlotCasesPage) {
            breadcrumbs = getBreadcrumbs(RESOURCE_FIELD_SLOT_CASES_PAGE, {
                isAgentPage,
                isSuperAdminPage,
                resourceFieldUuid: currentSlotItem?.resourceFieldUuid,
                resourceFieldTitle: currentSlotItem?.resourceFieldTitle,
            });
        } else if (isResourceFieldBudgetCasesPage) {
            breadcrumbs = getBreadcrumbs(RESOURCE_FIELD_BUDGET_CASES_PAGE, {
                isAgentPage,
                isSuperAdminPage,
                resourceFieldUuid: currentBudgetItem?.resourceFieldUuid,
                resourceFieldTitle: currentBudgetItem?.resourceFieldTitle,
            });
        } else {
            breadcrumbs = getBreadcrumbs(currentTab, {isAgentPage, isSuperAdminPage});
        }

        setPageBreadcrumbs(breadcrumbs);
    }, [
        routerParams,
        currentTab,
        isAgentPage,
        isSuperAdminPage,
        isWorkflowEditPage,
        isFormEditEnabled,
        currentWorkflowStatus,
        isFormUpdatePage,
        isFormVersionPage,
        formInfo,
        formsList,
        isDashboardWithPanelsPage,
        isOrganizationCreatePage,
        isOrganizationUpdatePage,
        isRequesterCaseInfoPage,
        isRequesterCaseActivityPage,
        isTicketCreationPage,
        isRequesterFormAgentPage,
        currentBoard,
        boardsMenu,
        currentCase,
        isUpdateResourceFieldPage,
        isUpdateResourceFieldCalendarPage,
        isResourceFieldSlotCasesPage,
        isResourceFieldBudgetCasesPage,
        searchParams,
    ]);

    setAddLink(null);

    if (isAccountSettingsPage) {
        return <AccountSettingsPage />;
    }
    if (isSecurityPage) {
        return <SecurityPage />;
    }
    if (isCreateRolePage) {
        setPageTitle(t('orguser.roles.createRole'));
        return <CreateRole />;
    }
    if (isUpdateRolePage) {
        setPageTitle(t('orguser.roles.updateRole'));
        return <UpdateRole />;
    }
    switch (true) {
        case profile?.roles?.includes(userRoles.ROLE_SUPER_ADMIN):
            if (isOrganizationCreatePage) {
                setPageTitle(t('superadmin.organizations.addNewOrganization'));
                return <CreateOrganizationComponent />;
            } else if (isOrganizationUpdatePage) {
                setPageTitle(t('superadmin.organizations.updateOrganization'));
                return <UpdateOrganizationComponent />;
            } else if (isRolesListPage) {
                setPageTitle(t('common.menu.roles'));
                return <Roles />;
            } else if (isSuperAdminDashboard && !currentTab) {
                setPageTitle(t('common.menu.dashboard'));
                return <SuperAdminDashboard />;
            }
            switch (currentTab) {
                case SUPER_ADMIN_ORGANIZATIONS: {
                    setPageTitle(t('common.menu.organizations'));
                    setAddLink(routes.ADD_ORGANIZATION);
                    return <OrganizationsTiles />;
                }
                case SUPER_ADMIN_INSTANCE: {
                    setPageTitle(t('superadmin.instance.instanceSettings'));
                    return <InstanceSettings />;
                }
                case CUSTOM_ATTRIBUTES_LIST: {
                    setPageTitle(t('common.menu.customAttributes'));
                    return <CustomAttributesList />;
                }
                case SUPER_ADMIN_FORMS: {
                    setPageTitle(t('common.menu.forms'));
                    return <FormsList />;
                }
                case SUPER_ADMIN_GENERAL_TEMPLATES: {
                    setPageTitle(t('common.menu.mailer'));
                    return <GeneralTemplates />;
                }
                case PUBLIC_KEYS_LIST: {
                    setPageTitle(t('common.menu.publicKeys'));
                    return <OrganizationPublicKeys />;
                }
                case SUPER_ADMIN_LOGIN_ATTEMPTS: {
                    setPageTitle(t('common.menu.loginAttempts'));
                    return <LoginAttempts />;
                }
                case SUPER_ADMIN_PASSWORD_RESET_ATTEMPTS: {
                    setPageTitle(t('common.menu.passwordResets'));
                    return <PasswordResetAttempts />;
                }
                case SUPER_ADMIN_DRAFT_REMOVES: {
                    setPageTitle(t('common.menu.draftRemoves'));
                    return <DraftRemoves />;
                }
                case SUPER_ADMIN_IMPRINT: {
                    return <RulesList type={RULE_TYPE_IMPRINT} />;
                }
                case SUPER_ADMIN_PRIVACY_POLICY: {
                    return <RulesList type={RULE_TYPE_PRIVACY_POLICY} />;
                }
            }
            return <NotFound />;

        case profile?.roles?.includes(userRoles.ROLE_ADMIN):
        case profile?.roles?.includes(userRoles.ROLE_AGENT):
            if (isBoardListCasesPage) {
                setBackTab('');
                setPageTitle('');
                return <CustomBoardCasesList />;
            }
            if (isBoardOverviewCasesPage) {
                setBackTab('');
                setPageTitle('');
                return <BoardOverviewCasesComponent />;
            }
            if (isBoardCasesPage) {
                setBackTab('');
                setPageTitle('');
                return <BoardCasesComponent />;
            }
            if (isSwimlaneCasesPage) {
                setBackTab('');
                setPageTitle('');
                return <SwimlaneBoardComponent />;
            }
            if (isFormCreatePage) {
                return <CreateFormComponent />;
            }
            if (isFormUpdatePage) {
                return <UpdateFormComponent />;
            }
            if (isFormVersionPage) {
                return <FormVersionComponent />;
            }
            if (isFormViewPage) {
                setPageTitle(t('orguser.forms.viewForm'));
                return <ViewFormComponent />;
            }
            if (isWorkflowEditPage) {
                setPageTitle(t('orguser.workflows.updateWorkflow'));
                return <UpdateWorkflowComponent />;
            }
            if (isWorkflowStatusEventsPage) {
                setPageTitle(t('orguser.workflows.updateWorkflowStatusEvents'));
                return <UpdateWorkflowStatusEventsComponent />;
            }
            if (isWorkflowVariablesPage) {
                setPageTitle(t('orguser.fieldVariables.pageTitle'));
                return <WorkflowFieldVariablesComponent />;
            }
            if (isPdfTemplateEditPage) {
                setPageTitle(t('superadmin.organizations.pdfTemplates.updatePdfTemplate'));
                return <UpdatePdfTemplateComponent />;
            }
            if (isCaseAccessRequestUpdatePage) {
                setPageTitle(t('orguser.caseAccessRequests.updateCaseAccessRequest'));
                return <UpdateCaseAccessRequest />;
            }
            if ((isAdminDashboard || isAgentDashboard) && !currentTab) {
                setPageTitle(t('common.menu.dashboard'));
                return <AgentDashboard />;
            }
            if (isTicketCreationPage) {
                return <TicketCreationPage />;
            }
            if (isRequesterFormAgentPage) {
                return <InteractiveRequestPage />;
            }
            if (isRequesterCaseInfoPage) {
                return <RequesterPage />;
            }
            if (isRequesterCaseActivityPage) {
                return <ActivityPage />;
            }
            if (isAgentSavedFiltersPage) {
                return <SavedFiltersPage />;
            }
            if (isOrgUserChatPage) {
                setTopBarTitle(t('common.menu.messages'));
                setBackLinkLabel('');
                setBackLink('');
                setBackTab('');
                setPageTitle('');
                return <AgentChatPage />;
            }
            if (isDashboardWithPanelsPage) {
                setBackLinkLabel('');
                setBackLink('');
                setBackTab('');
                return <DashboardWithPanelsPage />;
            }
            if (isUpdateResourceFieldCalendarPage) {
                setPageTitle(
                    `${t('orguser.resourceFields.updateResourceField')} (${t(
                        'orguser.resourceFields.types.calendar',
                    )})`,
                );
                return <UpdateResourceFieldCalendarPage />;
            }
            if (isResourceFieldSlotCasesPage) {
                setPageTitle(t('orguser.resourceFields.casesList'));
                return <SlotCasesList />;
            }
            if (isResourceFieldBudgetCasesPage) {
                setPageTitle(t('orguser.resourceFields.casesList'));
                return <BudgetCasesList />;
            }
            if (isUpdateResourceFieldPage) {
                setPageTitle(t('orguser.resourceFields.updateResourceField'));
                return <UpdateResourceFieldPage />;
            }
            switch (currentTab) {
                case ADMIN_MY_ORGANIZATION: {
                    setPageTitle(t('common.menu.myOrganization'));
                    return <MyOrganization />;
                }
                case ORG_USERS_LIST: {
                    setPageTitle(t('common.menu.organizationUsers'));
                    return <OrgUsers />;
                }
                case CUSTOM_ATTRIBUTES_LIST: {
                    setPageTitle(t('common.menu.customAttributes'));
                    return <CustomAttributesList />;
                }
                case GROUPS_LIST: {
                    setPageTitle(t('common.menu.groups'));
                    return <GroupsList />;
                }
                case ORGANIZATION_POLICY: {
                    setPageTitle(t('common.menu.privacyPolicy'));
                    return <OrganizationPrivacyPolicy />;
                }
                case WORKFLOWS_LIST: {
                    setPageTitle(t('common.menu.workflows'));
                    return <Workflows />;
                }
                case ROLES_LIST: {
                    setPageTitle(t('common.menu.roles'));
                    return <Roles />;
                }
                case FORMS_LIST: {
                    setPageTitle(t('common.menu.forms'));
                    return <FormsList />;
                }
                case BOARDS_LIST: {
                    setPageTitle(t('common.menu.boards'));
                    return <BoardsList />;
                }
                case PUBLIC_KEYS_LIST: {
                    setPageTitle(t('common.menu.publicKeys'));
                    return <OrganizationPublicKeys />;
                }
                case ACCESS_REQUESTS_LIST: {
                    setPageTitle(t('common.menu.accessRequests'));
                    return <CaseAccessRequestsList />;
                }
                case TEMPLATES_LIST: {
                    setPageTitle(t('common.menu.mailer'));
                    return <OrganizationTemplatesList />;
                }
                case ORGANIZATION_TOKENS: {
                    setPageTitle(t('common.menu.organizationTokens'));
                    return <OrganizationIntegrationTokens />;
                }
                case INVITES_LIST: {
                    setPageTitle(t('common.menu.invites'));
                    return <Invites />;
                }
                case PANELS_LIST: {
                    setPageTitle(t('common.menu.panels'));
                    return <PanelsList />;
                }
                case DASHBOARDS_LIST: {
                    setPageTitle(t('common.menu.dashboards'));
                    return <DashboardsList />;
                }
                case RESOURCE_FIELDS: {
                    setPageTitle(t('common.menu.resourceFields'));
                    return <ResourceFieldsList />;
                }
                case PDF_TEMPLATES: {
                    setPageTitle(t('common.menu.pdfTemplates'));
                    return <PdfTemplates />;
                }
                case TIME_TRACKING: {
                    setPageTitle(t('common.menu.timeTracking'));
                    return <TimeTracking />;
                }
            }
            return <NotFound />;

        default:
            if (profile) {
                return <NotFound />;
            }
    }
    return <NotFound />;
};

export default AgentContent;
