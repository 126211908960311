import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextWithShowAll from 'components/TextWithShowAll/TextWithShowAll';

interface ComponentType {
    label: string;
    value: string | number | null;
    isText?: boolean;
}

const ImpersonatedCaseDetailsItem: FC<ComponentType> = ({label, value, isText}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Typography sx={{fontSize: 12, fontWeight: 700, mr: 1}}>{`${t(label)}:`}</Typography>
            {isText ? (
                <TextWithShowAll text={String(value)} fontSize={12} />
            ) : (
                <Typography sx={{fontSize: 12}}>{value ? value : t('common.none')}</Typography>
            )}
        </Box>
    );
};

export default ImpersonatedCaseDetailsItem;
