import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';

import FiltersBlock from 'components/AdminScreenComponents/FiltersBlock';
import Search from 'components/AdminScreenComponents/Search';
import {TableFilterTypes} from 'components/AdminScreenComponents/types';
import {ItemsListTypes} from 'components/AdminScreenComponents/translationsHelper';
import CommonCheckbox from 'components/AdminScreenComponents/CommonCheckbox';

import {PARAMETER_PER_PAGE, ITEMS_PER_PAGE_OPTIONS_DEFAULT, PARAMETER_PAGE, DEFAULT_PAGE} from 'config/index';

interface ItemsNumberSelectorType {
    itemsList: ItemsListTypes;
    currentPage: number;
    pagesNumber: number;
    itemsPerPage: number;
    itemsPerPageOptions?: number[];
    setCurrentPageValue: (value: number) => void;
    hasSearch?: boolean;
    hasCheckboxes?: boolean;
    hasSelectAllFiltered?: boolean;
    filters?: TableFilterTypes[];
    selectAllCheckboxes?: () => void;
    leftSideComponent?: ReactNode;
    showClearAll?: boolean;
}

const ItemsNumberSelector: FC<ItemsNumberSelectorType> = ({
    itemsList,
    itemsPerPage,
    filters,
    hasSearch,
    hasCheckboxes,
    hasSelectAllFiltered,
    itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    selectAllCheckboxes,
    setCurrentPageValue,
    leftSideComponent,
    showClearAll,
}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (event: SelectChangeEvent): void => {
        const value = event.target.value;
        setCurrentPageValue(DEFAULT_PAGE);
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        searchParams.set(PARAMETER_PER_PAGE, value);
        setSearchParams(searchParams);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
            }}
        >
            <Grid container>
                <Grid
                    item
                    sm={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {hasCheckboxes && itemsList && (
                        <CommonCheckbox
                            itemsNumber={itemsList.length}
                            selectAllCheckboxes={selectAllCheckboxes}
                            hasSelectAllFiltered={hasSelectAllFiltered}
                        />
                    )}
                    {leftSideComponent}
                    {hasSearch && <Search />}
                </Grid>
                <Grid item sm={8}>
                    {filters && (
                        <FiltersBlock
                            filters={filters}
                            setCurrentPageValue={setCurrentPageValue}
                            showClearAll={showClearAll}
                        />
                    )}
                </Grid>
                <Grid item sm={1} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pt: 2}}>
                    <FormControl variant="standard" sx={{width: 70, pb: 2}}>
                        <InputLabel>{t('common.perPage')}</InputLabel>
                        <Select value={String(itemsPerPage)} onChange={handleChange} IconComponent={ExpandMoreIcon}>
                            {itemsPerPageOptions.map((value: number, index: number) => {
                                return (
                                    <MenuItem key={`paginationItemsNumberOption-${index}`} value={value}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ItemsNumberSelector;
