import {
    INVITES_GET_LIST,
    INVITES_GET_USER_LIST,
    INVITE_CREATE,
    INVITE_CREATE_BULK,
    INVITE_DELETE,
    INVITE_SEND,
    INVITE_CREATE_USER,
} from 'appRedux/actions/invites';
import {InviteTypes} from 'appRedux/actions/invites/types';

export interface InvitesState {
    isLoading: boolean;
    invites: InviteTypes[];
    userInvites: InviteTypes[];
    error: IErrors | undefined;
}

export const initialState: InvitesState = {
    isLoading: false,
    invites: [],
    userInvites: [],
    error: undefined,
};

const invites = (state = initialState, action: IActionType<InviteTypes[] | IErrors>): InvitesState => {
    switch (action.type) {
        case INVITE_SEND.REQUEST:
        case INVITE_CREATE.REQUEST:
        case INVITE_CREATE_BULK.REQUEST:
        case INVITE_DELETE.REQUEST:
        case INVITE_CREATE_USER.REQUEST:
        case INVITES_GET_LIST.REQUEST:
        case INVITES_GET_USER_LIST.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case INVITE_SEND.SUCCESS:
        case INVITE_DELETE.SUCCESS:
        case INVITE_CREATE.SUCCESS:
        case INVITE_CREATE_BULK.SUCCESS:
        case INVITES_GET_LIST.SUCCESS:
        case INVITE_CREATE_USER.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                invites: action.payload as InviteTypes[],
            };
        }

        case INVITES_GET_USER_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                userInvites: action.payload as InviteTypes[],
            };
        }

        case INVITE_SEND.ERROR:
        case INVITE_DELETE.ERROR:
        case INVITE_CREATE.ERROR:
        case INVITE_CREATE_BULK.ERROR:
        case INVITE_CREATE_USER.ERROR:
        case INVITES_GET_LIST.ERROR:
        case INVITES_GET_USER_LIST.ERROR: {
            return {
                ...state,
                isLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default invites;
