import {createTheme, Theme} from '@mui/material/styles';

export const MAIN_CLIENT_COLOR = '#07D889';
export const MAIN_ADMIN_COLOR = '#A3D86F';
export const MAIN_AGENT_COLOR = '#A1869B';

export const CLIENT_BACKGROUND_COLOR = '#f1f0ee';
export const ADMIN_LIGHT_GRAY_COLOR = '#eee';

export const ERROR_TEXT_COLOR = '#CC4941';

export const HIGHLIGHTED_SECTION_COLOR = '#fce17e';

export const FONT_FAMILY_OPEN_SANS = 'OpenSans';

export const TAG_COLOR_1 = '#B8B5B2';
export const TAG_COLOR_2 = '#9B7B6D';
export const TAG_COLOR_3 = '#A1869B';
export const TAG_COLOR_4 = '#6692B0';
export const TAG_COLOR_5 = '#205072';
export const TAG_COLOR_6 = '#329D9C';
export const TAG_COLOR_7 = '#6F927D';
export const TAG_COLOR_8 = '#6EC181';
export const TAG_COLOR_9 = '#CC4941';
export const TAG_COLOR_10 = '#FCE17E';

export const tagColors = [
    TAG_COLOR_1,
    TAG_COLOR_2,
    TAG_COLOR_3,
    TAG_COLOR_4,
    TAG_COLOR_5,
    TAG_COLOR_6,
    TAG_COLOR_7,
    TAG_COLOR_8,
    TAG_COLOR_9,
    TAG_COLOR_10,
];

export const randomColors = [
    '#8633d0',
    '#039c4b',
    '#66d313',
    '#fedf17',
    '#ff0984',
    '#21409a',
    '#04adff',
    '#e48873',
    '#f16623',
    '#f44546',
    '#30ff9c',
    '#030213',
    '#8587a8',
    '#604523',
    '#30ff9c',
    '#542b54',
];

let currentTheme: Theme;

export const createAppTheme = (direction: 'ltr' | 'rtl') =>
    createTheme({
        direction,
        palette: {
            primary: {
                main: '#458AD5',
                light: '#3F47AA',
                dark: '#1472B9',
                contrastText: '#808181',
            },
            warning: {
                main: '#A3D86F',
                light: '#D9D9D9',
                dark: 'rgba(163, 216, 111, 0.23)',
                contrastText: '#F1F0EE',
            },
            info: {
                main: '#222222',
                light: '#808181',
                dark: '#9A9999',
                contrastText: '#B4B4B4',
            },
            secondary: {
                main: '#3D3BA1',
                light: '#242647',
                dark: '#E9F6D8',
                contrastText: '#CFCFCF',
            },
            error: {
                main: '#d32f2f',
                light: '#ef5350',
                dark: '#c62828',
                contrastText: '#fff',
            },
            success: {
                main: '#2e7d32',
                light: '#4caf50',
                dark: '#1b5e20',
                contrastText: '#fff',
            },
            grey: {
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                800: '#424242',
                900: '#212121',
                A100: '#f5f5f5',
                A200: '#eeeeee',
                A400: '#bdbdbd',
                A700: '#616161',
            },
            background: {
                default: '#ffffff',
                paper: '#ffffff',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    startIcon: {
                        marginRight: direction === 'rtl' ? -4 : 8,
                        marginLeft: direction === 'rtl' ? 8 : -4,
                    },
                    endIcon: {
                        marginLeft: direction === 'rtl' ? -4 : 8,
                        marginRight: direction === 'rtl' ? 8 : -4,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label': {
                            right: direction === 'rtl' ? 0 : 'unset',
                            left: direction === 'rtl' ? 'unset' : 0,
                            transformOrigin: direction === 'rtl' ? 'right' : 'left',
                        },
                        '& label.MuiInputLabel-shrink': {
                            transform:
                                direction === 'rtl'
                                    ? 'translate(0, -1.5px) scale(0.75)'
                                    : 'translate(0, -1.5px) scale(0.75)',
                        },
                        '& .MuiBox-root': {
                            right: 'auto',
                            left: 'auto',
                            transformOrigin: direction === 'rtl' ? 'right' : 'left',
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: direction === 'rtl' ? 'auto !important' : '14px !important',
                        marginRight: direction === 'rtl' ? '14px !important' : 'auto !important',
                        textAlign: direction === 'rtl' ? 'right' : 'left',
                    },
                    contained: {
                        marginLeft: direction === 'rtl' ? 'auto !important' : '14px !important',
                        marginRight: direction === 'rtl' ? '14px !important' : 'auto !important',
                    },
                },
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: FONT_FAMILY_OPEN_SANS,
            h1: {
                fontSize: '2.5rem',
                fontWeight: 400,
            },
            h2: {
                fontSize: '2rem',
                fontWeight: 400,
            },
            h3: {
                fontSize: '1.75rem',
                fontWeight: 400,
            },
            h4: {
                fontSize: '1.5rem',
                fontWeight: 400,
            },
            h5: {
                fontSize: '1.25rem',
                fontWeight: 400,
            },
            h6: {
                fontSize: '1rem',
                fontWeight: 500,
            },
        },
    });

// Initial theme creation
currentTheme = createAppTheme(document.body.dir as 'ltr' | 'rtl');

// Update theme when body.dir changes
const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
        if (mutation.attributeName === 'dir') {
            const direction = document.body.dir as 'ltr' | 'rtl';
            currentTheme = createAppTheme(direction);
        }
    });
});

observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['dir'],
});

export const theme = currentTheme;
