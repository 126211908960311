import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

import {
    TEMPLATE_ACCOUNT_DELETING,
    TEMPLATE_AGENT_ASSIGNED,
    TEMPLATE_USER_CHAT_MESSAGE,
    TEMPLATE_INVITE_REGISTRATION,
    TEMPLATE_ORGANIZATION_ACCOUNT_CREATED,
    TEMPLATE_PASSWORD_RECOVERY,
    TEMPLATE_VERIFICATION,
    TEMPLATE_FORGET_NICKNAME,
    TEMPLATE_TFA_DEACTIVATED,
    TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT,
    TEMPLATE_CASES_QUOTA_HARD,
    TEMPLATE_CASES_QUOTA_SOFT,
    TEMPLATE_USER_ACCOUNT_REPORT,
    TEMPLATE_CLIENT_SECTION_COMMENT,
    TEMPLATE_USER_EMAIL_UPDATING,
} from 'pages/admin/generalTemplate/helper';

export const customButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.requesterCase.name',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.requesterCase.form',
        markdown: '{{ form }}',
    },
    {
        title: 'orguser.requesterCase.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.requesterCase.caseLabel',
        markdown: '{{ caseLabel }}',
    },
    {
        title: 'orguser.requesterCase.status',
        markdown: '{{ statusTitle }}',
    },
];

export const accountDeletingButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.organization',
        markdown: '{{ organization }}',
    },
    {
        title: 'orguser.mailer.buttons.deletedDate',
        markdown: '{{ deletedDate }}',
    },
    {
        title: 'orguser.mailer.buttons.organizationEmail',
        markdown: '{{ organizationEmail }}',
    },
];

export const agentAssignedToCaseButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.chatLink',
        markdown: '{{ url }}',
    },
];

export const passwordRecoveryRequestButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.link',
        markdown: '{{ link }}',
    },
];

export const inviteToRegistrationButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.formTitle',
        markdown: '{{ form }}',
    },
    {
        title: 'orguser.mailer.buttons.inviteLink',
        markdown: '{{ link }}',
    },
];

export const orgUserAccountCreatedButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.organization',
        markdown: '{{ organization }}',
    },
    {
        title: 'orguser.mailer.buttons.link',
        markdown: '{{ link }}',
    },
    {
        title: 'orguser.mailer.buttons.accountUrl',
        markdown: '{{ accountUrl }}',
    },
];

export const userVerificationButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.formTitle',
        markdown: '{{ title }}',
    },
    {
        title: 'orguser.mailer.buttons.link',
        markdown: '{{ url }}',
    },
];

export const forgetNicknameButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.nickname',
        markdown: '{{ nickname }}',
    },
];

export const deactivateTfaButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.datetime',
        markdown: '{{ datetime }}',
    },
    {
        title: 'orguser.mailer.buttons.organizationEmail',
        markdown: '{{ organizationEmail }}',
    },
];

export const organizationStatisticReportButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
];

export const requesterCasesQuotaButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.currentQty',
        markdown: '{{ currentQty }}',
    },
];

export const userAccountReportButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.expiredDate',
        markdown: '{{ expiredDate }}',
    },
];

export const clientCommentSectionButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.sectionTitle',
        markdown: '{{ sectionName }}',
    },
    {
        title: 'orguser.requesterCase.caseLink',
        markdown: '{{ url }}',
    },
];

export const userUpdateEmailButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.link',
        markdown: '{{ link }}',
    },
];

export const getCustomButtonsByType = (type: number): MdxEditorCustomButtonType[] => {
    switch (type) {
        case TEMPLATE_ACCOUNT_DELETING:
            return accountDeletingButtons;
        case TEMPLATE_AGENT_ASSIGNED:
        case TEMPLATE_USER_CHAT_MESSAGE:
            return agentAssignedToCaseButtons;
        case TEMPLATE_PASSWORD_RECOVERY:
            return passwordRecoveryRequestButtons;
        case TEMPLATE_ORGANIZATION_ACCOUNT_CREATED:
            return orgUserAccountCreatedButtons;
        case TEMPLATE_INVITE_REGISTRATION:
            return inviteToRegistrationButtons;
        case TEMPLATE_VERIFICATION:
            return userVerificationButtons;
        case TEMPLATE_FORGET_NICKNAME:
            return forgetNicknameButtons;
        case TEMPLATE_TFA_DEACTIVATED:
            return deactivateTfaButtons;
        case TEMPLATE_ORGANIZATIONS_STATISTIC_REPORT:
            return organizationStatisticReportButtons;
        case TEMPLATE_CASES_QUOTA_HARD:
        case TEMPLATE_CASES_QUOTA_SOFT:
            return requesterCasesQuotaButtons;
        case TEMPLATE_USER_ACCOUNT_REPORT:
            return userAccountReportButtons;
        case TEMPLATE_USER_EMAIL_UPDATING:
            return userUpdateEmailButtons;
        case TEMPLATE_CLIENT_SECTION_COMMENT:
            return clientCommentSectionButtons;
        default:
            return customButtons;
    }
};
