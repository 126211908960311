import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import FormTimeBucketForm from 'components/Forms/FormBuilder/FormTimeBucketForm/FormTimeBucketForm';

interface AddFormTimeBucketButtonType {
    formId: number;
}

const AddFormTimeBucketButton: FC<AddFormTimeBucketButtonType> = ({formId}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton
                isSubmit={false}
                onClick={toggleModal}
                title={t('orguser.forms.timeTracking.addTimeBucket')}
            />
            <ModalWrapper
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                title={t('orguser.forms.timeTracking.addTimeBucket')}
            >
                <FormTimeBucketForm formId={formId} closeModal={() => setIsShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default AddFormTimeBucketButton;
