import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FieldOptionType, FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {getCustomAnswer} from 'pages/agent/requesterPage/sections/helper';

import {getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

interface SelectedOptionType {
    field: FormFieldTypes;
    formId: number;
    clientText: string;
    option: FieldOptionType;
}

const SelectedOption: FC<SelectedOptionType> = ({field, formId, clientText, option}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, title, text, isCustom} = option;

    const keywordOptionTitle = getOptionKeyword(formId, id, 'title');
    const keywordOptionText = getOptionKeyword(formId, id, 'text');
    const optionTitle = getFormTranslatedLabel(translations, keywordOptionTitle, title);
    const optionText = text ? getFormTranslatedLabel(translations, keywordOptionText, text) : null;

    const answer = isCustom ? getCustomAnswer(field, clientText) : optionTitle;

    if (isCustom) {
        return (
            <Box sx={{width: '100%'}} key={option.id}>
                <Typography sx={{fontWeight: 700}}>{`- ${t('orguser.requesterCase.customAnswer')}:`}</Typography>
                <Typography sx={{ml: 1.5}}>{answer}</Typography>
            </Box>
        );
    }
    return (
        <Box sx={{width: '100%'}} key={option.id}>
            - {answer}
            {optionText && <DescriptionPopover text={optionText} />}
        </Box>
    );
};

export default SelectedOption;
