import React, {FC, MouseEvent, TouchEvent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useDispatch} from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import {SxProps} from '@mui/material';
import Box from '@mui/system/Box';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {DELETE_CHAT_MESSAGE} from 'appRedux/actions/requestChat';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateChatMessageForm from 'components/Forms/RequesterCaseChatForm/UpdateChatMessageForm';

import {theme} from 'config/theme';

interface ChatMessageBlockOptionsType {
    item: MessageTypes;
    userId: number;
    isButtonShow: boolean;
    isCurrentUserSender: boolean;
    children: React.ReactNode;
    wrapperSx: SxProps;
}

const ChatMessageBlockOptions: FC<ChatMessageBlockOptionsType> = ({
    item,
    isButtonShow,
    userId,
    isCurrentUserSender,
    children,
    wrapperSx,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const {showAlert} = useContext(AlertContext);
    const {isMobile} = useContext(MediaContext);

    const {createdAt, uuid, isSystemMessage} = item;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isUpdateOpened, setIsUpdateOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const toggleIsUpdateOpened = () => {
        setIsUpdateOpened(previous => !previous);
    };

    const removeChatMessage = useCallback(
        data => dispatch({type: DELETE_CHAT_MESSAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMessageChangingPossible = moment(createdAt).add(1, 'hour').unix() > moment().unix();

    const open = Boolean(anchorEl);

    const onClickAction = () => {
        removeChatMessage({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const [dragging, setDragging] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [startX, setStartX] = useState(0);

    useEffect(() => {
        if (!anchorEl) setPositionX(0);
    }, [anchorEl]);

    const handleTouchStart = (event: TouchEvent<HTMLElement>) => {
        const touch = event.touches[0];
        setStartX(touch.clientX);
        setDragging(true);
    };

    const handleTouchMove = (event: TouchEvent<HTMLElement>) => {
        if (!dragging || !(isMobile && isCurrentUserSender && !isSystemMessage)) return;

        const touch = event.touches[0];
        const deltaX = touch.clientX - startX;

        if (deltaX < 10) {
            setPositionX(Math.max(deltaX, -50));
        }
    };

    const handleTouchEnd = () => {
        if (positionX < -40) {
            buttonRef.current && buttonRef.current.click();
            setPositionX(-40);
        } else {
            setPositionX(0);
        }

        setDragging(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isCurrentUserSender ? 'row-reverse' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...(isMobile && isCurrentUserSender && !isSystemMessage
                    ? {
                          transform: `translateX(${positionX + 50}px)`,
                          transition: dragging ? 'none' : 'transform 0.3s ease-out',
                          willChange: 'transform',
                      }
                    : {}),
            }}
        >
            {isCurrentUserSender && !isSystemMessage && (
                <IconButton
                    ref={buttonRef}
                    sx={{
                        ml: 1,
                        visibility: isButtonShow || isMobile ? 'visible' : 'hidden',
                    }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    onClick={() => setIsUpdateOpened(true)}
                >
                    <EditSvgIcon />
                    <Typography sx={{ml: 1}}>{t('common.buttons.update')}</Typography>
                </MenuItem>
                <MenuItem
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    onClick={() => setIsOpened(true)}
                >
                    <DeleteSvgIcon />
                    <Typography sx={{ml: 1}}>{t('common.buttons.delete')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={isUpdateOpened}
                toggleModal={toggleIsUpdateOpened}
                title={t('requester.chat.updateChatMessage')}
            >
                <UpdateChatMessageForm
                    item={item}
                    closeForm={() => setIsUpdateOpened(false)}
                    userId={userId}
                    isMessageChangingPossible={isMessageChangingPossible}
                />
            </ModalWrapper>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickAction}
                description={isMessageChangingPossible ? '' : t('requester.chat.chatMessageRemovingImpossible')}
                deletingRestrict={!isMessageChangingPossible}
            />
            <Box
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                sx={{
                    ...wrapperSx,
                    userSelect: 'none',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ChatMessageBlockOptions;
