import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Markdown from 'react-markdown';
import {Document, Page, View, Text} from '@react-pdf/renderer';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {RESEND_EMAIL} from 'appRedux/actions/profile';
import {REQUESTER_PREPARED_STATUS, GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import SendMail from 'components/SendMail/SendMail';
import ClientButton from 'components/ClientScreenComponents/ClientButton';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import PagePdfContent from 'pages/agent/pdfExport/pdfPartials/PagePdfContent';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import {checkIsFormTotallyCompleted} from 'pages/client/final/helper';

import {routes, STATUS_OPENING} from 'config/index';

const FinalPage: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {requestCase, form} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isFormTotallyCompleted, setIsFormTotallyCompleted] = useState<boolean>(false);

    const {
        admin: {formInfo},
        profile: {profile, isLoading},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {submitMessage, pages} = formInfo;
    const {status, result} = currentCase;

    const isUserVerified = get(profile, 'isVerify', false);
    const submitMessageText = submitMessage ? submitMessage : t('requester.finalPage.finalTextVerified');

    const text = isUserVerified ? submitMessageText : t('requester.finalPage.finalTextNotVerified');
    const buttonLabel = isUserVerified ? 'requester.finalPage.viewAgain' : 'requester.finalPage.sendAgain';

    const resendEmailAction = useCallback(() => dispatch({type: RESEND_EMAIL.REQUEST}), [dispatch]);

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const submitRequesterCase = useCallback(
        data => dispatch({type: REQUESTER_PREPARED_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToRequestsList = () => {
        navigate(`${routes.REQUESTS_LIST}`);
    };

    const onSendAgainClicked = () => {
        resendEmailAction();
    };

    const redirectToCurrentRequest = () => {
        navigate(`${routes.REQUEST}/${requestCase}/form/${form}`);
    };

    useEffect(() => {
        if (isUserVerified && status === STATUS_OPENING && isFormTotallyCompleted) {
            submitRequesterCase({
                id: requestCase,
                showAlert,
            });
        }
    }, [isUserVerified, status, isFormTotallyCompleted]);

    useEffect(() => {
        getRequestCaseInformation({id: requestCase});
    }, []);

    useEffect(() => {
        if (pages.length > 0 && result) {
            setIsFormTotallyCompleted(checkIsFormTotallyCompleted(pages, result));
        }
    }, [pages, result, requestCase]);

    // if (!isFormTotallyCompleted) {
    //     return (
    //         <ClientScreenWrapper>
    //             <Box sx={{p: 2}}>
    //                 <Typography align="justify" sx={{mb: 2}}>
    //                     {t('requester.finalPage.formNotCompleted')}
    //                 </Typography>
    //             </Box>
    //             <Box sx={{display: 'flex', justifyContent: 'center', mb: 3, gap: 2}}>
    //                 <ClientButton title={t(buttonLabel)} onClickAction={redirectToCurrentRequest} />
    //             </Box>
    //         </ClientScreenWrapper>
    //     );
    // }

    return (
        <ClientScreenWrapper>
            {status === STATUS_OPENING && <SendMail size={200} />}
            <Box sx={{p: 2}}>
                {isLoading ? (
                    <Typography align="justify" sx={{mb: 2}}>
                        {t('common.pleaseWait')}
                    </Typography>
                ) : (
                    <Box sx={{mb: 3}}>
                        <Markdown>{text}</Markdown>
                    </Box>
                )}

                <Box sx={{maxWidth: '700px', mx: 'auto', my: 2}}>
                    {/*<PdfDocumentContext.Provider*/}
                    {/*    value={{translations: formInfo.translations, preview: true, currentCase}}*/}
                    {/*>*/}
                    {/*    <Document title={currentCase.caseLabel}>*/}
                    {/*        {pages.map((page: FormPageTypes, index: number) => (*/}
                    {/*            <Page size="A4" style={pdfStyles.page} key={`page-${index}`}>*/}
                    {/*                <PagePdfContent*/}
                    {/*                    pageNumber={index + 1}*/}
                    {/*                    page={page}*/}
                    {/*                    result={currentCase.result}*/}
                    {/*                    formId={form}*/}
                    {/*                />*/}
                    {/*            </Page>*/}
                    {/*        ))}*/}
                    {/*    </Document>*/}
                    {/*</PdfDocumentContext.Provider>*/}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mb: 3, gap: 2}}>
                    <ClientButton
                        title={t(buttonLabel)}
                        dataId={`button#form-final`}
                        onClickAction={isUserVerified ? redirectToCurrentRequest : onSendAgainClicked}
                    />
                    <ClientButton
                        title={t('requester.finalPage.myCases')}
                        dataId={`button#form-my-cases`}
                        onClickAction={redirectToRequestsList}
                    />
                </Box>
            </Box>
        </ClientScreenWrapper>
    );
};

export default FinalPage;
