import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {AlertContext} from 'contexts/alert/context';

import {CREATE_WORKFLOW_FIELD_VARIABLE} from 'appRedux/actions/workflow';
import {WorkflowFieldVariableFormTypes} from 'appRedux/actions/workflow/types';

import WorkflowFieldVariableForm from 'components/Forms/Workflow/workflowFieldVariables/WorkflowFieldVariableForm';

interface ComponentType {
    uuid: string;
    closeModal: () => void;
}

const CreateWorkflowFieldVariableForm: FC<ComponentType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createWorkflowFieldVariable = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_FIELD_VARIABLE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowFieldVariableFormTypes) => {
        createWorkflowFieldVariable({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <WorkflowFieldVariableForm
            initialValues={{
                title: '',
                internalName: '',
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateWorkflowFieldVariableForm;
