import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface CopyingImpossiblePopupType {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    toggleShowModal: () => void;
    text: string;
    copyAction?: () => void;
}

const CopyingImpossiblePopup: FC<CopyingImpossiblePopupType> = ({
    text,
    showModal,
    toggleShowModal,
    setShowModal,
    copyAction,
}) => {
    const [t] = useTranslation();

    return (
        <ModalWrapper isShowModal={showModal} title={t('messages.warning.attention')} toggleModal={toggleShowModal}>
            <Typography>{text}</Typography>
            {copyAction ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentInverseButton onClick={() => setShowModal(false)} title={t('common.buttons.cancel')} />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.copy')}
                        onClick={() => {
                            copyAction();
                            setShowModal(false);
                        }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        onClick={() => setShowModal(false)}
                        title={t('common.buttons.close')}
                    />
                </Box>
            )}
        </ModalWrapper>
    );
};

export default CopyingImpossiblePopup;
