import React, {FC, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import FormCheckedSvgIcon from 'assets/icons/FormCheckedSvgIcon';
import FormPendingSvgIcon from 'assets/icons/FormPendingSvgIcon';
import ChatSvgIcon from 'assets/icons/ChatSvgIcon';

import {MediaContext} from 'contexts/media/context';

import {showLinkToChat} from 'components/TopBarMenu/helper';
import StatusLabel from 'components/ClientScreenComponents/StatusLabel';

import {isFormChecked} from 'pages/client/requests/helper';
import DraftAutoRemoveAlert from 'pages/client/requests/partials/DraftAutoRemoveAlert';
import RequesterCaseCancelButton from 'pages/client/requests/partials/RequesterCaseCancelButton';

import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';
import {routes, STATUS_PREPARED, STATUS_OPENING} from 'config/index';
import RequesterCaseDraftRemovingButton from 'pages/client/requests/partials/RequesterCaseDraftRemovingButton';

interface RequesterCaseTitleType {
    isCurrentOrganizationCase: boolean;
    item: RequesterAllCaseItemTypes;
    togglePagesModal: () => void;
    toggleFilesModal: () => void;
    isPagesOpened: boolean;
    isFilesOpened: boolean;
}

const RequesterCaseTitle: FC<RequesterCaseTitleType> = ({
    item,
    isCurrentOrganizationCase,
    togglePagesModal,
    toggleFilesModal,
    isPagesOpened,
    isFilesOpened,
}) => {
    const navigate = useNavigate();

    const {isMobile, isTablet} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {form, pages, requestCase, isAgentAssigned, notReadMessages, checkedSections, filesNumber} = item;

    const {
        id,
        isChatActivated,
        isRemovingImpossible,
        caseLabel,
        updatedAt,
        removedAt,
        status,
        statusTitle,
        statusColor,
    } = requestCase;

    const redirectToChat = () => {
        navigate(`${routes.REQUEST}/${requestCase.id}/chat`);
    };

    const isUserVerified = get(profile, 'isVerify', false);

    const formTitleKeyword = getFormKeyword(form.id, 'title');

    const redirectToForm = () => {
        navigate(`${routes.REQUEST}/${requestCase.id}/form/${form.id}`);
    };

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap-reverse',
                }}
            >
                <Typography sx={{fontSize: 11, position: 'absolute', top: 4, mx: 0.5}}>
                    {convertDateToCurrentLocale(updatedAt, false, true)}
                </Typography>
                <Box
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '100%',
                        flexGrow: 2,
                    }}
                >
                    <Box
                        data-id={`button#requests-case-${requestCase.id}`}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            mt: 3,
                            maxWidth: 'calc(100% - 100px)',
                        }}
                        onClick={redirectToForm}
                    >
                        {isFormChecked(pages, checkedSections) ? <FormCheckedSvgIcon /> : <FormPendingSvgIcon />}
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                pl: 1,
                                pr: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {`${isMobile ? '' : caseLabel + ' | '}${getFormTranslatedLabel(
                                translations,
                                formTitleKeyword,
                                form.title,
                            )}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            minWidth: 0,
                        }}
                    >
                        <IconButton
                            onClick={togglePagesModal}
                            sx={{color: isPagesOpened ? ERROR_TEXT_COLOR : 'inherit'}}
                        >
                            <InsertDriveFileOutlinedIcon fontSize="small" />
                        </IconButton>
                        <Typography sx={{color: isPagesOpened ? ERROR_TEXT_COLOR : 'inherit'}}>
                            {pages.length}
                        </Typography>
                        {isCurrentOrganizationCase && filesNumber > 0 && (
                            <>
                                <IconButton
                                    onClick={toggleFilesModal}
                                    sx={{color: isFilesOpened ? ERROR_TEXT_COLOR : 'inherit'}}
                                >
                                    <FilePresentOutlinedIcon fontSize="small" />
                                </IconButton>
                                <Typography sx={{color: isFilesOpened ? ERROR_TEXT_COLOR : 'inherit'}}>
                                    {filesNumber}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexGrow: 1,
                        mb: -1,
                        gap: 1,
                    }}
                >
                    {isCurrentOrganizationCase &&
                        showLinkToChat(isUserVerified, isChatActivated, isAgentAssigned, false) && (
                            <IconButton
                                data-id={`button#requests-case-${id}-chat`}
                                onClick={redirectToChat}
                                sx={{
                                    mr: 1,
                                    backgroundColor: theme.palette.info.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.info.main,
                                    },
                                }}
                            >
                                <Badge badgeContent={notReadMessages} color="error" invisible={notReadMessages === 0}>
                                    <ChatSvgIcon />
                                </Badge>
                            </IconButton>
                        )}
                    {!isRemovingImpossible && !isMobile && !isTablet && removedAt && (
                        <DraftAutoRemoveAlert requestCase={requestCase} />
                    )}
                    {status === STATUS_PREPARED && <RequesterCaseCancelButton caseId={id} />}
                    {status === STATUS_OPENING && <RequesterCaseDraftRemovingButton caseId={id} />}
                    {isUserVerified && <StatusLabel status={statusTitle} color={statusColor} />}
                </Box>
            </Box>
            {(isMobile || isTablet) && removedAt && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        mb: 2,
                    }}
                >
                    <DraftAutoRemoveAlert requestCase={requestCase} />
                </Box>
            )}
        </Box>
    );
};

export default RequesterCaseTitle;
