import * as yup from 'yup';

import {calculateEntropy} from 'helpers/calculateEntropy';
import {MAXIMAL_PASSWORD_ENTROPY} from 'helpers/security';

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        keyPassword: yup
            .string()
            .test('password', t('messages.validation.passwordIsTooWeak'), value => {
                return calculateEntropy(value) > MAXIMAL_PASSWORD_ENTROPY;
            })
            .required(t('messages.validation.passwordIsRequired')),
    });
