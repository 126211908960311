import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {theme} from 'config/theme';

interface FormRightSideDetailsPanelWrapperType {
    title: string;
    children: ReactNode;
}

const FormRightSideDetailsPanelWrapper: FC<FormRightSideDetailsPanelWrapperType> = ({children, title}) => {
    return (
        <Box
            sx={{
                mt: 4,
                ml: 3,
                p: 2,
                borderRadius: 4,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Typography sx={{fontWeight: 700, fontSize: 16, mb: 1}}>{title}</Typography>
            {children}
        </Box>
    );
};

export default FormRightSideDetailsPanelWrapper;
