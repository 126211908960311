import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {REQUESTER_CASE_HAS_CALENDAR_CREATED} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

interface CalendarStepFiveType {
    currentCalendar: RequesterCaseHasCalendarTypes;
}

const CalendarStepFive: FC<CalendarStepFiveType> = ({currentCalendar}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {
        status,
        calendarDate,
        resourceFieldCalendarSlot,
        resourceFieldCalendarBookframe,
        resourceFieldCalendarException,
        startTime,
        endTime,
    } = currentCalendar;

    const calendarInfo = allCalendarFormItems.find(item => item.id === currentCalendar.resourceFieldCalendar);

    if (!calendarInfo) return null;

    const slot = calendarInfo.slots.find(item => item.id === Number(resourceFieldCalendarSlot));
    const bookframe = calendarInfo.bookframes.find(item => item.id === Number(resourceFieldCalendarBookframe));
    const exception = calendarInfo.exceptions.find(item => item.id === Number(resourceFieldCalendarException));

    if (!slot || (!bookframe && !exception) || !calendarDate) return null;

    return (
        <Box>
            {status === REQUESTER_CASE_HAS_CALENDAR_CREATED && (
                <Alert sx={{mb: 2}} severity={'info'}>
                    <Typography>{t('requester.casePage.calendarSlotCreatedAlert')}</Typography>
                </Alert>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('requester.casePage.calendarSlot')}:`}</Typography>
                <Typography>{`${slot.name} (${slot.duration} ${t('common.units.minutes')})`}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                    'requester.casePage.calendarSlotBookframe',
                )}:`}</Typography>
                <Typography>{`${convertDateToCurrentLocale(
                    new Date(calendarDate),
                    true,
                    true,
                )} / ${startTime} - ${endTime}`}</Typography>
            </Box>
        </Box>
    );
};

export default CalendarStepFive;
