import React, {FC} from 'react';
import get from 'lodash/get';
import {FormikProps} from 'formik';
import DatePicker from 'react-datepicker';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import {DATEPICKER_FORMAT} from 'config/index';
import FormFieldErrorMessage from '../../AdminScreenComponents/FormFieldErrorMessage';

interface FormBuilderDateInputType {
    formik: FormikProps<any>;
    fieldName: string;
    date: Date;
    handleChange: (value: Date) => void;
}

const styles = () =>
    createStyles({
        datepicker: {
            fontSize: 16,
            backgroundColor: '#ffffff',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomColor: '#c4c4c4',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            padding: 0,
            width: '100%',
            height: 42,
            '&:hover': {
                borderColor: '#222222',
                borderWidth: 1.5,
            },
            '&:focus': {
                outline: 'none',
                borderColor: '#222222',
                borderWidth: 2,
            },
        },
    });

const FormBuilderDateInput: FC<FormBuilderDateInputType & WithStyles<typeof styles>> = ({
    classes,
    fieldName,
    formik: {touched, errors},
    date,
    handleChange,
}) => {
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <Box sx={{width: '100%'}}>
            <FormControl fullWidth>
                <DatePicker
                    className={classes.datepicker}
                    selected={date}
                    onChange={handleChange}
                    dateFormat={DATEPICKER_FORMAT}
                    showYearDropdown
                />
                {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
            </FormControl>
        </Box>
    );
};

export default withStyles(styles)(FormBuilderDateInput);
