import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {FormLabelTypes} from 'appRedux/actions/forms/types';

import TagChipWithDeleting from 'components/TagChip/TagChipWithDeleting';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateFormLabelForm from 'components/Forms/FormBuilder/FormLabelForm/UpdateFormLabelForm';

import {PARAMETER_LABEL, PARAMETER_PAGE, DEFAULT_PAGE} from 'config/index';
import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormLabelFilterItemType {
    item: FormLabelTypes;
}

const FormLabelFilterItem: FC<FormLabelFilterItemType> = ({item}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {id, title} = item;

    const currentFormLabels = searchParams.get(PARAMETER_LABEL);
    const currentFormLabelsArray = currentFormLabels ? currentFormLabels.split(',') : [];

    const onLabelClick = () => {
        if (currentFormLabelsArray.includes(String(id))) {
            for (let i = 0, n = currentFormLabelsArray.length; i < n; i++) {
                if (currentFormLabelsArray[i] === String(id)) {
                    currentFormLabelsArray.splice(i, 1);
                }
            }
        } else {
            currentFormLabelsArray.push(String(id));
        }
        searchParams.set(PARAMETER_LABEL, currentFormLabelsArray.join(','));
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        setSearchParams(searchParams);
    };

    const isFormLabelSelected = currentFormLabelsArray.includes(String(id));

    return (
        <>
            <TagChipWithDeleting
                id={id}
                title={title}
                color={isFormLabelSelected ? theme.palette.info.main : CLIENT_BACKGROUND_COLOR}
                isEditIcon
                onUpdateClick={onLabelClick}
                onEditIconClick={() => setShowModal(true)}
            />
            <ModalWrapper
                isShowModal={showModal}
                title={t('orguser.forms.formLabels.updateFormLabel')}
                toggleModal={toggleModal}
            >
                <UpdateFormLabelForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default FormLabelFilterItem;
