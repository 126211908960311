import {FieldOptionType, FormFieldTypes, FormTranslationItemTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';

import {FORM_FIELD_CHECKBOXES, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {getOptionsIdsArray} from 'pages/client/form/fieldTypes/helper';

import {getFieldKeyword, getOptionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

export const getSelectedOptionsList = (
    fieldOptions: FieldOptionType[],
    selectedOptions: RequesterCaseOptionTypes[],
): FieldOptionType[] => {
    const results: FieldOptionType[] = [];
    fieldOptions.forEach(fieldOption => {
        if (selectedOptions.some(selectedOption => selectedOption.optionId === fieldOption.id)) {
            results.push(fieldOption);
        }
    });
    return results;
};

export const getCustomAnswer = (field: FormFieldTypes, clientText: string): string => {
    const formattedText = clientText.replace(/<[^>]*>/g, '');
    return field.type === FORM_FIELD_CHECKBOXES && field.fieldOptions
        ? getCustomCheckboxAnswer(field.fieldOptions, formattedText)
        : formattedText;
};

export const getCustomCheckboxAnswer = (options: FieldOptionType[], formattedText: string): string => {
    const optionsIds = getOptionsIdsArray(options);
    const formattedTextArray = formattedText.split(OPTIONS_SEPARATOR);
    const results: string[] = [];
    formattedTextArray.forEach(item => {
        if (!optionsIds.includes(Number(item))) {
            results.push(item);
        }
    });
    return results.join(OPTIONS_SEPARATOR);
};
