import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {REQUEST_CASE_CHAT_ACTIVATE} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

const ActivateChatByAgent: FC = () => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleIsChecked = () => {
        setIsChecked(previous => !previous);
    };

    const changeFormStatus = useCallback(
        data => dispatch({type: REQUEST_CASE_CHAT_ACTIVATE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        toggleIsChecked();
        changeFormStatus({
            caseId: Number(caseId),
            showAlert,
        });
    };

    useEffect(() => {
        setIsChecked(currentCase.isChatActivatedByAgent);
    }, [currentCase]);

    return <CustomSwitcher checked={isChecked} onClick={handleChangeStatus} />;
};

export default ActivateChatByAgent;
