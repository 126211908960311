import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseTimeBucketFormTypes} from 'appRedux/actions/requestCase/types';
import {CREATE_REQUESTER_CASE_TIME_BUCKET} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import TimeRecordForm from 'pages/agent/requesterPage/timeTracking/TimeRecordForm';

import {convertTimeStringToNum} from 'helpers/parseTimeString';

export interface BucketOptionTypes {
    id: number;
    label: string;
}

interface AddTimeRecordFormType {
    closeModal: () => void;
}

const AddTimeRecordForm: FC<AddTimeRecordFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {
            formInfo: {timeBuckets},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createCaseWatcher = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_TIME_BUCKET.REQUEST, payload: data}),
        [dispatch],
    );

    const formBucketsSelection: BucketOptionTypes[] = timeBuckets
        .map(item => ({
            label: item.bucketTitle,
            id: item.bucketId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const onSubmitAction = (values: RequesterCaseTimeBucketFormTypes, {setErrors}) => {
        createCaseWatcher({
            ...values,
            duration: convertTimeStringToNum(values.duration, t),
            id: Number(caseId),
            setErrors,
            callback: closeModal,
            showAlert,
        });
    };

    return (
        <TimeRecordForm
            initialValues={{
                bucket: formBucketsSelection?.[0].id || 0,
                bucketDate: moment().format('YYYY-MM-DD'), // Set today's date as initial value
                text: '',
                duration: '',
            }}
            onSubmitAction={onSubmitAction}
            closeModal={closeModal}
        />
    );
};

export default AddTimeRecordForm;
