import React, {ChangeEvent, FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {SelectChangeEvent} from '@mui/material/Select';

import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';
import {AGENT_FILTERS_LIST_UPDATE} from 'appRedux/actions/agentFilters';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import FilterForm from 'components/Forms/SavedFilterForm/FilterForm';
import HorizontalLeftFlexbox from 'components/Flexboxes/HorizontalLeftFlexbox';
import {BOARD_TYPE_BOARD} from 'components/Forms/BoardForm/helper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import EmptyArea from 'components/EmptyArea';

import {
    FilterObjectType,
    FILTER_SEPARATOR,
    getFilterObject,
    getFilterIconByType,
} from 'pages/agent/savedFilters/helper';
import SelectedFilters from 'pages/agent/savedFilters/SelectedFilters';
import FiltersPanel from 'pages/agent/savedFilters/FiltersPanel';
import FilterDeleteButton from 'pages/agent/savedFilters/FilterDeleteButton';
import FilterSaveButton from 'pages/agent/savedFilters/FilterSaveButton';

interface SavedFilterInfoType {
    item: AgentSavedFilterTypes;
    activeFilter: string | null;
    setActiveFilter: (value: string | null) => void;
}

const SavedFilterInfo: FC<SavedFilterInfoType> = ({item, activeFilter, setActiveFilter}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, title, text, iconType, board, type, boardTitle} = item;

    const filterObject: FilterObjectType = getFilterObject(text);

    const [updatedTitle, setUpdatedTitle] = useState<string>(title);
    const [updatedIconType, setUpdatedIconType] = useState<number>(iconType);
    const [updatedBoard, setUpdatedBoard] = useState<number>(board);
    const [updatedFilter, setUpdatedFilter] = useState<FilterObjectType>(filterObject);
    const [updatedFilterString, setUpdatedFilterString] = useState<string>(text);

    const [errorBoardIsRequired, setErrorBoardIsRequired] = useState<boolean>(false);

    const saveCurrentFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        setActiveFilter(String(uuid));
    };

    const onDiscardClick = () => {
        setActiveFilter(null);
        setUpdatedTitle(title);
        setUpdatedBoard(board);
        setUpdatedIconType(iconType);
        setUpdatedFilterString(text);
        setUpdatedFilter(filterObject);
    };

    const onTitleChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setUpdatedTitle(value);
    };

    const onIconTypeChange = (e: SelectChangeEvent) => {
        const value = Number(e.target.value);
        setUpdatedIconType(value);
    };

    const onBoardChange = (e: SelectChangeEvent) => {
        const value = String(e.target.value);
        setUpdatedBoard(Number(value));
        updateFilterStructure();
    };

    const updateFilterStructure = () => {
        const newFilterArray: any = [];
        let counter = 0;
        Object.entries(updatedFilter).map(item => {
            const key = item[0];
            const value = item[1];
            if (Array.isArray(value)) {
                newFilterArray[counter] = `${key}=${value.join(FILTER_SEPARATOR)}`;
            } else {
                newFilterArray[counter] = `${key}=${value}`;
            }
            counter++;
        });
        const newFilter = '?' + newFilterArray.join('&');
        setUpdatedFilterString(newFilter);
        setUpdatedFilter(getFilterObject(newFilter));
    };

    const onSaveCurrentClick = () => {
        if (!updatedBoard) {
            setErrorBoardIsRequired(true);
        } else {
            saveCurrentFilter({
                uuid,
                board: updatedBoard,
                title: updatedTitle,
                iconType: updatedIconType,
                text: updatedFilterString,
                showAlert,
                callback: () => setActiveFilter(null),
            });
        }
    };

    const isEditClicked = activeFilter === uuid;

    const Icon = getFilterIconByType(updatedIconType);

    return (
        <>
            <Box>
                {isEditClicked ? (
                    <FilterForm
                        updatedTitle={updatedTitle}
                        onTitleChange={onTitleChange}
                        updatedIconType={String(updatedIconType)}
                        onIconTypeChange={onIconTypeChange}
                        updatedBoard={Number(updatedBoard)}
                        onBoardChange={onBoardChange}
                        errorBoardIsRequired={errorBoardIsRequired}
                    />
                ) : (
                    <HorizontalLeftFlexbox>
                        <IconButton>
                            <Icon />
                        </IconButton>
                        <Typography sx={{fontSize: 24, ml: 1}}>{title}</Typography>
                        <Typography sx={{ml: 2}}>{`(${t(
                            type === BOARD_TYPE_BOARD ? 'common.views.board' : 'common.views.list',
                        )}: ${boardTitle})`}</Typography>
                    </HorizontalLeftFlexbox>
                )}
                {isEditClicked && (
                    <FiltersPanel
                        boardId={Number(updatedBoard)}
                        updatedFilter={updatedFilter}
                        updateFilterStructure={updateFilterStructure}
                    />
                )}
                <SelectedFilters
                    updatedFilter={updatedFilter}
                    isEditClicked={isEditClicked}
                    updateFilterStructure={updateFilterStructure}
                />
                {isEditClicked ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '50%',
                        }}
                    >
                        <FilterDeleteButton item={item} />
                        <EmptyArea />
                        <AgentInverseButton title={t('common.buttons.discardChanges')} onClick={onDiscardClick} />
                        <FilterSaveButton
                            setActiveFilter={setActiveFilter}
                            updatedTitle={updatedTitle}
                            updatedBoard={Number(updatedBoard)}
                            updatedIconType={updatedIconType}
                            updatedFilterString={updatedFilterString}
                            onSaveCurrentClick={onSaveCurrentClick}
                            onDiscardClick={onDiscardClick}
                            item={item}
                        />
                    </Box>
                ) : (
                    <AgentInverseButton
                        title={t('orguser.savedFilters.editSavedFilter')}
                        icon={<EditSvgIcon />}
                        onClick={onEditClick}
                    />
                )}
            </Box>
            <Divider sx={{mt: 3, mb: 2}} />
        </>
    );
};

export default SavedFilterInfo;
