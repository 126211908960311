import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';

import EmptyArea from 'components/EmptyArea';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import {theme} from 'config/theme';
import {routes} from 'config/index';

const ClientChatHeader: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {isMobile} = useContext(MediaContext);

    const {
        requestCase: {
            currentCase: {caseLabel, id, formId, formTitle, ...other},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    console.log(other);
    const redirectToCase = () => {
        navigate(`${routes.REQUEST}/${id}/form/${formId}`);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 60,
                pl: isMobile ? 2 : 4,
                pr: isMobile ? 3 : 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 600,
                        color: theme.palette.info.main,
                    }}
                >
                    {caseLabel.split(' | ')[0] + ' | ' + formTitle}
                </Typography>
                <Box sx={{position: 'relative', width: 34, height: 34}}>
                    <AgentInverseButton title={t('common.buttons.openCase')} onClick={redirectToCase} />
                </Box>
            </Box>
            <EmptyArea />
        </Box>
    );
};

export default ClientChatHeader;
