import React, {FC} from 'react';
import {FieldProps} from 'formik';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface FormikTextInputTypes {
    name: string;
    label?: string;
    placeholder?: string;
}

const ClientFormikTextInput: FC<FieldProps & FormikTextInputTypes> = ({
    field,
    form: {touched, errors},
    name,
    ...props
}) => {
    const [t] = useTranslation();

    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <>
            <FormControl fullWidth>
                <AutoDirectionTextField fullWidth variant="standard" margin="normal" id={name} {...field} {...props} />
            </FormControl>
            {isTouched && errorMessage && (
                <Typography
                    sx={{
                        mt: 1,
                        color: ERROR_TEXT_COLOR,
                        fontSize: 14,
                    }}
                >
                    {t(String(errorMessage))}
                </Typography>
            )}
        </>
    );
};

export default ClientFormikTextInput;
