import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {getSelectedOptionsList} from 'pages/agent/requesterPage/sections/helper';
import SelectedOption from 'pages/agent/requesterPage/sections/SelectedOption';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface PopupInformationSelectableItemRowType {
    formId: number;
    value: string;
    field: FormFieldTypes;
    keyword: string;
    popupItemId: number;
}

const PopupInformationSelectableItemRow: FC<PopupInformationSelectableItemRowType> = ({
    formId,
    value,
    field,
    keyword,
    popupItemId,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {popupItems},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const options = popupItems.find(item => item.id === popupItemId)?.options;
    const selectedOptions = field.fieldOptions && options ? getSelectedOptionsList(field.fieldOptions, options) : [];

    return (
        <Box sx={{mb: 2}}>
            <Typography variant="body1" sx={{fontWeight: 600, mb: 1}}>
                {`${getFormTranslatedLabel(translations, keyword, field.label)}: `}
            </Typography>
            <Box>
                {selectedOptions &&
                    selectedOptions.map(option => {
                        return (
                            <SelectedOption
                                field={field}
                                formId={formId}
                                option={option}
                                key={`option-${option.uuid}`}
                                clientText={value}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default PopupInformationSelectableItemRow;
