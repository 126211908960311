import React, {FC, useCallback, useContext, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import {FORM_HAS_ADDITIONAL_CREATE} from 'appRedux/actions/forms';
import {FormHasAdditionalCreateTypes, FormHasAdditionalTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getFormsForSelection, FormOptionTypes} from 'components/Forms/FormBuilder/FormHasAdditionalForm/helper';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {preventSendForm} from 'helpers/index';

interface FormHasAdditionalFormType {
    formId: number;
}

const FormHasAdditionalForm: FC<FormHasAdditionalFormType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [selectedValue, setSelectedValue] = useState<number | null>(null);

    const {
        admin: {formsList, formInfo, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitCreateForm = useCallback(
        data => dispatch({type: FORM_HAS_ADDITIONAL_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const formHasAdditionalItems: FormHasAdditionalTypes[] = get(formInfo, 'additionalForms', []);

    const activeFormsList = formsList ? formsList.filter(item => item.isActive) : [];

    const formsForSelection: FormOptionTypes[] = activeFormsList
        ? getFormsForSelection(activeFormsList, formHasAdditionalItems, formId)
        : [];

    if (isLoading) return null;
    return (
        <Formik
            initialValues={{
                id: formId,
                formAdditional: 0,
            }}
            onSubmit={() => {
                submitCreateForm({
                    formAdditional: selectedValue,
                    id: formId,
                    showAlert,
                    callback: () => setSelectedValue(null),
                });
            }}
        >
            {(formik: FormikProps<FormHasAdditionalCreateTypes>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                options={formsForSelection}
                                sx={{width: 300, mr: 2}}
                                onChange={(event, value) => {
                                    if (value) {
                                        setSelectedValue(Number(value.id));
                                    }
                                }}
                                autoHighlight
                                renderInput={params => (
                                    <AutoDirectionTextField
                                        label={t('orguser.forms.formHasAdditional.form')}
                                        placeholder={t('orguser.forms.formHasAdditional.form')}
                                        {...params}
                                    />
                                )}
                            />
                            <AgentSaveButton isSubmit title={t('orguser.forms.addForm')} isDisabled={!selectedValue} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormHasAdditionalForm;
