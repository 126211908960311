import {FormPageTypes, FormFieldTypes} from 'appRedux/actions/forms/types';
import {WorkflowFieldVariableItemTypes} from 'appRedux/actions/workflow/types';

import {TabItemType} from 'components/TabsWrapper/helper';

import {
    OPTIONS_SEPARATOR,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_RESOURCE_FIELD,
    FORM_FIELD_SIGNATURE,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const OPTION_ERROR_SEMICOLON = 1;
export const OPTION_ERROR_MIN_TWO_OPTIONS = 2;
export const OPTION_ERROR_MIN_ONE_OPTION = 3;
export const OPTION_ERROR_REPEAT = 4;
export const OPTION_ERROR_HTML_TAG = 5;
export const OPTION_ERROR_MIN_LESS_MAX = 6;
export const OPTION_ERROR_BOTH_RANGES = 7;

export const getOptionsListError = (optionsErrorType: number | null): string => {
    switch (optionsErrorType) {
        case OPTION_ERROR_SEMICOLON:
            return 'orguser.forms.formField.validation.semicolonValidation';
        case OPTION_ERROR_MIN_TWO_OPTIONS:
            return 'orguser.forms.formField.validation.minTwoOptionsValidation';
        case OPTION_ERROR_MIN_ONE_OPTION:
            return 'orguser.forms.formField.validation.minOneOptionValidation';
        case OPTION_ERROR_REPEAT:
            return 'orguser.forms.formField.validation.optionsRepeatValidation';
        case OPTION_ERROR_HTML_TAG:
            return 'orguser.forms.formField.validation.htmlTagsDenied';
        case OPTION_ERROR_MIN_LESS_MAX:
            return 'orguser.forms.formField.validation.minLessThanMax';
        case OPTION_ERROR_BOTH_RANGES:
            return 'orguser.forms.formField.validation.bothRangesRequired';
    }
    return '';
};

export const getOptionsArray = (optionsString: string): string[] => {
    if (!optionsString) {
        return [];
    }
    if (optionsString.includes(OPTIONS_SEPARATOR)) {
        return optionsString.split(OPTIONS_SEPARATOR);
    }
    return [optionsString];
};

export interface ResourceFieldBudgetOptionTypes {
    value: number;
    label: string;
    isHidden?: boolean;
}

export const TYPE_BUDGET_DEFINITION_REQUESTER = 0;
export const TYPE_BUDGET_DEFINITION_AGENT = 1;
export const TYPE_BUDGET_DEFINITION_BOTH = 2;
export const TYPE_BUDGET_DEFINITION_FIXED = 3;

export const getBudgetDefinitionOptions = (isAgentPage: boolean): ResourceFieldBudgetOptionTypes[] => {
    return [
        {
            value: TYPE_BUDGET_DEFINITION_REQUESTER,
            label: 'orguser.forms.formField.typeBudgetDefinitionOptions.requester',
            isHidden: isAgentPage,
        },
        {
            value: TYPE_BUDGET_DEFINITION_AGENT,
            label: 'orguser.forms.formField.typeBudgetDefinitionOptions.agent',
        },
        {
            value: TYPE_BUDGET_DEFINITION_BOTH,
            label: 'orguser.forms.formField.typeBudgetDefinitionOptions.both',
            isHidden: isAgentPage,
        },
        {
            value: TYPE_BUDGET_DEFINITION_FIXED,
            label: 'orguser.forms.formField.typeBudgetDefinitionOptions.fixed',
        },
    ];
};

export const TYPE_BUDGET_INPUT_TEXT = 0;
export const TYPE_BUDGET_INPUT_CALENDAR = 1;

export const budgetInputTextOptions: ResourceFieldBudgetOptionTypes[] = [
    {
        value: TYPE_BUDGET_INPUT_TEXT,
        label: 'orguser.forms.formField.typeBudgetInputOptions.text',
    },
    {
        value: TYPE_BUDGET_INPUT_CALENDAR,
        label: 'orguser.forms.formField.typeBudgetInputOptions.calendar',
    },
];

export const TYPE_BUDGET_TRANSPARENCY_SHOW = 0;
export const TYPE_BUDGET_TRANSPARENCY_SHOW_EXISTS = 1;

export const budgetTransparency: ResourceFieldBudgetOptionTypes[] = [
    {
        value: TYPE_BUDGET_TRANSPARENCY_SHOW,
        label: 'orguser.forms.formField.typeBudgetTransparencyOptions.show',
    },
    {
        value: TYPE_BUDGET_TRANSPARENCY_SHOW_EXISTS,
        label: 'orguser.forms.formField.typeBudgetTransparencyOptions.showIfExists',
    },
];

export const TAB_FIELD_DATA = 1;
export const TAB_GDPR_SETTINGS = 2;
export const TAB_INTERNAL = 3;

export const tabs: TabItemType[] = [
    {
        title: 'orguser.forms.fieldFormTabs.fieldData',
        tabNumber: TAB_FIELD_DATA,
        isDisabled: false,
    },
    {
        title: 'orguser.forms.fieldFormTabs.gdpr',
        tabNumber: TAB_GDPR_SETTINGS,
        isDisabled: false,
    },
    {
        title: 'orguser.forms.fieldFormTabs.internal',
        tabNumber: TAB_INTERNAL,
        isDisabled: false,
    },
];

export const isMetadataVariableDisabled = (initialValues: FormFieldTypes, isPopup: boolean) => {
    const {isRequired, type} = initialValues;
    return (
        isPopup || !isRequired || [FORM_FIELD_UPLOADER, FORM_FIELD_RESOURCE_FIELD, FORM_FIELD_SIGNATURE].includes(type)
    );
};

export const getSelectedVariablesIds = (
    pages: FormPageTypes[],
    currentWorkflowVariables: WorkflowFieldVariableItemTypes[],
): number[] => {
    const variableIds: number[] = [];
    currentWorkflowVariables.forEach(variable => {
        variableIds.push(variable.id);
    });
    const results: number[] = [];
    pages.forEach(page => {
        const sections = page.sections;
        sections.forEach(section => {
            const fields = section.fields;
            if (fields && fields.length > 0) {
                fields.forEach(field => {
                    if (field.variable && variableIds.includes(field.variable)) {
                        results.push(field.variable);
                    }
                });
            }
        });
    });
    return results;
};
