import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';

import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';
import {GET_REQUESTER_CASE_ALL_FILES} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import {getAllUserFiles} from 'components/FilesListWithGallery/helper';
import FilesListWithGallery from 'components/FilesListWithGallery/FilesListWithGallery';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';
import {useDispatch} from 'react-redux';

interface FilesListType {
    isCurrentOrganizationCase: boolean;
    currentCase: RequesterAllCaseItemTypes;
    isOpened?: boolean;
}

const FilesList: FC<FilesListType> = ({currentCase, isOpened}) => {
    const dispatch = useDispatch();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {showAlert} = useContext(AlertContext);

    const [filesList, setFilesList] = useState<CommonFileType[]>([]);

    const getCaseFilesList = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_ALL_FILES.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (isOpened) {
            getCaseFilesList({
                id: currentCase.caseId,
                showAlert,
            });
        }
    }, [isOpened, currentCase.caseId]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            const allFiles: CommonFileType[] = await getAllUserFiles(currentCase, [], unwrappedCaseKey);
            setFilesList(allFiles);
        }, 200);
        return () => clearTimeout(timer);
    }, [currentCase]);

    return (
        <Box>
            {isOpened && (
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2, flexWrap: 'wrap'}}>
                    <FilesListWithGallery
                        caseId={Number(currentCase.requestCase.id)}
                        allFiles={filesList}
                        deleteNotPossible={true}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FilesList;
