import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {MediaContext} from 'contexts/media/context';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';
import {routes, DEFAULT_PAGE, PARAMETER_PAGE_ID, PARAMETER_SECTION_ID} from 'config/index';

interface AttachedSectionLabelType {
    item: MessageTypes;
    caseId: number;
}

const AttachedSectionLabel: FC<AttachedSectionLabelType> = ({item, caseId}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {formId, pageId, sectionId, sectionTitle, sectionTitleKeyword} = item;
    const createdAtTS = new Date(item.createdAt).getTime();

    const {isClientMode} = useContext(RouteContext);
    const {isMobile} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToSection = () => {
        const currentPage = pageId ?? DEFAULT_PAGE;
        if (isClientMode) {
            navigate(
                `${routes.REQUEST}/${caseId}/form/${formId}?${PARAMETER_PAGE_ID}=${currentPage}&${PARAMETER_SECTION_ID}=${sectionId}`,
            );
        } else {
            navigate(
                `${routes.REQUESTER_CASE}/${caseId}/form/${formId}?${PARAMETER_PAGE_ID}=${currentPage}&${PARAMETER_SECTION_ID}=${sectionId}`,
            );
        }
    };

    return (
        <Box
            data-id={`button#chat-question-${createdAtTS}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                px: 1,
                borderRadius: 2,
                cursor: 'pointer',
                backgroundColor: CLIENT_BACKGROUND_COLOR,
            }}
            onClick={redirectToSection}
        >
            <HelpOutlineIcon />
            <Typography
                sx={{
                    ml: 1,
                    fontWeight: 600,
                    fontSize: isMobile ? 12 : 14,
                }}
            >
                {sectionTitleKeyword && sectionTitle
                    ? `${t('orguser.chat.questionToSection')}: ${getFormTranslatedLabel(
                          translations,
                          sectionTitleKeyword,
                          sectionTitle,
                      )}`
                    : t('orguser.chat.questionToSection')}
            </Typography>
        </Box>
    );
};

export default AttachedSectionLabel;
