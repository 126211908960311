import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {CHANGE_BOARD_PRIORITY, TOGGLE_BOARD_STATUS, GET_MENU_BOARDS_LIST} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import {getBoardIconByType, getBoardTypeLabel, getPermissionTypeLabel} from 'components/Forms/BoardForm/helper';
import DraggableTableRow from 'components/AdminScreenComponents/DraggableTableRow';

import DeleteBoardButton from 'pages/admin/boardsList/DeleteBoardButton';
import UpdateBoardButton from 'pages/admin/boardsList/UpdateBoardButton';
import StatusesList from 'pages/admin/boardsList/statuses/StatusesList';

interface BoardTableRowType {
    item: BoardItemTypes;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number>>;
    isEditEnabled: boolean;
}

const BoardTableRow: FC<BoardTableRowType> = ({item, dragItem, setDragItem, dropTo, setDropTo, isEditEnabled}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {title, workflow, uuid, type, typePermission, statuses, iconType, isActive} = item;

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const [isBoardActive, setIsBoardActive] = useState<boolean>(isActive);

    const changeStatus = useCallback(data => dispatch({type: TOGGLE_BOARD_STATUS.REQUEST, payload: data}), [dispatch]);

    const changePriority = useCallback(
        data => dispatch({type: CHANGE_BOARD_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        changeStatus({
            uuid,
            showAlert,
            callback: () => {
                setIsBoardActive(previous => !previous);
                if (isCaseViewEnabled) {
                    getMenuBoardsList({showAlert});
                }
            },
        });
    };

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => {
                setDragItem('');
                if (isCaseViewEnabled) {
                    getMenuBoardsList({showAlert});
                }
            },
        });
    };

    const Icon = getBoardIconByType(iconType);

    const rowCells = (
        <>
            <TableCell>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Icon />
                    <Typography sx={{ml: 2}}>{title}</Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography>{workflow ? workflow : t('common.none')}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{t(getBoardTypeLabel(type))}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{t(getPermissionTypeLabel(typePermission))}</Typography>
            </TableCell>
            <TableCell>
                <StatusesList statuses={statuses} type={type} />
            </TableCell>
            {isEditEnabled && (
                <>
                    <TableCell>
                        <CustomSwitcher
                            checked={isBoardActive}
                            onClick={handleChangeStatus}
                            disabled={!isEditEnabled}
                        />
                    </TableCell>
                    <TableCell>
                        <UpdateBoardButton item={item} />
                        <DeleteBoardButton item={item} />
                    </TableCell>
                </>
            )}
        </>
    );

    return isEditEnabled ? (
        <DraggableTableRow
            item={item}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            {rowCells}
        </DraggableTableRow>
    ) : (
        <TableRow>{rowCells}</TableRow>
    );
};

export default BoardTableRow;
