import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateFormLabelForm from 'components/Forms/FormBuilder/FormLabelForm/CreateFormLabelForm';

interface AddFormLabelButtonType {
    formId: number;
}

const AddFormLabelButton: FC<AddFormLabelButtonType> = ({formId}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mt: 2}}>
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.forms.formLabels.addLabel')}
                icon={<PlusSvgIcon isActive />}
                onClick={() => setShowModal(true)}
            />
            <ModalWrapper
                title={t('orguser.forms.formLabels.addLabel')}
                isShowModal={showModal}
                toggleModal={toggleModal}
            >
                <CreateFormLabelForm formId={formId} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddFormLabelButton;
