import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {PanelItemsTypes, TextFieldAnalysisItemType} from 'appRedux/actions/analytics/types';

import {PermissionContext} from 'contexts/permission/context';

import TextWrappedLabel from 'components/TextWrappedLabel/TextWrappedLabel';

interface DashboardPanelTextFieldAnalysisType {
    item: PanelItemsTypes;
}

const DashboardPanelTextFieldAnalysis: FC<DashboardPanelTextFieldAnalysisType> = ({item}) => {
    const [t] = useTranslation();

    const {isCaseViewEnabled} = useContext(PermissionContext);

    const {fields} = item;

    if (fields.length === 0) {
        return (
            <Box sx={{p: 2}}>
                <Typography>{t('common.none')}</Typography>
            </Box>
        );
    }

    const cases = item.cases as TextFieldAnalysisItemType[];

    return (
        <Box>
            <Typography sx={{fontWeight: 700, fontSize: 18, textAlign: 'center'}}>{fields[0].title}</Typography>
            <Box sx={{mt: 1, p: 2}}>
                {Array.isArray(cases) &&
                    cases.map((caseItem: TextFieldAnalysisItemType, index: number) => {
                        const {fields} = caseItem;
                        if (fields.length === 0) return null;
                        const selectedField = fields[0];
                        return (
                            <Box
                                key={`case-item-${index}`}
                                sx={{
                                    mb: 2,
                                    borderRadius: 4,
                                    p: 2,
                                    boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                                }}
                            >
                                <TextWrappedLabel text={selectedField.value} />
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default DashboardPanelTextFieldAnalysis;
