import * as yup from 'yup';

import {
    BOARD_TYPE_BOARD,
    BOARD_TYPE_LIST,
    BOARD_TYPE_OVERVIEW,
    BOARD_TYPE_SWIMLANE,
    BOARD_PERMISSION_TYPE_PUBLIC,
} from 'components/Forms/BoardForm/helper';

export const initialValues = {
    title: '',
    type: BOARD_TYPE_BOARD,
    typeSwimlane: null,
    typePermission: BOARD_PERMISSION_TYPE_PUBLIC,
    iconType: 0,
    workflow: 0,
};

export const validationSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
});

export const validationSchemaForOverview = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
});

export const validationUpdateSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
});

export const validationSchemaForStatusList = yup.object({
    status: yup.string().required('messages.validation.isRequired'),
});
