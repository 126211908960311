import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {WorkflowStatusTaskItemTypes, WorkflowStatusTaskFormTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS_TASK} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchemaWorkflowTask} from 'components/Forms/Workflow/validation';

interface UpdateWorkflowStatusTaskRemoveCaseFormType {
    item: WorkflowStatusTaskItemTypes;
}

const UpdateWorkflowStatusTaskRemoveCaseForm: FC<UpdateWorkflowStatusTaskRemoveCaseFormType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_TASK.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, type, period, newStatus} = item;

    const onSaveClicked = (values: WorkflowStatusTaskFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
        });
    };

    return (
        <Box sx={{mt: 2}}>
            <Typography sx={{fontWeight: 600}}>{`${t('orguser.workflows.tasks.period')}:`}</Typography>
            <Formik
                initialValues={{type, period, newStatus}}
                onSubmit={onSaveClicked}
                validation={validationSchemaWorkflowTask}
            >
                {(formik: FormikProps<WorkflowStatusTaskFormTypes>) => {
                    return (
                        <Form>
                            <FormControl required fullWidth>
                                <Field
                                    required
                                    name="period"
                                    type="number"
                                    placeholder={t('orguser.workflows.tasks.period')}
                                    component={FormikTextInput}
                                    isDisabled={isLoading}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        const value = Number(e.target.value);
                                        if (value > 0) {
                                            formik.submitForm();
                                        }
                                    }}
                                />
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateWorkflowStatusTaskRemoveCaseForm;
