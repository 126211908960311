import * as yup from 'yup';

import {calculateEntropy} from 'helpers/calculateEntropy';
import {MAXIMAL_PASSWORD_ENTROPY} from 'helpers/security';

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        nickname: yup.string().required(t('messages.validation.usernameIsRequired')),
        newEmail: yup.string().required(t('messages.validation.emailRequired')),
        password: yup.object({
            first: yup
                .string()
                .test('password', t('messages.validation.passwordIsTooWeak'), value => {
                    return calculateEntropy(value) > MAXIMAL_PASSWORD_ENTROPY;
                })
                .required(t('messages.validation.passwordIsRequired')),
            second: yup
                .string()
                .required(t('messages.validation.passwordIsRequired'))
                .oneOf([yup.ref('first'), null], t('messages.validation.passwordShouldMatch')),
        }),
    });
