import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import UserRoleSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';

interface SetCustomOptionType {
    index: number;
    isCustom: boolean;
    onClick: (index: number) => void;
}

const SetCustomOption: FC<SetCustomOptionType> = ({index, isCustom, onClick}) => {
    const [t] = useTranslation();

    return (
        <IconButton title={t('orguser.forms.formField.setOptionAsCustom')} onClick={() => onClick(index)}>
            <UserRoleSvgIcon isDisabled={!isCustom} />
        </IconButton>
    );
};

export default SetCustomOption;
