import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import {CREATE_TICKET} from 'appRedux/actions/requestCase';
import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {routes, PARAMETER_CURRENT_BOARD} from 'config/index';
import {theme} from 'config/theme';

interface ComponentType {
    closeModal: () => void;
}

const TicketCreationForm: FC<ComponentType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formsList},
        workflow: {currentBoard},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createTicket = useCallback(data => dispatch({type: CREATE_TICKET.REQUEST, payload: data}), [dispatch]);

    const onFormSelected = (e: SelectChangeEvent<HTMLInputElement>) => {
        const formId = Number(e.target.value);
        createTicket({
            formId,
            showAlert,
            callback: (caseId: number) => {
                navigate(`${routes.TICKET}/${caseId}/form/${formId}?${PARAMETER_CURRENT_BOARD}=${currentBoard?.id}`);
            },
        });
    };

    const ticketForms = formsList ? formsList.filter(item => item.isTicket && item.isActive) : [];

    return (
        <Box>
            <FormControl
                sx={{
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 3,
                    pl: 3,
                    pr: 3,
                    pb: 2,
                    mb: 2,
                }}
                required
                fullWidth
            >
                <InputLabel>{t('orguser.forms.selectTicketForm')}</InputLabel>
                <Select
                    sx={{
                        height: 50,
                        mt: 2,
                    }}
                    variant="standard"
                    onChange={onFormSelected}
                    IconComponent={KeyboardArrowDownIcon}
                >
                    {ticketForms &&
                        ticketForms.map((formItem: FormItemBasic) => (
                            <MenuItem value={formItem.id} key={`form-${formItem.id}`}>
                                {formItem.title}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AgentSaveButton isSubmit={false} title={t('common.buttons.close')} onClick={closeModal} />
            </Box>
        </Box>
    );
};

export default TicketCreationForm;
