import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    ORGANIZATION_TIME_BUCKETS_LIST,
    getOrganizationTimeBucketsList,
    CREATE_ORGANIZATION_TIME_BUCKET,
    createOrganizationTimeBucket,
    UPDATE_ORGANIZATION_TIME_BUCKET,
    updateOrganizationTimeBucket,
    DELETE_ORGANIZATION_TIME_BUCKET,
    deleteOrganizationTimeBucket,
} from 'appRedux/actions/organization';
import {
    OrganizationTimeBucketCreateRequestTypes,
    OrganizationTimeBucketDeleteRequestTypes,
    OrganizationTimeBucketUpdateRequestTypes,
    OrganizationTimeBucketsRequestTypes,
    OrganizationTimeBucketsResponseTypes,
} from 'appRedux/actions/organization/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchOrganizationTimeBucketsListRequest() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<OrganizationTimeBucketsRequestTypes> = yield take(ORGANIZATION_TIME_BUCKETS_LIST.REQUEST);
        try {
            const data: OrganizationTimeBucketsResponseTypes = yield call(http, `organization/${id}/bucket/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationTimeBucketsList.success(data.results));
            } else {
                yield put(getOrganizationTimeBucketsList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationTimeBucketsList.error({message: String(e)}));
        }
    }
}

function* watchOrganizationTimeBucketCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationTimeBucketCreateRequestTypes> = yield take(CREATE_ORGANIZATION_TIME_BUCKET.REQUEST);
        try {
            const data: OrganizationTimeBucketsResponseTypes = yield call(http, `organization/${id}/bucket/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationTimeBucket.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createOrganizationTimeBucket.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationTimeBucket.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTimeBucketUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationTimeBucketUpdateRequestTypes> = yield take(UPDATE_ORGANIZATION_TIME_BUCKET.REQUEST);
        try {
            const data: OrganizationTimeBucketsResponseTypes = yield call(http, `organization/${uuid}/bucket/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationTimeBucket.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateOrganizationTimeBucket.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateOrganizationTimeBucket.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTimeBucketDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<OrganizationTimeBucketDeleteRequestTypes> = yield take(DELETE_ORGANIZATION_TIME_BUCKET.REQUEST);
        try {
            const data: OrganizationTimeBucketsResponseTypes = yield call(http, `organization/${uuid}/bucket/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteOrganizationTimeBucket.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteOrganizationTimeBucket.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteOrganizationTimeBucket.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchOrganizationTimeBucketsListRequest),
    fork(watchOrganizationTimeBucketCreation),
    fork(watchOrganizationTimeBucketUpdating),
    fork(watchOrganizationTimeBucketDeleting),
];
