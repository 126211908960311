import {createStyles} from '@material-ui/core';

const mdxEditorStyles = () =>
    createStyles({
        mdxEditorWrapper: {
            display: 'none', // hide mdx editor and placeholder
            width: '100%',
            minHeight: '250px',
            '&:focus': {
                outline: 'none',
            },
            '&:first-child': {
                display: 'block', // show only first child (editor)
            },
            '& li:has(> ol), & li:has(> ul)': {
                listStyleType: 'none',
            },
        },
    });

export const mdxEditorSmallStyles = () =>
    createStyles({
        mdxEditorWrapper: {
            display: 'none', // hide mdx editor and placeholder
            width: '100%',
            minHeight: '100px',
            '&:focus': {
                outline: 'none',
            },
            '&:first-child': {
                display: 'block', // show only first child (editor)
            },
            '& li:has(> ol), & li:has(> ul)': {
                listStyleType: 'none',
            },
        },
    });

export default mdxEditorStyles;
