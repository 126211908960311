import React, {FC, useCallback, useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {FORM_FIELD_ADD_RELATED_SUBFORM, FORM_FIELD_REMOVE_RELATED_SUBFORM} from 'appRedux/actions/forms';
import {FieldOptionType, FormHasAdditionalTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface RelateSubFormButtonType {
    subForms: FormHasAdditionalTypes[];
    colorIndex: number;
    option: FieldOptionType;
    viewOnly: boolean;
}

const RelateSubFormButton: FC<RelateSubFormButtonType> = ({subForms, colorIndex, option, viewOnly}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleFieldAddRelatedSubForm = useCallback(
        data => dispatch({type: FORM_FIELD_ADD_RELATED_SUBFORM.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldRemoveRelatedSubForm = useCallback(
        data => dispatch({type: FORM_FIELD_REMOVE_RELATED_SUBFORM.REQUEST, payload: data}),
        [dispatch],
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onAddSection = (sectionId: number) => {
        handleFieldAddRelatedSubForm({
            uuid: option.uuid,
            form: sectionId,
            showAlert,
            callback: handleClose,
        });
    };
    const onRemoveSection = () => {
        handleFieldRemoveRelatedSubForm({
            uuid: option.uuid,
            showAlert,
            callback: handleClose,
        });
    };

    const handleChange = (sectionId: number) => {
        if (sectionId) {
            onAddSection(sectionId);
        } else {
            onRemoveSection();
        }
    };

    const open = Boolean(anchorEl);

    const selectedSubform = subForms.find(subForm => subForm.id === option.relatedSubForm);

    const buttonLabel = selectedSubform ? selectedSubform.title : t('orguser.forms.formField.relateOptionWithSubform');

    return (
        <>
            <AgentSelectButton
                title={buttonLabel}
                onClick={handleClick}
                open={open}
                isDisabled={viewOnly}
                borderColor={selectedSubform ? theme.palette.info.main : CLIENT_BACKGROUND_COLOR}
            />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClose={handleClose}>
                <MenuItem key={`menuItem-default`} value={''}>
                    <Typography>{t('common.none')}</Typography>
                </MenuItem>
                {subForms.map((item: FormHasAdditionalTypes) => {
                    return (
                        <MenuItem
                            key={`subform-${item.uuid}`}
                            value={item.id.toString()}
                            onClick={() => handleChange(item.id)}
                        >
                            <Typography>{item.title}</Typography>
                        </MenuItem>
                    );
                })}
            </MenuAnchorWrapper>
        </>
    );
};

export default RelateSubFormButton;
