import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {BoardItemTypes, WorkflowEventGroupTypes} from 'appRedux/actions/workflow/types';
import AttachedGroupListItem from 'pages/admin/boardsList/permissions/AttachedGroupListItem';

interface AttachedGroupsListType {
    item: BoardItemTypes;
}

const AttachedGroupsList: FC<AttachedGroupsListType> = ({item}) => {
    const {groups} = item;

    return (
        <Box sx={{mt: 2, mb: 1}}>
            {groups &&
                groups.map((group: WorkflowEventGroupTypes) => {
                    return <AttachedGroupListItem item={group} key={`board-attached-group-${group.uuid}`} />;
                })}
        </Box>
    );
};

export default AttachedGroupsList;
