import React, {FC, useCallback, useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {FORM_FIELD_ADD_RELATED_SECTION, FORM_FIELD_REMOVE_RELATED_SECTION} from 'appRedux/actions/forms';
import {FieldOptionType, FormSectionTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import {CLIENT_BACKGROUND_COLOR, randomColors} from 'config/theme';

interface RelateSectionButtonType {
    sections: FormSectionTypes[];
    colorIndex: number;
    option: FieldOptionType;
    viewOnly: boolean;
}

const RelateSectionButton: FC<RelateSectionButtonType> = ({sections, colorIndex, option, viewOnly}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleFieldAddRelatedSection = useCallback(
        data => dispatch({type: FORM_FIELD_ADD_RELATED_SECTION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldRemoveRelatedSection = useCallback(
        data => dispatch({type: FORM_FIELD_REMOVE_RELATED_SECTION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onAddSection = (sectionId: number) => {
        handleFieldAddRelatedSection({
            uuid: option.uuid,
            section: sectionId,
            showAlert,
            callback: handleClose,
        });
    };
    const onRemoveSection = () => {
        handleFieldRemoveRelatedSection({
            uuid: option.uuid,
            showAlert,
            callback: handleClose,
        });
    };

    const handleChange = (sectionId: number) => {
        if (sectionId) {
            onAddSection(sectionId);
        } else {
            onRemoveSection();
        }
    };

    const open = Boolean(anchorEl);

    const borderColor = colorIndex >= 0 ? randomColors[colorIndex] ?? 'black' : CLIENT_BACKGROUND_COLOR;

    const selectedSection = sections.find(section => section.id === option.relatedSection);

    const buttonLabel = selectedSection ? selectedSection.title : t('orguser.forms.formField.relateOptionWithSection');

    return (
        <>
            <AgentSelectButton
                title={buttonLabel}
                onClick={handleClick}
                open={open}
                isDisabled={viewOnly}
                borderColor={borderColor}
            />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClose={handleClose}>
                <MenuItem key={`menuItem-default`} value={''}>
                    <Typography>{t('common.none')}</Typography>
                </MenuItem>
                {sections.map((item: FormSectionTypes) => {
                    return (
                        <MenuItem
                            key={`menuItem-${item.id}`}
                            value={item.id.toString()}
                            onClick={() => handleChange(item.id)}
                        >
                            <Typography>{`${t('orguser.forms.section')} ${item.priority + 1} - ${
                                item.title
                            }`}</Typography>
                        </MenuItem>
                    );
                })}
            </MenuAnchorWrapper>
        </>
    );
};

export default RelateSectionButton;
