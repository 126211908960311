import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

export const REQUESTER_CASE_HAS_BUDGET_CREATED = 0;
export const REQUESTER_CASE_HAS_BUDGET_RESERVED = 1;
export const REQUESTER_CASE_HAS_BUDGET_BOOKED = 2;
export const REQUESTER_CASE_HAS_BUDGET_CLEARED = 3;

export const getBudgetStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_BUDGET_CREATED:
            return 'requester.casePage.budgetStatus.created';
        case REQUESTER_CASE_HAS_BUDGET_RESERVED:
            return 'requester.casePage.budgetStatus.reserved';
        case REQUESTER_CASE_HAS_BUDGET_BOOKED:
            return 'requester.casePage.budgetStatus.booked';
        case REQUESTER_CASE_HAS_BUDGET_CLEARED:
            return 'requester.casePage.budgetStatus.cleared';
    }
    return '';
};

export const getBudgetStatusDateTime = (budget: RequesterCaseHasBudgetTypes): string => {
    const {status, createdAt, reservedAt, bookedAt, clearedAt} = budget;
    switch (status) {
        case REQUESTER_CASE_HAS_BUDGET_CREATED:
            return createdAt ? convertDateToCurrentLocale(new Date(createdAt), false, true) : '';
        case REQUESTER_CASE_HAS_BUDGET_RESERVED:
            return reservedAt ? convertDateToCurrentLocale(new Date(reservedAt), false, true) : '';
        case REQUESTER_CASE_HAS_BUDGET_BOOKED:
            return bookedAt ? convertDateToCurrentLocale(new Date(bookedAt), false, true) : '';
        case REQUESTER_CASE_HAS_BUDGET_CLEARED:
            return clearedAt ? convertDateToCurrentLocale(new Date(clearedAt), false, true) : '';
    }
    return '';
};
