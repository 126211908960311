import React, {FC} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormItemBasic} from 'appRedux/actions/forms/types';
import {ImpersonatedCaseDetailsFormTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationImpersonatedCaseSchema} from 'components/Forms/ImpersonalCaseForm/validation';

interface ComponentType {
    closeModal: () => void;
    initialValues: ImpersonatedCaseDetailsFormTypes;
    onSubmitAction: (values: ImpersonatedCaseDetailsFormTypes, {setErrors}) => void;
    isCreate?: boolean;
}

const ImpersonalCaseDetailsForm: FC<ComponentType> = ({initialValues, isCreate, closeModal, onSubmitAction}) => {
    const [t] = useTranslation();

    const {
        admin: {formsList},
        requestCase: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const filteredForms = formsList ? formsList.filter(item => item.isImpersonatedCasesEnabled && item.isActive) : [];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationImpersonatedCaseSchema(t)}
            onSubmit={onSubmitAction}
        >
            {(formik: FormikProps<any>) => {
                return (
                    <Form>
                        {isCreate && (
                            <Field
                                as="select"
                                required
                                name={'id'}
                                label={t('orguser.requesterCase.impersonatedCases.parameters.form')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {filteredForms.map((item: FormItemBasic, index: number) => {
                                    return (
                                        <MenuItem key={`form-${index}`} value={item.id}>
                                            <Typography sx={{fontWeight: 600}}>{t(item.title)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Field>
                        )}
                        <Field
                            name="reason"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.reason')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.reason')}
                            component={FormikTextInput}
                            multiline
                            rows={4}
                        />
                        <Field
                            name="firstName"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.firstName')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.firstName')}
                            component={FormikTextInput}
                            required
                        />
                        <Field
                            name="lastName"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.lastName')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.lastName')}
                            component={FormikTextInput}
                            required
                        />
                        <Field
                            name="email"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.email')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.email')}
                            component={FormikTextInput}
                        />
                        <Field
                            name="pinCode"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.pinCode')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.pinCode')}
                            component={FormikTextInput}
                            required
                        />
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                            <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.send')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ImpersonalCaseDetailsForm;
