import React, {FC} from 'react';

import Box from '@mui/material/Box';

interface EmptyAreaType {
    entropy: number;
}

const EntropyBar: FC<EmptyAreaType> = ({entropy}) => {
    const width = Math.min(entropy / 1.2, 100) + '%';
    let backgroundColor;
    if (entropy < 35) {
        backgroundColor = '#f44336';
    } else if (entropy < 60) {
        backgroundColor = '#ff9800';
    } else if (entropy < 100) {
        backgroundColor = '#ffc107';
    } else {
        backgroundColor = '#4caf50';
    }
    return (
        <Box
            sx={{
                mb: -0.5,
                transition: 'width 0.3s',
                height: '4px',
                width: '100%',
                background: entropy > 0 ? '#ccc' : 'transparent',
                marginTop: '5px',
            }}
        >
            {entropy > 0 && <Box sx={{height: '100%', width, backgroundColor}} />}
        </Box>
    );
};

export default EntropyBar;
