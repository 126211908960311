import * as yup from 'yup';
import {TFunction} from 'i18next';

import {FormFieldRequestType} from 'appRedux/actions/forms/types';

import {REGEX_FOR_TOOLTIP, REGEX_FOR_DESCRIPTION, REGEX_FOR_TITLES} from 'components/Forms/FormBuilder/helper';

import {
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_RESOURCE_FIELD,
    TEXTAREA_DEFAULT_LENGTH,
    VALIDATION_TYPE_NONE,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {decimalOrIntegerValidation} from 'helpers/validation';

export const formFieldInitialValues = {
    resourceField: null,
    resourceFieldCalendar: null,
    resourceFieldSlot: null,
    resourceFieldBudget: null,
    type: FORM_FIELD_TEXT_INPUT,
    validationType: VALIDATION_TYPE_NONE,
    label: '',
    description: '',
    options: '',
    relatedSections: '',
    tooltip: '',
    isRequired: false,
    isFilterable: false,
    isTextFieldAnalysis: false,
    isRelationWithSectionEnabled: false,
    isRelationWithSubformEnabled: false,
    minLength: null,
    budgetAmount: 0,
    typeBudgetDefinition: null,
    typeBudgetInput: null,
    typeBudgetTransparency: null,
};

const FIELD_LABEL_MINIMAL_LENGTH = 2;
const FIELD_LABEL_MAXIMAL_LENGTH = 100;

const FIELD_TOOLTIP_MINIMAL_LENGTH = 2;
const FIELD_TOOLTIP_MAXIMAL_LENGTH = 100;

const FIELD_TEXTAREA_MINIMAL_LENGTH = 1;

export const getValidationSchema = (
    t: TFunction,
    isLanguageWithLatinLetters: boolean,
    initialValues: FormFieldRequestType,
) => {
    const isBudgetFormField = initialValues.type === FORM_FIELD_RESOURCE_FIELD && initialValues.resourceFieldBudget;
    if (isLanguageWithLatinLetters) {
        const basicRulesWithLatinLetters = {
            label: yup
                .string()
                .required('messages.validation.isRequired')
                .min(
                    FIELD_LABEL_MINIMAL_LENGTH,
                    t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FIELD_LABEL_MINIMAL_LENGTH)),
                )
                .max(
                    FIELD_LABEL_MAXIMAL_LENGTH,
                    t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FIELD_LABEL_MAXIMAL_LENGTH)),
                ),
            description: yup.string().nullable(),
            tooltip: yup
                .string()
                .nullable()
                .min(
                    FIELD_TOOLTIP_MINIMAL_LENGTH,
                    t('messages.validation.valueMinimalLength').replace(
                        '%NUMBER%',
                        String(FIELD_TOOLTIP_MINIMAL_LENGTH),
                    ),
                )
                .max(
                    FIELD_TOOLTIP_MAXIMAL_LENGTH,
                    t('messages.validation.valueMaximalLength').replace(
                        '%NUMBER%',
                        String(FIELD_TOOLTIP_MAXIMAL_LENGTH),
                    ),
                ),
            options: yup.string().nullable(),
            minLength: yup
                .number()
                .min(0)
                .max(999)
                .nullable(true)
                .transform((_, val) => (val === Number(val) ? val : null)),
        };

        const budgetFieldRulesWithLatinLetters = {
            ...basicRulesWithLatinLetters,
            budgetAmount: yup
                .number()
                .typeError(t('messages.validation.isNumberRequired'))
                .required('messages.validation.isRequired')
                .test('decimal', t('messages.validation.decimalFormatRequired'), value =>
                    decimalOrIntegerValidation(String(value)),
                ),
        };

        return isBudgetFormField
            ? yup.object(budgetFieldRulesWithLatinLetters)
            : yup.object(basicRulesWithLatinLetters);
    }

    const basicRules = {
        label: yup
            .string()
            .required('messages.validation.isRequired')
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                FIELD_LABEL_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FIELD_LABEL_MINIMAL_LENGTH)),
            )
            .max(
                FIELD_LABEL_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FIELD_LABEL_MAXIMAL_LENGTH)),
            ),
        description: yup
            .string()
            .matches(REGEX_FOR_DESCRIPTION, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .nullable(),
        tooltip: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_TOOLTIP, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                FIELD_TOOLTIP_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FIELD_TOOLTIP_MINIMAL_LENGTH)),
            )
            .max(
                FIELD_TOOLTIP_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FIELD_TOOLTIP_MAXIMAL_LENGTH)),
            ),
        options: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed'),
        minLength: yup
            .number()
            .min(0)
            .max(999)
            .nullable(true)
            .transform((_, val) => (val === Number(val) ? val : null)),
    };

    const budgetFieldRules = {
        ...basicRules,
        budgetAmount: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required('messages.validation.isRequired')
            .test('decimal', t('messages.validation.decimalFormatRequired'), value =>
                decimalOrIntegerValidation(String(value)),
            ),
    };

    return isBudgetFormField ? yup.object(budgetFieldRules) : yup.object(basicRules);
};
