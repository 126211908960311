import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
    buttonStyles,
    buttonOutlineStyles,
    labelStyles,
    labelOutlineStyles,
} from 'components/AgentScreenComponents/_buttons/common';

import {theme} from 'config/theme';
import {SxProps} from '@mui/material';

interface SubmitButtonType {
    isLoading?: boolean;
    isDisabled?: boolean;
    borderColor?: string;
    title: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    open: boolean;
    sx?: SxProps;
}

const AgentSelectButton: FC<SubmitButtonType> = ({isLoading, isDisabled, borderColor, open, title, onClick, sx}) => {
    const stylesList = borderColor
        ? {...(open ? buttonStyles : buttonOutlineStyles), ...sx, borderColor}
        : {...(open ? buttonStyles : buttonOutlineStyles), ...sx};
    return (
        <Button
            disabled={isLoading || isDisabled}
            sx={stylesList}
            onClick={onClick}
            endIcon={
                open ? (
                    <KeyboardArrowUpIcon sx={{color: theme.palette.background.default}} />
                ) : (
                    <KeyboardArrowDownIcon sx={{color: theme.palette.info.main}} />
                )
            }
        >
            <Typography sx={open ? labelStyles : labelOutlineStyles}>{title}</Typography>
        </Button>
    );
};

export default AgentSelectButton;
