import React, {FC, RefObject} from 'react';
import {MDXEditorMethods} from '@mdxeditor/editor';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import CustomButtonItemsSelector from '../CodeMirrorField/CustomButtonItemsSelector';

interface CustomMdxEditorButtonType {
    editorRef: RefObject<MDXEditorMethods>;
    title: string;
    markdown: string;
    items: MdxEditorCustomButtonType[];
}

const CustomMdxEditorButton: FC<CustomMdxEditorButtonType> = ({title, markdown, items, editorRef}) => {
    const onCustomButtonClicked = (markdown: string) => {
        editorRef.current?.insertMarkdown(markdown);
    };

    if (items && items.length > 0) {
        return (
            <CustomButtonItemsSelector
                button={{
                    title,
                    markdown,
                    items,
                }}
                key={`custom-button-selector-list`}
                onOptionSelect={onCustomButtonClicked}
            />
        );
    }
    return <AgentSaveButton title={title} isSubmit={false} onClick={() => onCustomButtonClicked(markdown)} />;
};

export default CustomMdxEditorButton;
