import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';

import PopupContentRow from 'pages/client/form/fieldPopup/PopupContentRow';

interface PopupContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    disabled: boolean;
    isAgentPage: boolean;
    initialItems: RequesterCasePopupType[];
    onEditClick: (value: number | null) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    selectedIndex: number | null;
    clientInformation: unknown;
    agentInformation: unknown;
}

const PopupContent: FC<PopupContentType> = ({
    formId,
    popup,
    pageId,
    sectionId,
    disabled,
    initialItems,
    onEditClick,
    onSaveClicked,
    selectedIndex,
    clientInformation,
    agentInformation,
    isAgentPage,
}) => {
    console.log(initialItems);
    return (
        <Box>
            {initialItems
                .sort((a, b) => a.id - b.id)
                .map((item: RequesterCasePopupType, index: number) => {
                    return (
                        <PopupContentRow
                            key={`popup-${index}`}
                            pageId={pageId}
                            formId={formId}
                            sectionId={sectionId}
                            popup={popup}
                            currentIndex={index}
                            initialItem={item}
                            onEditClick={onEditClick}
                            disabled={disabled}
                            onSaveClicked={onSaveClicked}
                            isSelected={selectedIndex === index}
                            isAgentPage={isAgentPage}
                            clientInformation={clientInformation}
                            agentInformation={agentInformation}
                            lastOne={index === initialItems.length - 1}
                        />
                    );
                })}
        </Box>
    );
};

export default PopupContent;
