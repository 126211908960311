import React, {FC, useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {AdminContext} from 'contexts/admin/context';
import {MediaContext} from 'contexts/media/context';

import {theme} from 'config/theme';

const MINIMAL_SEARCH_LENGTH = 2;

const AgentChatTopBar: FC = () => {
    const [t] = useTranslation();

    const ref = useRef<HTMLInputElement | null>(null);

    const {searchValue, setSearchValue} = useContext(AdminContext);
    const {isMobile, isTablet} = useContext(MediaContext);

    const onIconClicked = () => {
        if (searchValue) {
            setSearchValue('');
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        const searchOnFocus = (keyEvent: KeyboardEvent) => {
            if (keyEvent && keyEvent.ctrlKey && keyEvent.charCode === 5) {
                if (ref && ref.current) {
                    ref.current.focus();
                }
            }
        };
        window.addEventListener('keypress', searchOnFocus);
        return () => window.removeEventListener('keypress', searchOnFocus);
    }, []);

    const isPortable = isMobile || isTablet;

    return (
        <FormControl sx={{width: isPortable ? '100%' : 330}}>
            <Input
                fullWidth
                inputRef={ref}
                inputProps={{className: 'search'}}
                placeholder={t('common.searchFieldPlaceholder')}
                onChange={handleSearchChange}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    width: isPortable ? '100%' : 330,
                    height: 16,
                    borderRadius: 8,
                    pl: 4,
                    p: 2,
                    mb: 2,
                }}
                value={searchValue}
                disableUnderline
                endAdornment={
                    <InputAdornment position="start" onClick={onIconClicked} sx={{cursor: 'pointer'}}>
                        {searchValue && searchValue.length >= MINIMAL_SEARCH_LENGTH ? (
                            <CancelSvgIcon />
                        ) : (
                            <SearchIcon />
                        )}
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default AgentChatTopBar;
