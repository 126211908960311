import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {ResourceFieldBookframeFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationBookframeSchema} from 'components/Forms/ResourceField/validation';

import {resourceFieldWeekdayTypes, ResourceFieldOptionType} from 'components/Forms/ResourceField/helper';

interface ResourceFieldBookframeFormType {
    initialValues: ResourceFieldBookframeFormTypes;
    onSubmitClicked: (values: ResourceFieldBookframeFormTypes) => void;
}

const ResourceFieldBookframeForm: FC<ResourceFieldBookframeFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationBookframeSchema}
            onSubmit={(values, {setErrors}) =>
                onSubmitClicked({
                    ...values,
                    setErrors,
                })
            }
        >
            {(formik: FormikProps<ResourceFieldBookframeFormTypes>) => {
                return (
                    <Form>
                        <Field
                            as="select"
                            required
                            name="weekday"
                            label={t('orguser.resourceFields.weekday')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {resourceFieldWeekdayTypes &&
                                resourceFieldWeekdayTypes.map((item: ResourceFieldOptionType) => {
                                    const {label, value} = item;
                                    return (
                                        <MenuItem key={`weekday-${value}`} value={value}>
                                            <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                        <Field
                            required
                            name="startTime"
                            type="time"
                            label={t('orguser.resourceFields.startTime')}
                            placeholder={t('orguser.resourceFields.startTime')}
                            component={FormikTextInput}
                        />
                        <Field
                            required
                            name="endTime"
                            type="time"
                            label={t('orguser.resourceFields.endTime')}
                            placeholder={t('orguser.resourceFields.endTime')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldBookframeForm;
