import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import {WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowRuleOptionForm from 'components/Forms/Workflow/workflowStatusRule/WorkflowRuleOptionForm';

import Approvers from 'pages/admin/updateWorkflow/partials/rules/approvers/Approvers';
import ApproverGroups from 'pages/admin/updateWorkflow/partials/rules/groups/ApproverGroups';
import {isRuleNotCompleted} from 'pages/admin/updateWorkflow/partials/rules/helper';

interface WorkflowRuleApprovalDetailsType {
    item: WorkflowStatusRuleItemTypes;
}

const WorkflowRuleApprovalDetails: FC<WorkflowRuleApprovalDetailsType> = ({item}) => {
    const [t] = useTranslation();

    const hasNotCompletedRule = isRuleNotCompleted(item);

    return (
        <Box>
            <Divider sx={{mt: 1, mb: 2}} />
            <Typography sx={{fontWeight: 600, mt: 1, mb: 1}}>{`${t(
                'orguser.workflows.rules.approversList',
            )}:`}</Typography>
            {hasNotCompletedRule && (
                <Alert severity={'error'} sx={{mt: 2, mb: 2}}>
                    <Typography>{t('orguser.workflows.rules.hasNotCompletedRules')}</Typography>
                </Alert>
            )}
            <Approvers item={item} />
            <ApproverGroups item={item} />
            <WorkflowRuleOptionForm item={item} />
        </Box>
    );
};

export default WorkflowRuleApprovalDetails;
