import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import {checkWorkflowStatusHasTranslations, showLanguageSelector} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE} from 'config/index';

interface TranslationSelectorType {
    organizationLanguage: LanguageItemTypes;
}

const TranslationSelector: FC<TranslationSelectorType> = ({organizationLanguage}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {organizationLanguages},
        workflow: {workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: string) => {
        searchParams.set(PARAMETER_LANGUAGE, value);
        setSearchParams(searchParams);
        setAnchorEl(null);
    };

    const currentLanguageName = searchParams.get(PARAMETER_LANGUAGE)
        ? searchParams.get(PARAMETER_LANGUAGE)
        : organizationLanguage.name;

    const open = Boolean(anchorEl);

    const activeOrganizationLanguages = organizationLanguages
        ? organizationLanguages.filter(item => item.isActive)
        : [];

    if (!showLanguageSelector(activeOrganizationLanguages, organizationLanguage)) {
        return null;
    }
    return (
        <>
            <AgentSelectButton
                title={`${t('orguser.forms.selectLanguage')}: ${currentLanguageName}`}
                onClick={handleClick}
                open={open}
            />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                <MenuItem
                    value={organizationLanguage.name}
                    key={`form-language-${organizationLanguage.name}`}
                    onClick={() => handleChange(organizationLanguage.name)}
                >
                    {organizationLanguage.lang}
                </MenuItem>
                {activeOrganizationLanguages &&
                    activeOrganizationLanguages.map((item: OrganizationLanguageTypes) => {
                        const {languageId, languageTitle, languageName, uuid} = item;
                        if (languageId !== organizationLanguage.id) {
                            const currentWorkflowStatusTranslation =
                                organizationLanguage.id === languageId
                                    ? true
                                    : checkWorkflowStatusHasTranslations(workflowStatusesList, languageId);
                            return (
                                <MenuItem
                                    value={languageId}
                                    key={`organization-language-${uuid}`}
                                    onClick={() => handleChange(languageName)}
                                >
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={Boolean(currentWorkflowStatusTranslation)}
                                        sx={{
                                            '& span': {
                                                right: '-5px',
                                            },
                                        }}
                                    >
                                        <Typography>{languageTitle}</Typography>
                                    </Badge>
                                </MenuItem>
                            );
                        }
                    })}
            </MenuAnchorWrapper>
        </>
    );
};

export default TranslationSelector;
