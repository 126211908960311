import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

export const checkIsFormTotallyCompleted = (
    pages: FormPageTypes[],
    clientInformation: RequesterCaseItemType,
): boolean => {
    if (Object.keys(clientInformation).length === 0) {
        return false;
    }

    for (let i = 0, n = pages.length; i < n; i++) {
        const page = pages[i];
        const isPageCompleted = `page-${page.id}` in clientInformation;
        if (!isPageCompleted) {
            //             return false;
        }
    }
    return true;
};
