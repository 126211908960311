import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ADD_REQUESTER_CASE_COMMENT} from 'appRedux/actions/comments';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';
import {CommentFormType} from 'appRedux/actions/comments/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import CurrentUserAvatar from 'components/UserAvatar/CurrentUserAvatar';
import CommentForm from 'components/Forms/CommentForm/CommentForm';

import {encryptStringWithKey} from 'helpers/cryptoApiHelper';

interface NewCommentPanelType {
    sectionId: number | null;
    setIsCommentsExpanded: (value: boolean) => void;
}

const NewCommentPanel: FC<NewCommentPanelType> = ({sectionId, setIsCommentsExpanded}) => {
    const [t] = useTranslation();
    const {caseId} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const {
        requestCase: {
            currentCase: {isEncryptInfo, stack},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const addNewComment = useCallback(
        data => dispatch({type: ADD_REQUESTER_CASE_COMMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);

    const toggleIsFormOpened = () => {
        setIsFormOpened(previous => !previous);
    };

    const addComment = async (values: CommentFormType) => {
        addNewComment({
            ...values,
            forStack: values.forStack ? 1 : 0,
            text:
                isEncryptInfo && unwrappedCaseKey
                    ? await encryptStringWithKey(values.text, unwrappedCaseKey)
                    : values.text,
            id: Number(caseId),
            callback: (isAgentAssigned?: boolean) => {
                setIsFormOpened(false);
                setIsCommentsExpanded(true);
                if (isAgentAssigned) {
                    getRequesterCaseInfo({id: caseId});
                }
            },
            showAlert,
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: isFormOpened ? 'flex-start' : 'center', mt: 2}}>
            <CurrentUserAvatar size={22} />
            <Box sx={{ml: 2, width: '100%'}}>
                {isFormOpened ? (
                    <CommentForm
                        initialValues={{
                            text: '',
                            isPublic: false,
                            section: sectionId ?? null,
                            forStack: false,
                        }}
                        closeForm={toggleIsFormOpened}
                        handleSubmit={addComment}
                        inStack={!!stack}
                    />
                ) : (
                    <Typography
                        onClick={toggleIsFormOpened}
                        sx={{
                            lineHeight: '22px',
                            cursor: 'pointer',
                            color: 'rgba(34, 34, 34, 0.4)',
                        }}
                    >
                        {t('orguser.requesterCase.comments.writeComment')}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default NewCommentPanel;
