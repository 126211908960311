import {OrganizationPdfAttachmentTypes} from 'appRedux/actions/organization/types';

export const getCurrentTemplates = (
    pdfTemplateAttachments: Record<string, OrganizationPdfAttachmentTypes[]>,
    currentLang: string,
    orgLang?: string,
) => {
    const currentLangTemplates = pdfTemplateAttachments[currentLang];
    const otherAttachments = {...pdfTemplateAttachments};

    if (
        currentLangTemplates &&
        (currentLangTemplates.find(tmp => tmp.isFrontPage) || currentLangTemplates.find(tmp => tmp.isContentPage))
    )
        return currentLangTemplates;
    if (
        currentLangTemplates &&
        !currentLangTemplates.find(tmp => tmp.isFrontPage) &&
        !currentLangTemplates.find(tmp => tmp.isContentPage)
    ) {
        delete otherAttachments[currentLang];

        if (orgLang && orgLang !== currentLang) return getCurrentTemplates(otherAttachments, orgLang);
        else {
            const otherKey = Object.keys(otherAttachments)[0];
            return getCurrentTemplates(otherAttachments, otherKey);
        }
    }
    return currentLangTemplates;
};
