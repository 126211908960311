import React, {FC, ChangeEvent, useState, useCallback, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import {FORM_FIELD_DELETE_OPTION, FORM_FIELD_CREATE_OPTION, FORM_FIELD_UPDATE_OPTION} from 'appRedux/actions/forms';
import {FieldOptionType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import EmptyArea from 'components/EmptyArea';
import UploadOptionFile from 'components/Forms/FormBuilder/FieldForm/partialsOptions/UploadOptionFile';
import {OPTION_ERROR_REPEAT} from 'components/Forms/FormBuilder/FieldForm/helper';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';
import ImageOptionsListItem from 'components/Forms/FormBuilder/FieldForm/partialsOptions/ImageOptionsListItem';

import {base64ToFile} from 'pages/client/form/fieldTypes/helper';

import {theme} from 'config/theme';

interface ImageOptionsListType {
    fieldType: number;
    setShowOptionsError: (value: boolean) => void;
    fieldOptions: FieldOptionType[];
    optionErrorType: number | null;
    setOptionErrorType: (value: number | null) => void;
    fieldId?: number;
    validateOptions: (fieldOptions: FieldOptionType[], fieldType: number) => void;
}

const ImageOptionsList: FC<ImageOptionsListType> = ({
    fieldType,
    setShowOptionsError,
    fieldOptions,
    fieldId,
    optionErrorType,
    setOptionErrorType,
    validateOptions,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showAddInput, setShowAddInput] = useState<boolean>(false);
    const [editedIndex, setEditedIndex] = useState<number | null>(null);
    const [loadingOptions, setLoadingOptions] = useState<number[]>([]);
    const [updatedOption, setUpdatedOption] = useState<string>('');
    const [updatedImage, setUpdatedImage] = useState<string | null>();
    const [dragItem, setDragItem] = useState<string>('');
    const [dropTo, setDropTo] = useState<number>(0);

    useEffect(() => {
        validateOptions(fieldOptions, fieldType);
    }, [validateOptions, fieldOptions, fieldType]);

    const createFieldOptionFile = useCallback(
        data => dispatch({type: FORM_FIELD_CREATE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const updateFieldOptionFile = useCallback(
        data => dispatch({type: FORM_FIELD_UPDATE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const deleteFieldOptionFile = useCallback(
        data => dispatch({type: FORM_FIELD_DELETE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleUpdate = (e: ChangeEvent<{value: unknown}>) => {
        const value = e.target.value as string;
        setUpdatedOption(value);
    };

    const toggleAddInput = () => {
        setShowOptionsError(false);
        setEditedIndex(null);
        setUpdatedImage(null);
        setUpdatedOption('');
        setShowAddInput(previous => !previous);
    };

    const toggleEdit = (index: number) => {
        setShowOptionsError(false);
        setShowAddInput(false);
        setEditedIndex(index);
        setUpdatedOption(fieldOptions?.[index]?.title);
        setUpdatedImage(null);
    };

    const closeEdit = () => {
        setShowOptionsError(false);
        setShowAddInput(false);
        setEditedIndex(null);
        setUpdatedOption('');
        setUpdatedImage(null);
    };

    const onAddNewClick = () => {
        if (editedIndex !== null) return onUpdateClick(editedIndex, 'new');
        if (updatedOption.length > 0 && updatedImage) return onAddClick('new');
        if (updatedOption.length > 0 || updatedImage) return;
        toggleAddInput();
    };

    const onEditClick = (index: number) => {
        if (editedIndex !== null) return onUpdateClick(editedIndex, index);
        if (updatedOption.length > 0 && updatedImage) return onAddClick(index);
        if (updatedOption.length > 0 || updatedImage) return;
        toggleEdit(index);
    };

    const onAddClick = async (move?: 'new' | number) => {
        if (updatedOption.length === 0 || !updatedImage || optionErrorType) return;
        const newIndex = fieldOptions.length;
        setLoadingOptions(prev => [...prev, newIndex]);

        const file = await base64ToFile(updatedImage);

        createFieldOptionFile({
            fieldId,
            file,
            title: String(updatedOption),
            priority: newIndex + 1,
            showAlert,
            callback: () => {
                setLoadingOptions(prev => prev.filter(i => i !== newIndex));
                toggleAddInput();
                setTimeout(() => {
                    if (move === 'new') {
                        setShowAddInput(true);
                    } else if (move !== undefined) {
                        toggleEdit(move);
                    } else {
                        closeEdit();
                    }
                }, 10);
            },
        });
    };

    const onUpdateClick = async (index: number, move?: 'new' | number) => {
        if (editedIndex === null || optionErrorType) return;

        if (updatedImage || updatedOption) {
            setLoadingOptions(prev => [...prev, index]);

            const file = updatedImage && (await base64ToFile(updatedImage));

            await new Promise(resolve => {
                updateFieldOptionFile({
                    file,
                    uuid: fieldOptions?.[index]?.uuid,
                    title: String(updatedOption),
                    priority: index,
                    showAlert,
                    callback: () => {
                        setLoadingOptions(prev => prev.filter(i => i !== index));
                        resolve('');
                    },
                });
            });
        }

        if (!updatedOption) {
            await onDeleteClick(index);
        }

        if (move === 'new') {
            toggleAddInput();
        } else if (move !== undefined) {
            toggleEdit(move);
        } else {
            closeEdit();
        }
    };

    const onDeleteClick = async (index: number) => {
        setLoadingOptions(prev => [...prev, index]);

        new Promise(resolve => {
            deleteFieldOptionFile({
                fieldId: fieldOptions?.[index]?.uuid,
                uuid: fieldOptions?.[index]?.uuid,
                optionIndex: index,
                showAlert,
                callback: () => {
                    setLoadingOptions(prev => prev.filter(i => i !== index));
                    resolve('');
                },
            });
        });
    };

    const checkTabPress = (e: React.KeyboardEvent<HTMLDivElement>, index?: number) => {
        if (e.shiftKey && e.key === 'Tab') {
            index !== undefined && index >= 0
                ? index === 0
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index - 1)
                : onAddClick(fieldOptions.length - 1);
        } else if (e.key === 'Tab') {
            index !== undefined && index < fieldOptions.length
                ? index === fieldOptions.length - 1
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index + 1)
                : onAddClick('new');
        }
    };

    const findDuplicates = (title: string): boolean => {
        let options = [...fieldOptions];
        if (editedIndex !== null) options = options.filter((_, i) => editedIndex !== i);

        const result = options.find(opt => opt.title === title);
        setOptionErrorType(result ? OPTION_ERROR_REPEAT : null);

        return !!result;
    };

    return (
        <Box sx={{mt: 1, mb: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="body2" sx={{mb: 1}}>
                    {t('orguser.forms.formField.options')}
                </Typography>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.light,
                        '&:hover': {backgroundColor: theme.palette.secondary.contrastText},
                    }}
                    onClick={onAddNewClick}
                >
                    <AddIcon fontSize="small" sx={{color: theme.palette.background.default}} />
                </IconButton>
            </Box>
            {fieldOptions.map((option: FieldOptionType, index: number) => {
                return (
                    <ImageOptionsListItem
                        key={`option-${option.uuid}`}
                        option={option}
                        fieldOptions={fieldOptions}
                        keyIndex={index}
                        editedIndex={editedIndex}
                        updatedImage={updatedImage}
                        setUpdatedImage={setUpdatedImage}
                        updatedOption={updatedOption}
                        loadingOptions={loadingOptions}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                        handleUpdate={handleUpdate}
                        onUpdateClick={onUpdateClick}
                        findDuplicates={findDuplicates}
                        checkTabPress={checkTabPress}
                        dragItem={dragItem}
                        setDragItem={setDragItem}
                        dropTo={dropTo}
                        setDropTo={setDropTo}
                    />
                );
            })}
            {showAddInput && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        p: 1,
                        pl: 2,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    <UploadOptionFile
                        clickFileInput
                        file={updatedImage}
                        setFile={setUpdatedImage}
                        loading={loadingOptions.includes(fieldOptions.length)}
                    />
                    <Box sx={{width: '90%', ml: 1}}>
                        <AutoDirectionTextField
                            sx={{'& div': {height: 40}}}
                            fullWidth
                            value={updatedOption}
                            onChange={handleUpdate}
                            autoFocus
                            onKeyDown={e => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                }
                            }}
                            onKeyUp={e => {
                                e.preventDefault();
                                checkTabPress(e);
                            }}
                        />
                    </Box>
                    <EmptyArea />
                    <Box sx={{ml: 1}}>
                        {updatedOption.length > 0 && updatedImage ? (
                            <IconButton disabled={findDuplicates(updatedOption)} onClick={() => onAddClick()}>
                                <CheckIcon fontSize="small" />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => toggleAddInput()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ImageOptionsList;
