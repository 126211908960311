import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';

import {TRANSLATE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {
    CustomAttributeTranslationFormTypes,
    CustomAttributeTranslationTypes,
} from 'appRedux/actions/customAttributes/types';

import {AlertContext} from 'contexts/alert/context';

import {validationTranslationSchema} from 'components/Forms/CustomAttributes/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {theme} from 'config/theme';

interface ComponentType {
    uuid: string;
    keyword: string;
    language: number;
    originTitle: string;
    currentTranslation?: CustomAttributeTranslationTypes;
}

const CustomAttributeTranslationForm: FC<ComponentType> = ({
    uuid,
    keyword,
    language,
    originTitle,
    currentTranslation,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const translateCustomAttribute = useCallback(
        data => dispatch({type: TRANSLATE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributeTranslationFormTypes) => {
        translateCustomAttribute({
            uuid,
            showAlert,
            ...values,
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                language,
                keyword,
                translation: currentTranslation ? currentTranslation.translation : '',
            }}
            validationSchema={() => validationTranslationSchema()}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<CustomAttributeTranslationFormTypes>) => {
                const isChanged = currentTranslation && currentTranslation.translation !== formik.values.translation;
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{width: '95%', pr: 2}}>
                                <Field
                                    required
                                    name="translation"
                                    placeholder={`${t('common.buttons.translate')}: ${originTitle}`}
                                    component={FormikTextInput}
                                />
                            </Box>
                            <Box sx={{width: '5%'}}>
                                <IconButton
                                    type={'submit'}
                                    sx={{
                                        backgroundColor: isChanged
                                            ? theme.palette.info.main
                                            : theme.palette.warning.contrastText,
                                        '&:hover': {
                                            backgroundColor: isChanged
                                                ? theme.palette.info.main
                                                : theme.palette.warning.contrastText,
                                        },
                                    }}
                                >
                                    <CheckSvgIcon isActive={isChanged} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CustomAttributeTranslationForm;
