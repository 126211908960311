import React, {FC} from 'react';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationTimeBucketsTypes} from 'appRedux/actions/organization/types';

import DeleteTimeBucketButton from 'pages/admin/organizationTimeTracking/DeleteTimeBucketButton';
import EditTimeBucketButton from 'pages/admin/organizationTimeTracking/EditTimeBucketButton';

interface TimeBucketRowType {
    item: OrganizationTimeBucketsTypes;
    currentUserLanguage?: string;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const TimeBucketRow: FC<TimeBucketRowType> = ({item}) => {
    const {title, description} = item;

    return (
        <TableRow>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>{description}</TableCell>
            <TableCell width={150}>
                <EditTimeBucketButton item={item} />
                <DeleteTimeBucketButton item={item} />
            </TableCell>
        </TableRow>
    );
};

export default TimeBucketRow;
