import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {ORGANIZATION_ADMIN_LIST} from 'appRedux/actions/organizationAdmin';
import {GET_ROLES_LIST} from 'appRedux/actions/roles';
import {FORMS_LIST_BY_ORGANIZATION} from 'appRedux/actions/forms';
import {GET_CUSTOM_ATTRIBUTES_LIST} from 'appRedux/actions/customAttributes';
import {OrganizationUserListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import OrganizationAdminDataGrid from 'components/OrganizationAdminsList/OrganizationAdminDataGrid';

interface OrganizationTeamType {
    organizationId: number;
}

const OrganizationTeam: FC<OrganizationTeamType> = ({organizationId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isSuperAdminPage} = useContext(RouteContext);
    const {isRoleViewEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const getRolesList = useCallback(data => dispatch({type: GET_ROLES_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getCustomAttributesList = useCallback(
        data => dispatch({type: GET_CUSTOM_ATTRIBUTES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationAdminsList = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getOrganizationAdminsList({
            id: organizationId,
        });
    }, [organizationId]);

    const organizationAdminListArr: OrganizationUserListTypes[] = organizationAdminList
        ? organizationAdminList.map(item => {
              const {admin, id} = item;
              return {
                  ...admin,
                  id,
                  item,
              };
          })
        : [];

    useEffect(() => {
        if (isRoleViewEnabled && organizationId) {
            getRolesList({
                id: organizationId,
                showAlert,
            });
            getFormsListByOrganization({
                id: organizationId,
                showAlert,
            });
            getCustomAttributesList({
                showAlert,
            });
        }
    }, [organizationId]);

    return (
        <>
            <DataGridWrapper
                title={isSuperAdminPage ? t('superadmin.organizations.organizationUsers') : ''}
                itemsList={organizationAdminListArr}
                itemsTotalNumber={organizationAdminListArr ? organizationAdminList.length : 0}
            >
                {organizationAdminListArr && (
                    <OrganizationAdminDataGrid organizationAdminList={organizationAdminListArr} />
                )}
            </DataGridWrapper>
        </>
    );
};

export default OrganizationTeam;
