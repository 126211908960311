import {requestTypeCreator} from 'helpers/index';

import {
    AgentItemBasic,
    AgentCasesActivitiesRequestTypes,
    ToggleLanguageRequestTypes,
    LanguageItemTypes,
    ListItemsRequestTypes,
    RequesterCaseDragAndDropType,
    ListItemsRequestTypesResponseTypes,
    ListUpdatedItemsRequestTypesResponseTypes,
    AgentTemplateType,
    AgentTemplateRequestType,
    RequesterCaseMultipleAssignAgentType,
    RequesterCaseMultipleChangeStatusType,
    RequesterCaseMultipleTogglePinningType,
    RequesterCaseExportType,
    RequesterCaseMultipleType,
    RequesterCaseMultipleChangeTagType,
    BoardItemsResponseType,
    BoardResponseType,
    BoardAlphabetResponseType,
    BoardColumnItemsRequestTypes,
    CustomBoardColumnItemsRequestTypes,
    CustomBoardItemsRequestTypes,
    BoardOverviewRequestTypes,
    SwimlaneBoardResponseType,
    CustomSwimlaneItemsRequestTypes,
    TableResponseType,
    TableRequestTypes,
    RequesterCaseStackCreateType,
    RequesterCaseStackDeleteType,
} from 'appRedux/actions/admin/types';
import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';

export const AGENT_LIST = requestTypeCreator('AGENT_LIST');
export const AGENT_TEMPLATE_GET = requestTypeCreator('AGENT_TEMPLATE_GET');
export const AGENT_TEMPLATE_UPDATE = requestTypeCreator('AGENT_TEMPLATE_UPDATE');

export const CASES_ACTIVITIES_LIST = requestTypeCreator('CASES_ACTIVITIES_LIST');

export const REQUESTER_CASES_LIST = requestTypeCreator('REQUESTER_CASES_LIST', {
    REQUEST_EMPTY: 'REQUEST_EMPTY',
    REQUEST_CUSTOM_BOARD: 'REQUEST_CUSTOM_BOARD',
    SUCCESS_CUSTOM_BOARD: 'SUCCESS_CUSTOM_BOARD',
    REQUEST_BOARD_COLUMN: 'REQUEST_BOARD_COLUMN',
    SUCCESS_BOARD_COLUMN: 'SUCCESS_BOARD_COLUMN',
    REQUEST_CUSTOM_BOARD_COLUMN: 'REQUEST_CUSTOM_BOARD_COLUMN',
    SUCCESS_CUSTOM_BOARD_COLUMN: 'SUCCESS_CUSTOM_BOARD_COLUMN',
    REQUEST_CUSTOM_BOARD_ALPHABET: 'REQUEST_CUSTOM_BOARD_ALPHABET',
    SUCCESS_CUSTOM_BOARD_ALPHABET: 'SUCCESS_CUSTOM_BOARD_ALPHABET',
    REQUEST_CUSTOM_BOARD_ALPHABET_UPDATE: 'REQUEST_CUSTOM_BOARD_ALPHABET_UPDATE',
    SUCCESS_CUSTOM_BOARD_ALPHABET_UPDATE: 'SUCCESS_CUSTOM_BOARD_ALPHABET_UPDATE',
    REQUEST_CUSTOM_BOARD_OVERVIEW: 'REQUEST_CUSTOM_BOARD_OVERVIEW',
    SUCCESS_CUSTOM_BOARD_OVERVIEW: 'SUCCESS_CUSTOM_BOARD_OVERVIEW',
    REQUEST_SWIMLANE_BOARD: 'REQUEST_SWIMLANE_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
    REQUEST_LOAD_SWIMLANE: 'REQUEST_LOAD_SWIMLANE',
    SUCCESS_LOAD_SWIMLANE: 'SUCCESS_LOAD_SWIMLANE',
    REQUEST_TABLE: 'REQUEST_TABLE',
    SUCCESS_TABLE: 'SUCCESS_TABLE',
});

export const GET_PINNED_CASES = requestTypeCreator('GET_PINNED_CASES');
export const GET_WAITING_APPROVE_CASES = requestTypeCreator('GET_WAITING_APPROVE_CASES');

export const LANGUAGE_LIST = requestTypeCreator('LANGUAGE_LIST');
export const TOGGLE_LANGUAGE = requestTypeCreator('TOGGLE_LANGUAGE');

export const REQUEST_CASE_ASSIGN_AGENT_MULTIPLE = requestTypeCreator('REQUEST_CASE_ASSIGN_AGENT_MULTIPLE', {
    SUCCESS_PINNED_LIST: 'SUCCESS_PINNED_LIST',
    SUCCESS_BOARD_VIEW: 'SUCCESS_BOARD_VIEW',
    SUCCESS_ALPHABET_LIST: 'SUCCESS_ALPHABET_LIST',
    SUCCESS_OVERVIEW_BOARD: 'SUCCESS_OVERVIEW_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
    SUCCESS_TABLE: 'SUCCESS_TABLE',
});

export const REQUEST_CASE_DRAG_AND_DROP = requestTypeCreator('REQUEST_CASE_DRAG_AND_DROP');

export const REQUEST_CASE_CHANGE_STATUS_MULTIPLE = requestTypeCreator('REQUEST_CASE_CHANGE_STATUS_MULTIPLE', {
    SUCCESS_PINNED_LIST: 'SUCCESS_PINNED_LIST',
    SUCCESS_BOARD_VIEW: 'SUCCESS_BOARD_VIEW',
    SUCCESS_ALPHABET_LIST: 'SUCCESS_ALPHABET_LIST',
    SUCCESS_OVERVIEW_BOARD: 'SUCCESS_OVERVIEW_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
    SUCCESS_TABLE: 'SUCCESS_TABLE',
});

export const REQUEST_CASE_TOGGLE_PINNING_MULTIPLE = requestTypeCreator('REQUEST_CASE_TOGGLE_PINNING_MULTIPLE', {
    SUCCESS_PINNED_LIST: 'SUCCESS_PINNED_LIST',
    SUCCESS_BOARD_VIEW: 'SUCCESS_BOARD_VIEW',
    SUCCESS_ALPHABET_LIST: 'SUCCESS_ALPHABET_LIST',
    SUCCESS_OVERVIEW_BOARD: 'SUCCESS_OVERVIEW_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
    SUCCESS_TABLE: 'SUCCESS_TABLE',
});

export const REQUEST_CASE_EXPORT = requestTypeCreator('REQUEST_CASE_EXPORT');
export const REQUEST_CASE_EXPORT_MULTIPLE = requestTypeCreator('REQUEST_CASE_EXPORT_MULTIPLE');

export const REQUEST_CASE_CHANGE_TAG_MULTIPLE = requestTypeCreator('REQUEST_CASE_CHANGE_TAG_MULTIPLE', {
    SUCCESS_ALPHABET_LIST: 'SUCCESS_ALPHABET_LIST',
    SUCCESS_BOARD_VIEW: 'SUCCESS_BOARD_VIEW',
    SUCCESS_PINNED_LIST: 'SUCCESS_PINNED_LIST',
    SUCCESS_OVERVIEW_BOARD: 'SUCCESS_OVERVIEW_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
    SUCCESS_TABLE: 'SUCCESS_TABLE',
});

export const REQUEST_CASE_CREATE_STACK = requestTypeCreator('REQUEST_CASE_CREATE_STACK', {
    SUCCESS_CUSTOM_BOARD: 'SUCCESS_CUSTOM_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
});

export const REQUEST_CASE_DELETE_STACK = requestTypeCreator('REQUEST_CASE_DELETE_STACK', {
    SUCCESS_CUSTOM_BOARD: 'SUCCESS_CUSTOM_BOARD',
    SUCCESS_SWIMLANE_BOARD: 'SUCCESS_SWIMLANE_BOARD',
});

export const agentList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_LIST.ERROR,
    }),
    request: () => ({
        type: AGENT_LIST.REQUEST,
    }),
    success: (payload: AgentItemBasic[]): IActionType<AgentItemBasic[]> => ({
        payload,
        type: AGENT_LIST.SUCCESS,
    }),
};

export const getCasesActivitiesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CASES_ACTIVITIES_LIST.ERROR,
    }),
    request: (payload: AgentCasesActivitiesRequestTypes): IActionType<AgentCasesActivitiesRequestTypes> => ({
        payload,
        type: CASES_ACTIVITIES_LIST.REQUEST,
    }),
    success: (payload: RequesterCaseActivitiesTypes[]): IActionType<RequesterCaseActivitiesTypes[]> => ({
        payload,
        type: CASES_ACTIVITIES_LIST.SUCCESS,
    }),
};

export const getAgentTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_TEMPLATE_GET.ERROR,
    }),
    request: () => ({
        type: AGENT_TEMPLATE_GET.REQUEST,
    }),
    success: (payload: AgentTemplateType): IActionType<AgentTemplateType> => ({
        payload,
        type: AGENT_TEMPLATE_GET.SUCCESS,
    }),
};

export const updateAgentTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_TEMPLATE_UPDATE.ERROR,
    }),
    request: (payload: AgentTemplateRequestType): IActionType<AgentTemplateRequestType> => ({
        payload,
        type: AGENT_TEMPLATE_UPDATE.REQUEST,
    }),
    success: (payload: AgentTemplateType): IActionType<AgentTemplateType> => ({
        payload,
        type: AGENT_TEMPLATE_UPDATE.SUCCESS,
    }),
};

export const requesterCasesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUESTER_CASES_LIST.ERROR,
    }),
    requestCustomBoard: (payload: CustomBoardItemsRequestTypes): IActionType<CustomBoardItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD,
    }),
    requestCustomBoardAlphabet: (payload: CustomBoardItemsRequestTypes): IActionType<CustomBoardItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET,
    }),
    requestCustomBoardAlphabetUpdate: (
        payload: CustomBoardItemsRequestTypes,
    ): IActionType<CustomBoardItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET_UPDATE,
    }),
    requestBoardColumn: (payload: BoardColumnItemsRequestTypes): IActionType<BoardColumnItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_BOARD_COLUMN,
    }),
    requestCustomBoardColumn: (
        payload: CustomBoardColumnItemsRequestTypes,
    ): IActionType<CustomBoardColumnItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_COLUMN,
    }),
    requestEmpty: () => ({
        type: REQUESTER_CASES_LIST.REQUEST_EMPTY,
    }),
    successCustomBoard: (payload: BoardResponseType): IActionType<BoardResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD,
    }),
    successCustomBoardAlphabet: (payload: BoardAlphabetResponseType): IActionType<BoardAlphabetResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_ALPHABET,
    }),
    successCustomBoardAlphabetUpdate: (payload: BoardAlphabetResponseType): IActionType<BoardAlphabetResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_ALPHABET_UPDATE,
    }),
    successBoardColumn: (payload: BoardItemsResponseType): IActionType<BoardItemsResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_BOARD_COLUMN,
    }),
    successCustomBoardColumn: (payload: BoardItemsResponseType): IActionType<BoardItemsResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_COLUMN,
    }),
    requestCustomBoardOverview: (payload: BoardOverviewRequestTypes): IActionType<BoardOverviewRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_OVERVIEW,
    }),
    successCustomBoardOverview: (payload: BoardItemsResponseType): IActionType<BoardItemsResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_OVERVIEW,
    }),
    requestSwimlaneBoard: (payload: CustomBoardItemsRequestTypes): IActionType<CustomBoardItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_SWIMLANE_BOARD,
    }),
    successSwimlaneBoard: (payload: SwimlaneBoardResponseType): IActionType<SwimlaneBoardResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_SWIMLANE_BOARD,
    }),
    requestLoadSwimlane: (payload: CustomSwimlaneItemsRequestTypes): IActionType<CustomSwimlaneItemsRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_LOAD_SWIMLANE,
    }),
    successLoadSwimlane: (payload: SwimlaneBoardResponseType): IActionType<SwimlaneBoardResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_LOAD_SWIMLANE,
    }),
    requestTable: (payload: TableRequestTypes): IActionType<TableRequestTypes> => ({
        payload,
        type: REQUESTER_CASES_LIST.REQUEST_TABLE,
    }),
    successTable: (payload: TableResponseType): IActionType<TableResponseType> => ({
        payload,
        type: REQUESTER_CASES_LIST.SUCCESS_TABLE,
    }),
};

export const requestCaseDragAndDrop = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_DRAG_AND_DROP.ERROR,
    }),
    request: (payload: RequesterCaseDragAndDropType): IActionType<RequesterCaseDragAndDropType> => ({
        payload,
        type: REQUEST_CASE_DRAG_AND_DROP.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_DRAG_AND_DROP.SUCCESS,
    }),
};

export const getPinnedCases = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PINNED_CASES.ERROR,
    }),
    request: (payload: ListItemsRequestTypes): IActionType<ListItemsRequestTypes> => ({
        payload,
        type: GET_PINNED_CASES.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: GET_PINNED_CASES.SUCCESS,
    }),
};

export const getCasesWaitingOnApprove = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_WAITING_APPROVE_CASES.ERROR,
    }),
    request: (payload: ListItemsRequestTypes): IActionType<ListItemsRequestTypes> => ({
        payload,
        type: GET_WAITING_APPROVE_CASES.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: GET_WAITING_APPROVE_CASES.SUCCESS,
    }),
};

export const languageList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: LANGUAGE_LIST.ERROR,
    }),
    request: () => ({
        type: LANGUAGE_LIST.REQUEST,
    }),
    success: (payload: LanguageItemTypes[]): IActionType<LanguageItemTypes[]> => ({
        payload,
        type: LANGUAGE_LIST.SUCCESS,
    }),
};

export const toggleLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_LANGUAGE.ERROR,
    }),
    request: (payload: ToggleLanguageRequestTypes): IActionType<ToggleLanguageRequestTypes> => ({
        payload,
        type: TOGGLE_LANGUAGE.REQUEST,
    }),
    success: () => ({
        type: TOGGLE_LANGUAGE.SUCCESS,
    }),
};

export const requestCaseAssignAgentMultiple = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.ERROR,
    }),
    request: (payload: RequesterCaseMultipleAssignAgentType): IActionType<RequesterCaseMultipleAssignAgentType> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS,
    }),
    successPinnedList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_PINNED_LIST,
    }),
    successBoardView: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_BOARD_VIEW,
    }),
    successAlphabetList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_ALPHABET_LIST,
    }),
    successOverviewBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_OVERVIEW_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_SWIMLANE_BOARD,
    }),
    successTable: (payload: TableResponseType): IActionType<TableResponseType> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_TABLE,
    }),
};

export const requestCaseChangeStatusMultiple = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.ERROR,
    }),
    request: (payload: RequesterCaseMultipleChangeStatusType): IActionType<RequesterCaseMultipleChangeStatusType> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS,
    }),
    successPinnedList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_PINNED_LIST,
    }),
    successBoardView: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_BOARD_VIEW,
    }),
    successAlphabetList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_ALPHABET_LIST,
    }),
    successOverviewBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_OVERVIEW_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_SWIMLANE_BOARD,
    }),
    successTable: (payload: TableResponseType): IActionType<TableResponseType> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_TABLE,
    }),
};

export const requestCaseTogglePinningMultiple = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.ERROR,
    }),
    request: (
        payload: RequesterCaseMultipleTogglePinningType,
    ): IActionType<RequesterCaseMultipleTogglePinningType> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS,
    }),
    successPinnedList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_PINNED_LIST,
    }),
    successBoardView: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_BOARD_VIEW,
    }),
    successAlphabetList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_ALPHABET_LIST,
    }),
    successOverviewBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_OVERVIEW_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_SWIMLANE_BOARD,
    }),
    successTable: (payload: TableResponseType): IActionType<TableResponseType> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_TABLE,
    }),
};

export const requestCaseExport = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_EXPORT.ERROR,
    }),
    request: (payload: RequesterCaseExportType): IActionType<RequesterCaseExportType> => ({
        payload,
        type: REQUEST_CASE_EXPORT.REQUEST,
    }),
    success: () => ({
        type: REQUEST_CASE_EXPORT.SUCCESS,
    }),
};

export const requestCaseExportMultiple = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_EXPORT_MULTIPLE.ERROR,
    }),
    request: (payload: RequesterCaseMultipleType): IActionType<RequesterCaseMultipleType> => ({
        payload,
        type: REQUEST_CASE_EXPORT_MULTIPLE.REQUEST,
    }),
    success: () => ({
        type: REQUEST_CASE_EXPORT_MULTIPLE.SUCCESS,
    }),
};

export const requestCaseChangeTagMultiple = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.ERROR,
    }),
    request: (payload: RequesterCaseMultipleChangeTagType): IActionType<RequesterCaseMultipleChangeTagType> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS,
    }),
    successAlphabetList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_ALPHABET_LIST,
    }),
    successPinnedList: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_PINNED_LIST,
    }),
    successBoardView: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_BOARD_VIEW,
    }),
    successOverviewBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_OVERVIEW_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_SWIMLANE_BOARD,
    }),
    successTable: (payload: TableResponseType): IActionType<TableResponseType> => ({
        payload,
        type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_TABLE,
    }),
};

export const requestCaseCreateStack = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_CREATE_STACK.ERROR,
    }),
    request: (payload: RequesterCaseStackCreateType): IActionType<RequesterCaseStackCreateType> => ({
        payload,
        type: REQUEST_CASE_CREATE_STACK.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CREATE_STACK.SUCCESS,
    }),
    successCustomBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CREATE_STACK.SUCCESS_CUSTOM_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_CREATE_STACK.SUCCESS_SWIMLANE_BOARD,
    }),
};

export const requestCaseDeleteStack = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_DELETE_STACK.ERROR,
    }),
    request: (payload: RequesterCaseStackDeleteType): IActionType<RequesterCaseStackDeleteType> => ({
        payload,
        type: REQUEST_CASE_DELETE_STACK.REQUEST,
    }),
    success: (payload: ListItemsRequestTypesResponseTypes): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_DELETE_STACK.SUCCESS,
    }),
    successCustomBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_DELETE_STACK.SUCCESS_CUSTOM_BOARD,
    }),
    successSwimlaneBoard: (
        payload: ListItemsRequestTypesResponseTypes,
    ): IActionType<ListItemsRequestTypesResponseTypes> => ({
        payload,
        type: REQUEST_CASE_DELETE_STACK.SUCCESS_SWIMLANE_BOARD,
    }),
};
