import React, {FC, ReactNode, useContext, useLayoutEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

import {MediaContext} from 'contexts/media/context';

import RequesterCaseChat from 'components/RequesterChat/RequesterCaseChat';

import ChatContactsList from 'pages/agent/chat/ChatContactsList';
import ChatHeader from 'pages/agent/chat/ChatHeader';
import AgentChatTopBar from 'pages/agent/chat/AgentChatTopBar';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';

interface AgentChatWrapperContentType {
    children: ReactNode;
}

const AgentChatWrapperContent: FC<AgentChatWrapperContentType> = ({children}) => {
    const contentWrapperRef = useRef<HTMLDivElement>();

    const {isMobile, isTablet} = useContext(MediaContext);

    const [chatBoxWidth, setChatBoxWidth] = useState<number>();
    const [showContactsList, setShowContactsList] = useState<boolean>(true);

    useLayoutEffect(() => {
        if (contentWrapperRef && contentWrapperRef.current) {
            setChatBoxWidth(contentWrapperRef.current.offsetWidth);
        }

        const onResize = () => {
            setChatBoxWidth(contentWrapperRef.current?.offsetWidth);
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [contentWrapperRef]);

    const {requestCase} = useParams();

    const {
        requestChat: {contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const item = Array.isArray(contacts)
        ? contacts.find((item: ChatContactTypes) => item?.caseId === Number(requestCase))
        : undefined;

    const messagesListWidth = isTablet ? 170 : 330;

    const isPortable = isMobile || isTablet;

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
            ref={contentWrapperRef}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    gap: '0',
                }}
            >
                {(showContactsList || !isPortable) && <AgentChatTopBar />}
                {item && (!showContactsList || !isPortable) && (
                    <ChatHeader item={item} onBackClick={!isPortable ? undefined : () => setShowContactsList(true)} />
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isPortable ? 'column' : 'row-reverse',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 2,
                }}
                onClick={() => setShowContactsList(false)}
            >
                {(showContactsList || !isPortable) && (
                    <Box
                        sx={{
                            width: isPortable ? '100%' : chatBoxWidth ? `${messagesListWidth}px` : 'auto',
                            height: `calc(100vh - ${isMobile ? '210px' : '180px'})`,
                            order: isPortable ? 2 : 1,
                            borderRight: !isPortable ? `1px solid ${theme.palette.divider}` : 'none',
                        }}
                    >
                        <ChatContactsList />
                    </Box>
                )}

                {(!showContactsList || !isPortable) && (
                    <Box
                        sx={{
                            width: isPortable
                                ? '100%'
                                : chatBoxWidth
                                ? `${chatBoxWidth - messagesListWidth}px`
                                : 'auto',
                            height: `calc(100vh - ${isMobile ? '240px' : '180px'})`,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                        {children}
                        <Box
                            sx={{
                                width: isPortable
                                    ? '100%'
                                    : chatBoxWidth
                                    ? `${chatBoxWidth - messagesListWidth}px`
                                    : 'auto',
                                position: 'absolute',
                                top: 'auto',
                                bottom: 0,
                                left: 0,
                                px: isPortable ? 1 : 2,
                                pt: 1,
                                background: CLIENT_BACKGROUND_COLOR,
                            }}
                        >
                            <RequesterCaseChat wrapperRef={contentWrapperRef} />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default AgentChatWrapperContent;
