import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useParams} from 'react-router-dom';

import {RequesterCaseApproveRuleTypes} from 'appRedux/actions/requestCase/types';
import {GET_CURRENT_WORKFLOW_STATUS} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import StatusChangingApproveForm from 'components/Forms/RequesterCase/StatusChangingApproveForm';

import RightPanelSectionWrapper from 'pages/agent/requesterPage/wrappers/RightPanelSectionWrapper';
import {
    checkIsCurrentUserCanApproveStatusChanging,
    checkIsAlreadyApprovedByCurrentUser,
    getNotApprovedStatusChangesForCurrentUser,
} from 'pages/agent/requesterPage/approverResolution/helper';

const ApproverResolution: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {
            formInfo: {statuses},
        },
        profile: {profile},
        requestCase: {
            currentCase: {approveRules},
        },
        workflow: {currentWorkflowStatus},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getCurrentWorkflowStatusInfo = useCallback(
        data => dispatch({type: GET_CURRENT_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const currentUserId = profile ? profile.id : null;

    const notApprovedRule: RequesterCaseApproveRuleTypes | null = currentUserId
        ? getNotApprovedStatusChangesForCurrentUser(approveRules, currentUserId)
        : null;

    const approveWaitingStatus = notApprovedRule
        ? statuses.find(item => item.uuid === notApprovedRule.newStatusUuid)
        : null;

    const alreadyApprovedByCurrentUser = currentUserId
        ? checkIsAlreadyApprovedByCurrentUser(approveRules, currentUserId)
        : false;

    useEffect(() => {
        if (approveWaitingStatus) {
            getCurrentWorkflowStatusInfo({
                uuid: approveWaitingStatus.uuid,
                showAlert,
            });
        }
    }, [approveWaitingStatus]);

    const isCurrentUserCanApproveStatusChanging = checkIsCurrentUserCanApproveStatusChanging(
        get(currentWorkflowStatus, 'rules', []),
        Number(currentUserId),
    );

    if (!isCurrentUserCanApproveStatusChanging || !notApprovedRule || alreadyApprovedByCurrentUser) return null;

    return (
        <RightPanelSectionWrapper title={t('orguser.requesterCase.approves.approveStatusChanging')}>
            <StatusChangingApproveForm statusChangeRequest={notApprovedRule} caseId={Number(caseId)} />
        </RightPanelSectionWrapper>
    );
};

export default ApproverResolution;
