import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateBoardGeneralForm from 'components/Forms/BoardForm/CreateBoardGeneralForm';

import EditBoardFormModal from 'pages/admin/boardsList/EditBoardFormModal';

const AddBoardButton: FC = () => {
    const [t] = useTranslation();

    const [createdBoardId, setCreatedBoardId] = useState<number | null>(null);

    const [showAddColumnModal, setShowAddColumnModal] = useState<boolean>(false);
    const [showUpdateColumnModal, setShowUpdateColumnModal] = useState<boolean>(false);

    const toggleAddColumnModal = () => {
        if (showAddColumnModal) {
            setCreatedBoardId(null);
            setShowUpdateColumnModal(false);
        }
        setShowAddColumnModal(previous => !previous);
    };

    const {
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentItem = boards.find(board => board.id === createdBoardId);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton
                icon={<PlusSvgIcon />}
                onClick={() => setShowAddColumnModal(true)}
                title={t('orguser.boards.addBoard')}
            />
            <ModalWrapper
                isShowModal={showAddColumnModal}
                toggleModal={toggleAddColumnModal}
                title={t(
                    showUpdateColumnModal && currentItem ? 'orguser.boards.updateBoard' : 'orguser.boards.addBoard',
                )}
                isWideModal={Boolean(showUpdateColumnModal && currentItem)}
            >
                {showUpdateColumnModal && currentItem ? (
                    <EditBoardFormModal item={currentItem} />
                ) : (
                    <CreateBoardGeneralForm
                        setShowUpdateColumnModal={setShowUpdateColumnModal}
                        setCreatedBoardId={setCreatedBoardId}
                    />
                )}
            </ModalWrapper>
        </Box>
    );
};

export default AddBoardButton;
