import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {FormTimeBucketTypes} from 'appRedux/actions/forms/types';

import FormTimeBucketDeleteButton from 'pages/admin/updateForm/partials/FormTimeTracking/FormTimeBucketDeleteButton';

interface FormTimeBucketItemType {
    item: FormTimeBucketTypes;
}

const FormTimeBucketItem: FC<FormTimeBucketItemType> = ({item}) => {
    console.log('FormTimeBucketItem', item);
    const {bucketTitle} = item;

    return (
        <Grid container flexDirection="row" alignItems="center" sx={{mb: 0.5}}>
            <Grid item sm={3}>
                <Typography>{bucketTitle}</Typography>
            </Grid>
            <Grid item sm={3}>
                <FormTimeBucketDeleteButton item={item} />
            </Grid>
        </Grid>
    );
};

export default FormTimeBucketItem;
