import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';
import {REQUEST_CASE_CHANGE_STATUS} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import CurrentStatusLabel from 'pages/agent/requesterPage/formInformation/status/CurrentStatusLabel';
import StatusButton from 'pages/agent/requesterPage/formInformation/status/StatusButton';

interface StatusSwitchButtonsType {
    title: string;
    color: string;
    requesterStatus: string;
    statuses: FormWorkflowStatusTypes[];
    currentStatus: FormWorkflowStatusTypes;
    hasRejectedStatusChangingRequest?: boolean;
}

const StatusSwitchButtons: FC<StatusSwitchButtonsType> = ({
    title,
    color,
    requesterStatus,
    currentStatus,
    statuses,
    hasRejectedStatusChangingRequest,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const changeRequesterStatus = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCase: {rejectedStatusIds, statusTitle, statusColor},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleStatusChange = (status: string) => {
        changeRequesterStatus({
            status,
            caseId: Number(caseId),
            showAlert,
            callback: () => {
                getFormByCaseInformation({id: caseId});
            },
        });
    };

    const filteredStatuses = statuses.filter(
        item => requesterStatus !== item.uuid && !rejectedStatusIds.includes(item.id),
    );

    if (filteredStatuses.length === 0 && currentStatus) {
        return (
            <Box sx={{mb: 2}}>
                <CurrentStatusLabel
                    title={currentStatus ? currentStatus.title : statusTitle}
                    color={currentStatus ? currentStatus.color : statusColor}
                />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {!hasRejectedStatusChangingRequest && <CurrentStatusLabel title={title} color={color} />}
            <Typography
                sx={{
                    mt: 2,
                    fontSize: 14,
                }}
            >{`${t('orguser.requesterCase.changeStatus')}:`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    mb: 2,
                }}
            >
                {filteredStatuses &&
                    filteredStatuses.map(filteredStatus => {
                        const {title, color, uuid} = filteredStatus;
                        return (
                            <StatusButton
                                key={`change-status-button-${uuid}`}
                                title={title}
                                color={color}
                                onClick={() => handleStatusChange(uuid)}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default StatusSwitchButtons;
