import moment from 'moment';

import {RequesterCaseHasSlotTypes} from 'appRedux/actions/requestCase/types';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

export const REQUESTER_CASE_HAS_SLOT_CREATED = 0;
export const REQUESTER_CASE_HAS_SLOT_RESERVED = 1;
export const REQUESTER_CASE_HAS_SLOT_BOOKED = 2;
export const REQUESTER_CASE_HAS_SLOT_CLEARED = 3;

export const getSlotStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_SLOT_CREATED:
            return 'requester.casePage.slotStatus.created';
        case REQUESTER_CASE_HAS_SLOT_RESERVED:
            return 'requester.casePage.slotStatus.reserved';
        case REQUESTER_CASE_HAS_SLOT_BOOKED:
            return 'requester.casePage.slotStatus.booked';
        case REQUESTER_CASE_HAS_SLOT_CLEARED:
            return 'requester.casePage.slotStatus.cleared';
    }
    return '';
};

export const getSlotStatusDateTime = (slot: RequesterCaseHasSlotTypes): string => {
    const {status, createdAt, reservedAt, bookedAt, clearedAt} = slot;
    switch (status) {
        case REQUESTER_CASE_HAS_SLOT_CREATED:
            return createdAt ? convertDateToCurrentLocale(createdAt, false, true) : '';
        case REQUESTER_CASE_HAS_SLOT_RESERVED:
            return reservedAt ? convertDateToCurrentLocale(reservedAt, false, true) : '';
        case REQUESTER_CASE_HAS_SLOT_BOOKED:
            return bookedAt ? convertDateToCurrentLocale(bookedAt, false, true) : '';
        case REQUESTER_CASE_HAS_SLOT_CLEARED:
            return clearedAt ? convertDateToCurrentLocale(clearedAt, false, true) : '';
    }
    return '';
};
