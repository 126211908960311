import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {Box, IconButton, Tooltip, Typography} from '@mui/material';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {GET_FILE_URL} from 'appRedux/actions/profile';
import {CHAT_DOCUMENT_URL} from 'appRedux/actions/requestChat';
import {DELETE_REQUESTER_DOCUMENT, EDIT_REQUESTER_DOCUMENT} from 'appRedux/actions/requestCase';
import {EditFileDescriptionCaseType} from 'appRedux/actions/requestCase/types';

import {RouteContext} from 'contexts/route/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {ClientFormContext} from 'contexts/clientForm/context';
import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import ThumbItem, {ThumbSize} from 'components/ClientScreenComponents/ThumbItem';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {CommonFileType, FILE_CHAT} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';
import {truncateString} from 'helpers/truncateString';

import {theme} from 'config/theme';

export const validationSchema = () =>
    yup.object({
        description: yup.string().required(),
    });

interface FileThumbItemType {
    file: CommonFileType;
    downloadFile: (url: string, fileName?: string) => void;
    getModalContent: (file: CommonFileType) => void;
    toggleIsModalOpened: () => void;
    deleteNotPossible?: boolean;
    sectionId?: number;
}

const FileThumbItem: FC<FileThumbItemType> = ({
    file,
    getModalContent,
    deleteNotPossible,
    downloadFile,
    toggleIsModalOpened,
    sectionId,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {toggleSectionChecked} = useContext(ClientFormContext);

    const [thumbSrc, setThumbSrc] = useState<string | null | undefined>();

    const getFileUrl = useCallback(data => dispatch({type: GET_FILE_URL.REQUEST, payload: data}), [dispatch]);

    const getChatFileUrl = useCallback(data => dispatch({type: CHAT_DOCUMENT_URL.REQUEST, payload: data}), [dispatch]);

    const deleteDocument = useCallback(
        data => dispatch({type: DELETE_REQUESTER_DOCUMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const editDocumentDecription = useCallback(
        data => dispatch({type: EDIT_REQUESTER_DOCUMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const {type, title, extension, uuid, encryptPrefix, thumbLink, originalName} = file;

    console.log('title', title);
    const onPreviewClicked = (file: CommonFileType) => {
        toggleIsModalOpened();
        getModalContent(file);
    };

    const onClickDelete = (callback: () => void) => {
        deleteDocument({
            uuid,
            sectionId,
            toggleSectionChecked,
            caseId: Number(requestCase),
            callback,
        });
    };

    const [editClicked, setEditClicked] = useState<boolean>(false);

    const toggleEditClicked = () => {
        setEditClicked(prev => !prev);
    };

    const editSubmitHandler = (values: EditFileDescriptionCaseType, {resetForm}) => {
        resetForm();
        editDocumentDecription({
            uuid,
            showAlert,
            callback: () => setEditClicked(false),
            sectionId,
            toggleSectionChecked,
            caseId: Number(requestCase),
            description: values.description,
        });
    };

    const downloadEncryptedFile = async (url: string) => {
        if (encryptPrefix) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, `${title}.${extension}`);
            }
        }
    };

    const handleDownloadFile = async () => {
        const getFileAction = type === FILE_CHAT ? getChatFileUrl : getFileUrl;
        getFileAction({
            uuid,
            callback: encryptPrefix ? await downloadEncryptedFile : downloadFile,
        });
    };

    useEffect(() => {
        const downloadEncryptedThumb = async (url: string) => {
            return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
        };

        const getThumbLink = async () => {
            if (thumbLink) return unwrappedCaseKey ? downloadEncryptedThumb(thumbLink) : thumbLink;
            return '';
        };

        getThumbLink().then(link => {
            setThumbSrc(link);
        });
    }, [thumbLink]);

    return (
        <Box sx={{maxWidth: '150px'}}>
            <ThumbItem
                uuid={uuid}
                currentFile={file}
                selectFile={onPreviewClicked}
                isUserOwner={isClientMode && !deleteNotPossible}
                downloadFile={handleDownloadFile}
                deleteFile={onClickDelete}
                thumbSrc={thumbSrc}
                thumbSize={ThumbSize.LARGE}
            />
            <Tooltip
                title={originalName && originalName.length < 21 ? '' : originalName}
                placement="top"
                arrow
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: 'rgb(97, 97, 97)',
                        },
                    },
                }}
            >
                <Typography variant="body2" sx={{fontSize: 12, color: theme.palette.info.dark, height: 17}}>
                    {truncateString(originalName || '', 15, 6)}
                </Typography>
            </Tooltip>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Tooltip
                    title={title.length > 16 ? title : ''}
                    placement="top"
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'rgb(97, 97, 97)',
                            },
                        },
                    }}
                >
                    <Typography variant="body2">{truncateString(title, 16)}</Typography>
                </Tooltip>

                {!deleteNotPossible && (
                    <IconButton onClick={toggleEditClicked}>
                        <EditSvgIcon />
                    </IconButton>
                )}
            </Box>

            {editClicked && (
                <Formik
                    initialValues={{
                        description: title,
                    }}
                    validationSchema={() => validationSchema()}
                    onSubmit={editSubmitHandler}
                >
                    {(formik: FormikProps<EditFileDescriptionCaseType>) => {
                        return (
                            <ModalWrapper
                                title={t('requester.casePage.changeFileName')}
                                isShowModal={editClicked}
                                toggleModal={toggleEditClicked}
                                disablePortal
                            >
                                <Form>
                                    <Box sx={{mb: 2}}>
                                        <AutoDirectionTextField
                                            data-id={`input#file-${uuid}-title-description`}
                                            name="description"
                                            variant="standard"
                                            fullWidth
                                            required
                                            label={t('orguser.requesterCase.shortDescriptionMandatory')}
                                            value={formik.values.description}
                                            onChange={value =>
                                                formik.setFieldValue('description', value.currentTarget.value)
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AgentSaveButton
                                            data-id={`input#file-${uuid}-title-submit`}
                                            isSubmit
                                            isBigButton
                                            title={t('common.buttons.submit')}
                                            onClick={formik.submitForm}
                                        />
                                    </Box>
                                </Form>
                            </ModalWrapper>
                        );
                    }}
                </Formik>
            )}
        </Box>
    );
};

export default FileThumbItem;
