import React, {FC, useCallback, useContext} from 'react';
import {Formik, Form, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';
import {AttachAgentToBoardFormTypes} from 'appRedux/actions/workflow/types';
import {ATTACH_AGENT_TO_BOARD} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import Autocomplete from '@mui/material/Autocomplete';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

interface ComponentType {
    item: BoardItemTypes;
    closeForm: () => void;
}

const AttachAgentToBoardForm: FC<ComponentType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, agents} = item;

    const {
        admin: {agentList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_AGENT_TO_BOARD.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = (values: AttachAgentToBoardFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const agentsNotAttached = agentList
        ? agentList
              .filter(item => !agents.some(agent => Number(item.id) === Number(agent.id)))
              .sort((a, b) => a.firstName?.localeCompare(b.firstName || '') || 0)
        : [];

    return (
        <Formik
            initialValues={{
                agent: 0,
            }}
            enableReinitialize
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<AttachAgentToBoardFormTypes>) => {
                return (
                    <Form>
                        <Autocomplete
                            options={agentsNotAttached}
                            getOptionLabel={agent => {
                                const {firstName, lastName, email} = agent;
                                return firstName && lastName ? `${firstName} ${lastName}` : email;
                            }}
                            value={agentsNotAttached.find(agent => agent.id === formik.values.agent) || null}
                            onChange={(event, value) => {
                                formik.setFieldValue('agent', value ? value.id : '');
                                formik.submitForm();
                            }}
                            fullWidth
                            popupIcon={<KeyboardArrowDownIcon />}
                            renderInput={params => (
                                <AutoDirectionTextField {...params} name="agent" variant="outlined" fullWidth />
                            )}
                            renderOption={(props, option) => {
                                const {id, avatarType, avatarLink, firstName, lastName, email} = option;
                                const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                return (
                                    <Box
                                        component="li"
                                        {...props}
                                        key={id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            mb: 1,
                                        }}
                                    >
                                        <AvatarImage
                                            size={25}
                                            avatarType={avatarType}
                                            avatarLink={avatarLink}
                                            avatarTitle={avatarTitle}
                                        />
                                        <Typography sx={{ml: 1, fontWeight: 600}}>
                                            {firstName && lastName ? `${firstName} ${lastName}` : email}
                                        </Typography>
                                    </Box>
                                );
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AttachAgentToBoardForm;
