import React, {FC, useEffect, useState, ReactNode, useCallback, useContext} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {GET_NEW_CHAT_MESSAGES, CHAT_AGENT_CONTACTS} from 'appRedux/actions/requestChat';
import {GET_ALL_REQUESTER_CASES, GET_REQUESTER_CASE, GET_REQUESTER_CASE_CLIENT} from 'appRedux/actions/requestCase';
import {GET_NOTIFICATIONS_LIST} from 'appRedux/actions/notifications';
import {ChatMessageTypes} from 'appRedux/actions/requestChat/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {WebsocketContext, WebsocketContextType, WEBSOCKET_PING_INTERVAL} from 'contexts/websocket/context';
import ShowLoginToYourAccountPopup from 'contexts/websocket/partials/ShowLoginToYourAccountPopup';
import {RouteContext} from 'contexts/route/context';

import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import {getPerPageForRequest, SWIMLANE_INITIAL_COLUMN_ITEMS} from 'components/BlockView/helper';

import {getNewestMessageId} from 'helpers/chatHelper';
import {VIEW_MODE_LIST} from 'helpers/filter';

import {LOCAL_STORAGE_TOKEN} from 'services/localStorage';

import {
    ALERT_TYPE_INFO,
    PARAMETER_TAG,
    PARAMETER_PER_PAGE,
    PARAMETER_OPTION,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_FIRST_LETTER,
} from 'config/index';
import {
    WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE,
    WEBSOCKET_NEW_NOTIFICATION,
    WEBSOCKET_NOTIFICATION_ANOTHER_LOGIN,
    WEBSOCKET_REQUESTER_CASE_STATUS_CHANGED,
    WEBSOCKET_NOTIFICATION_UPDATE_BOARDS,
    WEBSOCKET_UPDATE_FORM_STRUCTURE,
} from 'config/websocket';

interface ContextType {
    children: ReactNode;
}

const WebsocketProviderWrapper: FC<ContextType> = ({children}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase, caseId, uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {itemsPerPage} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {
        isAdminPage,
        isAgentPage,
        isClientMode,
        isCurrentUserRequesterCasesListPage,
        isRequesterFormPage,
        isRequesterCaseInfoPage,
        isBoardCasesPage,
        isSwimlaneCasesPage,
        isBoardOverviewCasesPage,
        isBoardListCasesPage,
    } = useContext(RouteContext);

    const [notReadMessagesCounter, setNotReadMessagesCounter] = useState<number>(0);
    const [isShowScrollBottomButton, setIsShowScrollBottomButton] = useState<boolean>(false);
    const [isShowLoginToYourAccountPopup, setIsShowLoginToYourAccountPopup] = useState<boolean>(false);

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const optionsFromUrl = searchParams.get(PARAMETER_OPTION);
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);

    const toggleShowLoginToYourAccountPopup = () => {
        setIsShowLoginToYourAccountPopup(previous => !previous);
    };

    const updateMessagesCounter = () => {
        setNotReadMessagesCounter(previous => previous + 1);
    };

    const getChatContacts = useCallback(() => dispatch({type: CHAT_AGENT_CONTACTS.REQUEST_BACKGROUND}), [dispatch]);

    const getNewChatMessages = useCallback(
        data => dispatch({type: GET_NEW_CHAT_MESSAGES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformationForClient = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CLIENT.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequesterCasesCustomBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD, payload: data}),
        [dispatch],
    );

    const getRequesterCasesCustomListBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET, payload: data}),
        [dispatch],
    );

    const getRequesterCasesOverviewBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_OVERVIEW, payload: data}),
        [dispatch],
    );

    const getRequesterCasesSwimlaneBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_SWIMLANE_BOARD, payload: data}),
        [dispatch],
    );

    const getNotifications = useCallback(() => dispatch({type: GET_NOTIFICATIONS_LIST.REQUEST}), [dispatch]);

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        profile,
        requestChat: {messages, newestMessageId},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}`);

        socket.onopen = () => {
            console.log('WebSocket opened');
            const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
            if (token) {
                socket.send(token);
                console.log('WebSocket token is sent');
                setInterval(function () {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({type: 'ping'}));
                    }
                }, WEBSOCKET_PING_INTERVAL);
            }
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
        };

        socket.onmessage = event => {
            const message = event.data;
            console.log('WebSocket message received:', message);

            const timer = setTimeout(() => {
                sendWebsocketRequest(message);
            }, 2000);
            return () => clearTimeout(timer);
        };

        socket.onerror = error => {
            console.log('WebSocket error:', error);
        };

        return () => {
            socket.close();
        };
    }, [requestCase, caseId, messages, uuid, newestMessageId]);

    const sendWebsocketRequest = (message: string) => {
        if (message.includes(WEBSOCKET_NOTIFICATION_ANOTHER_LOGIN)) {
            getNotifications();
            setIsShowLoginToYourAccountPopup(true);
        } else if (
            message.includes('type') &&
            message.includes('caseId') &&
            message.includes(WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE)
        ) {
            newChatMessageHandling(message);
        } else if (message.includes('type') && message.includes(WEBSOCKET_NEW_NOTIFICATION)) {
            const timer = setTimeout(() => {
                getNotifications();
            }, 2000);
            return () => clearTimeout(timer);
        } else if (message.includes('caseId') && message.includes(WEBSOCKET_REQUESTER_CASE_STATUS_CHANGED)) {
            updateRequesterCaseStatus(message);
        } else if (message.includes('uuids') && message.includes(WEBSOCKET_NOTIFICATION_UPDATE_BOARDS)) {
            const uuids: string[] = get(JSON.parse(message), 'uuids', []);
            if (uuid && uuids.includes(uuid)) {
                updateCurrentBoard(uuid);
            }
        } else if (
            message.includes('caseId') &&
            message.includes('formId') &&
            message.includes(WEBSOCKET_UPDATE_FORM_STRUCTURE)
        ) {
            updateFormStructure(message);
        }
    };

    const newChatMessageHandling = (message: string) => {
        const type = get(JSON.parse(message), 'type', null);
        const senderCaseId = get(JSON.parse(message), 'caseId', null);
        const isCurrentCase = checkIsCurrentCasePage(Number(senderCaseId));
        if (type === WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE) {
            if (isAdminPage || isAgentPage) {
                updateChatContacts();
            }
            if (isCurrentCase) {
                updateChatMessages(messages, newestMessageId);
            }
            if (isClientMode || isCurrentUserRequesterCasesListPage) {
                updateCurrentUserCasesList();
            }
            if (isRequesterFormPage && isCurrentCase) {
                updateCurrentCasePage();
            }
        }
    };

    const updateFormStructure = (message: string): void => {
        const caseId = get(JSON.parse(message), 'caseId', null);
        const formId = get(JSON.parse(message), 'formId', null);
        const isCurrentCasePage = checkIsCurrentCasePage(Number(caseId));
        if (!caseId || !formId || !isCurrentCasePage) return;
        getFormByCaseInformation({id: caseId});
    };

    const checkIsCurrentCasePage = (senderCaseId: number): boolean => {
        return senderCaseId > 0 && (senderCaseId === Number(requestCase) || senderCaseId === Number(caseId));
    };

    const updateCurrentCasePage = () => {
        if (isClientMode) {
            getRequestCaseInformationForClient({id: caseId ? caseId : requestCase});
        } else {
            getRequestCaseInformation({id: caseId ? caseId : requestCase});
        }
    };

    const updateCurrentUserCasesList = () => {
        const userId = get(profile, ['profile', 'id'], null);
        getAllRequesterCases({
            id: userId,
        });
    };

    const updateRequesterCaseStatus = (message: string): void => {
        const messageArray = JSON.parse(message);
        const caseLabel = get(messageArray, 'caseLabel', '');
        const statusTitle = get(messageArray, 'statusTitle', '');
        const alertMessage = t('requester.common.statusChangingAlert')
            .replace('"%CASE_LABEL%"', String(caseLabel))
            .replace('%STATUS_NAME%', String(statusTitle));

        if (isClientMode) {
            showAlert(ALERT_TYPE_INFO, alertMessage);
        }
        if (isCurrentUserRequesterCasesListPage) {
            updateCurrentUserCasesList();
        }

        const senderCaseId = get(JSON.parse(message), 'caseId', null);
        const isCurrentCase = checkIsCurrentCasePage(Number(senderCaseId));
        if ((isRequesterFormPage || isRequesterCaseInfoPage) && isCurrentCase) {
            updateCurrentCasePage();
        }
    };

    const updateChatContacts = () => {
        getChatContacts();
    };

    const updateChatMessages = (messages: ChatMessageTypes, newestMessageId: string) => {
        const lastMessageUuid: string = getNewestMessageId(messages, newestMessageId);
        const currentCaseId = requestCase ? Number(requestCase) : Number(caseId);
        if (lastMessageUuid && currentCaseId) {
            getNewChatMessages({
                id: currentCaseId,
                lastMessageUuid,
                callback: () => {
                    setIsShowScrollBottomButton(true);
                    updateMessagesCounter();
                },
            });
        }
    };

    const updateCurrentBoard = (uuid: string) => {
        if (isSwimlaneCasesPage) {
            getRequesterCasesSwimlaneBoard({
                uuid,
                agent: getParameterFromUrl(agentFromUrl),
                form: getParameterFromUrl(formFromUrl),
                tags: getParameterFromUrl(tagsFromUrl),
                options: getParameterFromUrl(optionsFromUrl),
                lastCreated: getParameterFromUrl(lastCreatedFromUrl),
                lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
                search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
                perPage: SWIMLANE_INITIAL_COLUMN_ITEMS,
            });
        } else if (isBoardOverviewCasesPage) {
            getRequesterCasesOverviewBoard({
                uuid,
                agent: getParameterFromUrl(agentFromUrl),
                form: getParameterFromUrl(formFromUrl),
                tags: getParameterFromUrl(tagsFromUrl),
                options: getParameterFromUrl(optionsFromUrl),
                lastCreated: getParameterFromUrl(lastCreatedFromUrl),
                lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
                search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            });
        } else if (isBoardListCasesPage) {
            getRequesterCasesCustomListBoard({
                uuid,
                perPage: getPerPageForRequest(perPageFromUrl, VIEW_MODE_LIST, itemsPerPage),
                agent: getParameterFromUrl(agentFromUrl),
                form: getParameterFromUrl(formFromUrl),
                tags: getParameterFromUrl(tagsFromUrl),
                options: getParameterFromUrl(optionsFromUrl),
                lastCreated: getParameterFromUrl(lastCreatedFromUrl),
                lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
                firstLetter: firstLetterFromUrl ?? '',
                search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            });
        } else if (isBoardCasesPage) {
            getRequesterCasesCustomBoard({
                uuid,
                agent: getParameterFromUrl(agentFromUrl),
                form: getParameterFromUrl(formFromUrl),
                tags: getParameterFromUrl(tagsFromUrl),
                options: getParameterFromUrl(optionsFromUrl),
                lastCreated: getParameterFromUrl(lastCreatedFromUrl),
                lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
                search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            });
        }
    };

    const context: WebsocketContextType = {
        isShowScrollBottomButton,
        setIsShowScrollBottomButton,
        notReadMessagesCounter,
        setNotReadMessagesCounter,
    };

    return (
        <WebsocketContext.Provider value={context}>
            {children}
            <ShowLoginToYourAccountPopup
                isShowLoginToYourAccountPopup={isShowLoginToYourAccountPopup}
                setIsShowLoginToYourAccountPopup={setIsShowLoginToYourAccountPopup}
                toggleShowLoginToYourAccountPopup={toggleShowLoginToYourAccountPopup}
            />
        </WebsocketContext.Provider>
    );
};

export default WebsocketProviderWrapper;
