import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {FORM_INFORMATION, FORMS_LIST_BY_ORGANIZATION} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import FormVersionsList from 'pages/admin/updateForm/partials/FormVersions/FormVersionsList';

import {isOrgAdminUser} from 'helpers/roles';

import {PARAMETER_TAB, routes} from 'config/index';

const ViewFormComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackLinkLabel} = useContext(RouteContext);

    const getFormInformation = useCallback(
        data => dispatch({type: FORM_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formsList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getFormInformation({id});
        getLanguageList();
        if (myOrganization) {
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        if (profile) {
            const route = isOrgAdminUser(profile) ? routes.ADMIN : routes.AGENT;
            setBackLink(`${route}?${PARAMETER_TAB}=forms`);
        }
        setBackLinkLabel(t('common.links.backToForms'));
    }, []);

    const formId = Number(id);

    const currentForm = formsList ? formsList.find(item => item.id === formId) : null;

    return (
        <>
            {id && currentForm && (
                <ClientSectionWrapper title={t('orguser.forms.formVersions')}>
                    <FormVersionsList />
                </ClientSectionWrapper>
            )}
        </>
    );
};

export default ViewFormComponent;
