import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import Markdown from 'react-markdown';
import {useParams} from 'react-router-dom';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_OPTION, UPDATE_REQUESTER_CASE_POPUP_OPTION} from 'appRedux/actions/requestCase';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';
import {FieldOptionType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {
    isRequesterCaseOptionPresented,
    isSelectedOptionCustom,
    getSelectedCustomCheckbox,
    getInitialSelectedOptions,
} from 'pages/client/form/fieldTypes/helper';
import {getInitialFieldValue} from 'pages/client/form/helper';
import CustomOptionTextField from 'pages/client/form/fieldTypes/CustomOptionTextField';

import {ignoreHtmlInString, getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

const FormCheckbox: FC<FormFieldPropsType> = props => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {
        formId,
        isPopup,
        pageId,
        sectionId,
        field,
        clientInformation,
        agentInformation,
        handleChangeInformation,
        onPopupFieldChange,
        disabled,
        popupContent,
        previewMode,
        popupItemId,
        onSaveClicked,
        isAgentPage,
    } = props;

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);

    const initialValue = getInitialFieldValue(
        pageId,
        sectionId,
        field.id,
        clientInformation,
        agentInformation,
        isAgentPage,
        isPopup,
        popupContent,
    );

    const [previewModeSelectedOptions, setPreviewModeSelectedOptions] = useState<string[]>([]);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [customSelectedOptionId, setCustomSelectedOptionId] = useState<number | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateRequesterCaseOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const updateRequesterCasePopupOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const options = field.fieldOptions || [];
    const keywordsOptions = options.map(opt => getOptionKeyword(formId, opt.id, opt.text ? 'text' : 'title'));
    const translatedOptions = keywordsOptions.map((keyword, i) =>
        getFormTranslatedLabel(translations, keyword, options[i].text || options[i].title),
    );

    const updateOption = (optionId: number, customAnswer: string | null, customCallback?: () => void) => {
        updateRequesterCaseOption({
            id: Number(requestCase),
            optionId,
            customAnswer,
            showAlert,
            callback: () => {
                setIsInitialRender(false);
                setIsUpdated(previous => !previous);
                customCallback && customCallback();
            },
        });
    };

    const updatePopupOption = (optionId: number, customAnswer: string | null, customCallback?: () => void) => {
        updateRequesterCasePopupOption({
            id: Number(requestCase),
            optionId,
            customAnswer,
            requesterCaseHasPopupId: popupItemId,
            showAlert,
            callback: () => {
                setIsInitialRender(false);
                setIsUpdated(previous => !previous);
                customCallback && customCallback();
            },
        });
    };

    const onClickInPreviewMode = (optionId: number, isCustom: boolean) => {
        setPreviewModeSelectedOptions(selectedOptions);
        setIsUpdated(previous => !previous);
        if (isCustom) {
            setCustomSelectedOptionId(optionId);
        } else {
            setCustomSelectedOptionId(null);
        }
    };

    const onCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionId = event.target.value;
        let isOptionAdded = false;
        if (selectedOptions.includes(optionId)) {
            for (let i = 0, n = selectedOptions.length; i < n; i++) {
                if (selectedOptions[i] === optionId) {
                    selectedOptions.splice(i, 1);
                }
            }
        } else {
            setErrorField && setErrorField(null);
            selectedOptions.push(optionId);
            isOptionAdded = true;
        }
        const value = selectedOptions.join(OPTIONS_SEPARATOR);
        const isCustom = isSelectedOptionCustom(options, Number(optionId));
        if (previewMode) {
            onClickInPreviewMode(Number(optionId), isCustom);
        } else if (isCustom && isOptionAdded) {
            setIsInitialRender(false);
            setCustomSelectedOptionId(Number(optionId));
        } else if (isPopup && onPopupFieldChange) {
            updatePopupOption(Number(optionId), null, () => {
                onPopupFieldChange(field.id, value);
                setCustomSelectedOptionId(null);
            });
        } else if (handleChangeInformation) {
            updateOption(Number(optionId), null, () => {
                handleChangeInformation && handleChangeInformation(pageId, sectionId, field.id, value);
                setCustomSelectedOptionId(null);
            });
        }
    };

    const requesterCaseOptions: RequesterCaseOptionTypes[] = useMemo(() => {
        if (isPopup && popupItemId) {
            const currentItem = currentCase.popupItems.find(item => item.id === popupItemId);
            return currentItem?.options || [];
        } else {
            return get(currentCase, 'options', []);
        }
    }, [currentCase, isPopup, popupItemId]);

    useEffect(() => {
        if (initialValue) {
            const initialOptions = getInitialSelectedOptions(options, requesterCaseOptions, initialValue);
            setSelectedOptions(initialOptions);
        }
    }, [options, requesterCaseOptions, initialValue]);

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    const selectedCustomOption = getSelectedCustomCheckbox(options, requesterCaseOptions);
    const showCustomTextArea = customSelectedOptionId || selectedCustomOption;

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
            hasDivider={!customSelectedOptionId}
        >
            <FormGroup defaultValue={initialValue} onChange={onCheckboxSelect}>
                {options.map((option: FieldOptionType, index: number) => {
                    const translatedValue = get(translatedOptions, index, null);
                    const value =
                        !previewMode && translatedValue
                            ? ignoreHtmlInString(translatedValue)
                            : option.text || option.title;
                    const isChecked = previewMode
                        ? previewModeSelectedOptions.includes(String(option.id))
                        : isRequesterCaseOptionPresented(option.id, requesterCaseOptions);
                    return (
                        <FormControlLabel
                            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-checkbox-${index}`}
                            key={`checkbox-${index}`}
                            disabled={disabled}
                            control={
                                <Checkbox
                                    checked={
                                        (customSelectedOptionId && customSelectedOptionId === option.id) ||
                                        (selectedCustomOption && selectedCustomOption.id === option.id) ||
                                        isChecked
                                    }
                                    value={option.id}
                                />
                            }
                            label={
                                option.text ? (
                                    <Markdown>{value}</Markdown>
                                ) : (
                                    <Typography variant="body1">{value}</Typography>
                                )
                            }
                        />
                    );
                })}
            </FormGroup>
            {showCustomTextArea && (
                <CustomOptionTextField
                    customSelectedOptionId={
                        customSelectedOptionId || Number(selectedCustomOption && selectedCustomOption.id)
                    }
                    updateOption={updateOption}
                    updatePopupOption={updatePopupOption}
                    isCustomCheckboxField
                    {...props}
                />
            )}
        </FormSelectorWrapper>
    );
};

export default FormCheckbox;
