import React, {FC, ChangeEvent, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {getOptionsArray} from 'components/Forms/FormBuilder/FieldForm/helper';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface RangeType {
    fieldType: number;
    validationErrors: string | null;
    optionsString: string;
    setOptionsParameter: (value: string) => void;
    setShowOptionsError: (value: boolean) => void;
    validateRange: (options: string[], type: number) => void;
}

const Range: FC<RangeType> = ({
    fieldType,
    optionsString,
    setOptionsParameter,
    setShowOptionsError,
    validateRange,
    validationErrors,
}) => {
    const [t] = useTranslation();

    const [updatedMin, setUpdatedMin] = useState<string>(getOptionsArray(optionsString)[0] || '');
    const [updatedMax, setUpdatedMax] = useState<string>(getOptionsArray(optionsString)[1] || '');

    const [options, setOptions] = useState<string[]>(getOptionsArray(optionsString));

    const handleUpdate = (variant: 'min' | 'max') => (e: ChangeEvent<{value: unknown}>) => {
        const value = e.target.value as string;
        if (value.match(/[^0-9]/)) {
            e.preventDefault();
            return;
        }

        variant === 'min' ? setUpdatedMin(value) : setUpdatedMax(value);
    };

    const saveOptions = () => {
        if (updatedMin || updatedMax) {
            const updatedOptions = [updatedMin, updatedMax];
            setOptions(updatedOptions);
            validateRange(updatedOptions, fieldType);
            setOptionsParameter(updatedOptions.join(OPTIONS_SEPARATOR));
        }
    };

    useEffect(() => {
        setShowOptionsError(false);
        validateRange(options, fieldType);
    }, [options.length]);

    return (
        <Box sx={{mt: 0, mb: 1}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <AutoDirectionTextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    label={t('orguser.forms.formField.range.min')}
                    sx={{'& div': {height: 40}}}
                    value={updatedMin}
                    onChange={handleUpdate('min')}
                    onBlur={() => saveOptions()}
                />
                <AutoDirectionTextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    label={t('orguser.forms.formField.range.max')}
                    sx={{'& div': {height: 40}}}
                    value={updatedMax}
                    onChange={handleUpdate('max')}
                    onBlur={() => saveOptions()}
                />
            </Box>
            {validationErrors && (
                <Typography
                    sx={{
                        mt: 1,
                        fontSize: 14,
                        color: ERROR_TEXT_COLOR,
                    }}
                    variant="body2"
                >
                    {t(validationErrors)}
                </Typography>
            )}
        </Box>
    );
};

export default Range;
