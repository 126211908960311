import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import {FORM_FIELD_METADATA_UPDATE} from 'appRedux/actions/forms';
import {FormFieldMetadataFormType, FormPageTypes, FormFieldTypes} from 'appRedux/actions/forms/types';
import {WorkflowFieldVariableItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {isMetadataVariableDisabled, getSelectedVariablesIds} from 'components/Forms/FormBuilder/FieldForm/helper';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';

import {preventSendForm} from 'helpers/index';

import {theme} from 'config/theme';
import get from 'lodash/get';

interface FieldGdprSettingsFormType {
    fieldId: number;
    initialValues: FormFieldTypes;
    isPopup?: boolean;
}

const FieldMetadataForm: FC<FieldGdprSettingsFormType> = ({fieldId, isPopup, initialValues}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formInfo},
        workflow: {currentWorkflowVariables},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_FIELD_METADATA_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: FormFieldMetadataFormType) => {
        submitUpdateForm({
            id: fieldId,
            ...values,
            variable: values.variable === 0 ? null : values.variable,
            showAlert,
        });
    };

    const {variable} = initialValues;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);
    const selectedVariablesIds: number[] = getSelectedVariablesIds(pages, currentWorkflowVariables);

    return (
        <Formik
            initialValues={{
                variable,
            }}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<FormFieldMetadataFormType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box
                            sx={{
                                pl: 2,
                                pr: 2,
                                pt: 1,
                                pb: 1,
                                mb: 1,
                                borderRadius: 2,
                                border: `1px solid ${theme.palette.secondary.contrastText}`,
                            }}
                        >
                            <Alert sx={{mt: 1, mb: 1}} severity={'info'}>
                                <Markdown>{t('orguser.forms.formField.variableExplanation')}</Markdown>
                            </Alert>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('orguser.forms.formField.variable')}
                            </Typography>
                            <FormControl
                                sx={{
                                    backgroundColor: theme.palette.warning.contrastText,
                                    borderRadius: 3,
                                    pl: 3,
                                    pr: 3,
                                    pt: 1,
                                    pb: 2,
                                    mb: 1,
                                }}
                                required
                                fullWidth
                            >
                                <Field
                                    as="select"
                                    name="variable"
                                    label={t('orguser.forms.formField.variable')}
                                    disabled={isMetadataVariableDisabled(initialValues, Boolean(isPopup))}
                                    options
                                    component={FormikSelectInput}
                                    fullwidth
                                >
                                    <MenuItem key={`variable-none`} value={0}>
                                        <Typography>{t('common.none')}</Typography>
                                    </MenuItem>
                                    {currentWorkflowVariables.map((item: WorkflowFieldVariableItemTypes) => {
                                        const {id, title, uuid} = item;

                                        if (selectedVariablesIds.includes(id) && variable !== id) return null;

                                        return (
                                            <MenuItem key={`variable-${uuid}`} value={id}>
                                                <Typography>{title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FieldMetadataForm;
