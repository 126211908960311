import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, IconButton} from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import CustomMdxEditorSmall from 'components/MdxEditorField/CustomMdxEditorSmall';
import {TranslationFormType} from 'components/Forms/FormBuilder/TranslationForm/validation';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {FormTranslationType} from 'helpers/translationsHelper';

import {DEFAULT_LANGUAGE} from 'config/index';
import Markdown from 'react-markdown';

interface PageTranslateInputType {
    item: FormTranslationType;
    lang: string;
    handleSaveTranslation: (values: TranslationFormType) => void;
    multiline?: boolean;
    viewOnly: boolean;
}

const PageTranslateInput: FC<PageTranslateInputType> = ({item, lang, handleSaveTranslation, multiline, viewOnly}) => {
    const [t] = useTranslation();

    const {keyword, translation, english, isRichText} = item;
    const initialValues = {
        keyword,
        trans: translation,
        lang: lang || DEFAULT_LANGUAGE,
    };
    const [value, setValue] = useState(translation || '');
    const [showMdxEditor, setShowMdxEditor] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<{value: unknown}>) => {
        const newValue = String(e.target.value);
        setValue(newValue);
    };

    useEffect(() => {
        setValue(translation);
    }, [translation]);

    const handleSubmit = () => {
        handleSaveTranslation({...initialValues, trans: value || english});
        setShowMdxEditor(false);
    };

    return isRichText ? (
        <>
            {showMdxEditor ? (
                <Box sx={{position: 'relative', width: '100%'}}>
                    <CustomMdxEditorSmall value={value} onChange={setValue} />
                    <Box sx={{position: 'absolute', right: '4px', bottom: '8px'}}>
                        <AgentSaveButton title={`${t('common.buttons.saveButton')}`} onClick={handleSubmit} />
                    </Box>
                </Box>
            ) : (
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box
                        sx={{
                            flexGrow: '1',
                        }}
                    >
                        <Markdown>{value}</Markdown>
                    </Box>
                    <IconButton onClick={() => setShowMdxEditor(prev => !prev)} disabled={viewOnly}>
                        <SubjectIcon fontSize="small" />
                    </IconButton>
                </Box>
            )}
        </>
    ) : (
        <AutoDirectionTextField
            fullWidth
            multiline={multiline}
            disabled={viewOnly}
            variant="standard"
            label=""
            placeholder=""
            value={value}
            onChange={handleChange}
            onBlur={handleSubmit}
        />
    );
};

export default PageTranslateInput;
