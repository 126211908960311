import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import TabsWrapper from 'components/TabsWrapper/TabsWrapper';

import {
    getTabsList,
    getInitialStepTab,
    getCalendarStepTitle,
    TAB_STEP_ONE,
    TAB_STEP_TWO,
    TAB_STEP_THREE,
    TAB_STEP_FOUR,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';
import CalendarStepOne from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepOne';
import CalendarStepTwo from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepTwo';
import CalendarStepThree from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepThree';
import CalendarStepFive from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepFive';
import RemoveRequesterCaseCalendarButton from 'pages/client/form/fieldTypes/resourceFieldCalendar/RemoveRequesterCaseCalendarButton';

interface CalendarStepsType {
    isDisabled: boolean;
    isRemovingDisabled: boolean;
    field: FormFieldTypes;
    currentCalendar?: RequesterCaseHasCalendarTypes;
}

const CalendarSteps: FC<CalendarStepsType> = ({currentCalendar, field, isDisabled, isRemovingDisabled}) => {
    const [t] = useTranslation();

    const [currentTab, setCurrentTab] = useState<number>(getInitialStepTab(currentCalendar));

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const calendarInfo = allCalendarFormItems.find(item => item.id === currentCalendar?.resourceFieldCalendar);

    return (
        <TabsWrapper tabItems={getTabsList(currentCalendar)} currentTab={currentTab} setCurrentTab={setCurrentTab}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                }}
            >
                <Typography sx={{fontWeight: 700}}>{t(getCalendarStepTitle(currentTab, currentCalendar))}</Typography>
                {!isRemovingDisabled && currentCalendar && (
                    <RemoveRequesterCaseCalendarButton currentCalendar={currentCalendar} />
                )}
            </Box>
            <Box>
                {currentTab === TAB_STEP_ONE && (
                    <CalendarStepOne field={field} currentCalendar={currentCalendar} setCurrentTab={setCurrentTab} />
                )}
                {currentTab === TAB_STEP_TWO && currentCalendar && (
                    <CalendarStepTwo
                        currentCalendar={currentCalendar}
                        setCurrentTab={setCurrentTab}
                        isDisabled={isDisabled}
                    />
                )}
                {currentTab === TAB_STEP_THREE && currentCalendar && calendarInfo && (
                    <CalendarStepThree
                        currentCalendar={currentCalendar}
                        setCurrentTab={setCurrentTab}
                        calendarInfo={calendarInfo}
                        isDisabled={isDisabled}
                    />
                )}
                {currentTab === TAB_STEP_FOUR && currentCalendar && (
                    <CalendarStepFive currentCalendar={currentCalendar} />
                )}
            </Box>
        </TabsWrapper>
    );
};

export default CalendarSteps;
