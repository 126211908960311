import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import {MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';

import {INVITE_CREATE_USER} from 'appRedux/actions/invites';
import {InviteCreateForUserFormType, InviteFormsTypes} from 'appRedux/actions/invites/types';
import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {inviteValidationSchema} from 'components/Forms/Invites/InviteForUserForm/validation';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

interface InviteForUserFormType {
    forms: InviteFormsTypes[];
    users: OrganizationAdminListTypes[];
    setIsOpened: (value: boolean) => void;
}

const InviteForUserForm: FC<InviteForUserFormType> = ({forms, users, setIsOpened}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createInviteForUser = useCallback(
        payload => dispatch({type: INVITE_CREATE_USER.REQUEST, payload}),
        [dispatch],
    );

    const {
        instance: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            initialValues={{
                user: Number(users[0].admin.id),
                id: forms[0].formId,
            }}
            validationSchema={inviteValidationSchema}
            onSubmit={values => {
                createInviteForUser({
                    ...values,
                    callback: () => {
                        setIsOpened(false);
                    },
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<InviteCreateForUserFormType>) => {
                return (
                    <Form>
                        <Box sx={{mr: 1}}>
                            <Field
                                as="select"
                                required
                                name="id"
                                label={t('orguser.invites.parameters.form')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {forms.map(item => (
                                    <MenuItem key={`form-${item.formId}`} value={item.formId}>
                                        {item.formTitle}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Box>
                        <Box sx={{mr: 1, mb: 3}}>
                            <Field
                                as="select"
                                required
                                name="user"
                                label={t('orguser.invites.parameters.orgUser')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {users.map(item => {
                                    const {
                                        admin: {id, avatarType, avatarLink, firstName, lastName, email},
                                    } = item;
                                    return (
                                        <MenuItem key={`user-${id}`} value={id}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    mb: 1,
                                                }}
                                            >
                                                <AvatarImage
                                                    size={25}
                                                    avatarType={Number(avatarType)}
                                                    avatarLink={avatarLink ? String(avatarLink) : null}
                                                    avatarTitle={getAvatarTitle(firstName, lastName, email)}
                                                />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    );
                                })}
                            </Field>
                        </Box>
                        <AgentSaveButton isLoading={isLoading} isSubmit />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InviteForUserForm;
