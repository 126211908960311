import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {OrganizationTimeBucketsTypes} from 'appRedux/actions/organization/types';

import TimeBucketRow from 'pages/admin/organizationTimeTracking/TimeBucketRow';

const OrganizationTimeTracking: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {organizationTimeBuckets},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.timeTracking.title')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.timeTracking.description')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.timeTracking.action')}
                        </Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {organizationTimeBuckets.length > 0 ? (
                        organizationTimeBuckets.map((item: OrganizationTimeBucketsTypes, index: number) => {
                            return <TimeBucketRow key={`workflow-${index}`} item={item} />;
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3} style={{textAlign: 'center'}}>
                                <Typography>{t('superadmin.organizations.timeTracking.none')}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};

export default OrganizationTimeTracking;
