import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {GridColDef} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {GET_WORKFLOW_FIELD_VARIABLES_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import DeleteWorkflowFieldVariableButton from 'pages/admin/workflowFieldVariables/DeleteWorkflowFieldVariableButton';
import EditWorkflowFieldVariableButton from 'pages/admin/workflowFieldVariables/EditWorkflowFieldVariableButton';
import AddWorkflowFieldVariableButton from 'pages/admin/workflowFieldVariables/AddWorkflowFieldVariableButton';

const WorkflowFieldVariablesComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {refreshPageInformation} = useContext(RouteContext);

    const getWorkflowFieldVariablesList = useCallback(
        data => dispatch({type: GET_WORKFLOW_FIELD_VARIABLES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        workflow: {currentWorkflowVariables},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        refreshPageInformation();
        if (uuid) {
            getWorkflowFieldVariablesList({
                uuid,
                showAlert,
            });
        }
    }, [uuid]);

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: t('orguser.fieldVariables.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'internalName',
            headerName: t('orguser.fieldVariables.internalName'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box>
                        <EditWorkflowFieldVariableButton item={row} />
                        <DeleteWorkflowFieldVariableButton item={row} />
                    </Box>
                );
            },
        },
    ];

    return (
        <Box>
            <AddWorkflowFieldVariableButton />
            <DataGridWrapper
                itemsList={currentWorkflowVariables}
                itemsTotalNumber={currentWorkflowVariables ? currentWorkflowVariables.length : 0}
            >
                {currentWorkflowVariables && columns && (
                    <CustomDataGrid
                        rows={currentWorkflowVariables}
                        columns={columns}
                        getRowId={row => row.uuid}
                        checkboxSelection={false}
                        enablePaging
                    />
                )}
            </DataGridWrapper>
        </Box>
    );
};

export default WorkflowFieldVariablesComponent;
