import React, {FC, useContext} from 'react';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import {MediaContext} from 'contexts/media/context';

import {theme} from 'config/theme';

interface CustomizedLoadingButtonType {
    dataId?: string;
    isLoading?: boolean;
    isSubmit?: boolean;
    onClickAction?: () => void;
    label: string;
}

const CustomizedLoadingButton: FC<CustomizedLoadingButtonType> = ({
    dataId,
    isSubmit,
    isLoading,
    onClickAction,
    label,
}) => {
    const {isMobile} = useContext(MediaContext);

    const styles = {
        minWidth: isMobile ? '60vw' : 180,
        minHeight: 40,
        backgroundColor: theme.palette.info.main,
        borderRadius: 24,
        '&:hover': {
            backgroundColor: theme.palette.info.main,
        },
    };

    if (isSubmit) {
        return (
            <LoadingButton
                type={'submit'}
                data-id={dataId}
                fullWidth
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                disableRipple
                sx={styles}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        textTransform: 'none',
                        color: theme.palette.background.default,
                    }}
                >
                    {label}
                </Typography>
            </LoadingButton>
        );
    }
    return (
        <LoadingButton
            type={'button'}
            data-id={dataId}
            fullWidth
            variant="contained"
            loading={isLoading}
            disabled={isLoading}
            disableRipple
            sx={styles}
            onClick={onClickAction}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    textTransform: 'none',
                    color: theme.palette.background.default,
                }}
            >
                {label}
            </Typography>
        </LoadingButton>
    );
};

export default CustomizedLoadingButton;
