import React, {ChangeEvent, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {BoardMenuItemTypes} from 'appRedux/actions/workflow/types';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {IconImageType, iconTypes} from 'pages/agent/savedFilters/helper';

import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

interface FilterFormType {
    updatedTitle: string;
    updatedIconType: string;
    onTitleChange: (e: ChangeEvent<{value: unknown}>) => void;
    onIconTypeChange: (e: SelectChangeEvent) => void;
    updatedBoard: number;
    onBoardChange: (e: SelectChangeEvent) => void;
    errorBoardIsRequired: boolean;
}

const FilterForm: FC<FilterFormType> = ({
    updatedTitle,
    updatedIconType,
    updatedBoard,
    onBoardChange,
    onTitleChange,
    onIconTypeChange,
    errorBoardIsRequired,
}) => {
    const [t] = useTranslation();

    const {
        workflow: {boardsMenu},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            <FormControl>
                <Select
                    name="iconType"
                    sx={{
                        height: 40,
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    value={updatedIconType}
                    variant="standard"
                    onChange={onIconTypeChange}
                >
                    {iconTypes.map(({type, icon}: IconImageType) => {
                        const Icon = icon;
                        return (
                            <MenuItem value={type} key={`icon-type-${type}`}>
                                <IconButton>
                                    <Icon />
                                </IconButton>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl sx={{width: '30%', ml: 2}}>
                <AutoDirectionTextField
                    sx={{mt: 1}}
                    variant="standard"
                    margin="normal"
                    placeholder={t('orguser.savedFilters.filterTitlePlaceholder')}
                    value={updatedTitle}
                    onChange={onTitleChange}
                />
            </FormControl>
            <Box sx={{ml: 2}}>
                <FormControl>
                    <Select
                        name="boards"
                        sx={{
                            minWidth: 150,
                            height: 40,
                        }}
                        IconComponent={KeyboardArrowDownIcon}
                        value={String(updatedBoard)}
                        variant="standard"
                        required
                        onChange={onBoardChange}
                    >
                        {boardsMenu &&
                            boardsMenu.map((item: BoardMenuItemTypes) => {
                                const {title, id} = item;
                                return (
                                    <MenuItem value={id} key={`board-${id}`}>
                                        <Typography>{title}</Typography>
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                {errorBoardIsRequired && <FormFieldErrorMessage errorMessage={t('messages.validation.isRequired')} />}
            </Box>
        </Box>
    );
};

export default FilterForm;
