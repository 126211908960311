import * as yup from 'yup';

import {htmlTagsPresented} from 'helpers/validation';

export const validationImpersonatedCaseSchema = (t: (trans: string) => string) =>
    yup.object({
        firstName: yup.string().required(t('messages.validation.isRequired')),
        lastName: yup.string().required(t('messages.validation.isRequired')),
        email: yup.string().email(t('messages.validation.emailRequired')),
        pinCode: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required('messages.validation.isRequired')
            .test('length', 'messages.validation.pinCodeLength', value => Boolean(value && String(value).length === 4)),
        reason: yup
            .string()
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .nullable(),
    });

export const validationPinCodeSchema = (t: (trans: string) => string) =>
    yup.object({
        pinCode: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required('messages.validation.isRequired')
            .test('length', 'messages.validation.pinCodeLength', value => Boolean(value && String(value).length === 4)),
    });
