import React, {FC, useCallback, useContext, useEffect} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {GET_ROLES_LIST} from 'appRedux/actions/roles';
import {RootReducer} from 'appRedux/reducers';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {FORMS_LIST_BY_ORGANIZATION} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import TableWrapper from 'components/AdminScreenComponents/TableWrapper';

import {getItemsPortionForPage} from 'pages/admin/formsList/helper';
import RolesTable from 'pages/admin/roles/RolesTable';

import {isSuperAdminUser} from 'helpers/roles';

import {ITEMS_PER_PAGE_DEFAULT, PARAMETER_PER_PAGE, routes} from 'config/index';

const Roles: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {currentPage, myOrganization} = useContext(AdminContext);
    const {setBackLink, setBackLinkLabel} = useContext(RouteContext);
    const {isRoleCreateEnabled} = useContext(PermissionContext);

    const getRolesList = useCallback(data => dispatch({type: GET_ROLES_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE) ?? ITEMS_PER_PAGE_DEFAULT;

    const {
        admin: {roles},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const organizationId = id ? id : get(myOrganization, ['id'], null);

    useEffect(() => {
        if (organizationId) {
            getRolesList({
                id: organizationId,
                showAlert,
            });
            getFormsListByOrganization({
                id: organizationId,
                showAlert,
            });
        }
    }, [organizationId]);

    useEffect(() => {
        const isSuperAdmin = profile ? isSuperAdminUser(profile) : false;
        if (isSuperAdmin) {
            setBackLink(`${routes.SUPER_ADMIN}?tab=organizations`);
            setBackLinkLabel(t('common.links.backToOrganizations'));
        }
    }, []);

    const itemsPortion: RoleTypes[] = roles
        ? (getItemsPortionForPage(roles, currentPage, Number(perPageFromUrl)) as RoleTypes[])
        : [];

    return (
        <TableWrapper
            addRoute={isRoleCreateEnabled ? `${routes.ROLE}/${organizationId}/create` : undefined}
            itemsList={roles}
            itemsTotalNumber={roles ? roles.length : 0}
        >
            <RolesTable itemsPortion={itemsPortion} />
        </TableWrapper>
    );
};

export default Roles;
