import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {
    ClientTranslationUpdatedType,
    ClientTranslationUpdatedItemType,
    ClientTranslationTypes,
} from 'appRedux/actions/clientTranslation/types';

import {PermissionContext} from 'contexts/permission/context';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {getInitialTranslatedValue} from 'pages/agent/requesterPage/partials/helper';
import DeleteTranslationButton from 'pages/agent/requesterPage/translation/DeleteTranslationButton';
import EditTranslationButton from 'pages/agent/requesterPage/translation/EditTranslationButton';

interface ClientTranslationType {
    sectionId: number;
    fieldId: number;
    editTranslation: boolean;
    currentTranslation?: ClientTranslationTypes;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations: (value: ClientTranslationUpdatedType) => void;
    currentValue: string;
    popupItem?: number;
}

const ClientTranslation: FC<ClientTranslationType> = ({
    sectionId,
    fieldId,
    editTranslation,
    clientTranslations,
    currentTranslation,
    setClientTranslations,
    currentValue,
    popupItem,
}) => {
    const [t] = useTranslation();

    const {isCaseEditEnabled} = useContext(PermissionContext);

    const currentTranslatedSection = get(clientTranslations, 'section', []);
    const currentTranslations: ClientTranslationUpdatedItemType[] = get(clientTranslations, 'translations', []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = String(e.target.value);
        let otherTranslations: ClientTranslationUpdatedItemType[] = [];
        if (currentTranslatedSection === sectionId) {
            otherTranslations = currentTranslations.filter((item: ClientTranslationUpdatedItemType) => {
                if (popupItem && item.popupItem) {
                    return item.popupItem === popupItem ? item.field !== fieldId : true;
                }
                return item.field !== fieldId;
            });
        }
        otherTranslations.push({
            field: fieldId,
            popupItem: popupItem ? popupItem : undefined,
            initialText: String(currentValue),
            translation: value,
        });

        setClientTranslations({
            section: sectionId,
            translations: otherTranslations,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                mb: 3,
            }}
        >
            <Box
                sx={{
                    backgroundColor: `rgba(34, 34, 34, 0.05)`,
                    borderRadius: 2,
                    width: '100%',
                    p: 2,
                }}
            >
                {editTranslation ? (
                    <FormControl fullWidth>
                        <AutoDirectionTextField
                            sx={{m: 0}}
                            fullWidth
                            margin="normal"
                            rows={4}
                            multiline
                            onChange={handleChange}
                            value={getInitialTranslatedValue(fieldId, clientTranslations, popupItem)}
                            defaultValue={currentTranslation ? currentTranslation.translation : ''}
                            inputProps={{
                                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
                                },
                            }}
                        />
                    </FormControl>
                ) : (
                    <Typography sx={{fontSize: 12}}>
                        {currentTranslation && currentTranslation.translation !== ''
                            ? currentTranslation.translation.replace('&#39;', "'")
                            : t('orguser.requesterCase.translation.notTranslatedYet')}
                    </Typography>
                )}
            </Box>
            {isCaseEditEnabled && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        mt: 1,
                    }}
                >
                    {currentTranslation && currentTranslation.translation !== '' ? (
                        <>
                            <EditTranslationButton
                                fieldId={fieldId}
                                popupItem={popupItem}
                                sectionId={sectionId}
                                currentTranslations={currentTranslations}
                                initialText={currentValue}
                                currentTranslation={currentTranslation}
                                setClientTranslations={setClientTranslations}
                            />
                            <DeleteTranslationButton
                                fieldId={fieldId}
                                sectionId={sectionId}
                                currentTranslations={currentTranslations}
                                currentTranslation={currentTranslation}
                                setClientTranslations={setClientTranslations}
                            />
                        </>
                    ) : (
                        <EditTranslationButton
                            fieldId={fieldId}
                            popupItem={popupItem}
                            sectionId={sectionId}
                            currentTranslations={currentTranslations}
                            initialText={currentValue}
                            setClientTranslations={setClientTranslations}
                            addTranslation
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ClientTranslation;
