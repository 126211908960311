import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {FORM_FIELD_IMPORT_OPTIONS} from 'appRedux/actions/forms';
import {ImportOptionsItemType, ImportOptionsMethodType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {theme} from 'config/theme';

const HEADER_TITLES = ['optionTitle', 'optionText'];
const IMPORT_OPTIONS_NETHODS = ['replace', 'merge', 'append'];

interface OptionsImportType {
    fieldId: number;
    fieldType: number;
    toggleModal: () => void;
}

const OptionsImport: FC<OptionsImportType> = ({fieldId, fieldType, toggleModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const importFieldOptions = useCallback(
        data => dispatch({type: FORM_FIELD_IMPORT_OPTIONS.REQUEST, payload: data}),
        [dispatch],
    );

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [file, setFile] = useState<File | null>(null);
    const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

    const [currentData, setCurrentData] = useState<(Record<string, string> | {skip?: boolean})[] | null>(null);
    const [headers, setHeaders] = useState<string[] | null>(null);
    const [selectedHeaders, setSelectedHeaders] = useState<string[] | null>(null);

    const [method, setMethod] = useState<ImportOptionsMethodType>('replace');

    const [loading, setLoading] = useState<boolean>(false);

    const filename = get(file, ['name'], null);

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (!file) {
            setCurrentData(null);
            setHeaders(null);
            return;
        }

        const reader = new FileReader();
        reader.onload = function (e) {
            const content = e.target?.result;
            const fileType = file.type;

            if (fileType.includes('json')) {
                displayJson(content);
            } else if (fileType.includes('xml')) {
                displayXml(content);
            } else if (fileType.includes('csv') || file.name.endsWith('.csv')) {
                displayCsv(content);
            } else {
                setFileErrorMessage('Unsupported file type. Please upload a JSON, XML, or CSV file.');
            }
        };
        reader.readAsText(file);

        function displayJson(content) {
            const data = JSON.parse(content);
            setCurrentData(data);
            setHeaders(Object.keys(data[0]));
        }

        function displayXml(content) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(content, 'text/xml');
            const rows = Array.from(xmlDoc.getElementsByTagName(xmlDoc.documentElement.nodeName)[0].children);

            if (rows.length > 0) {
                const headers = Array.from(rows[0].children).map(child => child.nodeName);
                const data = rows.map(row => {
                    const obj = {};
                    headers.forEach(header => {
                        obj[header] = row.getElementsByTagName(header)[0].textContent;
                    });
                    return obj;
                });
                setCurrentData(data);
                setHeaders(headers);
            } else {
                setFileErrorMessage('Invalid XML format.');
            }
        }

        function displayCsv(content) {
            const rows = content.split('\n').map(row => row.split(','));
            const headers = rows[0];
            const data = rows.slice(1).map(row => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });
            setCurrentData(data);
            setHeaders(headers);
        }
    }, [file]);

    useEffect(() => {
        const showRichTextField = [FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS].includes(fieldType);
        const headersLength = showRichTextField ? 2 : 1;

        setSelectedHeaders(headers ? headers?.slice(0, headersLength) : null);
    }, [headers, fieldType]);

    const handleHeaderChange =
        (index: number) =>
        (event): void => {
            const value = event.target.value;
            setSelectedHeaders(
                prev => prev && [...(prev?.slice(0, index) || []), value, ...(prev?.slice(index + 1) || [])],
            );
        };

    const handleSelectChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setCurrentData(
            prev =>
                prev && [
                    ...(prev?.slice(0, index) || []),
                    {...prev[index], skip: !checked},
                    ...(prev?.slice(index + 1) || []),
                ],
        );
    };

    console.log(currentData);

    const handleMethodChange = (event): void => {
        const value = event.target.value as ImportOptionsMethodType;
        setMethod(value);
    };

    const handleImport = async () => {
        if (!selectedHeaders || !currentData) return;
        const items: ImportOptionsItemType[] = [];

        currentData?.forEach(item => {
            const isCorrect =
                !item.skip &&
                selectedHeaders.reduce((acc, header) => {
                    return acc && !!item[header];
                }, true);

            if (isCorrect) {
                items.push({title: item[selectedHeaders[0]], text: selectedHeaders[1] && item[selectedHeaders[1]]});
            }
        });

        setLoading(true);

        importFieldOptions({
            fieldId,
            method,
            items,
            showAlert,
            callback: () => {
                setLoading(false);
                toggleModal();
            },
        });
    };

    console.log(currentData);

    return (
        <Box sx={{mb: 2}}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 2,
                }}
            >
                {file ? (
                    <>
                        <IconButton
                            data-id={`button#chat-file-close`}
                            onClick={() => {
                                setFile(null);
                            }}
                        >
                            <CloseSvgIcon />
                        </IconButton>
                        <Typography>{filename}</Typography>
                    </>
                ) : (
                    <>
                        <IconButton data-id={`button#chat-file-add`} onClick={onLabelClick}>
                            <AddIcon />
                        </IconButton>
                        <Typography sx={{color: theme.palette.grey[700]}}>{t('common.buttons.uploadFile')}</Typography>
                    </>
                )}
                <input
                    ref={fileInputRef}
                    data-id={`input#importOptions`}
                    style={{display: 'none'}}
                    name="file"
                    type="file"
                    accept=".json, .xml, .csv"
                    onChange={event => {
                        const newFile = get(event, ['currentTarget', 'files', '0'], null);
                        setFile(newFile);
                        event.target.value = '';
                        setFileErrorMessage(null);
                    }}
                />
                <FormHelperText sx={{color: theme.palette.error.main}}>
                    {fileErrorMessage && t(fileErrorMessage)}
                </FormHelperText>
            </Box>
            <Box sx={{maxHeight: '300px', overflowY: 'auto'}}>
                {headers && currentData && selectedHeaders ? (
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {selectedHeaders.map((header: string, i: number) => (
                                    <TableCell key={`headerSelectCell-${i}`}>
                                        <Typography sx={{fontWeight: 'bold', mb: 1}}>
                                            {t(`orguser.forms.formField.${HEADER_TITLES[i]}`)}
                                        </Typography>
                                        <Select
                                            value={header}
                                            onChange={handleHeaderChange(i)}
                                            IconComponent={ExpandMoreIcon}
                                            size="small"
                                        >
                                            {headers.map((value: string, j: number) => {
                                                return (
                                                    <MenuItem key={`headersOption-${j}`} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{width: '100%'}}>
                            {currentData.map((record, i) => (
                                <TableRow key={`dataRow-${i}`}>
                                    <TableCell sx={{p: 0}} width={50}>
                                        <Checkbox defaultChecked onChange={handleSelectChange(i)} />
                                    </TableCell>
                                    {selectedHeaders.map((header, j) => (
                                        <TableCell key={`dataCell-${j}`}>
                                            {j === 0 ? (
                                                record[header] || '-' // first element always is option value
                                            ) : (
                                                <Markdown>{record[header] || '-'}</Markdown> // option description (can be rich text)
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Box sx={{height: '300px'}}></Box>
                )}
            </Box>
            {headers && currentData && selectedHeaders ? (
                <>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, my: 2}}>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.forms.formField.optionsImportMethod')}</InputLabel>
                            <Select
                                label={t('orguser.forms.formField.optionsImportMethod')}
                                value={method}
                                onChange={handleMethodChange}
                                IconComponent={ExpandMoreIcon}
                            >
                                {IMPORT_OPTIONS_NETHODS.map((value: string, j: number) => {
                                    return (
                                        <MenuItem key={`headersOption-${j}`} value={value}>
                                            {t(`orguser.forms.formField.optionImportMethods.${value}`)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <AgentSaveButton
                            isLoading={loading}
                            isSubmit={false}
                            title={t('orguser.forms.formField.optionsImportModal')}
                            onClick={handleImport}
                        />
                    </Box>
                </>
            ) : (
                <Box sx={{height: '100px'}}></Box>
            )}
        </Box>
    );
};

export default OptionsImport;
