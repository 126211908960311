import React, {ChangeEvent, Dispatch, SetStateAction, KeyboardEvent, FC, useContext, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SubjectIcon from '@mui/icons-material/Subject';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

import HerupuSpinner from 'assets/herupuSpinner';

import {FORM_FIELD_CHANGE_OPTION_PRIORITY} from 'appRedux/actions/forms';
import {FieldOptionType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import CustomMdxEditorSmall from 'components/MdxEditorField/CustomMdxEditorSmall';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import SetCustomOption from 'components/Forms/FormBuilder/FieldForm/partialsOptions/SetCustomOption';
import DeleteOption from 'components/Forms/FormBuilder/FieldForm/partialsOptions/DeleteOption';
import EmptyArea from 'components/EmptyArea';
import DraggableBlockRow from 'components/AdminScreenComponents/DraggableBlockRow';

import {getOptionsListError} from 'components/Forms/FormBuilder/FieldForm/helper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';

interface OptionsListItemType {
    showRichTextField: boolean;
    keyIndex: number;
    editedIndex: number | null;
    optionEditError: number | null;
    loadingOptions: number[];
    updatedOptionTitle: string;
    updatedOptionText: string;
    option: FieldOptionType;
    handleUpdate: (e: ChangeEvent<{value: unknown}>) => void;
    onUpdateClick: (index: number, move?: 'new' | number) => void;
    checkTabPress: (e: KeyboardEvent<HTMLDivElement>, index?: number) => void;
    handleTextUpdate: (value: string) => void;
    onDeleteClick: (value: number) => void;
    showMdxEditor: boolean;
    setShowMdxEditor: (value: boolean) => void;
    toggleShowMdxEditor: () => void;
    onSetCustomClick: (value: number) => void;
    closeAndSave: (value: number) => void;
    toggleEdit: (value: number) => void;
    dragItem?: string;
    setDragItem: Dispatch<SetStateAction<string>>;
    dropTo?: number;
    setDropTo: Dispatch<SetStateAction<number>>;
}

const OptionsListItem: FC<OptionsListItemType> = ({
    option,
    showRichTextField,
    keyIndex,
    editedIndex,
    optionEditError,
    loadingOptions,
    updatedOptionTitle,
    updatedOptionText,
    handleUpdate,
    onUpdateClick,
    checkTabPress,
    handleTextUpdate,
    onDeleteClick,
    showMdxEditor,
    setShowMdxEditor,
    toggleShowMdxEditor,
    onSetCustomClick,
    closeAndSave,
    toggleEdit,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const onEditClick = async (index: number) => {
        closeAndSave(index);
        toggleEdit(index);
    };

    const changePriority = useCallback(
        data => dispatch({type: FORM_FIELD_CHANGE_OPTION_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    return (
        <DraggableBlockRow
            item={option}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 3,
                    p: 1,
                    pl: 2,
                    ml: 1,
                    mt: 1,
                    mb: 1,
                }}
            >
                {editedIndex === keyIndex ? (
                    <>
                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Box
                                sx={{
                                    width: 'calc(100% - 80px)',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <AutoDirectionTextField
                                    sx={{'& div': {height: 40}}}
                                    fullWidth
                                    value={updatedOptionTitle}
                                    onChange={handleUpdate}
                                    onBlur={() => !showRichTextField && onUpdateClick(keyIndex)}
                                    autoFocus
                                    onKeyDown={e => {
                                        if (e.key === 'Tab') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onKeyUp={e => {
                                        e.preventDefault();
                                        checkTabPress(e, keyIndex);
                                    }}
                                />
                            </Box>
                            <EmptyArea />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {loadingOptions.includes(keyIndex) ? (
                                    <HerupuSpinner size={30} />
                                ) : (
                                    showRichTextField && (
                                        <IconButton onClick={toggleShowMdxEditor}>
                                            <SubjectIcon fontSize="small" />
                                        </IconButton>
                                    )
                                )}
                                <IconButton
                                    disabled={updatedOptionTitle.length === 0}
                                    onClick={() => onUpdateClick(keyIndex)}
                                >
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        {showRichTextField && showMdxEditor && (
                            <Box sx={{position: 'relative', width: '100%', mr: 1, my: 1}}>
                                <CustomMdxEditorSmall value={updatedOptionText} onChange={handleTextUpdate} />
                                <Box sx={{position: 'absolute', right: '4px', bottom: '8px'}}>
                                    <AgentSaveButton
                                        title={`${t('common.buttons.saveButton')}`}
                                        onClick={() => onUpdateClick(keyIndex)}
                                    />
                                </Box>
                            </Box>
                        )}
                        {optionEditError && (
                            <Box sx={{width: '100%', mr: 1, my: 1}}>
                                <Typography
                                    sx={{
                                        mt: 0.5,
                                        mb: 1,
                                        fontSize: 14,
                                        color: ERROR_TEXT_COLOR,
                                    }}
                                >
                                    {t(getOptionsListError(optionEditError))}
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'calc(100% - 120px)',
                                height: 30,
                                ml: 1,
                            }}
                            onClick={() => onEditClick(keyIndex)}
                        >
                            <Typography variant="body2">{option.title}</Typography>
                        </Box>
                        <EmptyArea />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SetCustomOption index={keyIndex} isCustom={option.isCustom} onClick={onSetCustomClick} />
                            {showRichTextField && (
                                <IconButton
                                    onClick={async () => {
                                        await onEditClick(keyIndex);
                                        setShowMdxEditor(true);
                                    }}
                                >
                                    <SubjectIcon fontSize="small" color={!option.text ? 'disabled' : 'action'} />
                                </IconButton>
                            )}
                            <DeleteOption index={keyIndex} onDeleteClick={onDeleteClick} />
                        </Box>
                    </>
                )}
            </Box>
        </DraggableBlockRow>
    );
};

export default OptionsListItem;
