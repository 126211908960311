import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SubFormRequestTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import EmptyArea from 'components/EmptyArea';

import DeleteSubFormRequestButton from 'pages/agent/requesterPage/additionalForms/DeleteSubFormRequestButton';

import {getFormTranslatedLabel, getFormKeyword} from 'helpers/translationsHelper';

interface SubFormRequestRowType {
    item: SubFormRequestTypes;
}

const SubFormRequestRow: FC<SubFormRequestRowType> = ({item}) => {
    const [t] = useTranslation();

    const {formId, formTitle, isSubmitted, isClientAdded} = item;

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Typography>
                {`${getFormTranslatedLabel(translations, getFormKeyword(Number(formId), 'title'), formTitle)} (${t(
                    'orguser.forms.subForm',
                )})`}
            </Typography>
            <EmptyArea />
            {!isSubmitted && !isClientAdded && <DeleteSubFormRequestButton item={item} />}
        </Box>
    );
};

export default SubFormRequestRow;
