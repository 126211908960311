import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {AdminContext} from 'contexts/admin/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

const SelectButton: FC = () => {
    const [t] = useTranslation();

    const {isSelectClicked, toggleIsSelectClicked, selectedIds, setSelectedIds} = useContext(AdminContext);

    if (isSelectClicked) {
        return (
            <AgentSaveButton
                isSubmit={false}
                title={`${t('orguser.requesterCase.topbar.select')}${
                    selectedIds.length > 0 ? ' (' + selectedIds.length + ')' : ''
                }`}
                onClick={toggleIsSelectClicked}
                sx={{minWidth: 90}}
            />
        );
    }
    return (
        <AgentInverseButton
            title={t('orguser.requesterCase.topbar.select')}
            onClick={() => {
                toggleIsSelectClicked();
                setSelectedIds([]);
            }}
            sx={{minWidth: 90}}
        />
    );
};

export default SelectButton;
