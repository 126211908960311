import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {SelectChangeEvent} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {AGENT_LIST} from 'appRedux/actions/admin';
import {
    FORM_FILTERS_GET,
    FORM_FILTERS_CLEAR,
    FORMS_LIST_BY_ORGANIZATION,
    FORMS_LIST_BY_BOARD,
} from 'appRedux/actions/forms';
import {AGENT_FILTERS_LIST} from 'appRedux/actions/agentFilters';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import {BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';

import Filter from 'pages/agent/savedFilters/Filter';
import {
    getFormsForFilter,
    getFormFieldOptionsList,
    getAgentsForFilter,
    getTagsForFilter,
} from 'pages/agent/tableView/helper';
import {FilterObjectType} from 'pages/agent/savedFilters/helper';
import MultipleFilter from 'pages/agent/savedFilters/MultipleFilter';

import {getDatePeriodFilters} from 'helpers/filter';
import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {
    DEFAULT_FILTER_OPTION,
    PARAMETER_AGENT,
    PARAMETER_FORM,
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
} from 'config/index';

interface FiltersPanelType {
    boardId: number;
    updatedFilter: FilterObjectType;
    updateFilterStructure: () => void;
}

const FiltersPanel: FC<FiltersPanelType> = ({boardId, updatedFilter, updateFilterStructure}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isFormViewEnabled} = useContext(PermissionContext);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );
    const getFormsListByBoard = useCallback(
        data => dispatch({type: FORMS_LIST_BY_BOARD.REQUEST, payload: data}),
        [dispatch],
    );

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);
    const getSavedFilters = useCallback(() => dispatch({type: AGENT_FILTERS_LIST.REQUEST}), [dispatch]);
    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );
    const clearFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);

    const {
        admin: {
            agentList,
            formsList,
            formFieldFilters: {formId, filters, tags, translations},
        },
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentFormsArray = updatedFilter[PARAMETER_FORM] ? String(updatedFilter[PARAMETER_FORM]).split(',') : [];
    const currentAgentsArray = updatedFilter[PARAMETER_AGENT] ? String(updatedFilter[PARAMETER_AGENT]).split(',') : [];
    const currentTagsArray = updatedFilter[PARAMETER_TAG] ? String(updatedFilter[PARAMETER_TAG]).split(',') : [];
    const currentOptionsArray = updatedFilter[PARAMETER_OPTION]
        ? String(updatedFilter[PARAMETER_OPTION]).split(',')
        : [];

    const selectedBoard = boards.find(item => item.id === boardId);

    useEffect(() => {
        if (selectedBoard && selectedBoard.type !== BOARD_TYPE_OVERVIEW && isFormViewEnabled && myOrganization) {
            getFormsListByBoard({
                uuid: selectedBoard.uuid,
                showAlert,
            });
        } else if (isFormViewEnabled && myOrganization) {
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization, selectedBoard]);

    useEffect(() => {
        getAgents();
        getSavedFilters();
    }, []);

    const handleSelectFilter = (event: SelectChangeEvent<string>, parameter: string) => {
        delete updatedFilter[parameter];
        updatedFilter[parameter] = event.target.value;
        updateFilterStructure();
    };

    const handleSelectForms = (event: SelectChangeEvent<string>) => {
        delete updatedFilter[PARAMETER_FORM];
        delete updatedFilter[PARAMETER_TAG];
        const option = event.target.value;
        if (currentFormsArray.includes(option)) {
            for (let i = 0, n = currentFormsArray.length; i < n; i++) {
                if (currentFormsArray[i] === option) {
                    currentFormsArray.splice(i, 1);
                }
            }
        } else {
            currentFormsArray.push(option);
        }
        const filteredFormsArray = currentFormsArray.filter(item => item !== '');
        updatedFilter[PARAMETER_FORM] = filteredFormsArray;
        updateFilterStructure();
        if (filteredFormsArray.length === 1) {
            const selectedFormId = filteredFormsArray[0];
            getFormFieldFilters({
                id: selectedFormId,
            });
        } else {
            clearFilters();
        }
    };

    const handleSelectAgents = (event: SelectChangeEvent<string>) => {
        delete updatedFilter[PARAMETER_AGENT];
        const option = event.target.value;
        if (option === DEFAULT_FILTER_OPTION) {
            if (currentAgentsArray.includes(DEFAULT_FILTER_OPTION)) {
                currentAgentsArray.splice(0, currentAgentsArray.length);
            } else if (agentList) {
                currentAgentsArray.splice(0, currentAgentsArray.length);
                const agents = getAgentsForFilter(agentList ?? []);
                agents.forEach(item => {
                    currentAgentsArray.push(String(item.name));
                });
            }
        } else {
            if (currentAgentsArray.includes(option)) {
                for (let i = 0, n = currentAgentsArray.length; i < n; i++) {
                    if (currentAgentsArray[i] === option || currentAgentsArray[i] === DEFAULT_FILTER_OPTION) {
                        currentAgentsArray.splice(i, 1);
                    }
                }
            } else {
                currentAgentsArray.push(option);
            }
        }
        updatedFilter[PARAMETER_AGENT] = currentAgentsArray.filter(item => item !== '');
        updateFilterStructure();
    };

    const handleSelectTags = (event: SelectChangeEvent<string>) => {
        delete updatedFilter[PARAMETER_TAG];
        const option = event.target.value;
        if (currentTagsArray.includes(option)) {
            for (let i = 0, n = currentTagsArray.length; i < n; i++) {
                if (currentTagsArray[i] === option) {
                    currentTagsArray.splice(i, 1);
                }
            }
        } else {
            currentTagsArray.push(option);
        }
        updatedFilter[PARAMETER_TAG] = currentTagsArray;
        updateFilterStructure();
    };

    const handleSelectOptions = (event: SelectChangeEvent<string>) => {
        delete updatedFilter[PARAMETER_OPTION];
        const option = event.target.value;
        if (currentOptionsArray.includes(option)) {
            for (let i = 0, n = currentOptionsArray.length; i < n; i++) {
                if (currentOptionsArray[i] === option) {
                    currentOptionsArray.splice(i, 1);
                }
            }
        } else {
            currentOptionsArray.push(option);
        }
        updatedFilter[PARAMETER_OPTION] = currentOptionsArray;
        updateFilterStructure();
    };

    const showFormFiltersAndTags = currentFormsArray.length === 1 && filters.length > 0;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 3,
                mb: 3,
            }}
        >
            <MultipleFilter
                filterLabel={t('orguser.requesterCase.topbar.agent')}
                optionList={getAgentsForFilter(agentList ?? [])}
                handleFilterChange={handleSelectAgents}
                selectedValues={currentAgentsArray}
            />
            <MultipleFilter
                filterLabel={t('orguser.requesterCase.topbar.form')}
                optionList={getFormsForFilter(formsList ?? [])}
                handleFilterChange={handleSelectForms}
                selectedValues={currentFormsArray}
            />
            {showFormFiltersAndTags &&
                formId &&
                filters &&
                filters.map((filter: FormFieldFilterType, index: number) => {
                    const {id, label, options} = filter;
                    const fieldLabelKeyword = getFieldKeyword(formId, Number(id), 'label');
                    return (
                        <MultipleFilter
                            key={`option-${filter.id}-${index}`}
                            filterLabel={getFormTranslatedLabel(translations, fieldLabelKeyword, label)}
                            optionList={getFormFieldOptionsList(formId, options, translations)}
                            handleFilterChange={handleSelectOptions}
                            selectedValues={currentOptionsArray}
                        />
                    );
                })}
            {showFormFiltersAndTags && (
                <MultipleFilter
                    filterLabel={t('orguser.requesterCase.topbar.tags')}
                    optionList={getTagsForFilter(tags)}
                    handleFilterChange={handleSelectTags}
                    selectedValues={currentTagsArray}
                />
            )}
            <Filter
                filterName={'lastCreated'}
                filterLabel={t('orguser.requesterCase.topbar.lastCreated')}
                optionList={getDatePeriodFilters()}
                handleFilterChange={(e: SelectChangeEvent<string>) => handleSelectFilter(e, PARAMETER_LAST_CREATED)}
                parameter={PARAMETER_LAST_CREATED}
                useFilterLabel
            />
            <Filter
                filterName={'lastUpdated'}
                filterLabel={t('orguser.requesterCase.topbar.lastUpdated')}
                optionList={getDatePeriodFilters()}
                handleFilterChange={(e: SelectChangeEvent<string>) => handleSelectFilter(e, PARAMETER_LAST_UPDATED)}
                parameter={PARAMETER_LAST_UPDATED}
                useFilterLabel
            />
        </Box>
    );
};

export default FiltersPanel;
