import React, {FC, useContext, useMemo} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import IconType_10 from 'assets/icons/savedFilters/IconType_10';
import BoardSvgIcon from 'assets/icons/buttons/BoardSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AdminContext} from 'contexts/admin/context';

import {
    AgentMenuItemTypes,
    getFirstAgentBlockItems,
    getBoardsItemsBlock,
    getCollapsedBoardsMenuItem,
} from 'components/AgentScreenComponents/helper';
import MenuBlockItem from 'components/AgentScreenComponents/MenuBlockItem';

import {CASE_ACCESS_REQUEST_CREATED} from 'pages/admin/accessRequests/helper';

import {ITEMS_PER_PAGE_BOARD} from 'config/index';

interface AgentMobileMenuTypes {
    toggleAdminMenu: (value: string) => void;
}

const AgentMobileMenu: FC<AgentMobileMenuTypes> = ({toggleAdminMenu}) => {
    const {isAgentPage} = useContext(RouteContext);
    const permissions = useContext(PermissionContext);
    const {myOrganization} = useContext(AdminContext);

    const {
        admin: {savedFilters},
        analytics: {dashboards},
        crypto: {caseAccessRequests},
        profile: {profile},
        requestChat: {contacts},
        workflow: {favoriteBoards, boardsMenu},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createdCaseAccessRequests = caseAccessRequests.filter(item => item.status === CASE_ACCESS_REQUEST_CREATED);

    const isAdvancedMode: boolean = get(profile, 'isAdvancedMode', false);

    const firstAgentBlockItems: AgentMenuItemTypes[] = getFirstAgentBlockItems(
        get(contacts, [0, 'caseId'], null),
        contacts,
        savedFilters,
        dashboards,
        permissions,
        isAgentPage,
        createdCaseAccessRequests.length,
        isAdvancedMode,
        myOrganization,
        true,
    );

    const {boardsItems, favoriteBoardsItems} = useMemo(() => {
        const favoriteBoardsData = favoriteBoards
            .map(item => ({...(boardsMenu.find(board => board.uuid === item.uuid) || {}), ...item}))
            .filter(item => item) as BoardItemTypes[];

        const boardsData = boardsMenu.filter(item => !favoriteBoards.find(board => board.uuid === item.uuid));

        const boardsItems: AgentMenuItemTypes[] = boardsData
            ? getBoardsItemsBlock(boardsData, ITEMS_PER_PAGE_BOARD, permissions, savedFilters)
            : [];

        const favoriteBoardsItems: AgentMenuItemTypes[] = favoriteBoardsData
            ? getBoardsItemsBlock(favoriteBoardsData, ITEMS_PER_PAGE_BOARD, permissions, savedFilters)
            : [];

        return {boardsItems, favoriteBoardsItems};
    }, [favoriteBoards, boardsMenu, ITEMS_PER_PAGE_BOARD, permissions, savedFilters]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                '@media (min-width: 600px)': {
                    mt: 3,
                    flexDirection: 'column',
                },
            }}
        >
            {firstAgentBlockItems.map((menuItem: AgentMenuItemTypes, index: number) => {
                if (menuItem.isHidden) {
                    return null;
                }

                return (
                    <MenuBlockItem
                        isMenuCollapsed={true}
                        menuItem={menuItem}
                        toggleAdminMenu={toggleAdminMenu}
                        key={`menuItem-${index}`}
                    />
                );
            })}

            {favoriteBoardsItems.length > 0 && (
                <MenuBlockItem
                    isMenuCollapsed={true}
                    menuItem={getCollapsedBoardsMenuItem(favoriteBoardsItems, {
                        title: 'common.menu.favoriteBoards',
                        icon: IconType_10,
                    })}
                    toggleAdminMenu={toggleAdminMenu}
                />
            )}
            {boardsItems.length > 0 && (
                <MenuBlockItem
                    isMenuCollapsed={true}
                    menuItem={getCollapsedBoardsMenuItem(boardsItems, {
                        title: 'common.menu.boards',
                        icon: BoardSvgIcon,
                    })}
                    toggleAdminMenu={toggleAdminMenu}
                />
            )}
        </Box>
    );
};

export default AgentMobileMenu;
