import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseApproveRuleTypes, RequesterCaseApproveTypes} from 'appRedux/actions/requestCase/types';

import {CASE_STATUS_CHANGE_DEFAULT} from 'components/Forms/RequesterCase/helper';

import ApproverItem from 'pages/agent/requesterPage/formInformation/approveRules/ApproverItem';

interface ApproveRuleType {
    item: RequesterCaseApproveRuleTypes;
}

const ApproveRule: FC<ApproveRuleType> = ({item}) => {
    const [t] = useTranslation();

    const {approvers, initialStatusTitle, newStatusTitle} = item;

    const nonApprovedResolutions = approvers
        ? approvers.filter(approver => approver.approverId && approver.status !== CASE_STATUS_CHANGE_DEFAULT)
        : [];

    if (nonApprovedResolutions.length === 0) {
        return <Typography>{t('common.none')}</Typography>;
    }

    return (
        <Box>
            {nonApprovedResolutions &&
                nonApprovedResolutions.map((approver: RequesterCaseApproveTypes) => {
                    const {approverId, status} = approver;
                    return (
                        <ApproverItem
                            item={approver}
                            initialStatusTitle={initialStatusTitle}
                            newStatusTitle={newStatusTitle}
                            key={`approver-${approver.uuid}`}
                        />
                    );
                })}
        </Box>
    );
};

export default ApproveRule;
