import React, {FC, useContext, useEffect, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import {RouteContext} from 'contexts/route/context';

import ChatMessageAvatar from 'components/RequesterChat/ChatMessageAvatar';
import {getAvatarAreaWidth} from 'components/RequesterChat/helper';
import ChatMessageBlock from 'components/RequesterChat/partials/ChatMessageBlock';

import {PARAMETER_MESSAGE_ID} from 'config/index';
import {MediaContext} from 'contexts/media/context';

interface ChatMessageType {
    previousMessageSenderSame: boolean;
    userId: number | null;
    caseId: number;
    item: MessageTypes;
    text: string;
    isMessenger?: boolean;
}

const ChatMessage: FC<ChatMessageType> = ({item, caseId, previousMessageSenderSame, isMessenger, userId, text}) => {
    const [searchParams] = useSearchParams();

    const messageRef = useRef<HTMLDivElement>();

    const currentMessageId = searchParams.get(PARAMETER_MESSAGE_ID);

    const {isMobile} = useContext(MediaContext);
    const {isClientMode} = useContext(RouteContext);

    const {senderId, uuid, thumb, isSystemMessage, isSenderRequester} = item;

    const isCurrentUserSender = senderId === userId;
    const isOtherAgentSender = !isSenderRequester && !isSystemMessage && !isClientMode;
    const isMyMessage = isCurrentUserSender || isOtherAgentSender;

    useEffect(() => {
        if (currentMessageId && currentMessageId === uuid) {
            if (messageRef && messageRef.current) {
                messageRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }, [currentMessageId]);

    return (
        <Box
            ref={messageRef}
            sx={{
                display: 'flex',
                flexDirection: isMyMessage ? 'row-reverse' : 'row',
                justifyContent: isSystemMessage ? 'center' : 'flex-start',
                mt: previousMessageSenderSame ? 1 : 2,
                mb: 1,
                ml: 2,
                mr: 2,
            }}
        >
            {isSystemMessage ? (
                <Box sx={{width: isMessenger ? '80%' : isSystemMessage && isMobile ? '90%' : '60%'}}>
                    <ChatMessageBlock
                        item={item}
                        userId={userId}
                        caseId={caseId}
                        text={text}
                        isCurrentUserSender={isMyMessage}
                        previousMessageSenderSame={previousMessageSenderSame}
                        isMessenger={isMessenger}
                    />
                </Box>
            ) : (
                <Grid
                    container
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={isMyMessage ? 'flex-end' : 'flex-start'}
                    sx={{
                        ml: isMyMessage ? 1 : 'initial',
                    }}
                >
                    {isMyMessage && (
                        <Grid
                            item
                            xs={isMyMessage ? 2 : 0}
                            sm={getAvatarAreaWidth(isMyMessage, isMessenger)}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            sx={{pr: 2}}
                        >
                            {!previousMessageSenderSame && !isMessenger && !isClientMode && (
                                <ChatMessageAvatar item={item} isCurrentUserSender={isCurrentUserSender} />
                            )}
                        </Grid>
                    )}
                    <Grid
                        item
                        sx={{
                            width: thumb ? 'fit-content' : isMessenger ? '91.666666%' : '83.333333%',
                            maxWidth: isMessenger ? '91.666666%' : '83.333333%',
                        }}
                    >
                        <ChatMessageBlock
                            item={item}
                            userId={userId}
                            caseId={caseId}
                            text={text}
                            isCurrentUserSender={isMyMessage}
                            previousMessageSenderSame={previousMessageSenderSame}
                            isMessenger={isMessenger}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default ChatMessage;
