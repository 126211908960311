import {TFunction} from 'i18next';

import {WorkflowStatusTranslationItemTypes} from 'appRedux/actions/workflow/types';

export const getModalDescriptionFormTitle = (
    t: TFunction,
    statusDefaultTitle: string,
    currentTranslation: WorkflowStatusTranslationItemTypes | null,
): string => {
    const statusTitle =
        currentTranslation && currentTranslation.translation ? currentTranslation.translation : statusDefaultTitle;

    if (!currentTranslation) {
        return `${statusTitle}: ${t('orguser.workflows.workflowStatusParameters.addDescription')}`;
    }
    return `${statusTitle}: ${t('orguser.workflows.workflowStatusParameters.updateDescription')}`;
};
