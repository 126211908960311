import React, {FC, useContext, useState, MouseEvent} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {RootReducer} from 'appRedux/reducers';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {RouteContext} from 'contexts/route/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import TicketCreationForm from 'components/Forms/RequesterCase/TicketCreationForm';
import ImpersonatedCaseCreationForm from 'components/Forms/ImpersonalCaseForm/ImpersonatedCaseCreationForm';

import {theme} from 'config/theme';

const CreateCaseButton: FC = () => {
    const [t] = useTranslation();

    const {isBoardCasesPage} = useContext(RouteContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isTicketOpened, setIsTicketOpened] = useState<boolean>(false);
    const [isImpersonatedFormOpened, setIsImpersonatedFormOpened] = useState<boolean>(false);

    const toggleTicketModal = () => {
        setIsTicketOpened(previous => !previous);
    };

    const toggleImpersonatedFormModal = () => {
        setIsImpersonatedFormOpened(previous => !previous);
    };

    const {
        admin: {formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showAddTicketButton = isBoardCasesPage && formsList && formsList.some(item => item.isTicket && item.isActive);
    const showAddImpersonatedCaseButton =
        isBoardCasesPage && formsList && formsList.some(item => item.isImpersonatedCasesEnabled && item.isActive);

    const open = Boolean(anchorEl);

    if (showAddTicketButton && showAddImpersonatedCaseButton) {
        return (
            <>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    onClick={handleClick}
                >
                    <PlusSvgIcon isActive />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            mt: 1,
                            pl: 2,
                            pr: 2,
                            pt: 1,
                            pb: 1,
                            width: 290,
                            borderRadius: 2,
                            overflow: 'visible',
                            boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                            backgroundColor: theme.palette.background.default,
                            '& ul': {
                                padding: 0,
                            },
                            overflowX: 'hidden',
                        },
                    }}
                    transformOrigin={{horizontal: 'left', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                >
                    {showAddTicketButton && (
                        <MenuItem onClick={() => setIsTicketOpened(true)}>{t('orguser.forms.addTicket')}</MenuItem>
                    )}
                    {showAddImpersonatedCaseButton && (
                        <MenuItem onClick={() => setIsImpersonatedFormOpened(true)}>
                            {t('orguser.forms.addImpersonatedCase')}
                        </MenuItem>
                    )}
                </Menu>
                <ModalWrapper
                    isShowModal={isTicketOpened}
                    title={t('orguser.forms.addTicket')}
                    toggleModal={toggleTicketModal}
                >
                    <TicketCreationForm closeModal={() => setIsTicketOpened(false)} />
                </ModalWrapper>
                <ModalWrapper
                    isShowModal={isImpersonatedFormOpened}
                    title={t('orguser.forms.addImpersonatedCase')}
                    toggleModal={toggleImpersonatedFormModal}
                >
                    <ImpersonatedCaseCreationForm closeModal={() => setIsImpersonatedFormOpened(false)} />
                </ModalWrapper>
            </>
        );
    }
    if (showAddTicketButton) {
        return (
            <>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    title={t('orguser.forms.addTicket')}
                    onClick={() => setIsTicketOpened(true)}
                >
                    <PlusSvgIcon isActive />
                </IconButton>
                <ModalWrapper
                    isShowModal={isTicketOpened}
                    title={t('orguser.forms.addTicket')}
                    toggleModal={toggleTicketModal}
                >
                    <TicketCreationForm closeModal={() => setIsTicketOpened(false)} />
                </ModalWrapper>
            </>
        );
    }
    if (showAddImpersonatedCaseButton) {
        return (
            <>
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    title={t('orguser.forms.addImpersonatedCase')}
                    onClick={() => setIsImpersonatedFormOpened(true)}
                >
                    <PlusSvgIcon isActive />
                </IconButton>
                <ModalWrapper
                    isShowModal={isImpersonatedFormOpened}
                    title={t('orguser.forms.addImpersonatedCase')}
                    toggleModal={toggleImpersonatedFormModal}
                >
                    <ImpersonatedCaseCreationForm closeModal={() => setIsImpersonatedFormOpened(false)} />
                </ModalWrapper>
            </>
        );
    }
    return null;
};

export default CreateCaseButton;
