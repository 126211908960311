import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import {useLocation} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {OPEN_ID_AUTH} from 'appRedux/actions/auth';
import {GET_USER_INFO} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {MediaContext} from 'contexts/media/context';

import {LOGIN_SECOND_STEP, getRedirectRoute} from 'pages/start/login/helper';
import {LOGIN_FIRST_STEP} from 'pages/start/login/helper';
import LoginSecondStep from 'pages/start/login/partials/LoginSecondStep';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {LOCAL_STORAGE_REDIRECT_AFTER_LOGIN} from 'services/localStorage';

import {PARAMETER_ID_TOKEN} from 'config/index';

const AuthSso: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {hash} = useLocation();

    const [loginStep, setLoginStep] = useState<string>(LOGIN_FIRST_STEP);
    const [email, setEmail] = useState<string>('');

    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);
    const {setLanguageAfterLogin} = useContext(MediaContext);

    const parsedHash = queryString.parse(hash);

    const idToken = get(parsedHash, PARAMETER_ID_TOKEN, null);

    const openIdAuthRequest = useCallback(data => dispatch({type: OPEN_ID_AUTH.REQUEST, payload: data}), [dispatch]);

    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const navigationCallback = () => {
        getUserInfo({
            callback: async (
                roles: string[],
                organizationMultiRoleType: number,
                languageId: number,
                defaultMode: number,
            ) => {
                setLanguageAfterLogin(languageId);
                const redirectAfterLogin = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                if (redirectAfterLogin) {
                    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                    await regenerateKeysAfterLogin(redirectAfterLogin);
                } else {
                    await regenerateKeysAfterLogin(getRedirectRoute(roles, organizationMultiRoleType, defaultMode));
                }
            },
        });
    };

    const showTwoFactorForm = (email: string) => {
        setEmail(email);
        setLoginStep(LOGIN_SECOND_STEP);
    };

    useEffect(() => {
        if (idToken) {
            openIdAuthRequest({
                showAlert,
                idToken,
                callback: navigationCallback,
                callbackTwoFactor: showTwoFactorForm,
            });
        }
    }, [idToken]);

    return (
        <LoginPageWrapper>
            {loginStep === LOGIN_FIRST_STEP && (
                <Typography sx={{fontWeight: 600, mt: 2}}>{t('common.pleaseWait')}</Typography>
            )}
            {loginStep === LOGIN_SECOND_STEP && <LoginSecondStep email={email} />}
        </LoginPageWrapper>
    );
};

export default AuthSso;
