import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import browserUpdate from 'browser-update';

import {Box, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {ERROR_TEXT_COLOR} from 'config/theme';

const BrowserVersionChecker: React.FC = () => {
    const [t] = useTranslation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        browserUpdate({
            required: {
                e: -4, // Edge - 4 versions behind
                f: -3, // Firefox - 3 versions behind
                o: -3, // Opera - 3 versions behind
                s: -1, // Safari - 1 version behind
                c: -3, // Chrome - 3 versions behind
            },
            insecure: true,
            api: 2025.01,
            reminder: 24,
            dont_show_debuginfo: true,
            onshow: () => {
                setShowModal(true);
            },
            nomessage: true,
        });
    }, []);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalWrapper isShowModal={showModal} title={t('messages.warning.attention')}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    p: 1,
                }}
            >
                <WarningIcon color="warning" sx={{fontSize: 48, mb: 2, color: ERROR_TEXT_COLOR}} />
                <Typography align="center" sx={{fontWeight: 600, mb: 2, fontSize: 22}}>
                    {t('messages.warning.browserVersionOutdated')}
                </Typography>
                <Typography align="center" sx={{mb: 3}}>
                    {t('messages.warning.pleaseUpdateBrowser')}
                </Typography>
                <Box sx={{display: 'flex', gap: 2, mt: 2}}>
                    <AgentSaveButton onClick={handleClose} title={t('common.buttons.close')} />
                </Box>
            </Box>
        </ModalWrapper>
    );
};

export default BrowserVersionChecker;
