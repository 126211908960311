import React, {FC} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {BoardFormItemTypes, WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import {validationSchema} from 'components/Forms/BoardForm/validation';
import {
    boardTypes,
    swimlaneTypes,
    boardPermissionTypes,
    iconTypes,
    BOARD_TYPE_SWIMLANE,
    BOARD_TYPE_OVERVIEW,
} from 'components/Forms/BoardForm/helper';

import {IconImageType, BoardViewType} from 'pages/agent/savedFilters/helper';

interface BoardGeneralFormType {
    initialValues: BoardFormItemTypes;
    onSubmitAction: (values: BoardFormItemTypes, {setErrors}) => void;
    isCreate?: boolean;
}

const BoardGeneralForm: FC<BoardGeneralFormType> = ({initialValues, isCreate, onSubmitAction}) => {
    const [t] = useTranslation();

    const {
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitAction}>
            {(formik: FormikProps<BoardFormItemTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.boards.parameters.title')}
                            component={FormikTextInput}
                        />
                        <Field
                            as="select"
                            required
                            name={'type'}
                            label={t('orguser.boards.parameters.type')}
                            options
                            disabled={!isCreate}
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {boardTypes.map((item: BoardViewType, index: number) => {
                                return (
                                    <MenuItem key={`workflow-${index}`} value={item.type}>
                                        <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        {formik.values.type !== BOARD_TYPE_OVERVIEW && (
                            <Field
                                as="select"
                                required
                                name={'workflow'}
                                label={t('orguser.boards.parameters.workflow')}
                                options
                                disabled={!isCreate}
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                        )}
                        {formik.values.type === BOARD_TYPE_SWIMLANE && (
                            <Field
                                as="select"
                                required
                                name={'typeSwimlane'}
                                label={t('orguser.boards.parameters.swimlaneType')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {swimlaneTypes.map(({type, label}: BoardViewType) => {
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Field>
                        )}
                        <Field
                            as="select"
                            required
                            name={'typePermission'}
                            label={t('orguser.boards.parameters.typePermission')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {boardPermissionTypes.map(({type, label}: BoardViewType) => {
                                return (
                                    <MenuItem value={type} key={`icon-type-${type}`}>
                                        <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <Field
                            as="select"
                            required
                            name={'iconType'}
                            label={t('orguser.boards.parameters.iconType')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {iconTypes.map(({type, icon}: IconImageType) => {
                                const Icon = icon;
                                return (
                                    <MenuItem value={type} key={`icon-type-${type}`}>
                                        <IconButton>
                                            <Icon />
                                        </IconButton>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default BoardGeneralForm;
