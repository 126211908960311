import React, {FC} from 'react';
import {Text} from '@react-pdf/renderer';
import parse from 'html-react-parser';

interface TextAreaPdfContentType {
    text: string;
}

const TextAreaPdfContent: FC<TextAreaPdfContentType> = ({text}) => {
    if (!text) {
        return null;
    }
    if (!text.includes('\n')) {
        return <Text>{parse(text)}</Text>;
    }
    return (
        <>
            {text.split('\n').map((textRow, index) => {
                const parsedText = String(parse(textRow));
                if (!parsedText) return null;
                return <Text key={`text-row-${index}`}>{parsedText}</Text>;
            })}
        </>
    );
};

export default TextAreaPdfContent;
