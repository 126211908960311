import React, {FC, useContext} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import InteractiveCaseEditSvgIcon from 'assets/icons/buttons/InteractiveCaseEditSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import {getNotReadMessagesNumber, getUserNameLabel} from 'pages/agent/requesterPage/partials/helper';
import UpdateImpersonalCaseDetailsButton from 'pages/agent/requesterPage/partials/UpdateImpersonalCaseDetailsButton';

import {checkIsCaseEditEnabled} from 'helpers/permissions';

import {MAIN_CLIENT_COLOR, ERROR_TEXT_COLOR, theme} from 'config/theme';
import {routes, DATE_FORMAT_CLIENT, PARAMETER_CURRENT_BOARD} from 'config/index';

interface RequesterNameBlockType {
    toggleIsChatOpened: () => void;
}

const RequesterNameBlock: FC<RequesterNameBlockType> = ({toggleIsChatOpened}) => {
    const [t] = useTranslation();
    const {caseId, formId} = useParams();
    const navigate = useNavigate();

    const {myOrganization} = useContext(AdminContext);
    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const [searchParams] = useSearchParams();

    const currentBoardId = searchParams.get(PARAMETER_CURRENT_BOARD);

    const {
        admin: {
            formInfo: {isTicketForm, pages, isImpersonatedCasesEnabled},
            requesterCasesList,
        },
        requestCase: {isCaseLoading, currentCase},
        profile: {profile},
        requestChat: {contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {caseLabel, deletedAt, isAnonymousAccount, userEmail, agentEmail, impersonatedCaseDetails} = currentCase;

    const currentUserEmail = get(profile, 'email', null);

    const notReadMessages =
        contacts && Array.isArray(contacts) ? getNotReadMessagesNumber(contacts, Number(caseId)) : null;

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const isInteractiveForm = pages.some(page => page.isAgentPage);

    const relatedCases = requesterCasesList.filter(
        caseItem => currentCase.stack?.uuid && caseItem.stack?.uuid === currentCase.stack?.uuid,
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCaseSelect = (caseId: number) => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}?${PARAMETER_CURRENT_BOARD}=${currentBoardId}`);
        handleClose();
    };

    if (isCaseLoading) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Skeleton variant="rectangular" width={200} height={15} sx={{fontSize: '1rem'}} />
                <Skeleton variant="rectangular" width={250} height={15} sx={{fontSize: '1rem', ml: 1}} />
                <Skeleton variant="circular" sx={{ml: 2}} width={45} height={45} />
                <Skeleton variant="circular" sx={{ml: 2}} width={45} height={45} />
            </Box>
        );
    }

    const redirectToCaseEditPage = () => {
        if (caseId && formId) {
            navigate(`${routes.TICKET}/${caseId}/form/${formId}`);
        }
    };

    const redirectToInteractiveCaseEditPage = () => {
        if (caseId && formId && (isInteractiveForm || isImpersonatedCasesEnabled)) {
            navigate(`${routes.INTERACTIVE_REQUEST}/${caseId}/form/${formId}`);
        }
    };

    if (!myOrganization) return null;

    const isImpersonatedNotClaimedCase = impersonatedCaseDetails && !impersonatedCaseDetails.isClaimed;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: '2px',
            }}
        >
            <Box>
                <Typography sx={{fontSize: 40, fontWeight: 300, lineHeight: '48px'}}>
                    {getUserNameLabel(t, caseLabel, isAnonymousAccount)}
                </Typography>
                {deletedAt && (
                    <Typography sx={{mt: 1, fontSize: 16, color: ERROR_TEXT_COLOR}}>{`(${t(
                        'orguser.requesterCase.deleted',
                    )} - ${moment(deletedAt).format(DATE_FORMAT_CLIENT)})`}</Typography>
                )}
            </Box>

            {!deletedAt && isEditEnabled && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {agentEmail === currentUserEmail && (
                        <IconButton
                            sx={{
                                ml: 2,
                                width: 40,
                                height: 40,
                                borderColor: theme.palette.info.main,
                                borderWidth: 1.5,
                                borderStyle: 'solid',
                            }}
                            onClick={toggleIsChatOpened}
                        >
                            <MessagesSvgIcon isActive={false} />
                        </IconButton>
                    )}
                    {((myOrganization.isAgentPageEnabled && agentEmail === currentUserEmail && isInteractiveForm) ||
                        (userEmail === currentUserEmail && impersonatedCaseDetails)) && (
                        <IconButton
                            sx={{
                                ml: 2,
                                width: 40,
                                height: 40,
                                borderColor: theme.palette.info.main,
                                borderWidth: 1.5,
                                borderStyle: 'solid',
                            }}
                            onClick={redirectToInteractiveCaseEditPage}
                        >
                            <InteractiveCaseEditSvgIcon isActive={false} />
                        </IconButton>
                    )}
                    {isImpersonatedNotClaimedCase && (
                        <UpdateImpersonalCaseDetailsButton impersonatedCaseDetails={impersonatedCaseDetails} />
                    )}
                    {userEmail === currentUserEmail && isTicketForm && (
                        <Badge
                            badgeContent={notReadMessages}
                            invisible={!notReadMessages}
                            sx={{
                                mt: '2px',
                                '& .MuiBadge-badge': {
                                    top: 4,
                                    right: 4,
                                    backgroundColor: MAIN_CLIENT_COLOR,
                                    color: theme.palette.background.default,
                                },
                            }}
                        >
                            <IconButton
                                sx={{
                                    ml: 2,
                                    width: 40,
                                    height: 40,
                                    borderColor: theme.palette.info.main,
                                    borderWidth: 1.5,
                                    borderStyle: 'solid',
                                }}
                                onClick={redirectToCaseEditPage}
                            >
                                <EditSvgIcon isActive={false} />
                            </IconButton>
                        </Badge>
                    )}
                </Box>
            )}
            {relatedCases.length > 0 && (
                <>
                    <Tooltip title={t('orguser.requesterCase.stackCases')} placement="top" arrow>
                        <IconButton
                            onClick={handleClick}
                            sx={{
                                ml: 2,
                                width: 40,
                                height: 40,
                                borderColor: theme.palette.info.main,
                                borderWidth: 1.5,
                                borderStyle: 'solid',
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="related-cases-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                },
                            },
                        }}
                    >
                        {relatedCases.map(caseItem => (
                            <MenuItem
                                key={caseItem.caseId}
                                onClick={() => handleCaseSelect(caseItem.caseId)}
                                sx={{
                                    minWidth: '200px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                disabled={caseItem.caseId === currentCase.id}
                            >
                                {caseItem.caseLabel}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </Box>
    );
};

export default RequesterNameBlock;
