import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import HerupuSpinner from 'assets/herupuSpinner';

import {RootReducer} from 'appRedux/reducers';

import mobileLogo from 'assets/images/mobileLogo.png';

import CustomImage from 'components/CustomImage/CustomImage';

interface OrganizaionLogoType {
    withBorderRadius?: boolean;
    image?: string;
}

const OrganizaionLogo: FC<OrganizaionLogoType> = ({withBorderRadius, image}) => {
    const {
        auth: {isLoading},
        instance: {files},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (isLoading) {
        return <HerupuSpinner />;
    }

    const instanceLogo = get(files, 'logo', null);

    const logo = image || instanceLogo;

    if (logo) {
        return <CustomImage imageSrc={logo} snippetImage={withBorderRadius} maxWidth={90} maxHeight={90} />;
    }
    return <img src={mobileLogo} style={{maxWidth: '100%'}} alt={'Herupu logo'} />;
};

export default OrganizaionLogo;
