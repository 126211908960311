import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import CustomizedLoadingButton from 'components/StartScreenComponents/CustomizedLoadingButton';

interface LoadingButtonType {
    label: string | JSX.Element | JSX.Element[];
    'data-id'?: string;
}

const LoadingStartButton: FC<LoadingButtonType> = ({label, 'data-id': data_id}) => {
    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 1,
                mb: 2,
            }}
        >
            <CustomizedLoadingButton dataId={data_id} isSubmit isLoading={auth.isLoading} label={String(label)} />
        </Box>
    );
};

export default LoadingStartButton;
