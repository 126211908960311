import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {View, Text} from '@react-pdf/renderer';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import {OPTIONS_SEPARATOR, FORM_FIELD_TEXTAREA} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import TextAreaPdfContent from 'pages/agent/pdfExport/pdfPartials/TextAreaPdfContent';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import getFieldMaxValue from 'helpers/getFieldMaxValue';

import {EMPTY_DATA} from 'config/index';

interface FieldPdfContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    result: RequesterCaseItemType | null;
}

const FieldPdfContent: FC<FieldPdfContentType> = ({formId, pageId, sectionId, field, result}) => {
    const {label, id, type} = field;

    const {translations} = useContext(PdfDocumentContext);

    const keyword = getFieldKeyword(formId, id, 'label');

    const maxValue = getFieldMaxValue(field.type, field.options);

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const fieldKeyword = `field-${id}`;

    const value = get(result, [pageKeyword, sectionKeyword, fieldKeyword], EMPTY_DATA);

    const Br = () => '\n';

    return (
        <View style={pdfStyles.field} wrap={false}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>
            <View style={type === FORM_FIELD_TEXTAREA ? pdfStyles.fieldValueTextArea : pdfStyles.fieldValue}>
                {type === FORM_FIELD_TEXTAREA ? (
                    <TextAreaPdfContent text={String(value)} />
                ) : (
                    <Text>
                        {String(value).replaceAll(OPTIONS_SEPARATOR, ', ')}
                        {maxValue || ''}
                    </Text>
                )}
            </View>
        </View>
    );
};

export default FieldPdfContent;
