import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import CustomizedLoadingButton from 'components/StartScreenComponents/CustomizedLoadingButton';

import {routes} from 'config/index';
import {theme} from 'config/theme';

const LoginViaSsoButton: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const redirectToLoginSso = () => {
        navigate(routes.LOGIN_SSO);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 40,
                    color: theme.palette.secondary.contrastText,
                    mb: 2,
                }}
            >
                <Divider sx={{mt: 0, width: '100%'}} />
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2,
                        minWidth: 100,
                        height: 40,
                        borderRadius: 20,
                        border: `1px solid ${theme.palette.secondary.contrastText}`,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Typography sx={{color: theme.palette.info.main}}>{t('common.or')}</Typography>
                </Box>
            </Box>
            <CustomizedLoadingButton label={t('common.loginForm.loginWithSso')} onClickAction={redirectToLoginSso} />
        </>
    );
};

export default LoginViaSsoButton;
