import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {REQUEST_CASE_REMOVING} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import ClientButton from 'components/ClientScreenComponents/ClientButton';
import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface RequesterCaseDraftRemovingButtonType {
    caseId: number;
}

const RequesterCaseDraftRemovingButton: FC<RequesterCaseDraftRemovingButtonType> = ({caseId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isMobile, isTablet, isMiddle} = useContext(MediaContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const requesterCaseRemoving = useCallback(
        data => dispatch({type: REQUEST_CASE_REMOVING.REQUEST, payload: data}),
        [dispatch],
    );

    const onRemoveClicking = () => {
        requesterCaseRemoving({
            id: caseId,
            showAlert,
        });
    };

    return (
        <>
            {isMobile || isTablet || isMiddle ? (
                <IconButton
                    onClick={() => setIsShowModal(true)}
                    sx={{
                        mr: 1,
                        p: 1.25,
                        borderColor: ERROR_TEXT_COLOR,
                        backgroundColor: ERROR_TEXT_COLOR,
                        '&:hover': {
                            backgroundColor: ERROR_TEXT_COLOR,
                        },
                        svg: {
                            height: '20px',
                            width: '20px',
                        },
                    }}
                >
                    <DeleteSvgIcon isActive />
                </IconButton>
            ) : (
                <ClientButton
                    title={t('requester.casesList.removeCaseButton')}
                    onClickAction={() => setIsShowModal(true)}
                    isError
                    endIcon={<CloseSvgIcon isActive />}
                />
            )}
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isShowModal}
                onClickAction={onRemoveClicking}
            />
        </>
    );
};

export default RequesterCaseDraftRemovingButton;
