import React, {FC, useCallback, useContext, useEffect} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {FormTimeBucketTypes} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';

import FormTimeBucketItem from 'pages/admin/updateForm/partials/FormTimeTracking/FormTimeBucketItem';
import {ORGANIZATION_TIME_BUCKETS_LIST} from 'appRedux/actions/organization';
import AddFormTimeBucketButton from './AddFormTimeBucketButton';
import {FORM_TIME_BUCKETS_LIST} from 'appRedux/actions/forms';

interface ComponentType {
    formId: number;
}

const FormTimeTracking: FC<ComponentType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const {
        admin: {organizationTimeBuckets, formInfo, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formTimeBuckets: FormTimeBucketTypes[] = get(formInfo, 'timeBuckets', []);

    const getOrganizationTimeBucketsList = useCallback(
        data => dispatch({type: ORGANIZATION_TIME_BUCKETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormTimeBucketsList = useCallback(
        data => dispatch({type: FORM_TIME_BUCKETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (myOrganization) {
            getOrganizationTimeBucketsList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        getFormTimeBucketsList({
            id: formId,
            showAlert,
        });
    }, [formId]);

    return (
        <Box>
            {!isLoading && formTimeBuckets.length === 0 && (
                <Typography>{t('orguser.forms.timeTracking.none')}</Typography>
            )}
            {formTimeBuckets &&
                formTimeBuckets.length > 0 &&
                formTimeBuckets.map((item: FormTimeBucketTypes) => {
                    return <FormTimeBucketItem key={`form-has-additional-${item.uuid}`} item={item} />;
                })}
            {formTimeBuckets.length < organizationTimeBuckets.length && (
                <Box sx={{mt: 2}}>
                    <AddFormTimeBucketButton formId={formId} />
                </Box>
            )}
        </Box>
    );
};

export default FormTimeTracking;
