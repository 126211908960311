import React, {ChangeEvent, FC} from 'react';

import Box from '@mui/material/Box';

import FormTextField from 'pages/client/form/fieldTypes/FormTextField';

import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getOptionsIdsArray} from 'pages/client/form/fieldTypes/helper';

interface CustomOptionTextFieldType extends FormFieldPropsType {
    customSelectedOptionId: number | null;
    updateOption: (value: number, customAnswer: string | null) => void;
    updatePopupOption: (value: number, customAnswer: string | null) => void;
    isCustomCheckboxField?: boolean;
}

const CustomOptionTextField: FC<CustomOptionTextFieldType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    agentInformation,
    handleChangeInformation,
    onPopupFieldChange,
    popupContent,
    disabled,
    previewMode,
    popupItemId,
    setIsDisabledSubmit,
    onSaveClicked,
    customSelectedOptionId,
    updateOption,
    updatePopupOption,
    isCustomCheckboxField,
    isAgentPage,
}) => {
    const options = field.fieldOptions || [];
    return (
        <Box>
            <FormTextField
                formId={formId}
                pageId={pageId}
                sectionId={sectionId}
                field={field}
                clientInformation={clientInformation}
                agentInformation={agentInformation}
                handleChangeInformation={handleChangeInformation}
                customOnBlur={(e: ChangeEvent<{value: unknown}>) => {
                    if (previewMode) return;
                    const customAnswer = String(e.target.value);
                    if (isPopup) {
                        customSelectedOptionId && updatePopupOption(customSelectedOptionId, customAnswer);
                    } else {
                        customSelectedOptionId && updateOption(customSelectedOptionId, customAnswer);
                    }
                }}
                popupContent={popupContent}
                onPopupFieldChange={onPopupFieldChange}
                isPopup={!!popupItemId}
                disabled={disabled}
                previewMode={previewMode}
                onSaveClicked={onSaveClicked}
                setIsDisabledSubmit={setIsDisabledSubmit}
                isAgentPage={isAgentPage}
                isCustomOption
                isCustomCheckboxField={isCustomCheckboxField}
                optionsIds={getOptionsIdsArray(options)}
                isTextArea
            />
        </Box>
    );
};

export default CustomOptionTextField;
