import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Typography} from '@mui/material';

import {FormVersionCardFieldType} from 'appRedux/actions/forms/types';

import {getAnswerFromResultJson} from 'pages/agent/tableView/helper';
import {
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {getOptionValue} from 'pages/agent/activityPage/helper';

import {ADMIN_LIGHT_GRAY_COLOR} from 'config/theme';

interface RequesterCaseCardFieldType {
    formId: number;
    field: FormVersionCardFieldType;
    resultJson: string | null;
}

const RequesterCaseCardField: FC<RequesterCaseCardFieldType> = ({formId, field, resultJson}) => {
    const [t] = useTranslation();
    const fieldAnswer = useMemo(() => {
        if (!resultJson) return '';
        const result = getAnswerFromResultJson(JSON.parse(resultJson), `field-${field.fieldId}`);
        switch (field.fieldType) {
            case FORM_FIELD_DROPDOWN:
            case FORM_FIELD_RADIOBUTTONS:
            case FORM_FIELD_IMAGE_RADIO:
                return field.fieldOptions?.find(o => o.id === Number(result))?.title || result;
            case FORM_FIELD_CHECKBOXES:
            case FORM_FIELD_IMAGE_CHECK:
                return field.fieldOptions
                    ? getOptionValue(formId, field.fieldType, field.fieldOptions, String(result), [])
                    : '';
            default:
                return result;
        }
    }, [resultJson, field.fieldId]);

    if (!field.fieldLabel) return <></>;

    return (
        <Box key={field.fieldId} sx={{p: 0.5, width: '100%', borderBottom: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`}}>
            <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '13px'}} component={'span'}>
                {field.fieldLabel}:{' '}
            </Typography>
            <Typography variant="body2" sx={{fontSize: '13px'}} component={'span'}>
                {fieldAnswer || t('common.none')}
            </Typography>
        </Box>
    );
};

export default RequesterCaseCardField;
