import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {getSecurityRoute} from 'helpers/menuHelper';

interface ShowLoginToYourAccountPopupType {
    isShowLoginToYourAccountPopup: boolean;
    setIsShowLoginToYourAccountPopup: (value: boolean) => void;
    toggleShowLoginToYourAccountPopup: () => void;
}

const ShowLoginToYourAccountPopup: FC<ShowLoginToYourAccountPopupType> = ({
    isShowLoginToYourAccountPopup,
    setIsShowLoginToYourAccountPopup,
    toggleShowLoginToYourAccountPopup,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isClientMode} = useContext(RouteContext);

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToSecurity = () => {
        if (profile) {
            refreshErrors(null);
            navigate(getSecurityRoute(profile.roles, isClientMode));
            setIsShowLoginToYourAccountPopup(false);
        }
    };

    return (
        <ModalWrapper
            isShowModal={isShowLoginToYourAccountPopup}
            title={t('messages.error.attention')}
            toggleModal={toggleShowLoginToYourAccountPopup}
        >
            <Typography sx={{mb: 1}}>{t('messages.other.newLoginToYourAccount')}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <AgentSaveButton isSubmit={false} title={t('common.buttons.details')} onClick={redirectToSecurity} />
            </Box>
        </ModalWrapper>
    );
};

export default ShowLoginToYourAccountPopup;
