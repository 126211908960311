import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import {getPersonName} from 'components/OrganizationAdminsList/helper';

import ActivityReviewButton from 'pages/agent/activityPage/ActivityReviewButton';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

interface ActivityPageDataGridType {
    items: RequesterCaseActivitiesTypes[];
}

const ActivityPageDataGrid: FC<ActivityPageDataGridType> = ({items}) => {
    const [t] = useTranslation();

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: t('orguser.activities.dateTime'),
            minWidth: 200,
            valueGetter: ({row}) => convertDateToCurrentLocale(row.createdAt),
        },
        {
            field: 'subject',
            headerName: t('orguser.activities.user'),
            minWidth: 200,
            valueGetter: ({row}) => t(getPersonName(row)),
        },
        {
            field: 'element',
            headerName: t('orguser.activities.element'),
            minWidth: 200,
            valueGetter: ({value}) =>
                value ? t(`orguser.activities.types.${String(value.parameter)}`) : `(${t('common.none')})`,
        },
        {
            field: 'action',
            headerName: t('orguser.activities.action'),
            width: 80,
            renderCell: ({row}) => {
                return <ActivityReviewButton item={row} isEncryptInfo={isEncryptInfo} />;
            },
        },
    ];

    return (
        <CustomDataGrid
            rows={items}
            columns={columns}
            columnVisibilityModel={{invite: false}}
            getRowId={row => row.rowId}
            checkboxSelection={false}
            enablePaging
        />
    );
};

export default ActivityPageDataGrid;
