import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_10: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 2.26L9.38 5.05L9.61 5.55L10.11 5.625L13.19 6.07L11 8.22L10.625 8.585L10.715 9.085L11.24 12.15L8.485 10.705L8 10.5L7.535 10.745L4.78 12.17L5.28 9.105L5.37 8.605L5 8.22L2.79 6.045L5.87 5.6L6.37 5.525L6.6 5.025L8 2.26ZM8 0L5.725 4.61L0.639999 5.345L4.32 8.935L3.45 14L8 11.61L12.55 14L11.68 8.935L15.36 5.35L10.275 4.61L8 0Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_10;
