import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FieldOptionType, FormFieldTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {FORM_FIELD_COPY, FORM_FIELD_DELETE, FORM_FIELD_DOWN, FORM_FIELD_UP} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import EmptyArea from 'components/EmptyArea/EmptyArea';

import TopPanel from 'pages/admin/updateForm/partials/FormStructure/TopPanel';
import {getFieldTitle} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {
    UPDATE_FIELD_KEYWORD,
    UPDATE_FIELD_POPUP_KEYWORD,
    REPLACE_FIELD_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';
import RelateSectionButton from 'pages/admin/updateForm/partials/FormStructure/RelateSectionButton';
import RelateSubFormButton from 'pages/admin/updateForm/partials/FormStructure/RelateSubFormButton';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';
import {EMPTY_DATA} from 'config/index';

interface FormFieldSelectionType {
    isFirst: boolean;
    isLast: boolean;
    item: FormFieldTypes;
    sections: FormSectionTypes[];
    setEntityId: (value: number) => void;
    setModalTitle: (value: string) => void;
    toggleModal: () => void;
    isPopup?: boolean;
    relatedSectionsIds?: number[];
    viewOnly: boolean;
}

const FormFieldSelection: FC<FormFieldSelectionType> = ({
    isFirst,
    isLast,
    item,
    sections,
    setEntityId,
    setModalTitle,
    toggleModal,
    isPopup,
    relatedSectionsIds,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        label,
        fieldOptions,
        description,
        id,
        isRequired,
        isRelationWithSectionEnabled,
        isRelationWithSubformEnabled,
    } = item;

    const {
        admin: {
            formInfo: {additionalForms},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleFieldRemove = useCallback(
        data => dispatch({type: FORM_FIELD_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldCopy = useCallback(data => dispatch({type: FORM_FIELD_COPY.REQUEST, payload: data}), [dispatch]);

    const handleFieldUpPriority = useCallback(
        data => dispatch({type: FORM_FIELD_UP.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldDownPriority = useCallback(
        data => dispatch({type: FORM_FIELD_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        toggleModal();
        setModalTitle(isPopup ? UPDATE_FIELD_POPUP_KEYWORD : UPDATE_FIELD_KEYWORD);
        setEntityId(id);
    };

    const onCopyClick = () => {
        handleFieldCopy({
            id,
        });
    };

    const onReplaceClick = () => {
        toggleModal();
        setModalTitle(REPLACE_FIELD_POPUP_KEYWORD);
        setEntityId(id);
    };

    const onDeleteClick = () => {
        handleFieldRemove({
            id,
        });
    };

    const onUpClick = () => {
        handleFieldUpPriority({
            id,
        });
    };

    const onDownClick = () => {
        handleFieldDownPriority({
            id,
        });
    };

    const subForms = additionalForms ? additionalForms.filter(form => form.isSubForm) : [];

    return (
        <Box
            sx={{
                my: 1,
                borderColor: CLIENT_BACKGROUND_COLOR,
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                pb: 1,
            }}
        >
            <TopPanel
                isFirst={isFirst}
                isLast={isLast}
                isRequired={isRequired}
                entityId={id}
                typeTitle={t(getFieldTitle(item))}
                title={label}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onReplaceClick={onReplaceClick}
                onDeleteClick={onDeleteClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                viewOnly={viewOnly}
                showReplaceButton={!isPopup}
            />
            <Box
                sx={{
                    width: '100%',
                    pl: 1,
                    pr: 2,
                    '& p': {
                        px: 1,
                        fontSize: 14,
                        fontWeight: 300,
                    },
                }}
            >
                <Markdown>{description}</Markdown>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pl: 1,
                    pr: 2,
                }}
            >
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    {fieldOptions && fieldOptions.length > 0 ? (
                        fieldOptions.map((option: FieldOptionType) => {
                            const colorIndex = relatedSectionsIds?.findIndex(id => id === option.relatedSection) ?? -1;
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                    key={`option-${option.id}`}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            p: 1,
                                            fontWeight: 300,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {option.title}
                                    </Typography>
                                    {!isPopup && (
                                        <>
                                            <EmptyArea />
                                            {isRelationWithSectionEnabled && (
                                                <RelateSectionButton
                                                    sections={sections}
                                                    option={option}
                                                    colorIndex={colorIndex}
                                                    viewOnly={viewOnly}
                                                />
                                            )}
                                            {isRelationWithSubformEnabled && (
                                                <RelateSubFormButton
                                                    subForms={subForms}
                                                    option={option}
                                                    colorIndex={colorIndex}
                                                    viewOnly={viewOnly}
                                                />
                                            )}
                                        </>
                                    )}
                                </Box>
                            );
                        })
                    ) : (
                        <Typography variant="body2" sx={{p: 1, fontWeight: 300}}>
                            {EMPTY_DATA}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default FormFieldSelection;
