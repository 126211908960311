import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {FORM_TIME_BUCKET_DELETE} from 'appRedux/actions/forms';
import {FormTimeBucketTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface FormTimeBucketDeleteButtonType {
    item: FormTimeBucketTypes;
}

const FormTimeBucketDeleteButton: FC<FormTimeBucketDeleteButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);
    const {isFormEditEnabled} = useContext(PermissionContext);

    const {uuid} = item;

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const deleteFormTimeBucket = useCallback(
        data => dispatch({type: FORM_TIME_BUCKET_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteFormTimeBucket({
            uuid,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={isLoading || !isFormEditEnabled}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default FormTimeBucketDeleteButton;
