import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {REQUEST_CASE_CHANGE_STATUS} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import StatusSwitchButtons from 'pages/agent/requesterPage/formInformation/status/StatusSwitchButtons';
import CurrentStatusLabel from 'pages/agent/requesterPage/formInformation/status/CurrentStatusLabel';

interface ChangeStatusFormType {
    initialStatus: string;
    hasRejectedStatusChangingRequest: boolean;
    currentStatus: FormWorkflowStatusTypes;
}

const ChangeStatusForm: FC<ChangeStatusFormType> = ({
    initialStatus,
    hasRejectedStatusChangingRequest,
    currentStatus,
}) => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [requesterStatus, setRequesterStatus] = useState<string>(initialStatus);

    const changeRequesterStatus = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleStatusChange = (event: SelectChangeEvent) => {
        if (event.target.value) {
            changeRequesterStatus({
                status: event.target.value,
                caseId: Number(caseId),
                showAlert,
                callback: () => {
                    setRequesterStatus(event.target.value as string);
                    getFormByCaseInformation({id: caseId});
                },
            });
        }
    };

    const {
        admin: {formInfo},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setRequesterStatus(initialStatus);
    }, [initialStatus]);

    const {statusPermittedIds, rejectedStatusIds, statusTitle, statusColor} = currentCase;

    const statuses: FormWorkflowStatusTypes[] = get(formInfo, 'statuses', []);

    const filteredStatuses = statuses.filter(status => {
        const {uuid, id} = status;
        return statusPermittedIds?.split(OPTIONS_SEPARATOR).includes(String(id)) || uuid === requesterStatus;
    });

    if (filteredStatuses.length <= 4 || hasRejectedStatusChangingRequest) {
        const selectedStatus = filteredStatuses.find(item => item.uuid === requesterStatus);
        return selectedStatus ? (
            <StatusSwitchButtons
                title={currentStatus.title}
                color={currentStatus.color}
                statuses={filteredStatuses}
                requesterStatus={requesterStatus}
                currentStatus={currentStatus}
                hasRejectedStatusChangingRequest={hasRejectedStatusChangingRequest}
            />
        ) : (
            <CurrentStatusLabel
                title={currentStatus ? currentStatus.title : statusTitle}
                color={currentStatus ? currentStatus.color : statusColor}
            />
        );
    }
    return (
        <FormControl
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
            fullWidth
        >
            <Select
                name="status"
                variant="standard"
                fullWidth
                value={requesterStatus}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleStatusChange}
                sx={{
                    '& div': {
                        display: 'flex',
                    },
                }}
                disableUnderline
            >
                {filteredStatuses &&
                    filteredStatuses.map(item => {
                        const {uuid, color, title, id} = item;
                        if (rejectedStatusIds.includes(id)) {
                            return null;
                        }
                        return (
                            <MenuItem
                                key={`status-${uuid}`}
                                value={uuid}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    pl: 1,
                                }}
                            >
                                <TripOriginIcon sx={{color: color}} />
                                <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
};

export default ChangeStatusForm;
