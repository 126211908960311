import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {SelectChangeEvent} from '@mui/material';

import {
    FORMS_LIST,
    FORMS_LIST_BY_ORGANIZATION,
    FORMS_COPY,
    FORM_MULTIPLE_STATUS_CHANGING,
    FORM_LABEL_ORGANIZATION_LIST,
} from 'appRedux/actions/forms';
import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import FormsDataGrid from 'pages/admin/formsList/FormsDataGrid';
import FormsSuperAdminDataGrid from 'pages/admin/formsList/FormsSuperAdminDataGrid';
import {isMaximalActiveFormsNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import ActiveFormsNumberQuotaAlertMultiplePopup from 'pages/admin/formsList/ActiveFormsNumberQuotaAlertMultiplePopup';
import {getWorkflowListOptions} from 'pages/admin/formsList/helper';
import FormLabelFilters from 'pages/admin/formsList/FormLabelFilters';

import {isSuperAdminUser} from 'helpers/roles';

import {
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_LABEL,
    PARAMETER_SEARCH,
    PARAMETER_WORKFLOW,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    ITEMS_PER_PAGE_DEFAULT,
    DEFAULT_PAGE,
} from 'config/index';

const FormsList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {selectedIds, setSelectedIds, myOrganization} = useContext(AdminContext);
    const {isSuperAdminPage, setTopBarTitle, setTopBarSubtitle, setBackLinkLabel, setBackLink} =
        useContext(RouteContext);
    const {isFormCreateEnabled, isFormEditEnabled, toggleIsTokenUpdated} = useContext(PermissionContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isQuotaHard, setIsQuotaHard] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormLabelsForOrganization = useCallback(
        data => dispatch({type: FORM_LABEL_ORGANIZATION_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {isLoadingFormsList, formsList, formsCount},
        profile: {profile},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const workflowFromUrl = searchParams.get(PARAMETER_WORKFLOW);
    const formLabelsFromUrl = searchParams.get(PARAMETER_LABEL);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const sortByFromUrl = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeFromUrl = searchParams.get(PARAMETER_SORT_TYPE);

    const currentFormLabelsArray = formLabelsFromUrl ? formLabelsFromUrl.split(',') : [];

    const copyMultiple = useCallback(data => dispatch({type: FORMS_COPY.REQUEST, payload: data}), [dispatch]);

    const changeStatusMultiple = useCallback(
        data => dispatch({type: FORM_MULTIPLE_STATUS_CHANGING.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const clearAllSelections = () => {
        setSelectedIds([]);
    };

    const onCopyFormClick = () => {
        copyMultiple({
            ids: selectedIds,
            showAlert,
            callback: () => {
                toggleIsTokenUpdated();
                clearAllSelections();
            },
            workflow: getParameterFromUrl(workflowFromUrl),
            labels: getParameterFromUrl(formLabelsFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            page: pageFromUrl ?? DEFAULT_PAGE,
            perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
            sortBy: sortByFromUrl,
            sortType: sortTypeFromUrl,
        });
    };

    const onActivateAllClick = () => {
        const activeForms = formsList ? formsList.filter(item => item.isActive) : [];
        if (myOrganization && formsList) {
            if (isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + selectedIds.length, true)) {
                setIsQuotaHard(true);
                toggleIsOpened();
            } else if (
                isMaximalActiveFormsNumberReached(myOrganization, activeForms.length + selectedIds.length, false)
            ) {
                setIsQuotaHard(false);
                toggleIsOpened();
            } else {
                changeStatusMultiple({
                    ids: selectedIds,
                    isActive: true,
                    callback: clearAllSelections,
                    showAlert,
                    workflow: getParameterFromUrl(workflowFromUrl),
                    labels: getParameterFromUrl(formLabelsFromUrl),
                    search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
                    page: pageFromUrl ?? DEFAULT_PAGE,
                    perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
                    sortBy: sortByFromUrl,
                    sortType: sortTypeFromUrl,
                });
            }
        }
    };

    const onDeactivateAllClick = () => {
        changeStatusMultiple({
            ids: selectedIds,
            isActive: false,
            callback: clearAllSelections,
            showAlert,
            workflow: getParameterFromUrl(workflowFromUrl),
            labels: getParameterFromUrl(formLabelsFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            page: pageFromUrl ?? DEFAULT_PAGE,
            perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
            sortBy: sortByFromUrl,
            sortType: sortTypeFromUrl,
        });
    };

    const setSearch = (searchParams: URLSearchParams) => {
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        setSearchParams(searchParams);
    };

    const handleSelectParameter = (parameter: string, event: SelectChangeEvent<string>) => {
        searchParams.set(parameter, event.target.value);
        setSearch(searchParams);
    };

    useEffect(() => {
        if (myOrganization && !isSuperAdminPage) {
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
            getFormLabelsForOrganization({
                showAlert,
            });
        }
    }, [myOrganization, isSuperAdminPage]);

    useEffect(() => {
        const commonUrlParameters = {
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            page: pageFromUrl ?? DEFAULT_PAGE,
            perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
            sortBy: sortByFromUrl,
            sortType: sortTypeFromUrl,
        };

        if (isSuperAdminPage) {
            getFormsList({
                showAlert,
                ...commonUrlParameters,
            });
        } else if (myOrganization) {
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
                workflow: getParameterFromUrl(workflowFromUrl),
                labels: getParameterFromUrl(formLabelsFromUrl),
                ...commonUrlParameters,
            });
        }
    }, [
        isSuperAdminPage,
        myOrganization,
        searchFromUrl,
        workflowFromUrl,
        formLabelsFromUrl,
        pageFromUrl,
        perPageFromUrl,
        sortByFromUrl,
        sortByFromUrl,
    ]);

    useEffect(() => {
        setTopBarTitle('');
        setTopBarSubtitle('');
        setBackLink('');
        setBackLinkLabel('');
    }, []);

    if (isSuperAdminPage) {
        return (
            <>
                <DataGridWrapper
                    itemsList={formsList}
                    itemsTotalNumber={formsCount}
                    isLoading={isLoadingFormsList}
                    hasSearch
                >
                    {formsList && <FormsSuperAdminDataGrid />}
                </DataGridWrapper>
            </>
        );
    }
    return (
        <>
            <DataGridWrapper
                itemsList={formsList}
                itemsTotalNumber={formsCount}
                isLoading={isLoadingFormsList}
                onCopyClicked={isFormCreateEnabled ? onCopyFormClick : undefined}
                onActivateClicked={isFormEditEnabled ? onActivateAllClick : undefined}
                onDeactivateClicked={isFormEditEnabled ? onDeactivateAllClick : undefined}
                filters={[
                    {
                        name: 'workflow',
                        label: t('orguser.forms.workflow'),
                        parameter: PARAMETER_WORKFLOW,
                        options: getWorkflowListOptions(organizationWorkflows ?? []),
                        handleFilterChange: e => handleSelectParameter(PARAMETER_WORKFLOW, e),
                    },
                ]}
                hasSearch
                showClearAll={currentFormLabelsArray.length > 0}
            >
                <FormLabelFilters />
                {formsList && <FormsDataGrid />}
            </DataGridWrapper>
            <ActiveFormsNumberQuotaAlertMultiplePopup
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                isQuotaHard={isQuotaHard}
                toggleIsOpened={toggleIsOpened}
                changeStatusMultiple={changeStatusMultiple}
            />
        </>
    );
};

export default FormsList;
