import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_LABEL_DETACH} from 'appRedux/actions/forms';
import {FormLabelTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import TagChipWithDeleting from 'components/TagChip/TagChipWithDeleting';

interface FormLabelsListItemType {
    item: FormLabelTypes;
}

const FormLabelsListItem: FC<FormLabelsListItemType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const detachFormLabel = useCallback(data => dispatch({type: FORM_LABEL_DETACH.REQUEST, payload: data}), [dispatch]);

    const {title, id, uuid} = item;

    const onDetachClick = () => {
        detachFormLabel({
            uuid,
            showAlert,
        });
    };

    return <TagChipWithDeleting id={id} title={title} onDeleteClick={onDetachClick} />;
};

export default FormLabelsListItem;
