import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import AllCasesSvgIcon from 'assets/icons/buttons/AllCasesSvgIcon';
import ListSvgIcon from 'assets/icons/buttons/ListSvgIcon';
import EnvelopmentSvgIcon from 'assets/icons/buttons/EnvelopmentSvgIcon';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {FORMS_LIST_FOR_REQUESTER} from 'appRedux/actions/forms';
import {INVITES_GET_USER_LIST} from 'appRedux/actions/invites';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import TopBarSidebarMenuItem from 'components/ClientScreenComponents/TopBarSidebarMenu/TopBarSidebarMenuItem';

import {routes} from 'config/index';
import {theme} from 'config/theme';
import {ORGS_LIST_FOR_REQUESTER} from 'appRedux/actions/organization';
import {OrgsListForRequesterTypes} from 'appRedux/actions/organization/types';
import TopBarSidebarOrgMenuItem from './TopBarSidebarOrgMenuItem';
import {Box} from '@mui/material';

const MENU_BUTTON_SIZE = 45;

const TopBarSidebarMenu: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formsListForRequester, isFormsListRequested, orgsListForRequester},
        auth: {organization},
        invites: {userInvites},
        profile: {profile},
        auth: {organization: authOrganization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const orgId = get(authOrganization, 'id', null);

    console.log('orgsListForRequester', orgsListForRequester);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getOrgsListForRequester = useCallback(
        data => dispatch({type: ORGS_LIST_FOR_REQUESTER.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormsListForRequester = useCallback(
        data => dispatch({type: FORMS_LIST_FOR_REQUESTER.REQUEST, payload: data}),
        [dispatch],
    );

    const getInvitesListForRequester = useCallback(
        data => dispatch({type: INVITES_GET_USER_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const redirectToCasesList = () => {
        refreshErrors(null);
        navigate(`${routes.REQUESTS_LIST}`);
    };

    const redirectToFormsList = () => {
        refreshErrors(null);
        navigate(`${routes.FORMS_LIST_REQUESTER}`);
    };

    const redirectToInvitesList = () => {
        refreshErrors(null);
        navigate(`${routes.INVITES_LIST_REQUESTER}`);
    };

    useEffect(() => {
        if (profile && !isFormsListRequested) {
            getFormsListForRequester({
                showAlert,
            });
        }
        if (profile && profile.isMultiRoleUser && profile.isRelatedWithCurrentOrganization) {
            getInvitesListForRequester({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        getOrgsListForRequester({
            id: profile?.id,
            showAlert,
        });
    }, [profile]);

    const isShowFormsList = get(organization, 'isShowFormsList', false);

    if (
        (!isShowFormsList || formsListForRequester.length === 0) &&
        userInvites.length === 0 &&
        orgsListForRequester.length <= 1
    ) {
        return (
            <IconButton
                data-id="button@topbar-left-menu"
                sx={{
                    width: MENU_BUTTON_SIZE,
                    height: MENU_BUTTON_SIZE,
                }}
                onClick={redirectToCasesList}
            >
                <MenuIcon sx={{color: theme.palette.background.default}} fontSize="large" />
            </IconButton>
        );
    }

    return (
        <>
            <IconButton
                data-id="button@topbar-left-menu"
                sx={{
                    width: MENU_BUTTON_SIZE,
                    height: MENU_BUTTON_SIZE,
                }}
                onClick={handleClick}
            >
                <MenuIcon sx={{color: theme.palette.background.default}} fontSize="large" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 2,
                        width: 287,
                        borderRadius: 2,
                        overflow: 'visible',
                        top: 10,
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <TopBarSidebarMenuItem
                    title={t(`common.menu.myCases`)}
                    icon={AllCasesSvgIcon}
                    onClickAction={redirectToCasesList}
                    data-id="button#topbar-left-menu-case-list"
                />
                {isShowFormsList && formsListForRequester.length > 0 && (
                    <TopBarSidebarMenuItem
                        title={t(`common.menu.forms`)}
                        icon={ListSvgIcon}
                        onClickAction={redirectToFormsList}
                        data-id="button#topbar-left-menu-form-list"
                    />
                )}
                {userInvites.length > 0 && (
                    <TopBarSidebarMenuItem
                        title={t(`common.menu.invites`)}
                        icon={EnvelopmentSvgIcon}
                        onClickAction={redirectToInvitesList}
                        data-id="button#topbar-left-menu-invites-list"
                    />
                )}
                {orgsListForRequester.length > 0 && (
                    <Box sx={{height: 2, backgroundColor: theme.palette.info.contrastText}}></Box>
                )}
                {orgsListForRequester
                    .sort((a, b) => (a.id === orgId ? -1 : b.id === orgId ? 1 : a.title.localeCompare(b.title)))
                    .map((organization: OrgsListForRequesterTypes, index: number) => {
                        return (
                            <TopBarSidebarOrgMenuItem
                                key={index}
                                organization={organization}
                                isCurrentOrganization={organization.id === orgId}
                            />
                        );
                    })}
            </Menu>
        </>
    );
};

export default TopBarSidebarMenu;
