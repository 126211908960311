import React, {FC} from 'react';

import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {
    RESOURCE_FIELD_TYPE_CALENDAR,
    RESOURCE_FIELD_TYPE_SLOT_TICKET,
    RESOURCE_FIELD_TYPE_BUDGET,
} from 'components/Forms/ResourceField/helper';

import ResourceFieldCalendar from 'pages/client/form/fieldTypes/resourceFieldCalendar/ResourceFieldCalendar';
import ResourceFieldBudget from 'pages/client/form/fieldTypes/resourceFieldBudget/ResourceFieldBudget';
import ResourceFieldSlot from 'pages/client/form/fieldTypes/resourceFieldSlot/ResourceFieldSlot';

interface FormResourceFieldInformationType {
    formId: number;
    sectionId: number;
    isAgentPage: boolean;
    field: FormFieldTypes;
}

const FormResourceFieldInformation: FC<FormResourceFieldInformationType> = ({
    formId,
    isAgentPage,
    sectionId,
    field,
}) => {
    const {resourceFieldType} = field;
    switch (resourceFieldType) {
        case RESOURCE_FIELD_TYPE_CALENDAR:
            return <ResourceFieldCalendar formId={formId} isAgentPage={isAgentPage} field={field} />;
        case RESOURCE_FIELD_TYPE_SLOT_TICKET:
            return <ResourceFieldSlot formId={formId} sectionId={sectionId} field={field} />;
        case RESOURCE_FIELD_TYPE_BUDGET:
            return <ResourceFieldBudget formId={formId} field={field} />;
        default:
            return null;
    }
};

export default FormResourceFieldInformation;
