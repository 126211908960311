import {fork, put, take, call} from 'redux-saga/effects';

import {
    FORM_TIME_BUCKETS_LIST,
    getFormTimeBucketList,
    FORM_TIME_BUCKET_ADD,
    addFormTimeBucket,
    FORM_TIME_BUCKET_DELETE,
    deleteFormTimeBucket,
} from 'appRedux/actions/forms';

import {
    FormTimeBucketListRequestTypes,
    FormTimeBucketAddRequestTypes,
    FormTimeBucketDeleteRequestTypes,
    FormTimeBucketResponseTypes,
} from 'appRedux/actions/forms/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormTimeBucketListGetting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<FormTimeBucketListRequestTypes> = yield take(FORM_TIME_BUCKETS_LIST.REQUEST);
        try {
            const data: FormTimeBucketResponseTypes = yield call(http, `form/${id}/bucket/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormTimeBucketList.success(data.results));
                callback && callback();
            } else {
                yield put(getFormTimeBucketList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormTimeBucketList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTimeBucketCreating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormTimeBucketAddRequestTypes> = yield take(FORM_TIME_BUCKET_ADD.REQUEST);
        try {
            const data: FormTimeBucketResponseTypes = yield call(http, `form/${id}/bucket/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addFormTimeBucket.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormTimeBucket.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(addFormTimeBucket.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTimeBucketDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormTimeBucketDeleteRequestTypes> = yield take(FORM_TIME_BUCKET_DELETE.REQUEST);
        try {
            const data: FormTimeBucketResponseTypes = yield call(http, `form/${uuid}/bucket/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormTimeBucket.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormTimeBucket.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteFormTimeBucket.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormTimeBucketListGetting),
    fork(watchFormTimeBucketCreating),
    fork(watchFormTimeBucketDeleting),
];
