import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_ADMIN_DELETE} from 'appRedux/actions/organizationAdmin';
import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RouteContext} from 'contexts/route/context';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import ReassignAgentButton from 'components/OrganizationAdminsList/ReassignAgentButton';

interface DeleteOrganizationAdminType {
    item: OrganizationAdminListTypes;
}

const DeleteOrganizationAdmin: FC<DeleteOrganizationAdminType> = ({item}) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);
    const {isUserDeleteEnabled} = useContext(PermissionContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const deleteOrganizationAdmin = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const {id, admin} = item;

    const onClickDelete = () => {
        deleteOrganizationAdmin({
            id,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const deletingRestrict = get(admin, 'casesQty', 0);
    const isCurrentUser = Boolean(profile && profile.id === admin.id);

    if (deletingRestrict) {
        return (
            <ReassignAgentButton
                organizationId={id}
                disabled={isLoading || !isUserDeleteEnabled || isCurrentUser}
                isShowModal={showModal}
                toggleModal={toggleModal}
            />
        );
    }
    return (
        <DeleteAdminConfirmationButton
            disabled={isLoading || !isUserDeleteEnabled || isCurrentUser}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteOrganizationAdmin;
