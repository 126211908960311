import React, {FC, useEffect, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {createAppTheme} from 'config/theme';

interface CustomThemeProviderProps {
    children: React.ReactNode;
}

const CustomThemeProvider: FC<CustomThemeProviderProps> = ({children}) => {
    const {i18n} = useTranslation();
    const [direction, setDirection] = useState<'ltr' | 'rtl'>(document.body.dir as 'ltr' | 'rtl');

    useEffect(() => {
        const handleLanguageChange = (lng: string) => {
            const newDirection = lng === 'dari' ? 'rtl' : 'ltr';
            document.body.dir = newDirection;
            setDirection(newDirection);
        };

        // Set initial direction
        handleLanguageChange(i18n.language);

        // Add language change listener
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    return <ThemeProvider theme={createAppTheme(direction)}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
