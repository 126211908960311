import React, {useState, ChangeEvent} from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';

// Helper function to detect if text contains RTL characters
const hasRTLCharacters = (text: string): boolean => {
    const rtlRegex = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/;
    return rtlRegex.test(text);
};

export const AutoDirectionTextField = (props: TextFieldProps) => {
    const [direction, setDirection] = useState<'rtl' | 'ltr'>('ltr');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        const isRTL = hasRTLCharacters(text);
        setDirection(isRTL ? 'rtl' : 'ltr');

        // Call the original onChange if it exists
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            inputProps={{
                ...props.inputProps,
                dir: direction,
            }}
        />
    );
};

export default AutoDirectionTextField;
