export const ADD_AGENT_PAGE_KEYWORD = 'orguser.forms.addNewAgentPage';
export const ADD_PAGE_KEYWORD = 'orguser.forms.addNewPage';
export const UPDATE_PAGE_KEYWORD = 'orguser.forms.updatePage';

export const ADD_NEW_SECTION_KEYWORD = 'orguser.forms.addNewSection';
export const UPDATE_SECTION_KEYWORD = 'orguser.forms.updateSection';

export const ADD_NEW_FIELD_KEYWORD = 'orguser.forms.addNewFormField';
export const UPDATE_FIELD_KEYWORD = 'orguser.forms.updateField';

export const ADD_NEW_POPUP_KEYWORD = 'orguser.forms.addNewPopup';
export const UPDATE_POPUP_KEYWORD = 'orguser.forms.updatePopup';

export const ADD_FIELD_POPUP_KEYWORD = 'orguser.forms.addNewPopupField';
export const UPDATE_FIELD_POPUP_KEYWORD = 'orguser.forms.updatePopupField';

export const REPLACE_SECTION_KEYWORD = 'orguser.forms.moveSection';
export const REPLACE_FIELD_POPUP_KEYWORD = 'orguser.forms.moveField';
export const REPLACE_POPUP_POPUP_KEYWORD = 'orguser.forms.movePopup';
