import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import TripOriginIcon from '@mui/icons-material/TripOrigin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import ArrowRightSvgIcon from 'assets/icons/buttons/ArrowRightSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';

import DeleteApproveRequestsButton from 'pages/agent/requesterPage/formInformation/status/DeleteApproveRequestsButton';
import {
    getRequiredApprovesNumber,
    getNotApprovedStatusChanges,
    checkIsAtLeastOneApprovePresented,
} from 'pages/agent/requesterPage/approverResolution/helper';

interface WaitingForApproveType {
    isEditEnabled: boolean;
}

const WaitingForApprove: FC<WaitingForApproveType> = ({isEditEnabled}) => {
    const [t] = useTranslation();

    const {
        admin: {formInfo},
        requestCase: {
            currentCase: {approveRules, hasStatusChangingPermission},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const statuses: FormWorkflowStatusTypes[] = get(formInfo, 'statuses', []);

    const approvesRequired = getRequiredApprovesNumber(approveRules);
    const notApprovedStatusChanges = getNotApprovedStatusChanges(approveRules);

    const approveWaitingStatus =
        notApprovedStatusChanges.length > 0
            ? statuses.find(item => item.uuid === notApprovedStatusChanges[0].newStatusUuid)
            : null;

    if (notApprovedStatusChanges.length === 0 || !approveWaitingStatus || Number(approvesRequired) === 0) return null;

    return (
        <Box sx={{mt: 2}}>
            <Typography sx={{fontSize: 12, mb: 1}}>{`${t('orguser.requesterCase.approves.waitingForApprove')} (${t(
                'orguser.requesterCase.approves.requiredApproves',
            )}: ${approvesRequired}):`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <IconButton>
                    <ArrowRightSvgIcon />
                </IconButton>
                <TripOriginIcon sx={{color: approveWaitingStatus.color}} />
                <Typography sx={{fontWeight: 600, ml: 1, mr: 2}}>{approveWaitingStatus.title}</Typography>
                {isEditEnabled && hasStatusChangingPermission && !checkIsAtLeastOneApprovePresented(approveRules) && (
                    <DeleteApproveRequestsButton />
                )}
            </Box>
        </Box>
    );
};

export default WaitingForApprove;
