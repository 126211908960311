import {ALERT_TYPE_ERROR} from 'config/index';
import {AlertContext} from 'contexts/alert/context';
import React, {FC, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom';

import {
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_TOKEN,
    isLocalStorageAvailable,
    removeLocalStorageData,
} from 'services/localStorage';

interface RedirectProps {
    to: string;
}

const Redirect: FC<RedirectProps> = ({to}) => {
    const [t] = useTranslation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);

    useEffect(() => {
        const clearTokens = async () => {
            if (!isLocalStorageAvailable()) {
                console.log('local storage is not available');
                showAlert(ALERT_TYPE_ERROR, t('messages.error.localStorageUnavailable'), 6000);
                return;
            }
            await removeLocalStorageData(LOCAL_STORAGE_TOKEN);
            await removeLocalStorageData(LOCAL_STORAGE_REFRESH_TOKEN);
        };
        clearTokens();
    }, []);

    if (hash) {
        return <Navigate to={`${to}${hash}`} replace />;
    }
    return <Navigate to={`${to}/${params['*']}?${searchParams}`} replace />;
};

export default Redirect;
