import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MENTION_MARKER} from 'pages/agent/requesterPage/common/helper';
import MentionInformation from 'components/Comments/MentionInformation';

interface TextWithMentionsType {
    text: string;
    forClient?: boolean;
}

const TextWithMentions: FC<TextWithMentionsType> = ({text, forClient}) => {
    if (!text.includes(MENTION_MARKER)) {
        return <Typography sx={{mb: 1}}>{text}</Typography>;
    }

    const textArray = text.replace(/\s+/g, ' ').split(' ');

    const results: any[] = [];

    textArray.forEach(item => {
        if (item.includes(MENTION_MARKER)) {
            results.push(<MentionInformation item={item} forClient={forClient} />);
        } else {
            results.push(<Typography sx={{display: 'inline-flex', mr: 0.5}}>{item}</Typography>);
        }
    });

    return <Box sx={{display: 'inline'}}>{results.map(item => item)}</Box>;
};

export default TextWithMentions;
