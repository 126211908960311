const timeUnitsInSeconds = {
    week: 40 * 60 * 60, // 40 hours per week
    day: 8 * 60 * 60, // 8 hours per day
    hour: 60 * 60,
    minute: 60,
    second: 1,
};

const defaultMap = {
    week: ['w', 'week', 'weeks'],
    day: ['d', 'day', 'days'],
    hour: ['h', 'hr', 'hour', 'hours'],
    minute: ['m', 'min', 'minute', 'minutes'],
    second: ['s', 'sec', 'second', 'seconds'],
};

type TimeUnitMap = {
    [unit: string]: string[];
};

export const getLaunguageMap = (t: (key: string) => string): TimeUnitMap => {
    const languageMap: TimeUnitMap = {
        week: [
            t('common.timeUnits.week.short'),
            t('common.timeUnits.week.singular'),
            t('common.timeUnits.week.plural'),
        ],
        day: [t('common.timeUnits.day.short'), t('common.timeUnits.day.singular'), t('common.timeUnits.day.plural')],
        hour: [
            t('common.timeUnits.hour.short'),
            t('common.timeUnits.hour.medium'),
            t('common.timeUnits.hour.singular'),
            t('common.timeUnits.hour.plural'),
        ],
        minute: [
            t('common.timeUnits.minute.short'),
            t('common.timeUnits.minute.medium'),
            t('common.timeUnits.minute.singular'),
            t('common.timeUnits.minute.plural'),
        ],
        second: [
            t('common.timeUnits.second.short'),
            t('common.timeUnits.second.medium'),
            t('common.timeUnits.second.singular'),
            t('common.timeUnits.second.plural'),
        ],
    };

    return languageMap;
};

export const parseTimeToSeconds = (input: string, languageMap: TimeUnitMap): number => {
    const mergedMap = Object.keys(languageMap).reduce(
        (acc, key) => {
            if (acc[key]) {
                return acc;
            }
            acc[key] = languageMap[key];
            return acc;
        },
        {...defaultMap},
    );

    let totalSeconds = 0;

    const patterns = Object.values(mergedMap).map(keywords => {
        const keywordPattern = keywords.map(kw => kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
        return `(?:(\\d+(?:\\.\\d+)?)\\s*(${keywordPattern}))`;
    });

    const regex = new RegExp(patterns.join('|'), 'gi');

    input = input.toLowerCase(); // Convert input to lowercase

    let match;
    while ((match = regex.exec(input)) !== null) {
        for (const [unit] of Object.entries(mergedMap)) {
            const groupIndex = match.findIndex(
                (_, idx) =>
                    idx > 0 && mergedMap[unit].some(keyword => keyword.toLowerCase() === match[idx]?.toLowerCase()),
            );
            if (groupIndex !== -1) {
                const value = parseFloat(match[groupIndex - 1].replace(',', '.'));
                totalSeconds += value * timeUnitsInSeconds[unit];
                break;
            }
        }
    }

    return Math.round(totalSeconds);
};

export const convertSecondsToStructure = (totalSeconds: number) => {
    const units = ['week', 'day', 'hour', 'minute', 'second'];
    const structure = {};

    units.forEach(unit => {
        const unitInSeconds = timeUnitsInSeconds[unit];
        structure[unit] = Math.floor(totalSeconds / unitInSeconds);
        totalSeconds %= unitInSeconds;
    });

    return structure;
};

type TimeStructure = {[unit: string]: number};
type LanguageMap = {[unit: string]: string[]};

export const formatStructure = (structure: TimeStructure, languageMap: LanguageMap): string => {
    return Object.entries(structure)
        .filter(([_, value]) => value > 0)
        .map(([unit, value]) => {
            const short_unit = languageMap[unit]?.[0];
            return `${value}${short_unit}`;
        })
        .join(', ');
};

export const convertTimeStringToNum = (input: string, t: (key: string) => string): number => {
    const languageMap = getLaunguageMap(t);

    console.log(input);

    const result = input ? parseTimeToSeconds(input, languageMap) : 0;

    return result;
};

export const convertNumToTimeString = (value: number, t: (key: string) => string): string => {
    const languageMap = getLaunguageMap(t);

    if (value > 0) {
        const structure = convertSecondsToStructure(value);
        const formattedStructure = formatStructure(structure, languageMap);
        return formattedStructure;
    } else {
        return '';
    }
};
