import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {ResourceFieldCalendarSlotItemTypes} from 'appRedux/actions/resourceFields/types';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import {TAB_STEP_THREE} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';

interface CalendarSlotItemType {
    isDisabled: boolean;
    currentCalendar: RequesterCaseHasCalendarTypes;
    slot: ResourceFieldCalendarSlotItemTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarSlotItem: FC<CalendarSlotItemType> = ({isDisabled, slot, currentCalendar, setCurrentTab}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {formField, resourceField, resourceFieldCalendar} = currentCalendar;
    const {setErrorField} = useContext(ClientFormContext);

    const {showAlert} = useContext(AlertContext);

    const {id, name, duration} = slot;

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const isAlreadySelected = currentCalendar.resourceFieldCalendarSlot === id;

    const onCalendarSlotClick = () => {
        if (!isDisabled) {
            updateRequesterCaseCalendar({
                uuid: currentCalendar.uuid,
                formField,
                resourceField,
                resourceFieldCalendar,
                resourceFieldCalendarSlot: id,
                calendarDate: null,
                resourceFieldCalendarBookframe: null,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_THREE);
                        setErrorField(null);
                    }, 500);
                },
            });
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                backgroundColor: isAlreadySelected ? MAIN_CLIENT_COLOR : theme.palette.secondary.contrastText,
                borderRadius: 2,
                cursor: isAlreadySelected || isDisabled ? 'initial' : 'pointer',
                '&:hover': {
                    backgroundColor: isAlreadySelected
                        ? MAIN_CLIENT_COLOR
                        : !isDisabled
                        ? theme.palette.info.main
                        : theme.palette.secondary.contrastText,
                    '& p': {
                        color:
                            !isDisabled || isAlreadySelected
                                ? theme.palette.background.default
                                : theme.palette.info.main,
                    },
                },
            }}
            onClick={onCalendarSlotClick}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {name}
            </Typography>
            <Typography
                sx={{
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {`${duration} ${t('common.units.minutes')}`}
            </Typography>
        </Box>
    );
};

export default CalendarSlotItem;
