import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {BoardItemTypes, WorkflowEventAgentTypes} from 'appRedux/actions/workflow/types';

import AttachedAgentsListItem from 'pages/admin/boardsList/permissions/AttachedAgentsListItem';

interface AttachedAgentsListType {
    item: BoardItemTypes;
}

const AttachedAgentsList: FC<AttachedAgentsListType> = ({item}) => {
    const {agents} = item;
    return (
        <Box sx={{mt: 1, mb: 1}}>
            {agents &&
                agents.map((agent: WorkflowEventAgentTypes) => {
                    return <AttachedAgentsListItem item={agent} key={`attached-agent-${agent.uuid}`} />;
                })}
        </Box>
    );
};

export default AttachedAgentsList;
