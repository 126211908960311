import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FORM_FILTERS_CLEAR, FORM_FILTERS_GET} from 'appRedux/actions/forms';
import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import FilterSvgIcon from 'assets/icons/buttons/FilterSvgIcon';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import Search from 'components/AgentScreenComponents/_topBar/Search';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import SelectButton from 'components/AgentScreenComponents/_caseBlock/SelectButton';
import SelectAllButton from 'components/AgentScreenComponents/_caseBlock/SelectAllButton';
import {filterParameters} from 'components/BlockView/helper';

import {VIEW_MODE_BOARD} from 'helpers/filter';

import {
    PARAMETER_AGENT,
    PARAMETER_VIEW_MODE,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_FIRST_LETTER,
} from 'config/index';

const ActiveCasesTopBar: FC = () => {
    const {search} = useLocation();
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const {
        filtersNumber,
        showFiltersPanel,
        setShowFiltersPanel,
        setFiltersNumber,
        toggleFiltersPanel,
        isSelectClicked,
    } = useContext(AdminContext);
    const {isCaseEditEnabled} = useContext(PermissionContext);

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const currentForms = searchParams.get(PARAMETER_FORM);

    const currentFormsArray = currentForms ? currentForms.split(',') : [];

    const {
        admin: {formFieldFilters},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);
    const refreshCases = useCallback(() => dispatch({type: REQUESTER_CASES_LIST.REQUEST_EMPTY}), [dispatch]);
    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const filters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);

    useEffect(() => {
        const filtersNumber = [
            ...filterParameters,
            ...filters.map((filter: FormFieldFilterType) => `field${filter.id}`),
        ].filter(parameter => searchParams.get(parameter)).length;
        setFiltersNumber(filtersNumber);
    }, [search, filters]);

    const clearAllFilters = () => {
        refreshCases();
        searchParams.delete(PARAMETER_PAGE);
        searchParams.delete(PARAMETER_PER_PAGE);
        searchParams.delete(PARAMETER_AGENT);
        searchParams.delete(PARAMETER_LAST_CREATED);
        searchParams.delete(PARAMETER_LAST_UPDATED);
        searchParams.delete(PARAMETER_TAG);
        searchParams.delete(PARAMETER_FORM);
        searchParams.delete(PARAMETER_FIRST_LETTER);
        filters.map((filter: FormFieldFilterType) => {
            searchParams.delete(`field${filter.id}`);
        });
        setFiltersNumber(0);
        setSearchParams(searchParams);
        clearFormFieldFilters();
    };

    const currentUserId = get(profile, 'id', null);

    const onAllCasesClicked = () => {
        searchParams.set(PARAMETER_AGENT, '');
        setSearchParams(searchParams);
    };

    const onMyCasesClicked = () => {
        searchParams.set(PARAMETER_AGENT, String(currentUserId));
        setSearchParams(searchParams);
    };

    const isMyCasesPage = String(agentFromUrl) === String(currentUserId);
    const isBoardMode = !viewModeFromUrl || viewModeFromUrl === VIEW_MODE_BOARD;

    useEffect(() => {
        return () => {
            setShowFiltersPanel(false);
        };
    }, []);

    useEffect(() => {
        if (currentFormsArray.length === 1) {
            getFormFieldFilters({
                id: currentFormsArray[0],
            });
        }
    }, []);

    if (!profile) {
        return null;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                pt: 0.5,
            }}
        >
            <Search />

            {isMyCasesPage ? (
                <>
                    <AgentInverseButton
                        title={t('orguser.requesterCase.topbar.allCases')}
                        onClick={onAllCasesClicked}
                        sx={{minWidth: 90}}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('orguser.requesterCase.topbar.myCases')}
                        onClick={onMyCasesClicked}
                        sx={{minWidth: 90}}
                    />
                </>
            ) : (
                <>
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('orguser.requesterCase.topbar.allCases')}
                        onClick={onAllCasesClicked}
                        sx={{minWidth: 90}}
                    />
                    <AgentInverseButton
                        title={t('orguser.requesterCase.topbar.myCases')}
                        onClick={onMyCasesClicked}
                        sx={{minWidth: 90}}
                    />
                </>
            )}
            {showFiltersPanel ? (
                <AgentSaveButton
                    isSubmit={false}
                    title={`${t('orguser.requesterCase.topbar.filter')}${
                        filtersNumber > 0 ? ' (' + filtersNumber + ')' : ''
                    }`}
                    onClick={toggleFiltersPanel}
                    icon={filtersNumber === 0 ? <FilterSvgIcon isActive /> : undefined}
                    onClose={filtersNumber > 0 ? clearAllFilters : undefined}
                    sx={{minWidth: 90}}
                />
            ) : (
                <AgentInverseButton
                    title={`${t('orguser.requesterCase.topbar.filter')}${
                        filtersNumber > 0 ? ' (' + filtersNumber + ')' : ''
                    }`}
                    onClick={toggleFiltersPanel}
                    icon={filtersNumber === 0 ? <FilterSvgIcon /> : undefined}
                    onClose={filtersNumber > 0 ? clearAllFilters : undefined}
                    sx={{minWidth: 90}}
                />
            )}
            {isCaseEditEnabled && <SelectButton />}
            {isSelectClicked && isBoardMode && <SelectAllButton />}
        </Box>
    );
};

export default ActiveCasesTopBar;
