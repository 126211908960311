import React, {FC} from 'react';
import {FieldProps} from 'formik';

import FormControl from '@mui/material/FormControl';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core';

import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

const styles = (theme: Theme) =>
    createStyles({
        input: {
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface FormikTextInputTypes {
    name: string;
    label?: string;
    placeholder?: string;
}

const CommentTestField: FC<FieldProps & FormikTextInputTypes & WithStyles<typeof styles>> = ({
    classes,
    field,
    form: {touched, errors},
    name,
    ...props
}) => {
    const fieldName = name || field.name;

    return (
        <>
            <FormControl fullWidth>
                <AutoDirectionTextField
                    sx={{m: 0, backgroundColor: theme.palette.background.default}}
                    fullWidth
                    margin="normal"
                    inputProps={{className: classes.input}}
                    id={name}
                    {...field}
                    {...props}
                />
            </FormControl>
            {touched[fieldName] && errors[fieldName] && (
                <span style={{fontSize: 12, color: ERROR_TEXT_COLOR}}>{String(errors[fieldName])}</span>
            )}
        </>
    );
};

export default withStyles(styles)(CommentTestField);
