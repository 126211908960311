import React, {FC, MouseEvent, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';

import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';
import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';
import TasksCompletedSvgIcon from 'assets/icons/buttons/TasksCompletedSvgIcon';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import TagsPopover from 'components/AgentScreenComponents/_caseBlock/TagsPopover';
import AvatarWithProgress from 'components/AgentScreenComponents/_caseBlock/AvatarWithProgress';
import RequesterCaseDrawer from 'components/AgentScreenComponents/_caseBlock/RequesterCaseDrawer/RequesterCaseDrawer';
import RequesterCaseContextMenu from 'components/AgentScreenComponents/_caseBlock/RequesterCaseContextMenu';
import ChatButton from 'components/AgentScreenComponents/_caseBlock/ChatButton';
import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';
import ImpersonatedCaseDetailsButton from 'components/AgentScreenComponents/_caseBlock/ImpersonatedCaseDetailsButton';

import {getRequesterCaseName} from 'pages/agent/listView/helper';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {routes, PARAMETER_CURRENT_BOARD} from 'config/index';
import {theme, ERROR_TEXT_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';

interface RequestCaseBlockType {
    item: RequesterCaseResponseTypes;
    permittedUuids: string[];
    currentUserEmail: string | null;
}

const RequestCaseListBlock: FC<RequestCaseBlockType> = ({item, permittedUuids, currentUserEmail}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const ref = useRef<HTMLDivElement>();

    const {activeRow, setActiveRow, isSelectClicked, selectedIds, setSelectedIds, toggleIsUpdated} =
        useContext(AdminContext);
    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const {
        workflow: {currentBoard},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [anchorHorizontal, setAnchorHorizontal] = useState<number | null>(null);
    const [anchorVertical, setAnchorVertical] = useState<number | null>(null);

    const onRightClick = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setShowContextMenu(true);
        if (ref && ref.current) {
            const left = event.pageX - ref.current.offsetLeft;
            const top = event.pageY - ref.current.offsetTop;
            setAnchorHorizontal(left);
            setAnchorVertical(top);
        }
    };

    const {
        caseId,
        deletedAt,
        tags,
        formId,
        updatedAt,
        agentEmail,
        notReadMsg,
        hasNotApprovedStatusChangingRequest,
        hasRejectedStatusChangingRequest,
        hasNotCompletedTasks,
        isPinned,
        impersonatedCaseDetails,
    } = item;

    const clickTimeout = useRef<NodeJS.Timeout>();
    const OnBlockClicked = (e: MouseEvent) => {
        switch (e.detail) {
            case 1:
                clickTimeout.current = setTimeout(() => {
                    setActiveRow(caseId);
                }, 200);
                break;
            case 2:
                clearTimeout(clickTimeout.current);
                setActiveRow(null);
                navigate(
                    `${routes.REQUESTER_CASE}/${caseId}/form/${formId}?${PARAMETER_CURRENT_BOARD}=${currentBoard?.id}`,
                );
                break;
        }
    };

    const selectCase = () => {
        if (selectedIds.some(item => item === caseId)) {
            for (let i = 0, n = selectedIds.length; i < n; i++) {
                if (selectedIds[i] === caseId) {
                    selectedIds.splice(i, 1);
                }
            }
        } else {
            selectedIds.push(caseId);
        }
        setIsChecked(previous => !previous);
        setSelectedIds(selectedIds);
        toggleIsUpdated();
    };

    useEffect(() => {
        if (selectedIds.length === 0) {
            setIsChecked(false);
        }
    }, [selectedIds]);

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, formId);

    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                cursor: 'pointer',
                mt: 'initial',
                mb: 1,
                p: 2,
                px: 2.5,
                borderRadius: 2,
                backgroundColor:
                    isSelectClicked && !isChecked ? `rgb(255, 255, 255, 0.4)` : theme.palette.background.default,

                '&:hover': {
                    backgroundColor: isSelectClicked
                        ? !isChecked
                            ? `rgb(7, 216, 137, 0.15)`
                            : `rgb(7, 216, 137, 0.35)`
                        : theme.palette.background.default,
                },
            }}
            onContextMenu={onRightClick}
        >
            {isEditEnabled && (
                <RequesterCaseContextMenu
                    showContextMenu={showContextMenu}
                    setShowContextMenu={setShowContextMenu}
                    anchorHorizontal={anchorHorizontal}
                    anchorVertical={anchorVertical}
                    permittedUuids={permittedUuids}
                    item={item}
                />
            )}
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    borderLeft: isPinned ? `8px solid ${MAIN_CLIENT_COLOR}` : '',
                    opacity: 0.8,
                }}
            />
            <Box sx={{width: '100%'}} onClick={OnBlockClicked}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item sm={3}>
                        <Typography sx={{fontWeight: 600}}>{getRequesterCaseName(t, item)}</Typography>
                        {deletedAt && (
                            <Typography sx={{ml: 1, color: ERROR_TEXT_COLOR}}>{`(${t(
                                'orguser.requesterCase.deleted',
                            )} - ${convertDateToCurrentLocale(deletedAt, false, true)})`}</Typography>
                        )}
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid item sm={4}>
                        <Typography>{`${t('orguser.requesterCase.lastUpdate')}: ${convertDateToCurrentLocale(
                            updatedAt,
                            false,
                            true,
                        )}`}</Typography>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid item sm={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <TagsPopover tags={tags} />
                            {impersonatedCaseDetails && (
                                <ImpersonatedCaseDetailsButton details={impersonatedCaseDetails} />
                            )}
                            {agentEmail === currentUserEmail && isEditEnabled && (
                                <ChatButton caseId={caseId} notReadMsg={notReadMsg} />
                            )}
                            {(hasNotApprovedStatusChangingRequest || hasRejectedStatusChangingRequest) && (
                                <HasNotApprovedStatusChangeRequestsButton
                                    title={t(
                                        hasRejectedStatusChangingRequest
                                            ? 'orguser.requesterCase.hasRejectedRequests'
                                            : 'orguser.requesterCase.hasNotApprovedStatusChangingRequest',
                                    )}
                                    icon={hasRejectedStatusChangingRequest ? WarningSvgIcon : ProgressSvgIcon}
                                />
                            )}
                            {hasNotCompletedTasks && (
                                <HasNotApprovedStatusChangeRequestsButton
                                    title={t('orguser.requesterCase.hasNotCompletedTasks')}
                                    icon={TasksCompletedSvgIcon}
                                />
                            )}
                            <AvatarWithProgress item={item} sx={{ml: 1}} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {isSelectClicked && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '7%',
                    }}
                >
                    <Checkbox
                        checked={isChecked}
                        sx={{p: 0, '& svg': {height: 16, width: 16, color: theme.palette.info.main}}}
                        value={caseId}
                        onChange={selectCase}
                        onClick={e => e.stopPropagation()}
                    />
                </Box>
            )}
            {activeRow === caseId && <RequesterCaseDrawer caseId={caseId} />}
        </Box>
    );
};

export default RequestCaseListBlock;
