import React, {FC} from 'react';
import {FieldProps} from 'formik';
import {get} from 'lodash';
import {Box, FormControl} from '@mui/material';
import {createStyles, InputLabel, WithStyles, withStyles} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

const styles = () =>
    createStyles({
        datepicker: {
            fontSize: 16,
            backgroundColor: '#ffffff',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomColor: '#c4c4c4',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            padding: 0,
            width: '100%',
            marginTop: 20,
            marginBottom: 2,
            height: 32,
            '&:hover': {
                borderColor: '#222222',
                borderWidth: 1.5,
            },
            '&:focus': {
                outline: 'none',
                borderColor: '#222222',
                borderWidth: 2,
            },
        },
    });

interface FormikDatePickerProps extends WithStyles<typeof styles> {
    name?: string;
    classes: {datepicker: string};
    placeholder?: string;
}

const FormBuilderDatePicker: FC<FieldProps & FormikDatePickerProps> = ({
    classes,
    field,
    form: {touched, errors, setFieldValue},
    name,
    placeholder,
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setFieldValue(fieldName, moment(date).format('YYYY-MM-DD'));
        } else {
            setFieldValue(fieldName, '');
        }
    };

    return (
        <Box sx={{width: '100%'}}>
            <FormControl fullWidth>
                <DatePicker
                    selected={field.value ? moment(field.value).toDate() : null}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    className={classes.datepicker}
                    placeholderText={placeholder}
                />
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(FormBuilderDatePicker);
