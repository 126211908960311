import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';
import {WorkflowTaskEmailTemplatesItemTypes} from 'appRedux/actions/workflow/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import {getLanguageById} from 'helpers/translationsHelper';

interface TranslationSelectorType {
    templates: WorkflowTaskEmailTemplatesItemTypes[];
    selectedLanguage: number;
    setSelectedLanguage: (value: number) => void;
    organizationLanguage: LanguageItemTypes;
    submitStatusEventForm: () => void;
}

const TranslationSelector: FC<TranslationSelectorType> = ({
    templates,
    selectedLanguage,
    setSelectedLanguage,
    organizationLanguage,
    submitStatusEventForm,
}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {languageList, organizationLanguages},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: string) => {
        const scrollDelay = setTimeout(async () => {
            await submitStatusEventForm();
            setSelectedLanguage(Number(value));
            setAnchorEl(null);
        }, 500);
        return () => clearTimeout(scrollDelay);
    };

    const currentLanguage = getLanguageById(languageList, selectedLanguage);

    const open = Boolean(anchorEl);

    return (
        <>
            <AgentSelectButton
                title={
                    currentLanguage
                        ? `${t('orguser.forms.selectLanguage')}: ${currentLanguage.name}`
                        : t('orguser.forms.selectLanguage')
                }
                onClick={handleClick}
                open={open}
            />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                <MenuItem
                    value={organizationLanguage.name}
                    key={`form-language-${organizationLanguage.name}`}
                    onClick={() => handleChange(String(organizationLanguage.id))}
                >
                    {organizationLanguage.lang}
                </MenuItem>
                {organizationLanguages &&
                    organizationLanguages.map((item: OrganizationLanguageTypes) => {
                        const {languageId, languageTitle, uuid} = item;
                        if (languageId !== organizationLanguage.id) {
                            const currentWorkflowStatusTranslation =
                                organizationLanguage.id === languageId
                                    ? true
                                    : templates.some(template => template.language === languageId);
                            return (
                                <MenuItem
                                    value={languageId}
                                    key={`organization-language-${uuid}`}
                                    onClick={() => handleChange(String(languageId))}
                                >
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={Boolean(currentWorkflowStatusTranslation)}
                                        sx={{
                                            '& span': {
                                                right: '-5px',
                                            },
                                        }}
                                    >
                                        <Typography>{languageTitle}</Typography>
                                    </Badge>
                                </MenuItem>
                            );
                        }
                    })}
            </MenuAnchorWrapper>
        </>
    );
};

export default TranslationSelector;
