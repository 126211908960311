import React, {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {RootReducer} from 'appRedux/reducers';

import LoginViaSsoButton from 'components/StartScreenComponents/LoginViaSsoButton';

import {routes} from 'config/index';
import {theme} from 'config/theme';

interface LoginIfAlreadyInCaseType {
    alreadyLogin?: boolean;
    onClick?: () => void;
}

const LoginIfAlreadyInCase: FC<LoginIfAlreadyInCaseType> = ({alreadyLogin, onClick}) => {
    const [t] = useTranslation();

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isSsoActive = get(auth, ['organization', 'isSsoActive'], false);
    const isShowSsoLogin = get(auth, ['organization', 'isShowSsoLogin'], false);

    if (alreadyLogin) {
        return (
            <RouterLink to={routes.REQUESTS_LIST}>
                <Typography align="center" sx={{mt: 2}}>
                    {t('common.registrationForm.goToOtherCases')}
                </Typography>
            </RouterLink>
        );
    }

    const showLoginViaSso = isShowSsoLogin && isSsoActive;

    return (
        <>
            <Link
                to={!onClick ? routes.START : ''}
                sx={{mt: 1, textDecoration: 'none'}}
                variant="body2"
                component={RouterLink}
                onClick={e => {
                    if (!onClick) return;
                    e.preventDefault();
                    onClick();
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.info.light,
                    }}
                >
                    {t('common.registrationForm.loginIfAlreadyInCase')}
                </Typography>
            </Link>
            {showLoginViaSso && (
                <Box sx={{mt: showLoginViaSso ? 2 : 'inherit'}}>
                    <LoginViaSsoButton />
                </Box>
            )}
        </>
    );
};

export default LoginIfAlreadyInCase;
