import {TFunction} from 'react-i18next';

export const NUMBER_REGEX = new RegExp(/^[0-9]+$/);
export const PHONE_REGEX = new RegExp(/^[0-9\-()+]+$/);
export const IBAN_REGEX = new RegExp(/[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/);
export const BIC_REGEX = new RegExp(/[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/);
export const DECIMAL_DOT_SEPARATOR_REGEX = new RegExp(/^\d*\.?\d*$/);
export const DECIMAL_COMMA_SEPARATOR_REGEX = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
export const TIME_REGEX = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
export const EMAILS_LIST_VIA_COMMA = new RegExp(
    /^(?!.*((?:(?<![\w.\-+%])[\w._%+-]+@[\w.-]+.[a-zA-Z]{2,}\b)).*\b\1\b)(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/,
);

export const htmlTagsPresented = (value?: string): boolean => {
    const checkingExp = /<\/?[a-z][\s\S]*>/i;
    return value ? !checkingExp.test(value) : true;
};

export const getUsernameLengthErrorMessage = (t: TFunction, keyword: string, limit: number): string => {
    return t(keyword).replace('%NUMBER%', String(limit));
};

export const decimalValidation = (value: string, isDecimalRequired: boolean): boolean => {
    return isDecimalRequired
        ? DECIMAL_DOT_SEPARATOR_REGEX.test(value) || DECIMAL_COMMA_SEPARATOR_REGEX.test(value)
        : true;
};

export const decimalOrIntegerValidation = (value: string): boolean => {
    return (
        DECIMAL_DOT_SEPARATOR_REGEX.test(value) || DECIMAL_COMMA_SEPARATOR_REGEX.test(value) || NUMBER_REGEX.test(value)
    );
};
