import React, {Dispatch, SetStateAction, ChangeEvent, KeyboardEvent, FC, useContext, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import EmptyArea from 'components/EmptyArea';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

import {AlertContext} from 'contexts/alert/context';

import {FORM_FIELD_CHANGE_OPTION_PRIORITY} from 'appRedux/actions/forms';
import {FieldOptionType} from 'appRedux/actions/forms/types';

import DeleteOption from 'components/Forms/FormBuilder/FieldForm/partialsOptions/DeleteOption';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';
import UploadOptionFile from 'components/Forms/FormBuilder/FieldForm/partialsOptions/UploadOptionFile';
import DraggableBlockRow from 'components/AdminScreenComponents/DraggableBlockRow';

import {theme} from 'config/theme';

interface ImageOptionsListItemType {
    keyIndex: number;
    editedIndex: number | null;
    updatedImage: string | null | undefined;
    setUpdatedImage: Dispatch<SetStateAction<string | null | undefined>>;
    updatedOption: string;
    loadingOptions: number[];
    option: FieldOptionType;
    fieldOptions: FieldOptionType[];
    onEditClick: (value: number) => void;
    onDeleteClick: (value: number) => void;
    handleUpdate: (e: ChangeEvent<{value: unknown}>) => void;
    onUpdateClick: (value: number, move?: 'new' | number) => void;
    findDuplicates: (value: string) => boolean;
    checkTabPress: (e: KeyboardEvent<HTMLDivElement>, index?: number) => void;
    dragItem?: string;
    setDragItem: Dispatch<SetStateAction<string>>;
    dropTo?: number;
    setDropTo: Dispatch<SetStateAction<number>>;
}

const ImageOptionsListItem: FC<ImageOptionsListItemType> = ({
    keyIndex,
    editedIndex,
    updatedImage,
    setUpdatedImage,
    updatedOption,
    loadingOptions,
    option,
    fieldOptions,
    onEditClick,
    onDeleteClick,
    handleUpdate,
    onUpdateClick,
    findDuplicates,
    checkTabPress,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const changePriority = useCallback(
        data => dispatch({type: FORM_FIELD_CHANGE_OPTION_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    return (
        <DraggableBlockRow
            item={option}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 3,
                    p: 1,
                    pl: 2,
                    ml: 1,
                    mt: 1,
                    mb: 1,
                }}
            >
                {editedIndex === keyIndex ? (
                    <>
                        <UploadOptionFile
                            file={updatedImage || option.fileLink}
                            setFile={setUpdatedImage}
                            loading={loadingOptions.includes(keyIndex)}
                        />
                        <Box sx={{width: '90%', ml: 1}}>
                            <AutoDirectionTextField
                                sx={{'& div': {height: 40}}}
                                fullWidth
                                value={updatedOption}
                                onChange={handleUpdate}
                                autoFocus
                                onKeyDown={e => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                    }
                                }}
                                onKeyUp={e => {
                                    e.preventDefault();
                                    checkTabPress(e, keyIndex);
                                }}
                            />
                        </Box>
                        <EmptyArea />
                        <Box>
                            <IconButton
                                disabled={updatedOption.length === 0 || findDuplicates(updatedOption)}
                                onClick={() => onUpdateClick(keyIndex)}
                            >
                                <CheckIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <>
                        <UploadOptionFile
                            file={fieldOptions?.[keyIndex]?.fileLink}
                            onClick={() => onEditClick(keyIndex)}
                        />
                        <Box
                            sx={{width: '90%', height: 30, display: 'flex', alignItems: 'center', ml: 2}}
                            onClick={() => onEditClick(keyIndex)}
                        >
                            <Typography variant="body2">{option.title}</Typography>
                        </Box>
                        <EmptyArea />
                        <Box>
                            <DeleteOption index={keyIndex} onDeleteClick={onDeleteClick} />
                        </Box>
                    </>
                )}
            </Box>
        </DraggableBlockRow>
    );
};

export default ImageOptionsListItem;
