import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';

import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ComponentType {
    initialValues: WorkflowStatusTranslationFormTypes;
    onSubmitClicked: (values: WorkflowStatusTranslationFormTypes) => void;
}

const WorkflowStatusDescriptionForm: FC<ComponentType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmitClicked}>
            {(formik: FormikProps<WorkflowStatusTranslationFormTypes>) => {
                return (
                    <Form>
                        <MdxEditorSmallField
                            name="translationDescription"
                            value={
                                formik.values.translationDescription ? String(formik.values.translationDescription) : ''
                            }
                            label={t('orguser.workflows.workflowStatusParameters.description')}
                        />
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusDescriptionForm;
