import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const InteractiveCaseEditSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                d="M14.8535 9.6465L13.3535 8.1465C13.3071 8.10005 13.252 8.0632 13.1913 8.03806C13.1307 8.01292 13.0657 7.99998 13 7.99998C12.9343 7.99998 12.8693 8.01292 12.8087 8.03806C12.748 8.0632 12.6929 8.10005 12.6465 8.1465L8 12.7929V15H10.2071L14.8535 10.3535C14.9 10.3071 14.9368 10.252 14.9619 10.1913C14.9871 10.1307 15 10.0657 15 10C15 9.93434 14.9871 9.86932 14.9619 9.80867C14.9368 9.74801 14.9 9.69291 14.8535 9.6465ZM9.79295 14H9V13.2071L11.5 10.7071L12.2929 11.5L9.79295 14ZM13 10.7929L12.2071 10L13 9.20705L13.7929 10L13 10.7929Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path d="M9 7V9H10V7H9Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M7 6V9H8V6H7Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M5 4V9H6V4H5Z" fill={getIconColor(isActive, isDisabled)} />
            <path
                d="M6 15H3C2.73487 14.9997 2.48068 14.8943 2.2932 14.7068C2.10572 14.5193 2.00028 14.2651 2 14V2C2.00028 1.73487 2.10572 1.48068 2.2932 1.2932C2.48068 1.10572 2.73487 1.00028 3 1H11C11.2651 1.00028 11.5193 1.10572 11.7068 1.2932C11.8943 1.48068 11.9997 1.73487 12 2V7H11V2H3V14H6V15Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default InteractiveCaseEditSvgIcon;
