import React, {FC, useContext, useRef, useState} from 'react';
import {get, isEmpty} from 'lodash';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';

import {InstanceSettingBasic, InstanceSettingUploadRequestType} from 'appRedux/actions/instance/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteInstanceFile from 'pages/admin/instanceSettings/DeleteInstanceFile';

import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {getLogoUploadErrorMessage} from 'helpers/documentsUploading';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';

interface InstanceSettingUploadLogoType {
    item: InstanceSettingBasic;
    onSubmitClick: (values: InstanceSettingUploadRequestType) => void;
    isPresented: boolean;
    isFavicon?: boolean;
}

const InstanceSettingUploadLogo: FC<InstanceSettingUploadLogoType> = ({
    item,
    isFavicon,
    onSubmitClick,
    isPresented,
}) => {
    const [t] = useTranslation();

    const {id} = item;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const {showAlert} = useContext(AlertContext);

    const [errors, setErrors] = useState<IErrors>();

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    if (isPresented) {
        return <DeleteInstanceFile item={item} isFavicon={isFavicon} />;
    }
    return (
        <Formik
            initialValues={{
                id,
                file: '',
            }}
            onSubmit={(values, {resetForm}) => {
                setErrors({});
                resetForm();
                onSubmitClick({
                    ...values,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<InstanceSettingUploadRequestType>) => {
                return (
                    <Form>
                        <Box>
                            <AutoDirectionTextField
                                inputRef={fileInputRef}
                                sx={{display: 'none'}}
                                fullWidth
                                variant="standard"
                                required
                                name="file"
                                type="file"
                                onChange={value => {
                                    formik.setFieldValue('file', get(value, ['currentTarget', 'files', '0'], null));
                                    formik.submitForm();
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    title={t('common.buttons.upload')}
                                    sx={{
                                        ml: 1,
                                        width: 30,
                                        height: 30,
                                        backgroundColor: theme.palette.info.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.info.main,
                                        },
                                    }}
                                    onClick={onLabelClick}
                                >
                                    <ExportSvgIcon isActive />
                                </IconButton>
                                {!isEmpty(errors) && (
                                    <Typography
                                        sx={{
                                            color: ERROR_TEXT_COLOR,
                                            fontSize: 12,
                                            ml: 2,
                                        }}
                                    >
                                        {t(`messages.error.${getLogoUploadErrorMessage(String(errors))}`)}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InstanceSettingUploadLogo;
