import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import moment from 'moment';

import {RequesterCaseHasTimeBucketTypes} from 'appRedux/actions/requestCase/types';
import {UPDATE_REQUESTER_CASE_TIME_BUCKET} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import TimeRecordForm from 'pages/agent/requesterPage/timeTracking/TimeRecordForm';

import {convertNumToTimeString, convertTimeStringToNum} from 'helpers/parseTimeString';

import {DATE_FORMAT_FILTER} from 'config/index';

export interface BucketOptionTypes {
    id: number;
    label: string;
}

interface EditTimeRecordFormType {
    item: RequesterCaseHasTimeBucketTypes;
    closeModal: () => void;
}

const EditTimeRecordForm: FC<EditTimeRecordFormType> = ({item, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateCaseTimeBucket = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_TIME_BUCKET.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitAction = (values, {setErrors}) => {
        updateCaseTimeBucket({
            ...item,
            ...values,
            duration: convertTimeStringToNum(values.duration, t),
            callback: closeModal,
            setErrors,
            showAlert,
        });
    };

    return (
        <TimeRecordForm
            initialValues={{
                bucket: item?.bucket || 0,
                bucketDate: moment(item?.bucketDate).format(DATE_FORMAT_FILTER),
                text: item?.text,
                duration: convertNumToTimeString(item?.duration, t),
            }}
            onSubmitAction={onSubmitAction}
            closeModal={closeModal}
        />
    );
};

export default EditTimeRecordForm;
