import get from 'lodash/get';
import {fork, put, take, call, takeLatest} from 'redux-saga/effects';

import {http} from 'services/http';
import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

import {
    AGENT_LIST,
    agentList,
    CASES_ACTIVITIES_LIST,
    getCasesActivitiesList,
    REQUESTER_CASES_LIST,
    requesterCasesList,
    TOGGLE_LANGUAGE,
    toggleLanguage,
    LANGUAGE_LIST,
    languageList,
    AGENT_TEMPLATE_GET,
    getAgentTemplate,
    AGENT_TEMPLATE_UPDATE,
    updateAgentTemplate,
    REQUEST_CASE_EXPORT,
    requestCaseExport,
    REQUEST_CASE_EXPORT_MULTIPLE,
    requestCaseExportMultiple,
    REQUEST_CASE_CHANGE_STATUS_MULTIPLE,
    requestCaseChangeStatusMultiple,
    REQUEST_CASE_ASSIGN_AGENT_MULTIPLE,
    requestCaseAssignAgentMultiple,
    REQUEST_CASE_TOGGLE_PINNING_MULTIPLE,
    requestCaseTogglePinningMultiple,
    GET_PINNED_CASES,
    getPinnedCases,
    GET_WAITING_APPROVE_CASES,
    getCasesWaitingOnApprove,
    REQUEST_CASE_CHANGE_TAG_MULTIPLE,
    requestCaseChangeTagMultiple,
    REQUEST_CASE_DRAG_AND_DROP,
    requestCaseDragAndDrop,
    REQUEST_CASE_CREATE_STACK,
    requestCaseCreateStack,
    REQUEST_CASE_DELETE_STACK,
    requestCaseDeleteStack,
} from 'appRedux/actions/admin';
import {
    AgentItemBasic,
    ToggleLanguageRequestTypes,
    ListItemsRequestTypes,
    ListItemsRequestTypesResponseTypes,
    AgentTemplateRequestType,
    AgentTemplateResponseType,
    RequesterCaseExportType,
    RequesterCaseMultipleAssignAgentType,
    RequesterCaseMultipleType,
    RequesterCaseDragAndDropType,
    RequesterCaseMultipleChangeStatusType,
    RequesterCaseMultipleTogglePinningType,
    AgentCasesActivitiesRequestTypes,
    RequesterCaseMultipleChangeTagType,
    BoardColumnItemsRequestTypes,
    CustomBoardColumnItemsRequestTypes,
    CustomBoardItemsRequestTypes,
    BoardResponseType,
    BoardItemsResponseType,
    BoardAlphabetResponseType,
    BoardOverviewRequestTypes,
    SwimlaneBoardResponseType,
    CustomSwimlaneItemsRequestTypes,
    RequesterCaseStackCreateType,
    RequesterCaseStackDeleteType,
} from 'appRedux/actions/admin/types';
import {RequesterCaseActivitiesResponseTypes} from 'appRedux/actions/requestCase/types';

import {getDateForFilter} from 'helpers/filter';
import {getRouteWithParameters} from 'helpers/sagasHelper';

import {ERROR_UNAVAILABLE_WORK_WITH_FORM} from 'config/errors';

function* watchGetAgentList() {
    while (true) {
        yield take(AGENT_LIST.REQUEST);
        try {
            const data: AgentItemBasic[] = yield call(http, 'agent/list', {
                method: 'GET',
            });
            if (data) {
                yield put(agentList.success(data));
            } else {
                yield put(agentList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(agentList.error({message: String(e)}));
        }
    }
}

function* watchGetAgentCasesActivitiesList() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<AgentCasesActivitiesRequestTypes> = yield take(CASES_ACTIVITIES_LIST.REQUEST);
        try {
            const data: RequesterCaseActivitiesResponseTypes = yield call(http, `cases/activity`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getCasesActivitiesList.success(data.results));
            } else {
                yield put(getCasesActivitiesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getCasesActivitiesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetRequesterCasesCustomBoard() {
    yield takeLatest(
        REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD,
        function* ({
            payload: {uuid, agent, form, tags, options, search, lastCreated, lastUpdated},
        }: IActionType<CustomBoardItemsRequestTypes>) {
            try {
                const lastCreatedDate = getDateForFilter(lastCreated);
                const lastUpdatedDate = getDateForFilter(lastUpdated);

                const route = getRouteWithParameters(`cases/${uuid}/board`, {
                    agent: agent,
                    form: form,
                    search: search,
                    tags: tags,
                    options: options,
                    lastCreated: lastCreatedDate,
                    lastUpdatedDate: lastUpdatedDate,
                });

                const data: BoardResponseType = yield call(http, route, {
                    method: 'GET',
                });

                if (data) {
                    yield put(requesterCasesList.successCustomBoard(data));
                } else {
                    yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
                }
            } catch (e) {
                yield put(requesterCasesList.error({message: String(e)}));
            }
        },
    );
}

function* watchGetRequesterCasesSwimlaneBoard() {
    yield takeLatest(
        REQUESTER_CASES_LIST.REQUEST_SWIMLANE_BOARD,
        function* ({
            payload: {uuid, agent, form, tags, options, search, lastCreated, lastUpdated, perPage},
        }: IActionType<CustomBoardItemsRequestTypes>) {
            try {
                const lastCreatedDate = getDateForFilter(lastCreated);
                const lastUpdatedDate = getDateForFilter(lastUpdated);

                const route = getRouteWithParameters(`cases/${uuid}/swimlane`, {
                    agent: agent,
                    form: form,
                    search: search,
                    tags: tags,
                    options: options,
                    lastCreated: lastCreatedDate,
                    lastUpdatedDate: lastUpdatedDate,
                    per_page: perPage,
                });

                console.log('call ', route);

                const data: SwimlaneBoardResponseType = yield call(http, route, {
                    method: 'GET',
                });

                console.log('data for ', route, 'is ', data);

                if (data) {
                    yield put(requesterCasesList.successSwimlaneBoard(data));
                } else {
                    yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
                }
            } catch (e) {
                yield put(requesterCasesList.error({message: String(e)}));
            }
        },
    );
}

function* watchGetRequesterCasesLoadSwimlane() {
    while (true) {
        const {
            payload: {
                uuid,
                agent,
                form,
                tags,
                options,
                search,
                lastCreated,
                lastUpdated,
                perPage,
                swimlaneParameter,
                swimlaneId,
                callback,
            },
        }: IActionType<CustomSwimlaneItemsRequestTypes> = yield take(REQUESTER_CASES_LIST.REQUEST_LOAD_SWIMLANE);
        try {
            const lastCreatedDate = getDateForFilter(lastCreated);
            const lastUpdatedDate = getDateForFilter(lastUpdated);

            const route = getRouteWithParameters(`cases/${uuid}/swimlane`, {
                agent: agent || swimlaneParameter === 'agent' ? swimlaneId : undefined,
                form: form || swimlaneParameter === 'form' ? swimlaneId : undefined,
                search: search,
                tags: tags,
                options: options,
                lastCreated: lastCreatedDate,
                lastUpdatedDate: lastUpdatedDate,
                per_page: perPage,
            });

            const data: SwimlaneBoardResponseType = yield call(http, route, {
                method: 'GET',
            });

            callback && callback();

            if (data) {
                yield put(requesterCasesList.successLoadSwimlane(data));
            } else {
                yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterCasesList.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCasesCustomBoardAlphabet() {
    while (true) {
        const {
            payload: {uuid, perPage, agent, form, tags, search, options, lastCreated, lastUpdated, firstLetter},
        }: IActionType<CustomBoardItemsRequestTypes> = yield take(REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET);
        try {
            const route = getRouteWithParameters(`cases/${uuid}/alphabet`, {
                page: 1,
                per_page: perPage,
                agent: agent,
                form: form,
                tags: tags,
                options: options,
                search: search,
                firstLetter: firstLetter,
                lastCreated: getDateForFilter(lastCreated),
                lastUpdated: getDateForFilter(lastUpdated),
            });

            const data: BoardAlphabetResponseType = yield call(http, route, {
                method: 'GET',
            });

            if (data) {
                yield put(requesterCasesList.successCustomBoardAlphabet(data));
            } else {
                yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterCasesList.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCasesCustomBoardAlphabetUpdate() {
    while (true) {
        const {
            payload: {uuid, perPage, agent, form, tags, options, search, lastCreated, lastUpdated, firstLetter, last},
        }: IActionType<CustomBoardItemsRequestTypes> = yield take(
            REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET_UPDATE,
        );
        try {
            const route = getRouteWithParameters(`cases/${uuid}/alphabet/update/${last}`, {
                per_page: perPage,
                agent: agent,
                form: form,
                tags: tags,
                options: options,
                search: search,
                firstLetter: firstLetter,
                lastCreated: getDateForFilter(lastCreated),
                lastUpdated: getDateForFilter(lastUpdated),
            });

            const data: BoardAlphabetResponseType = yield call(http, route, {
                method: 'GET',
            });
            if (data) {
                yield put(requesterCasesList.successCustomBoardAlphabetUpdate(data));
            } else {
                yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterCasesList.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCasesBoardColumn() {
    while (true) {
        const {
            payload: {status, last, agent, form, tags, search, lastCreated, lastUpdated},
        }: IActionType<BoardColumnItemsRequestTypes> = yield take(REQUESTER_CASES_LIST.REQUEST_BOARD_COLUMN);
        try {
            const route = getRouteWithParameters(`cases/${status}/board/${last}`, {
                agent: agent,
                form: form,
                tags: tags,
                search: search,
                lastCreated: getDateForFilter(lastCreated),
                lastUpdated: getDateForFilter(lastUpdated),
            });

            const data: BoardItemsResponseType = yield call(http, route, {
                method: 'GET',
            });

            if (data) {
                yield put(requesterCasesList.successBoardColumn({...data, status}));
            } else {
                yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterCasesList.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCasesBoardOverview() {
    yield takeLatest(
        REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_OVERVIEW,
        function* ({
            payload: {
                uuid,
                page,
                perPage,
                sortBy,
                sortType,
                agent,
                form,
                tags,
                options,
                search,
                lastCreated,
                lastUpdated,
            },
        }: IActionType<BoardOverviewRequestTypes>) {
            try {
                const route = getRouteWithParameters(`cases/${uuid}/overview`, {
                    page: page,
                    per_page: perPage,
                    agent: agent,
                    form: form,
                    tags: tags,
                    options: options,
                    search: search,
                    lastCreated: getDateForFilter(lastCreated),
                    lastUpdated: getDateForFilter(lastUpdated),
                    sortBy: sortBy,
                    sortType: sortType,
                });

                const data: BoardItemsResponseType = yield call(http, route, {
                    method: 'GET',
                });

                if (data) {
                    yield put(requesterCasesList.successCustomBoardOverview(data));
                } else {
                    yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
                }
            } catch (e) {
                yield put(requesterCasesList.error({message: String(e)}));
            }
        },
    );
}

function* watchGetRequesterCasesTable() {
    yield takeLatest(
        REQUESTER_CASES_LIST.REQUEST_TABLE,
        function* ({
            payload: {
                uuid,
                page,
                perPage,
                sortBy,
                sortType,
                agent,
                form,
                tags,
                options,
                search,
                lastCreated,
                lastUpdated,
            },
        }: IActionType<BoardOverviewRequestTypes>) {
            try {
                const route = getRouteWithParameters(`cases/${uuid}/table`, {
                    page: page,
                    per_page: perPage,
                    agent: agent,
                    form: form,
                    tags: tags,
                    options: options,
                    search: search,
                    lastCreated: getDateForFilter(lastCreated),
                    lastUpdated: getDateForFilter(lastUpdated),
                    sortBy: sortBy,
                    sortType: sortType,
                });

                const data: BoardItemsResponseType = yield call(http, route, {
                    method: 'GET',
                });

                if (data) {
                    yield put(requesterCasesList.successTable(data));
                } else {
                    yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
                }
            } catch (e) {
                yield put(requesterCasesList.error({message: String(e)}));
            }
        },
    );
}

function* watchGetRequesterCasesCustomBoardColumn() {
    while (true) {
        const {
            payload: {uuid, last, agent, form, tags, options, search, lastCreated, lastUpdated},
        }: IActionType<CustomBoardColumnItemsRequestTypes> = yield take(
            REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_COLUMN,
        );
        try {
            const route = getRouteWithParameters(`cases/${uuid}/custom/board/${last}`, {
                agent: agent,
                form: form,
                tags: tags,
                options: options,
                search: search,
                lastCreated: getDateForFilter(lastCreated),
                lastUpdated: getDateForFilter(lastUpdated),
            });

            const data: BoardItemsResponseType = yield call(http, route, {
                method: 'GET',
            });

            if (data) {
                yield put(requesterCasesList.successCustomBoardColumn({...data, status: uuid}));
            } else {
                yield put(requesterCasesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterCasesList.error({message: String(e)}));
        }
    }
}

function* watchGetPinnedRequesterCasesList() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<ListItemsRequestTypes> = yield take(GET_PINNED_CASES.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/pinned`, {
                method: 'GET',
            });
            if (data) {
                yield put(getPinnedCases.success(data));
            } else {
                yield put(getPinnedCases.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getPinnedCases.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetWaitingApproveRequesterCasesList() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<ListItemsRequestTypes> = yield take(GET_WAITING_APPROVE_CASES.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/waiting/approve`, {
                method: 'GET',
            });
            if (data) {
                yield put(getCasesWaitingOnApprove.success(data));
            } else {
                yield put(getCasesWaitingOnApprove.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getCasesWaitingOnApprove.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetLanguageList() {
    while (true) {
        yield take(LANGUAGE_LIST.REQUEST);
        try {
            const data: [] = yield call(http, 'language/list', {
                method: 'GET',
            });

            if (data) {
                yield put(languageList.success(data));
            } else {
                yield put(languageList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(languageList.error({message: String(e)}));
        }
    }
}

function* watchToggleLanguage() {
    while (true) {
        const {
            payload: {id, enable},
        }: IActionType<ToggleLanguageRequestTypes> = yield take(TOGGLE_LANGUAGE.REQUEST);
        try {
            yield call(http, `language/${id}/toggle/enable`, {
                method: 'POST',
                body: JSON.stringify({enable}),
            });

            yield put(toggleLanguage.success());
        } catch (e) {
            yield put(toggleLanguage.error({message: String(e)}));
        }
    }
}

function* watchGetAgentTemplate() {
    while (true) {
        yield take(AGENT_TEMPLATE_GET.REQUEST);
        try {
            const data: AgentTemplateResponseType = yield call(http, `agent/template`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getAgentTemplate.success(data.template));
            } else {
                yield put(getAgentTemplate.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getAgentTemplate.error({message: String(e)}));
        }
    }
}

function* watchUpdateAgentTemplate() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<AgentTemplateRequestType> = yield take(AGENT_TEMPLATE_UPDATE.REQUEST);
        try {
            const data: AgentTemplateResponseType = yield call(http, `agent/template`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateAgentTemplate.success(data.template));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateAgentTemplate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateAgentTemplate.error({message: String(e)}));
        }
    }
}

function* watchRequestCaseExporting() {
    while (true) {
        const {
            payload: {showAlert, callback, callbackDownload, id},
        }: IActionType<RequesterCaseExportType> = yield take(REQUEST_CASE_EXPORT.REQUEST);
        try {
            const data: string | Blob = yield call(http, `case/${id}/export`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(requestCaseExport.success());
                callback && callback();
                callbackDownload && callbackDownload(data);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseExport.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseExport.error({message: String(e)}));
        }
    }
}

function* multipleRequestCasesExporting() {
    while (true) {
        const {
            payload: {showAlert, callback, callbackDownload, ids},
        }: IActionType<RequesterCaseMultipleType> = yield take(REQUEST_CASE_EXPORT_MULTIPLE.REQUEST);
        try {
            const data: string | Blob = yield call(http, `cases/export?id=${ids.join(',')}`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(requestCaseExportMultiple.success());
                callback && callback();
                callbackDownload && callbackDownload(data);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseExportMultiple.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseExportMultiple.error({message: String(e)}));
        }
    }
}

function* requestCaseDragAndDropStatusChanging() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<RequesterCaseDragAndDropType> = yield take(REQUEST_CASE_DRAG_AND_DROP.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/${id}/drag-and-drop`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (!data.errors) {
                yield put(requestCaseDragAndDrop.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(requestCaseDragAndDrop.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else {
                yield put(requestCaseDragAndDrop.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseDragAndDrop.error({message: String(e)}));
        }
    }
}

function* multipleRequestCasesStatusChanging() {
    while (true) {
        const {
            payload: {showAlert, callback, ids, uuid, ...values},
        }: IActionType<RequesterCaseMultipleChangeStatusType> = yield take(REQUEST_CASE_CHANGE_STATUS_MULTIPLE.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/${uuid}/status`, {
                method: 'POST',
                body: JSON.stringify({
                    id: ids.join(','),
                    ...values,
                }),
            });
            if (!data.errors) {
                if (values.isPinnedList) {
                    yield put(requestCaseChangeStatusMultiple.successPinnedList(data));
                } else if (values.isTableView === 1) {
                    yield put(requestCaseChangeStatusMultiple.successTable(data));
                } else if (values.isAlphabetList === 1) {
                    yield put(requestCaseChangeStatusMultiple.successAlphabetList(data));
                } else if (values.isOverviewBoard === 1) {
                    yield put(requestCaseChangeStatusMultiple.successOverviewBoard(data));
                } else if (values.isSwimlaneBoard === 1) {
                    yield put(requestCaseChangeStatusMultiple.successSwimlaneBoard(data));
                } else {
                    yield put(requestCaseChangeStatusMultiple.successBoardView(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                const errorMessage = get(data, 'errors', 'messages.error.somethingWentWrong');
                yield put(
                    requestCaseChangeStatusMultiple.error({
                        message: errorMessage,
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, errorMessage);
            } else {
                yield put(requestCaseChangeStatusMultiple.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseChangeStatusMultiple.error({message: String(e)}));
        }
    }
}

function* multipleRequestCasesAgentAssigning() {
    while (true) {
        const {
            payload: {showAlert, callback, ids, ...values},
        }: IActionType<RequesterCaseMultipleAssignAgentType> = yield take(REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/agents`, {
                method: 'POST',
                body: JSON.stringify({
                    ...values,
                    id: ids.join(','),
                }),
            });
            if (data.errors) {
                yield put(requestCaseAssignAgentMultiple.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else if (data) {
                if (values.isPinnedList) {
                    yield put(requestCaseAssignAgentMultiple.successPinnedList(data));
                } else if (values.isTableView === 1) {
                    yield put(requestCaseAssignAgentMultiple.successTable(data));
                } else if (values.isAlphabetList === 1) {
                    yield put(requestCaseAssignAgentMultiple.successAlphabetList(data));
                } else if (values.isOverviewBoard === 1) {
                    yield put(requestCaseAssignAgentMultiple.successOverviewBoard(data));
                } else if (values.isSwimlaneBoard === 1) {
                    yield put(requestCaseAssignAgentMultiple.successSwimlaneBoard(data));
                } else {
                    yield put(requestCaseAssignAgentMultiple.successBoardView(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseAssignAgentMultiple.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseAssignAgentMultiple.error({message: String(e)}));
        }
    }
}

function* multipleRequestCasesTogglePinning() {
    while (true) {
        const {
            payload: {showAlert, callback, ids, ...values},
        }: IActionType<RequesterCaseMultipleTogglePinningType> = yield take(
            REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.REQUEST,
        );
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/pinning`, {
                method: 'POST',
                body: JSON.stringify({
                    ...values,
                    id: ids.join(','),
                }),
            });
            if (data) {
                if (values.isPinnedList === 1) {
                    yield put(requestCaseTogglePinningMultiple.successPinnedList(data));
                } else if (values.isTableView === 1) {
                    yield put(requestCaseTogglePinningMultiple.successTable(data));
                } else if (values.isAlphabetList === 1) {
                    yield put(requestCaseTogglePinningMultiple.successAlphabetList(data));
                } else if (values.isOverviewBoard === 1) {
                    yield put(requestCaseTogglePinningMultiple.successOverviewBoard(data));
                } else if (values.isSwimlaneBoard === 1) {
                    yield put(requestCaseTogglePinningMultiple.successSwimlaneBoard(data));
                } else {
                    yield put(requestCaseTogglePinningMultiple.successBoardView(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseTogglePinningMultiple.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseTogglePinningMultiple.error({message: String(e)}));
        }
    }
}

function* multipleRequestCasesTagsChange() {
    while (true) {
        const {
            payload: {showAlert, callback, ids, ...values},
        }: IActionType<RequesterCaseMultipleChangeTagType> = yield take(REQUEST_CASE_CHANGE_TAG_MULTIPLE.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `cases/tags`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.errors) {
                yield put(requestCaseChangeTagMultiple.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else if (data) {
                if (values.isPinnedList === 1) {
                    yield put(requestCaseChangeTagMultiple.successPinnedList(data));
                } else if (values.isTableView === 1) {
                    yield put(requestCaseChangeTagMultiple.successTable(data));
                } else if (values.isAlphabetList === 1) {
                    yield put(requestCaseChangeTagMultiple.successAlphabetList(data));
                } else if (values.isOverviewBoard === 1) {
                    yield put(requestCaseChangeTagMultiple.successOverviewBoard(data));
                } else if (values.isSwimlaneBoard === 1) {
                    yield put(requestCaseChangeTagMultiple.successSwimlaneBoard(data));
                } else {
                    yield put(requestCaseChangeTagMultiple.successBoardView(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseChangeTagMultiple.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseChangeTagMultiple.error({message: String(e)}));
        }
    }
}

function* watchRequestCaseStackCreating() {
    while (true) {
        const {
            payload: {showAlert, callback, board, isBoardCasesPage, isSwimlaneCasesPage, ...values},
        }: IActionType<RequesterCaseStackCreateType> = yield take(REQUEST_CASE_CREATE_STACK.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `stack/${board}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.errors) {
                yield put(requestCaseCreateStack.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else if (data) {
                console.log('watchRequestCaseStackCreating', data, isBoardCasesPage, isSwimlaneCasesPage);

                if (isBoardCasesPage) {
                    yield put(requestCaseCreateStack.successCustomBoard(data));
                } else if (isSwimlaneCasesPage) {
                    yield put(requestCaseCreateStack.successSwimlaneBoard(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseCreateStack.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseCreateStack.error({message: String(e)}));
        }
    }
}

function* watchRequestCaseStackDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, isBoardCasesPage, isSwimlaneCasesPage, ...values},
        }: IActionType<RequesterCaseStackDeleteType> = yield take(REQUEST_CASE_DELETE_STACK.REQUEST);
        try {
            const data: ListItemsRequestTypesResponseTypes = yield call(http, `stack/${uuid}/delete`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.errors) {
                yield put(requestCaseDeleteStack.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else if (data) {
                console.log('watchRequestCaseStackDeleting', data, isBoardCasesPage, isSwimlaneCasesPage);
                if (isBoardCasesPage) {
                    yield put(requestCaseDeleteStack.successCustomBoard(data));
                } else if (isSwimlaneCasesPage) {
                    yield put(requestCaseDeleteStack.successSwimlaneBoard(data));
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseDeleteStack.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseDeleteStack.error({message: String(e)}));
        }
    }
}

const adminSagas = [
    fork(watchGetAgentList),
    fork(watchGetAgentCasesActivitiesList),
    fork(watchGetRequesterCasesCustomBoard),
    fork(watchGetRequesterCasesSwimlaneBoard),
    fork(watchGetRequesterCasesLoadSwimlane),
    fork(watchGetRequesterCasesCustomBoardAlphabet),
    fork(watchGetRequesterCasesCustomBoardAlphabetUpdate),
    fork(watchGetRequesterCasesBoardColumn),
    fork(watchGetRequesterCasesBoardOverview),
    fork(watchGetRequesterCasesTable),
    fork(watchGetRequesterCasesCustomBoardColumn),
    fork(watchGetPinnedRequesterCasesList),
    fork(watchGetWaitingApproveRequesterCasesList),
    fork(watchGetLanguageList),
    fork(watchToggleLanguage),
    fork(watchGetAgentTemplate),
    fork(watchUpdateAgentTemplate),
    fork(watchRequestCaseExporting),
    fork(multipleRequestCasesExporting),
    fork(multipleRequestCasesStatusChanging),
    fork(requestCaseDragAndDropStatusChanging),
    fork(multipleRequestCasesAgentAssigning),
    fork(multipleRequestCasesTogglePinning),
    fork(multipleRequestCasesTagsChange),
    fork(watchRequestCaseStackCreating),
    fork(watchRequestCaseStackDeleting),
];

export default adminSagas;
