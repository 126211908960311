import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';

import {CommentFormType, RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';
import {UPDATE_REQUESTER_CASE_COMMENT} from 'appRedux/actions/comments';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CommentForm from 'components/Forms/CommentForm/CommentForm';

import {decryptStringWithKey, encryptStringWithKey} from 'helpers/cryptoApiHelper';

interface UpdateCommentType {
    item: RequesterCaseCommentsTypes;
}

const UpdateComment: FC<UpdateCommentType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {text, section, isEncryptInfo, uuid, isPublic} = item;

    const [initialValue, setInitialValue] = useState<string>(text);

    const {showAlert} = useContext(AlertContext);
    const {keys} = useContext(CryptoContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const updateComment = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_COMMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const onUpdateClick = async (values: CommentFormType) => {
        updateComment({
            ...values,
            text:
                isEncryptInfo && unwrappedCaseKey
                    ? await encryptStringWithKey(values.text, unwrappedCaseKey)
                    : values.text,
            uuid,
            callback: () => setIsOpened(false),
            showAlert,
        });
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (text && keys && unwrappedCaseKey) {
                const decodedMessage = await decryptStringWithKey(text, unwrappedCaseKey);
                setInitialValue(decodedMessage);
            } else {
                setInitialValue(text);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [text]);

    return (
        <>
            <Typography
                onClick={toggleIsOpened}
                sx={{
                    cursor: 'pointer',
                    fontWeight: 600,
                    fontSize: 12,
                    mr: 2,
                }}
            >
                {t('common.buttons.update')}
            </Typography>
            <ModalWrapper
                title={t('orguser.requesterCase.comments.updateComment')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                <CommentForm
                    initialValues={{
                        text: initialValue,
                        isPublic,
                        section: section ?? null,
                        forStack: false,
                    }}
                    closeForm={toggleIsOpened}
                    handleSubmit={onUpdateClick}
                    isModal
                />
            </ModalWrapper>
        </>
    );
};

export default UpdateComment;
