import React, {ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import get from 'lodash/get';

import {Box, Typography} from '@mui/material';

import {CREATE_PUBLIC_KEY, GET_USER_PUBLIC_KEYS, GET_USER_SAVED_KEYS, SAVE_USER_KEYS} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';
import {LOAD_KEYS} from 'appRedux/actions/auth';
import {ExportCryptoKeysTypes} from 'appRedux/actions/auth/types';

import {CryptoContext, CryptoContextType, CryptoModalType, KeysType} from 'contexts/crypto/context';
import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import KeysNameForm from 'components/Forms/SettingsForms/AddCryptoKeysForm/KeysNameForm';
import AddCryptoKeysForm from 'components/Forms/SettingsForms/AddCryptoKeysForm/AddCryptoKeysForm';
import CryptoPasswordForm from 'components/PublicKeysList/CryptoPasswordForm';

import {
    decryptNumberWithKey,
    decryptStringWithKey,
    encryptNumberWithKey,
    encryptStringWithKey,
    exportPublicKey,
    generateCryptoKey,
    generateWrapperKeyPair,
    importPublicKey,
    unwrapKey,
    wrapKey,
    getWrappedKeyName,
    exportPrivateKey,
    getPasswordKeyAndIv,
    encryptStringWithPasswordKey,
} from 'helpers/cryptoApiHelper';
import {
    deleteKeysFromIndexedDB,
    openIndexedDB,
    readKeysFromIndexedDB,
    writeKeysToIndexedDB,
} from 'helpers/indexedDBHelper';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

const iDB: IDBFactory = window.indexedDB;
export let globalKeys: KeysType | null = null;
export let indexedDb: IDBDatabase | null = null;

interface ContextType {
    children: ReactNode;
}

let isTest = false; // set to true to run the crypto helpers test

const CryptoContextWrapper: React.FC<ContextType> = ({children}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modalOpened, setModalOpened] = useState<CryptoModalType | false>(false);

    const [keys, setKeys] = useState<KeysType | null>(null);
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const {showAlert} = useContext(AlertContext);

    const {
        auth: {authData, organization},
        profile: {profile},
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const userId = get(profile, 'id', null);
    const orgId = get(organization, 'id', null);
    const isEncryptionPossible = get(organization, 'isEncryptionPossible', false);
    const currentUserSavedKeys = currentUserPublicKeys?.filter(item => item.isKeyStored);

    const savePublicKeyToDatabase = useCallback(
        data => dispatch({type: CREATE_PUBLIC_KEY.REQUEST, payload: data}),
        [dispatch],
    );

    const getUserPublicKeys = useCallback(
        data => dispatch({type: GET_USER_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const saveUserKeys = useCallback(data => dispatch({type: SAVE_USER_KEYS.REQUEST, payload: data}), [dispatch]);

    const loadKeyStart = useCallback(() => dispatch({type: LOAD_KEYS.REQUEST}), [dispatch]);
    const loadKeysEnd = useCallback(() => dispatch({type: LOAD_KEYS.SUCCESS}), [dispatch]);

    useLayoutEffect(() => {
        if (modalOpened) {
            loadKeyStart();
        } else {
            loadKeysEnd();
        }
    }, [modalOpened, loadKeysEnd, loadKeyStart]);

    useLayoutEffect(() => {
        if (userId && isEncryptionPossible) {
            loadKeyStart();
            const timer = setTimeout(async () => {
                getUserPublicKeys({
                    showAlert,
                });
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [userId, isEncryptionPossible]);

    useEffect(() => {
        // crypto helpers test:
        (async () => {
            if (!isTest) return;
            isTest = false;
            const testString = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer scelerisque eros arcu, vel ornare nulla placerat in. Duis in metus maximus, ornare metus sit amet, auctor urna. Nullam risus metus, commodo ultricies lectus bibendum, accumsan facilisis est. Suspendisse non nisi mollis, accumsan lorem eu, blandit ante. Curabitur risus arcu, egestas vel convallis vitae, lobortis non est. Quisque nec neque eu ante sollicitudin accumsan non vitae diam. Donec non turpis eu lorem varius consectetur sit amet id libero. Aliquam vulputate purus quis maximus tempor. In sit amet pulvinar mauris. Ut tincidunt nibh id sem malesuada, non venenatis est porta. Cras maximus vehicula lectus, eget tristique mauris aliquet eget. Integer tincidunt blandit magna in mattis. Sed eu odio nec tellus ultrices accumsan. Vivamus hendrerit dolor vel faucibus lacinia. Aenean vitae orci interdum, consectetur nibh et, molestie erat.`;

            try {
                // generate private/public key pair:
                const clientKeys = await generateWrapperKeyPair();
                console.log('clientKeys', clientKeys);

                // generate case key:
                const caseKey = await generateCryptoKey();
                console.log('caseKey', caseKey);

                // export/import public key (use this for saving Public key on BE)
                const exportedPublicKey = await exportPublicKey(clientKeys.publicKey);
                console.log('exportedPublicKey', exportedPublicKey);
                const importedPublicKey = await importPublicKey(exportedPublicKey);
                console.log('importedPublicKey', importedPublicKey);

                // wrap/unwrap the case key (use this for saving Case key on BE)
                const wrappedCaseKey = await wrapKey(caseKey, importedPublicKey);
                console.log('wrappedCaseKey', wrappedCaseKey);
                const unwrappedCaseKey = await unwrapKey(wrappedCaseKey, clientKeys.privateKey);
                console.log('unwrappedCaseKey', unwrappedCaseKey);

                // encrypt string and number with case key:
                const encryptedData = await encryptStringWithKey(testString, caseKey);
                console.log('encryptedData', encryptedData);
                const encryptedNumData = await encryptNumberWithKey(1234345234235, caseKey);
                console.log('encryptedNumData', encryptedNumData);

                // decrypt string and number with case key:
                const decryptedData = await decryptStringWithKey(encryptedData, caseKey);
                console.log('decryptedData', decryptedData);
                const decryptedNumData = await decryptNumberWithKey(encryptedNumData, caseKey);
                console.log('decryptedNumData', decryptedNumData);

                // decrypt string and number with unwrapped key:
                const decryptedUnwrappedData = await decryptStringWithKey(encryptedData, unwrappedCaseKey);
                console.log('unwrapped key decryptedData', decryptedUnwrappedData);
                const decryptedUnwrappedNumData = await decryptNumberWithKey(encryptedNumData, unwrappedCaseKey);
                console.log('unwrapped key decryptedNumData', decryptedUnwrappedNumData);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        globalKeys = keys;
    }, [keys]);

    useEffect(() => {
        if (
            !userId ||
            !orgId ||
            keys ||
            !currentUserPublicKeys ||
            !iDB ||
            indexedDb ||
            !authData?.refreshToken ||
            !isEncryptionPossible
        )
            return;

        const currentPublicKeys = currentUserPublicKeys.filter(k => k.userId === userId);
        if (currentPublicKeys.length !== currentUserPublicKeys.length) return;

        if (!window.crypto || !window.crypto.subtle) {
            showAlert(ALERT_TYPE_ERROR, t('messages.error.cryptoBrowserIssue'));
            return;
        }

        const wrapperKeyName = getWrappedKeyName(userId, orgId);

        openIndexedDB(iDB)
            .then(db => {
                indexedDb = db;
                return readWrapperKeys(userId, orgId);
            })
            .then(async keys => {
                if (!keys) {
                    if (currentUserSavedKeys?.length) {
                        setModalOpened(CryptoModalType.DOWNLOAD);
                    } else if (currentPublicKeys.length) {
                        setModalOpened(CryptoModalType.IMPORT);
                    } else {
                        setModalOpened(CryptoModalType.NEW);
                    }
                }

                const publishedKey = currentPublicKeys.find(k => k.uuid === keys?.uuid);

                if (keys && !publishedKey) {
                    return deleteWrapperKeys(wrapperKeyName);
                } else {
                    keys && !modalOpened && loadKeysEnd();
                }
            })
            .catch(err => {
                console.error(err);
                if (currentUserSavedKeys?.length) {
                    setModalOpened(CryptoModalType.DOWNLOAD);
                } else if (currentPublicKeys.length) {
                    setModalOpened(CryptoModalType.IMPORT);
                } else {
                    setModalOpened(CryptoModalType.NEW);
                }
            });
    }, [userId, orgId, currentUserPublicKeys, keys, iDB, authData?.refreshToken, isEncryptionPossible]);

    const readWrapperKeys = (userId: number | string, orgId: string) => {
        if (!window.crypto || !window.crypto.subtle) {
            showAlert(ALERT_TYPE_ERROR, t('messages.error.cryptoBrowserIssue'));
            return;
        }

        if (!window.indexedDB) {
            showAlert(ALERT_TYPE_ERROR, t('messages.error.indexedDBOpenError'));
            return;
        }

        if (!indexedDb) {
            return;
        }

        const wrapperKeyName = getWrappedKeyName(userId, orgId);

        // Read the Keys from DB
        return readKeysFromIndexedDB(indexedDb, wrapperKeyName)
            .then(async keys => {
                console.log('readKeysFromIndexedDB', keys);
                if (!keys && indexedDb) {
                    // try to find general keys record
                    const wrapperKeyName = getWrappedKeyName(userId);
                    keys = await readKeysFromIndexedDB(indexedDb, wrapperKeyName);
                }
                setKeys(keys);
                return keys;
            })
            .catch(err => {
                setKeys(null);
                console.error(err);
            });
    };

    const deleteWrapperKeys = (wrapperKeyName: string) => {
        if (!window.crypto || !window.crypto.subtle) {
            showAlert(ALERT_TYPE_ERROR, t('messages.error.cryptoBrowserIssue'));
            return;
        }

        if (!window.indexedDB) {
            showAlert(ALERT_TYPE_ERROR, t('messages.error.indexedDBOpenError'));
            return;
        }

        if (!indexedDb) {
            return;
        }

        // Read the Keys from DB
        return deleteKeysFromIndexedDB(indexedDb, wrapperKeyName)
            .then(() => {
                setKeys(null);
                if (currentUserSavedKeys?.length) {
                    setModalOpened(CryptoModalType.DOWNLOAD);
                } else if (currentUserPublicKeys?.length) {
                    setModalOpened(CryptoModalType.IMPORT);
                } else {
                    setModalOpened(CryptoModalType.NEW);
                }
                return;
            })
            .catch(err => {
                console.error(err);
            });
    };

    useEffect(() => {
        if (redirectTo) {
            if ((isEncryptionPossible && keys) || !isEncryptionPossible) {
                navigate(redirectTo, {replace: true});
                setRedirectTo(null);
            }
        }
    }, [redirectTo, isEncryptionPossible, keys]);

    const regenerateKeysAfterLogin = async (redirectTo?: string) => {
        if (redirectTo) {
            setRedirectTo(redirectTo);
        }
    };

    const generateWrapperKeys = async (wrapperKeyName: string, keyTitle?: string) => {
        try {
            const cryptoKey = await generateWrapperKeyPair();

            if (!indexedDb) {
                // No operation can be performed.
                showAlert(ALERT_TYPE_ERROR, 'IndexedDBError');
                throw new Error('IndexedDB KeyStore is not open. Reload the page.');
            }
            const {publicKey, privateKey} = cryptoKey;

            const exportedPublicKey = await exportPublicKey(publicKey);
            savePublicKeyToDatabase({
                data: exportedPublicKey,
                title: keyTitle,
                callback: async (uuid: string) => {
                    const keyPairRecord = {
                        publicKey: publicKey,
                        privateKey: privateKey,
                        name: wrapperKeyName,
                        uuid,
                    };

                    if (!indexedDB) {
                        // No operation can be performed.
                        showAlert(ALERT_TYPE_ERROR, 'IndexedDBError');
                        throw new Error('IndexedDB KeyStore is not open. Reload the page.');
                    }

                    const keys = indexedDb && (await writeKeysToIndexedDB(indexedDb, keyPairRecord));
                    setKeys(keys);

                    if (redirectTo) {
                        navigate(redirectTo, {replace: true});
                    }
                },
            });

            setModalOpened(false);
        } catch (err) {
            setKeys(null);
            console.error(err);
        }
    };

    const context: CryptoContextType = {
        keys,
        readWrapperKeys,
        generateWrapperKeys,
        regenerateKeysAfterLogin,
    };

    const handleImport = async (keyPairRecord: KeysType) => {
        try {
            if (!indexedDb) {
                // No operation can be performed.
                showAlert(ALERT_TYPE_ERROR, t('messages.error.indexedDBError'));
                return;
            }

            const keys = await writeKeysToIndexedDB(indexedDb, keyPairRecord);
            setKeys(keys);
            setModalOpened(false);
            showAlert(ALERT_TYPE_SUCCESS, t('messages.success.importKeysSuccess'));
        } catch (err: any) {
            console.error(err);
            showAlert(ALERT_TYPE_ERROR, t('messages.error.importKeysError'));
        }
    };

    const handleGenerate = async ({keyName}: {keyName: string}) => {
        if (!orgId) return;

        const wrapperKeyName = getWrappedKeyName(userId, orgId);

        await generateWrapperKeys(wrapperKeyName, keyName);
        setModalOpened(CryptoModalType.SAVE);
        showAlert(ALERT_TYPE_SUCCESS, t('messages.success.generateKeysSuccess'));
    };

    const handleSave = async (values: ExportCryptoKeysTypes) => {
        if (!keys) return;

        try {
            const publicKey = await exportPublicKey(keys.publicKey);
            const privateKey = await exportPrivateKey(keys.privateKey);

            const keysToExport = {
                name: keys.name,
                publicKey,
                privateKey,
                uuid: keys.uuid,
            };
            const keysString = JSON.stringify(keysToExport);

            const {key, iv} = await getPasswordKeyAndIv(values.keyPassword);
            const ecnrypted = await encryptStringWithPasswordKey(keysString, key, iv);

            saveUserKeys({
                encryptedKey: ecnrypted,
                uuid: keys.uuid,
                showAlert,
                callback: () => {
                    setModalOpened(false);
                    getUserPublicKeys({showAlert});
                },
            });
        } catch (e) {
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    };

    return (
        <CryptoContext.Provider value={context}>
            {children}
            {modalOpened && [CryptoModalType.IMPORT, CryptoModalType.DOWNLOAD].includes(modalOpened) && (
                <ModalWrapper title={t('common.crypto.addKeysTitle')} isShowModal={!!modalOpened}>
                    <AddCryptoKeysForm
                        handleImport={handleImport}
                        handleGenerate={handleGenerate}
                        modalType={modalOpened}
                    />
                </ModalWrapper>
            )}
            {modalOpened === CryptoModalType.NEW && (
                <ModalWrapper title={t('common.crypto.newKeysTitle')} isShowModal={!!modalOpened}>
                    <Box>
                        <Typography align="center" variant="body2" sx={{mt: 0, mb: 4}}>
                            {t('common.registrationForm.newKeysDescription')}
                        </Typography>
                        <KeysNameForm handleSubmit={handleGenerate} />
                    </Box>
                </ModalWrapper>
            )}
            {modalOpened === CryptoModalType.SAVE && (
                <ModalWrapper title={t('common.publicKeys.saveCryptoKeysLabel')} isShowModal={!!modalOpened}>
                    <Box>
                        <Typography align="center" variant="body2" sx={{mt: 2}}>
                            {t('common.publicKeys.saveCryptoKeysDescription')}
                        </Typography>
                        <CryptoPasswordForm handleSubmit={handleSave} onClose={() => setModalOpened(false)} />
                    </Box>
                </ModalWrapper>
            )}
        </CryptoContext.Provider>
    );
};

export default CryptoContextWrapper;
