import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowEventAgentTypes} from 'appRedux/actions/workflow/types';
import {REMOVE_AGENT_FROM_BOARD} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import AgentChipButton from 'components/AgentScreenComponents/_buttons/AgentChipButton';

interface AttachedAgentListItemType {
    item: WorkflowEventAgentTypes;
}

const AttachedAgentsListItem: FC<AttachedAgentListItemType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    const deleteAgent = useCallback(
        data => dispatch({type: REMOVE_AGENT_FROM_BOARD.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, avatarType, avatarLink, firstName, lastName, email} = item;

    const onRemoveClick = () => {
        deleteAgent({
            uuid,
            showAlert,
            callback: () => setIsOpen(false),
        });
    };

    return (
        <AgentChipButton
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggleModal={toggleModal}
            avatarType={avatarType}
            avatarLink={avatarLink}
            firstName={firstName}
            lastName={lastName}
            email={email}
            onRemoveClick={onRemoveClick}
        />
    );
};

export default AttachedAgentsListItem;
