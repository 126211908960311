import React, {ChangeEvent, FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import HerupuSpinner from 'assets/herupuSpinner';

import {CustomAttributesItemTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';
import {
    CREATE_CUSTOM_ATTRIBUTE_OPTION,
    UPDATE_CUSTOM_ATTRIBUTE_OPTION,
    DELETE_CUSTOM_ATTRIBUTE_OPTION,
} from 'appRedux/actions/customAttributes';

import {AlertContext} from 'contexts/alert/context';

import EmptyArea from 'components/EmptyArea/EmptyArea';
import {OPTION_ERROR_REPEAT} from 'components/Forms/CustomAttributes/CustomAttributesOptions/helper';
import DeleteOption from 'components/Forms/CustomAttributes/CustomAttributesOptions/DeleteOption';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {theme} from 'config/theme';

interface CustomAttributesOptionsListType {
    customAttribute: CustomAttributesItemTypes;
    optionErrorType: number | null;
    setOptionErrorType: (value: number | null) => void;
}

const CustomAttributesOptionsList: FC<CustomAttributesOptionsListType> = ({
    customAttribute,
    optionErrorType,
    setOptionErrorType,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showAddInput, setShowAddInput] = useState<boolean>(false);
    const [editedIndex, setEditedIndex] = useState<number | null>(null);
    const [updatedOptionTitle, setUpdatedOptionTitle] = useState<string>('');
    const [loadingOptions, setLoadingOptions] = useState<number[]>([]);
    const [optionEditError, setOptionEditError] = useState<number | null>(null);

    const {uuid, options} = customAttribute;

    const createOption = useCallback(
        data => dispatch({type: CREATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const updateOption = useCallback(
        data => dispatch({type: UPDATE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const deleteOption = useCallback(
        data => dispatch({type: DELETE_CUSTOM_ATTRIBUTE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const findDuplicates = (title: string): boolean => {
        let filteredOptions = options;
        if (editedIndex !== null) {
            filteredOptions = options.filter((_, i) => editedIndex !== i);
        }

        const result = filteredOptions.find(opt => opt.title === title);
        setOptionErrorType(result ? OPTION_ERROR_REPEAT : null);

        return !!result;
    };

    const handleUpdate = (e: ChangeEvent<{value: unknown}>) => {
        setOptionEditError(null);
        setOptionErrorType(null);
        const value = e.target.value as string;
        setUpdatedOptionTitle(value);
    };

    const checkTabPress = (e: React.KeyboardEvent<HTMLDivElement>, index?: number) => {
        if (e.shiftKey && e.key === 'Tab') {
            index !== undefined && index >= 0
                ? index === 0
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index - 1)
                : onAddClick(options.length - 1);
        } else if (e.key === 'Tab') {
            index !== undefined && index < options.length
                ? index === options.length - 1
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index + 1)
                : onAddClick('new');
        }
    };

    const onAddClick = async (move?: 'new' | number) => {
        if (updatedOptionTitle.length === 0 || optionErrorType) return;

        const newIndex = options.length;
        setLoadingOptions(prev => [...prev, newIndex]);

        createOption({
            uuid,
            file: null,
            title: String(updatedOptionTitle),
            showAlert,
            callback: () => {
                setLoadingOptions(prev => prev.filter(i => i !== newIndex));
                toggleAddInput();
                setTimeout(() => {
                    if (move === 'new') {
                        setShowAddInput(true);
                    } else if (move !== undefined) {
                        toggleEdit(move);
                    } else {
                        closeEdit();
                    }
                }, 10);
            },
        });

        setTimeout(() => {
            if (move === 'new') {
                setShowAddInput(true);
            } else if (move !== undefined) {
                toggleEdit(move);
            } else {
                closeEdit();
            }
        }, 10);
    };

    const onUpdateClick = async (index: number, move?: 'new' | number) => {
        if (editedIndex === null || optionErrorType) return;

        if (updatedOptionTitle) {
            if (findDuplicates(updatedOptionTitle)) {
                setOptionEditError(OPTION_ERROR_REPEAT);
                return;
            }
            setLoadingOptions(prev => [...prev, index]);

            await new Promise(resolve => {
                updateOption({
                    uuid: options?.[index]?.uuid,
                    title: String(updatedOptionTitle),
                    priority: index,
                    showAlert,
                    callback: () => {
                        setLoadingOptions(prev => prev.filter(i => i !== index));
                        resolve('');
                    },
                });
            });
        }

        if (!updatedOptionTitle) {
            await onDeleteClick(index);
        }

        if (move === 'new') {
            toggleAddInput();
        } else if (move !== undefined) {
            toggleEdit(move);
        } else {
            closeEdit();
        }
    };

    const onDeleteClick = async (index: number) => {
        setLoadingOptions(prev => [...prev, index]);

        new Promise(resolve => {
            deleteOption({
                uuid: options?.[index]?.uuid,
                optionIndex: index,
                showAlert,
                callback: () => {
                    setLoadingOptions(prev => prev.filter(i => i !== index));
                    resolve('');
                },
            });
        });
    };

    const closeAndSave = async (move?: 'new' | number) => {
        setOptionEditError(null);
        if (editedIndex !== null) return onUpdateClick(editedIndex, move);
        if (updatedOptionTitle.length > 0) return onAddClick(move);
    };

    const toggleAddInput = () => {
        setEditedIndex(null);
        setUpdatedOptionTitle('');
        setShowAddInput(previous => !previous);
    };

    const toggleEdit = (index: number) => {
        setShowAddInput(false);
        setEditedIndex(index);
        setUpdatedOptionTitle(options?.[index]?.title || '');
    };

    const closeEdit = () => {
        setShowAddInput(false);
        setEditedIndex(null);
        setUpdatedOptionTitle('');
    };

    const onAddNewClick = async () => {
        closeAndSave('new');
        toggleAddInput();
    };

    const onEditClick = async (index: number) => {
        closeAndSave(index);
        toggleEdit(index);
    };

    return (
        <Box sx={{mt: 2, mb: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{mb: 1, fontWeight: 700}}>{t('superadmin.customAttributes.optionsList')}</Typography>
                <Box>
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.info.light,
                            '&:hover': {backgroundColor: theme.palette.secondary.contrastText},
                            mr: 1,
                        }}
                        onClick={onAddNewClick}
                    >
                        <AddIcon fontSize="small" sx={{color: theme.palette.background.default}} />
                    </IconButton>
                </Box>
            </Box>
            {options.map((option: CustomAttributeOptionTypes, index: number) => {
                return (
                    <Box
                        key={`option-${index}`}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            backgroundColor: theme.palette.warning.contrastText,
                            borderRadius: 3,
                            p: 1,
                            pl: 2,
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        {editedIndex === index ? (
                            <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                <Box
                                    sx={{
                                        width: 'calc(100% - 80px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AutoDirectionTextField
                                        sx={{'& div': {height: 40}}}
                                        fullWidth
                                        value={updatedOptionTitle}
                                        onChange={handleUpdate}
                                        onBlur={() => onUpdateClick(index)}
                                        autoFocus
                                        onKeyDown={e => {
                                            if (e.key === 'Tab') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onKeyUp={e => {
                                            e.preventDefault();
                                            checkTabPress(e, index);
                                        }}
                                    />
                                </Box>
                                <EmptyArea />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {loadingOptions.includes(index) && <HerupuSpinner size={30} />}
                                    <IconButton
                                        disabled={updatedOptionTitle.length === 0}
                                        onClick={() => onUpdateClick(index)}
                                    >
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'calc(100% - 80px)',
                                        height: 30,
                                        ml: 1,
                                    }}
                                    onClick={() => onEditClick(index)}
                                >
                                    <Typography variant="body2">{option.title}</Typography>
                                </Box>
                                <EmptyArea />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <DeleteOption index={index} onDeleteClick={onDeleteClick} />
                                </Box>
                            </>
                        )}
                    </Box>
                );
            })}
            {showAddInput && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        p: 1,
                        pl: 3,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    <Box sx={{width: 'calc(100% - 80px)', display: 'flex', alignItems: 'center'}}>
                        <AutoDirectionTextField
                            sx={{'& div': {height: 40}}}
                            fullWidth
                            value={updatedOptionTitle}
                            onChange={handleUpdate}
                            onBlur={() => onAddClick()}
                            autoFocus
                            onKeyDown={e => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                }
                            }}
                            onKeyUp={e => {
                                e.preventDefault();
                                checkTabPress(e);
                            }}
                        />
                        <Box
                            sx={{
                                width: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loadingOptions.includes(options.length) && <HerupuSpinner size={30} />}
                        </Box>
                    </Box>
                    <EmptyArea />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            disabled={updatedOptionTitle.length === 0 || findDuplicates(updatedOptionTitle)}
                            onClick={() => onAddClick()}
                        >
                            <CheckIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CustomAttributesOptionsList;
