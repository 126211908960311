import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import EmptyArea from 'components/EmptyArea';
import Loader from 'components/Loader';

import usePdfFileLoader from 'pages/agent/pdfExport/pdfPartials/usePdfFileLoader';
import PdfDocument from 'pages/agent/pdfExport/pdfPartials/PdfDocument';
import SectionsList from 'pages/agent/pdfExport/pdfForm/SectionsList';
import {getDecodedResults} from 'pages/client/form/helper';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';
import {getCurrentTemplates} from 'helpers/templatesHelper';

const buttonStyles = {
    width: 150,
    height: 40,
    borderRadius: 2,
    textTransform: 'none',
    backgroundColor: theme.palette.info.main,
    '&:hover': {
        backgroundColor: theme.palette.info.main,
    },
};

interface PdfEditorType {
    pages: FormPageTypes[];
}

export interface PrintSectionType {
    printSection: boolean;
    printComments?: boolean;
}

const PdfEditor: FC<PdfEditorType> = ({pages}) => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {keys} = useContext(CryptoContext);
    const {encodedCaseKey, unwrappedCaseKey} = useContext(CaseKeyContext);

    const {
        requestCase: {currentCase},
        admin: {
            organizationPdfTemplates,
            languageList,
            formInfo: {translations},
        },
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {files, signatures, comments, result, resultAgent, isEncryptInfo, formPrefix, userName, popupItems} =
        currentCase;

    const orgLang = get(profile, 'lang', '');
    const languageId = get(profile, 'languageId', 1);
    const currentLang = languageList.find(item => item.id === languageId)?.name || '';

    const pdfTemplate = organizationPdfTemplates.find(template => currentCase.templatePdf === template.id);
    console.log('pdfTemplate: ', pdfTemplate, languageList, profile);
    const templates = pdfTemplate?.attachments && getCurrentTemplates(pdfTemplate?.attachments, currentLang, orgLang);

    const [isPreview, setIsPreview] = useState(false);
    const [printSections, setPrintSections] = useState<Record<string, Record<string, PrintSectionType>>>({});
    const [printAttachments, setPrintAttachments] = useState<Record<string, boolean>>({});

    const {loaded, fileImages, preparedSignatures, preparedPages, preparedTemplates, preparedPopupItems} =
        usePdfFileLoader({
            files,
            signatures,
            pages,
            templates,
            popupItems,
        });

    const onPageCheck = (pageId: number) => (checked: boolean) => {
        setPrintSections(prev => {
            const page = {};
            for (const sectionId in prev[pageId]) {
                page[sectionId] = {...page[sectionId], printSection: checked};
            }
            return {...prev, [pageId]: page};
        });
    };

    const onSectionCheck = (pageId: number, sectionId: number) => (print: PrintSectionType) => {
        setPrintSections(prev => ({...prev, [pageId]: {...prev[pageId], [sectionId]: print}}));
    };

    useEffect(() => {
        if (!pages) return;
        const selectedPages = {};
        for (const page of pages) {
            selectedPages[page.id] = {};
            for (const section of page.sections) {
                const hasComments = !!comments.find(comment => comment.section === section.id);
                selectedPages[page.id][section.id] = {
                    printSection: true,
                    ...(hasComments ? {printComments: true} : {}),
                };
            }
        }
        setPrintSections(selectedPages);
    }, [pages, comments]);

    useEffect(() => {
        if (!files) return;
        const attachments = {};
        for (const file of files) {
            attachments[file.uuid] = true;
        }
        setPrintAttachments(attachments);
    }, [files]);

    const [clientInformation, setClientInformation] = useState<RequesterCaseItemType | null>(null);
    const [agentInformation, setAgentInformation] = useState<RequesterCaseItemType | null>(null);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isEncryptInfo) {
                if (result && keys && encodedCaseKey) {
                    const currentResults = await getDecodedResults(result, encodedCaseKey, keys);
                    setClientInformation({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        enc: isEncryptInfo,
                        ...currentResults,
                    });
                }
                if (resultAgent && keys && encodedCaseKey) {
                    const currentAgentResults = await getDecodedResults(resultAgent, encodedCaseKey, keys);
                    setAgentInformation({
                        enc: isEncryptInfo,
                        ...currentAgentResults,
                    });
                }
            } else {
                setClientInformation(result);
                setAgentInformation(resultAgent);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [result, resultAgent, encodedCaseKey, isEncryptInfo]);

    const [commentsInfo, setCommentsInfo] = useState<RequesterCaseCommentsTypes[] | null>(null);

    useEffect(() => {
        if (!isEncryptInfo) {
            setCommentsInfo(comments);
            return;
        }

        const timer = setTimeout(async () => {
            if (comments && keys && unwrappedCaseKey) {
                const commentsRecord: RequesterCaseCommentsTypes[] = [];

                for (const comment of comments) {
                    const decodedMessage = await decryptStringWithKey(comment.text, unwrappedCaseKey);
                    commentsRecord.push({...comment, text: decodedMessage});
                }
                setCommentsInfo(commentsRecord);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [isEncryptInfo, comments]);

    return (
        <Box sx={{position: 'relative', pt: 14, display: 'flex', height: '100vh', maxWidth: '800px', mx: 'auto'}}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 3,
                }}
            >
                <Typography align="left" mb={2} sx={{fontSize: 32, fontWeight: 300}}>
                    {t(
                        isPreview
                            ? 'orguser.requesterCase.pdfPreview'
                            : 'orguser.requesterCase.specifyExportedElements',
                    )}
                </Typography>
                <EmptyArea />

                <Button
                    sx={{...buttonStyles, width: isPreview ? 100 : 150, mr: 1}}
                    onClick={() => setIsPreview(prev => !prev)}
                >
                    <Typography sx={{color: theme.palette.background.default}}>
                        {t(isPreview ? 'common.buttons.update' : 'orguser.requesterCase.generatePdf')}
                    </Typography>
                </Button>
                {loaded && isPreview && (!result || clientInformation || agentInformation) && commentsInfo && (
                    <Button sx={buttonStyles}>
                        <PDFDownloadLink
                            document={
                                <PdfDocument
                                    pages={preparedPages}
                                    currentCase={{
                                        ...currentCase,
                                        comments: commentsInfo,
                                        signatures: preparedSignatures,
                                        popupItems: preparedPopupItems,
                                    }}
                                    result={clientInformation}
                                    resultAgent={agentInformation}
                                    formId={formId}
                                    fileImages={fileImages}
                                    printSections={printSections}
                                    printAttachments={printAttachments}
                                    translations={translations}
                                    templates={preparedTemplates}
                                />
                            }
                            fileName={`${formPrefix}_${userName}.pdf`}
                            style={{
                                color: theme.palette.background.default,
                                textDecoration: 'none',
                                fontSize: '1rem',
                            }}
                        >
                            {({loading}) => t(loading ? 'common.pleaseWait' : 'orguser.requesterCase.exportPdf')}
                        </PDFDownloadLink>
                    </Button>
                )}
            </Box>
            {isPreview ? (
                <Box sx={{overflowY: 'auto', flex: 1, py: 1, px: 2}}>
                    <Box sx={{maxWidth: '700px', mx: 'auto'}}>
                        {loaded && commentsInfo && (!result || clientInformation || agentInformation) ? (
                            <PdfDocument
                                pages={preparedPages}
                                currentCase={{...currentCase, comments: commentsInfo, signatures: preparedSignatures}}
                                formId={formId}
                                result={clientInformation}
                                resultAgent={agentInformation}
                                fileImages={fileImages}
                                printSections={printSections}
                                printAttachments={printAttachments}
                                translations={translations}
                                templates={preparedTemplates}
                                preview
                            />
                        ) : (
                            <Loader />
                        )}
                    </Box>
                </Box>
            ) : (
                <Box sx={{overflowY: 'auto', flex: 1, py: 1, px: 2}}>
                    {pages.map((page: FormPageTypes) => {
                        return (
                            <SectionsList
                                page={page}
                                currentCase={currentCase}
                                key={page.id}
                                formId={formId}
                                printSections={printSections[page.id]}
                                onSectionCheck={onSectionCheck}
                                onPageCheck={onPageCheck}
                                printAttachments={printAttachments}
                                setPrintAttachments={setPrintAttachments}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default PdfEditor;
