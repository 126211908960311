import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface SignaturesContentType {
    field: FormFieldTypes;
    keywordTitle: string;
    keywordDescription: string;
    sectionId: number;
    signatures: RequesterSignatureResponseType[];
}

const SignaturesContent: FC<SignaturesContentType> = ({
    field,
    keywordTitle,
    keywordDescription,
    sectionId,
    signatures,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const fieldSignatures = signatures.filter(s => s.section === sectionId && s.field === field.id);
    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            {fieldSignatures.map(signature => (
                <Box sx={{mb: 1, height: '50px'}} key={signature.uuid}>
                    <img src={signature.fileLink || ''} style={{maxHeight: '100%', maxWidth: '100%'}} />
                </Box>
            ))}
        </InformationWrapper>
    );
};

export default SignaturesContent;
