import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {Link as MuiLink} from '@mui/material';

import RedirectSvgIcon from 'assets/icons/buttons/RedirectSvgIcon';

import {InviteAdditionalFormTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import EmptyArea from 'components/EmptyArea';
import TagChipWithDeleting from 'components/TagChip/TagChipWithDeleting';

import DeleteAdditionalFormRequestButton from 'pages/agent/requesterPage/additionalForms/DeleteAdditionalFormRequestButton';

import {getFormTranslatedLabel, getFormKeyword} from 'helpers/translationsHelper';

import {routes} from 'config/index';

interface InviteAdditionalFormRowType {
    item: InviteAdditionalFormTypes;
}

const InviteAdditionalFormRow: FC<InviteAdditionalFormRowType> = ({item}) => {
    const [t] = useTranslation();

    const {caseId, formId, formTitle, isSubmitted, statusTitle, statusColor} = item;

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isStatusVisible = statusTitle && statusColor;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: isStatusVisible ? 'flex-start' : 'center',
                mt: 1,
            }}
        >
            <Box>
                <Typography sx={{mb: isStatusVisible ? 0.5 : 'initial'}}>
                    {getFormTranslatedLabel(translations, getFormKeyword(Number(formId), 'title'), formTitle)}
                </Typography>
                {isStatusVisible && <TagChipWithDeleting id={Number(formId)} title={statusTitle} color={statusColor} />}
            </Box>
            <EmptyArea />
            {!isSubmitted ? (
                <DeleteAdditionalFormRequestButton item={item} />
            ) : (
                <MuiLink href={`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`} target="_blank">
                    <IconButton title={t('orguser.requesterCase.additionalForms.redirectToCase')}>
                        <RedirectSvgIcon />
                    </IconButton>
                </MuiLink>
            )}
        </Box>
    );
};

export default InviteAdditionalFormRow;
