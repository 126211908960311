import {FormLabelTypes} from 'appRedux/actions/forms/types';

export interface FormLabelOptionTypes {
    inputValue?: string | number;
    title: string;
}

export const getFormLabelOptions = (
    formLabelsForOrganization: FormLabelTypes[],
    formLabels: FormLabelTypes[],
): FormLabelOptionTypes[] => {
    const results: FormLabelOptionTypes[] = [];
    const presentedLabelIds: number[] = [];
    formLabels.forEach(label => {
        presentedLabelIds.push(label.id);
    });
    formLabelsForOrganization.forEach(item => {
        if (!presentedLabelIds.includes(item.id)) {
            results.push({
                inputValue: item.id,
                title: item.title,
            });
        }
    });
    return results;
};
