import React, {FC, MouseEvent, useCallback, useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useOnClickOutside, useEventListener} from 'usehooks-ts';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {REQUEST_CASE_TOGGLE_PINNING_MULTIPLE} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';

import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import AssignAgentForm from 'components/Forms/RequesterCase/AssignAgentForm';
import ChangeStatusMultipleForm from 'components/Forms/RequesterCase/ChangeStatusMultipleForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import {useExportCase} from 'components/AgentScreenComponents/_caseBlock/useExportCase';

import {getCasesStatus} from 'pages/agent/tableView/helper';
import CaseTagForm from 'pages/agent/tableView/CaseTagForm';

import {VIEW_MODE_BOARD} from 'helpers/filter';

import {theme} from 'config/theme';
import {
    PARAMETER_AGENT,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_SEARCH,
    PARAMETER_TAG,
    PARAMETER_VIEW_MODE,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_STATUS,
    PARAMETER_TAB,
    PARAMETER_FIRST_LETTER,
    STATUS_OPENING,
    ITEMS_PER_PAGE_DEFAULT,
} from 'config/index';

interface RequesterCaseContextMenuType {
    permittedUuids: string[];
    showContextMenu: boolean;
    setShowContextMenu: (value: boolean) => void;
    item: RequesterCaseResponseTypes;
    anchorHorizontal: number | null;
    anchorVertical: number | null;
    pinnedCasesList?: boolean;
    isTableView?: boolean;
    toggleAction?: () => void;
}

const RequesterCaseContextMenu: FC<RequesterCaseContextMenuType> = ({
    showContextMenu,
    setShowContextMenu,
    anchorHorizontal,
    anchorVertical,
    item,
    permittedUuids,
    pinnedCasesList,
    isTableView,
    toggleAction,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {isBoardListCasesPage, isBoardOverviewCasesPage, isSwimlaneCasesPage} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);
    const {setSelectedIds, toggleIsUpdated} = useContext(AdminContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string | null>(null);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {isPinned, caseId, status, formVersionId, isEncryptInfo} = item;

    const pageFromUrl = searchParams.get(PARAMETER_PAGE) ?? DEFAULT_PAGE;
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE) ?? ITEMS_PER_PAGE_DEFAULT;
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const modeFromUrl = searchParams.get(PARAMETER_VIEW_MODE) ?? VIEW_MODE_BOARD;
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const currentTab = searchParams.get(PARAMETER_TAB);
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER) ?? '';

    const ref = useRef<HTMLElement | null>(null);

    const commonParameters = {
        ids: [caseId],
        showAlert,
        board: uuid,
        isAlphabetList: Number(isBoardListCasesPage),
        isOverviewBoard: Number(isBoardOverviewCasesPage),
        isSwimlaneBoard: Number(isSwimlaneCasesPage),
        isPinnedList: Number(pinnedCasesList !== undefined),
        viewMode: modeFromUrl,
        firstLetter: firstLetterFromUrl,
        page: pageFromUrl,
        per_page: perPageFromUrl,
        agent: getParameterFromUrl(agentFromUrl),
        status: getCasesStatus(statusFromUrl, currentTab),
        form: getParameterFromUrl(formFromUrl),
        tags: getParameterFromUrl(tagsFromUrl),
        search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
    };

    const togglePinning = useCallback(
        data => dispatch({type: REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const exportCase = useExportCase();

    const onPinnedClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        togglePinning({
            ...commonParameters,
            isPinned: isPinned ? 0 : 1,
            isTableView: Number(isTableView),
            callback: () => {
                setShowContextMenu(false);
                toggleAction && toggleAction();
            },
        });
    };

    const onExportClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        exportCase(
            {
                id: caseId,
                callback: () => {
                    setShowContextMenu(false);
                },
            },
            isEncryptInfo,
        );
    };

    const openModal = (event: MouseEvent<HTMLElement>, value: string) => {
        event.stopPropagation();
        setSelectedIds([caseId]);
        toggleIsUpdated();
        setShowModal(true);
        setModalType(value);
        setShowContextMenu(false);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalType(null);
    };

    useOnClickOutside(ref, () => setShowContextMenu(false));
    useEventListener('scroll', () => setShowContextMenu(false));

    const top = Number(anchorVertical) < window.innerHeight - 200 ? Number(anchorVertical) : 'auto';
    const left = Number(anchorHorizontal) < window.innerWidth - 300 ? Number(anchorHorizontal) : 'auto';
    const bottom =
        Number(anchorVertical) >= window.innerHeight - 200 ? window.innerHeight - Number(anchorVertical) : 'auto';
    const right =
        Number(anchorHorizontal) >= window.innerWidth - 300 ? window.innerWidth - Number(anchorHorizontal) : 'auto';

    return (
        <>
            {showContextMenu && (
                <Box
                    ref={ref}
                    sx={{
                        width: 287,
                        zIndex: 9999999,
                        backgroundColor: theme.palette.background.default,
                        position: 'absolute',
                        top,
                        left,
                        bottom,
                        right,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        '& ul': {
                            padding: 0,
                        },
                    }}
                >
                    <MenuList>
                        <MenuItem sx={{pt: 1, pb: 1}} onClick={onPinnedClick}>
                            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                {t(isPinned ? 'orguser.requesterCase.unpinCase' : 'orguser.requesterCase.pinCase')}
                            </Typography>
                        </MenuItem>
                        {myOrganization && myOrganization.isExportAllowed && (
                            <MenuItem sx={{pt: 1, pb: 1}} onClick={onExportClick}>
                                <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                    {t('orguser.requesterCase.exportCase')}
                                </Typography>
                            </MenuItem>
                        )}
                        {status !== STATUS_OPENING && (
                            <MenuItem sx={{pt: 1, pb: 1}} onClick={e => openModal(e, 'status')}>
                                <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                    {t('orguser.requesterCase.changeStatus')}
                                </Typography>
                            </MenuItem>
                        )}
                        <MenuItem sx={{pt: 1, pb: 1}} onClick={e => openModal(e, 'agent')}>
                            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                {t('orguser.requesterCase.assignAgent')}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{pt: 1, pb: 1}}
                            onClick={e => {
                                getRequesterCaseInfo({id: caseId});
                                openModal(e, 'tags');
                            }}
                        >
                            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                                {t('orguser.requesterCase.editTags')}
                            </Typography>
                        </MenuItem>
                    </MenuList>
                </Box>
            )}
            {showModal && (
                <ModalWrapper
                    isShowModal={showModal}
                    toggleModal={toggleModal}
                    title={t(
                        modalType === 'agent'
                            ? 'orguser.requesterCase.assignAgent'
                            : modalType === 'tags'
                            ? 'orguser.requesterCase.editTags'
                            : 'orguser.requesterCase.changeStatus',
                    )}
                >
                    {modalType === 'agent' ? (
                        <AssignAgentForm
                            closeModal={closeModal}
                            pinnedCasesList={pinnedCasesList}
                            isTableView={isTableView}
                        />
                    ) : modalType === 'tags' ? (
                        <CaseTagForm
                            closeModal={closeModal}
                            pinnedCasesList={pinnedCasesList}
                            isTableView={isTableView}
                        />
                    ) : (
                        <ChangeStatusMultipleForm
                            formVersionId={formVersionId}
                            closeModal={closeModal}
                            pinnedCasesList={pinnedCasesList}
                            permittedUuids={permittedUuids}
                            isTableView={isTableView}
                        />
                    )}
                </ModalWrapper>
            )}
        </>
    );
};

export default RequesterCaseContextMenu;
