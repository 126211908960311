import React, {FC, useContext} from 'react';
import {View, Text} from '@react-pdf/renderer';

import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface SelectFieldPdfContentType {
    formId: number;
    field: FormFieldTypes;
}

const SelectFieldPdfContent: FC<SelectFieldPdfContentType> = ({formId, field}) => {
    const {translations, currentCase} = useContext(PdfDocumentContext);

    const {label, id} = field;

    const {options} = currentCase;

    const keyword = getFieldKeyword(formId, id, 'label');

    const selectedOptions = options.filter(option => option.fieldId === field.id);

    return (
        <View style={pdfStyles.field}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>

            <View style={pdfStyles.fieldValue}>
                {selectedOptions.length ? (
                    selectedOptions.map((option, index) => (
                        <Text key={`selected-option-${option.optionId}-${index}`}>
                            {option.title || EMPTY_DATA}
                            {index < selectedOptions.length - 1 ? ', ' : ''}
                        </Text>
                    ))
                ) : (
                    <Text>{EMPTY_DATA}</Text>
                )}
            </View>
        </View>
    );
};

export default SelectFieldPdfContent;
