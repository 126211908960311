import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';
import UserAttributeSvgIcon from 'assets/icons/buttons/UserAttributeSvgIcon';

import {WorkflowItemTypes, WorkflowFormItemTypes} from 'appRedux/actions/workflow/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {CLONE_WORKFLOW} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import EmptyArea from 'components/EmptyArea/EmptyArea';
import WorkflowGraph from 'components/WorkflowGraph/WorkflowGraph';

import DeleteWorkflowButton from 'pages/admin/workflows/DeleteWorkflowButton';

import {routes, PARAMETER_LANGUAGE} from 'config/index';
import {theme} from 'config/theme';

interface WorkflowTileType {
    item: WorkflowItemTypes;
    currentOrganizationLanguage?: LanguageItemTypes;
    currentUserLanguage: string;
}

const WorkflowTile: FC<WorkflowTileType> = ({item, currentUserLanguage, currentOrganizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isFormEditEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const {title, forms, uuid} = item;

    const cloneWorkflow = useCallback(data => dispatch({type: CLONE_WORKFLOW.REQUEST, payload: data}), [dispatch]);

    const redirectToWorkflow = () => {
        if (currentOrganizationLanguage && currentOrganizationLanguage.name === currentUserLanguage) {
            navigate(`${routes.WORKFLOW}/${uuid}/edit`);
        } else {
            navigate(`${routes.WORKFLOW}/${uuid}/edit?${PARAMETER_LANGUAGE}=${currentUserLanguage}`);
        }
    };

    const redirectToForm = (id: number) => {
        if (isFormEditEnabled) {
            navigate(`${routes.FORM}/${id}/edit`);
        }
    };

    const redirectToVariablesList = () => {
        navigate(`${routes.WORKFLOW}/${uuid}/variables`);
    };

    const onCloneClicked = () => {
        cloneWorkflow({
            uuid,
            showAlert,
        });
    };

    return (
        <Box
            sx={{
                borderRadius: 4,
                backgroundColor: theme.palette.background.default,
                p: 2,
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, fontSize: 16}}>{title}</Typography>
                <EmptyArea />
                <IconButton title={t('common.buttons.update')} onClick={redirectToWorkflow}>
                    <EditSvgIcon />
                </IconButton>
                <IconButton title={t('common.buttons.copy')} onClick={onCloneClicked}>
                    <CopySvgIcon />
                </IconButton>
                <IconButton title={t('orguser.fieldVariables.pageTitle')} onClick={redirectToVariablesList}>
                    <UserAttributeSvgIcon />
                </IconButton>
                <DeleteWorkflowButton item={item} />
            </Box>
            <WorkflowGraph uuid={uuid} currentLanguage={currentUserLanguage} isGroup />
            <Typography sx={{fontWeight: 600, mb: 1}}>{`${t('orguser.workflows.parameters.forms')}:`}</Typography>
            <Grid container>
                {forms.length > 0 ? (
                    forms.map((form: WorkflowFormItemTypes) => {
                        const {id, title} = form;
                        return (
                            <Grid item md={3} key={`form-${uuid}-${id}`}>
                                <Typography
                                    key={`form-${id}`}
                                    sx={{mb: 0.5, cursor: isFormEditEnabled ? 'pointer' : 'initial'}}
                                    onClick={() => redirectToForm(id)}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <Typography>{t('common.none')}</Typography>
                )}
            </Grid>
        </Box>
    );
};

export default WorkflowTile;
