import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateWorkflowFieldVariableForm from 'components/Forms/Workflow/workflowFieldVariables/CreateWorkflowFieldVariableForm';

const AddWorkflowFieldVariableButton: FC = () => {
    const [t] = useTranslation();
    const {uuid} = useParams();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                mb: 2,
            }}
        >
            <AgentInverseButton
                title={t('orguser.fieldVariables.addVariable')}
                onClick={() => setIsOpened(true)}
                icon={<PlusSvgIcon />}
            />
            <ModalWrapper
                isShowModal={isOpened}
                title={t('orguser.fieldVariables.addVariable')}
                toggleModal={toggleIsOpened}
            >
                <CreateWorkflowFieldVariableForm uuid={String(uuid)} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddWorkflowFieldVariableButton;
