import React, {FC, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';

import AccountSvgIcon from 'assets/icons/topBarMenu/AccountSvgIcon';
import SecuritySvgIcon from 'assets/icons/topBarMenu/SecuritySvgIcon';
import CaseFiltersSvgIcon from 'assets/icons/topBarMenu/CaseFiltersSvgIcon';
import LogoutSvgIcon from 'assets/icons/topBarMenu/LogoutSvgIcon';
import UserMultipleSvgIcon from 'assets/icons/buttons/UserMultipleSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {SET_ERRORS} from 'appRedux/actions/errors';
import {UPDATE_DEFAULT_ROLE} from 'appRedux/actions/profile';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import {MenuContentItemType} from 'components/TopBarMenu/helper';
import MenuContentItem from 'components/TopBarMenu/MenuContentItem';
import AvatarNameAndRole from 'components/TopBarMenu/AvatarNameAndRole';

import {getSecurityRoute, getAccountRoute} from 'helpers/menuHelper';

import {routes, TYPE_MULTI_ROLE_ORGANIZATION_USER, TYPE_MULTI_ROLE_REQUESTER} from 'config/index';

const MenuContent: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {onLogoutClicked, isAgentPage, isAdminPage, isClientMode} = useContext(RouteContext);
    const {isCaseViewEnabled, isModeSwitchPossible} = useContext(PermissionContext);

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const updateDefaultRole = useCallback(
        data => dispatch({type: UPDATE_DEFAULT_ROLE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const roles: string[] = get(profile, 'roles', []);

    const redirectToPage = () => {
        const route = isClientMode ? routes.ADMIN : routes.REQUESTS_LIST;
        navigate(route);
    };

    const switchMode = () => {
        refreshErrors(null);
        updateDefaultRole({
            typeMultiRole: isClientMode ? TYPE_MULTI_ROLE_ORGANIZATION_USER : TYPE_MULTI_ROLE_REQUESTER,
            callbackSwitcher: redirectToPage,
        });
    };

    const redirectToAccount = () => {
        refreshErrors(null);
        navigate(getAccountRoute(roles, isClientMode));
    };

    const redirectToSecurity = () => {
        refreshErrors(null);
        navigate(getSecurityRoute(roles, isClientMode));
    };

    const redirectToCaseFilter = () => {
        refreshErrors(null);
        navigate(routes.SAVED_FILTERS);
    };

    const menuItems: MenuContentItemType[] = [
        {
            icon: UserMultipleSvgIcon,
            label: isClientMode ? 'switchToAdmin' : 'switchToRequester',
            onClickAction: switchMode,
            isHidden: !profile || !profile.isRelatedWithCurrentOrganization,
        },
        {
            icon: AccountSvgIcon,
            label: 'account',
            path: 'account',
            onClickAction: redirectToAccount,
        },
        {
            icon: SecuritySvgIcon,
            label: 'security',
            path: 'security',
            onClickAction: redirectToSecurity,
        },
        {
            icon: CaseFiltersSvgIcon,
            label: 'caseFilter',
            path: 'savedFilters',
            onClickAction: redirectToCaseFilter,
            isHidden: isClientMode || !isCaseViewEnabled || (!isAgentPage && !isAdminPage),
        },
        {
            icon: LogoutSvgIcon,
            label: 'logout',
            path: '',
            onClickAction: () => onLogoutClicked(),
        },
    ];

    return (
        <Box sx={{p: 2}}>
            <AvatarNameAndRole />
            <Divider sx={{mt: 2, mb: 2}} />
            <MenuList>
                {menuItems.map((item: MenuContentItemType, index: number) => {
                    return <MenuContentItem key={`menuItem-${index}`} item={item} />;
                })}
            </MenuList>
        </Box>
    );
};

export default MenuContent;
