import {TFunction} from 'react-i18next';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

export const MINIMAL_PASSWORD_LENGTH = 12;
export const MAXIMAL_PASSWORD_LENGTH = 64;
export const MAXIMAL_PASSWORD_ENTROPY = 60;

export const getPasswordLengthErrorMessage = (t: TFunction, keyword: string, limit: number): string => {
    return t(keyword).replace('%NUMBER%', String(limit));
};

export const checkSubdomainCorrect = (organization: OrganizationItemBasic | null) => {
    return organization && location.host === organization.domainName;
};
