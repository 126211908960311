import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {RequesterAllCaseItemTypes, RequestCasePageType} from 'appRedux/actions/requestCase/types';

import PagesListItem from 'pages/client/requests/partials/PagesListItem';

interface PagesListType {
    item: RequesterAllCaseItemTypes;
    isOpened?: boolean;
}

const PagesList: FC<PagesListType> = ({item, isOpened}) => {
    const {pages} = item;

    return (
        <Box>
            {isOpened &&
                pages.map((page: RequestCasePageType, index: number) => {
                    return <PagesListItem index={index} page={page} item={item} key={`page-${index}`} />;
                })}
        </Box>
    );
};

export default PagesList;
