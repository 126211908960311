import React, {FC} from 'react';

import {FieldOptionType} from 'appRedux/actions/forms/types';

import {
    OPTIONS_SEPARATOR,
    FORM_FIELD_DATE_RANGE,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RANGE,
    FORM_FIELD_RATING,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DATEPICKER,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_RESOURCE_FIELD,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import FormTextField from 'pages/client/form/fieldTypes/FormTextField';
import FormDatepicker from 'pages/client/form/fieldTypes/FormDatepicker';
import FormSelector from 'pages/client/form/fieldTypes/FormSelector';
import FormRadio from 'pages/client/form/fieldTypes/FormRadio';
import FormCheckbox from 'pages/client/form/fieldTypes/FormCheckbox';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import FormUploaderField from 'pages/client/form/fieldTypes/FormUploader';
import FormDateRange from 'pages/client/form/fieldTypes/FormDateRange';
import FormRange from 'pages/client/form/fieldTypes/FormRange';
import FormRating from 'pages/client/form/fieldTypes/FormRating';
import FormSignature from 'pages/client/form/fieldTypes/FormSignature';
import FormImageCheck from 'pages/client/form/fieldTypes/FormImageCheck';
import FormImageRadio from 'pages/client/form/fieldTypes/FormImageRadio';
import FormResourceFieldInformation from 'pages/client/form/fieldTypes/FormResourceFieldInformation';

interface FormFieldSwitcherType extends FormFieldPropsType {
    formId: number;
    popupId?: number;
    previewMode?: boolean;
}

const FormFieldSwitcher: FC<FormFieldSwitcherType> = ({
    formId,
    pageId,
    sectionId,
    field,
    clientInformation,
    agentInformation,
    handleChangeInformation,
    disabled,
    popupContent,
    onPopupFieldChange,
    popupItemId,
    previewMode,
    onSaveClicked,
    setIsDisabledSubmit,
    continueClickedRef,
    isAgentPage,
}) => {
    const options: string[] = field.options ? field.options.split(OPTIONS_SEPARATOR) : [];
    const fieldOptions: FieldOptionType[] = field.fieldOptions || [];

    switch (field.type) {
        case FORM_FIELD_TEXT_INPUT:
            return (
                <FormTextField
                    data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${field.id}`}
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    onSaveClicked={onSaveClicked}
                    setIsDisabledSubmit={setIsDisabledSubmit}
                    continueClickedRef={continueClickedRef}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_TEXTAREA:
            return (
                <FormTextField
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    onSaveClicked={onSaveClicked}
                    setIsDisabledSubmit={setIsDisabledSubmit}
                    continueClickedRef={continueClickedRef}
                    isAgentPage={isAgentPage}
                    isTextArea
                />
            );
        case FORM_FIELD_DATEPICKER:
            return (
                <FormDatepicker
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                    previewMode={previewMode}
                    continueClickedRef={continueClickedRef}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_DATE_RANGE:
            return (
                <FormDateRange
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                    previewMode={previewMode}
                    continueClickedRef={continueClickedRef}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_UPLOADER:
            return (
                <FormUploaderField
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    isPopup={!!popupItemId}
                    popupItemId={popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_RANGE:
            return (
                <FormRange
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    range={options}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    onSaveClicked={onSaveClicked}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_RATING:
            return (
                <FormRating
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    onSaveClicked={onSaveClicked}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_SIGNATURE:
            return (
                <FormSignature
                    formId={formId}
                    pageId={pageId}
                    sectionId={sectionId}
                    field={field}
                    clientInformation={clientInformation}
                    agentInformation={agentInformation}
                    handleChangeInformation={handleChangeInformation}
                    popupContent={popupContent}
                    onPopupFieldChange={onPopupFieldChange}
                    isPopup={!!popupItemId}
                    popupItemId={popupItemId}
                    disabled={disabled}
                    previewMode={previewMode}
                    onSaveClicked={onSaveClicked}
                    setIsDisabledSubmit={setIsDisabledSubmit}
                    isAgentPage={isAgentPage}
                />
            );
        case FORM_FIELD_CHECKBOXES:
            if (fieldOptions.length > 0) {
                return (
                    <FormCheckbox
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        field={field}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        handleChangeInformation={handleChangeInformation}
                        popupContent={popupContent}
                        onPopupFieldChange={onPopupFieldChange}
                        isPopup={!!popupItemId}
                        popupItemId={popupItemId}
                        disabled={disabled}
                        previewMode={previewMode}
                        onSaveClicked={onSaveClicked}
                        continueClickedRef={continueClickedRef}
                        isAgentPage={isAgentPage}
                    />
                );
            }
            return null;
        case FORM_FIELD_RADIOBUTTONS:
            if (fieldOptions.length > 1) {
                return (
                    <FormRadio
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        field={field}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        handleChangeInformation={handleChangeInformation}
                        popupContent={popupContent}
                        onPopupFieldChange={onPopupFieldChange}
                        isPopup={!!popupItemId}
                        popupItemId={popupItemId}
                        disabled={disabled}
                        setIsDisabledSubmit={setIsDisabledSubmit}
                        previewMode={previewMode}
                        onSaveClicked={onSaveClicked}
                        continueClickedRef={continueClickedRef}
                        isAgentPage={isAgentPage}
                    />
                );
            }
            return null;
        case FORM_FIELD_DROPDOWN:
            if (fieldOptions.length > 1) {
                return (
                    <FormSelector
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        field={field}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        handleChangeInformation={handleChangeInformation}
                        popupContent={popupContent}
                        onPopupFieldChange={onPopupFieldChange}
                        isPopup={!!popupItemId}
                        popupItemId={popupItemId}
                        disabled={disabled}
                        previewMode={previewMode}
                        onSaveClicked={onSaveClicked}
                        continueClickedRef={continueClickedRef}
                        isAgentPage={isAgentPage}
                    />
                );
            }
            return null;
        case FORM_FIELD_IMAGE_CHECK:
            if (fieldOptions.length > 1) {
                return (
                    <FormImageCheck
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        field={field}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        handleChangeInformation={handleChangeInformation}
                        popupContent={popupContent}
                        onPopupFieldChange={onPopupFieldChange}
                        isPopup={!!popupItemId}
                        popupItemId={popupItemId}
                        disabled={disabled}
                        previewMode={previewMode}
                        onSaveClicked={onSaveClicked}
                        continueClickedRef={continueClickedRef}
                        isAgentPage={isAgentPage}
                    />
                );
            }
            return null;
        case FORM_FIELD_IMAGE_RADIO:
            if (fieldOptions.length > 1) {
                return (
                    <FormImageRadio
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        field={field}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        handleChangeInformation={handleChangeInformation}
                        popupContent={popupContent}
                        onPopupFieldChange={onPopupFieldChange}
                        isPopup={!!popupItemId}
                        popupItemId={popupItemId}
                        disabled={disabled}
                        previewMode={previewMode}
                        onSaveClicked={onSaveClicked}
                        continueClickedRef={continueClickedRef}
                        isAgentPage={isAgentPage}
                    />
                );
            }
            return null;
        case FORM_FIELD_RESOURCE_FIELD:
            return (
                <FormResourceFieldInformation
                    formId={formId}
                    sectionId={sectionId}
                    isAgentPage={isAgentPage}
                    field={field}
                />
            );
    }
    return null;
};

export default FormFieldSwitcher;
