import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import {AgentMenuItemTypes} from 'components/AgentScreenComponents/helper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';

interface MenuLinkType {
    isActive?: boolean;
    isSubItem?: boolean;
    hasSubItems?: boolean;
    isMenuCollapsed?: boolean;
    menuItem: AgentMenuItemTypes;
    toggleAdminMenu: (value: string) => void;
    isMobile?: boolean;
}

const MenuLink: FC<MenuLinkType> = ({
    menuItem,
    isActive,
    hasSubItems,
    isSubItem,
    isMenuCollapsed,
    toggleAdminMenu,
    isMobile,
}) => {
    const [t] = useTranslation();

    const {badge, href, label, link} = menuItem;

    const ItemIcon = menuItem.icon;

    return (
        <Link
            data-id={`link#menu-${label}`}
            to={href}
            style={{textDecoration: 'none'}}
            onClick={() => toggleAdminMenu(link)}
        >
            <Tooltip
                title={<Typography variant="body2">{t(label)}</Typography>}
                placement={isMobile ? 'top' : 'right'}
                arrow
                disableHoverListener={!isMenuCollapsed}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isMenuCollapsed ? 'center' : 'flex-start',
                        alignItems: 'center',
                        height: isSubItem ? 'auto' : 40,
                        ml: isMenuCollapsed ? 0.5 : !isSubItem ? 2 : 4.5,
                        pr: isMenuCollapsed ? 0.5 : 2,
                        py: hasSubItems || isSubItem ? 0 : 1,
                        borderLeftWidth: !isMenuCollapsed && isSubItem ? 1 : 'none',
                        borderLeftStyle: !isMenuCollapsed && isSubItem ? 'solid' : 'none',
                        borderLeftColor: !isMenuCollapsed && isSubItem ? theme.palette.info.main : 'none',
                    }}
                >
                    {ItemIcon && (
                        <IconButton
                            sx={{
                                ml: isSubItem ? 1 : 'initial',
                                width: 40,
                                height: 40,
                                backgroundColor: isActive ? theme.palette.info.main : 'initial',
                                '&:hover': {
                                    backgroundColor: isActive ? theme.palette.info.main : 'initial',
                                },
                            }}
                        >
                            <Badge
                                sx={{
                                    '& .MuiBadge-badge': {
                                        top: 2,
                                        right: 2,
                                        backgroundColor: MAIN_CLIENT_COLOR,
                                    },
                                }}
                                variant="dot"
                                invisible={!badge}
                            >
                                <ItemIcon isActive={isActive} />
                            </Badge>
                        </IconButton>
                    )}
                    {!isMenuCollapsed && (
                        <Typography
                            sx={{
                                ml: 1,
                                display: 'block',
                                color: theme.palette.info.main,
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {t(label)}
                        </Typography>
                    )}
                </Box>
            </Tooltip>
        </Link>
    );
};

export default MenuLink;
