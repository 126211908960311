import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {IconButton} from '@mui/material';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {MediaContext} from 'contexts/media/context';

import ClientButton from 'components/ClientScreenComponents/ClientButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CancelRequesterCaseForm from 'components/Forms/RequesterCase/CancelRequesterCaseForm';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface RequesterCaseCancelButtonType {
    caseId: number;
}

const RequesterCaseCancelButton: FC<RequesterCaseCancelButtonType> = ({caseId}) => {
    const [t] = useTranslation();

    const {isMobile, isTablet, isMiddle} = useContext(MediaContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            {isMobile || isTablet || isMiddle ? (
                <IconButton
                    onClick={() => setIsOpened(true)}
                    sx={{
                        mr: 1,
                        p: 1.25,
                        borderColor: ERROR_TEXT_COLOR,
                        backgroundColor: ERROR_TEXT_COLOR,
                        '&:hover': {
                            backgroundColor: ERROR_TEXT_COLOR,
                        },
                        svg: {
                            height: '20px',
                            width: '20px',
                        },
                    }}
                >
                    <DeleteSvgIcon isActive />
                </IconButton>
            ) : (
                <ClientButton
                    title={t('requester.casesList.cancelCaseButton')}
                    onClickAction={() => setIsOpened(true)}
                    isError
                    endIcon={<CloseSvgIcon isActive />}
                />
            )}
            <ModalWrapper
                title={t('requester.casesList.cancelCaseButton')}
                isShowModal={isOpened}
                toggleModal={toggleModal}
            >
                <Typography>{t('requester.casesList.cancelReasonExplanation')}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                        '& form': {
                            width: '100%',
                        },
                    }}
                >
                    <CancelRequesterCaseForm caseId={caseId} closeModal={() => setIsOpened(false)} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default RequesterCaseCancelButton;
