import {fork, put, take, call} from 'redux-saga/effects';

import {
    InviteListRequestType,
    InvitesListResponse,
    InviteCreateRequestType,
    InviteBulkCreateRequestType,
    SendInviteRequestType,
    InviteDeleteRequestType,
    InviteCreateForUserRequestType,
} from 'appRedux/actions/invites/types';
import {
    getInvitesList,
    INVITES_GET_LIST,
    getUserInvitesList,
    INVITES_GET_USER_LIST,
    createInvite,
    INVITE_CREATE,
    createInviteBulk,
    INVITE_CREATE_BULK,
    deleteInvite,
    INVITE_DELETE,
    sendInvite,
    INVITE_SEND,
    createInviteForUser,
    INVITE_CREATE_USER,
} from 'appRedux/actions/invites';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

import {http} from 'services/http';

function* watchGetInvitesList() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<InviteListRequestType> = yield take(INVITES_GET_LIST.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, 'invites', {
                method: 'GET',
            });
            if (data.success) {
                yield put(getInvitesList.success(data.results));
            } else {
                yield put(getInvitesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getInvitesList.error({message: String(e)}));
        }
    }
}

function* watchGetUserInvitesList() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<InviteListRequestType> = yield take(INVITES_GET_USER_LIST.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, 'invites/user', {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserInvitesList.success(data.results));
            } else {
                yield put(getUserInvitesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getUserInvitesList.error({message: String(e)}));
        }
    }
}

function* watchInviteCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<InviteCreateRequestType> = yield take(INVITE_CREATE.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, `invite/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createInvite.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createInvite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createInvite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteBulkCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<InviteCreateRequestType> = yield take(INVITE_CREATE_BULK.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, `invite/${id}/bulk`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createInviteBulk.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createInviteBulk.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createInviteBulk.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<InviteDeleteRequestType> = yield take(INVITE_DELETE.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, `invite/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteInvite.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteInvite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteInvite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteSending() {
    while (true) {
        const {
            payload: {showAlert, callback, formId, ...values},
        }: IActionType<SendInviteRequestType> = yield take(INVITE_SEND.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, `invite/${formId}/send`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(sendInvite.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(sendInvite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(sendInvite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteForUserCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<InviteCreateForUserRequestType> = yield take(INVITE_CREATE_USER.REQUEST);
        try {
            const data: InvitesListResponse = yield call(http, `invite/${id}/user/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createInviteForUser.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createInviteForUser.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createInviteForUser.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetInvitesList),
    fork(watchGetUserInvitesList),
    fork(watchInviteCreation),
    fork(watchInviteBulkCreation),
    fork(watchInviteDeleting),
    fork(watchInviteSending),
    fork(watchInviteForUserCreation),
];
