function calculateRepeatPatternPenalty(password) {
    const length = password.length;
    let repeatPenalty = 0;

    for (let chunkSize = 2; chunkSize <= Math.floor(length / 2); chunkSize++) {
        for (let start = 0; start <= length - chunkSize; start++) {
            const chunk = password.slice(start, start + chunkSize);
            let repeatCount = 0;

            for (let i = start; i <= length - chunkSize; i += chunkSize) {
                if (password.slice(i, i + chunkSize) === chunk) {
                    repeatCount++;
                } else {
                    break;
                }
            }

            if (repeatCount > 1) {
                repeatPenalty += chunkSize * repeatCount;
            }
        }
    }
    return repeatPenalty;
}

export function calculateEntropy(password = '') {
    if (!password) return 0;
    const loweredPassword = password.toLowerCase();
    const reversedPassword = password.split('').reverse().join('');
    const loweredReversedPassword = reversedPassword.toLowerCase();

    const commonPasswords = [
        'password',
        '123456',
        '123456789',
        'test',
        'pass',
        'qwert',
        '12345',
        '1234',
        '111111',
        'abc123',
        '1q2w3e',
        '!@#$%^&*',
        '101',
        'google',
        'herupu',
        'xxx',
        'never',
        'admin',
    ];

    let poolSize = 0;
    if (/[a-z]/.test(password)) poolSize += 26;
    if (/[A-Z]/.test(password)) poolSize += 26;
    if (/[0-9]/.test(password)) poolSize += 10;
    if (/[^a-zA-Z0-9]/.test(password)) poolSize += 32;

    let entropy = password.length * (Math.log2(poolSize) || 0);

    if (/(012|123|234|345|456|567|678|789|890|147|258|369|963|852|741|159|753|357|951)/.test(password)) {
        entropy -= 10;
    }
    if (
        /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/i.test(
            password,
        )
    ) {
        entropy -= 15;
    }

    entropy -= calculateRepeatPatternPenalty(loweredPassword);

    if (commonPasswords.some(common => loweredPassword.includes(common) || loweredReversedPassword.includes(common))) {
        entropy -= 30;
    }

    if (/\d{4}$/.test(password) && parseInt(password.slice(-4)) >= 1900 && parseInt(password.slice(-4)) <= 2050) {
        entropy -= 10;
    } else if (/\d{2}$/.test(password) && parseInt(password.slice(-2)) >= 0 && parseInt(password.slice(-2)) <= 50) {
        entropy -= 5;
    }

    return Math.max(0, entropy);
}
