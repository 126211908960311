import moment from 'moment';

export const convertDateToCurrentLocale = (datetime: Date, hideTime?: boolean, textMonth?: boolean): string => {
    if (hideTime) {
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: textMonth ? 'short' : 'numeric',
            day: 'numeric',
        }).format(new Date(datetime));
    }
    return new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: textMonth ? 'short' : 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(new Date(datetime));
};

export const convertTimeToSeconds = (time: string): number => {
    const timeArray = time.split(':');
    if (timeArray.length < 2) return 0;
    return Number(timeArray[0]) * 3600 + Number(timeArray[1]) * 60;
};

export const convertSecondsToTime = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);

    const hoursString = Number(hours) < 10 ? `0${hours}` : hours;
    const minutesString = Number(minutes) < 10 ? `0${minutes}` : minutes;

    return `${hoursString}:${minutesString}`;
};
