import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {FORM_LABEL_LIST} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormRightSideDetailsPanelWrapper from 'pages/admin/updateForm/partials/FormRightSideDetailsPanelWrapper';
import FormLabelsList from 'pages/admin/updateForm/partials/FormLabels/FormLabelsList';
import AddFormLabelButton from 'pages/admin/updateForm/partials/FormLabels/AddFormLabelButton';

interface FormLabelsType {
    formId: number;
}

const FormLabels: FC<FormLabelsType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getFormLabels = useCallback(data => dispatch({type: FORM_LABEL_LIST.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {
            formInfo: {formLabels},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getFormLabels({
            id: formId,
            showAlert,
        });
    }, [formId]);

    if (!formLabels) return null;

    return (
        <FormRightSideDetailsPanelWrapper title={t('orguser.forms.formLabels.formLabels')}>
            {formLabels.length === 0 ? (
                <Typography>{t('common.none')}</Typography>
            ) : (
                <FormLabelsList items={formLabels} />
            )}
            <AddFormLabelButton formId={formId} />
        </FormRightSideDetailsPanelWrapper>
    );
};

export default FormLabels;
