import React, {FC, useState, useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';
import {GET_MENU_BOARDS_LIST} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import TabsWrapper from 'components/TabsWrapper/TabsWrapper';
import UpdateBoardGeneralForm from 'components/Forms/BoardForm/UpdateBoardGeneralForm';
import BoardTypeBoardStatusesList from 'components/Forms/BoardForm/BoardTypeBoardStatusesList';
import {
    BOARD_TYPE_BOARD,
    BOARD_TYPE_LIST,
    BOARD_TYPE_OVERVIEW,
    BOARD_TYPE_SWIMLANE,
} from 'components/Forms/BoardForm/helper';
import EditBoardListStatusForm from 'components/Forms/BoardForm/EditBoardListStatusForm';
import EditBoardOverviewStatusForm from 'components/Forms/BoardForm/EditBoardOverviewStatusForm';

import BoardPermisstionTab from 'pages/admin/boardsList/permissions/BoardPermisstionTab';
import {tabs, TAB_BOARD_GENERAL, TAB_BOARD_STATUSES, TAB_BOARD_PERMISSIONS} from 'pages/admin/boardsList/helper';

interface EditBoardFormModalType {
    item: BoardItemTypes;
}

const EditBoardFormModal: FC<EditBoardFormModalType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const [currentTab, setCurrentTab] = useState<number>(TAB_BOARD_GENERAL);

    const {type, agents, groups} = item;

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (isCaseViewEnabled) {
            getMenuBoardsList({showAlert});
        }
    }, [agents.length, groups.length]);

    return (
        <TabsWrapper tabItems={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}>
            {currentTab === TAB_BOARD_GENERAL && <UpdateBoardGeneralForm item={item} />}
            {currentTab === TAB_BOARD_STATUSES && [BOARD_TYPE_BOARD, BOARD_TYPE_SWIMLANE].includes(type) && (
                <BoardTypeBoardStatusesList item={item} />
            )}
            {currentTab === TAB_BOARD_STATUSES && type === BOARD_TYPE_LIST && <EditBoardListStatusForm item={item} />}
            {currentTab === TAB_BOARD_STATUSES && type === BOARD_TYPE_OVERVIEW && (
                <EditBoardOverviewStatusForm item={item} />
            )}
            {currentTab === TAB_BOARD_PERMISSIONS && <BoardPermisstionTab item={item} />}
        </TabsWrapper>
    );
};

export default EditBoardFormModal;
