import React, {FC, MouseEvent, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import {PermissionContext} from 'contexts/permission/context';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import {routes, PARAMETER_SUB, PARAMETER_TICKET} from 'config/index';

const AddFormDropdown: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const permissions = useContext(PermissionContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectClicked = (route: string) => {
        navigate(route);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            {permissions.isFormCreateEnabled && (
                <AgentAddMultipleButton title={t('orguser.forms.addForm')} onClick={handleClick} open={open} />
            )}
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                <MenuItem onClick={() => onSelectClicked(`${routes.FORM}/create`)}>
                    <Typography>{t('orguser.forms.addForm')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => onSelectClicked(`${routes.FORM}/create?${PARAMETER_SUB}=1`)}>
                    <Typography>{t('orguser.forms.addSubForm')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => onSelectClicked(`${routes.FORM}/create?${PARAMETER_TICKET}=1`)}>
                    <Typography>{t('orguser.forms.addTicketForm')}</Typography>
                </MenuItem>
            </MenuAnchorWrapper>
        </Box>
    );
};

export default AddFormDropdown;
