import React, {FC, useCallback, useContext, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';

import {Box, IconButton} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {REQUEST_CASE_DELETE_STACK} from 'appRedux/actions/admin';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import RequesterCaseBlock from 'components/RequesterCaseBlocks/RequesterCaseBlock';

import {theme} from 'config/theme';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

interface RequesterCaseStackType {
    items: RequesterCaseResponseTypes[];
    permittedUuids: string[];
    currentUserEmail: string | null;
    isSwimlaneBoard?: boolean;
}

import {
    PARAMETER_TAG,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_SEARCH,
    ITEMS_PER_PAGE_DESKTOP,
    PARAMETER_AGENT,
} from 'config/index';
import {t} from 'i18next';

const RequesterCaseStack: FC<RequesterCaseStackType> = ({items, permittedUuids, currentUserEmail, isSwimlaneBoard}) => {
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {isSwimlaneCasesPage, isBoardCasesPage} = useContext(RouteContext);

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);

    const values = {
        board: uuid,
        workflowStatusId: items[0].statusUuid,
        page: DEFAULT_PAGE,
        perPage: ITEMS_PER_PAGE_DESKTOP,
        agent: getParameterFromUrl(agentFromUrl),
        form: getParameterFromUrl(formFromUrl),
        tags: getParameterFromUrl(tagsFromUrl),
        search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        isBoardCasesPage,
        isSwimlaneCasesPage,
    };

    const deleteRequesterCaseStack = useCallback(
        data => dispatch({type: REQUEST_CASE_DELETE_STACK.REQUEST, payload: data}),
        [dispatch],
    );

    const [isStackOpened, setIsStackOpened] = useState(false);

    const toggleIsStackOpen = () => {
        setIsStackOpened(prev => !prev);
    };

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const toggleIsDeleteModalOpen = () => {
        setIsDeleteModalOpened(prev => !prev);
    };

    const onDeleteStackClicked = () => {
        const stack = items[0].stack;
        deleteRequesterCaseStack({
            uuid: stack.uuid,
            ...values,
            callback: () => {
                toggleIsDeleteModalOpen();
            },
            showAlert,
        });
    };

    const elRefs = useRef<HTMLDivElement[]>([]);

    return (
        <Box
            sx={{
                position: 'relative',
                ':hover': {
                    '& .addRow': {
                        mt: -0.5,
                    },
                    '& .deleteStack': {
                        visibility: 'initial',
                    },
                },
            }}
        >
            <IconButton
                size="small"
                className="deleteStack"
                disableRipple
                sx={{
                    position: 'absolute',
                    visibility: 'hidden',
                    top: -4,
                    right: -4,
                    zIndex: 11,
                    boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.07)',
                    backgroundColor: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: theme.palette.background.default,
                    },
                }}
                onClick={toggleIsDeleteModalOpen}
            >
                <CloseSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsDeleteModalOpen}
                isShowModal={isDeleteModalOpened}
                onClickAction={onDeleteStackClicked}
                customTitle={t('orguser.requesterCase.deleteStackModalTitle')}
            />
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 2,
                }}
            >
                {items.map((caseItem: RequesterCaseResponseTypes, i: number) => {
                    return (
                        <Box
                            key={`${caseItem.status}-${caseItem.caseId}`}
                            ref={(ref: HTMLDivElement) => {
                                elRefs.current[i] = ref;
                            }}
                            sx={{
                                position: 'relative',
                                visibility: !isStackOpened && i > 3 ? 'hidden' : 'initial',
                                zIndex: Math.max(10 - i, 5),
                                width: '100%',
                                mx: 0,
                                mt: !i
                                    ? 0
                                    : !isStackOpened
                                    ? `-${(elRefs.current[i]?.offsetHeight || 100) - (i > 3 ? 0 : 15)}px`
                                    : -0.5,
                                transition: 'all 0.2s ease-out',
                            }}
                            onClick={() => i && setIsStackOpened(true)}
                        >
                            <RequesterCaseBlock
                                item={caseItem}
                                permittedUuids={permittedUuids}
                                currentUserEmail={currentUserEmail}
                                disabled={!!i && !isStackOpened}
                                inStack
                                isSwimlaneBoard={isSwimlaneBoard}
                            />
                        </Box>
                    );
                })}

                <Box
                    onClick={toggleIsStackOpen}
                    className="addRow"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '0 0 8px 8px',
                        transition: 'all 0.2s ease-out',
                        mt: -3.5,
                        mb: 1,
                        width: `100%`,
                        mx: 0,
                        pt: 0.5,
                    }}
                >
                    {!isStackOpened ? (
                        <KeyboardArrowDownIcon sx={{color: theme.palette.info.light}} />
                    ) : (
                        <KeyboardArrowUpIcon sx={{color: theme.palette.info.light}} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default RequesterCaseStack;
