import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CREATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';
import {RouteContext} from 'contexts/route/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import CalendarSteps from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarSteps';

import {ERROR_TEXT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface ResourceFieldCalendarType {
    formId: number;
    isAgentPage: boolean;
    field: FormFieldTypes;
}

const ResourceFieldCalendar: FC<ResourceFieldCalendarType> = ({formId, isAgentPage, field}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);
    const {isRequesterFormAgentPage} = useContext(RouteContext);

    const [showCalendarSteps, setShowCalendarSteps] = useState<boolean>(false);

    const {id, resourceField, resourceFieldCalendar} = field;

    const createRequesterCaseCalendar = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCaseCalendars,
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const currentCalendar = currentCaseCalendars.find(item => item.formField === id);

    const onReserveClick = () => {
        if (resourceFieldCalendar) {
            createRequesterCaseCalendar({
                id: Number(requestCase),
                formField: id,
                resourceField,
                resourceFieldCalendar,
                showAlert,
                callback: () => {
                    setErrorField(null);
                },
            });
        } else {
            setShowCalendarSteps(true);
        }
    };

    const isDisabled = isRequesterFormAgentPage ? !isAgentPage : status !== STATUS_OPENING;

    return (
        <FormFieldWrapper wrapperRef={ref} formId={formId} field={field}>
            <Box>
                {currentCalendar || showCalendarSteps ? (
                    <CalendarSteps
                        field={field}
                        currentCalendar={currentCalendar}
                        isDisabled={isDisabled}
                        isRemovingDisabled={status !== STATUS_OPENING}
                    />
                ) : (
                    <Box sx={{width: '100%'}}>
                        <AgentSaveButton
                            isSubmit={false}
                            isDisabled={isDisabled}
                            title={t('common.buttons.reserve')}
                            onClick={onReserveClick}
                        />
                    </Box>
                )}
                {id === errorField && (
                    <Typography sx={{mt: 1, color: ERROR_TEXT_COLOR, fontSize: 12}}>
                        {t('requester.casePage.allCalendarStepsShouldBeFilled')}
                    </Typography>
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default ResourceFieldCalendar;
