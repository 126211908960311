import React, {DragEvent, FC, ReactNode, useContext} from 'react';

import Box from '@mui/material/Box';

import {AdminContext} from 'contexts/admin/context';

import {
    CONTENT_POPUP,
    DROP_PLACEHOLDDER_HEIGHT,
    FORM_FIELD_RESOURCE_FIELD,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {theme} from 'config/theme';

interface DragPlaceholderFormBlockType {
    dropType: string;
    isPopup?: boolean;
    onDrop: (index?: number) => void;
    index?: number;
    children?: ReactNode;
}

const DragPlaceholderFormBlock: FC<DragPlaceholderFormBlockType> = ({dropType, isPopup, onDrop, index, children}) => {
    const {isDragStart, setDropType, dragType} = useContext(AdminContext);

    const skipPopup =
        (isPopup && dragType === CONTENT_POPUP) || (isPopup && String(dragType) === String(FORM_FIELD_RESOURCE_FIELD));
    const isDroppable = isDragStart && !skipPopup;

    const onDragEnter = () => {
        setDropType(dropType);
    };

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                height: isDroppable || React.Children.count(children) ? DROP_PLACEHOLDDER_HEIGHT : 0,
                border: isDroppable ? `2px dashed ${theme.palette.secondary.contrastText}` : 'none',
                borderRadius: 2,
                pointerEvents: skipPopup ? 'none' : 'auto',
            }}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={isDroppable ? () => onDrop(index) : undefined}
        >
            {isDroppable ? null : children}
        </Box>
    );
};

export default DragPlaceholderFormBlock;
