import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {PermissionContext} from 'contexts/permission/context';
import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import EmptyArea from 'components/EmptyArea';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import TextWithMentions from 'components/Comments/TextWithMentions';
import TextWithLineBreaks from 'components/Comments/TextWithLineBreaks';

import RemoveComment from 'pages/agent/requesterPage/common/RemoveComment';
import UpdateComment from 'pages/agent/requesterPage/common/UpdateComment';
import {getCommentTypeLabel} from 'pages/agent/requesterPage/common/helper';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {decryptStringWithKey} from 'helpers/cryptoApiHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {theme} from 'config/theme';

interface CommentRowType {
    item: RequesterCaseCommentsTypes;
    inRightPanel?: boolean;
}

const CommentEncryptRow: FC<CommentRowType> = ({item, inRightPanel}) => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {avatarLink, section, avatarType, uuid, text, dateAndTime, author, isUpdated, isPublic, isCurrentUser} = item;

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);
    const {keys} = useContext(CryptoContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [value, setValue] = useState<string>('');

    let authorFirstName: string | null = null;
    let authorLastName: string | null = null;
    const authorNameArray = author ? author.split(', ') : [];
    if (authorNameArray.length > 1) {
        authorFirstName = authorNameArray[1];
        authorLastName = authorNameArray[0];
    }

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (text && keys && unwrappedCaseKey) {
                const decodedMessage = await decryptStringWithKey(text, unwrappedCaseKey);
                setValue(decodedMessage);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [text]);

    const isLineBreakPresented = text.includes('\n');

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            {!inRightPanel && (
                <AvatarImage
                    size={22}
                    avatarLink={avatarLink}
                    avatarType={avatarType}
                    avatarTitle={getAvatarTitle(authorFirstName, authorLastName, author)}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    backgroundColor:
                        section || inRightPanel ? `rgba(34, 34, 34, 0.05)` : theme.palette.background.default,
                    borderRadius: 2,
                    ml: 2,
                    mb: 2,
                    p: 2,
                    pt: 1,
                    pb: 1,
                }}
            >
                <Typography sx={{mb: 0.5, fontWeight: 600}}>{author}</Typography>
                {isLineBreakPresented ? <TextWithLineBreaks text={value} /> : <TextWithMentions text={value} />}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mt: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 600, fontSize: 12}}>
                        {convertDateToCurrentLocale(dateAndTime)}
                    </Typography>
                    {(isUpdated || isPublic) && (
                        <Typography sx={{ml: 1, fontSize: 12, fontWeight: 300}}>
                            {getCommentTypeLabel(t, isUpdated, isPublic)}
                        </Typography>
                    )}
                    {!inRightPanel && isCurrentUser && isEditEnabled && (
                        <>
                            <EmptyArea />
                            <UpdateComment item={item} />
                            <RemoveComment uuid={uuid} />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CommentEncryptRow;
