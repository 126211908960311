import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {BoardItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import BoardStatusRow from 'pages/admin/boardsList/statuses/BoardStatusRow';
import BoardStatusNewRow from 'pages/admin/boardsList/statuses/BoardStatusNewRow';

interface BoardTypeBoardStatusesListTypes {
    item: BoardItemTypes;
}

const BoardTypeBoardStatusesList: FC<BoardTypeBoardStatusesListTypes> = ({item}) => {
    const [t] = useTranslation();

    const {workflowId, statuses, uuid} = item;

    const {
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentWorkflow = organizationWorkflows
        ? organizationWorkflows.find(workflowItem => workflowItem.id === workflowId)
        : null;

    if (!currentWorkflow) return null;

    const {workflowStatuses} = currentWorkflow;

    return (
        <Box sx={{mt: 2}}>
            {workflowStatuses &&
                workflowStatuses.map((workflowStatus: WorkflowStatusItemTypes) => {
                    const status = statuses.find(status => status.statusUuid === workflowStatus.uuid);
                    if (status) {
                        return <BoardStatusRow key={`board-status-${status.statusUuid}`} item={status} />;
                    }
                    return (
                        <BoardStatusNewRow
                            key={`board-status-${workflowStatus.uuid}`}
                            item={workflowStatus}
                            boardUuid={uuid}
                        />
                    );
                })}
        </Box>
    );
};

export default BoardTypeBoardStatusesList;
