import React, {FC} from 'react';

import Box from '@mui/material/Box';

import TextWithMentions from 'components/Comments/TextWithMentions';

interface TextWithLineBreaksType {
    text: string;
    forClient?: boolean;
}

const TextWithLineBreaks: FC<TextWithLineBreaksType> = ({text, forClient}) => {
    return (
        <Box sx={{mt: 1, mb: 1}}>
            {text.split('\n').map((item: string, index: number) => (
                <TextWithMentions key={`text-row-${index}`} text={item} forClient={forClient} />
            ))}
        </Box>
    );
};

export default TextWithLineBreaks;
