import React, {FC} from 'react';
import get from 'lodash/get';
import {FieldProps} from 'formik';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

const styles = () =>
    createStyles({
        input: {
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface FormikTextInputTypes extends WithStyles<typeof styles> {
    name: string;
    label?: string;
    placeholder?: string;
    prefix?: string;
    onFieldValueChange: (e: React.ChangeEvent<any>) => void;
}

const FormBuilderTextInput: FC<FieldProps & FormikTextInputTypes> = ({
    classes,
    field,
    form: {touched, errors},
    name,
    prefix,
    onFieldValueChange,
    ...props
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <Box sx={{width: '100%'}}>
            <FormControl fullWidth>
                <AutoDirectionTextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    inputProps={{
                        className: classes.input,
                    }}
                    InputProps={{
                        startAdornment: prefix && (
                            <InputAdornment
                                position="start"
                                sx={{
                                    padding: '3px 0 5px',
                                    height: '32px',
                                    mr: '0px',
                                }}
                            >
                                {prefix}
                            </InputAdornment>
                        ),
                    }}
                    id={name}
                    {...field}
                    {...props}
                    onChange={e => {
                        field.onChange(e);
                        onFieldValueChange && onFieldValueChange(e);
                    }}
                />
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(FormBuilderTextInput);
