import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';

import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

interface TranslationSelectorType {
    selectedLanguage: string;
    setSelectedLanguage: (value: string) => void;
    languages: OrganizationLanguageTypes[];
    organizationLanguage: LanguageItemTypes;
}

const TranslationSelector: FC<TranslationSelectorType> = ({
    selectedLanguage,
    setSelectedLanguage,
    organizationLanguage,
    languages,
}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: string) => {
        setSelectedLanguage(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const availableLanguages = languages ? languages.filter(item => item.isLanguageEnabled) : [];

    if (availableLanguages.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton
                title={`${t('orguser.forms.selectLanguage')} - ${selectedLanguage}`}
                onClick={handleClick}
                open={open}
            />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                <MenuItem
                    value={organizationLanguage.name}
                    key={`form-language-${organizationLanguage.name}`}
                    onClick={() => handleChange(organizationLanguage.name)}
                >
                    {organizationLanguage.lang}
                </MenuItem>
                {languages &&
                    languages.map((item: OrganizationLanguageTypes) => {
                        const {
                            isLanguageEnabled,
                            isActive,
                            isAllMainTranslationsPresented,
                            languageTitle,
                            languageName,
                        } = item;
                        if (languageName !== organizationLanguage.name && isLanguageEnabled && isActive) {
                            return (
                                <MenuItem
                                    value={languageName}
                                    key={`form-language-${languageName}`}
                                    onClick={() => handleChange(languageName)}
                                >
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={isAllMainTranslationsPresented}
                                        sx={{
                                            '& span': {
                                                right: '-5px',
                                            },
                                        }}
                                    >
                                        <Typography>{languageTitle}</Typography>
                                    </Badge>
                                </MenuItem>
                            );
                        }
                    })}
            </MenuAnchorWrapper>
        </>
    );
};

export default TranslationSelector;
