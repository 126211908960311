import React, {FC, useState, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import EmptyArea from 'components/EmptyArea';

interface RightPanelSectionType {
    title: string;
    subtitle?: number;
    headerInfo?: ReactNode;
    children: ReactNode;
}

const RightPanelSectionWrapper: FC<RightPanelSectionType> = ({title, subtitle, headerInfo, children}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };

    return (
        <Box sx={{m: 3}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {Number(subtitle) > 0 && <Typography sx={{ml: 3, fontSize: 12}}>{subtitle}</Typography>}
                <EmptyArea />
                {headerInfo}
                <IconButton onClick={toggleExpanded}>{isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}</IconButton>
            </Box>
            {isExpanded ? children : null}
            <Divider sx={{mt: 2}} />
        </Box>
    );
};

export default RightPanelSectionWrapper;
