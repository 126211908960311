import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const HomeSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill={getIconColor(isActive, isDisabled)} fillOpacity="0.01" />
            <path
                d="M8.30615 1.10693C8.2174 1.03768 8.10805 1.00006 7.99547 1.00006C7.8829 1.00006 7.77355 1.03768 7.6848 1.10693L0.5 6.70973L1.12135 7.49558L2 6.81048V13C2.00054 13.2651 2.10607 13.5191 2.29349 13.7065C2.48091 13.894 2.73495 13.9995 3 14H13C13.2651 13.9995 13.5191 13.894 13.7066 13.7066C13.894 13.5192 13.9995 13.2651 14 13V6.81503L14.8786 7.50003L15.5 6.71413L8.30615 1.10693ZM9 13H7V9.00003H9V13ZM10 13V9.00003C9.9997 8.73491 9.89424 8.48073 9.70677 8.29326C9.5193 8.10579 9.26512 8.00034 9 8.00003H7C6.73486 8.0003 6.48066 8.10574 6.29319 8.29322C6.10571 8.4807 6.00026 8.7349 6 9.00003V13H3V6.03078L8 2.13578L13 6.03603V13H10Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default HomeSvgIcon;
