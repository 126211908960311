import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_GDPR_FIELDS_GET} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {getIsNoneGdprField} from 'pages/admin/updateForm/partials/FormGdprFieldsStatistic/helper';
import FormRightSideDetailsPanelWrapper from 'pages/admin/updateForm/partials/FormRightSideDetailsPanelWrapper';

interface FormGdprFieldsStatisticType {
    formId: number;
}

const FormGdprFieldsStatistic: FC<FormGdprFieldsStatisticType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getFormGdprStatistic = useCallback(
        data => dispatch({type: FORM_GDPR_FIELDS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {gdprFields},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getFormGdprStatistic({
            id: formId,
            showAlert,
        });
    }, [formId]);

    if (!gdprFields) return null;

    const isNoneGdprField = getIsNoneGdprField(gdprFields);

    return (
        <FormRightSideDetailsPanelWrapper title={t('orguser.forms.gdprStatistic')}>
            {isNoneGdprField ? (
                <>
                    {Object.entries(gdprFields).map(([key, value]) => {
                        if (value === 0) return null;
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                key={`gdpr-${key}`}
                            >
                                <Typography sx={{fontWeight: 700, fontSize: 14, mr: 1}}>{`${value}  X`}</Typography>
                                <Typography sx={{fontSize: 14}}>{`${t(
                                    `orguser.forms.fieldGdprSettings.${key}`,
                                )}`}</Typography>
                                <DescriptionPopover text={t(`orguser.forms.fieldGdprSettings.${key}Explanation`)} />
                            </Box>
                        );
                    })}
                </>
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </FormRightSideDetailsPanelWrapper>
    );
};

export default FormGdprFieldsStatistic;
