import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {GET_MENU_BOARDS_LIST, SET_CURRENT_BOARD} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';
import {BoardMenuItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';
import {AdminContext} from 'contexts/admin/context';

import SwimlaneBoardCases from 'pages/agent/swimlaneView/SwimlaneBoardCases';
import RequestersList from 'pages/agent/tableView/RequestersList';

import {VIEW_MODE_TABLE} from 'helpers/filter';

import {PARAMETER_VIEW_MODE} from 'config/index';

const SwimlaneBoardComponent: FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle, setCustomBoardTitle} =
        useContext(RouteContext);
    const {setShowFiltersPanel} = useContext(AdminContext);

    const [currentBoard, setCurrentBoard] = useState<BoardMenuItemTypes | null>(null);

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const setCurrentBoardState = useCallback(data => dispatch({type: SET_CURRENT_BOARD, payload: data}), [dispatch]);

    const {
        workflow: {boardsMenu},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(null);
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        const params: [string, string][] = [];

        const current = boardsMenu.find((item: BoardMenuItemTypes) => {
            return item.uuid === uuid;
        });

        if (current) {
            for (const entry of searchParams.entries()) {
                params.push(entry);
            }

            setTopBarTitle(current.title);
            setCurrentBoard(current);
            setCustomBoardTitle(current.title);
            setCurrentBoardState({id: current.uuid, params});
        } else {
            setTopBarTitle('');
        }
    }, [uuid, boardsMenu, searchParams]);

    useEffect(() => {
        if (isCaseViewEnabled) {
            getMenuBoardsList({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        setShowFiltersPanel(false);
    }, [uuid]);

    if (!currentBoard) return null;

    if (viewModeFromUrl === VIEW_MODE_TABLE) {
        return <RequestersList isTableView />;
    }
    return <SwimlaneBoardCases currentBoard={currentBoard} />;
};

export default SwimlaneBoardComponent;
