import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {withStyles, WithStyles} from '@material-ui/styles';

import styles from 'components/TextWithShowAll/styles';

import {MINIMAL_HEIGHT_FOR_READ_MORE} from 'config/index';

interface TextWithShowAllType extends WithStyles<typeof styles> {
    text: string | JSX.Element | JSX.Element[];
    onTextClick?: () => void;
    isTable?: boolean;
    fontSize?: number;
    ['data-e2e-id']?: string;
}

const TextWithShowAll: FC<TextWithShowAllType> = ({
    classes,
    text,
    isTable,
    fontSize,
    onTextClick,
    ['data-e2e-id']: dataE2eId,
}) => {
    const [t] = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    const [showAll, setShowAll] = useState<boolean>(true);
    const [needShowAll, setNeedShowAll] = useState<boolean>(false);

    useEffect(() => {
        if (ref && ref.current) {
            if (ref.current.offsetHeight >= MINIMAL_HEIGHT_FOR_READ_MORE) {
                setNeedShowAll(true);
                setShowAll(false);
            }
        }
    }, [ref]);

    const toggleShowAll = () => {
        setShowAll(previous => !previous);
    };

    const descriptionShowAllClassname = isTable ? classes.descriptionTable : classes.description;

    return (
        <Box>
            <Typography
                align="justify"
                data-e2e-id={dataE2eId}
                ref={ref}
                className={showAll ? classes.descriptionShowAll : descriptionShowAllClassname}
                variant="body2"
                sx={{
                    fontSize: fontSize ? fontSize : 'initial',
                    cursor: onTextClick ? 'pointer' : 'initial',
                    fontWeight: 300,
                    '& p': {
                        margin: 0,
                    },
                }}
                onClick={onTextClick}
            >
                {text}
            </Typography>
            {needShowAll && (
                <Typography
                    variant="body2"
                    data-e2e-id={showAll ? `${dataE2eId}-readLess` : `${dataE2eId}-continueReading`}
                    onClick={toggleShowAll}
                    sx={{mt: 1, fontSize: fontSize ? fontSize : 'initial', cursor: 'pointer', fontWeight: 600}}
                >
                    {t(showAll ? 'common.readLess' : 'common.continueReading')}
                </Typography>
            )}
        </Box>
    );
};

export default withStyles(styles)(TextWithShowAll);
