import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FORM_LABEL_UPDATE} from 'appRedux/actions/forms';
import {FormLabelTypes, FormLabelFormTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/FormBuilder/FormLabelForm/validation';

interface UpdateFormLabelFormType {
    item: FormLabelTypes;
    closeModal: () => void;
}

const UpdateFormLabelForm: FC<UpdateFormLabelFormType> = ({item, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateFormLabel = useCallback(data => dispatch({type: FORM_LABEL_UPDATE.REQUEST, payload: data}), [dispatch]);

    const {title, uuid} = item;

    const onSubmitAction = (values: FormLabelFormTypes) => {
        updateFormLabel({
            uuid,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                title,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={values => {
                onSubmitAction({
                    ...values,
                });
            }}
        >
            {(formik: FormikProps<FormLabelFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.forms.formLabels.title')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UpdateFormLabelForm;
