import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface FormInformationRowWrapperType {
    title: string;
    subtitle?: string;
    isSelector?: boolean;
    alignTop?: boolean;
    children: ReactNode;
}

const FormInformationRowWrapper: FC<FormInformationRowWrapperType> = ({
    title,
    subtitle,
    isSelector,
    alignTop,
    children,
}) => {
    const [t] = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: alignTop ? 'flex-start' : 'center',
                mb: isSelector ? 2 : 3,
            }}
        >
            <Box sx={{minWidth: 110}}>
                <Typography>{`${t(title)}:`}</Typography>
                {subtitle && <Typography sx={{fontWeight: 600, fontSize: 14}}>{subtitle}</Typography>}
            </Box>
            <Box>{children}</Box>
        </Box>
    );
};

export default FormInformationRowWrapper;
