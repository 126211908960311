import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {WorkflowStatusTaskItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {ACTIVATE_WORKFLOW_STATUS_TASK} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {
    getWorkflowTaskLabel,
    getWorkflowTaskLabelDetails,
    WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING,
} from 'components/Forms/Workflow/workflowStatusTask/helper';
import EmptyArea from 'components/EmptyArea';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

import DeleteWorkflowTaskButton from 'pages/admin/updateWorkflow/partials/tasks/DeleteWorkflowTaskButton';
import WorkflowTaskSendEmailDetails from 'pages/admin/updateWorkflow/partials/tasks/WorkflowTaskSendEmailDetails';
import UpdateWorkflowTaskSwitcher from 'pages/admin/updateWorkflow/partials/tasks/UpdateWorkflowTaskSwitcher';
import {checkEmailSendingTaskCompleted} from 'pages/admin/updateWorkflow/partials/tasks/helper';

import {getLanguageById} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface WorkflowTasksListItemType {
    item: WorkflowStatusTaskItemTypes;
    status: WorkflowStatusItemTypes;
}

const WorkflowTasksListItem: FC<WorkflowTasksListItemType> = ({item, status}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const itemRef = useRef<HTMLDivElement>();

    const {showAlert} = useContext(AlertContext);

    const {isActive, isCreatedRightNow, uuid, type} = item;

    const [isTaskActive, setIsTaskActive] = useState<boolean>(isActive);
    const [showDetails, setShowDetails] = useState<boolean>(isCreatedRightNow);
    const [selectedLanguage, setSelectedLanguage] = useState<number>(0);

    const {
        admin: {organizationList, languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleDetails = () => {
        setShowDetails(previous => !previous);
    };

    const changeTaskStatus = useCallback(
        data => dispatch({type: ACTIVATE_WORKFLOW_STATUS_TASK.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        setIsTaskActive(!isTaskActive);
        changeTaskStatus({
            uuid,
            showAlert,
            callbackError: () => setIsTaskActive(false),
        });
    };

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;
    const myOrganizationLanguage = myOrganization ? getLanguageById(languageList, myOrganization.language) : null;

    useEffect(() => {
        if (myOrganizationLanguage) {
            setSelectedLanguage(myOrganizationLanguage.id);
        }
    }, [myOrganizationLanguage]);

    useEffect(() => {
        if (itemRef && itemRef.current && isCreatedRightNow) {
            itemRef.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        }
    }, [isCreatedRightNow]);

    if (!myOrganization || !myOrganizationLanguage) return null;

    return (
        <Box
            ref={itemRef}
            sx={{
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: 2,
                p: 2,
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mr: 2,
                    }}
                >
                    <Typography sx={{fontWeight: 700}}>{t(getWorkflowTaskLabel(type))}</Typography>
                    <Typography sx={{fontSize: 14, mt: 1}}>
                        {getWorkflowTaskLabelDetails(t, item, selectedLanguage)}
                    </Typography>
                </Box>
                <EmptyArea />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <CustomSwitcher checked={isActive} onClick={handleChangeStatus} />
                    <IconButton
                        onClick={toggleDetails}
                        title={t(showDetails ? 'common.buttons.close' : 'common.buttons.update')}
                    >
                        {showDetails ? <CloseSvgIcon /> : <EditSvgIcon />}
                    </IconButton>
                    <DeleteWorkflowTaskButton item={item} />
                </Box>
            </Box>
            {checkEmailSendingTaskCompleted(item) && (
                <Alert severity={'error'} sx={{mt: 2, mb: 2}}>
                    <Typography>{t('orguser.workflows.tasks.hasNotCompletedTasks')}</Typography>
                </Alert>
            )}
            {showDetails && (
                <>
                    <UpdateWorkflowTaskSwitcher item={item} status={status} />
                    {type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING && (
                        <WorkflowTaskSendEmailDetails
                            item={item}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                            myOrganizationLanguage={myOrganizationLanguage}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default WorkflowTasksListItem;
