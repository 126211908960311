import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';
import {Box, Tooltip} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import {truncate} from 'pages/admin/formsList/helper';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {EMPTY_DATA} from 'config/index';

const FormsSuperAdminDataGrid: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: t('orguser.forms.title'),
            minWidth: 400,
            renderCell: ({value}) => (
                <Tooltip
                    title={useParsedTranslation(t, String(value))}
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'rgb(97, 97, 97)',
                            },
                        },
                    }}
                >
                    <Box>{truncate(value, 30)}</Box>
                </Tooltip>
            ),
        },
        {
            field: 'organization',
            headerName: t('orguser.forms.organization'),
            type: 'singleSelect',
            valueOptions: [...new Set(formsList.map(({organization}) => organization ?? EMPTY_DATA))],
            minWidth: 150,
            valueGetter: ({value}) => value ?? EMPTY_DATA,
        },
    ];

    return (
        <CustomDataGrid
            rows={formsList}
            columns={columns}
            getRowId={row => row.id}
            checkboxSelection={false}
            paginationMode="server"
            sortingMode="server"
            enablePaging
            isLarge
        />
    );
};

export default FormsSuperAdminDataGrid;
