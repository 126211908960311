import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {useFormikContext} from 'formik';

import {RootReducer} from 'appRedux/reducers';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

interface OverwriteFilterType {
    boardId: number;
    setUpdatedFilter: (value: AgentSavedFilterTypes | null) => void;
}

const OverwriteFilter: FC<OverwriteFilterType> = ({boardId, setUpdatedFilter}) => {
    const [t] = useTranslation();
    const {setFieldValue} = useFormikContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const {
        admin: {savedFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filter = savedFilters.find(item => item.uuid === String(event.target.value));
        if (filter) {
            setUpdatedFilter(filter);
            setFieldValue('title', filter.title);
            setFieldValue('iconType', filter.iconType);
            setAnchorEl(null);
        }
    };

    const currentBoardFilters = savedFilters ? savedFilters.filter(savedFilter => savedFilter.board === boardId) : [];

    const open = Boolean(anchorEl);

    if (currentBoardFilters.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={t('orguser.savedFilters.overwriteFilter')} onClick={handleClick} open={open} />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                <RadioGroup onChange={handleChange}>
                    {currentBoardFilters.map((option: AgentSavedFilterTypes, index: number) => {
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.uuid}
                                control={<Radio />}
                                label={option.title}
                            />
                        );
                    })}
                </RadioGroup>
            </MenuAnchorWrapper>
        </>
    );
};

export default OverwriteFilter;
