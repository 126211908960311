import {useCallback, useContext, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {
    DataGrid,
    DataGridProps,
    gridClasses,
    GridColDef,
    GridColumnVisibilityModel,
    GridFeatureMode,
    GridFilterModel,
    GridPaginationModel,
    GridRowHeightReturnValue,
    GridRowParams,
    GridRowSelectionModel,
    GridRowsProp,
    GridSortDirection,
    GridSortModel,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid';
import {Box} from '@mui/material';

import {AdminContext} from 'contexts/admin/context';

import {getPerPageForRequest} from 'components/BlockView/helper';

import {
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    PARAMETER_VIEW_MODE,
} from 'config/index';
import {HIGHLIGHTED_SECTION_COLOR} from 'config/theme';

interface CustomDataGridProps<T extends GridValidRowModel> extends DataGridProps<T> {
    rows: GridRowsProp<T>;
    columns: GridColDef[];
    enablePaging?: boolean;
    perPage?: number;
    rowSelectable?: (params: GridRowParams) => boolean;
    checkBoxSelection?: boolean;
    disableVirtualization?: boolean;
    initialSort?: GridSortModel;
    columnVisibilityModel?: GridColumnVisibilityModel;
    isLarge?: boolean;
    disableSortable?: boolean;
    paginationMode?: GridFeatureMode;
    sortingMode?: GridFeatureMode;
}

const getPageSize = (rowsNumber: number, perPage?: string | number, enablePaging?: boolean): number => {
    let result = rowsNumber;
    if (enablePaging && perPage) {
        result = Number(perPage);
    }
    return result < 100 ? result : ITEMS_PER_PAGE_OPTIONS_DEFAULT[0];
};

export const CustomDataGrid = <T extends GridValidRowModel>({
    rows,
    columns,
    enablePaging,
    rowSelectable,
    checkBoxSelection,
    disableVirtualization = true,
    initialSort,
    columnVisibilityModel,
    isLarge,
    disableSortable,
    paginationMode = 'client',
    sortingMode = 'client',
    sx,
    ...otherGridProps
}: CustomDataGridProps<T>) => {
    const apiRef = useGridApiRef();

    const [searchParams, setSearchParams] = useSearchParams();
    const {itemsPerPage, currentPage, selectedIds, setSelectedIds, setTotalItems} = useContext(AdminContext);

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const sortByFromUrl = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeFromUrl = searchParams.get(PARAMETER_SORT_TYPE);

    useEffect(() => {
        if (sortByFromUrl && sortTypeFromUrl) {
            setSortModel([
                {
                    field: sortByFromUrl,
                    sort: sortTypeFromUrl.toLowerCase() as GridSortDirection,
                },
            ]);
        }
    }, [sortByFromUrl, sortTypeFromUrl]);

    const perPage = getPerPageForRequest(
        perPageFromUrl,
        viewModeFromUrl,
        itemsPerPage || ITEMS_PER_PAGE_OPTIONS_DEFAULT[0],
    );

    const getRowHeight = useCallback(() => {
        return 'auto' as GridRowHeightReturnValue;
    }, []);

    const defaultSort: GridSortModel = [];
    const [sortModel, setSortModel] = useState<GridSortModel>(initialSort ?? defaultSort);

    const updateSortModel = (sortModel: GridSortModel) => {
        if (paginationMode === 'server') {
            const parameter = sortModel[0]?.field || '';
            const type = sortModel[0]?.sort?.toUpperCase() || '';
            searchParams.set(PARAMETER_SORT_BY, parameter);
            searchParams.set(PARAMETER_SORT_TYPE, type);
            setSearchParams(searchParams);
        }
        setSortModel(sortModel);
    };

    const defaultFilter: GridFilterModel = {items: []};
    const [filterModel, setFilterModel] = useState<GridFilterModel>(defaultFilter);

    const updateRowsCount = () => {
        const filteredRowsCount = Object.values(apiRef.current.state.filter.filteredRowsLookup).reduce(
            (acc, item) => (item ? acc + 1 : acc),
            0,
        );
        setTotalItems(filteredRowsCount);
    };

    const updateFilterModel = (filterModel: GridFilterModel) => {
        if (paginationMode === 'server') return;
        setTimeout(() => {
            updateRowsCount();
        }, 100);

        searchParams.set(PARAMETER_PAGE, String(1));
        setSearchParams(searchParams);
        setFilterModel(filterModel);
    };

    useEffect(() => {
        if (paginationMode === 'server') return;
        updateRowsCount();
    }, [rows, enablePaging]);

    const paginationModel: GridPaginationModel = {
        page: currentPage - 1,
        pageSize: getPageSize(rows.length, perPage, enablePaging),
    };

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        selectedIds && setSelectedRows(selectedIds);
    }, [selectedIds]);

    const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
        setSelectedIds(ids.map(id => Number(id)));
    };

    const mappedColumns = columns
        .map(column => (column.width || column.flex ? column : {...column, flex: 1}))
        .map(column => (disableSortable ? {...column, sortable: false} : column));

    console.log('paginationModel', paginationModel);

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <DataGrid
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        // is used in combination with getRowHeight
                        py: isLarge ? 2 : 1,
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        fontWeight: '600',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '168px !important',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                        outline: 'none !important',
                    },
                    '& .highlighted': {
                        backgroundColor: HIGHLIGHTED_SECTION_COLOR,
                    },
                    minHeight: '114px',
                    ...sx,
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel,
                    },
                }}
                apiRef={apiRef}
                rows={rows}
                columns={mappedColumns}
                sortModel={sortModel}
                onSortModelChange={updateSortModel}
                pagination={enablePaging || undefined}
                paginationModel={paginationModel}
                paginationMode={paginationMode}
                sortingMode={sortingMode}
                onFilterModelChange={updateFilterModel}
                filterModel={filterModel}
                checkboxSelection={checkBoxSelection ?? true}
                disableRowSelectionOnClick
                isRowSelectable={rowSelectable}
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={onRowSelectionModelChange}
                getRowHeight={getRowHeight}
                disableVirtualization={disableVirtualization}
                hideFooter
                {...otherGridProps}
            />
        </Box>
    );
};
