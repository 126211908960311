import React, {FC, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ResourceFieldItemTypes} from 'appRedux/actions/resourceFields/types';

import {PermissionContext} from 'contexts/permission/context';

import {routes} from 'config/index';

interface RelatedFormsListType {
    item: ResourceFieldItemTypes;
}

const RelatedFormsList: FC<RelatedFormsListType> = ({item}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {isFormEditEnabled} = useContext(PermissionContext);

    const {relatedForms} = item;

    const redirectToForm = (id: number) => {
        if (isFormEditEnabled) {
            navigate(`${routes.FORM}/${id}/edit`);
        } else {
            navigate(`${routes.FORM}/${id}/view`);
        }
    };

    if (relatedForms.length === 0) {
        return <Typography>{t('common.none')}</Typography>;
    }

    return (
        <Box>
            {relatedForms.map(form => {
                const {id, title} = form;
                return (
                    <Typography
                        key={`form-title-${id}`}
                        sx={{fontWeight: 700, cursor: 'pointer'}}
                        onClick={() => redirectToForm(id)}
                    >
                        {title}
                    </Typography>
                );
            })}
        </Box>
    );
};

export default RelatedFormsList;
