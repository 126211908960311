import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {Loader} from 'components/index';
import 'react-datepicker/dist/react-datepicker.css';
import {createRoot} from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';

import 'assets/css/index.css';
import 'assets/fonts/fonts.css';

import './sentry';
import App from 'router/index';

import {setConsoleLogDebugMode} from 'helpers/debug';

import 'services/translations/i18n';
import i18n from 'services/translations/i18n';

import {registerServiceWorker} from './registerServiceWorker';
import CustomThemeProvider from 'components/CustomThemeProvider/CustomThemeProvider';

import {store} from 'appRedux/store';

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(domNode);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Loader />}>
                    <CustomThemeProvider>
                        <App />
                    </CustomThemeProvider>
                </Suspense>
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,
);

registerServiceWorker();
setConsoleLogDebugMode();
