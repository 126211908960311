import React, {FC, useState, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';

import OrgUsersSvgIcon from 'assets/icons/buttons/OrgUsersSvgIcon';

import {ImpersonatedCaseDetailsTypes} from 'appRedux/actions/requestCase/types';

import ImpersonatedCaseDetailsItem from 'components/AgentScreenComponents/_caseBlock/ImpersonatedCaseDetailsItem';

import {theme, TAG_COLOR_10} from 'config/theme';

interface ImpersonatedCaseDetailsButtonType {
    details: ImpersonatedCaseDetailsTypes;
}

const ImpersonatedCaseDetailsButton: FC<ImpersonatedCaseDetailsButtonType> = ({details}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {firstName, lastName, email, reason} = details;

    const open = Boolean(anchorEl);

    if (details.isClaimed) return null;

    return (
        <>
            <IconButton
                title={t('orguser.requesterCase.impersonatedCases.title')}
                onClick={handleClick}
                sx={{
                    backgroundColor: TAG_COLOR_10,
                    mr: 1,
                    '&:hover': {
                        backgroundColor: TAG_COLOR_10,
                    },
                }}
            >
                <OrgUsersSvgIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <ImpersonatedCaseDetailsItem
                    label={'orguser.requesterCase.impersonatedCases.parameters.firstName'}
                    value={firstName}
                />
                <Divider sx={{mt: 1, mb: 1}} />
                <ImpersonatedCaseDetailsItem
                    label={'orguser.requesterCase.impersonatedCases.parameters.lastName'}
                    value={lastName}
                />
                <Divider sx={{mt: 1, mb: 1}} />
                <ImpersonatedCaseDetailsItem
                    label={'orguser.requesterCase.impersonatedCases.parameters.email'}
                    value={email}
                />
                <Divider sx={{mt: 1, mb: 1}} />
                <ImpersonatedCaseDetailsItem
                    label={'orguser.requesterCase.impersonatedCases.parameters.reason'}
                    value={reason}
                    isText
                />
            </Menu>
        </>
    );
};

export default ImpersonatedCaseDetailsButton;
