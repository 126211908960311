import React, {FC, useContext, useRef, useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import {FormHelperText, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    DELETE_REQUESTER_SIGNATURE,
    GET_REQUESTER_CASE_POPUPS,
    UPLOAD_REQUESTER_SIGNATURE,
} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {DeleteRequesterSignatureTypes, UploadRequesterSignatureTypes} from 'appRedux/actions/requestCase/types';
import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {RESEND_EMAIL} from 'appRedux/actions/profile';

import ScaleSvgIcon from 'assets/icons/buttons/ScaleSvgIcon';

import {ClientFormContext} from 'contexts/clientForm/context';
import {MediaContext} from 'contexts/media/context';
import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {base64ToFile} from 'pages/client/form/fieldTypes/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';
import {RequestFullscreenElement, checkIfFullscreen, exitFullScreen, requestFullscreen} from 'helpers/fulscreen';
import {createPortal} from 'react-dom';

const FormSignature: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    disabled,
    previewMode,
    popupItemId,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isMobile, isTablet} = useContext(MediaContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [showVerification, setShowVerification] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isFullscreen, setIsFullScreen] = useState<boolean>(checkIfFullscreen());

    const toggleVerificationModal = () => {
        setShowVerification(previous => !previous);
    };

    const {id, label} = field;

    const {
        admin: {
            formInfo: {translations},
        },
        profile: {profile},
        requestCase: {
            currentCase: {signatures, popupItems},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, ['isVerify'], false);
    const resendEmailAction = useCallback(data => dispatch({type: RESEND_EMAIL.REQUEST, payload: data}), [dispatch]);

    const onSendAgainClicked = () => {
        resendEmailAction({
            showAlert,
            callback: () => {
                setShowVerification(false);
            },
        });
    };

    const ref = useRef<HTMLDivElement | undefined>();
    const canvasRef = useRef<any>();
    const fullscreenCanvasRef = useRef<any>();

    const openFullScreen = async () => {
        setIsFullScreen(true);
        try {
            await requestFullscreen(document.body as RequestFullscreenElement);
            setTimeout(async () => {
                try {
                    window.screen.orientation?.['lock'] && (await window.screen.orientation['lock']('landscape'));
                } catch (e) {
                    console.log(e);
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }
    };

    const closeFullScreen = async () => {
        setIsFullScreen(false);
        try {
            window.screen.orientation?.['unlock'] && window.screen.orientation['unlock']();
            exitFullScreen();
        } catch (e) {
            console.log(e);
        }
    };

    const submitUploadSignature = useCallback(
        (data: UploadRequesterSignatureTypes) => dispatch({type: UPLOAD_REQUESTER_SIGNATURE.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteSignature = useCallback(
        (data: DeleteRequesterSignatureTypes) => dispatch({type: DELETE_REQUESTER_SIGNATURE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCasePopups = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_POPUPS.REQUEST, payload: data}),
        [dispatch],
    );

    const [signature, setSignature] = useState<RequesterSignatureResponseType | null>();

    // Function to validate if the signature canvas has enough content
    function isCanvasValid(canvas: HTMLCanvasElement, blackPixelThreshold = 1000) {
        if (!canvas) return false;
        const ctx = canvas.getContext('2d');
        if (!ctx) return false;
        const {width, height} = canvas;
        const imageData = ctx.getImageData(0, 0, width, height).data;

        let blackPixelCount = 0;

        for (let i = 0; i < imageData.length; i += 4) {
            const r = imageData[i];
            const g = imageData[i + 1];
            const b = imageData[i + 2];
            const a = imageData[i + 3];

            // Count only fully black, non-transparent pixels
            if (r === 0 && g === 0 && b === 0 && a > 0) {
                blackPixelCount++;
                if (blackPixelCount >= blackPixelThreshold) return true; // Stop early if valid
            }
        }

        return false;
    }

    useEffect(() => {
        if (isPopup && popupItemId) {
            const currentItem = popupItems.find(item => item.id === popupItemId);
            console.log(currentItem);
            if (currentItem && currentItem.signatures && Array.isArray(currentItem.signatures)) {
                const currentSignature = currentItem.signatures.find(
                    s => s.section === sectionId && s.field === field.id,
                );
                setSignature(currentSignature);
            }
        } else {
            if (signatures && Array.isArray(signatures)) {
                const currentSignature = signatures.find(s => s.section === sectionId && s.field === field.id);
                setSignature(currentSignature);
            }
        }
    }, [signatures, popupItems, popupItemId, isPopup]);

    const handleSave = () => {
        if (previewMode) return;
        const currentRef = isFullscreen ? fullscreenCanvasRef : canvasRef;
        console.log('isFullscreen', isFullscreen);

        // Validate the signature before saving
        const canvas = currentRef.current.getTrimmedCanvas();
        if (!isCanvasValid(canvas)) {
            setIsError(true);
            setErrorField && setErrorField(id);
            return;
        }

        const image: string = canvas.toDataURL('image/png');

        setIsError(false);
        setErrorField && setErrorField(null);

        const file = base64ToFile(image);

        submitUploadSignature({
            file,
            userId: profile?.id,
            caseId: Number(requestCase),
            formId,
            sectionId,
            fieldId: id,
            requesterCaseHasPopup: popupItemId,
            callback: () => {
                getRequesterCasePopups({
                    id: requestCase,
                    showAlert,
                });
            },
        });

        isFullscreen && closeFullScreen();
    };

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        disabled && canvasRef.current?.off();
        !disabled && canvasRef.current?.on();
    }, [disabled]);

    const clearCanvas = () => {
        fullscreenCanvasRef.current?.clear();
        canvasRef.current?.clear();
    };

    const handleDelete = () => {
        signature &&
            deleteSignature({
                uuid: signature.uuid,
                sectionId,
                caseId: Number(requestCase),
                callback: () => {
                    getRequesterCasePopups({
                        id: requestCase,
                        showAlert,
                    });
                },
            });
        clearCanvas();
    };

    const keywordLabel = getFieldKeyword(formId, id, 'label');

    return (
        <FormFieldWrapper
            data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            previewMode={previewMode}
            hasDivider
        >
            <Box
                sx={{
                    position: 'relative',
                    maxWidth: '1000px',
                    overflow: 'hidden',
                }}
            >
                <Typography
                    sx={{
                        color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        fontSize: 12,
                        mb: 1,
                    }}
                >
                    {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                        field.isRequired ? '*' : ''
                    }`}
                </Typography>
                {!signature && (isMobile || isTablet) && (
                    <IconButton
                        title={t('requester.casePage.fullScreenMode')}
                        onClick={openFullScreen}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            svg: {
                                width: '32px',
                                height: '32px',
                            },
                        }}
                    >
                        <ScaleSvgIcon />
                    </IconButton>
                )}
                <Box
                    sx={{
                        mb: 1,
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: isMobile
                            ? signature
                                ? '300px'
                                : '100px'
                            : isTablet
                            ? signature
                                ? '350px'
                                : '100px'
                            : '400px',
                        pb: '50px',
                    }}
                >
                    {signature ? (
                        <img
                            src={signature.fileLink}
                            style={{maxWidth: '100%', maxHeight: isMobile ? '220px' : isTablet ? '270px' : '320px'}}
                            alt="Signature"
                        />
                    ) : null}
                    <Box
                        sx={{
                            width: '100%',
                            display: signature || isMobile || isTablet || disabled ? 'none' : 'block',
                        }}
                    >
                        <SignatureCanvas
                            penColor="black"
                            canvasProps={{width: 2000, height: 1500, className: 'sigCanvas'}}
                            ref={canvasRef}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
                {!disabled && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            bottom: 0,
                            right: 0,
                            borderTop: '1px solid #c4c4c4',
                            py: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {!signature && !(isMobile || isTablet) && (
                            <AgentInverseButton
                                onClick={signature ? handleDelete : clearCanvas}
                                title={t('common.buttons.clear')}
                            />
                        )}
                        {!(isMobile || isTablet) && !signature && (
                            <AgentSaveButton
                                onClick={!isUserVerified ? toggleVerificationModal : handleSave}
                                title={t('requester.casePage.saveSignature')}
                            />
                        )}
                        {signature && (
                            <AgentSaveButton onClick={handleDelete} title={t('requester.casePage.deleteSignature')} />
                        )}
                    </Box>
                )}
                {(isError || id === errorField) && (
                    <FormHelperText sx={{color: ERROR_TEXT_COLOR}}>
                        {t('messages.validation.signatureIsRequired')}
                    </FormHelperText>
                )}

                {createPortal(
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            display: isFullscreen ? 'block' : 'none',
                            zIndex: isFullscreen ? '10000' : '0',
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Box
                            sx={{
                                overflow: 'hidden',
                            }}
                        >
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{width: 2000, height: 1500, className: 'sigCanvas'}}
                                ref={fullscreenCanvasRef}
                                disabled={disabled}
                            />
                        </Box>
                        <IconButton
                            onClick={closeFullScreen}
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                svg: {
                                    width: '32px',
                                    height: '32px',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                bottom: 0,
                                right: 0,
                                borderTop: '1px solid #c4c4c4',
                                py: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <AgentInverseButton
                                onClick={signature ? handleDelete : clearCanvas}
                                title={t('common.buttons.clear')}
                            />
                            <AgentSaveButton
                                onClick={!isUserVerified ? toggleVerificationModal : handleSave}
                                title={t('requester.casePage.saveSignature')}
                            />
                        </Box>
                    </Box>,
                    document.body,
                )}
                {showVerification && (
                    <ModalWrapper
                        title={t('messages.error.fileAttachingDenied')}
                        isShowModal={showVerification}
                        toggleModal={toggleVerificationModal}
                    >
                        <Box>
                            <Typography sx={{color: theme.palette.info.main, mb: 2}}>
                                {`${t('messages.warning.verifyEmailToAllowUploads')}.`}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentSaveButton
                                    isSubmit={false}
                                    title={t('common.buttons.sendItAgain')}
                                    onClick={onSendAgainClicked}
                                />
                            </Box>
                        </Box>
                    </ModalWrapper>
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default FormSignature;
