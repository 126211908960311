import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {DELETE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface RemoveRequesterCaseCalendarButtonType {
    currentCalendar: RequesterCaseHasCalendarTypes;
}

const RemoveRequesterCaseCalendarButton: FC<RemoveRequesterCaseCalendarButtonType> = ({currentCalendar}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const deleteRequesterCaseHasCalendar = useCallback(
        data => dispatch({type: DELETE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const onDeleteClick = () => {
        deleteRequesterCaseHasCalendar({
            uuid: currentCalendar.uuid,
            showAlert,
            callback: () => {
                setIsShowModal(false);
            },
        });
    };

    return (
        <>
            <IconButton sx={{p: 0}} title={t('common.buttons.delete')} onClick={() => setIsShowModal(true)}>
                <CloseSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isShowModal}
                onClickAction={onDeleteClick}
            />
        </>
    );
};

export default RemoveRequesterCaseCalendarButton;
