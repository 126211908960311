import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {BOARD_PERMISSION_TYPE_LIMITED, getBoardPermissionTypeExplanation} from 'components/Forms/BoardForm/helper';

import AttachAgentToBoardButton from 'pages/admin/boardsList/permissions/AttachAgentToBoardButton';
import AttachGroupToBoardButton from 'pages/admin/boardsList/permissions/AttachGroupToBoardButton';
import AttachedGroupsList from 'pages/admin/boardsList/permissions/AttachedGroupsList';
import AttachedAgentsList from 'pages/admin/boardsList/permissions/AttachedAgentsList';

interface BoardPermissionTabType {
    item: BoardItemTypes;
}

const BoardPermissionTab: FC<BoardPermissionTabType> = ({item}) => {
    const [t] = useTranslation();

    const {typePermission} = item;

    return (
        <Box sx={{pb: 2}}>
            <Alert sx={{mt: 1, mb: 1}} severity={'info'}>
                <Typography>{t(getBoardPermissionTypeExplanation(typePermission))}</Typography>
            </Alert>
            {typePermission === BOARD_PERMISSION_TYPE_LIMITED && (
                <Box sx={{mt: 2}}>
                    <Typography sx={{fontWeight: 700, mb: 2}}>{`${t(
                        'orguser.boards.permittedForAgents',
                    )}:`}</Typography>
                    <AttachedAgentsList item={item} />
                    <AttachAgentToBoardButton item={item} />
                    <Divider sx={{mt: 2, mb: 2}} />
                    <Typography sx={{fontWeight: 700, mt: 1, mb: 2}}>{`${t(
                        'orguser.boards.permittedForGroups',
                    )}:`}</Typography>
                    <AttachedGroupsList item={item} />
                    <AttachGroupToBoardButton item={item} />
                </Box>
            )}
        </Box>
    );
};

export default BoardPermissionTab;
