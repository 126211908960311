import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import {RootReducer} from 'appRedux/reducers';
import {WorkflowEventAttachAgentFormTypes, WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';
import {ATTACH_WORKFLOW_EVENT_AGENT} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

interface WorkflowEventAttachAgentFormType {
    item: WorkflowStatusEventItemTypes;
    closeForm: () => void;
}

const WorkflowEventAttachAgentForm: FC<WorkflowEventAttachAgentFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {agents, uuid} = item;

    const {
        admin: {agentList},
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_WORKFLOW_EVENT_AGENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = (values: WorkflowEventAttachAgentFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const agentsNotAttached = agentList
        ? agentList
              .filter(item => !agents.some(agent => Number(item.id) === Number(agent.id)))
              .sort((a, b) => a.firstName?.localeCompare(b.firstName || '') || 0)
        : [];

    return (
        <Formik
            initialValues={{
                agent: 0,
            }}
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<WorkflowEventAttachAgentFormTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <Autocomplete
                                options={agentsNotAttached}
                                getOptionLabel={agent => {
                                    const {firstName, lastName, email} = agent;
                                    return firstName && lastName ? `${firstName} ${lastName}` : email;
                                }}
                                value={agentsNotAttached.find(agent => agent.id === formik.values.agent) || null}
                                onChange={(event, value) => formik.setFieldValue('agent', value ? value.id : '')}
                                fullWidth
                                popupIcon={<KeyboardArrowDownIcon />}
                                renderInput={params => (
                                    <AutoDirectionTextField
                                        {...params}
                                        label={t('orguser.workflows.workflowStatusEventParameters.selectAgent')}
                                        name="agent"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                renderOption={(props, option) => {
                                    const {id, avatarType, avatarLink, firstName, lastName, email} = option;
                                    const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                    return (
                                        <Box
                                            component="li"
                                            {...props}
                                            key={id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                mb: 1,
                                            }}
                                        >
                                            <AvatarImage
                                                size={25}
                                                avatarType={avatarType}
                                                avatarLink={avatarLink}
                                                avatarTitle={avatarTitle}
                                            />
                                            <Typography sx={{ml: 1, fontWeight: 600}}>
                                                {firstName && lastName ? `${firstName} ${lastName}` : email}
                                            </Typography>
                                        </Box>
                                    );
                                }}
                            />
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit isDisabled={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowEventAttachAgentForm;
