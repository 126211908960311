import * as yup from 'yup';

import {EMAILS_LIST_VIA_COMMA} from 'helpers/validation';

export const inviteValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        emails: yup
            .string()
            .required('messages.validation.isRequired')
            .test('emailsList', t('messages.validation.emailsListViaComma'), value => {
                return EMAILS_LIST_VIA_COMMA.test(String(value));
            }),
    });
