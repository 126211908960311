import React, {FC, useCallback, useContext} from 'react';
import get from 'lodash/get';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';

import {AGENT_FILTERS_LIST_CREATE, AGENT_FILTERS_LIST_UPDATE} from 'appRedux/actions/agentFilters';
import {AgentSavedFilterFormTypes} from 'appRedux/actions/agentFilters/types';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import {validationSchema} from 'components/Forms/SavedFilterForm/validation';
import {getFilterHrefForSaving} from 'components/Forms/SavedFilterForm/helper';
import OverwriteFilter from 'components/Forms/SavedFilterForm/OverwriteFilter';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {FILTER_ICON_DEFAULT_TYPE, IconImageType, iconTypes} from 'pages/agent/savedFilters/helper';

interface SavedFilterFormType {
    boardId: number;
    updatedFilter: AgentSavedFilterFormTypes | null;
    setUpdatedFilter: (value: AgentSavedFilterFormTypes | null) => void;
    closeModal: () => void;
}

const SavedFilterForm: FC<SavedFilterFormType> = ({updatedFilter, setUpdatedFilter, closeModal, boardId}) => {
    const [t] = useTranslation();
    const {pathname, search} = useLocation();
    const dispatch = useDispatch();

    const schema = () => validationSchema(t);

    const {showAlert} = useContext(AlertContext);

    const createAgentFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const saveCurrentFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                title: '',
                board: boardId,
                iconType: FILTER_ICON_DEFAULT_TYPE,
                text: getFilterHrefForSaving(pathname, search),
            }}
            validationSchema={schema}
            onSubmit={values => {
                if (updatedFilter) {
                    saveCurrentFilter({
                        ...values,
                        uuid: get(updatedFilter, 'uuid', ''),
                        text: getFilterHrefForSaving(pathname, search),
                        showAlert,
                        callback: () => {
                            closeModal();
                            setUpdatedFilter(null);
                        },
                    });
                } else {
                    createAgentFilter({
                        ...values,
                        showAlert,
                        callback: () => {
                            closeModal();
                            setUpdatedFilter(null);
                        },
                    });
                }
            }}
        >
            {(formik: FormikProps<AgentSavedFilterFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                            }}
                        >
                            <FormControl
                                sx={{
                                    width: '80%',
                                }}
                            >
                                <Field
                                    name="title"
                                    placeholder={t('orguser.savedFilters.nameOfFilter')}
                                    label={t('orguser.savedFilters.title')}
                                    type="text"
                                    component={FormikTextInput}
                                />
                            </FormControl>
                            <Box sx={{ml: 1, mt: 2.4, width: '15%'}}>
                                <Field
                                    as="select"
                                    required
                                    name={'iconType'}
                                    options
                                    component={FormikSelectInput}
                                    fullwidth
                                >
                                    {iconTypes.map(({type, icon}: IconImageType) => {
                                        const Icon = icon;
                                        return (
                                            <MenuItem value={type} key={`icon-type-${type}`}>
                                                <IconButton>
                                                    <Icon />
                                                </IconButton>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                            <OverwriteFilter boardId={boardId} setUpdatedFilter={setUpdatedFilter} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SavedFilterForm;
