import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowStatusTranslationItemTypes, WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS_TRANSLATION} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusDescriptionForm from 'components/Forms/Workflow/workflowStatusTranslation/WorkflowStatusDescriptionForm';

interface ComponentType {
    currentItem: WorkflowStatusTranslationItemTypes;
    languageId: number;
    closeForm: () => void;
}

const UpdateWorkflowStatusDescriptionTranslationForm: FC<ComponentType> = ({currentItem, languageId, closeForm}) => {
    const dispatch = useDispatch();

    const {uuid, translation, translationRequester, translationDescription} = currentItem;

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowStatusTranslation = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusTranslationFormTypes) => {
        updateWorkflowStatusTranslation({
            ...values,
            uuid,
            showAlert,
            callback: closeForm,
        });
    };

    return (
        <WorkflowStatusDescriptionForm
            initialValues={{
                translation,
                translationRequester,
                translationDescription,
                language: languageId,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateWorkflowStatusDescriptionTranslationForm;
