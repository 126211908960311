import React, {FC, useCallback} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';

import {FORM_FIELD_UPDATE} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';
import {TAB_FIELD_DATA, TAB_GDPR_SETTINGS, TAB_INTERNAL} from 'components/Forms/FormBuilder/FieldForm/helper';
import FieldGdprSettingsForm from 'components/Forms/FormBuilder/FieldForm/FieldGdprSettingsForm';
import FieldMetadataForm from 'components/Forms/FormBuilder/FieldForm/FieldMetadataForm';
import {RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';

import {
    getPopupFieldById,
    getFieldById,
    getPageByFieldId,
    getPageByPopupFieldId,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface FormFieldPopupContentType {
    currentTab: number;
    fieldId: number;
    isPopup?: boolean;
}

const FormFieldPopupContent: FC<FormFieldPopupContentType> = ({fieldId, currentTab, isPopup}) => {
    const dispatch = useDispatch();

    const {
        admin: {
            formInfo: {pages},
        },
        resourceFields: {resourceFieldBudgetItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_FIELD_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const fieldValues = isPopup ? getPopupFieldById(pages, fieldId) : getFieldById(pages, fieldId);
    const initialValues = fieldValues ? {...formFieldInitialValues, ...fieldValues} : formFieldInitialValues;

    const pageId = isPopup ? getPageByPopupFieldId(pages, fieldId) : getPageByFieldId(pages, fieldId);

    if (!pageId) return null;

    if (currentTab === TAB_FIELD_DATA) {
        const selectedBudgetItem = resourceFieldBudgetItems.find(item => item.id === initialValues.resourceFieldBudget);
        return (
            <FieldForm
                pageId={pageId}
                entityId={fieldId}
                initialValues={{
                    ...initialValues,
                    budgetAmount:
                        selectedBudgetItem && selectedBudgetItem.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY
                            ? (0.01 * Number(initialValues.budgetAmount)).toFixed(2)
                            : initialValues.budgetAmount,
                    resourceFieldSlot: initialValues.resourceFieldSlot ? initialValues.resourceFieldSlot : 0,
                }}
                onSubmitAction={submitUpdateForm}
                newItemPriority={get(initialValues, 'priority', 0)}
                isPopup={isPopup}
            />
        );
    }
    if (fieldValues && currentTab === TAB_GDPR_SETTINGS) {
        return <FieldGdprSettingsForm fieldId={fieldId} initialValues={fieldValues} />;
    }
    if (fieldValues && currentTab === TAB_INTERNAL) {
        return <FieldMetadataForm fieldId={fieldId} initialValues={fieldValues} isPopup={isPopup} />;
    }
    return null;
};

export default FormFieldPopupContent;
