import React, {FC} from 'react';
import get from 'lodash/get';
import {FieldProps} from 'formik';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';

import {theme} from 'config/theme';

const styles = () =>
    createStyles({
        input: {
            paddingLeft: '10px',
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface TabTextInputTypes extends WithStyles<typeof styles> {
    name: string;
    label?: string;
    placeholder?: string;
    currentColor?: string;
}

const TabTextInput: FC<FieldProps & TabTextInputTypes> = ({
    classes,
    field,
    form: {touched, errors},
    name,
    currentColor,
    ...props
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <Box sx={{width: '100%'}}>
            <FormControl fullWidth>
                <AutoDirectionTextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    sx={{
                        margin: 0,
                        '& input': {
                            color: currentColor ? getTagColor(currentColor) : theme.palette.info.main,
                        },
                    }}
                    inputProps={{className: classes.input}}
                    id={name}
                    {...field}
                    {...props}
                />
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(TabTextInput);
