import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {RequesterCaseHasCalendarTypes, CalendarTimeRanges} from 'appRedux/actions/requestCase/types';
import {AvailableBookframeItemTypes, ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import {
    TAB_STEP_FOUR,
    isCalendarBookframeItemUnavailable,
    getCalendarBookframeItemBackgroundColor,
    getCalendarBookframeItemHoverBackgroundColor,
    getCalendarBookframeItemTextColor,
    getCalendarBookframeItemHoverTextColor,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

interface CalendarBookframeItemType {
    isDisabled: boolean;
    currentCalendar: RequesterCaseHasCalendarTypes;
    bookframe: AvailableBookframeItemTypes;
    blockedDatesExceptions: ResourceFieldExceptionItemTypes[];
    itemDate: string;
    setCurrentTab: (value: number) => void;
}

const CalendarBookframeItem: FC<CalendarBookframeItemType> = ({
    isDisabled,
    currentCalendar,
    bookframe,
    blockedDatesExceptions,
    itemDate,
    setCurrentTab,
}) => {
    const dispatch = useDispatch();

    const {
        calendarUuid,
        formField,
        resourceField,
        resourceFieldCalendar,
        resourceFieldCalendarSlot,
        resourceFieldCalendarBookframe,
        resourceFieldCalendarException,
        calendarDate,
        startTime,
        endTime,
    } = currentCalendar;

    const {showAlert} = useContext(AlertContext);
    const {setErrorField} = useContext(ClientFormContext);

    const {
        requestCase: {unavailableCalendarRanges, isUnavailableCalendarRangesLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, slotStartTime, slotEndTime, isException} = bookframe;

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const unavailableRanges: CalendarTimeRanges[] = get(unavailableCalendarRanges, calendarUuid, []);

    const isTimeRangeDisabled = isCalendarBookframeItemUnavailable(
        itemDate,
        slotStartTime,
        slotEndTime,
        blockedDatesExceptions,
        unavailableRanges,
        isUnavailableCalendarRangesLoading,
    );

    const isAlreadySelected =
        (resourceFieldCalendarBookframe === id || resourceFieldCalendarException === id) &&
        startTime === slotStartTime &&
        endTime === slotEndTime &&
        moment(itemDate).format('YYYY-MM-DD') === moment(calendarDate).format('YYYY-MM-DD');

    const onCalendarBookframeClick = () => {
        if (!isDisabled && !isTimeRangeDisabled) {
            updateRequesterCaseCalendar({
                uuid: currentCalendar.uuid,
                formField,
                resourceField,
                resourceFieldCalendar,
                resourceFieldCalendarSlot,
                startTime: slotStartTime,
                endTime: slotEndTime,
                calendarDate: moment(itemDate).format('YYYY-MM-DD'),
                resourceFieldCalendarBookframe: !isException ? id : null,
                resourceFieldCalendarException: isException ? id : null,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_FOUR);
                        setErrorField(null);
                    }, 500);
                },
            });
        }
    };

    return (
        <Box
            sx={{
                p: 1,
                mb: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: getCalendarBookframeItemBackgroundColor(isAlreadySelected, isTimeRangeDisabled),
                borderRadius: 2,
                cursor: isTimeRangeDisabled || isAlreadySelected || isDisabled ? 'initial' : 'pointer',
                '&:hover': {
                    backgroundColor: getCalendarBookframeItemHoverBackgroundColor(
                        isAlreadySelected,
                        isTimeRangeDisabled,
                        isDisabled,
                    ),
                    '& p': {
                        color: getCalendarBookframeItemHoverTextColor(
                            isAlreadySelected,
                            isTimeRangeDisabled,
                            isDisabled,
                        ),
                    },
                },
            }}
            onClick={onCalendarBookframeClick}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: getCalendarBookframeItemTextColor(isAlreadySelected, isTimeRangeDisabled),
                }}
            >
                {`${slotStartTime} - ${slotEndTime}`}
            </Typography>
        </Box>
    );
};

export default CalendarBookframeItem;
