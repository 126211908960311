import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';

import mobileLogo from 'assets/images/mobileLogo.png';
import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import ActiveCasesSvgIcon from 'assets/icons/menu/ActiveCasesSvgIcon';

import {OrgsListForRequesterTypes} from 'appRedux/actions/organization/types';
import {GET_REDIRECT_URL} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import CustomLogo from 'components/StartScreenComponents/CustomLogo';
import EmptyArea from 'components/EmptyArea';

import {theme} from 'config/theme';

interface TopBarSidebarOrgMenuItemType {
    organization: OrgsListForRequesterTypes;
    isCurrentOrganization: boolean;
}

const TopBarSidebarOrgMenuItem: FC<TopBarSidebarOrgMenuItemType> = ({organization, isCurrentOrganization}) => {
    const dispatch = useDispatch();
    const {showAlert} = useContext(AlertContext);

    const {id, title, logo, caseUpdatesNumber, notReadMessagesNumber} = organization;

    const getCorrectOrganizationRedirect = useCallback(
        data => dispatch({type: GET_REDIRECT_URL.REQUEST, payload: data}),
        [dispatch],
    );

    const onRedirectClicked = () => {
        if (isCurrentOrganization) return;
        getCorrectOrganizationRedirect({
            id: id,
            showAlert,
            callback: (redirectTo: string) => {
                window.location.href = redirectTo;
            },
        });
    };

    return (
        <MenuItem
            sx={{
                pt: 1,
                pb: 1,
            }}
            selected={isCurrentOrganization}
            onClick={onRedirectClicked}
        >
            <IconButton sx={{p: 0.5, width: 32, height: 32}}>
                <CustomLogo logoSource={logo || mobileLogo} maxWidth={24} maxHeight={24} />
            </IconButton>
            <Typography
                variant="body2"
                sx={{
                    ml: 1,
                    color: theme.palette.info.main,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {title}
            </Typography>
            <EmptyArea />
            {caseUpdatesNumber > 0 && (
                <IconButton sx={{p: 0.5, ml: 1}}>
                    <Badge badgeContent={caseUpdatesNumber} color="error" invisible={caseUpdatesNumber === 0}>
                        <ActiveCasesSvgIcon isActive={false} />
                    </Badge>
                </IconButton>
            )}
            {notReadMessagesNumber > 0 && (
                <IconButton sx={{p: 0.5, ml: 1}}>
                    <Badge badgeContent={notReadMessagesNumber} color="error" invisible={notReadMessagesNumber === 0}>
                        <MessagesSvgIcon />
                    </Badge>
                </IconButton>
            )}
        </MenuItem>
    );
};

export default TopBarSidebarOrgMenuItem;
