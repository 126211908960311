import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';

import {FormPopupTypes, FormFieldTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {FORM_POPUP_COPY, FORM_POPUP_DELETE, FORM_POPUP_UP, FORM_POPUP_DOWN} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {
    CONTENT_POPUP,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DATE_RANGE,
    FORM_FIELD_DATEPICKER,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_RANGE,
    FORM_FIELD_RATING,
    FORM_FIELD_RESOURCE_FIELD,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_UPLOADER,
    isPopupIncludesResourceFields,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import TopPanel from 'pages/admin/updateForm/partials/FormStructure/TopPanel';
import FormFieldInformation from 'pages/admin/updateForm/partials/FormStructure/FormFieldInformation';
import FormFieldSelection from 'pages/admin/updateForm/partials/FormStructure/FormFieldSelection';
import {
    ADD_FIELD_POPUP_KEYWORD,
    UPDATE_POPUP_KEYWORD,
    REPLACE_POPUP_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';
import DragPlaceholderFormBlock from 'pages/admin/updateForm/partials/FormStructure/DragPlaceholderFormBlock';
import FormFieldFileSelection from 'pages/admin/updateForm/partials/FormStructure/FormFieldFileSelection';
import CopyingImpossiblePopup from 'pages/admin/updateForm/partials/FormStructure/CopyingImpossiblePopup';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormPopupInformationType {
    isFirst: boolean;
    isLast: boolean;
    item: FormPopupTypes;
    sections: FormSectionTypes[];
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
    toggleModal: () => void;
    onAddFieldPopupClick: (value: number) => void;
    onDrop: () => void;
    viewOnly: boolean;
}

const FormPopupInformation: FC<FormPopupInformationType> = ({
    item,
    isFirst,
    isLast,
    sections,
    toggleModal,
    setEntityId,
    setModalTitle,
    onAddFieldPopupClick,
    onDrop,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const {title, description, fields, id, isRequired} = item;

    const handlePopupRemove = useCallback(
        data => dispatch({type: FORM_POPUP_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const handlePopupCopy = useCallback(data => dispatch({type: FORM_POPUP_COPY.REQUEST, payload: data}), [dispatch]);

    const handlePopupUpPriority = useCallback(
        data => dispatch({type: FORM_POPUP_UP.REQUEST, payload: data}),
        [dispatch],
    );

    const handlePopupDownPriority = useCallback(
        data => dispatch({type: FORM_POPUP_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        toggleModal();
        setModalTitle(UPDATE_POPUP_KEYWORD);
        setEntityId(id);
    };

    const copyAction = () => {
        handlePopupCopy({
            id,
            showAlert,
        });
    };

    const onCopyClick = () => {
        if (isPopupIncludesResourceFields(item)) {
            setShowModal(true);
        } else {
            copyAction();
        }
    };

    const onReplaceClick = () => {
        toggleModal();
        setModalTitle(REPLACE_POPUP_POPUP_KEYWORD);
        setEntityId(id);
    };

    const onDeleteClick = () => {
        handlePopupRemove({
            id,
            showAlert,
        });
    };

    const onUpClick = () => {
        handlePopupUpPriority({
            id,
            showAlert,
        });
    };

    const onDownClick = () => {
        handlePopupDownPriority({
            id,
            showAlert,
        });
    };

    return (
        <Box
            sx={{
                my: 1,
                borderColor: CLIENT_BACKGROUND_COLOR,
                borderWidth: 2,
                borderRadius: 2,
                borderStyle: 'solid',
                pb: 2,
            }}
        >
            <TopPanel
                isFirst={isFirst}
                isLast={isLast}
                isRequired={isRequired}
                entityId={id}
                typeTitle={t('orguser.forms.popup')}
                title={title}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onReplaceClick={onReplaceClick}
                onDeleteClick={onDeleteClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                viewOnly={viewOnly}
                showReplaceButton
            />
            <Box
                sx={{
                    width: '100%',
                    pl: 1,
                    pr: 2,
                    '& p': {
                        px: 1,
                        fontSize: 14,
                        fontWeight: 300,
                    },
                }}
            >
                <Markdown>{description}</Markdown>
            </Box>
            <Box
                sx={{
                    pt: 1,
                    px: 2,
                }}
            >
                {fields.map((field: FormFieldTypes, index: number) => {
                    switch (field.type) {
                        case FORM_FIELD_TEXT_INPUT:
                        case FORM_FIELD_TEXTAREA:
                        case FORM_FIELD_DATEPICKER:
                        case FORM_FIELD_UPLOADER:
                        case FORM_FIELD_DATE_RANGE:
                        case FORM_FIELD_RANGE:
                        case FORM_FIELD_RATING:
                        case FORM_FIELD_SIGNATURE:
                        case FORM_FIELD_RESOURCE_FIELD:
                            return (
                                <Box key={`field-${field.type}-${index}`}>
                                    <DragPlaceholderFormBlock
                                        dropType={`${CONTENT_POPUP}-${id}`}
                                        isPopup={true}
                                        onDrop={onDrop}
                                        index={index}
                                    />
                                    <FormFieldInformation
                                        key={`field-${field.type}-${index}`}
                                        item={field}
                                        toggleModal={toggleModal}
                                        setModalTitle={setModalTitle}
                                        setEntityId={setEntityId}
                                        isFirst={index === 0}
                                        isLast={index === fields.length - 1}
                                        isPopup
                                        viewOnly={viewOnly}
                                    />
                                </Box>
                            );
                        case FORM_FIELD_DROPDOWN:
                        case FORM_FIELD_CHECKBOXES:
                        case FORM_FIELD_RADIOBUTTONS:
                            return (
                                <Box key={`field-${field.type}-${index}`}>
                                    <DragPlaceholderFormBlock
                                        dropType={`${CONTENT_POPUP}-${id}`}
                                        isPopup={true}
                                        onDrop={onDrop}
                                        index={index}
                                    />
                                    <FormFieldSelection
                                        item={field}
                                        sections={sections}
                                        toggleModal={toggleModal}
                                        setModalTitle={setModalTitle}
                                        setEntityId={setEntityId}
                                        isFirst={index === 0}
                                        isLast={index === fields.length - 1}
                                        isPopup
                                        viewOnly={viewOnly}
                                    />
                                </Box>
                            );
                        case FORM_FIELD_IMAGE_CHECK:
                        case FORM_FIELD_IMAGE_RADIO:
                            return (
                                <Box key={`field-${field.type}-${index}`}>
                                    <DragPlaceholderFormBlock
                                        dropType={`${CONTENT_POPUP}-${id}`}
                                        onDrop={onDrop}
                                        index={index}
                                    />
                                    <FormFieldFileSelection
                                        item={field}
                                        sections={sections}
                                        setEntityId={setEntityId}
                                        toggleModal={toggleModal}
                                        setModalTitle={setModalTitle}
                                        isFirst={index === 0}
                                        isLast={index === fields.length - 1}
                                        isPopup
                                        viewOnly={viewOnly}
                                    />
                                </Box>
                            );
                    }
                    return null;
                })}
                <DragPlaceholderFormBlock dropType={`${CONTENT_POPUP}-${id}`} isPopup={true} onDrop={onDrop}>
                    <AgentSaveButton onClick={() => onAddFieldPopupClick(id)} title={t(ADD_FIELD_POPUP_KEYWORD)} />
                </DragPlaceholderFormBlock>
            </Box>
            <CopyingImpossiblePopup
                setShowModal={setShowModal}
                showModal={showModal}
                toggleShowModal={toggleShowModal}
                text={t('orguser.forms.formPopup.popupWithResourceFieldCopyingWarning')}
                copyAction={copyAction}
            />
        </Box>
    );
};

export default FormPopupInformation;
