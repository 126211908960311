import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {
    MDXEditor,
    toolbarPlugin,
    imagePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    linkDialogPlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
    BoldItalicUnderlineToggles,
    UndoRedo,
    Separator,
    ListsToggle,
    InsertThematicBreak,
    MDXEditorMethods,
    codeBlockPlugin,
} from '@mdxeditor/editor';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CodeIcon from '@mui/icons-material/Code';
import Button from '@mui/material/Button';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';
import EmptyArea from 'components/EmptyArea';

interface CustomMdxEditorTypes {
    mdxEditorClassName: any;
    label?: string;
    description?: string;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const CustomMdxEditor: FC<CustomMdxEditorTypes> = ({
    mdxEditorClassName,
    label,
    description,
    value = '',
    onChange,
    onBlur,
}) => {
    const mdxRef = useRef<MDXEditorMethods>(null);
    const [isSourceMode, setIsSourceMode] = useState(false);

    useEffect(() => {
        mdxRef.current && mdxRef.current.setMarkdown(value);
    }, [value]);

    const handleSourceModeToggle = () => {
        setIsSourceMode(!isSourceMode);
        if (mdxRef.current) {
            mdxRef.current.setMarkdown(value);
        }
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(e.target?.value || '');
    };

    return (
        <Box>
            {label && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 600, mr: 1}}>{`${label}:`}</Typography>
                    {description && <DescriptionPopover text={description} />}
                </Box>
            )}
            <Box
                sx={{
                    borderColor: CLIENT_BACKGROUND_COLOR,
                    backgroundColor: theme.palette.background.default,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 2,
                    p: 1,
                    pt: 2,
                }}
            >
                {isSourceMode ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pb: 1,
                                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                            }}
                        >
                            <IconButton
                                onClick={handleSourceModeToggle}
                                sx={{
                                    backgroundColor: theme.palette.info.main,
                                    border: `1px solid ${theme.palette.info.main}`,
                                    borderRadius: 16,
                                    height: 32,
                                    minWidth: 60,
                                    mr: 0.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.info.main,
                                    },
                                }}
                            >
                                <CodeIcon sx={{color: theme.palette.background.default}} />
                            </IconButton>
                        </Box>
                        <AutoDirectionTextField
                            fullWidth
                            multiline
                            minRows={5}
                            value={value}
                            onChange={handleTextChange}
                            onBlur={onBlur}
                            variant="outlined"
                            InputProps={{
                                sx: {
                                    fontFamily: 'monospace',
                                    '& fieldset': {border: 'none'},
                                },
                            }}
                        />
                    </>
                ) : (
                    <MDXEditor
                        ref={mdxRef}
                        markdown={value || ''}
                        contentEditableClassName={mdxEditorClassName}
                        plugins={[
                            toolbarPlugin({
                                toolbarContents: () => (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 0.5,
                                                mb: 1,
                                                '& div': {
                                                    display: 'flex',
                                                },
                                                '& div[role="separator"]': {
                                                    width: 3,
                                                },
                                                '& button': {
                                                    backgroundColor: theme.palette.info.main,
                                                    border: `1px solid ${theme.palette.info.main}`,
                                                    borderRadius: 16,
                                                    height: 32,
                                                    minWidth: 60,
                                                    marginRight: 0.5,
                                                },
                                                '& button[disabled]': {
                                                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                                                    border: `1px solid ${CLIENT_BACKGROUND_COLOR}`,
                                                },
                                                '& button[title="Underline"]': {
                                                    display: 'none',
                                                },
                                                '& g': {
                                                    color: theme.palette.background.default,
                                                },
                                                '& button[disabled] g': {
                                                    color: theme.palette.info.light,
                                                },
                                            }}
                                        >
                                            <UndoRedo />
                                            <Separator />
                                            <BoldItalicUnderlineToggles />
                                            <Separator />
                                            <ListsToggle options={['number', 'bullet']} />
                                            <Separator />
                                            <InsertThematicBreak />
                                            <Separator />
                                            <EmptyArea />
                                            <Button
                                                onClick={handleSourceModeToggle}
                                                sx={{
                                                    backgroundColor: theme.palette.info.main,
                                                    cursor: 'default',
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.info.main,
                                                    },
                                                }}
                                            >
                                                <CodeIcon sx={{color: theme.palette.background.default}} />
                                            </Button>
                                        </Box>
                                        <Divider sx={{mt: 1}} />
                                    </Box>
                                ),
                            }),
                            headingsPlugin(),
                            imagePlugin(),
                            listsPlugin(),
                            quotePlugin(),
                            thematicBreakPlugin(),
                            linkPlugin(),
                            linkDialogPlugin(),
                            frontmatterPlugin(),
                            markdownShortcutPlugin(),
                            codeBlockPlugin({defaultCodeBlockLanguage: 'txt'}),
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CustomMdxEditor;
