import React, {ReactNode, useEffect, useState, useContext, useCallback} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {FORM_VERSION_CARDS_GET_ALL} from 'appRedux/actions/forms';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';
import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext, AdminContextType} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import {filterParameters} from 'components/BlockView/helper';

import {PARAMETER_TAB, DEFAULT_PAGE, PARAMETER_PAGE, PARAMETER_PER_PAGE, userRoles, routes} from 'config/index';

interface ContextType {
    children: ReactNode;
}

const AdminContextWrapper: React.FC<ContextType> = ({children}) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isSuperAdminPage} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);

    const [searchValue, setSearchValue] = useState<string>('');
    const [filtersNumber, setFiltersNumber] = useState<number>(0);
    const [questionSectionId, setQuestionSectionId] = useState<number | null>(null);
    const [questionSectionName, setQuestionSectionName] = useState<string | null>(null);
    const [showFiltersPanel, setShowFiltersPanel] = useState<boolean>(
        !!filterParameters.filter(parameter => searchParams.get(parameter)).length,
    );
    const [isSelectClicked, setIsSelectClicked] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [selectAllFiltered, setSelectAllFiltered] = useState<boolean>(false);
    const [isDragStart, setIsDragStart] = useState<boolean>(false);
    const [dragType, setDragType] = useState<string | null>(null);
    const [dropType, setDropType] = useState<string | null>(null);
    const [, isUpdated] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<number | null>(null);
    const [itemsPerPage, setItemsPerPage] = useState<number>(Number(searchParams.get(PARAMETER_PER_PAGE)));
    const [currentPage, setCurrentPage] = useState<number>(
        searchParams.get(PARAMETER_PAGE) ? Number(searchParams.get(PARAMETER_PAGE)) : DEFAULT_PAGE,
    );
    const [totalItems, setTotalItems] = useState<number | null>(null);

    const [draggedCaseInitialStatus, setDraggedCaseInitialStatus] = useState<string | null>(null);
    const [draggedCasePermittedUuids, setDraggedCasePermittedUuids] = useState<string[]>([]);

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationFieldCards = useCallback(
        data => dispatch({type: FORM_VERSION_CARDS_GET_ALL.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleFiltersPanel = () => {
        setShowFiltersPanel(previous => !previous);
    };

    const toggleIsSelectClicked = () => {
        setIsSelectClicked(previous => !previous);
    };

    const toggleIsUpdated = () => {
        isUpdated(previous => !previous);
    };

    const toggleDragStart = () => {
        setIsDragStart(previous => !previous);
    };

    useEffect(() => {
        setActiveRow(null);
    }, [searchParams.get(PARAMETER_TAB)]);

    useEffect(() => {
        setItemsPerPage(Number(searchParams.get(PARAMETER_PER_PAGE)));
    }, [searchParams.get(PARAMETER_PER_PAGE)]);

    useEffect(() => {
        setCurrentPage(Number(searchParams.get(PARAMETER_PAGE) || DEFAULT_PAGE));
    }, [searchParams.get(PARAMETER_PAGE)]);

    useEffect(() => {
        if (!isSuperAdminPage && !myOrganization && profile && profile.isRelatedWithCurrentOrganization) {
            getOrganizationList();
            getOrganizationFieldCards({
                showAlert,
            });
        }
    }, [isSuperAdminPage, organizationList, profile]);

    const roles: string[] = get(profile, 'roles', []);
    const userNotPermittedForOrganization =
        profile &&
        !profile.isRelatedWithCurrentOrganization &&
        !isSuperAdminPage &&
        roles.includes(userRoles.ROLE_REQUEST);

    useEffect(() => {
        if (userNotPermittedForOrganization) {
            navigate(routes.REQUESTS_LIST);
        }
    }, [profile]);

    useEffect(() => {
        if (!isSuperAdminPage && myOrganization) {
            getOrganizationLanguages({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [isSuperAdminPage, organizationList]);

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const context: AdminContextType = {
        searchValue,
        setSearchValue,
        filtersNumber,
        setFiltersNumber,
        questionSectionId,
        setQuestionSectionId,
        questionSectionName,
        setQuestionSectionName,
        isSelectClicked,
        setIsSelectClicked,
        toggleIsSelectClicked,
        selectedIds,
        setSelectedIds,
        toggleIsUpdated,
        showFiltersPanel,
        setShowFiltersPanel,
        toggleFiltersPanel,
        selectAllFiltered,
        setSelectAllFiltered,
        isDragStart,
        setIsDragStart,
        toggleDragStart,
        dragType,
        setDragType,
        dropType,
        setDropType,
        activeRow,
        setActiveRow,
        itemsPerPage,
        currentPage,
        totalItems,
        setTotalItems,
        draggedCaseInitialStatus,
        setDraggedCaseInitialStatus,
        draggedCasePermittedUuids,
        setDraggedCasePermittedUuids,
        myOrganization,
    };

    if (!myOrganization && !isSuperAdminPage) return null;

    return <AdminContext.Provider value={context}>{children}</AdminContext.Provider>;
};

export default AdminContextWrapper;
