import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {RequesterCaseHasTimeBucketTypes} from 'appRedux/actions/requestCase/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import EditTimeRecordForm from 'pages/agent/requesterPage/timeTracking/EditTimeRecordForm';

interface EditTimeRecordButtonType {
    item: RequesterCaseHasTimeBucketTypes;
}

const EditTimeRecordButton: FC<EditTimeRecordButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{mr: 1}}
                title={t('orguser.requesterCase.timeTracking.editTimeRecord')}
                onClick={() => setShowModal(true)}
            >
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                title={t('orguser.requesterCase.timeTracking.editTimeRecord')}
                toggleModal={toggleModal}
            >
                <EditTimeRecordForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditTimeRecordButton;
