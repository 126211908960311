import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {RequesterCaseApproveRuleTypes} from 'appRedux/actions/requestCase/types';

import FormInformationRowWrapper from 'pages/agent/requesterPage/wrappers/FormInformationRowWrapper';
import ApproveRule from 'pages/agent/requesterPage/formInformation/approveRules/ApproveRule';
import {checkIsAtLeastOneApprovePresented} from 'pages/agent/requesterPage/approverResolution/helper';

interface ApproveRulesType {
    items: RequesterCaseApproveRuleTypes[];
}

const ApproveRules: FC<ApproveRulesType> = ({items}) => {
    const [t] = useTranslation();
    const rulesNumber = items.length;

    const isAtLeastOneApprovePresented = checkIsAtLeastOneApprovePresented(items);

    if (!isAtLeastOneApprovePresented) return null;

    return (
        <>
            {items.map((item: RequesterCaseApproveRuleTypes, index: number) => {
                return (
                    <FormInformationRowWrapper
                        title={t('orguser.requesterCase.approves.approver')}
                        subtitle={rulesNumber > 1 ? `(${t('orguser.requesterCase.approves.rule')} ${index + 1})` : ''}
                        key={`rule-${item.ruleUuid}`}
                        alignTop
                    >
                        <ApproveRule item={item} />
                    </FormInformationRowWrapper>
                );
            })}
        </>
    );
};

export default ApproveRules;
