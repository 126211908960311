import React, {FC, useCallback, useContext, useEffect} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import LockSvgIcon from 'assets/icons/buttons/LockSvgIcon';
import NoEditSvgIcon from 'assets/icons/buttons/NoEditSvgIcon';
import AddFileSvgIcon from 'assets/icons/buttons/AddFileSvgIcon';
import NotificationSvgIcon from 'assets/icons/buttons/NotificationSvgIcon';

import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {ORGANIZATION_PDF_TEMPLATES_LIST, ORGANIZATION_PRIVACY_POLICY_LIST} from 'appRedux/actions/organization';

import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import {tickerFormValidationSchema} from 'components/Forms/FormBuilder/ConfigurationForm/validation';
import {makeAbbreviation} from 'components/Forms/FormBuilder/ConfigurationForm/helper';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import CheckboxIconButton from 'components/AdminScreenComponents/CheckboxIconButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import UpdateFormWorkflowButton from 'components/Forms/FormBuilder/ConfigurationForm/UpdateFormWorkflowButton';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';

import {getLanguageById} from 'helpers/translationsHelper';

interface FormTicketType {
    initialValues: FormConfigurationRequestTypes;
    onSubmitClicked: (values: FormConfigurationRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const FormTicket: FC<FormTicketType> = ({initialValues, isLoading, isCreate, onSubmitClicked}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationPrivacyPolicies = useCallback(
        data => dispatch({type: ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationPdfTemplatesList = useCallback(
        data => dispatch({type: ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {languageList},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {isLocked} = initialValues;

    useEffect(() => {
        if (myOrganization) {
            const organizationLanguage = getLanguageById(languageList, myOrganization.language);
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
            getOrganizationPrivacyPolicies({
                id: myOrganization.id,
                language: organizationLanguage.name,
                showAlert,
            });
            getOrganizationPdfTemplatesList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization, isCreate]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => tickerFormValidationSchema(t)}
            enableReinitialize
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    policy: values.policy === 0 ? null : values.policy,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<FormConfigurationRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.forms.title')}
                                component={FormikTextInput}
                                onFieldValueChange={(e: React.ChangeEvent<any>) => {
                                    if (!formik.touched.handlePrefix) {
                                        console.log(makeAbbreviation(e.target.value));
                                        formik.setFieldValue('handlePrefix', makeAbbreviation(e.target.value));
                                    }
                                }}
                            />
                        </Box>
                        {isLocked && (
                            <Alert severity={'error'} sx={{mt: 2, mb: 1}}>
                                <Typography>{t('orguser.forms.formIsLocked')}</Typography>
                            </Alert>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Field
                                as="select"
                                required
                                name={'workflow'}
                                label={t('orguser.forms.workflow')}
                                options
                                disabled={!isCreate}
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                            {!isCreate && (
                                <UpdateFormWorkflowButton
                                    currentWorkflowId={Number(initialValues.workflow)}
                                    isLocked={isLocked}
                                />
                            )}
                        </Box>
                        <Box sx={{mt: 1}}>
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.description')}
                            />
                        </Box>
                        <Box sx={{mt: 2, mb: 2}}>
                            <MdxEditorField
                                name="submitMessage"
                                value={formik.values.submitMessage ? String(formik.values.submitMessage) : ''}
                                label={t('orguser.forms.submitMessage')}
                            />
                        </Box>
                        <Box>
                            <MdxEditorSmallField
                                name="pauseReason"
                                value={formik.values.pauseReason ? String(formik.values.pauseReason) : ''}
                                label={t('orguser.forms.pauseReason')}
                            />
                        </Box>
                        <Box sx={{mt: 2, display: 'flex', gap: 2}}>
                            <Field
                                type="checkbox"
                                name="isChatActivated"
                                label={t('orguser.forms.activateChatOnDefault')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={MessagesSvgIcon}
                                        title={t('orguser.forms.defaultChat')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isAutoChatEnabled"
                                label={t('orguser.forms.isChatMailingEnabled')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NotificationSvgIcon}
                                        title={t('orguser.forms.chat')}
                                    />
                                )}
                            />
                            {myOrganization && myOrganization.isEncryptionPossible && (
                                <Field
                                    type="checkbox"
                                    name="isEncryptInfo"
                                    label={t('orguser.forms.isEncryptInfo')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={LockSvgIcon}
                                            title={t('orguser.forms.encryption')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization && myOrganization.isMultipleRequestPossible && (
                                <Field
                                    type="checkbox"
                                    name="isMultipleRequestPossible"
                                    label={t('orguser.forms.multipleRequestPossible')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={AddFileSvgIcon}
                                            title={t('orguser.forms.multipleRequests')}
                                        />
                                    )}
                                />
                            )}
                            <Field
                                type="checkbox"
                                name="noEditForm"
                                label={t('orguser.forms.requestNotEditableAfterSubmit')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NoEditSvgIcon}
                                        title={t('orguser.forms.notEditable')}
                                    />
                                )}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isLoading={isLoading}
                                title={t(isCreate ? 'orguser.forms.addNewForm' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormTicket;
