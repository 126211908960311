import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {theme} from 'config/theme';

interface BackLinkLabelType {
    backLinkLabel: string;
    onLinkClick: () => void;
}

const BackLinkLabel: FC<BackLinkLabelType> = ({backLinkLabel, onLinkClick}) => {
    const [t] = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                color: theme.palette.info.main,
            }}
            onClick={onLinkClick}
        >
            <KeyboardArrowLeft fontSize="small" />
            <Typography sx={{fontSize: 14, fontWeight: 600}}>{t(backLinkLabel)}</Typography>
        </Box>
    );
};

export default BackLinkLabel;
