import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {AlertContext} from 'contexts/alert/context';

import {UPDATE_WORKFLOW_FIELD_VARIABLE} from 'appRedux/actions/workflow';
import {WorkflowFieldVariableFormTypes, WorkflowFieldVariableItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowFieldVariableForm from 'components/Forms/Workflow/workflowFieldVariables/WorkflowFieldVariableForm';

interface ComponentType {
    item: WorkflowFieldVariableItemTypes;
    closeModal: () => void;
}

const UpdateWorkflowFieldVariableForm: FC<ComponentType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowFieldVariable = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_FIELD_VARIABLE.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, title, internalName} = item;

    const onSubmitClicked = (values: WorkflowFieldVariableFormTypes) => {
        updateWorkflowFieldVariable({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <WorkflowFieldVariableForm
            initialValues={{
                title,
                internalName,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateWorkflowFieldVariableForm;
