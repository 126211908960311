import React, {FC, ReactElement, useContext} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {MediaContext} from 'contexts/media/context';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

interface ClientButtonType {
    title: string;
    onClickAction: () => void;
    dataId?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    isError?: boolean;
}

const ClientButton: FC<ClientButtonType> = ({title, dataId, isError, onClickAction, startIcon, endIcon}) => {
    const {isMobile} = useContext(MediaContext);

    return (
        <Button
            data-id={dataId}
            sx={{
                cursor: 'initial',
                minWidth: 120,
                minHeight: 40,
                mr: isMobile ? 0 : 1,
                p: isMobile ? 1 : 0,
                pl: isMobile ? 1 : 2,
                pr: isMobile ? 0.5 : 1,
                borderRadius: 20,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                '&:hover': {
                    backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                },
            }}
            onClick={onClickAction}
        >
            {startIcon && (
                <IconButton
                    sx={{
                        backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        },
                    }}
                >
                    {startIcon}
                </IconButton>
            )}
            <Typography
                sx={{
                    textTransform: 'none',
                    color: theme.palette.background.default,
                    ml: startIcon ? 1 : 0,
                    mr: 1,
                }}
            >
                {title}
            </Typography>
            {endIcon && (
                <IconButton
                    sx={{
                        backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        },
                    }}
                >
                    {endIcon}
                </IconButton>
            )}
        </Button>
    );
};

export default ClientButton;
