import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import TranslateSvgIcon from 'assets/icons/buttons/TranslateSvgIcon';

import {CustomAttributesItemTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';
import {RootReducer} from 'appRedux/reducers';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CustomAttributeTranslationForm from 'components/Forms/CustomAttributes/CustomAttributeTranslationForm';
import {
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
    getCustomAttributeTranslationKeyword,
    getCustomAttributeOptionTranslationKeyword,
} from 'components/Forms/CustomAttributes/helper';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';

import {getLanguagesWithBadgeIdsArray} from 'pages/admin/customAttributes/helper';

interface TranslateAttributeButtonType {
    item: CustomAttributesItemTypes;
}

const TranslateAttributeButton: FC<TranslateAttributeButtonType> = ({item}) => {
    const [t] = useTranslation();

    const {id, title, type, options, translations, uuid} = item;

    const {
        admin: {languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [languageId, setLanguageId] = useState<number | null>(null);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const keywordTitle = getCustomAttributeTranslationKeyword(id, 'title');

    const requiredTranslationsNumber = type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN ? options.length + 1 : 1;

    return (
        <>
            <IconButton
                title={t('common.buttons.translate')}
                onClick={() => setIsOpened(true)}
                disabled={Boolean(item.parentId)}
            >
                <TranslateSvgIcon isDisabled={Boolean(item.parentId)} />
            </IconButton>
            <ModalWrapper title={t('common.buttons.translate')} isShowModal={isOpened} toggleModal={toggleIsOpened}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: languageId ? 'flex-end' : 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {!languageId && <Typography>{t('superadmin.customAttributes.languageNotSelected')}</Typography>}
                    <LanguageSelector
                        languageId={Number(languageId)}
                        setLanguageId={setLanguageId}
                        languagesWithBadgeIds={getLanguagesWithBadgeIdsArray(
                            languageList,
                            translations,
                            requiredTranslationsNumber,
                        )}
                        hideOrganizationLanguage
                    />
                </Box>
                {languageId && (
                    <Box>
                        <CustomAttributeTranslationForm
                            uuid={uuid}
                            keyword={keywordTitle}
                            language={languageId}
                            originTitle={title}
                            currentTranslation={translations.find(
                                translation =>
                                    translation.language === languageId && translation.keyword === keywordTitle,
                            )}
                        />
                        {type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN && options.length > 0 && (
                            <Box sx={{mt: 2}}>
                                <Typography sx={{fontWeight: 700, mb: 1}}>{`${t(
                                    'superadmin.customAttributes.optionsList',
                                )}:`}</Typography>
                                {options.map((option: CustomAttributeOptionTypes, index: number) => {
                                    const keywordOptionTitle = getCustomAttributeOptionTranslationKeyword(
                                        id,
                                        option.id,
                                    );
                                    return (
                                        <CustomAttributeTranslationForm
                                            key={`option-translation-${option.uuid}`}
                                            uuid={uuid}
                                            keyword={keywordOptionTitle}
                                            language={languageId}
                                            originTitle={option.title}
                                            currentTranslation={translations.find(
                                                translation =>
                                                    translation.language === languageId &&
                                                    translation.keyword === keywordOptionTitle,
                                            )}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                )}
            </ModalWrapper>
        </>
    );
};

export default TranslateAttributeButton;
