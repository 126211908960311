import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik} from 'formik';
import {Box} from '@mui/material';

import {UPDATE_ORGANIZATION_TIME_BUCKET} from 'appRedux/actions/organization';
import {OrganizationTimeBucketInitTypes, OrganizationTimeBucketsTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {organizationTimeBucketValidationSchema} from 'components/Forms/OrganizationTimeBucketForm/validation';

interface UpdateTimeBucketFormType {
    currentBucket: OrganizationTimeBucketsTypes;
    closeModal: () => void;
}

const UpdateTimeBucketForm: FC<UpdateTimeBucketFormType> = ({currentBucket, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {title, description, uuid} = currentBucket;

    const {showAlert} = useContext(AlertContext);

    const updateOrganizationPdfTemplate = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION_TIME_BUCKET.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitAction = (values: OrganizationTimeBucketInitTypes) => {
        updateOrganizationPdfTemplate({
            uuid,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{title, description}}
            validationSchema={organizationTimeBucketValidationSchema}
            onSubmit={onSubmitAction}
        >
            {() => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="title"
                                label={t('superadmin.organizations.timeTracking.title')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="description"
                                label={t('superadmin.organizations.timeTracking.description')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UpdateTimeBucketForm;
