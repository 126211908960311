import React, {FC, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RouteContext} from 'contexts/route/context';
import {MediaContext} from 'contexts/media/context';

import {
    AgentMenuItemTypes,
    isMenuItemActive,
    isMenuSubItemActive,
    isAllSubItemsInactive,
} from 'components/AgentScreenComponents/helper';
import MenuLink from 'components/AgentScreenComponents/MenuLink';
import MenuBlockItemWrapper from 'components/AgentScreenComponents/MenuBlockItemWrapper';

import {isCurrentSubItemActive} from 'helpers/menuHelper';

import {routes} from 'config/index';

interface MenuBlockItemTypes {
    isMenuCollapsed: boolean;
    menuItem: AgentMenuItemTypes;
    toggleAdminMenu: (value: string) => void;
}

const MenuBlockItem: FC<MenuBlockItemTypes> = ({isMenuCollapsed, menuItem, toggleAdminMenu}) => {
    const {pathname, search} = useLocation();
    const {uuid} = useParams();

    const {isAgentPage} = useContext(RouteContext);
    const {isMobile} = useContext(MediaContext);

    const path = isAgentPage ? routes.AGENT : routes.ADMIN;

    const isItemActive =
        isMenuItemActive(menuItem, pathname, search) || isMenuSubItemActive(menuItem, pathname, search, uuid);

    const isParentActive = menuItem.subItems && isItemActive;

    const isActive =
        menuItem?.subItems && menuItem?.subItems.length > 0
            ? isMenuItemActive(menuItem, pathname, search) &&
              isAllSubItemsInactive(menuItem.subItems, pathname, search, isAgentPage, uuid)
            : isItemActive;

    const subItems = menuItem?.subItems?.filter(subItem => !subItem.isHidden);
    const subItemsOpened = isParentActive && subItems && subItems.length > 0;

    const subItemsInPopup = !!subItems && subItems.length > 0 && menuItem.icon && isMenuCollapsed;

    return (
        <MenuBlockItemWrapper isActive={isItemActive} menuItem={menuItem} enablePopup={subItemsInPopup}>
            <>
                <MenuLink
                    isActive={isActive}
                    isMenuCollapsed={isMenuCollapsed && !subItemsInPopup}
                    menuItem={menuItem}
                    toggleAdminMenu={toggleAdminMenu}
                    hasSubItems={subItemsOpened}
                    isMobile={isMobile}
                />
                {(subItemsOpened || isMenuCollapsed) && (
                    <Box sx={{mt: 0, mb: 0.5}}>
                        {subItems &&
                            subItems.map((subItem: AgentMenuItemTypes, subIndex: number) => {
                                return (
                                    <MenuLink
                                        key={`subItem-${subIndex}`}
                                        isActive={isCurrentSubItemActive(
                                            menuItem,
                                            subItem,
                                            path,
                                            pathname,
                                            search,
                                            uuid,
                                        )}
                                        isMenuCollapsed={false}
                                        menuItem={subItem}
                                        toggleAdminMenu={toggleAdminMenu}
                                        isSubItem
                                    />
                                );
                            })}
                    </Box>
                )}
            </>
        </MenuBlockItemWrapper>
    );
};

export default MenuBlockItem;
