import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import OrgUsersSvgIcon from 'assets/icons/buttons/OrgUsersSvgIcon';

import {ImpersonatedCaseDetailsTypes} from 'appRedux/actions/requestCase/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme, TAG_COLOR_10} from 'config/theme';
import ImpersonalCaseDetailsUpdateForm from 'components/Forms/ImpersonalCaseForm/ImpersonalCaseDetailsUpdateForm';

interface UpdateImpersonalCaseDetailsButtonType {
    impersonatedCaseDetails: ImpersonatedCaseDetailsTypes;
}

const UpdateImpersonalCaseDetailsButton: FC<UpdateImpersonalCaseDetailsButtonType> = ({impersonatedCaseDetails}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{
                    ml: 1,
                    width: 40,
                    height: 40,
                    backgroundColor: TAG_COLOR_10,
                    borderColor: theme.palette.info.main,
                    borderWidth: 1.5,
                    borderStyle: 'solid',
                    '&:hover': {
                        backgroundColor: TAG_COLOR_10,
                    },
                }}
                title={t('orguser.requesterCase.impersonatedCases.updateDetails')}
                onClick={() => setShowModal(true)}
            >
                <OrgUsersSvgIcon isActive={false} />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('orguser.requesterCase.impersonatedCases.updateDetails')}
            >
                <ImpersonalCaseDetailsUpdateForm
                    item={impersonatedCaseDetails}
                    closeModal={() => setShowModal(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default UpdateImpersonalCaseDetailsButton;
