import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {MINIMAL_SEARCH_LENGTH} from 'helpers/search';

import {PARAMETER_SEARCH} from 'config/index';
import {theme} from 'config/theme';

const Search: FC = () => {
    const [t] = useTranslation();

    const ref = useRef<HTMLInputElement | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const currentSearchValue = searchParams.get(PARAMETER_SEARCH);

    const [value, setValue] = useState<string>(currentSearchValue ?? '');

    const onIconClicked = () => {
        if (currentSearchValue) {
            setValue('');
            searchParams.set(PARAMETER_SEARCH, '');
            setSearchParams(searchParams);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (!currentSearchValue) {
            setValue('');
        }
    }, [currentSearchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value) {
                searchParams.set(PARAMETER_SEARCH, value);
                setSearchParams(searchParams);
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    useEffect(() => {
        const searchOnFocus = (keyEvent: KeyboardEvent) => {
            if (keyEvent && keyEvent.ctrlKey && keyEvent.charCode === 5) {
                if (ref && ref.current) {
                    ref.current.focus();
                }
            }
        };
        window.addEventListener('keypress', searchOnFocus);
        return () => window.removeEventListener('keypress', searchOnFocus);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mx: 1,
                mb: 0.5,
                flex: 1,
                minWidth: 150,
            }}
        >
            <FormControl fullWidth>
                <Input
                    fullWidth
                    inputRef={ref}
                    inputProps={{className: 'search'}}
                    placeholder={t('common.searchFieldPlaceholder')}
                    onChange={handleSearchChange}
                    sx={{
                        backgroundColor: theme.palette.background.default,
                        height: 16,
                        borderRadius: 8,
                        pl: 4,
                        p: 2,
                    }}
                    value={value}
                    disableUnderline
                    endAdornment={
                        <InputAdornment position="start" onClick={onIconClicked} sx={{cursor: 'pointer'}}>
                            {currentSearchValue && currentSearchValue.length >= MINIMAL_SEARCH_LENGTH ? (
                                <CancelSvgIcon />
                            ) : (
                                <SearchIcon />
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
};

export default Search;
