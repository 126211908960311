import React, {FC, useCallback, useContext} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {AlertContext} from 'contexts/alert/context';

import {REASSIGN_IMPERSONATED_CASE} from 'appRedux/actions/requestCase';
import {ImpersonatedCaseReassignFormTypes} from 'appRedux/actions/requestCase/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationPinCodeSchema} from 'components/Forms/ImpersonalCaseForm/validation';

import {routes} from 'config/index';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import Box from '@mui/material/Box';

interface ComponentType {
    caseId: number;
}

const ReassignImpersonatedCaseForm: FC<ComponentType> = ({caseId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const createImpersonatedFormCase = useCallback(
        data => dispatch({type: REASSIGN_IMPERSONATED_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ImpersonatedCaseReassignFormTypes) => {
        createImpersonatedFormCase({
            id: caseId,
            ...values,
            showAlert,
            callback: (caseId: number, formId: number) => {
                navigate(`${routes.REQUEST}/${caseId}/form/${formId}`);
            },
        });
    };

    return (
        <Formik
            initialValues={{
                pinCode: 0,
            }}
            validationSchema={() => validationPinCodeSchema(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ImpersonatedCaseReassignFormTypes>) => {
                return (
                    <Form>
                        <Field
                            name="pinCode"
                            label={t('orguser.requesterCase.impersonatedCases.parameters.pinCode')}
                            placeholder={t('orguser.requesterCase.impersonatedCases.parameters.pinCode')}
                            component={FormikTextInput}
                            required
                        />
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <AgentSaveButton isSubmit title={t('common.buttons.confirm')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReassignImpersonatedCaseForm;
