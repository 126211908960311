import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_IMPERSONATED_CASE} from 'appRedux/actions/requestCase';
import {ImpersonatedCaseDetailsFormTypes} from 'appRedux/actions/requestCase/types';

import {AlertContext} from 'contexts/alert/context';

import ImpersonalCaseDetailsForm from 'components/Forms/ImpersonalCaseForm/ImpersonalCaseDetailsForm';

import {routes} from 'config/index';

interface ComponentType {
    closeModal: () => void;
}

const ImpersonatedCaseCreationForm: FC<ComponentType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createImpersonatedFormCase = useCallback(
        data => dispatch({type: CREATE_IMPERSONATED_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ImpersonatedCaseDetailsFormTypes, {setErrors}) => {
        createImpersonatedFormCase({
            ...values,
            setErrors,
            showAlert,
            callback: (caseId: number, formId: number) => {
                window.open(`${routes.INTERACTIVE_REQUEST}/${caseId}/form/${formId}`, '_blank');
                closeModal();
            },
        });
    };

    return (
        <ImpersonalCaseDetailsForm
            closeModal={closeModal}
            initialValues={{
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                reason: '',
                pinCode: Math.floor(1000 + Math.random() * 9000),
            }}
            onSubmitAction={onSubmitClicked}
            isCreate
        />
    );
};

export default ImpersonatedCaseCreationForm;
