import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {DELETE_REQUESTER_POPUP} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';
import EmptyArea from 'components/EmptyArea';

import PopupRowForm from 'pages/client/form/fieldPopup/PopupRowForm';

import {ALERT_TYPE_SUCCESS} from 'config/index';
import {ADMIN_LIGHT_GRAY_COLOR, theme} from 'config/theme';

interface PopupContentRowType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    currentIndex: number;
    disabled: boolean;
    isAgentPage: boolean;
    initialItem: RequesterCasePopupType;
    onEditClick: (value: number | null) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    isSelected: boolean;
    clientInformation: unknown;
    agentInformation: unknown;
    lastOne?: boolean;
}

const PopupContentRow: FC<PopupContentRowType> = ({
    formId,
    pageId,
    sectionId,
    popup,
    disabled,
    currentIndex,
    initialItem,
    onEditClick,
    onSaveClicked,
    isSelected,
    isAgentPage,
    clientInformation,
    agentInformation,
    lastOne,
}) => {
    const dispatch = useDispatch();

    const [isShowDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const {showAlert} = useContext(AlertContext);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(previous => !previous);
    };

    const deleteRequesterPopup = useCallback(
        data => dispatch({type: DELETE_REQUESTER_POPUP.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteRequesterPopup({
            uuid: initialItem.uuid,
            showAlert,
            callback: () => {
                setShowDeleteConfirmation(false);
                onSaveClicked(false);
                showAlert(ALERT_TYPE_SUCCESS);
            },
        });
    };

    const toggleEdit = () => {
        onEditClick(isSelected ? null : currentIndex);
    };

    return (
        <Box
            sx={{
                mx: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 0.5,
                    height: '48px',
                    p: 1,
                    '&:last-child': {
                        borderBottom: `none`,
                    },
                    backgroundColor: 'none',
                }}
            >
                <Typography sx={{fontWeight: 600}}>{`${popup.title} ${currentIndex + 1}`}</Typography>
                <EmptyArea />
                {!disabled && (
                    <>
                        <IconButton onClick={toggleDeleteConfirmation}>
                            <DeleteSvgIcon />
                        </IconButton>
                        <DeleteClientConfirmationModal
                            isShowModal={isShowDeleteConfirmation}
                            toggleModal={toggleDeleteConfirmation}
                            onClickAction={onClickDelete}
                        />
                    </>
                )}
                <Box onClick={toggleEdit}>
                    <IconButton sx={{p: 0.5}}>{isSelected ? <RemoveIcon /> : <AddIcon />}</IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    height: isSelected ? 'auto' : '0px',
                    pb: isSelected ? 1 : 0,
                    pl: 1,
                    transition: 'height 0.3s ease-in-out',
                    borderTop: isSelected
                        ? `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`
                        : lastOne && disabled
                        ? ''
                        : `1px solid ${theme.palette.info.contrastText}`,
                    borderBottom: isSelected ? `1px solid ${theme.palette.info.contrastText}` : '',
                }}
            >
                {isSelected && (
                    <PopupRowForm
                        toggleModal={toggleEdit}
                        initialItem={initialItem}
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        popup={popup}
                        clientInformation={clientInformation}
                        agentInformation={agentInformation}
                        disabled={disabled}
                        onSaveClicked={onSaveClicked}
                        isAgentPage={isAgentPage}
                        currentIndex={currentIndex}
                    />
                )}
            </Box>
        </Box>
    );
};

export default PopupContentRow;
