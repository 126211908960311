import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

import {AlertContext} from 'contexts/alert/context';

import {RootReducer} from 'appRedux/reducers';
import {OrganizationAdminReassignTypes, OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {ORGANIZATION_AGENT_REASSIGN} from 'appRedux/actions/organizationAdmin';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationAgentReassignSchema} from 'components/Forms/OrganizationAdminForm/validation';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {theme} from 'config/theme';

interface ReassignAgentFormType {
    organizationId: number;
    toggleModal: () => void;
}

const ReassignAgentForm: FC<ReassignAgentFormType> = ({organizationId, toggleModal}) => {
    const [t] = useTranslation();
    const schema = () => validationAgentReassignSchema(t);
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading, organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ORGANIZATION_AGENT_REASSIGN.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                agent: '',
            }}
            validationSchema={schema}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    id: organizationId,
                    setErrors,
                    callback: toggleModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<OrganizationAdminReassignTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('superadmin.users.selectAgent')}
                            </Typography>
                            <FormControl
                                sx={{backgroundColor: theme.palette.warning.contrastText, pl: 3, pr: 3, pb: 2, mb: 1}}
                                required
                                fullWidth
                            >
                                <Autocomplete
                                    sx={{
                                        mt: 1,
                                        height: 50,
                                    }}
                                    options={organizationAdminList}
                                    getOptionLabel={(option: OrganizationAdminListTypes) => {
                                        const {firstName, lastName, email} = option.admin;
                                        return firstName && lastName ? `${firstName} ${lastName} (${email})` : email;
                                    }}
                                    onChange={(event, value) => {
                                        formik.setFieldValue('agent', value ? value.admin.id : ''); // Update formik with the selected value
                                    }}
                                    renderInput={params => (
                                        <AutoDirectionTextField
                                            {...params}
                                            name="agent"
                                            variant="standard"
                                            label="Select Agent"
                                            fullWidth
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.admin.id === value.admin.id}
                                />
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isLoading={isLoading} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReassignAgentForm;
