import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';
import {TOGGLE_WORKFLOW_STATUS_RULE} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {
    getWorkflowRuleLabel,
    WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED,
} from 'components/Forms/Workflow/workflowStatusRule/helper';
import EmptyArea from 'components/EmptyArea';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

import DeleteWorkflowRuleButton from 'pages/admin/updateWorkflow/partials/rules/DeleteWorkflowRuleButton';
import WorkflowRuleApprovalDetails from 'pages/admin/updateWorkflow/partials/rules/WorkflowRuleApprovalDetails';

import {theme} from 'config/theme';

interface WorkflowRulesListItemType {
    item: WorkflowStatusRuleItemTypes;
}

const WorkflowRulesListItem: FC<WorkflowRulesListItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {isActive, uuid} = item;

    const [isRuleActive, setIsRuleActive] = useState<boolean>(isActive);

    const changeRuleStatus = useCallback(
        data => dispatch({type: TOGGLE_WORKFLOW_STATUS_RULE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        setIsRuleActive(!isRuleActive);
        changeRuleStatus({
            uuid,
            showAlert,
            callbackError: () => setIsRuleActive(false),
        });
    };

    return (
        <Box
            sx={{
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: 2,
                p: 2,
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography>{t(getWorkflowRuleLabel(item.type))}</Typography>
                <EmptyArea />
                <CustomSwitcher checked={isActive} onClick={handleChangeStatus} />
                <DeleteWorkflowRuleButton item={item} />
            </Box>
            {item.type === WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED && <WorkflowRuleApprovalDetails item={item} />}
        </Box>
    );
};

export default WorkflowRulesListItem;
