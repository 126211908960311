import {AgentsWithCaseAccessTypes} from 'appRedux/actions/crypto/types';

export const getFilteredAgents = (
    agents: AgentsWithCaseAccessTypes[],
    searchValue: string | null,
): AgentsWithCaseAccessTypes[] => {
    if (!searchValue || searchValue === '') {
        return agents;
    }
    const results: AgentsWithCaseAccessTypes[] = [];
    const searchValueLowerCase = searchValue.toLowerCase();
    agents.forEach(agent => {
        const {firstName, lastName, email} = agent;
        if (
            (firstName && firstName.toLowerCase().includes(searchValueLowerCase)) ||
            (lastName && lastName.toLowerCase().includes(searchValueLowerCase))
        ) {
            results.push(agent);
        }
    });
    return results;
};
