import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment/moment';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import {ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';

import {getResourceFieldWeekdayLabel} from 'components/Forms/ResourceField/helper';

import {isBlockedDate, isBeforeAndNotToday} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_FORMAT_FILTER} from 'config/index';
import {theme} from 'config/theme';

interface CalendarWeekdayItemProps {
    date: Date;
    selectedDate: Date | null;
    setSelectedDate: (date: Date) => void;
    blockedDatesExceptions: ResourceFieldExceptionItemTypes[];
}

const CalendarWeekdayItem: FC<CalendarWeekdayItemProps> = ({
    date,
    selectedDate,
    setSelectedDate,
    blockedDatesExceptions,
}) => {
    const [t] = useTranslation();

    const weekday = date.getDay();
    const isSelected = Boolean(selectedDate && date.toDateString() === selectedDate.toDateString());

    const currentDate = moment(date).format(DATE_FORMAT_FILTER);

    return (
        <ListItemButton
            key={`weekday-${weekday}`}
            selected={isSelected}
            onClick={() => setSelectedDate(date)}
            disableRipple
            sx={{
                '&.MuiButtonBase-root': {
                    backgroundColor: theme.palette.secondary.contrastText,
                },
                '& p, & span': {
                    color: theme.palette.info.main,
                    fontWeight: 700,
                },
                borderRadius: 1,
                mb: 0.5,
                ' &.Mui-selected': {
                    backgroundColor: theme.palette.info.main,
                    '& p, & span': {
                        color: theme.palette.background.default,
                    },
                },
                '&:hover, &.Mui-selected:hover': {
                    backgroundColor: theme.palette.info.main,
                    '& p, & span': {
                        color: theme.palette.background.default,
                    },
                },
                opacity:
                    isBeforeAndNotToday(currentDate) || isBlockedDate(blockedDatesExceptions, currentDate) ? 0.5 : 1,
            }}
        >
            <ListItemText
                primary={t(getResourceFieldWeekdayLabel(weekday))}
                secondary={convertDateToCurrentLocale(date, true)}
            />
        </ListItemButton>
    );
};

export default CalendarWeekdayItem;
