import React, {FC} from 'react';

import {withStyles, WithStyles} from '@material-ui/core';

import CustomMdxEditor from 'components/MdxEditorField/CustomMdxEditor';
import {mdxEditorSmallStyles} from 'components/MdxEditorField/MdxEditorStyles';

interface CustomMdxEditorSmallTypes extends WithStyles<typeof mdxEditorSmallStyles> {
    label?: string;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const CustomMdxEditorSmall: FC<CustomMdxEditorSmallTypes> = ({classes, ...rest}) => {
    return <CustomMdxEditor mdxEditorClassName={classes.mdxEditorWrapper} {...rest} />;
};

export default withStyles(mdxEditorSmallStyles)(CustomMdxEditorSmall);
