import React, {FC, useCallback, useContext, useRef, useState} from 'react';
import {get, isEmpty} from 'lodash';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {UPLOAD_REQUESTER_DOCUMENT} from 'appRedux/actions/requestCase';
import {UploadRequesterCaseTypes} from 'appRedux/actions/requestCase/types';

import {ClientFormContext} from 'contexts/clientForm/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {AlertContext} from 'contexts/alert/context';

import FilesListWithGallery from 'components/FilesListWithGallery/FilesListWithGallery';
import {getFormFieldFilesForGallery} from 'components/FilesListWithGallery/helper';
import InfectedFilePopup from 'components/InfectedFilePopup/InfectedFilePopup';
import FileDropArea from 'components/ClientScreenComponents/FileDropArea';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import FormUploaderWrapper from 'pages/client/form/wrappers/FormUploaderWrapper';
import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';
import {validationSchema} from 'pages/client/form/fieldTypes/validation';
import {base64ToFile, createThumbnail} from 'pages/client/form/fieldTypes/helper';
import {getFileExtension} from 'pages/client/form/helper';

import FileUploadThumbButton from 'components/ClientScreenComponents/FileUploadThumbButton';

import {encryptBase64FileWithKey, extractBase64FromFileContent, encryptStringWithKey} from 'helpers/cryptoApiHelper';
import {ERROR_TEXT_COLOR} from 'config/theme';

interface FormUploaderFieldType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    disabled: boolean;
    isAgentPage: boolean;
    isPopup?: boolean;
    popupItemId?: number;
    previewMode?: boolean;
}

const FormUploaderField: FC<FormUploaderFieldType> = ({
    formId,
    pageId,
    sectionId,
    field,
    popupItemId,
    disabled,
    previewMode,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {toggleSectionChecked, setErrorField} = useContext(ClientFormContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {showAlert} = useContext(AlertContext);

    const submitUploadFile = useCallback(
        data => dispatch({type: UPLOAD_REQUESTER_DOCUMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCase: {id, files, isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const filesNumber = get(field, 'options', 1);

    const uploadedFiles = files.filter((file: RequesterDocumentResponseType) => {
        const {caseId, formField, requesterCaseHasPopupId} = file;
        if (requesterCaseHasPopupId) {
            return (
                Number(caseId) === Number(requestCase) &&
                formField === `form-${formId}-field-${field.id}` &&
                requesterCaseHasPopupId === Number(popupItemId)
            );
        }
        return Number(caseId) === Number(requestCase) && formField === `form-${formId}-field-${field.id}`;
    });

    const allFiles: CommonFileType[] = getFormFieldFilesForGallery(uploadedFiles);

    const wrapperRef = useRef<HTMLDivElement | undefined>();
    const inputFileRef = useRef<HTMLDivElement | null>(null);

    const loadFileClick = () => {
        inputFileRef.current?.click();
    };

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [attemptsNumber, setAttemptsNumber] = useState<number | null>(null);

    const toggleIsOpened = () => {
        setShowPopup(previous => !previous);
    };

    const cleanErrorField = () => {
        setErrorField && setErrorField(null);
    };

    const showFileInfectedMessage = (value: number | null) => {
        setShowPopup(true);
        setAttemptsNumber(value);
    };

    const fileSubmitHandler = (
        values: Omit<UploadRequesterCaseTypes, 'thumb'> & {thumb: string | null},
        {setErrors, resetForm},
    ) => {
        resetForm();
        if (previewMode) return;

        const fileName = get(values, ['file', 'name'], '');

        if (isEncryptInfo && unwrappedCaseKey) {
            const reader = new FileReader();
            reader.readAsDataURL(values.file as Blob);
            reader.addEventListener('load', async e => {
                const fileContent = get(e, ['currentTarget', 'result'], null);
                const {base64, prefix} = extractBase64FromFileContent(fileContent);

                if (base64) {
                    const encrypted = await encryptBase64FileWithKey(base64, unwrappedCaseKey);
                    const extension = getFileExtension(fileName);
                    const encryptedFileName = await encryptStringWithKey(fileName, unwrappedCaseKey);

                    const encryptedFile = new Blob([encrypted], {
                        type: 'application/octet-stream',
                    });

                    const thumb = get(values, ['thumb'], '');
                    let encryptedThumb: Blob | null = null;

                    if (thumb) {
                        const {base64: thumbBase64} = extractBase64FromFileContent(thumb);
                        const encryptedArr = thumbBase64
                            ? await encryptBase64FileWithKey(thumbBase64, unwrappedCaseKey)
                            : '';
                        encryptedThumb = new Blob([encryptedArr], {
                            type: 'application/octet-stream',
                        });
                    }

                    submitUploadFile({
                        ...values,
                        file: encryptedFile,
                        encryptPrefix: prefix,
                        fileName: `${encryptedFileName}.${extension}`,
                        description: `${field.label} - ${uploadedFiles.length + 1}`,
                        thumb: encryptedThumb,
                        requesterCaseHasPopupId: popupItemId ? Number(popupItemId) : null,
                        setErrors,
                        callback: cleanErrorField,
                        callbackInfectedFileError: showFileInfectedMessage,
                        callbackError: toggleSectionChecked,
                        showAlert,
                    });
                }
            });
        } else {
            submitUploadFile({
                ...values,
                thumb: values.thumb && base64ToFile(values.thumb),
                requesterCaseHasPopupId: popupItemId ? Number(popupItemId) : null,
                fileName,
                description: `${field.label} - ${uploadedFiles.length + 1}`,
                setErrors,
                callback: cleanErrorField,
                callbackInfectedFileError: showFileInfectedMessage,
                callbackError: toggleSectionChecked,
                showAlert,
            });
        }
        resetForm();
    };

    console.log(field.label, uploadedFiles);

    return (
        <FormUploaderWrapper wrapperRef={wrapperRef} formId={formId} field={field} previewMode={previewMode}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2, flexWrap: 'wrap'}}>
                <FilesListWithGallery
                    caseId={Number(id)}
                    allFiles={allFiles}
                    deleteNotPossible={disabled}
                    sectionId={sectionId}
                />
                {Number(filesNumber) > uploadedFiles.length && (
                    <FileUploadThumbButton loadFileClick={loadFileClick} disabled={disabled} />
                )}

                <Formik
                    initialValues={{
                        description: '',
                        caseId: Number(requestCase),
                        formId,
                        sectionId,
                        fieldId: field.id,
                        file: '',
                        thumb: null,
                        requesterCaseHasPopupId: null,
                        fileName: '',
                    }}
                    validationSchema={() => validationSchema(t)}
                    onSubmit={fileSubmitHandler}
                >
                    {formik => {
                        return (
                            <Box>
                                {Number(filesNumber) > uploadedFiles.length && !disabled && (
                                    <FileDropArea
                                        onDropFile={(file: File) => {
                                            formik.resetForm();
                                            cleanErrorField();
                                            formik.setFieldValue('file', file);
                                            createThumbnail(file)
                                                .then(thumb => {
                                                    thumb && formik.setFieldValue('thumb', thumb);
                                                })
                                                .finally(() => {
                                                    setTimeout(() => formik.submitForm(), 100);
                                                });
                                        }}
                                        wrapperRef={wrapperRef}
                                    />
                                )}
                                <AutoDirectionTextField
                                    data-id={`input#form-uploader-${formId}-${pageId}-${sectionId}-${field.id}-file`}
                                    sx={{display: 'none'}}
                                    name="file"
                                    type="file"
                                    inputRef={inputFileRef}
                                    onChange={value => {
                                        formik.resetForm();
                                        const file: File = get(value, ['currentTarget', 'files', '0'], null);
                                        if (file) {
                                            formik.setFieldValue('file', file);
                                            cleanErrorField();
                                            createThumbnail(file)
                                                .then(thumb => {
                                                    thumb && formik.setFieldValue('thumb', thumb);
                                                })
                                                .finally(() => {
                                                    setTimeout(() => formik.submitForm(), 100);
                                                });
                                        }
                                    }}
                                />
                                {!isEmpty(formik.errors) && (
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            color: ERROR_TEXT_COLOR,
                                            fontSize: 12,
                                            width: '100%',
                                        }}
                                    >
                                        {t(String(formik.errors.file))}
                                    </Typography>
                                )}
                            </Box>
                        );
                    }}
                </Formik>
            </Box>
            <InfectedFilePopup
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                toggleIsOpened={toggleIsOpened}
                attemptsNumber={attemptsNumber}
            />
        </FormUploaderWrapper>
    );
};

export default FormUploaderField;
