import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';
import {LanguageSelectorItemTypes, getLanguagesList} from 'components/LanguageSelector/helper';

import {getLanguageById} from 'helpers/translationsHelper';

interface LanguageSelectorType {
    languageId: number;
    setLanguageId: (value: number) => void;
    languagesWithBadgeIds: number[];
    hideOrganizationLanguage?: boolean;
}

const LanguageSelector: FC<LanguageSelectorType> = ({
    languageId,
    setLanguageId,
    languagesWithBadgeIds,
    hideOrganizationLanguage,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isSuperAdminPage} = useContext(RouteContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {languageList, organizationLanguages},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: number) => {
        setLanguageId(value);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!isSuperAdminPage && myOrganization) {
            getOrganizationLanguages({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [isSuperAdminPage, myOrganization]);

    const open = Boolean(anchorEl);

    const languages: LanguageSelectorItemTypes[] = getLanguagesList(
        languageList,
        organizationLanguages,
        isSuperAdminPage,
    );

    const buttonTitle = !languageId
        ? t('orguser.forms.selectLanguage')
        : `${t('orguser.forms.selectLanguage')}: ${getLanguageById(languageList, languageId).name}`;

    return (
        <>
            <AgentSelectButton title={buttonTitle} onClick={handleClick} open={open} />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClick={handleClose} handleClose={handleClose}>
                {languages &&
                    languages.map((item: LanguageSelectorItemTypes) => {
                        const {value, name} = item;
                        if (myOrganization && hideOrganizationLanguage && value === myOrganization.language) {
                            return null;
                        }
                        return (
                            <MenuItem
                                value={value}
                                key={`selector-language-${value}`}
                                onClick={() => handleChange(value)}
                            >
                                <Badge
                                    color="error"
                                    variant="dot"
                                    invisible={!languagesWithBadgeIds.includes(value)}
                                    sx={{
                                        '& span': {
                                            right: '-5px',
                                        },
                                    }}
                                >
                                    <Typography>{name}</Typography>
                                </Badge>
                            </MenuItem>
                        );
                    })}
            </MenuAnchorWrapper>
        </>
    );
};

export default LanguageSelector;
