import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_RESOURCE_FIELD_EXCEPTION} from 'appRedux/actions/resourceFields';
import {ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';

import {AlertContext} from 'contexts/alert/context';

import UpdateResourceFieldExceptionForm from 'components/Forms/ResourceField/UpdateResourceFieldExceptionForm';

import CalendarDetailsItem from 'pages/admin/updateResourceField/calendar/CalendarDetailsItem';
import {getExceptionSubtitle} from 'pages/admin/updateResourceField/exception/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_FORMAT} from 'config/index';

interface ExceptionsListItemType {
    item: ResourceFieldExceptionItemTypes;
}

const ExceptionsListItem: FC<ExceptionsListItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);

    const toggleUpdateModal = () => {
        setIsShowUpdateModal(previous => !previous);
    };

    const {date, uuid} = item;

    const removeException = useCallback(
        data => dispatch({type: DELETE_RESOURCE_FIELD_EXCEPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeException({
            uuid,
            showAlert,
        });
    };

    const subtitle = getExceptionSubtitle(t, item);

    return (
        <CalendarDetailsItem
            isShowUpdateModal={isShowUpdateModal}
            toggleUpdateModal={toggleUpdateModal}
            title={convertDateToCurrentLocale(new Date(date), true, true)}
            subtitle={subtitle}
            onClickDelete={onClickDelete}
            updateForm={<UpdateResourceFieldExceptionForm item={item} closeModal={() => setIsShowUpdateModal(false)} />}
            modalTitle={`${t('orguser.resourceFields.updateResourceField')} (${t(
                'orguser.resourceFields.calendarTypes.exceptions',
            )})`}
        />
    );
};

export default ExceptionsListItem;
