import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {format} from 'date-fns';

import {ResourceFieldExceptionFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_EXCEPTION} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldExceptionForm from 'components/Forms/ResourceField/ResourceFieldExceptionForm';
import {CALENDAR_EXCEPTION_CHANGED_TIMES} from 'components/Forms/ResourceField/helper';

import {getInitialDate} from 'helpers/datepickerHelper';

import {DATEPICKER_FORMAT} from 'config/index';

interface CreateResourceFieldBookframeFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldExceptionForm: FC<CreateResourceFieldBookframeFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [date, setDate] = useState<Date>(getInitialDate(null));

    const createResourceFieldException = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_EXCEPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldExceptionFormTypes) => {
        createResourceFieldException({
            ...values,
            date,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldExceptionForm
            initialValues={{
                date: format(date, DATEPICKER_FORMAT),
                type: CALENDAR_EXCEPTION_CHANGED_TIMES,
                startTime: '09:00',
                endTime: '12:00',
            }}
            date={date}
            setDate={setDate}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateResourceFieldExceptionForm;
