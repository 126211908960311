import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_FIELD_ADD,
    addFormField,
    FORM_FIELD_UPDATE,
    updateFormField,
    FORM_FIELD_GDPR_SETTINGS_UPDATE,
    updateFormFieldGdprSettings,
    FORM_FIELD_METADATA_UPDATE,
    updateFormFieldMetadata,
    FORM_FIELD_COPY,
    copyFormField,
    FORM_FIELD_DELETE,
    deleteFormField,
    FORM_FIELD_UP,
    formFieldUpPriority,
    FORM_FIELD_DOWN,
    formFieldDownPriority,
    FORM_FIELD_CREATE_OPTION,
    createFormFieldOption,
    FORM_FIELD_UPDATE_OPTION,
    updateFormFieldOption,
    FORM_FIELD_DELETE_OPTION,
    deleteFormFieldOption,
    FORM_FIELD_CUSTOM_OPTION,
    setCustomFormFieldOption,
    FORM_FIELD_CHANGE_OPTION_PRIORITY,
    changeFormFieldOptionPriority,
    FORM_FIELD_MOVE,
    formFieldMoving,
    FORM_FIELD_ADD_RELATED_SECTION,
    formFieldAddRelatedSection,
    FORM_FIELD_REMOVE_RELATED_SECTION,
    formFieldRemoveRelatedSection,
    FORM_FIELD_ADD_RELATED_SUBFORM,
    formFieldAddRelatedSubForm,
    FORM_FIELD_REMOVE_RELATED_SUBFORM,
    formFieldRemoveRelatedSubForm,
    FORM_FIELD_IMPORT_OPTIONS,
    importFormFieldOptions,
} from 'appRedux/actions/forms';
import {
    FormTotalInformationTypes,
    FormAddFieldRequest,
    CommonFormBuilderRequest,
    FormChangeStatusType,
    OptionFileCreateResponseType,
    OptionFileUpdateResponseType,
    OptionFileRequestType,
    FormFieldMoveRequestType,
    FormItemBasicResponseTypes,
    FormFieldAddRelatedSection,
    FormItemPagesResponseTypes,
    FormFieldGdprSettingsUpdateRequestType,
    FormFieldMetadataUpdateRequestType,
    ImportOptionsResponseType,
    FormBuilderResponseTypes,
    FieldOptionChangePriorityRequestTypes,
    OptionRelateSectionRequestType,
    OptionRelateSubFormRequestType,
    OptionRelateRequestType,
} from 'appRedux/actions/forms/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormFieldAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, setErrors, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_ADD.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addFormField.success(data.results));
                callback && data.fieldId && callback(data.fieldId);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, setErrors, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormField.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldGdprSettingsUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormFieldGdprSettingsUpdateRequestType> = yield take(FORM_FIELD_GDPR_SETTINGS_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/gdpr/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormFieldGdprSettings.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(updateFormFieldGdprSettings.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormFieldGdprSettings.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldMetadataUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, setErrors, ...values},
        }: IActionType<FormFieldMetadataUpdateRequestType> = yield take(FORM_FIELD_METADATA_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/metadata/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormFieldMetadata.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormFieldMetadata.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormFieldMetadata.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_FIELD_DELETE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormField.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldCopying() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_FIELD_COPY.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/copy`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(copyFormField.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyFormField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyFormField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldMoving() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormFieldMoveRequestType> = yield take(FORM_FIELD_MOVE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/move`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formFieldMoving.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldMoving.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldMoving.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldUpPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FIELD_UP.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/up`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formFieldUpPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDownPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FIELD_DOWN.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `field/${id}/down`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formFieldDownPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formFieldDownPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formFieldDownPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldAddRelatedSection() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OptionRelateSectionRequestType> = yield take(FORM_FIELD_ADD_RELATED_SECTION.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${uuid}/relate/section`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formFieldAddRelatedSection.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldAddRelatedSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(formFieldAddRelatedSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDeleteRelatedSection() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<OptionRelateRequestType> = yield take(FORM_FIELD_REMOVE_RELATED_SECTION.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${uuid}/remove/section`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(formFieldRemoveRelatedSection.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldRemoveRelatedSection.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(formFieldRemoveRelatedSection.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldAddRelatedSubForm() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OptionRelateSubFormRequestType> = yield take(FORM_FIELD_ADD_RELATED_SUBFORM.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${uuid}/relate/subform`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formFieldAddRelatedSubForm.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldAddRelatedSubForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(formFieldAddRelatedSubForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldDeleteRelatedSubForm() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<OptionRelateRequestType> = yield take(FORM_FIELD_REMOVE_RELATED_SUBFORM.REQUEST);
        try {
            const data: FormItemPagesResponseTypes = yield call(http, `option/${uuid}/remove/subform`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(formFieldRemoveRelatedSubForm.success(data.pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formFieldRemoveRelatedSubForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(formFieldRemoveRelatedSubForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileCreation() {
    while (true) {
        const {
            payload: {fieldId, title, text, priority, file, callback, showAlert, setErrors},
        }: IActionType<OptionFileCreateResponseType> = yield take(FORM_FIELD_CREATE_OPTION.REQUEST);
        try {
            const formData = new FormData();
            formData.append('priority', String(priority));
            formData.append('title', title);
            formData.append('text', text || '');
            file && formData.append('media', file);

            const data: FormBuilderResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `field/${fieldId}/option/create`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(createFormFieldOption.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(createFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileUpdating() {
    while (true) {
        const {
            payload: {uuid, title, text, priority, file, callback, showAlert, setErrors},
        }: IActionType<OptionFileUpdateResponseType> = yield take(FORM_FIELD_UPDATE_OPTION.REQUEST);
        try {
            const formData = new FormData();
            formData.append('priority', String(priority));
            formData.append('title', title);
            formData.append('text', text || '');
            file && formData.append('media', file);

            const data: FormBuilderResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `field/${uuid}/option/update`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(updateFormFieldOption.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileDeleting() {
    while (true) {
        const {
            payload: {fieldId, callback, showAlert},
        }: IActionType<OptionFileRequestType> = yield take(FORM_FIELD_DELETE_OPTION.REQUEST);
        try {
            const data: FormBuilderResponseTypes = yield call(http, `field/${fieldId}/option/delete`, {
                method: 'DELETE',
            });

            if (data.success) {
                yield put(deleteFormFieldOption.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionFileCustomSetting() {
    while (true) {
        const {
            payload: {fieldId, callback, showAlert},
        }: IActionType<OptionFileRequestType> = yield take(FORM_FIELD_CUSTOM_OPTION.REQUEST);
        try {
            const data: FormBuilderResponseTypes = yield call(http, `field/${fieldId}/option/custom`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(setCustomFormFieldOption.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(setCustomFormFieldOption.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(setCustomFormFieldOption.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionsImport() {
    while (true) {
        const {
            payload: {fieldId, items, method, callback, showAlert},
        }: IActionType<ImportOptionsResponseType> = yield take(FORM_FIELD_IMPORT_OPTIONS.REQUEST);
        try {
            const data: FormBuilderResponseTypes = yield call(http, `field/${fieldId}/options/import`, {
                method: 'POST',
                body: JSON.stringify({method, items}),
            });

            if (data.success) {
                yield put(importFormFieldOptions.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(importFormFieldOptions.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(importFormFieldOptions.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldOptionPriorityChanging() {
    while (true) {
        const {
            payload: {uuid, priority, showAlert, callback, ...values},
        }: IActionType<FieldOptionChangePriorityRequestTypes> = yield take(FORM_FIELD_CHANGE_OPTION_PRIORITY.REQUEST);
        try {
            const data: FormBuilderResponseTypes = yield call(http, `field/${uuid}/option/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeFormFieldOptionPriority.success(data.pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeFormFieldOptionPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(changeFormFieldOptionPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormFieldAdding),
    fork(watchFormFieldUpdating),
    fork(watchFormFieldGdprSettingsUpdating),
    fork(watchFormFieldMetadataUpdating),
    fork(watchFormFieldDeleting),
    fork(watchFormFieldCopying),
    fork(watchFormFieldMoving),
    fork(watchFormFieldUpPriority),
    fork(watchFormFieldDownPriority),
    fork(watchFormFieldAddRelatedSection),
    fork(watchFormFieldDeleteRelatedSection),
    fork(watchFormFieldAddRelatedSubForm),
    fork(watchFormFieldDeleteRelatedSubForm),
    fork(watchFormFieldOptionFileCreation),
    fork(watchFormFieldOptionFileUpdating),
    fork(watchFormFieldOptionFileDeleting),
    fork(watchFormFieldOptionFileCustomSetting),
    fork(watchFormFieldOptionsImport),
    fork(watchFormFieldOptionPriorityChanging),
];
