import React, {FC, useContext, useState} from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import ChatReadSvgIcon from 'assets/icons/small/ChatReadSvgIcon';
import ChatSentSvgIcon from 'assets/icons/small/ChatSentSvgIcon';

import {MediaContext} from 'contexts/media/context';

import TextWrappedLabel from 'components/TextWrappedLabel';
import AttachedDocumentLabel from 'components/RequesterChat/AttachedDocumentLabel';
import AttachedSectionLabel from 'components/RequesterChat/AttachedSectionLabel';
import AttachedThumb from 'components/RequesterChat/AttachedThumb';
import {getChatMessageBlockColor} from 'components/RequesterChat/helper';
import ChatMessageBlockOptions from 'components/RequesterChat/partials/ChatMessageBlockOptions';
import ChatMessageTitle from 'components/RequesterChat/partials/ChatMessageTitle';

import {theme} from 'config/theme';

interface ChatMessageBlockType {
    item: MessageTypes;
    userId: number | null;
    caseId: number;
    text: string;
    isCurrentUserSender: boolean;
    previousMessageSenderSame: boolean;
    isMessenger?: boolean;
}

const ChatMessageBlock: FC<ChatMessageBlockType> = ({
    item,
    userId,
    caseId,
    text,
    isCurrentUserSender,
    previousMessageSenderSame,
    isMessenger,
}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const [isButtonShow, setIsButtonShow] = useState<boolean>(false);

    const {readAt, sectionId, file, createdAt, thumb, hasMarkdown, isSystemMessage, updatedAt} = item;

    return (
        <ClickAwayListener onClickAway={() => setIsButtonShow(false)}>
            <Box onMouseEnter={() => setIsButtonShow(true)} onMouseLeave={() => setIsButtonShow(false)}>
                <ChatMessageBlockOptions
                    isButtonShow={isButtonShow}
                    isCurrentUserSender={isCurrentUserSender}
                    item={item}
                    userId={Number(userId)}
                    wrapperSx={{
                        backgroundColor: getChatMessageBlockColor(isCurrentUserSender, isSystemMessage, isMessenger),
                        borderRadius: 2,
                        p: 1,
                        pb: 0.5,
                        maxWidth: '100%',
                        width:
                            !thumb && (sectionId || file || !previousMessageSenderSame || isSystemMessage)
                                ? '100%'
                                : 'fit-content',
                        ml: isCurrentUserSender ? 'auto' : 0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        flexDirection: sectionId || file ? 'column' : 'row',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flexGrow: sectionId || file ? 1 : 0,
                            overflow: 'hidden',
                        }}
                    >
                        <ChatMessageTitle
                            item={item}
                            isCurrentUserSender={isCurrentUserSender}
                            previousMessageSenderSame={previousMessageSenderSame}
                        />
                        {thumb && <AttachedThumb item={item} userId={userId} />}
                        {hasMarkdown ? (
                            <Box
                                sx={{
                                    color:
                                        isCurrentUserSender || isSystemMessage
                                            ? theme.palette.info.main
                                            : theme.palette.background.default,
                                    '& p': {
                                        mt: 0,
                                        mb: 1,
                                        fontSize: isMobile ? 12 : 14,
                                        lineHeight: '22px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    },
                                    '& ul': {
                                        mt: 0,
                                        mb: 1,
                                        fontSize: isMobile ? 12 : 14,
                                        lineHeight: '18px',
                                    },
                                    '& li': {
                                        mt: 0,
                                        mb: 1,
                                        fontSize: isMobile ? 12 : 14,
                                        lineHeight: '18px',
                                    },
                                    '& a': {
                                        color:
                                            isCurrentUserSender || isSystemMessage
                                                ? 'default'
                                                : theme.palette.primary.main,
                                    },
                                }}
                            >
                                <Markdown
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        a: (props: any) => {
                                            if (!props.href) return null;
                                            return (
                                                <a href={props.href} target="_blank" rel="noreferrer">
                                                    {props.children}
                                                </a>
                                            );
                                        },
                                    }}
                                >
                                    {text}
                                </Markdown>
                            </Box>
                        ) : (
                            <TextWrappedLabel
                                text={text}
                                color={
                                    isCurrentUserSender || isSystemMessage
                                        ? theme.palette.info.main
                                        : theme.palette.background.default
                                }
                            />
                        )}
                        {sectionId && <AttachedSectionLabel item={item} caseId={caseId} />}
                        {file && !thumb && <AttachedDocumentLabel item={item} userId={userId} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            flexGrow: 1,
                            alignItems: 'center',
                            mt: 'auto',
                            ml: 2,
                        }}
                    >
                        {updatedAt && (
                            <Typography
                                sx={{
                                    color:
                                        isCurrentUserSender || isSystemMessage
                                            ? theme.palette.primary.contrastText
                                            : theme.palette.background.default,
                                    fontSize: isMobile ? 11 : 12,
                                    mr: 1,
                                }}
                            >
                                {t('requester.chat.edited')}
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                color:
                                    isCurrentUserSender || isSystemMessage
                                        ? theme.palette.primary.contrastText
                                        : theme.palette.background.default,
                                fontSize: isMobile ? 11 : 12,
                                mr: 0.5,
                            }}
                        >
                            {moment(createdAt).format('HH:mm')}
                        </Typography>
                        {readAt ? <ChatReadSvgIcon /> : <ChatSentSvgIcon />}
                    </Box>
                </ChatMessageBlockOptions>
            </Box>
        </ClickAwayListener>
    );
};

export default ChatMessageBlock;
