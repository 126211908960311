import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {useParams} from 'react-router';

import {RootReducer} from 'appRedux/reducers';
import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {RouteContext} from 'contexts/route/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import MultipleActionButtonsPanel from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/MultipleActionButtonsPanel';

import RequestersDataGrid from 'pages/agent/tableView/RequestersDataGrid';

import {
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    DEFAULT_PAGE,
    ITEMS_PER_PAGE_DEFAULT,
    PARAMETER_SORT_TYPE,
    PARAMETER_SORT_BY,
} from 'config/index';
import {SET_CURRENT_BOARD} from 'appRedux/actions/workflow';

interface RequestersListType {
    isTableView?: boolean;
}

const RequestersList: FC<RequestersListType> = ({isTableView}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle, setCustomBoardTitle} =
        useContext(RouteContext);

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const optionsFromUrl = searchParams.get(PARAMETER_OPTION);
    const sortByFromUrl = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeFromUrl = searchParams.get(PARAMETER_SORT_TYPE);

    const getRequesterCasesTable = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_TABLE, payload: data}),
        [dispatch],
    );

    const getRequesterCasesCustomBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_OVERVIEW, payload: data}),
        [dispatch],
    );

    const setCurrentBoardState = useCallback(data => dispatch({type: SET_CURRENT_BOARD, payload: data}), [dispatch]);

    const {
        admin: {requesterCasesNumber, requesterCasesList},
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const getCasesRequest = isTableView ? getRequesterCasesTable : getRequesterCasesCustomBoard;
        getCasesRequest({
            uuid,
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            options: getParameterFromUrl(optionsFromUrl),
            page: pageFromUrl ?? DEFAULT_PAGE,
            perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            sortBy: sortByFromUrl,
            sortType: sortTypeFromUrl,
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    }, [
        uuid,
        agentFromUrl,
        formFromUrl,
        searchFromUrl,
        tagsFromUrl,
        optionsFromUrl,
        pageFromUrl,
        perPageFromUrl,
        lastCreatedFromUrl,
        lastUpdatedFromUrl,
        sortByFromUrl,
        sortTypeFromUrl,
        isTableView,
    ]);

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(null);
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });
        if (current) {
            setTopBarTitle(current.title);
            setCustomBoardTitle(current.title);
        } else {
            setTopBarTitle('');
        }
    }, [uuid, boards]);

    useEffect(() => {
        const params: [string, string][] = [];

        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });

        for (const entry of searchParams.entries()) {
            params.push(entry);
        }
        if (current) {
            setCurrentBoardState({id: current.uuid, params});
        }
    }, [uuid, boards, searchParams]);

    return (
        <DataGridWrapper
            itemsList={requesterCasesList}
            itemsTotalNumber={requesterCasesNumber ?? 0}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS_DEFAULT}
        >
            {requesterCasesList && <RequestersDataGrid items={requesterCasesList} isTableView={isTableView} />}
            <MultipleActionButtonsPanel />
        </DataGridWrapper>
    );
};

export default RequestersList;
