import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {FormHasLanguageTypes, FormPageTypes} from 'appRedux/actions/forms/types';
import {FORM_TRANSLATION_AUTO} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface GenerateAutoTranslationsButtonType {
    page: FormPageTypes;
    currentFormLanguage: FormHasLanguageTypes;
}

const GenerateAutoTranslationsButton: FC<GenerateAutoTranslationsButtonType> = ({page, currentFormLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const generateAutoTranslations = useCallback(
        data => dispatch({type: FORM_TRANSLATION_AUTO.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickGenerate = () => {
        generateAutoTranslations({
            id: page.id,
            lang: currentFormLanguage.language.name,
            showAlert,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
        >
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.formTranslations.generateTranslations')}
                onClick={onClickGenerate}
            />
        </Box>
    );
};

export default GenerateAutoTranslationsButton;
