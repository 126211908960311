import React, {ChangeEvent, FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as EmailValidator from 'email-validator';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';

import EmptyArea from 'components/EmptyArea';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface AddNewEmailType {
    emails: string[];
    setEmails: (value: string[]) => void;
    closeInput: () => void;
    toggleIsUpdated: () => void;
}

const AddNewEmail: FC<AddNewEmailType> = ({emails, setEmails, closeInput, toggleIsUpdated}) => {
    const [t] = useTranslation();

    const [value, setValue] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(null);
        const inputValue = String(e.target.value);
        setValue(inputValue);
    };

    const onSubmitClick = () => {
        if (value) {
            const isAlreadyExists = emails.some(item => item === value);
            if (!EmailValidator.validate(value)) {
                setErrorMessage('errorValueNotEmail');
            } else if (isAlreadyExists) {
                setErrorMessage('emailAlreadyExists');
            } else {
                emails.push(value);
                setEmails(emails);
                toggleIsUpdated();
                closeInput();
            }
        }
    };

    return (
        <Box
            key={`email-new`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
            }}
        >
            <FormControl>
                <AutoDirectionTextField sx={{width: 350}} variant="standard" value={value} onChange={handleChange} />
                {errorMessage && (
                    <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR, mt: 1, mb: 1}}>
                        {t(errorMessage)}
                    </Typography>
                )}
            </FormControl>
            <EmptyArea />
            <IconButton onClick={onSubmitClick}>
                <CheckSvgIcon />
            </IconButton>
            <IconButton onClick={closeInput}>
                <CloseSvgIcon />
            </IconButton>
        </Box>
    );
};

export default AddNewEmail;
