import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldExceptionFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationExceptionSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldCalendarExceptionTypes,
    ResourceFieldOptionType,
    CALENDAR_EXCEPTION_BLOCKED_DATE,
} from 'components/Forms/ResourceField/helper';
import FormBuilderDateInput from 'components/AgentScreenComponents/_form/FormBuilderDateInput';

interface ResourceFieldExceptionFormType {
    initialValues: ResourceFieldExceptionFormTypes;
    date: Date;
    setDate: (value: Date) => void;
    onSubmitClicked: (values: ResourceFieldExceptionFormTypes) => void;
    isCreate?: boolean;
}

const ResourceFieldExceptionForm: FC<ResourceFieldExceptionFormType> = ({
    date,
    setDate,
    initialValues,
    onSubmitClicked,
    isCreate,
}) => {
    const [t] = useTranslation();

    const handleChange = (value: Date): void => {
        setDate(value);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationExceptionSchema}
            onSubmit={(values, {setErrors}) =>
                onSubmitClicked({
                    ...values,
                    startTime: values.type === CALENDAR_EXCEPTION_BLOCKED_DATE ? null : values.startTime,
                    endTime: values.type === CALENDAR_EXCEPTION_BLOCKED_DATE ? null : values.endTime,
                    setErrors,
                })
            }
        >
            {(formik: FormikProps<ResourceFieldExceptionFormTypes>) => {
                return (
                    <Form>
                        <FormBuilderDateInput
                            fieldName={'date'}
                            date={date}
                            handleChange={handleChange}
                            formik={formik}
                        />
                        <FormControl sx={{mt: 2, mb: 1}} required fullWidth>
                            <Field
                                as="select"
                                required
                                name="type"
                                label={t('orguser.resourceFields.type')}
                                options
                                disabled={!isCreate}
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {resourceFieldCalendarExceptionTypes &&
                                    resourceFieldCalendarExceptionTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`exception-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                        </FormControl>
                        {formik.values.type !== CALENDAR_EXCEPTION_BLOCKED_DATE && (
                            <>
                                <Field
                                    name="startTime"
                                    type="time"
                                    label={t('orguser.resourceFields.startTime')}
                                    placeholder={t('orguser.resourceFields.startTime')}
                                    component={FormikTextInput}
                                />
                                <Field
                                    name="endTime"
                                    type="time"
                                    label={t('orguser.resourceFields.endTime')}
                                    placeholder={t('orguser.resourceFields.endTime')}
                                    component={FormikTextInput}
                                />
                            </>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldExceptionForm;
