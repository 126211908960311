import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {FormLabelTypes} from 'appRedux/actions/forms/types';

import FormLabelsListItem from 'pages/admin/updateForm/partials/FormLabels/FormLabelsListItem';

interface FormLabelsListType {
    items: FormLabelTypes[];
}

const FormLabelsList: FC<FormLabelsListType> = ({items}) => {
    return (
        <Box sx={{mt: 1}}>
            {items.map(item => {
                return <FormLabelsListItem key={`form-label-${item.uuid}`} item={item} />;
            })}
        </Box>
    );
};

export default FormLabelsList;
