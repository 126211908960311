import React, {FC, ReactNode} from 'react';

import Menu from '@mui/material/Menu';

import {theme} from 'config/theme';

interface MenuAnchorWrapperType {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClick?: () => void;
    handleClose?: () => void;
    children: ReactNode;
}

const MenuAnchorWrapper: FC<MenuAnchorWrapperType> = ({anchorEl, open, handleClick, handleClose, children}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClick={handleClick}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    mt: 1,
                    pl: 2,
                    pr: 2,
                    pt: 1,
                    pb: 1,
                    minWidth: 290,
                    borderRadius: 2,
                    overflow: 'visible',
                    boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                    backgroundColor: theme.palette.background.default,
                    '& ul': {
                        padding: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        >
            {children}
        </Menu>
    );
};

export default MenuAnchorWrapper;
