import React, {FC, useState, useContext, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {FORM_LABEL_CREATE, FORM_LABEL_ATTACH} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {FormLabelOptionTypes, getFormLabelOptions} from 'components/Forms/FormBuilder/FormLabelForm/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ComponentType {
    formId: number;
    closeModal: () => void;
}

const CreateFormLabelForm: FC<ComponentType> = ({formId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [value, setValue] = useState<FormLabelOptionTypes | null>(null);

    const createFormLabel = useCallback(data => dispatch({type: FORM_LABEL_CREATE.REQUEST, payload: data}), [dispatch]);

    const attachFormLabel = useCallback(data => dispatch({type: FORM_LABEL_ATTACH.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {
            formLabelsForOrganization,
            formInfo: {formLabels},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const filter = createFilterOptions<FormLabelOptionTypes>();

    const formLabelOptions = formLabelsForOrganization
        ? getFormLabelOptions(formLabelsForOrganization, formLabels)
        : [];

    return (
        <Box>
            <Box sx={{width: '100%'}}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        value={value}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setValue({
                                    title: newValue,
                                });
                                createFormLabel({
                                    id: formId,
                                    title: newValue,
                                    showAlert,
                                    callback: closeModal,
                                });
                            } else if (newValue && newValue.inputValue && typeof newValue.inputValue === 'string') {
                                setValue({
                                    title: String(newValue.inputValue),
                                });
                                createFormLabel({
                                    id: formId,
                                    title: newValue.inputValue,
                                    showAlert,
                                    callback: closeModal,
                                });
                            } else if (newValue && newValue.inputValue && typeof newValue.inputValue === 'number') {
                                const selectedOption = formLabelsForOrganization.find(
                                    item => item.id === newValue.inputValue,
                                );
                                if (selectedOption) {
                                    setValue({
                                        title: selectedOption.title,
                                    });
                                    attachFormLabel({
                                        id: formId,
                                        label: selectedOption.id,
                                        showAlert,
                                        callback: closeModal,
                                    });
                                }
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const {inputValue} = params;
                            const isExisting = options.some(option => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    title: `${t('orguser.forms.formLabels.add')} "${inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={formLabelOptions}
                        getOptionLabel={option => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return String(option.inputValue);
                            }
                            return option.title;
                        }}
                        renderOption={(props, option) => {
                            const {...optionProps} = props;
                            return <MenuItem {...optionProps}>{option.title}</MenuItem>;
                        }}
                        freeSolo
                        renderInput={params => <TextField variant="standard" {...params} />}
                    />
                </FormControl>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mt: 2}}>
                <AgentSaveButton isSubmit={false} title={t('common.buttons.close')} onClick={closeModal} />
            </Box>
        </Box>
    );
};

export default CreateFormLabelForm;
