import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {AGENT_LIST} from 'appRedux/actions/admin';
import {GET_BOARDS_LIST, GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {GET_GROUPS_LIST} from 'appRedux/actions/groups';
import {RootReducer} from 'appRedux/reducers';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import TableWrapper from 'components/AdminScreenComponents/TableWrapper';

import {getItemsPortionForPage} from 'pages/admin/formsList/helper';
import BoardsTable from 'pages/admin/boardsList/BoardsTable';

import {ITEMS_PER_PAGE_DEFAULT, PARAMETER_PER_PAGE} from 'config/index';

const BoardsList: FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {setTopBarTitle, setTopBarSubtitle, setBackLinkLabel, setBackLink} = useContext(RouteContext);
    const {currentPage, myOrganization} = useContext(AdminContext);
    const {isBoardViewEnabled} = useContext(PermissionContext);

    const getBoardsList = useCallback(data => dispatch({type: GET_BOARDS_LIST.REQUEST, payload: data}), [dispatch]);

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const getGroupList = useCallback(data => dispatch({type: GET_GROUPS_LIST.REQUEST, payload: data}), [dispatch]);

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (isBoardViewEnabled) {
            getBoardsList({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        if (myOrganization) {
            getGroupList({
                id: myOrganization.id,
                showAlert,
            });
            getAgents();
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        setTopBarTitle('');
        setTopBarSubtitle('');
        setBackLink('');
        setBackLinkLabel('');
    }, []);

    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE) ?? ITEMS_PER_PAGE_DEFAULT;

    const itemsPortion: BoardItemTypes[] = boards
        ? (getItemsPortionForPage(boards, currentPage, Number(perPageFromUrl)) as BoardItemTypes[])
        : [];

    return (
        <>
            <TableWrapper itemsList={boards} itemsTotalNumber={boards ? boards.length : 0}>
                <BoardsTable itemsPortion={itemsPortion} />
            </TableWrapper>
        </>
    );
};

export default BoardsList;
