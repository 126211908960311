import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';
import {http} from 'services/http';

import {
    FORM_LANGUAGE_ADD,
    addFormLanguage,
    FORM_LANGUAGE_STATUS,
    changeFormLanguageStatus,
    FORM_TRANSLATIONS,
    getFormTranslations,
    FORM_TRANSLATION_UPDATE,
    updateFormTranslation,
    FORM_TRANSLATION_IMPORT,
    importFormTranslation,
    FORM_TRANSLATION_EXPORT,
    exportFormTranslation,
    FORM_TRANSLATION_AUTO,
    autoFormTranslation,
    FORM_MAIN_TRANSLATION_UPDATE,
    updateFormMainTranslation,
    FORM_LANGUAGE_DELETE,
    deleteFormLanguage,
} from 'appRedux/actions/forms';
import {
    FormChangeStatusType,
    FormAddLanguageRequest,
    FormLanguagesResponseTypes,
    FormTranslationRequest,
    FormTranslationResponseType,
    FormUpdateTranslationRequestType,
    FormMainTranslationsUpdateRequestTypes,
    FormMainTranslationsResponseType,
    FormTranslationExportType,
    FormTranslationImportType,
    FormTranslationImportResponseType,
} from 'appRedux/actions/forms/types';

import {getTranslationUploadErrorMessage, getLogoUploadErrorMessage} from 'helpers/documentsUploading';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormLanguageAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddLanguageRequest> = yield take(FORM_LANGUAGE_ADD.REQUEST);
        try {
            const data: FormLanguagesResponseTypes = yield call(http, `form/language/${id}`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addFormLanguage.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormLanguage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(addFormLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetFormTranslations() {
    while (true) {
        const {
            payload: {id, lang},
        }: IActionType<FormTranslationRequest> = yield take(FORM_TRANSLATIONS.REQUEST);
        try {
            const data: FormTranslationResponseType = yield call(http, `form/${id}/translations/${lang}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormTranslations.success(data.results));
            } else {
                yield put(getFormTranslations.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormTranslations.error({message: String(e)}));
        }
    }
}

function* watchFormTranslationUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormUpdateTranslationRequestType> = yield take(FORM_TRANSLATION_UPDATE.REQUEST);
        try {
            const data: FormTranslationResponseType = yield call(http, `form/${id}/translation/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormTranslation.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormTranslation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormTranslation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTranslationImport() {
    while (true) {
        const {
            payload: {showAlert, lang, file, callback, id, setErrors},
        }: IActionType<FormTranslationImportType> = yield take(FORM_TRANSLATION_IMPORT.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: FormTranslationImportResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `form/${id}/translation/import/${lang}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.success) {
                yield put(importFormTranslation.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                const errorMessage = getTranslationUploadErrorMessage(data.errors);
                setErrors && setErrors(errorMessage);
                yield put(importFormTranslation.error({message: errorMessage}));
                showAlert && showAlert(ALERT_TYPE_ERROR, errorMessage);
            }
        } catch (e) {
            yield put(importFormTranslation.error({message: String(e)}));
        }
    }
}

function* watchFormTranslationExport() {
    while (true) {
        const {
            payload: {showAlert, lang, callback, callbackDownload, id},
        }: IActionType<FormTranslationExportType> = yield take(FORM_TRANSLATION_EXPORT.REQUEST);
        try {
            const data: string | Blob = yield call(http, `form/${id}/translation/${lang}/export`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(exportFormTranslation.success());
                callback && callback();
                callbackDownload && callbackDownload(data);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(exportFormTranslation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(exportFormTranslation.error({message: String(e)}));
        }
    }
}

function* watchFormAutoTranslation() {
    while (true) {
        const {
            payload: {id, lang, callback, showAlert},
        }: IActionType<FormTranslationRequest> = yield take(FORM_TRANSLATION_AUTO.REQUEST);
        try {
            const data: FormTranslationResponseType = yield call(http, `page/${id}/translation/${lang}/auto`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(autoFormTranslation.success(data.results));
                callback && callback();
            } else {
                yield put(autoFormTranslation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(autoFormTranslation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormMainTranslationsUpdating() {
    while (true) {
        const {
            payload: {id, lang, callback, showAlert, ...values},
        }: IActionType<FormMainTranslationsUpdateRequestTypes> = yield take(FORM_MAIN_TRANSLATION_UPDATE.REQUEST);
        try {
            const data: FormMainTranslationsResponseType = yield call(http, `form/${id}/translations/${lang}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormMainTranslation.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormMainTranslation.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormMainTranslation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLanguageDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_LANGUAGE_DELETE.REQUEST);
        try {
            const data: FormLanguagesResponseTypes = yield call(http, `form/language/${id}/delete`, {
                method: 'DELETE',
            });

            if (data.success) {
                yield put(deleteFormLanguage.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormLanguage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLanguageStatusChanging() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_LANGUAGE_STATUS.REQUEST);
        try {
            const data: FormLanguagesResponseTypes = yield call(http, `form/language/${id}/status`, {
                method: 'POST',
            });

            if (data.success) {
                yield put(changeFormLanguageStatus.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeFormLanguageStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeFormLanguageStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormLanguageAdding),
    fork(watchGetFormTranslations),
    fork(watchFormTranslationUpdating),
    fork(watchFormTranslationImport),
    fork(watchFormTranslationExport),
    fork(watchFormAutoTranslation),
    fork(watchFormMainTranslationsUpdating),
    fork(watchFormLanguageDeleting),
    fork(watchFormLanguageStatusChanging),
];
