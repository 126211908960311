import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import PlusSvgIcon from 'assets/icons/small/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/buttons/MinusSvgIcon';

import {CryptoContext} from 'contexts/crypto/context';

import {FieldsDifferenceType, getFieldValue} from 'pages/agent/activityPage/helper';
import ActivityInformationItem from 'pages/agent/activityPage/ActivityInformationItem';

import {decryptStringWithKey, unwrapKey} from 'helpers/cryptoApiHelper';

interface ActivityInformationResultsItemType {
    item: FieldsDifferenceType;
    isEncryptInfo?: boolean;
    encodedCaseKey?: string;
}

const ActivityInformationResultsItem: FC<ActivityInformationResultsItemType> = ({
    item,
    isEncryptInfo,
    encodedCaseKey,
}) => {
    const [t] = useTranslation();

    const {keys} = useContext(CryptoContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [oldTextValue, setOldTextValue] = useState<string>('');
    const [newTextValue, setNewTextValue] = useState<string>('');

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {page, section, field, fieldType, popup, oldValue, newValue, added, removed} = item;

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isEncryptInfo && keys && encodedCaseKey) {
                const unwrappedCaseKey = await unwrapKey(encodedCaseKey, keys.privateKey);
                if (oldValue) {
                    const oldDecodedResults = await decryptStringWithKey(oldValue, unwrappedCaseKey);
                    setOldTextValue(getFieldValue(oldDecodedResults, Number(fieldType)));
                }
                if (newValue) {
                    const newDecodedResults = await decryptStringWithKey(newValue, unwrappedCaseKey);
                    setNewTextValue(getFieldValue(newDecodedResults, Number(fieldType)));
                }
            } else if (!isEncryptInfo) {
                if (oldValue) {
                    setOldTextValue(getFieldValue(oldValue, Number(fieldType)));
                }
                if (newValue) {
                    setNewTextValue(getFieldValue(newValue, Number(fieldType)));
                }
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [isEncryptInfo, oldValue, newValue]);

    return (
        <Box sx={{mt: 1, mb: 2}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isOpened ? 'flex-end' : 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                {!isOpened && <Typography sx={{fontWeight: 600}}>{t('orguser.activities.change')}</Typography>}
                <IconButton onClick={toggleIsOpened}>{isOpened ? <MinusSvgIcon /> : <PlusSvgIcon />}</IconButton>
            </Box>
            {isOpened && (
                <Box>
                    <ActivityInformationItem title={t('orguser.forms.page')} value={page} />
                    <ActivityInformationItem title={t('orguser.forms.section')} value={section} />
                    {popup && <ActivityInformationItem title={t('orguser.forms.popup')} value={popup} />}
                    {field && <ActivityInformationItem title={t('orguser.forms.field')} value={field} />}
                    {oldValue && (
                        <ActivityInformationItem title={t('orguser.activities.oldValue')} value={oldTextValue} />
                    )}
                    {newValue && (
                        <ActivityInformationItem title={t('orguser.activities.newValue')} value={newTextValue} />
                    )}
                    {added && <ActivityInformationItem title={t('orguser.activities.added')} value={added} />}
                    {removed && <ActivityInformationItem title={t('orguser.activities.removed')} value={removed} />}
                    <Divider />
                </Box>
            )}
        </Box>
    );
};

export default ActivityInformationResultsItem;
