import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {SelectChangeEvent} from '@mui/material/Select';

import {AGENT_FILTERS_LIST_CREATE} from 'appRedux/actions/agentFilters';

import FilterSvgIcon from 'assets/icons/buttons/FilterSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FilterForm from 'components/Forms/SavedFilterForm/FilterForm';

import FiltersPanel from 'pages/agent/savedFilters/FiltersPanel';
import {
    FILTER_SEPARATOR,
    FilterObjectType,
    getFilterObject,
    FILTER_ICON_DEFAULT_TYPE,
} from 'pages/agent/savedFilters/helper';
import SelectedFilters from 'pages/agent/savedFilters/SelectedFilters';

import {theme} from 'config/theme';

const CreateNewFilter: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

    const [updatedTitle, setUpdatedTitle] = useState<string>('');
    const [updatedIconType, setUpdatedIconType] = useState<number>(FILTER_ICON_DEFAULT_TYPE);
    const [updatedBoard, setUpdatedBoard] = useState<number | null>(null);
    const [updatedFilter, setUpdatedFilter] = useState<FilterObjectType>({});
    const [updatedFilterString, setUpdatedFilterString] = useState<string>('');

    const [errorBoardIsRequired, setErrorBoardIsRequired] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
        refreshFilter();
    };

    const refreshFilter = () => {
        setUpdatedTitle('');
        setUpdatedFilter({});
        setUpdatedFilterString('');
    };

    const createNewFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onTitleChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setUpdatedTitle(value);
    };

    const onIconTypeChange = (e: SelectChangeEvent) => {
        const value = Number(e.target.value);
        setUpdatedIconType(value);
    };

    const onBoardChange = (e: SelectChangeEvent) => {
        const value = String(e.target.value);
        setUpdatedBoard(Number(value));
        setErrorBoardIsRequired(false);
    };

    const updateFilterStructure = () => {
        const newFilterArray: any = [];
        let counter = 0;
        Object.entries(updatedFilter).map(item => {
            const key = item[0];
            const value = item[1];
            if (Array.isArray(value)) {
                newFilterArray[counter] = `${key}=${value.join(FILTER_SEPARATOR)}`;
            } else {
                newFilterArray[counter] = `${key}=${value}`;
            }
            counter++;
        });
        const newFilter = newFilterArray.join('&');
        setUpdatedFilterString(newFilter.length > 0 && newFilter[0] !== '?' ? `?${newFilter}` : newFilter);
        setUpdatedFilter(getFilterObject(newFilter));
    };

    const onSaveClicked = () => {
        if (!updatedBoard) {
            setErrorBoardIsRequired(true);
        } else {
            createNewFilter({
                title: updatedTitle,
                board: updatedBoard,
                text: updatedFilterString,
                iconType: updatedIconType,
                showAlert,
                callback: () => {
                    setIsOpened(false);
                    refreshFilter();
                },
            });
        }
    };

    useEffect(() => {
        setIsSubmitDisabled(updatedTitle.length === 0 || updatedFilterString.length === 0);
    }, [updatedTitle, updatedFilterString]);

    return (
        <>
            <AgentSaveButton
                title={t(isOpened ? 'common.buttons.discardChanges' : 'orguser.savedFilters.addCaseFilter')}
                isSubmit={false}
                icon={
                    isOpened ? <CloseIcon sx={{color: theme.palette.background.default}} /> : <FilterSvgIcon isActive />
                }
                onClick={toggleIsOpened}
            />
            {isOpened && (
                <Box sx={{mt: 2}}>
                    <FilterForm
                        updatedTitle={updatedTitle}
                        onTitleChange={onTitleChange}
                        updatedIconType={String(updatedIconType)}
                        onIconTypeChange={onIconTypeChange}
                        updatedBoard={Number(updatedBoard)}
                        onBoardChange={onBoardChange}
                        errorBoardIsRequired={errorBoardIsRequired}
                    />
                    {updatedBoard && (
                        <FiltersPanel
                            boardId={Number(updatedBoard)}
                            updatedFilter={updatedFilter}
                            updateFilterStructure={updateFilterStructure}
                        />
                    )}
                    <SelectedFilters
                        updatedFilter={updatedFilter}
                        isEditClicked={true}
                        updateFilterStructure={updateFilterStructure}
                    />
                    <AgentSaveButton onClick={onSaveClicked} isSubmit={false} isLoading={isSubmitDisabled} />
                </Box>
            )}
        </>
    );
};

export default CreateNewFilter;
