import React, {FC, ChangeEvent, useContext, useRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import {TEXTAREA_DEFAULT_LENGTH} from 'pages/admin/updateForm/partials/FormStructure/helper';
import TextAreaProgressCircle from 'pages/client/form/partials/TextAreaProgressCircle';
import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getTextFieldLabel, getCurrentFieldValue} from 'pages/client/form/fieldTypes/helper';
import {getInitialFieldValue, isSaveUnavailable, getErrorMessage} from 'pages/client/form/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

const FormTextField: FC<FormFieldPropsType> = ({
    formId,
    pageId,
    sectionId,
    field,
    clientInformation,
    agentInformation,
    onPopupFieldChange,
    handleChangeInformation,
    isTextArea,
    isPopup,
    isAgentPage,
    popupContent,
    previewMode,
    disabled,
    isCustomOption,
    isCustomCheckboxField,
    optionsIds,
    onSaveClicked,
    setIsDisabledSubmit,
    customOnBlur,
    continueClickedRef,
}) => {
    const [t] = useTranslation();

    const {id, isRequired, label, tooltip, minLength} = field;

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isError, setIsError] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [leftChars, setLeftChars] = useState<number>(minLength ?? TEXTAREA_DEFAULT_LENGTH);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, id, value);
        }
        if (isTextArea && minLength && minLength > 0) {
            setProgress((100 * value.length) / minLength);
            setLeftChars(minLength - value.length);
        }
    };

    const handlePopupFieldChange = (e: ChangeEvent<{value: unknown}>) => {
        const value = String(e.target.value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(id, value);
        }
        if (isTextArea && minLength && minLength > 0) {
            setProgress((100 * value.length) / minLength);
            setLeftChars(minLength - value.length);
        }
    };

    const onFocusField = () => {
        if (isPopup && setIsDisabledSubmit) {
            setIsDisabledSubmit(true);
        }
    };

    const validateField = (e: ChangeEvent<{value: unknown}>) => {
        const handleValidate = () => {
            const value = e ? String(e.target.value) : '';
            if (isSaveUnavailable(field, value)) {
                setIsError(true);
            } else {
                onSaveClicked();
            }
            if (isPopup && setIsDisabledSubmit) {
                setIsDisabledSubmit(false);
            }
        };
        if (!continueClickedRef?.current) {
            handleValidate();
        }
        setTimeout(handleValidate, 100);
    };

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const keywordLabel = getFieldKeyword(formId, id, 'label');
    const keywordTooltip = getFieldKeyword(formId, id, 'tooltip');

    const value = getInitialFieldValue(
        pageId,
        sectionId,
        id,
        clientInformation,
        agentInformation,
        isAgentPage,
        isPopup,
        popupContent,
    );
    const fieldValue = getCurrentFieldValue(value, optionsIds, isCustomOption, isCustomCheckboxField);

    const fieldLabel = getTextFieldLabel(
        label,
        getFormTranslatedLabel(translations, keywordLabel, label),
        previewMode,
        isCustomOption,
    );

    const fieldPlaceholder = getTextFieldLabel(
        tooltip,
        getFormTranslatedLabel(translations, keywordTooltip, tooltip),
        previewMode,
        isCustomOption,
    );

    return (
        <FormFieldWrapper
            data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
            isCustomOption={isCustomOption}
        >
            <Box sx={{position: 'relative'}}>
                <AutoDirectionTextField
                    fullWidth
                    variant="standard"
                    disabled={disabled}
                    error={isError || id === errorField}
                    helperText={isError || id === errorField ? getErrorMessage(field, t, fieldValue) : null}
                    label={fieldLabel}
                    placeholder={fieldPlaceholder}
                    required={isRequired}
                    value={fieldValue}
                    onChange={isPopup ? handlePopupFieldChange : handleChange}
                    multiline={isTextArea}
                    rows={isTextArea ? 5 : 1}
                    onFocus={onFocusField}
                    onBlur={e => {
                        validateField(e);
                        customOnBlur && customOnBlur(e);
                    }}
                />
                {isTextArea && minLength && leftChars > 0 && (
                    <TextAreaProgressCircle leftChars={leftChars} progress={progress} />
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default FormTextField;
