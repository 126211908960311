import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {
    getCalendarStatusLabel,
    getCalendarStatusDateTime,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

interface CalendarInformationItemType {
    currentCalendar: RequesterCaseHasCalendarTypes;
}

const CalendarInformationItem: FC<CalendarInformationItemType> = ({currentCalendar}) => {
    const [t] = useTranslation();

    const {
        title,
        description,
        status,
        calendarDate,
        resourceFieldCalendarSlotTitle,
        resourceFieldCalendarSlotDuration,
        startTime,
        endTime,
    } = currentCalendar;

    const calendarDateObject = calendarDate ? new Date(calendarDate) : null;

    return (
        <Box key={`currentCalendar-${currentCalendar.uuid}`}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.title')}:`}</Typography>
                <Typography>{title}</Typography>
                {description && <DescriptionPopover text={description} />}
            </Box>
            {resourceFieldCalendarSlotTitle && resourceFieldCalendarSlotDuration && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.calendarSlot',
                    )}:`}</Typography>
                    <Typography>{`${resourceFieldCalendarSlotTitle} (${resourceFieldCalendarSlotDuration} ${t(
                        'common.units.minutes',
                    )})`}</Typography>
                </Box>
            )}
            {calendarDateObject && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.calendarDate',
                    )}:`}</Typography>
                    <Typography>{convertDateToCurrentLocale(calendarDateObject, true, true)}</Typography>
                </Box>
            )}
            {startTime && endTime && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.calendarTime',
                    )}:`}</Typography>
                    <Typography>{`${startTime} - ${endTime}`}</Typography>
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(getCalendarStatusLabel(status))}:`}</Typography>
                <Typography>{getCalendarStatusDateTime(currentCalendar)}</Typography>
            </Box>
        </Box>
    );
};

export default CalendarInformationItem;
