import React, {ElementType} from 'react';

export type BreadcrumbsType = {
    Icon?: ElementType;
    label: string;
    href: string;
};

export type RouteContextType = {
    currentTab: string;
    setCurrentTab: (value: string) => void;
    formPage: number;
    setFormPage: (value: number) => void;
    formSection: number | null;
    setFormSection: (value: number | null) => void;
    pageTitle: string;
    setPageTitle: (value: string) => void;
    pageBreadcrumbs: BreadcrumbsType[];
    setPageBreadcrumbs: (value: BreadcrumbsType[]) => void;
    topBarTitle: string;
    setTopBarTitle: (value: string) => void;
    topBarSubtitle: string;
    setTopBarSubtitle: (value: string) => void;
    addLink: string | null;
    setAddLink: (value: string | null) => void;
    backLink: string | null;
    setBackLink: (value: string | null) => void;
    backTab: string | null;
    setBackTab: (value: string | null) => void;
    backLinkLabel: string | null;
    setBackLinkLabel: (value: string | null) => void;
    customBoardTitle: string | null;
    setCustomBoardTitle: (value: string | null) => void;
    isSuperAdminPage: boolean;
    isOrganizationCreatePage: boolean;
    isOrganizationUpdatePage: boolean;
    isAdminPage: boolean;
    isAgentPage: boolean;
    isSuperAdminDashboard: boolean;
    isAdminDashboard: boolean;
    isAgentDashboard: boolean;
    isAgentSavedFiltersPage: boolean;
    isClientMode: boolean;
    isFormCreatePage: boolean;
    isFormUpdatePage: boolean;
    isFormVersionPage: boolean;
    isFormViewPage: boolean;
    isWorkflowEditPage: boolean;
    isWorkflowVariablesPage: boolean;
    isWorkflowStatusEventsPage: boolean;
    isPdfTemplateEditPage: boolean;
    isCaseAccessRequestUpdatePage: boolean;
    isTicketCreationPage: boolean;
    isRequesterCaseInfoPage: boolean;
    isRequesterCasePrintPage: boolean;
    isRequesterFormPage: boolean;
    isRequesterFormAgentPage: boolean;
    isOrgUserChatPage: boolean;
    isRequesterChatPage: boolean;
    isRolesListPage: boolean;
    isCreateRolePage: boolean;
    isUpdateRolePage: boolean;
    isAccountSettingsPage: boolean;
    isRequesterCaseActivityPage: boolean;
    isSecurityPage: boolean;
    isImprintPage: boolean;
    isPrivacyPolicyPage: boolean;
    isBoardCasesPage: boolean;
    isSwimlaneCasesPage: boolean;
    isBoardOverviewCasesPage: boolean;
    isBoardListCasesPage: boolean;
    isDashboardWithPanelsPage: boolean;
    isFormsListForRequester: boolean;
    isUpdateResourceFieldPage: boolean;
    isUpdateResourceFieldCalendarPage: boolean;
    isEmailUpdatePage: boolean;
    isCurrentUserRequesterCasesListPage: boolean;
    isResourceFieldSlotCasesPage: boolean;
    isResourceFieldBudgetCasesPage: boolean;
    refreshPageInformation: () => void;
    onLogoutClicked: (isSessionExpired?: boolean) => void;
};

export const RouteContext = React.createContext({} as RouteContextType);
