import {fork, put, take, call} from 'redux-saga/effects';
import {http} from 'services/http';
import get from 'lodash/get';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

import {
    ORGANIZATION_LIST,
    organizationList,
    CREATE_ORGANIZATION,
    createOrganization,
    UPDATE_ORGANIZATION,
    updateOrganization,
    TOGGLE_TWO_FACTOR_ORGANIZATION,
    toggleTwoFactorOrganization,
    DELETE_ORGANIZATION,
    deleteOrganization,
    CHANGE_ORGANIZATION_STATUS,
    changeOrganizationStatus,
    ORGANIZATION_MULTIPLE_STATUS_CHANGING,
    organizationMultipleStatusChanging,
    ORGANIZATION_CHANGE_DOMAIN,
    changeOrganizationDomain,
    ORGANIZATION_UPLOAD_LOGO,
    uploadOrganizationLogo,
    ORGANIZATION_LOGO_DELETE,
    organizationLogoDelete,
    ORGANIZATION_LOGO_GET,
    getOrganizationLogo,
    ORGANIZATION_UPLOAD_FAVICON,
    uploadOrganizationFavicon,
    ORGANIZATION_FAVICON_DELETE,
    organizationFaviconDelete,
    ORGANIZATION_QUOTAS_LIST,
    organizationQuotasList,
    CREATE_ORGANIZATION_QUOTA,
    createOrganizationQuota,
    UPDATE_ORGANIZATION_QUOTA,
    updateOrganizationQuota,
    DELETE_ORGANIZATION_QUOTA,
    deleteOrganizationQuota,
    TOGGLE_ORGANIZATION_QUOTA_HARD,
    toggleIsHardOrganizationQuota,
    GET_ORGANIZATIONS_STATISTIC,
    getOrganizationsStatistic,
    SEND_ORGANIZATIONS_STATISTIC,
    sendOrganizationsStatistic,
    CREATE_ORGANIZATION_SSO_CREDENTIALS,
    createOrganizationSsoCredentials,
    UPDATE_ORGANIZATION_SSO_CREDENTIALS,
    updateOrganizationSsoCredentials,
    UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS,
    updateOrganizationSsoClientSecretCredentials,
    ORGANIZATION_TEMPLATES_LIST,
    getOrganizationTemplatesList,
    ORGANIZATION_TEMPLATE_CREATE,
    createOrganizationTemplate,
    ORGANIZATION_TEMPLATE_UPDATE,
    updateOrganizationTemplate,
    getOrgsListForRequester,
    ORGS_LIST_FOR_REQUESTER,
} from 'appRedux/actions/organization';
import {
    OrganizationItemBasic,
    CreateOrganizationRequestTypes,
    DeleteOrganizationRequestTypes,
    ToggleTwoFactorOrganizationRequestTypes,
    ChangeOrganizationStatusRequestTypes,
    ChangeOrganizationDomainRequestType,
    OrganizationMultipleChangeStatusType,
    OrganizationResponseTypes,
    OrganizationLogoUrlRequestTypes,
    OrganizationQuotasListRequestTypes,
    OrganizationQuotasCreateRequestTypes,
    OrganizationQuotasUpdateRequestTypes,
    OrganizationQuotasDeleteRequestTypes,
    OrganizationQuotasToggleRequestTypes,
    OrganizationQuotasResponseTypes,
    OrganizationsStatisticRequestTypes,
    OrganizationsStatisticSendRequestTypes,
    OrganizationSsoCreateTypes,
    OrganizationSsoUpdateTypes,
    OrganizationSsoUpdateClientSecretTypes,
    OrganizationTemplateCreateRequestType,
    OrganizationTemplateUpdateRequestType,
    OrganizationTemplateRequestType,
    OrganizationTemplateResponseType,
    OrgsListForRequesterRequestTypes,
    OrgsListForRequesterResponseTypes,
} from 'appRedux/actions/organization/types';
import {CommonLogoResponseType, CommonUploadLogoTypes} from 'appRedux/actions/forms/types';

import {ERROR_MESSAGE_WRONG_FAVICON_TYPE} from 'config/errors';

function* watchGetOrganizationList() {
    while (true) {
        yield take(ORGANIZATION_LIST.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, 'organization/list', {
                method: 'GET',
            });
            if (data.success) {
                yield put(organizationList.success(data.results));
            } else {
                yield put(organizationList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(organizationList.error({message: String(e)}));
        }
    }
}

function* watchCreateOrganization() {
    while (true) {
        const {
            payload: {callback, setErrors, showAlert, ...values},
        }: IActionType<CreateOrganizationRequestTypes> = yield take(CREATE_ORGANIZATION.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, 'organization/create', {
                method: 'POST',
                body: JSON.stringify(values),
            });

            if (data.success) {
                yield put(organizationList.success(data.results));
                const newOrganizationId = get(data.results, [0, 'id'], null);
                if (newOrganizationId) {
                    callback && callback(Number(newOrganizationId));
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                setErrors && setErrors(data.errors);
                yield put(createOrganization.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUpdateOrganization() {
    while (true) {
        const {
            payload: {id, setErrors, showAlert, ...values},
        }: IActionType<OrganizationItemBasic> = yield take(UPDATE_ORGANIZATION.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganization.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                setErrors && setErrors(data.errors);
                yield put(updateOrganization.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchToggleTwoFactorOrganization() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<ToggleTwoFactorOrganizationRequestTypes> = yield take(TOGGLE_TWO_FACTOR_ORGANIZATION.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/${id}/two-factor`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleTwoFactorOrganization.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(toggleTwoFactorOrganization.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleTwoFactorOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteOrganization() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<DeleteOrganizationRequestTypes> = yield take(DELETE_ORGANIZATION.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/${id}/delete`, {
                method: 'DELETE',
            });

            if (data.success) {
                yield put(deleteOrganization.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteOrganization.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchChangeOrganizationStatus() {
    while (true) {
        const {
            payload: {organizationId, showAlert},
        }: IActionType<ChangeOrganizationStatusRequestTypes> = yield take(CHANGE_ORGANIZATION_STATUS.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/${organizationId}/active`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data.success) {
                yield put(changeOrganizationStatus.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeOrganizationStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeOrganizationStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchChangeOrganizationDomain() {
    while (true) {
        const {
            payload: {organizationId, setErrors, callback, showAlert, ...values},
        }: IActionType<ChangeOrganizationDomainRequestType> = yield take(ORGANIZATION_CHANGE_DOMAIN.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/${organizationId}/domain`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const organizations = get(data, 'results', []);
                yield put(changeOrganizationDomain.success(organizations));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                setErrors && setErrors(data.errors);
                yield put(changeOrganizationDomain.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeOrganizationDomain.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationMultipleStatusChanging() {
    while (true) {
        const {
            payload: {organizationIds, isActive, showAlert},
        }: IActionType<OrganizationMultipleChangeStatusType> = yield take(
            ORGANIZATION_MULTIPLE_STATUS_CHANGING.REQUEST,
        );
        try {
            const data: OrganizationResponseTypes = yield call(
                http,
                `organization/status?id=${organizationIds.join(',')}&isActive=${Number(isActive)}`,
                {
                    method: 'GET',
                },
            );
            if (data.success) {
                yield put(organizationMultipleStatusChanging.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(organizationMultipleStatusChanging.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(organizationMultipleStatusChanging.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationLogoUploading() {
    while (true) {
        const {
            payload: {id, file, type, setErrors, callback, showAlert},
        }: IActionType<CommonUploadLogoTypes> = yield take(ORGANIZATION_UPLOAD_LOGO.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            formData.append('type', String(type));
            const data: OrganizationResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `organization/logo/${id}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(uploadOrganizationLogo.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadOrganizationLogo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(uploadOrganizationLogo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationFaviconUploading() {
    while (true) {
        const {
            payload: {id, file, setErrors, callback, showAlert},
        }: IActionType<CommonUploadLogoTypes> = yield take(ORGANIZATION_UPLOAD_FAVICON.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: OrganizationResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `organization/favicon/${id}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(uploadOrganizationFavicon.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors === ERROR_MESSAGE_WRONG_FAVICON_TYPE) {
                setErrors && setErrors(data.errors);
                yield put(uploadOrganizationFavicon.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, ERROR_MESSAGE_WRONG_FAVICON_TYPE);
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadOrganizationFavicon.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(uploadOrganizationFavicon.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationLogoGetting() {
    while (true) {
        const {
            payload: {id, callback, ...values},
        }: IActionType<OrganizationLogoUrlRequestTypes> = yield take(ORGANIZATION_LOGO_GET.REQUEST);
        try {
            const data: CommonLogoResponseType = yield call(http, `organization/logo/${id}/get`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.fileLink) {
                yield put(getOrganizationLogo.success(data));
                callback(data.fileLink);
            } else {
                yield put(getOrganizationLogo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationLogo.error({message: String(e)}));
        }
    }
}

function* watchOrganizationLogoRemoving() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<OrganizationLogoUrlRequestTypes> = yield take(ORGANIZATION_LOGO_DELETE.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/logo/${id}/delete`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(organizationLogoDelete.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(organizationLogoDelete.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(organizationLogoDelete.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationFaviconRemoving() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<OrganizationLogoUrlRequestTypes> = yield take(ORGANIZATION_FAVICON_DELETE.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/favicon/${id}/delete`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(organizationFaviconDelete.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(organizationFaviconDelete.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(organizationFaviconDelete.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetOrganizationQuotasList() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<OrganizationQuotasListRequestTypes> = yield take(ORGANIZATION_QUOTAS_LIST.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `quotas/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(organizationQuotasList.success(data.results));
                callback && callback();
            } else {
                yield put(organizationQuotasList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(organizationQuotasList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCreateOrganizationQuota() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<OrganizationQuotasCreateRequestTypes> = yield take(CREATE_ORGANIZATION_QUOTA.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `quotas/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationQuota.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createOrganizationQuota.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createOrganizationQuota.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUpdateOrganizationQuota() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<OrganizationQuotasUpdateRequestTypes> = yield take(UPDATE_ORGANIZATION_QUOTA.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `quotas/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationQuota.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateOrganizationQuota.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationQuota.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteOrganizationQuota() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<OrganizationQuotasDeleteRequestTypes> = yield take(DELETE_ORGANIZATION_QUOTA.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `quotas/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteOrganizationQuota.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteOrganizationQuota.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteOrganizationQuota.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchToggleIsHardOrganizationQuota() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<OrganizationQuotasToggleRequestTypes> = yield take(TOGGLE_ORGANIZATION_QUOTA_HARD.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `quotas/${uuid}/hard`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleIsHardOrganizationQuota.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(toggleIsHardOrganizationQuota.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleIsHardOrganizationQuota.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationsStatisticGetting() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<OrganizationsStatisticRequestTypes> = yield take(GET_ORGANIZATIONS_STATISTIC.REQUEST);
        try {
            const data: string | Blob = yield call(http, `organizations/statistic`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(getOrganizationsStatistic.success(data));
                callback && callback(data);
            } else {
                yield put(getOrganizationsStatistic.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrganizationsStatistic.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationsStatisticSending() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<OrganizationsStatisticSendRequestTypes> = yield take(SEND_ORGANIZATIONS_STATISTIC.REQUEST);
        try {
            const data: OrganizationQuotasResponseTypes = yield call(http, `organizations/statistic/send`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(sendOrganizationsStatistic.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(sendOrganizationsStatistic.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(sendOrganizationsStatistic.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationSsoCreating() {
    while (true) {
        const {
            payload: {id, setErrors, showAlert, ...values},
        }: IActionType<OrganizationSsoCreateTypes> = yield take(CREATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/sso/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationSsoCredentials.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                setErrors && setErrors(data.errors);
                yield put(createOrganizationSsoCredentials.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createOrganizationSsoCredentials.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationSsoUpdating() {
    while (true) {
        const {
            payload: {id, setErrors, showAlert, ...values},
        }: IActionType<OrganizationSsoUpdateTypes> = yield take(UPDATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST);
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/sso/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationSsoCredentials.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                setErrors && setErrors(data.errors);
                yield put(updateOrganizationSsoCredentials.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationSsoCredentials.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationSsoClientSecretUpdating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationSsoUpdateClientSecretTypes> = yield take(
            UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.REQUEST,
        );
        try {
            const data: OrganizationResponseTypes = yield call(http, `organization/sso/${id}/secret`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationSsoClientSecretCredentials.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateOrganizationSsoClientSecretCredentials.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationSsoClientSecretCredentials.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTemplateListGetting() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<OrganizationTemplateRequestType> = yield take(ORGANIZATION_TEMPLATES_LIST.REQUEST);
        try {
            const data: OrganizationTemplateResponseType = yield call(http, `organization/template/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationTemplatesList.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(getOrganizationTemplatesList.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrganizationTemplatesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTemplateCreating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationTemplateCreateRequestType> = yield take(ORGANIZATION_TEMPLATE_CREATE.REQUEST);
        try {
            const data: OrganizationTemplateResponseType = yield call(http, `organization/template/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationTemplate.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(createOrganizationTemplate.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createOrganizationTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTemplateUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationTemplateUpdateRequestType> = yield take(ORGANIZATION_TEMPLATE_UPDATE.REQUEST);
        try {
            const data: OrganizationTemplateResponseType = yield call(http, `organization/template/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationTemplate.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateOrganizationTemplate.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetOrgsListForRequester() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<OrgsListForRequesterRequestTypes> = yield take(ORGS_LIST_FOR_REQUESTER.REQUEST);
        try {
            const data: OrgsListForRequesterResponseTypes = yield call(http, `user/${id}/organizations`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrgsListForRequester.success(data.results));
                callback && callback();
            } else {
                yield put(getOrgsListForRequester.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrgsListForRequester.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetOrganizationList),
    fork(watchCreateOrganization),
    fork(watchUpdateOrganization),
    fork(watchToggleTwoFactorOrganization),
    fork(watchDeleteOrganization),
    fork(watchChangeOrganizationStatus),
    fork(watchChangeOrganizationDomain),
    fork(watchOrganizationMultipleStatusChanging),
    fork(watchOrganizationLogoUploading),
    fork(watchOrganizationFaviconUploading),
    fork(watchOrganizationLogoGetting),
    fork(watchOrganizationLogoRemoving),
    fork(watchOrganizationFaviconRemoving),
    fork(watchGetOrganizationQuotasList),
    fork(watchCreateOrganizationQuota),
    fork(watchUpdateOrganizationQuota),
    fork(watchDeleteOrganizationQuota),
    fork(watchToggleIsHardOrganizationQuota),
    fork(watchOrganizationsStatisticGetting),
    fork(watchOrganizationsStatisticSending),
    fork(watchOrganizationSsoCreating),
    fork(watchOrganizationSsoUpdating),
    fork(watchOrganizationSsoClientSecretUpdating),
    fork(watchOrganizationTemplateListGetting),
    fork(watchOrganizationTemplateCreating),
    fork(watchOrganizationTemplateUpdating),
    fork(watchGetOrgsListForRequester),
];
