import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {View, Text} from '@react-pdf/renderer';

import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {FieldOptionType, FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';

import {
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_UPLOADER,
    isSelectionFields,
    OPTIONS_SEPARATOR,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import {ImageComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface PopupFieldPdfContentType {
    pageId: number;
    sectionId: number;
    popupId: number;
    item: RequesterCasePopupType;
    fields: FormFieldTypes[];
    formId: number;
}

const PopupFieldPdfContent: FC<PopupFieldPdfContentType> = ({fields, item, formId, sectionId}) => {
    const {translations, preview, currentCase} = useContext(PdfDocumentContext);
    const content = item.result && JSON.parse(item.result);

    const {options} = currentCase;

    const renderImageSelectField = (field: FormFieldTypes, value: string) => {
        const selectedIds = String(value).split(OPTIONS_SEPARATOR);
        const selectedFiles = (field.fieldOptions || [])?.filter(
            (file: FieldOptionType) => selectedIds.includes(String(file?.id)) || selectedIds.includes(file?.uuid),
        );

        return selectedFiles.length ? (
            <View style={pdfStyles.fieldValue}>
                {selectedFiles.map(file => (
                    <View style={pdfStyles.fieldImage} key={file.uuid}>
                        <ImageComponent src={file.fileLink || ''} preview={preview} />
                        <Text>{file.title}</Text>
                    </View>
                ))}
            </View>
        ) : (
            <View style={pdfStyles.popupFieldValue}>
                <Text>{EMPTY_DATA}</Text>
            </View>
        );
    };

    const renderSignatureField = (field: FormFieldTypes) => {
        const fieldSignatures = currentCase.signatures.filter(
            (signature: RequesterSignatureResponseType) =>
                signature.field === field.id &&
                signature.section === sectionId &&
                signature.requesterCaseHasPopup === item.id,
        );

        return fieldSignatures.length ? (
            <View style={pdfStyles.fieldValue}>
                {fieldSignatures.map(signature => (
                    <View style={pdfStyles.fieldSignature} key={signature.uuid}>
                        <ImageComponent src={signature.fileLink || ''} preview={preview} />
                    </View>
                ))}
            </View>
        ) : (
            <View style={pdfStyles.popupFieldValue}>
                <Text>{EMPTY_DATA}</Text>
            </View>
        );
    };

    const renderImageField = (field: FormFieldTypes) => {
        const {files} = currentCase;

        const fieldRelatedFiles = files.filter(
            file => file.fieldId === field.id && file.requesterCaseHasPopupId === item.id,
        );

        return (
            <View style={pdfStyles.fieldValue}>
                {fieldRelatedFiles.length ? (
                    fieldRelatedFiles.map(file => {
                        return (
                            <View style={pdfStyles.fieldImage} key={file.uuid}>
                                <ImageComponent preview={preview} src={file.thumbLink || ''} />
                                <Text>{file.description}</Text>
                            </View>
                        );
                    })
                ) : (
                    <View style={pdfStyles.popupFieldValue}>
                        <Text>{' - '}</Text>
                    </View>
                )}
            </View>
        );
    };

    return (
        <View style={pdfStyles.popupContent} wrap={false}>
            {fields.map((field: FormFieldTypes, index: number) => {
                const {id, label, type} = field;
                const fieldKeyword = getFieldKeyword(Number(formId), id, 'label');
                const value = get(content, [`field-${id}`], EMPTY_DATA);

                const selectedOptions = options.filter(
                    option => option.fieldId === field.id && option.requesterCaseHasPopup === item.id,
                );
                const selectedOptionsRow: string[] = [];
                selectedOptions.forEach(item => {
                    selectedOptionsRow.push(item.title);
                });

                return (
                    <React.Fragment key={id}>
                        {index !== 0 && <View style={pdfStyles.divider}></View>}
                        <View style={pdfStyles.fieldTitle}>
                            <Text>{`${getFormTranslatedLabel(translations, fieldKeyword, label)}:`}</Text>
                        </View>
                        {[FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(type) ? (
                            renderImageSelectField(field, value)
                        ) : type === FORM_FIELD_SIGNATURE ? (
                            renderSignatureField(field)
                        ) : type === FORM_FIELD_UPLOADER ? (
                            renderImageField(field)
                        ) : (
                            <View style={pdfStyles.popupFieldValue}>
                                <Text>{isSelectionFields(type) ? selectedOptionsRow.join(', ') : String(value)}</Text>
                            </View>
                        )}
                    </React.Fragment>
                );
            })}
        </View>
    );
};

export default PopupFieldPdfContent;
