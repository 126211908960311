import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {FORMS_LIST_BY_ORGANIZATION} from 'appRedux/actions/forms';
import {GET_PANELS_LIST} from 'appRedux/actions/analytics';
import {PanelItemsTypes} from 'appRedux/actions/analytics/types';
import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import PanelTile from 'pages/admin/analytics/panels/PanelTile';

const PanelsList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isAnalyticViewEnabled} = useContext(PermissionContext);
    const {refreshPageInformation} = useContext(RouteContext);

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getPanelsList = useCallback(data => dispatch({type: GET_PANELS_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        analytics: {isLoading, panels},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        refreshPageInformation();
        if (isAnalyticViewEnabled) {
            getPanelsList({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        if (myOrganization) {
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
            });
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    return (
        <Box>
            {panels && panels.length > 0 ? (
                <Grid container>
                    {panels.map((panel: PanelItemsTypes, index: number) => {
                        return (
                            <Grid item sm={12} key={`panel-tile-${index}`}>
                                <PanelTile item={panel} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Typography sx={{mt: 2}}>
                    {t(isLoading ? 'common.pleaseWait' : 'orguser.analytics.panels.nonePanels')}
                </Typography>
            )}
        </Box>
    );
};

export default PanelsList;
