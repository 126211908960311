import React, {FC, useState, useCallback, useEffect} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {
    BoardItemTypes,
    UpdateBoardOverviewRequestTypes,
    BoardStatusTypes,
    BoardHasStatusItemTypes,
} from 'appRedux/actions/workflow/types';
import {UPDATE_BOARD_OVERVIEW} from 'appRedux/actions/workflow';

import StatusesSelectors from 'components/Forms/BoardForm/StatusesSelectors';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaForOverview} from 'components/Forms/BoardForm/validation';

interface EditBoardOverviewStatusFormType {
    item: BoardItemTypes;
}

const EditBoardOverviewStatusForm: FC<EditBoardOverviewStatusFormType> = ({item}) => {
    const dispatch = useDispatch();

    const {uuid, statuses, title, type, typeSwimlane, typePermission, iconType} = item;

    const [boardStatuses, setBoardStatuses] = useState<BoardHasStatusItemTypes[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const updateBoard = useCallback(data => dispatch({type: UPDATE_BOARD_OVERVIEW.REQUEST, payload: data}), [dispatch]);

    useEffect(() => {
        statuses.forEach((status: BoardStatusTypes) => {
            boardStatuses.push({
                workflowStatus: status.id,
            });
        });
        setBoardStatuses(boardStatuses);
        setIsUpdated(previous => !previous);
    }, [uuid]);

    return (
        <Formik
            initialValues={{
                title,
                uuid,
                type,
                typeSwimlane,
                typePermission,
                iconType,
                workflow: null,
                boardHasStatuses: boardStatuses,
            }}
            validationSchema={validationSchemaForOverview}
            onSubmit={(values: UpdateBoardOverviewRequestTypes, {setErrors}) => {
                updateBoard({
                    ...values,
                    uuid,
                    boardHasStatuses: boardStatuses,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<UpdateBoardOverviewRequestTypes>) => {
                return (
                    <Form>
                        <StatusesSelectors statuses={boardStatuses} setStatuses={setBoardStatuses} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditBoardOverviewStatusForm;
