import React, {FC, useCallback, useContext, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import {FORM_TIME_BUCKET_ADD} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AutoDirectionTextField from 'components/_common/AutoDirectionTextField';

import {preventSendForm} from 'helpers/index';
import {FormTimeBucketAddRequestTypes, FormTimeBucketTypes} from 'appRedux/actions/forms/types';

export interface BucketOptionTypes {
    id: number;
    label: string;
}

interface FormTimeBucketFormType {
    formId: number;
    closeModal: () => void;
}

const FormTimeBucketForm: FC<FormTimeBucketFormType> = ({formId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [selectedValue, setSelectedValue] = useState<number | null>(null);

    const {
        admin: {organizationTimeBuckets, formInfo, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitCreateForm = useCallback(
        data => dispatch({type: FORM_TIME_BUCKET_ADD.REQUEST, payload: data}),
        [dispatch],
    );

    const formTimeBucketsItems: FormTimeBucketTypes[] = get(formInfo, 'timeBuckets', []);

    const formBucketsSelection: BucketOptionTypes[] = organizationTimeBuckets
        .filter(item => !formTimeBucketsItems.find(formItem => item.id === formItem.bucketId))
        .map(item => ({label: item.title, id: item.id}));

    if (isLoading) return null;
    return (
        <Formik
            initialValues={{
                id: formId,
                bucket: 0,
            }}
            onSubmit={() => {
                submitCreateForm({
                    bucket: selectedValue,
                    id: formId,
                    showAlert,
                    callback: () => {
                        setSelectedValue(null);
                        closeModal();
                    },
                });
            }}
        >
            {(formik: FormikProps<FormTimeBucketAddRequestTypes>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box sx={{mt: 1, mb: 3}}>
                            <Autocomplete
                                disablePortal
                                options={formBucketsSelection}
                                onChange={(event, value) => {
                                    if (value) {
                                        setSelectedValue(value.id);
                                    }
                                }}
                                autoHighlight
                                renderInput={params => (
                                    <AutoDirectionTextField
                                        label={t('orguser.forms.timeTracking.selectTimeBucket')}
                                        placeholder={t('orguser.forms.timeTracking.selectTimeBucket')}
                                        {...params}
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <AgentSaveButton
                                isSubmit
                                title={t('superadmin.organizations.timeTracking.addTimeBucket')}
                                isDisabled={!selectedValue}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormTimeBucketForm;
