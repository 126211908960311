import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_WORKFLOW_FIELD_VARIABLE} from 'appRedux/actions/workflow';
import {WorkflowFieldVariableItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface ComponentType {
    item: WorkflowFieldVariableItemTypes;
}

const DeleteWorkflowFieldVariableButton: FC<ComponentType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const deleteFieldVariable = useCallback(
        data => dispatch({type: DELETE_WORKFLOW_FIELD_VARIABLE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteFieldVariable({
            uuid: item.uuid,
            callback: () => setIsShowModal(false),
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteWorkflowFieldVariableButton;
