import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import {RootReducer} from 'appRedux/reducers';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

interface MentionInformationType {
    item: string;
    forClient?: boolean;
}

const MentionInformation: FC<MentionInformationType> = ({item, forClient}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const {
        admin: {agentList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const currentAgent = agentList.find(agent => {
        const {id} = agent;

        const itemArray = item.split('_');

        if (itemArray.length > 0) {
            return Number(itemArray[1]) === id;
        }
        return false;
    });

    const agentName = forClient
        ? t('common.roles.roleOrgUser')
        : currentAgent && currentAgent.firstName && currentAgent.lastName
        ? `${currentAgent.firstName} ${currentAgent.lastName}`
        : item;

    return (
        <Box sx={{display: 'inline-flex'}}>
            <Typography
                sx={{fontWeight: 600, mr: 0.5, cursor: 'pointer'}}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {agentName}
            </Typography>
            {currentAgent && !forClient && (
                <Popover
                    id="mouse-over-popover"
                    sx={{pointerEvents: 'none'}}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <AvatarImage
                            size={25}
                            avatarType={currentAgent.avatarType}
                            avatarLink={currentAgent.avatarLink}
                            avatarTitle={getAvatarTitle(
                                currentAgent.firstName,
                                currentAgent.lastName,
                                currentAgent.email,
                            )}
                        />
                        <Typography sx={{ml: 1, fontWeight: 600}}>
                            {currentAgent.firstName && currentAgent.lastName
                                ? `${currentAgent.firstName} ${currentAgent.lastName}`
                                : currentAgent.email}
                        </Typography>
                    </Box>
                </Popover>
            )}
        </Box>
    );
};

export default MentionInformation;
