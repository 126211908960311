import * as yup from 'yup';

import {REGEX_FOR_NICKNAME} from 'components/Forms/SettingsForms/UpdateUsernameForm/validation';

import {getUsernameLengthErrorMessage, htmlTagsPresented} from 'helpers/validation';
import {calculateEntropy} from 'helpers/calculateEntropy';
import {MAXIMAL_PASSWORD_ENTROPY} from 'helpers/security';

import {MAXIMAL_NICKNAME_LENGTH, MINIMAL_NICKNAME_LENGTH, DEFAULT_LANGUAGE} from 'config/index';

export const startStepInitialValues = {
    nickname: '',
    email: '',
    lang: DEFAULT_LANGUAGE,
    password: {
        first: '',
        second: '',
    },
};

export const startValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        nickname: yup
            .string()
            .required(t('messages.validation.nicknameIsRequired'))
            .matches(REGEX_FOR_NICKNAME, 'Username should consists of latin letters and numbers')
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .min(
                MINIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMinLength', MINIMAL_NICKNAME_LENGTH),
            )
            .max(
                MAXIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMaxLength', MAXIMAL_NICKNAME_LENGTH),
            ),
        email: yup
            .string()
            .email(t('messages.validation.emailIncorrect'))
            .required(t('messages.validation.emailRequired')),
        password: yup.object({
            first: yup
                .string()
                .test('password', t('messages.validation.passwordIsTooWeak'), value => {
                    return calculateEntropy(value) > MAXIMAL_PASSWORD_ENTROPY;
                })
                .required(t('messages.validation.passwordIsRequired')),
            second: yup
                .string()
                .required(t('messages.validation.passwordIsRequired'))
                .oneOf([yup.ref('first'), null], t('messages.validation.passwordShouldMatch')),
        }),
    });
