import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import {PanelHasFieldItemTypes} from 'appRedux/actions/analytics/types';
import {FormAnalysisFieldTypes} from 'appRedux/actions/forms/types';

interface FormAnalysisFieldsSelectorsTypes {
    panelFields: PanelHasFieldItemTypes[];
    setPanelFields: (values: PanelHasFieldItemTypes[]) => void;
    analysisFields: FormAnalysisFieldTypes[];
}

const FormAnalysisFieldsSelectors: FC<FormAnalysisFieldsSelectorsTypes> = ({
    panelFields,
    setPanelFields,
    analysisFields,
}) => {
    const [t] = useTranslation();
    const [, setIsUpdated] = useState<boolean>(false);

    const handleChange = (event: SelectChangeEvent) => {
        const fieldId = Number(event.target.value);
        const selectedField = analysisFields.find(item => item.id === fieldId);
        if (selectedField) {
            setPanelFields([
                {
                    field: fieldId,
                },
            ]);
            setIsUpdated(previous => !previous);
        }
    };

    const selectedField = panelFields.length > 0 ? panelFields[0].field : null;

    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
            }}
        >
            <Typography sx={{fontWeight: 700, mb: 1}}>{`${t('orguser.analytics.panels.selectFields')}:`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <RadioGroup value={selectedField} onChange={handleChange}>
                    {analysisFields &&
                        analysisFields.map((item: FormAnalysisFieldTypes) => {
                            const {label, id} = item;
                            return (
                                <FormControlLabel
                                    key={`option-${id}`}
                                    value={id}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {label}
                                        </Typography>
                                    }
                                    sx={{
                                        width: '100%',
                                        overflow: 'hidden',
                                    }}
                                />
                            );
                        })}
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default FormAnalysisFieldsSelectors;
