import {TabItemType} from 'components/TabsWrapper/helper';

export const TAB_BOARD_GENERAL = 1;
export const TAB_BOARD_STATUSES = 2;
export const TAB_BOARD_PERMISSIONS = 3;

export const tabs: TabItemType[] = [
    {
        title: 'orguser.boards.tabs.general',
        tabNumber: TAB_BOARD_GENERAL,
        isDisabled: false,
    },
    {
        title: 'orguser.boards.tabs.statuses',
        tabNumber: TAB_BOARD_STATUSES,
        isDisabled: false,
    },
    {
        title: 'orguser.boards.tabs.permissions',
        tabNumber: TAB_BOARD_PERMISSIONS,
        isDisabled: false,
    },
];
