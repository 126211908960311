import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_IMPERSONATED_CASE_DETAILS} from 'appRedux/actions/requestCase';
import {ImpersonatedCaseDetailsFormTypes, ImpersonatedCaseDetailsTypes} from 'appRedux/actions/requestCase/types';

import {AlertContext} from 'contexts/alert/context';

import ImpersonalCaseDetailsForm from 'components/Forms/ImpersonalCaseForm/ImpersonalCaseDetailsForm';

interface ComponentType {
    item: ImpersonatedCaseDetailsTypes;
    closeModal: () => void;
}

const ImpersonatedCaseDetailsUpdateForm: FC<ComponentType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateImpersonatedCaseDetails = useCallback(
        data => dispatch({type: UPDATE_IMPERSONATED_CASE_DETAILS.REQUEST, payload: data}),
        [dispatch],
    );

    const {firstName, lastName, email, reason, uuid} = item;

    const onSubmitClicked = (values: ImpersonatedCaseDetailsFormTypes, {setErrors}) => {
        updateImpersonatedCaseDetails({
            uuid,
            ...values,
            setErrors,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ImpersonalCaseDetailsForm
            closeModal={closeModal}
            initialValues={{
                firstName,
                lastName,
                email,
                reason,
                pinCode: Math.floor(1000 + Math.random() * 9000),
            }}
            onSubmitAction={onSubmitClicked}
        />
    );
};

export default ImpersonatedCaseDetailsUpdateForm;
