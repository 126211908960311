import React, {FC} from 'react';

import {withStyles, WithStyles} from '@material-ui/core';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';
import MdxEditorCommon from 'components/MdxEditorField/MdxEditorCommon';
import mdxEditorStyles from 'components/MdxEditorField/MdxEditorStyles';

interface MdxEditorFieldTypes extends WithStyles<typeof mdxEditorStyles> {
    key?: string;
    label?: string;
    description?: string;
    notInPopup?: boolean;
    name: string;
    value?: string;
    customButtons?: MdxEditorCustomButtonType[];
}

const MdxEditorField: FC<MdxEditorFieldTypes> = ({classes, ...rest}) => {
    return <MdxEditorCommon mdxEditorClassName={classes.mdxEditorWrapper} {...rest} />;
};

export default withStyles(mdxEditorStyles)(MdxEditorField);
