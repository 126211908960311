import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {getModalDescriptionFormTitle} from 'pages/admin/updateWorkflow/partials/helper';

import CreateWorkflowStatusDescriptionTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/CreateWorkflowStatusDescriptionTranslationForm';
import UpdateWorkflowStatusDescriptionTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/UpdateWorkflowStatusDescriptionTranslationForm';

import {getLanguageIdByName} from 'helpers/translationsHelper';

interface WorkflowStatusDescriptionType {
    workflowStatus: WorkflowStatusItemTypes;
    currentLanguage: string;
}

const WorkflowStatusDescription: FC<WorkflowStatusDescriptionType> = ({workflowStatus, currentLanguage}) => {
    const [t] = useTranslation();

    const {myOrganization} = useContext(AdminContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentTranslation = workflowStatus.translations[currentLanguage];

    if (!myOrganization) return null;

    return (
        <Box>
            {currentTranslation && currentTranslation.translationDescription ? (
                <MarkdownWithShowAll
                    text={currentTranslation.translationDescription}
                    onTextClick={() => setIsOpened(true)}
                    isTable
                />
            ) : (
                <Typography
                    sx={{
                        fontSize: 14,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => setIsOpened(true)}
                >
                    {t('orguser.workflows.workflowStatusParameters.addDescription')}
                </Typography>
            )}
            <ModalWrapper
                isShowModal={isOpened}
                title={getModalDescriptionFormTitle(t, workflowStatus.title, currentTranslation)}
                toggleModal={toggleIsOpened}
            >
                {currentTranslation ? (
                    <UpdateWorkflowStatusDescriptionTranslationForm
                        currentItem={currentTranslation}
                        languageId={getLanguageIdByName(languageList, currentLanguage, myOrganization.language)}
                        closeForm={() => setIsOpened(false)}
                    />
                ) : (
                    <CreateWorkflowStatusDescriptionTranslationForm
                        statusUuid={workflowStatus.uuid}
                        languageId={getLanguageIdByName(languageList, currentLanguage, myOrganization.language)}
                        closeForm={() => setIsOpened(false)}
                    />
                )}
            </ModalWrapper>
        </Box>
    );
};

export default WorkflowStatusDescription;
