import React, {ChangeEvent, FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import TagChip from 'components/TagChip/TagChip';
import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {tagColors} from 'config/theme';

interface StatusColorRadioGroupType {
    title: string;
    parameter: string;
    value: string;
    setValue: (value: string) => void;
    isTouched: boolean;
    errorMessage: string | null;
}

const StatusColorRadioGroup: FC<StatusColorRadioGroupType> = ({
    title,
    parameter,
    value,
    setValue,
    isTouched,
    errorMessage,
}) => {
    const onColorSelect = (e: ChangeEvent<{value: string}>) => {
        setValue(e.target.value);
    };
    return (
        <Box sx={{pt: 2, pb: 1}}>
            <Typography sx={{fontWeight: 700}}>{`${title}:`}</Typography>
            <RadioGroup
                value={value}
                name={parameter}
                row
                sx={{
                    p: 1,
                    mb: 1,
                }}
            >
                {tagColors.map((tagColor: string, index: number) => {
                    const isChecked = value.toUpperCase() === tagColor.toUpperCase();
                    return (
                        <FormControlLabel
                            key={`color-${index}`}
                            value={tagColor}
                            control={
                                <Radio sx={{mb: 1.5, display: 'none'}} checked={isChecked} onChange={onColorSelect} />
                            }
                            label={
                                <TagChip id={String(index)} title={tagColor} color={tagColor} hasOpacity={isChecked} />
                            }
                        />
                    );
                })}
            </RadioGroup>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={errorMessage} />}
        </Box>
    );
};

export default StatusColorRadioGroup;
