import {
    WorkflowStatusRuleItemTypes,
    WorkflowStatusRuleApproverItemTypes,
    WorkflowStatusRuleApproversGroupItemTypes,
} from 'appRedux/actions/workflow/types';

import {WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED} from 'components/Forms/Workflow/workflowStatusRule/helper';

export const getApproversNumber = (
    approvers: WorkflowStatusRuleApproverItemTypes[],
    groups: WorkflowStatusRuleApproversGroupItemTypes[],
): number => {
    let approversNumber = approvers.length;
    groups.forEach(group => {
        const {agents} = group;
        agents.forEach(agent => {
            if (approvers.length === 0 || (approvers.length > 0 && approvers.find(item => item.id !== agent.id))) {
                approversNumber++;
            }
        });
    });
    return approversNumber;
};

export const checkStatusHasNotCompletedRules = (rules: WorkflowStatusRuleItemTypes[]): boolean => {
    const approvingRequiredRule = rules.find(item => item.type === WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED);
    if (approvingRequiredRule) {
        return isRuleNotCompleted(approvingRequiredRule);
    }
    return false;
};

export const isRuleNotCompleted = (rule: WorkflowStatusRuleItemTypes): boolean => {
    const {approvers, groups, requiredApproval} = rule;
    const approversNumber = getApproversNumber(approvers, groups);
    return approversNumber === 0 || approversNumber < Number(requiredApproval);
};
