export const LOCAL_STORAGE_TOKEN = 'TOKEN';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOCAL_STORAGE_PERSIST_ROOT = 'persist:root';
export const LOCAL_STORAGE_FILTER = 'agentFilter';
export const LOCAL_STORAGE_REDIRECT_AFTER_LOGIN = 'redirectAfterLogin';
export const LOCAL_STORAGE_ACTIVE_CASES_VIEW = 'activeCasesView';
export const LOCAL_STORAGE_CURRENT_LANGUAGE = 'language';
export const LOCAL_STORAGE_LOGOUT_TIME = 'LOGOUT_TIME';
export const LOCAL_STORAGE_NICKNAME_POPUP_STATUS = 'nicknamePopupStatus';

export const keys: {[key: string]: string} = {
    TOKEN: LOCAL_STORAGE_TOKEN,
    REFRESH_TOKEN: LOCAL_STORAGE_REFRESH_TOKEN,
};

export const isLocalStorageAvailable = (): boolean => {
    try {
        const testKey = '__test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

export const removeLocalStorageData = async (key: string): Promise<void> => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing data from localStorage:', error);
    }
};

export const storeLocalStorageData = async (key: string, value: string) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error('Error storing data in localStorage:', error);
    }
};

export const getLocalStorageData = async (key: string): Promise<string | null> => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.error('Error getting data from localStorage:', error);
        return null;
    }
};

export const setToken = async (token: string): Promise<void> => {
    await storeLocalStorageData(keys.TOKEN, token);
};

export const getToken = async (): Promise<unknown | undefined> => {
    const data = await getLocalStorageData(keys.TOKEN);
    return data as string;
};

export const setRefreshToken = async (refreshToken: string): Promise<void> => {
    await storeLocalStorageData(keys.REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = async (): Promise<unknown | undefined> => {
    const data = await getLocalStorageData(keys.REFRESH_TOKEN);
    return data as string;
};

export const storeLogoutTime = async (time: number): Promise<void> => {
    await storeLocalStorageData(LOCAL_STORAGE_LOGOUT_TIME, String(time));
};

export const getLogoutTime = async (): Promise<number | undefined> => {
    const data = await getLocalStorageData(LOCAL_STORAGE_LOGOUT_TIME);
    return data ? Number(data) : undefined;
};
