import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import PauseSvgIcon from 'assets/icons/buttons/PauseSvgIcon';

import {FormItemBasic, CommonUrlParameterTypes} from 'appRedux/actions/forms/types';

import {PermissionContext} from 'contexts/permission/context';

import PauseForm from 'components/Forms/FormBuilder/PauseForm/PauseForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {checkIsFormUpdatingEnabled} from 'helpers/permissions';

interface PauseReasonModalType {
    form: FormItemBasic;
    commonUrlParameters: CommonUrlParameterTypes;
}

const PauseReasonModal: FC<PauseReasonModalType> = ({form, commonUrlParameters}) => {
    const [t] = useTranslation();

    const {isFormEditEnabled, filtersFormEditAllowed} = useContext(PermissionContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const isEditEnabled = checkIsFormUpdatingEnabled(isFormEditEnabled, filtersFormEditAllowed, form.id);

    return (
        <>
            <IconButton title={t('orguser.forms.pauseOn')} onClick={toggleModal} disabled={!isEditEnabled}>
                <PauseSvgIcon isDisabled={!isEditEnabled} />
            </IconButton>
            <ModalWrapper
                title={t('orguser.forms.pauseOn')}
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                isWideModal
            >
                <PauseForm form={form} toggleModal={toggleModal} commonUrlParameters={commonUrlParameters} />
            </ModalWrapper>
        </>
    );
};

export default PauseReasonModal;
