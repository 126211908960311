import React, {FC, ReactNode} from 'react';
import get from 'lodash/get';
import {FieldProps} from 'formik';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import {createStyles, Select, WithStyles, withStyles} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

const styles = () =>
    createStyles({
        input: {
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface FormikTextInputTypes extends WithStyles<typeof styles> {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
}

const FormBuilderSelectInput: FC<FieldProps & FormikTextInputTypes & {children: ReactNode}> = ({
    classes,
    field,
    form: {touched, errors},
    name,
    label,
    children,
    required,
    ...props
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);

    return (
        <Box sx={{mt: 2, mb: 1, width: '100%'}}>
            {label && (
                <Typography
                    sx={{
                        mb: 0.5,
                        fontSize: 12,
                        color: `rgba(0, 0, 0, 0.6)`,
                    }}
                >
                    {label}
                    {required && <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>}
                </Typography>
            )}
            <FormControl fullWidth required={Boolean(required)}>
                <Select
                    fullWidth
                    required={Boolean(required)}
                    variant="standard"
                    inputProps={{className: classes.input}}
                    IconComponent={KeyboardArrowDownIcon}
                    id={name}
                    {...field}
                    {...props}
                >
                    {children}
                </Select>
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(FormBuilderSelectInput);
