import React, {FC} from 'react';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {DISPLAY_TYPE_BAR_CHART, TYPE_TEXT_FIELD_ANALYSIS} from 'components/Forms/Dashboard/helper';

import BarStatisticChart from 'pages/admin/analytics/charts/BarStatisticChart';
import CircleStatisticChart from 'pages/admin/analytics/charts/CircleStatisticChart';
import DashboardPanelTextFieldAnalysis from 'pages/admin/analytics/panels/DashboardPanelTextFieldAnalysis';

interface ChartAreaType {
    item: PanelItemsTypes;
}

const DashboardPanelChartArea: FC<ChartAreaType> = ({item}) => {
    const {displayType, type} = item;

    if (type === TYPE_TEXT_FIELD_ANALYSIS) {
        return <DashboardPanelTextFieldAnalysis item={item} />;
    }

    if (displayType === DISPLAY_TYPE_BAR_CHART) {
        return <BarStatisticChart item={item} />;
    }
    return <CircleStatisticChart item={item} />;
};

export default DashboardPanelChartArea;
