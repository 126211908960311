import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';
import {FormikErrors} from 'formik';

import {http} from 'services/http';
import {setToken, setRefreshToken} from 'services/localStorage';

import {
    FORMS_LIST,
    formsListInfo,
    FORMS_LIST_BY_ORGANIZATION,
    formsListByOrganizationInfo,
    FORMS_LIST_BY_BOARD,
    formsListByBoardInfo,
    FORMS_LIST_FOR_REQUESTER,
    FORM_CHANGE_STATUS,
    FORM_DELETE,
    FORMS_COPY,
    FORM_CREATE,
    FORM_UPDATE,
    FORM_MULTIPLE_STATUS_CHANGING,
    FORM_INFORMATION,
    FORM_INFORMATION_BY_VERSION,
    FORM_GDPR_FIELDS_GET,
    FORM_BY_CASE_INFORMATION,
    FORM_FIELD_POPUP_ADD,
    FORM_UPLOAD_LOGO,
    FORM_LOGO_GET,
    FORM_LOGO_DELETE,
    FORM_FILTERS_GET,
    FORM_ANALYSIS_FIELDS_GET,
    getFormAnalysisFields,
    FORM_PAUSE_ON,
    FORM_PAUSE_OFF,
    FORM_WORKFLOW_GET_STATUSES,
    FORM_WORKFLOW_UPDATE,
    formChangeStatus,
    getFormsListForRequester,
    deleteForm,
    copyForm,
    createForm,
    updateForm,
    getFormInformation,
    getFormInformationByVersion,
    getFormGdprFields,
    getFormByCaseInformation,
    addFormPopupField,
    multipleFormStatusChanging,
    uploadFormLogo,
    getFormLogo,
    formLogoDelete,
    getFormFilters,
    formPauseOn,
    formPauseOff,
    getFormWorkflowStatuses,
    updateFormWorkflow,
    FORM_LABEL_ORGANIZATION_LIST,
    getFormLabelsForOrganization,
    FORM_LABEL_LIST,
    getFormLabelsForForm,
    FORM_LABEL_CREATE,
    createFormLabel,
    FORM_LABEL_UPDATE,
    updateFormLabel,
    FORM_LABEL_ATTACH,
    attachFormLabel,
    FORM_LABEL_DETACH,
    detachFormLabel,
} from 'appRedux/actions/forms';
import {
    FormsListRequestType,
    FormChangeStatusType,
    FormItemBasic,
    FormAddFieldRequest,
    FormMultipleChangeStatusType,
    FormConfigurationRequestTypes,
    FormResponseTypes,
    FormsTableListResponseType,
    FormsTableListCopyResponseType,
    FormCreateResponseTypes,
    CommonUploadLogoTypes,
    CommonDeleteLogoTypes,
    CommonGetLogoUrlTypes,
    FormMultipleActionType,
    CommonLogoResponseType,
    FormFieldFilterResponseType,
    FormPauseRequestType,
    FormFieldAddResponseType,
    FormWorkflowStatusesRequest,
    UpdateFormWorkflowRequestTypes,
    CurrentFormWorkflowStatusesResponseTypes,
    FormItemBasicResponseTypes,
    FormsListForRequesterListRequestTypes,
    FormsListForRequesterListResponseTypes,
    FormGdprFieldsResponseType,
    FormsListResponseType,
    FormsListByOrganizationRequestType,
    FormsListByBoardRequestType,
    FormInformationByVersionRequestType,
    FormAnalysisFieldsRequestTypes,
    FormAnalysisFieldsResponseTypes,
    FormLabelOrganizationListRequestTypes,
    FormLabelListRequestTypes,
    FormLabelCreateRequestTypes,
    FormLabelUpdateRequestTypes,
    FormLabelAttachRequestTypes,
    FormLabelDetachRequestTypes,
    FormLabelResponseTypes,
} from 'appRedux/actions/forms/types';

import {getLogoUploadErrorMessage} from 'helpers/documentsUploading';
import {getRouteWithParameters} from 'helpers/sagasHelper';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetFormsList() {
    while (true) {
        const {
            payload: {showAlert, page, perPage, search, sortBy, sortType},
        }: IActionType<FormsListRequestType> = yield take(FORMS_LIST.REQUEST);
        try {
            const route = getRouteWithParameters(`forms`, {
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });

            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(formsListInfo.success(data.results));
            } else {
                yield put(formsListInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formsListInfo.error({message: String(e)}));
        }
    }
}

function* watchGetFormsListByOrganization() {
    while (true) {
        const {
            payload: {id, showAlert, workflow, page, perPage, search, labels, sortBy, sortType},
        }: IActionType<FormsListByOrganizationRequestType> = yield take(FORMS_LIST_BY_ORGANIZATION.REQUEST);
        try {
            const route = getRouteWithParameters(`forms/${id}/organization`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });

            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(formsListByOrganizationInfo.success(data.results));
            } else {
                yield put(formsListByOrganizationInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formsListByOrganizationInfo.error({message: String(e)}));
        }
    }
}

function* watchGetFormsListByBoard() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<FormsListByBoardRequestType> = yield take(FORMS_LIST_BY_BOARD.REQUEST);
        try {
            const data: FormsListResponseType = yield call(http, `forms/${uuid}/board`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(formsListByBoardInfo.success(data.results));
            } else {
                yield put(formsListByBoardInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formsListByBoardInfo.error({message: String(e)}));
        }
    }
}

function* watchGetFormsListForRequester() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<FormsListForRequesterListRequestTypes> = yield take(FORMS_LIST_FOR_REQUESTER.REQUEST);
        try {
            const data: FormsListForRequesterListResponseTypes = yield call(http, `forms/requester`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormsListForRequester.success(data.results));
            } else {
                yield put(getFormsListForRequester.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormsListForRequester.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormInformation() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_INFORMATION.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/content`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormInformation.success(data));
            } else {
                yield put(getFormInformation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormInformation.error({message: String(e)}));
        }
    }
}

function* watchFormInformationByVersion() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<FormInformationByVersionRequestType> = yield take(FORM_INFORMATION_BY_VERSION.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${uuid}/content/version`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormInformationByVersion.success(data));
            } else {
                yield put(getFormInformationByVersion.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormInformationByVersion.error({message: String(e)}));
        }
    }
}

function* watchFormGdprFieldsRequest() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_GDPR_FIELDS_GET.REQUEST);
        try {
            const data: FormGdprFieldsResponseType = yield call(http, `form/${id}/gdpr/statistic`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormGdprFields.success(data.results));
            } else {
                yield put(getFormGdprFields.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormGdprFields.error({message: String(e)}));
        }
    }
}

function* watchFormByCaseInformation() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormChangeStatusType> = yield take(FORM_BY_CASE_INFORMATION.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/case`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormByCaseInformation.success(data));
                callback && callback();
            } else {
                yield put(getFormByCaseInformation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormByCaseInformation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldFilters() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FILTERS_GET.REQUEST);
        try {
            const data: FormFieldFilterResponseType = yield call(http, `form/${id}/filters`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormFilters.success(data));
                callback && callback();
            } else {
                yield put(getFormFilters.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormFilters.error({message: String(e)}));
        }
    }
}

function* watchFormAnalysisFields() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormAnalysisFieldsRequestTypes> = yield take(FORM_ANALYSIS_FIELDS_GET.REQUEST);
        try {
            const data: FormAnalysisFieldsResponseTypes = yield call(http, `form/${id}/fields/analysis`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormAnalysisFields.success(data.results));
                callback && callback();
            } else {
                yield put(getFormAnalysisFields.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormAnalysisFields.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchStatusChanging() {
    while (true) {
        const {
            payload: {
                id,
                showAlert,
                callbackError,
                callback,
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            },
        }: IActionType<FormChangeStatusType> = yield take(FORM_CHANGE_STATUS.REQUEST);
        try {
            const route = getRouteWithParameters(`form/active/${id}`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data.success) {
                yield put(formChangeStatus.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formChangeStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                callbackError && callbackError();
            }
        } catch (e) {
            yield put(formChangeStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPauseOn() {
    while (true) {
        const {
            payload: {id, callback, showAlert, workflow, labels, page, perPage, search, sortBy, sortType, ...values},
        }: IActionType<FormChangeStatusType> = yield take(FORM_PAUSE_ON.REQUEST);
        try {
            const route = getRouteWithParameters(`form/${id}/pause`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formPauseOn.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPauseOn.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPauseOn.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPauseOff() {
    while (true) {
        const {
            payload: {id, callback, workflow, labels, page, perPage, search, sortBy, sortType, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_PAUSE_OFF.REQUEST);
        try {
            const route = getRouteWithParameters(`form/${id}/unpause`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formPauseOff.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPauseOff.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPauseOff.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteForm() {
    while (true) {
        const {
            payload: {id, callback, workflow, labels, page, perPage, search, sortBy, sortType, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_DELETE.REQUEST);
        try {
            const route = getRouteWithParameters(`form/${id}/delete`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteForm.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormCopying() {
    while (true) {
        const {
            payload: {ids, callback, workflow, labels, page, perPage, search, sortBy, sortType, showAlert},
        }: IActionType<FormMultipleActionType> = yield take(FORMS_COPY.REQUEST);
        try {
            const route = getRouteWithParameters(`forms/copy?id=${ids.join(',')}`, {
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const {success, results, token, refreshToken}: FormsTableListCopyResponseType = yield call(http, route, {
                method: 'GET',
            });
            if (success) {
                yield put(copyForm.success(results));
                if (token && refreshToken) {
                    yield setToken(token);
                    yield setRefreshToken(refreshToken);
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormCreating() {
    while (true) {
        const {
            payload: {callback, setErrors, showAlert, ...values},
        }: IActionType<FormConfigurationRequestTypes> = yield take(FORM_CREATE.REQUEST);
        try {
            const {success, newFormId, results, token, refreshToken, errors}: FormCreateResponseTypes = yield call(
                http,
                `form/create`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (success) {
                yield put(createForm.success(results));
                if (token && refreshToken) {
                    yield setToken(token);
                    yield setRefreshToken(refreshToken);
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                if (newFormId) {
                    callback && callback(Number(newFormId));
                }
            } else {
                yield put(createForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors && setErrors(errors);
            }
        } catch (e) {
            yield put(createForm.error({message: String(e)}));
        }
    }
}

function* watchFormUpdating() {
    while (true) {
        const {
            payload: {id, setErrors, showAlert, ...values},
        }: IActionType<FormConfigurationRequestTypes> = yield take(FORM_UPDATE.REQUEST);
        try {
            const data: FormsTableListResponseType = yield call(http, `form/edit/${id}`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateForm.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateForm.error({message: 'messages.error.somethingWentWrong'}));
                const errors: FormikErrors<FormConfigurationRequestTypes> = get(data, 'errors', {});
                setErrors && setErrors(errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormMultipleStatusChanging() {
    while (true) {
        const {
            payload: {
                ids,
                isActive,
                callback,
                callbackError,
                showAlert,
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            },
        }: IActionType<FormMultipleChangeStatusType> = yield take(FORM_MULTIPLE_STATUS_CHANGING.REQUEST);
        try {
            const route = getRouteWithParameters(`forms/status`, {
                id: ids.join(','),
                isActive: isActive ? '1' : '0',
                workflow,
                labels,
                page,
                perPage,
                search,
                sortBy,
                sortType,
            });
            const data: FormsTableListResponseType = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(multipleFormStatusChanging.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(multipleFormStatusChanging.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                callbackError && callbackError();
            }
        } catch (e) {
            yield put(multipleFormStatusChanging.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldPopupAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_POPUP_ADD.REQUEST);
        try {
            const data: FormFieldAddResponseType = yield call(http, `field/${id}/create/popup`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(addFormPopupField.success(pages));
                callback && data.fieldId && callback(data.fieldId);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormPopupField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormPopupField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLogoUploading() {
    while (true) {
        const {
            payload: {id, file, setErrors, callback, showAlert},
        }: IActionType<CommonUploadLogoTypes> = yield take(FORM_UPLOAD_LOGO.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: FormsTableListResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `form/logo/${id}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(uploadFormLogo.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                const errorMessage = getLogoUploadErrorMessage(data.errors);
                setErrors && setErrors(errorMessage);
                yield put(uploadFormLogo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, errorMessage);
            }
        } catch (e) {
            yield put(uploadFormLogo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLogoGetting() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<CommonGetLogoUrlTypes> = yield take(FORM_LOGO_GET.REQUEST);
        try {
            const data: CommonLogoResponseType = yield call(http, `form/logo/${id}`, {
                method: 'GET',
            });
            if (data.fileLink) {
                yield put(getFormLogo.success(data));
                callback && callback(data.fileLink);
            } else {
                yield put(getFormLogo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormLogo.error({message: String(e)}));
        }
    }
}

function* watchFormLogoRemoving() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonDeleteLogoTypes> = yield take(FORM_LOGO_DELETE.REQUEST);
        try {
            const data: FormsTableListResponseType = yield call(http, `form/logo/${id}/delete`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(formLogoDelete.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formLogoDelete.error({message: 'messages.error.somethingWentWrong'}));
                const errors: FormikErrors<FormConfigurationRequestTypes> = get(data, 'errors', {});
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formLogoDelete.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormWorkflowStatusesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<FormWorkflowStatusesRequest> = yield take(FORM_WORKFLOW_GET_STATUSES.REQUEST);
        try {
            const data: CurrentFormWorkflowStatusesResponseTypes = yield call(http, `form/workflow/${id}/statuses`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormWorkflowStatuses.success(data.results));
                callback && callback();
            } else {
                yield put(getFormWorkflowStatuses.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormWorkflowStatuses.error({message: String(e)}));
        }
    }
}

function* watchFormWorkflowUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<UpdateFormWorkflowRequestTypes> = yield take(FORM_WORKFLOW_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `form/workflow/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormWorkflow.error({message: String(e)}));
        }
    }
}

function* watchFormLabelsForOrganizationRequest() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<FormLabelOrganizationListRequestTypes> = yield take(FORM_LABEL_ORGANIZATION_LIST.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/organization`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormLabelsForOrganization.success(data.results));
                callback && callback();
            } else {
                yield put(getFormLabelsForOrganization.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormLabelsForOrganization.error({message: String(e)}));
        }
    }
}

function* watchFormLabelsForFormRequest() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<FormLabelListRequestTypes> = yield take(FORM_LABEL_LIST.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormLabelsForForm.success(data.results));
                callback && callback();
            } else {
                yield put(getFormLabelsForForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormLabelsForForm.error({message: String(e)}));
        }
    }
}

function* watchFormLabelCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<FormLabelCreateRequestTypes> = yield take(FORM_LABEL_CREATE.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createFormLabel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createFormLabel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createFormLabel.error({message: String(e)}));
        }
    }
}

function* watchFormLabelUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<FormLabelUpdateRequestTypes> = yield take(FORM_LABEL_UPDATE.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormLabel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormLabel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateFormLabel.error({message: String(e)}));
        }
    }
}

function* watchFormLabelAttaching() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<FormLabelAttachRequestTypes> = yield take(FORM_LABEL_ATTACH.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/${id}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachFormLabel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachFormLabel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(attachFormLabel.error({message: String(e)}));
        }
    }
}

function* watchFormLabelDetaching() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<FormLabelDetachRequestTypes> = yield take(FORM_LABEL_DETACH.REQUEST);
        try {
            const data: FormLabelResponseTypes = yield call(http, `form/label/${uuid}/detach`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(detachFormLabel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(detachFormLabel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(detachFormLabel.error({message: String(e)}));
        }
    }
}

export default [
    fork(watchGetFormsList),
    fork(watchGetFormsListByOrganization),
    fork(watchGetFormsListByBoard),
    fork(watchGetFormsListForRequester),
    fork(watchFormInformation),
    fork(watchFormInformationByVersion),
    fork(watchFormGdprFieldsRequest),
    fork(watchFormByCaseInformation),
    fork(watchStatusChanging),
    fork(watchDeleteForm),
    fork(watchFormPauseOn),
    fork(watchFormPauseOff),
    fork(watchFormCopying),
    fork(watchFormCreating),
    fork(watchFormUpdating),
    fork(watchFormMultipleStatusChanging),
    fork(watchFormFieldPopupAdding),
    fork(watchFormLogoUploading),
    fork(watchFormLogoGetting),
    fork(watchFormLogoRemoving),
    fork(watchFormAnalysisFields),
    fork(watchFormFieldFilters),
    fork(watchFormWorkflowStatusesGetting),
    fork(watchFormWorkflowUpdating),
    fork(watchFormLabelsForOrganizationRequest),
    fork(watchFormLabelsForFormRequest),
    fork(watchFormLabelCreation),
    fork(watchFormLabelUpdating),
    fork(watchFormLabelAttaching),
    fork(watchFormLabelDetaching),
];
