import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {DELETE_BOARD, GET_MENU_BOARDS_LIST} from 'appRedux/actions/workflow';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteBoardButtonType {
    item: BoardItemTypes;
}

const DeleteBoardButton: FC<DeleteBoardButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const deleteBoard = useCallback(data => dispatch({type: DELETE_BOARD.REQUEST, payload: data}), [dispatch]);

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteBoard({
            uuid: item.uuid,
            showAlert,
            callback: () => {
                setIsOpened(false);
                if (isCaseViewEnabled) {
                    getMenuBoardsList({showAlert});
                }
            },
        });
    };

    return (
        <>
            <IconButton onClick={toggleIsOpened} title={t('common.buttons.delete')}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteBoardButton;
