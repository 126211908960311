import React, {FC} from 'react';

import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {getTagColor, isDarkColor} from 'pages/admin/updateForm/partials/FormTags/helper';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface TagChipWithDeletingType {
    id: string | number;
    title: string;
    color?: string;
    isEditIcon?: boolean;
    onUpdateClick?: () => void;
    onEditIconClick?: () => void;
    onDeleteClick?: () => void;
}

const TagChipWithDeleting: FC<TagChipWithDeletingType> = ({
    id,
    title,
    color,
    isEditIcon,
    onUpdateClick,
    onEditIconClick,
    onDeleteClick,
}) => {
    const backgroundColor = color ?? CLIENT_BACKGROUND_COLOR;
    return (
        <Chip
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 24,
                fontSize: 12,
                borderRadius: 12,
                backgroundColor: backgroundColor,
                color: getTagColor(backgroundColor),
                mr: 1,
                mb: 1,
            }}
            key={`tag-chip-${id}`}
            label={title}
            onClick={onUpdateClick}
            onDelete={isEditIcon ? onEditIconClick : onDeleteClick}
            deleteIcon={
                <IconButton sx={{ml: 5, p: 0, width: 16, height: 16}} disableRipple>
                    {isEditIcon ? (
                        <EditSvgIcon isActive={!isDarkColor(backgroundColor)} />
                    ) : (
                        <CancelSvgIcon isActive={!isDarkColor(backgroundColor)} />
                    )}
                </IconButton>
            }
        />
    );
};

export default TagChipWithDeleting;
