import React, {FC, useCallback, useContext} from 'react';
import {Formik, Form, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FORM_PAUSE_ON} from 'appRedux/actions/forms';
import {FormPauseRequestType, FormItemBasic, CommonUrlParameterTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import {formValidationSchema} from 'components/Forms/FormBuilder/PauseForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {preventSendForm} from 'helpers/index';

interface FormPauseType {
    form: FormItemBasic;
    toggleModal: () => void;
    commonUrlParameters?: CommonUrlParameterTypes;
}

const PauseForm: FC<FormPauseType> = ({form, toggleModal, commonUrlParameters}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {id, pauseReason} = form;

    const {showAlert} = useContext(AlertContext);

    const formPauseOn = useCallback(data => dispatch({type: FORM_PAUSE_ON.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleSubmit = (values: FormPauseRequestType) => {
        formPauseOn({
            ...values,
            id,
            callback: toggleModal,
            ...commonUrlParameters,
            showAlert,
        });
    };

    return (
        <Formik
            initialValues={{
                pauseReason: pauseReason ?? '',
            }}
            validationSchema={formValidationSchema}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<FormPauseRequestType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <MdxEditorField
                            name="pauseReason"
                            value={formik.values.pauseReason ? String(formik.values.pauseReason) : ''}
                            label={t('orguser.forms.pauseReason')}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isLoading={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PauseForm;
