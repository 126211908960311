import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_BOARD, GET_MENU_BOARDS_LIST} from 'appRedux/actions/workflow';
import {BoardItemTypes, BoardFormItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import BoardGeneralForm from 'components/Forms/BoardForm/BoardGeneralForm';

interface CreateBoardFormType {
    item: BoardItemTypes;
}

const UpdateBoardGeneralForm: FC<CreateBoardFormType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const {title, workflowId, type, typeSwimlane, typePermission, iconType, uuid} = item;

    const getMenuBoardsList = useCallback(
        data => dispatch({type: GET_MENU_BOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const updateBoard = useCallback(data => dispatch({type: UPDATE_BOARD.REQUEST, payload: data}), [dispatch]);

    const onSubmitAction = (values: BoardFormItemTypes, {setErrors}) => {
        updateBoard({
            ...values,
            showAlert,
            uuid,
            setErrors,
            callback: () => {
                if (isCaseViewEnabled) {
                    getMenuBoardsList({showAlert});
                }
            },
        });
    };

    return (
        <BoardGeneralForm
            initialValues={{
                title,
                type,
                typeSwimlane,
                typePermission,
                workflow: workflowId,
                iconType,
            }}
            onSubmitAction={onSubmitAction}
        />
    );
};

export default UpdateBoardGeneralForm;
