export const ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_TYPE = 'ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_TYPE';
export const ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_SIZE = 'ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_SIZE';
export const ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_FORM = 'ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_FORM';
export const ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_LANGUAGE = 'ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_LANGUAGE';
export const ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_HTML = 'ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_HTML';
export const ERROR_MESSAGE_WRONG_DOCUMENT_TYPE = 'WRONG_DOCUMENT_TYPE';
export const ERROR_MESSAGE_WRONG_LOGO_TYPE = 'WRONG_LOGO_TYPE';
export const ERROR_MESSAGE_WRONG_FAVICON_TYPE = 'WRONG_FAVICON_TYPE';
export const ERROR_MESSAGE_WRONG_DOCUMENT_SIZE = 'WRONG_DOCUMENT_SIZE';
export const ERROR_MESSAGE_WRONG_LOGO_SIZE = 'WRONG_LOGO_SIZE';
export const ERROR_MESSAGE_EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
export const ERROR_OUTDATED_INFORMATION = 'ERROR_OUTDATED_INFORMATION';
export const ERROR_SECTION_ALREADY_CHECKED = 'ERROR_SECTION_ALREADY_CHECKED';
export const PASSWORD_RESET_REQUEST_ALREADY_EXISTS = 'PASSWORD_RESET_REQUEST_ALREADY_EXISTS';
export const ERROR_UNAVAILABLE_WORK_WITH_FORM = 'ERROR_UNAVAILABLE_WORK_WITH_FORM';
export const ERROR_INFECTED_FILE = 'ERROR_INFECTED_FILE';
