import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import SaveSvgIcon from 'assets/icons/buttons/SaveSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {AgentSavedFilterFormTypes} from 'appRedux/actions/agentFilters/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import SavedFilterForm from 'components/Forms/SavedFilterForm/SavedFilterForm';
import AgentIconButton from 'components/AgentScreenComponents/_buttons/AgentIconButton';
import {getSavedFilterModalTitle} from 'components/BlockView/helper';

interface SaveFilterButtonType {
    uuid: string;
}

const SaveFilterButton: FC<SaveFilterButtonType> = ({uuid}) => {
    const [t] = useTranslation();

    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [updatedFilter, setUpdatedFilter] = useState<AgentSavedFilterFormTypes | null>(null);

    const {
        workflow: {boardsMenu},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleIsModalOpened = () => {
        setIsModalOpened(previous => !previous);
    };

    const board = boardsMenu && boardsMenu.find(item => item.uuid === uuid);

    if (!board) return null;

    return (
        <>
            <AgentIconButton onClick={toggleIsModalOpened} icon={<SaveSvgIcon />} />
            <ModalWrapper
                isShowModal={isModalOpened}
                title={getSavedFilterModalTitle(t, updatedFilter)}
                toggleModal={toggleIsModalOpened}
            >
                <SavedFilterForm
                    boardId={board.id}
                    updatedFilter={updatedFilter}
                    setUpdatedFilter={setUpdatedFilter}
                    closeModal={() => setIsModalOpened(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default SaveFilterButton;
