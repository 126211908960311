import React, {FC, useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import ClientFormContextWrapper from 'contexts/clientForm';

import ClientForm from 'pages/client/form/ClientForm';

import {DEFAULT_PAGE} from 'config/index';

const TicketCreationPage: FC = () => {
    const {
        backTab,
        setBackLink,
        setPageTitle,
        setFormPage,
        setFormSection,
        setTopBarTitle,
        setBackLinkLabel,
        setTopBarSubtitle,
    } = useContext(RouteContext);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setTopBarSubtitle('');
        setBackLinkLabel('');
        setFormPage(DEFAULT_PAGE);
        setFormSection(null);
    }, [currentCase, backTab]);

    return (
        <ClientFormContextWrapper>
            <ClientForm />
        </ClientFormContextWrapper>
    );
};

export default TicketCreationPage;
