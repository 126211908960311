import React, {FC, useCallback, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORMS_LIST_BY_ORGANIZATION} from 'appRedux/actions/forms';
import {INVITES_GET_LIST} from 'appRedux/actions/invites';
import {InviteFormsTypes} from 'appRedux/actions/invites/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import InvitesList from 'components/Invites/InvitesList';

const InvitesWrapper: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isInviteCreateEnabled, filtersInviteCreateAllowed} = useContext(PermissionContext);

    const getInvitesList = useCallback(data => dispatch({type: INVITES_GET_LIST.REQUEST, payload: data}), [dispatch]);
    const getFormsListByOrganization = useCallback(
        data => dispatch({type: FORMS_LIST_BY_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formsList},
        invites: {invites},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (myOrganization) {
            getInvitesList({
                showAlert,
            });
            getFormsListByOrganization({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    const inviteForms: InviteFormsTypes[] | undefined = useMemo(
        () =>
            formsList
                ?.filter(form => {
                    const isInviteCreateEnabledForForm =
                        isInviteCreateEnabled && filtersInviteCreateAllowed.includes(Number(form.id));
                    return form.isOnlyInvite && form.isActive && isInviteCreateEnabledForForm;
                })
                .map(form => ({formId: form.id, formTitle: form.title})),
        [formsList],
    );

    if ((inviteForms && inviteForms.length > 0) || (invites && invites.length > 0)) {
        return <InvitesList invites={invites} inviteForms={inviteForms} />;
    }
    return (
        <Box>
            <Typography>{t('orguser.invites.noneActiveFormWithInvites')}</Typography>
        </Box>
    );
};

export default InvitesWrapper;
