import React, {FC} from 'react';
import {FieldProps} from 'formik';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';
import {FormControl, TextField, IconButton, Stack, Typography, Tooltip} from '@mui/material';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {convertTimeStringToNum, convertNumToTimeString, getLaunguageMap} from 'helpers/parseTimeString';

const styles = () =>
    createStyles({
        helperText: {
            marginTop: 4,
            fontSize: '0.75rem',
            color: 'rgba(0, 0, 0, 0.6)',
        },
    });

interface TimeAdjusterProps {
    label: string;
    onIncrement: () => void;
    onDecrement: () => void;
}

const TimeAdjuster: FC<TimeAdjusterProps> = ({label, onIncrement, onDecrement}) => (
    <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        justifyContent="center"
        sx={{border: '1px solid rgba(0, 0, 0, 0.4)', borderRadius: '16px'}}
    >
        <IconButton onClick={onDecrement} size="small" sx={{p: '2px'}}>
            <RemoveIcon fontSize="small" />
        </IconButton>
        <Typography variant="body2" sx={{width: 30, textAlign: 'center'}}>
            {label}
        </Typography>
        <IconButton onClick={onIncrement} size="small" sx={{p: '2px'}}>
            <AddIcon fontSize="small" />
        </IconButton>
    </Stack>
);

interface FormikTimeInputProps extends WithStyles<typeof styles> {
    name?: string;
    classes: {helperText: string};
    placeholder?: string;
    required?: boolean;
    helperText?: string;
}

const FormBuilderTimeInput: FC<FieldProps & FormikTimeInputProps> = ({
    field,
    form: {touched, errors, setFieldValue},
    placeholder,
    required,
    classes,
    helperText,
}) => {
    const {t} = useTranslation();
    const fieldError = get(errors, field.name);
    const showError = get(touched, field.name) && fieldError;
    const languageMap = getLaunguageMap(t);

    const TIME_ADJUSTMENTS = [
        {seconds: 5 * 60, label: `5${languageMap.minute[0]}`},
        {seconds: 15 * 60, label: `15${languageMap.minute[0]}`},
        {seconds: 3600, label: `1${languageMap.hour[0]}`},
        {seconds: 8 * 3600, label: `1${languageMap.day[0]}`},
    ] as const;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFieldValue(field.name, value);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value) {
            return;
        }

        try {
            const seconds = convertTimeStringToNum(value, t);
            const formatted = convertNumToTimeString(seconds, t);
            setFieldValue(field.name, formatted);
        } catch (error) {
            // Keep the invalid value to show validation error
        }
    };

    const handleTimeAdjustment = (seconds: number) => {
        const currentValue = field.value || '';
        const {seconds: currentSeconds} = validateAndConvertTime(currentValue);
        const newSeconds = Math.max(0, currentSeconds + seconds);
        const newValue = convertNumToTimeString(newSeconds, t);
        setFieldValue(field.name, newValue);
    };

    const handleIncrement = (seconds: number) => () => handleTimeAdjustment(seconds);
    const handleDecrement = (seconds: number) => () => handleTimeAdjustment(-seconds);

    const validateAndConvertTime = (value: string) => {
        try {
            const seconds = convertTimeStringToNum(value, t);
            const formatted = convertNumToTimeString(seconds, t);
            return {isValid: true, formatted, seconds};
        } catch (error) {
            return {isValid: false, formatted: value, seconds: 0};
        }
    };

    return (
        <FormControl fullWidth>
            <TextField
                {...field}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                required={required}
                variant="standard"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <Tooltip title={t('orguser.requesterCase.timeTracking.timeFormat')} placement="top">
                            <IconButton size="small" sx={{p: 0}}>
                                <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ),
                }}
            />
            {showError && <FormFieldErrorMessage errorMessage={String(fieldError)} />}
            <Stack spacing={1} sx={{mt: 1}} direction="row" alignItems="center" justifyContent="space-around">
                {TIME_ADJUSTMENTS.map(({seconds, label}) => (
                    <TimeAdjuster
                        key={label}
                        label={label}
                        onIncrement={handleIncrement(seconds)}
                        onDecrement={handleDecrement(seconds)}
                    />
                ))}
            </Stack>
        </FormControl>
    );
};

export default withStyles(styles)(FormBuilderTimeInput);
