import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_STATUS_TRANSLATION} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusDescriptionForm from 'components/Forms/Workflow/workflowStatusTranslation/WorkflowStatusDescriptionForm';

interface ComponentType {
    statusUuid: string;
    languageId: number;
    closeForm: () => void;
}

const CreateWorkflowStatusDescriptionTranslationForm: FC<ComponentType> = ({statusUuid, languageId, closeForm}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createWorkflowStatusTranslation = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusTranslationFormTypes) => {
        createWorkflowStatusTranslation({
            ...values,
            uuid: statusUuid,
            showAlert,
            callback: closeForm,
        });
    };

    return (
        <WorkflowStatusDescriptionForm
            initialValues={{
                translation: '',
                translationRequester: '',
                translationDescription: '',
                language: languageId,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateWorkflowStatusDescriptionTranslationForm;
