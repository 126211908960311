import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {PermissionContext} from 'contexts/permission/context';

import {TableHeadTypography} from 'components/AgentScreenComponents/_form/CustomElements';

import BoardTableRow from 'pages/admin/boardsList/BoardTableRow';

interface BoardsTableType {
    itemsPortion: BoardItemTypes[];
}

const BoardsTable: FC<BoardsTableType> = ({itemsPortion}) => {
    const [t] = useTranslation();

    const {isWorkWithBoardEnabled} = useContext(PermissionContext);

    const [dragItem, setDragItem] = useState<string>('');
    const [dropTo, setDropTo] = useState<number>(0);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {isWorkWithBoardEnabled && <TableCell></TableCell>}
                    <TableCell>
                        <TableHeadTypography
                            text={`${t('orguser.boards.parameters.iconType')} / ${t(
                                'orguser.boards.parameters.title',
                            )}`}
                        />
                    </TableCell>
                    <TableCell>
                        <TableHeadTypography text={t('orguser.boards.parameters.workflow')} />
                    </TableCell>
                    <TableCell>
                        <TableHeadTypography text={t('orguser.boards.parameters.type')} />
                    </TableCell>
                    <TableCell>
                        <TableHeadTypography text={t('orguser.boards.parameters.typePermission')} />
                    </TableCell>
                    <TableCell>
                        <TableHeadTypography text={t('orguser.boards.parameters.statuses')} />
                    </TableCell>
                    {isWorkWithBoardEnabled && (
                        <>
                            <TableCell>
                                <TableHeadTypography text={t('orguser.boards.parameters.isActive')} />
                            </TableCell>
                            <TableCell>
                                <TableHeadTypography text={t('orguser.boards.parameters.action')} />
                            </TableCell>
                        </>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {Array.isArray(itemsPortion) &&
                    itemsPortion.map((item: BoardItemTypes, index: number) => {
                        return (
                            <BoardTableRow
                                key={`formTable-row-${item.uuid}`}
                                item={item}
                                dragItem={dragItem}
                                setDragItem={setDragItem}
                                dropTo={dropTo}
                                setDropTo={setDropTo}
                                isEditEnabled={isWorkWithBoardEnabled}
                            />
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default BoardsTable;
