import React, {FC, ReactNode, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MediaContext} from 'contexts/media/context';

import {TabItemType, getTabBackgroundColor, getTabTextColor} from 'components/TabsWrapper/helper';

import {theme} from 'config/theme';

interface TabsWrapperType {
    tabItems: TabItemType[];
    currentTab: number;
    setCurrentTab: (value: number) => void;
    children: ReactNode;
}

const TabsWrapper: FC<TabsWrapperType> = ({tabItems, currentTab, setCurrentTab, children}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const onTabClick = (tabItem: TabItemType) => {
        const {tabNumber, isDisabled} = tabItem;
        if (!isDisabled) {
            setCurrentTab(tabNumber);
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pl: isMobile ? 0 : 2,
                    pr: isMobile ? 0 : 2,
                }}
            >
                {tabItems.map((tabItem: TabItemType, index: number) => {
                    const {title, tabNumber, isDisabled} = tabItem;
                    const isActive = currentTab === tabNumber;
                    return (
                        <React.Fragment key={`tab-${tabItem.title}-${index}`}>
                            <Box
                                sx={{
                                    backgroundColor: getTabBackgroundColor(isActive, isDisabled),
                                    borderRadius: isMobile && !isActive ? '6px' : 0,
                                    borderTopLeftRadius: 6,
                                    borderTopRightRadius: 6,
                                    cursor: isActive || isDisabled ? 'initial' : 'pointer',
                                    mr: isMobile ? 1.5 : 1,
                                    ml: isMobile ? 1.5 : 0,
                                    mt: isMobile ? 1 : 0,
                                    width: isMobile ? 'calc(100% - 24px)' : 'auto',
                                    pl: 2,
                                    pr: 2,
                                    pt: 1,
                                    pb: 1,
                                }}
                                onClick={() => onTabClick(tabItem)}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        color: getTabTextColor(isActive, isDisabled),
                                    }}
                                >
                                    {t(title)}
                                </Typography>
                            </Box>
                            {isMobile && isActive && (
                                <Box
                                    sx={{
                                        border: `2px solid ${theme.palette.info.main}`,
                                        borderRadius: 4,
                                        p: 2,
                                        width: '100%',
                                        mb: 1,
                                    }}
                                >
                                    {children}
                                </Box>
                            )}
                        </React.Fragment>
                    );
                })}
            </Box>
            {!isMobile && (
                <Box
                    sx={{
                        border: `2px solid ${theme.palette.info.main}`,
                        borderRadius: 4,
                        p: 2,
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default TabsWrapper;
