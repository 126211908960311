import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {workflowRuleTypes} from 'components/Forms/Workflow/workflowStatusRule/helper';
import WorkflowStatusRuleForm from 'components/Forms/Workflow/workflowStatusRule/WorkflowStatusRuleForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface RulesFromSwitcherType {
    item: WorkflowStatusItemTypes;
}

const RulesFromSwitcher: FC<RulesFromSwitcherType> = ({item}) => {
    const [t] = useTranslation();

    const [isShowForm, setIsShowForm] = useState<boolean>(false);

    return (
        <>
            {isShowForm ? (
                <WorkflowStatusRuleForm item={item} closeForm={() => setIsShowForm(false)} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        icon={<PlusSvgIcon isActive />}
                        title={t('orguser.workflows.rules.addRule')}
                        onClick={() => setIsShowForm(true)}
                    />
                </Box>
            )}
            <Divider sx={{mt: 2, mb: 2}} />
        </>
    );
};

export default RulesFromSwitcher;
