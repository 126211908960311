import {TFunction} from 'i18next';

import {ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';

import {
    CALENDAR_EXCEPTION_CHANGED_TIMES,
    CALENDAR_EXCEPTION_BLOCKED_DATE,
    CALENDAR_EXCEPTION_BLOCKED_TIMES,
} from 'components/Forms/ResourceField/helper';

export const getExceptionSubtitle = (t: TFunction, item: ResourceFieldExceptionItemTypes): string => {
    const {type, startTime, endTime} = item;
    switch (type) {
        case CALENDAR_EXCEPTION_CHANGED_TIMES:
            return `${t('orguser.resourceFields.exceptionTypes.changedTimes')}: ${startTime} - ${endTime}`;
        case CALENDAR_EXCEPTION_BLOCKED_DATE:
            return t('orguser.resourceFields.exceptionTypes.blockedDate');
        case CALENDAR_EXCEPTION_BLOCKED_TIMES:
            return `${t('orguser.resourceFields.exceptionTypes.blockedTimes')}: ${startTime} - ${endTime}`;
    }
    return '';
};
