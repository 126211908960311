import React, {FC, useCallback, useContext} from 'react';
import {Formik, Form, Field, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ResetPasswordRequestTypes} from 'appRedux/actions/auth/types';
import {RESET_PASSWORD} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';
import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';

import {resetPasswordValidationSchema} from 'pages/start/resetPassword/validation';

import {preventSendForm} from 'helpers/index';

const ResetPasswordForm: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const resetPasswordRequest = useCallback(
        data => dispatch({type: RESET_PASSWORD.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Box sx={{width: '100%'}}>
            <Typography align={'center'} sx={{mb: 2, fontWeight: 600}}>
                {t('common.resetPasswordForm.resetPassword')}
            </Typography>
            <Formik
                initialValues={{
                    nickname: '',
                }}
                validationSchema={() => resetPasswordValidationSchema(t)}
                onSubmit={(values, {resetForm}) => {
                    resetPasswordRequest({
                        ...values,
                        showAlert,
                    });
                    resetForm();
                }}
            >
                {(formik: FormikProps<ResetPasswordRequestTypes>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Box sx={{mb: 3}}>
                                <Field
                                    data-id={`input#resetpassword-email`}
                                    name="nickname"
                                    label={t('common.resetPasswordForm.emailOrNickname')}
                                    placeholder={t('common.resetPasswordForm.emailOrNickname')}
                                    component={ClientFormikTextInput}
                                    required
                                />
                            </Box>
                            <LoadingStartButton
                                data-id={`input#resetpassword-continue`}
                                label={t('common.resetPasswordForm.continue')}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ResetPasswordForm;
