import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import {Checkbox, FormGroup} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import MenuAnchorWrapper from 'components/AgentScreenComponents/MenuAnchorWrapper';

import FilterItemWithSubItems from 'pages/agent/savedFilters/FilterItemWithSubItems';

interface MultipleFilterTypes {
    optionList: FilterOptionType[];
    handleFilterChange: any;
    filterLabel: string;
    selectedValues: string[];
}

const MultipleFilter: FC<MultipleFilterTypes> = ({filterLabel, handleFilterChange, optionList, selectedValues}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (optionList.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={filterLabel} onClick={handleClick} open={open} />
            <MenuAnchorWrapper open={open} anchorEl={anchorEl} handleClose={handleClose}>
                <FormGroup onChange={handleFilterChange}>
                    {optionList.map((option: FilterOptionType, index: number) => {
                        const subItems = get(option, 'subItems', []);
                        if (subItems.length > 0) {
                            return (
                                <FilterItemWithSubItems
                                    key={`option-${index}`}
                                    option={option}
                                    selectedValues={selectedValues}
                                />
                            );
                        }
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.name}
                                control={<Checkbox checked={selectedValues.includes(option.name)} />}
                                label={t(option.label)}
                            />
                        );
                    })}
                </FormGroup>
            </MenuAnchorWrapper>
        </>
    );
};

export default MultipleFilter;
